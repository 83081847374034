angular.module("vgresiduos").controller("DestinationTrackingCtrl", [
	"$scope",
	"$rootScope",
	"accountService",
	function ($scope, $rootScope, accountService) {
		"use strict";

		function getLoggedClientCoordinates() {
			$scope.currentClient = accountService.getCurrentClient();
			var lat = $scope.currentClient.latitude
				? $scope.currentClient.latitude.replace
					? parseFloat($scope.currentClient.latitude.replace(",", "."))
					: $scope.currentClient.latitude
				: 0;
			var lng = $scope.currentClient.longitude
				? $scope.currentClient.longitude.replace
					? parseFloat($scope.currentClient.longitude.replace(",", "."))
					: $scope.currentClient.longitude
				: 0;

			return lat && lng ? new Cmp.constructors.MapPoint(lat, lng) : Vgr.constants.MapLocations.BELO_HORIZONTE_MG;
		}

		$scope.customInitOptions = {
			disableDefaultUI: true,
			draggable: false,
			zoomControl: false,
			scrollwheel: false,
			disableDoubleClickZoom: true
		};

		function initGoogleMaps() {
			var initialZoom = 6;
			var initialCoordinates = getLoggedClientCoordinates();

			$scope.mapOptions = new Cmp.constructors.GoogleMapOptions(initialCoordinates, initialZoom);
			$scope.mapOptions.onRenderFinished = function () {
				if ($scope.mapOptions.loaded) {
					return;
				}

				$scope.mapOptions.loaded = true;
				addTrackingRouteInformation();
			};
		}

		function addTrackingRouteInformation() {
			var disposal = $scope.readOnlyModel;
			if (
				disposal.Client.Latitude &&
				disposal.Client.Longitude &&
				disposal.Destinator.Latitude &&
				disposal.Destinator.Longitude
			) {
				var origin = disposal.Client.Latitude + ", " + disposal.Client.Longitude;
				var destination = disposal.Destinator.Latitude + ", " + disposal.Destinator.Longitude;

				var waypoints = [];
				if (disposal.TemporaryStorager && disposal.TemporaryStorager.Latitude && disposal.TemporaryStorager.Longitude) {
					waypoints.push(disposal.TemporaryStorager.Latitude + ", " + disposal.TemporaryStorager.Longitude);
				}

				$scope.mapOptions.route(origin, destination, waypoints);
			}
		}

		this.$onInit = function () {
			initGoogleMaps();
		};
	}
]);
