angular.module("vgresiduos").controller("GoalEditHeaderCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onChanges = function (obj) {
			if (
				obj.measureUnitId &&
				obj.measureUnitId.currentValue &&
				obj.measureUnitId.currentValue != obj.measureUnitId.previousValue
			) {
				$scope.measureUnitId = obj.measureUnitId.currentValue;
			}
		};

		$scope.isRevenueChange = function (isRevenue) {
			$scope.$ctrl.onIsRevenueChange(isRevenue);
		};

		$scope.measureUnitChanged = function (newValue) {
			$scope.$ctrl.onMeasureUnitChange(newValue);
		};
	}
]);
