angular.module("vgresiduos").controller("propertyTypeSelectFieldCtrl", [
	"$scope",
	"$rootScope",
	"dialogService",
	function ($scope, $rootScope, dialogService) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.model = {};

		$scope.addValueInList = function () {
			if (
				!validateValueNames($scope.model.suspendListValue) &&
				!validateValueEmptyNames($scope.model.suspendListValue)
			) {
				$scope.ctrl.additionalPropertyValues.push({ name: $scope.model.suspendListValue });
				$scope.model.suspendListValue = null;
			}
		};

		$scope.additionalPropertyTypes = [
			{ value: "Text", name: $scope.labels.FREE_TEXT },
			{ value: "List", name: $scope.labels.DEFAULT_VALUE }
		];

		function validateValueEmptyNames(valueName) {
			return valueName == "" || valueName == null ? true : false;
		}

		function validateValueNames(valueName) {
			return $scope.ctrl.additionalPropertyValues.find((avl) => avl.name == valueName) ? true : false;
		}

		$scope.onChangePropertyType = function (type) {
			validateType(type);
			$scope.ctrl.additionalPropertyType = type;
		};

		$scope.$watch("ctrl.additionalPropertyType", function (newValue, oldValue) {
			if (
				oldValue == Vgr.enumerations.additionalProperty.type.SuspendList &&
				newValue == Vgr.enumerations.additionalProperty.type.FreeText
			) {
				if (!$scope.ctrl.additionalPropertyValues.length == 0) {
					showDialogConfirm();
				}
			}
		});

		function showDialogConfirm() {
			var params = {
				popupTitle: null,
				popupText: $rootScope.labels.WANT_REMOVE_DEFAULT_VALUE,
				popupAuxiliaryText: null,
				popupDescription: null,
				cancelButtonLabel: null,
				confirmButtonLabel: null,
				cancelMethod: null,
				confirmMethod: null
			};

			dialogService
				.showDialogFromTemplateV2(
					"views/components/popups/confirmPopup_template.html",
					"ConfirmPopupCtrl",
					null,
					params
				)
				.then(
					function () {
						$scope.ctrl.additionalPropertyValues = [];
						$scope.onChangePropertyType(Vgr.enumerations.additionalProperty.type.FreeText);
					},
					function () {
						$scope.onChangePropertyType(Vgr.enumerations.additionalProperty.type.SuspendList);
					}
				);
		}

		function validateType(type) {
			if (type == "List") {
				$scope.hasPropertyValues = true;
			} else {
				$scope.hasPropertyValues = false;
			}
		}

		this.$onInit = function () {
			validateType($scope.ctrl.additionalPropertyType);
		};
	}
]);
