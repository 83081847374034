angular.module("vgresiduos").component("groupBy", {
	controller: "GroupByCtrl",
	templateUrl: "views/components/groupBy/groupBy_template.html",
	bindings: {
		list: "<",
		onChange: "<",
		clear: "<",
		initialValues: "<?"
	}
});
