angular.module("vgresiduos").controller("MasterUserCustomizedFilterCtrl", [
	"$rootScope",
	"$scope",
	"httpService",
	"clientService",
	"organizationService",
	"$q",
	function ($rootScope, $scope, httpService, clientService, organizationService, $q) {
		"use strict";

		var organiztionsOptions = {
			pageSize: 30,
			pageNumber: 1,
			query: "",
			id: 0,
			onlyActive: true
		};

		var unitsOptions = {
			pageSize: 30,
			pageNumber: 1,
			query: "",
			id: 0,
			onlyActive: true
		};

		$scope.labels = $rootScope.labels;

		$scope.customizedFilterOptions = {
			isFilterActive: false,
			selectedFilters: [],
			availableFields: [
				{
					type: Vgr.enumerations.customizedFilter.type.Text,
					label: $rootScope.labels.CODE,
					id: "code",
					isEnabled: true,
					isOptionEnabled: function () {
						return this.isEnabled;
					}
				},
				{
					type: Vgr.enumerations.customizedFilter.type.Text,
					label: $rootScope.labels.userForm.NAME,
					id: "name",
					isEnabled: true,
					isOptionEnabled: function () {
						return this.isEnabled;
					}
				},
				{
					type: Vgr.enumerations.customizedFilter.type.Text,
					label: $rootScope.labels.userForm.EMAIL,
					id: "email",
					isEnabled: true,
					isOptionEnabled: function () {
						return this.isEnabled;
					}
				},
				{
					type: Vgr.enumerations.customizedFilter.type.TextCompletePaginateServer,
					label: $rootScope.labels.ORGANIZATION,
					displayProperty: "DescriptionToShow",
					id: "organizationId",
					valueField: "Id",
					isEnabled: true,
					list: [],
					listOptions: organiztionsOptions,
					isOptionEnabled: function () {
						return this.isEnabled;
					},
					loadList: function () {
						var deferred = $q.defer();

						var params = {
							take: organiztionsOptions.pageSize,
							skip: organiztionsOptions.pageNumber - 1,
							nameLike: organiztionsOptions.query || "",
							onlyActive: organiztionsOptions.onlyActive
						};

						httpService
							.getListFromServiceV2(organizationService.listOrganizations, params, "Organizations")
							.then((organizations) => {
								var organizationsWithDescription = organizations.map((organization) => {
									return { ...organization, DescriptionToShow: organization.Id + " - " + organization.Name };
								});
								deferred.resolve(organizationsWithDescription);
							})
							.catch((err) => deferred.reject());

						return deferred.promise;
					}
				},
				{
					type: Vgr.enumerations.customizedFilter.type.TextCompletePaginateServer,
					label: $rootScope.labels.UNIT,
					id: "organizationUnitId",
					valueField: "Id",
					isEnabled: true,
					displayProperty: "DescriptionToShow",
					list: [],
					listOptions: unitsOptions,
					isOptionEnabled: function () {
						return this.isEnabled;
					},
					loadList: function () {
						const deferred = $q.defer();

						httpService
							.getListFromServiceCore(clientService.listMasterClients, {
								take: unitsOptions.pageSize,
								skip: unitsOptions.pageNumber - 1,
								query: unitsOptions.query || "",
								onlyActive: unitsOptions.onlyActive
							})
							.then(function (response) {
								const organizationUnitFilterList = response.list.map((client) => {
									return {
										Id: client.id,
										DescriptionToShow: client.id + " - " + client.name
									};
								});
								deferred.resolve(organizationUnitFilterList);
							})
							.catch(() => deferred.reject());

						return deferred.promise;
					}
				}
			]
		};

		$scope.onFilter = function () {
			var dto = getDtoFromFilters();
			if (dto) $scope.ctrl.onFilter(dto);
		};

		function getDtoFromFilters() {
			var filters = $scope.customizedFilterOptions.selectedFilters;
			var dto = {};

			for (var i = 0; i < filters.length; ++i) {
				if (!addValueFromOptionToDto(dto, filters[i])) {
					$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.MANDATORY_FILL_ALL_FILTERS);
					return null;
				}
			}

			return dto;
		}

		function addValueFromOptionToDto(dto, filter) {
			if (!filter.field) {
				return true;
			} else if (!filter.value) {
				return false;
			}

			if (filter.field.type == Vgr.enumerations.customizedFilter.type.TextCompletePaginateServer) {
				dto[filter.field.id] = filter.value.Id;
			} else if (filter.field.type == Vgr.enumerations.customizedFilter.type.Text) {
				dto[filter.field.id] = filter.value;
			}

			return true;
		}
	}
]);
