angular.module("vgresiduos").factory("supplierContactService", [
	"$http",
	"accountService",
	function ($http, accountService) {
		"use strict";

		function getSupplierContactBaseRequestUrl(supplierCode) {
			return (
				Vgr.constants.suppliersHostUrl +
				Vgr.resources.suppliers.supplierContacts
					.replace("[ORGANIZATION]", accountService.getCurrentClient().organization.id)
					.replace("[SUPPLIER_CODE]", supplierCode)
			);
		}

		const _listContacts = function (dto) {
			const route = getSupplierContactBaseRequestUrl(dto.supplierCode) + Vgr.util.serializeObjectToQueryString(dto);
			const req = {
				method: "get",
				url: route
			};

			return $http(req);
		};

		return {
			listContacts: _listContacts
		};
	}
]);
