angular.module("vgresiduos").directive("ibamaRappFollowupCard", function () {
	return {
		scope: true,
		controller: "IbamaRappFollowupCardCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/ibama/ibamaRappFollowup/card/ibamaRappFollowupCard_template.html",
		bindToController: {
			model: "=",
			reloadCards: "=?"
		}
	};
});
