angular.module("vgresiduos").controller("CustomizedFilterMultiGroupingCtrl", [
	"$rootScope",
	"$scope",
	"reportUtilService",
	function ($rootScope, $scope, reportUtilService) {
		"use strict";
		$scope.labels = $rootScope.labels;

		$scope.groupByOptions = [];

		this.$onInit = function () {
			initAvailableFields($scope.$ctrl.list);
			setValues($scope.$ctrl.setter);
		};

		function initAvailableFields(list) {
			$scope.availableFields = [];

			for (const item of list) {
				const optionField = buildOptionField(item);
				$scope.availableFields.push(optionField);
			}
		}

		function buildOptionField(groupOption) {
			const groupBy = groupOption.id;
			if (groupBy == reportUtilService.filterIds.GenerationProperty) {
				return buildAdditionalPropertyOptionField(
					groupOption,
					Vgr.enumerations.additionalProperty.process.InternalGathering
				);
			} else if (groupBy == reportUtilService.filterIds.AreaProperty) {
				return buildAdditionalPropertyOptionField(groupOption, Vgr.enumerations.additionalProperty.process.Area);
			} else if (groupBy == reportUtilService.filterIds.OrganizationUnitProperty) {
				return buildAdditionalPropertyOptionField(
					groupOption,
					Vgr.enumerations.additionalProperty.process.OrganizationUnit
				);
			} else if (groupBy == reportUtilService.filterIds.DisposalProperty) {
				return buildAdditionalPropertyOptionField(groupOption, Vgr.enumerations.additionalProperty.process.Disposal);
			} else {
				return {
					id: groupOption.id,
					description: groupOption.label,
					category: groupOption.category,

					type: Vgr.enumerations.customizedFilterV2.optionField.type.common,

					isEnabled: true,
					isOptionEnabled: function () {
						return this.isEnabled;
					}
				};
			}
		}

		function buildAdditionalPropertyOptionField(groupOption, process) {
			return {
				id: groupOption.id,
				description: groupOption.label,
				category: groupOption.category,

				type: Vgr.enumerations.customizedFilterV2.optionField.type.additionalProperty,

				displayProperty: $scope.labels.PROPERTY,
				propertyProcess: process,

				isOptionEnabled: function () {
					return true;
				}
			};
		}

		function setValues(values) {
			if (values && values.length > 0) {
				setValueFields(values);
			} else {
				addNewEmptyOption();
			}
		}

		function setValueFields(values) {
			for (const value of values) {
				const groupByOption = addNewEmptyOption();
				groupByOption.optionSetter = {
					optionId: value.id,
					additionalPropertyId: value.additionalPropertyId
				};
			}
		}

		function addNewEmptyOption() {
			const groupByOption = {
				id: Vgr.util.uuid(),
				optionSetter: null,
				selectedOption: null
			};

			groupByOption.callbackOption = function (selectedOption) {
				onChangeOptionField(selectedOption, groupByOption);
			};

			$scope.groupByOptions.push(groupByOption);
			return groupByOption;
		}

		function onChangeOptionField(selectedOption, groupByOption) {
			if (groupByOption.selectedOption) {
				updateAvailableOption(groupByOption.selectedOption.item.id, true);
			}
			if (selectedOption) {
				updateAvailableOption(selectedOption.item.id, false);
			}

			groupByOption.selectedOption = selectedOption;

			callback();
		}

		function updateAvailableOption(optionId, enable) {
			const option = $scope.availableFields.filter(function (item) {
				return item.id === optionId;
			})[0];

			option.isEnabled = enable;
		}

		$scope.onClickAddGroupBy = function () {
			addNewEmptyOption();
		};

		$scope.onRemoveOption = function (index) {
			if ($scope.groupByOptions[index].selectedOption) {
				updateAvailableOption($scope.groupByOptions[index].selectedOption.item.id, true);
			}

			if ($scope.groupByOptions.length == 1) {
				$scope.groupByOptions = [];
				addNewEmptyOption();
			} else {
				$scope.groupByOptions.splice(index, 1);
			}

			callback();
		};

		$scope.disableAddButton = function () {
			const validOptions = $scope.groupByOptions.filter(isValidOption);

			return validOptions.length != $scope.groupByOptions.length;
		};

		this.$onChanges = function (obj) {
			if (obj.list && !obj.list.isFirstChange()) {
				onChangeList(obj.list.currentValue);
			}
			if (obj.clear) {
				if (obj.clear.currentValue) {
					onClearGroupings();
				}
			}
		};

		function onChangeList(newList) {
			initAvailableFields(newList);
			removeGroupings();
			setValues($scope.$ctrl.setter);
			callback();
		}

		function onClearGroupings() {
			removeGroupings();
			setValues();
			callback();
		}

		function removeGroupings() {
			$scope.groupByOptions = [];
		}

		function callback() {
			const selectedOptions = $scope.groupByOptions.filter(isValidOption).map(function (item) {
				return item.selectedOption;
			});

			$scope.$ctrl.callback(selectedOptions);
		}

		function isValidOption(item) {
			return !!item.selectedOption;
		}
	}
]);
