angular.module("vgresiduos").component("additionalPropertyValueMultiSelect", {
	controller: "AdditionalPropertyValueMultiSelectCtrl",
	templateUrl:
		"views/components/multiSelects/additionalPropertyValueMultiSelect/additionalPropertyValueMultiSelect_template.html",
	bindings: {
		additionalPropertyId: "<",

		setter: "<?",
		callback: "<",

		clear: "<?"
	}
});
