angular.module("vgresiduos").controller("DnitReportCtrl", [
	"$scope",
	"$rootScope",
	"dialogService",
	"dnitIntegrationService",
	function ($scope, $rootScope, dialogService, dnitIntegrationService) {
		"use strict";

		const minYearRange = 3;
		const maxYearRange = 1;
		const currentYear = new Date().getFullYear();

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;
			$scope.yearFilterOptions = {};
			$scope.filters = {};

			$scope.yearFilterOptions.initialYear = currentYear - 1;
			$scope.yearFilterOptions.minYear = $scope.yearFilterOptions.initialYear - minYearRange;
			$scope.yearFilterOptions.maxYear = $scope.yearFilterOptions.initialYear + maxYearRange;

			$scope.filters.year = $scope.yearFilterOptions.initialYear;

			$scope.yearChanged = function (year) {
				$scope.filters.year = year;
			};

			$rootScope.contentNavBar = {
				title: $scope.labels.DNIT_INTEGRATION,
				valid: true,

				buttons: []
			};

			$scope.generateReport = function () {
				const params = {
					localVariables: {
						popupTitle: $rootScope.labels.DNIT_INTEGRATION,
						popupText: $rootScope.labels.DNIT_INTEGRATION_POPUP_DESCRIPTION,
						list: $scope.filters.selectedClients,
						operationMethod: dnitIntegrationService.generateDnitReport,
						idField: "",
						getDto: getGenerateReportDto,
						descriptionField: "Name",
						confirmLabel: $rootScope.labels.FINISH_REQUEST,
						downloadAction: downloadDnitIntegrationFile,
						keepPopupOpenOnFinish: true,
						runOperationsOnPopupOpen: true
					}
				};

				openConfirmationPopup(params);
			};
		};

		function openConfirmationPopup(params) {
			return dialogService.showDialogFromTemplateV2(
				"views/components/popups/operationConfirmationPopup_template.html",
				"OperationConfirmationPopupCtrl",
				null,
				params,
				true
			);
		}

		function getGenerateReportDto(unit) {
			return {
				Year: $scope.filters.year,
				OrganizationUnitIds: [unit.Id]
			};
		}

		function downloadDnitIntegrationFile(item) {
			var fileDTO = Vgr.util.fileDtoToFile(item.returnDto[0]);
			saveAs(fileDTO, fileDTO.FileName);
		}
	}
]);
