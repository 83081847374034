angular.module("vgresiduos").directive("destinationFlow", function () {
	return {
		scope: false,
		controller: "DestinationFlowCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/destination/baseComponents/destinationFlow/destinationFlow_template.html",
		bindToController: {
			onStagesLoaded: "="
		}
	};
});
