angular.module("vgresiduos").controller("UserFormCtrl", [
	"$scope",
	function ($scope) {
		"use strict";

		$scope.$watch("userForm.$valid", function (newVal) {
			$scope.ctrl.saveOptions.valid = newVal;
		});
	}
]);
