angular.module("vgresiduos").controller("DisposalEstimateGatheringCtrl", [
	"$scope",
	function ($scope) {
		"use strict";

		const form = "estimateGatheringForm";
		const input = "estimateGatheringInput";

		$scope.formScope = {};
		$scope.formError = {};

		this.$onInit = function () {};

		$scope.getMinDate = function (date) {
			return Vgr.util.getDateStringYYYYMMDD(date || Vgr.util.getDate());
		};

		$scope.getMaxDate = function () {
			const dateSummed = Vgr.util.getSumOfDate(Vgr.util.getDate(), $scope.getMaxDaysEstimatedGathering());
			return Vgr.util.getDateStringYYYYMMDD(dateSummed);
		};

		$scope.estimatedGatheringDateChanged = function () {
			const step = $scope.destinationConfig.currentStage.Steps.find(
				(item) => item.Index == $scope.disposalSolicitationSteps.EstimateGathering.Index
			).Id;
			if ($scope.destinationModel.EstimatedGatheringDate) {
				$scope.updateSlip(step, Vgr.util.dateToString(new Date($scope.destinationModel.EstimatedGatheringDate)));
			} else {
				$scope.updateSlip(step, null);
			}
		};

		$scope.setFormScope = function (scope) {
			$scope.formScope = scope;
		};

		$scope.estimatedGatheringDateOnBlur = function () {
			$scope.formError = {};
			$scope.customErrorMessage = "";

			$scope.formError = $scope.formScope[form][input].$error;

			const errorMessages = getErrorMessages();
			const keysFormError = Object.keys($scope.formError);

			keysFormError.forEach((item) => {
				$scope.customErrorMessage += `${errorMessages[item]}\n`;
			});
		};

		function getErrorMessages() {
			const minMaxError = $scope.labels.ESTIMATED_DATE_GATHERING_RANGE_ERROR.replace(
				"[P1]",
				$scope.getMaxDaysEstimatedGathering()
			);
			const dateError = $scope.labels["ERR-DAT-0001"];

			return {
				min: minMaxError,
				max: minMaxError,
				date: dateError
			};
		}
	}
]);
