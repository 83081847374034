angular.module("vgresiduos").controller("TransporterEditCtrl", [
	"$rootScope",
	"$scope",
	"$stateParams",
	"$state",
	"supplierService",
	"httpService",
	"accountService",
	"clientService",
	function ($rootScope, $scope, $stateParams, $state, supplierService, httpService, accountService, clientService) {
		"use strict";

		// Permissions
		$scope.createEditDeletePermission = false;

		// Local variables
		const labels = $rootScope.labels;

		// Scope variables
		$scope.transporterCode = $stateParams.id;
		$scope.transporterId = null;
		$scope.generalSaveOptions = {};
		$scope.isSupplier = clientService.isSupplier();
		$scope.tabOptions = {};

		// Scope functions
		$scope.onSelectTab = onSelectTab;

		this.$onInit = function () {
			loadTabOptions();
			setSelectedTab();

			loadSupplier();
		};

		function loadTabOptions() {
			if ($scope.isSupplier) {
				buildAuditsTab(0);
				buildClientAuditsTab(1);
				buildClientActionPlansTab(2);
			} else {
				buildGeneralTab(0);
			}
		}

		function buildGeneralTab(index) {
			$scope.tabOptions.general = createBaseTab(
				index,
				Vgr.enumerations.supplier.tabPageRoute.general,
				labels.GENERAL,
				false
			);
		}

		function buildAuditsTab(index) {
			$scope.tabOptions.audits = createBaseTab(index, Vgr.enumerations.supplier.tabPageRoute.audits, labels.MY_AUDITS);
		}

		function buildClientAuditsTab(index) {
			$scope.tabOptions.clientAudits = createBaseTab(
				index,
				Vgr.enumerations.supplier.tabPageRoute.clientAudits,
				labels.CLIENT_AUDITS
			);
		}

		function buildClientActionPlansTab(index) {
			$scope.tabOptions.clientActionPlans = createBaseTab(
				index,
				Vgr.enumerations.supplier.tabPageRoute.clientActionPlans,
				labels.ACTION_PLANS
			);
		}

		function createBaseTab(index, route, name, disabled) {
			if (disabled === undefined || disabled === null) {
				disabled = false;
			}

			return {
				index: index,
				name: name,
				route: route,
				disabled: disabled
			};
		}

		function setSelectedTab() {
			const route = $stateParams.context;

			if (!route) {
				$scope.selectedTabIndex = 0;
			} else if ($scope.tabOptions.general && $scope.tabOptions.general.route == route) {
				$scope.selectedTabIndex = $scope.tabOptions.general.index;
			} else if ($scope.tabOptions.clientAudits && $scope.tabOptions.clientAudits.route == route) {
				$scope.selectedTabIndex = $scope.tabOptions.clientAudits.index;
			} else if ($scope.tabOptions.actionPlans && $scope.tabOptions.actionPlans.route == route) {
				$scope.selectedTabIndex = $scope.tabOptions.actionPlans.index;
			} else if ($scope.tabOptions.clientActionPlans && $scope.tabOptions.clientActionPlans.route == route) {
				$scope.selectedTabIndex = $scope.tabOptions.clientActionPlans.index;
			} else {
				$state.go("transporterEdit", { id: $scope.transporterCode });
				return;
			}
		}

		function loadSupplier() {
			if ($scope.isSupplier) {
				$scope.transporter = {};
				$scope.transporterId = accountService.getCurrentClient().id;
				return;
			}
			if ($scope.transporterCode) {
				httpService.getDTOFromServiceV2(supplierService.getOrganizationUnitSupplier, $scope.transporterCode).then(
					function (organizationUnitSupplier) {
						if (organizationUnitSupplier) {
							httpService
								.getDTOFromServiceV2(supplierService.getOrganizationSupplierByCode, {
									supplierCode: $scope.transporterCode
								})
								.then(function (organizationSupplier) {
									configureEdition(organizationUnitSupplier, organizationSupplier);
								});
						} else {
							goBack();
						}
					},
					function () {
						goBack();
					}
				);
			} else {
				goBack();
			}
		}

		function configureEdition(organizationUnitSupplier, organizationSupplier) {
			$scope.createEditDeletePermission = supplierService.hasSupplierPermission(
				organizationSupplier,
				null,
				Vgr.systemPermissions.SupplierEdit
			);
			$scope.disableOrganizationFields = true;

			$scope.transporter = organizationUnitSupplier;
			$scope.organizationSupplier = organizationSupplier;
			$scope.transporterId = organizationUnitSupplier.supplierId;
			if ($scope.transporter.document) {
				$scope.transporter.document = $scope.transporter.document.toString();
				$scope.transporter.isCNPJ =
					!$scope.transporter.document || $scope.transporter.document.length == 14 ? true : false;
			} else {
				$scope.transporter.isCNPJ = false;
			}

			if ($scope.transporter.email) {
				$scope.transporter.email = $scope.transporter.email.trim();
			}
		}

		function goBack() {
			if ($scope.isSupplier) {
				accountService.goHome();
			} else {
				$state.go("transporterList");
			}
		}

		function getDefaultContentNavBar() {
			return {
				title: $scope.labels.SUPPLIER_UNIT_CONTROL,
				back: goBack,
				removeButtonBar: true,
				onSaveError: function () {
					//Nothing to be done
				},
				breadcrumb: $scope.transporter.code
			};
		}

		function getGeneralTabContentNavBar() {
			const contentNavBar = getDefaultContentNavBar();

			contentNavBar.hasPermission = function () {
				return $scope.createEditDeletePermission;
			};
			contentNavBar.removeButtonBar = false;

			contentNavBar.buttons = [labels.SAVE];
			contentNavBar.valid = true;

			contentNavBar.save = function () {
				return $scope.generalSaveOptions.save();
			};
			contentNavBar.validate = function () {
				return $scope.generalSaveOptions.validate();
			};

			return contentNavBar;
		}

		function onSelectTab(index) {
			if ($scope.tabOptions.general && index == $scope.tabOptions.general.index) {
				$rootScope.contentNavBar = getGeneralTabContentNavBar();
			} else {
				$rootScope.contentNavBar = getDefaultContentNavBar();
			}

			$rootScope.contentNavBarGrid = false;

			$scope.currentTab = index; // wait event ends to build tab
		}
	}
]);
