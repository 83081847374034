angular.module("componentes").component("statusCard", {
	controller: function ($scope) {
		this.$onInit = function () {
			switch (this.status) {
				case Vgr.constants.generalStatus.success:
					$scope.icon = this.icon ? this.icon : "check_circle";
					$scope.colorClass = "status-card-success";
					break;
				case Vgr.constants.generalStatus.warning:
					$scope.icon = this.icon ? this.icon : "access_time";
					$scope.colorClass = "status-card-warning";
					break;
				case Vgr.constants.generalStatus.error:
					$scope.icon = this.icon ? this.icon : "error";
					$scope.colorClass = "status-card-error";
					break;
			}
		};
	},
	templateUrl: "views/components/statusCard/statusCard_template.html",
	transclude: true,
	bindings: {
		icon: "@?",
		title: "@",
		description: "@?",
		status: "<"
	}
});
