angular.module("vgresiduos").factory("preferencesService", [
	"$http",
	"$q",
	"accountService",
	"rootScopeService",
	function ($http, $q, accountService, rootScopeService) {
		"use strict";

		const _purgeSupplierPreferences = function () {
			rootScopeService.setOrganizationSupplierPreferences(null);
		};

		const _getSupplierPreferencesFromService = function () {
			const client = accountService.getCurrentClient();
			const organizationId = client.organization.id;
			const route = Vgr.resources.suppliers.preferences.replace("[ORGANIZATION]", organizationId);
			const req = {
				method: "get",
				url: Vgr.constants.suppliersHostUrl + route
			};

			return $http(req);
		};

		const _getSupplierPreferences = function () {
			const deferred = $q.defer();

			const supplierPreferences = rootScopeService.getOrganizationSupplierPreferences();

			if (supplierPreferences != null) {
				deferred.resolve(supplierPreferences);
			} else {
				_getSupplierPreferencesFromService().then(
					function (response) {
						rootScopeService.setOrganizationSupplierPreferences(response.data);
						deferred.resolve(rootScopeService.getOrganizationSupplierPreferences());
					},
					function (_response) {
						deferred.reject();
					}
				);
			}

			return deferred.promise;
		};

		return {
			getSupplierPreferences: _getSupplierPreferences,
			purgeSupplierPreferences: _purgeSupplierPreferences
		};
	}
]);
