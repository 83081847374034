angular.module("vgresiduos").controller("disposalEditModelsCtrl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `disposal-models/${$stateParams.id}`);
		};
	}
]);
