angular.module("vgresiduos").factory("userAccessService", [
	"permissionService",
	"featureToggleService",
	function (permissionService, featureToggleService) {
		"use strict";

		const _hasAccess = function (userPermission, featureTogglePermission, featureToggleRelease) {
			const hasAccess = _getAccess(userPermission, featureTogglePermission, featureToggleRelease, false);

			return hasAccess == Vgr.enumerations.userAccess.Enabled;
		};

		const _getAccess = function (
			userPermission,
			featureTogglePermission,
			featureToggleRelease,
			canShowAsDisabledToUpgrade
		) {
			let hasUserPermission = true;
			let hasReleaseToggle = true;
			let hasPlanAcquired = true;

			if (userPermission) {
				hasUserPermission = permissionService.hasPermissionV2(userPermission);
			}
			if (featureTogglePermission) {
				hasPlanAcquired = featureToggleService.hasFeatureToggle(featureTogglePermission);
			}
			if (featureToggleRelease) {
				hasReleaseToggle = featureToggleService.hasFeatureToggle(featureToggleRelease);
			}

			if (canShowAsDisabledToUpgrade) {
				if (hasUserPermission && hasReleaseToggle && hasPlanAcquired) {
					return Vgr.enumerations.userAccess.Enabled;
				} else if (hasUserPermission && hasReleaseToggle && !hasPlanAcquired) {
					return Vgr.enumerations.userAccess.Disabled;
				} else {
					return Vgr.enumerations.userAccess.Hidden;
				}
			} else {
				if (hasUserPermission && hasReleaseToggle && hasPlanAcquired) {
					return Vgr.enumerations.userAccess.Enabled;
				} else {
					return Vgr.enumerations.userAccess.Hidden;
				}
			}
		};

		return {
			hasAccess: _hasAccess,
			getAccess: _getAccess
		};
	}
]);
