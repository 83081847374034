angular.module("vgresiduos").factory("vgrProtectedResourceInterceptor", [
	"$injector",
	"$q",
	"$rootScope",
	function ($injector, $q, $rootScope) {
		"use strict";

		return {
			request: function (config) {
				if (config) {
					const accountService = $injector.get("accountService");

					const cachedTokens = accountService.getCachedVgrToken();
					config.headers = config.headers || {};

					if (cachedTokens) {
						config.headers.Authorization = "Bearer " + cachedTokens.accessToken;
						return config;
					}

					for (const anonymousEndpoint of Vgr.constants.authentication.anonymousEndpoints) {
						if (config.url.indexOf(anonymousEndpoint) > -1) {
							return config;
						}
					}

					// Cancel request if login is starting
					let delayedRequest;
					if (accountService.isLoginOnIdpInProgress()) {
						return $q.reject(config);
					} else if (accountService.isRefreshingVgrSession()) {
						delayedRequest = $q.defer();
						$rootScope.$on(Vgr.constants.evtVgrLoginSuccess, function (_event, tokens) {
							if (tokens) {
								config.headers.Authorization = "Bearer " + tokens.accessToken;
								delayedRequest.resolve(config);
							}
						});
						$rootScope.$on(Vgr.constants.evtVgrLoginFailure, function (_event, error) {
							if (error) {
								config.data = error;
								delayedRequest.reject(config);
							}
						});
						return delayedRequest.promise;
					} else {
						// delayed request to return after iframe completes
						delayedRequest = $q.defer();
						accountService.refreshVgrSession().then(
							function (tokens) {
								config.headers.Authorization = "Bearer " + tokens.accessToken;
								delayedRequest.resolve(config);
							},
							function (error) {
								config.data = error;
								accountService.logout();
								delayedRequest.reject(config);
								window.location.reload(true);
							}
						);

						return delayedRequest.promise;
					}
				}
			}
		};
	}
]);
