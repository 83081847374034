angular.module("vgresiduos").factory("ibamaExternalClientService", [
	"$http",
	"accountService",
	"$q",
	"$timeout",
	function ($http, accountService, $q, $timeout) {
		"use strict";

		var ibamaExternalClientService = {};

		var _listIbamaExternalClient = function () {
			var organizationId = accountService.getCurrentClient().organization.id;

			var req = {
				method: "get",
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.ibamaSystemClient.replace("[ORGANIZATION]", organizationId)
			};

			return $http(req);
		};

		var _getIbamaExternalClient = function (cnpj) {
			var organizationId = accountService.getCurrentClient().organization.id;

			var req = {
				method: "get",
				url:
					Vgr.constants.coreHostUrl +
					Vgr.resources.core.ibamaSystemClient.replace("[ORGANIZATION]", organizationId) +
					cnpj
			};

			return $http(req);
		};

		var _createIbamaExternalClient = function (dto) {
			var organizationId = accountService.getCurrentClient().organization.id;

			var req = {
				method: "post",
				data: dto,
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.ibamaSystemClient.replace("[ORGANIZATION]", organizationId)
			};

			return $http(req);
		};

		var _updateIbamaExternalClient = function (dto) {
			var organizationId = accountService.getCurrentClient().organization.id;

			var req = {
				method: "put",
				data: dto,
				url:
					Vgr.constants.coreHostUrl +
					Vgr.resources.core.ibamaSystemClient.replace("[ORGANIZATION]", organizationId) +
					dto.cnpj
			};

			return $http(req);
		};

		var _deleteIbamaExternalClient = function (cnpj) {
			var organizationId = accountService.getCurrentClient().organization.id;

			var req = {
				method: "delete",
				url:
					Vgr.constants.coreHostUrl +
					Vgr.resources.core.ibamaSystemClient.replace("[ORGANIZATION]", organizationId) +
					cnpj
			};

			return $http(req);
		};

		ibamaExternalClientService.listIbamaExternalClient = _listIbamaExternalClient;
		ibamaExternalClientService.getIbamaExternalClient = _getIbamaExternalClient;
		ibamaExternalClientService.updateIbamaExternalClient = _updateIbamaExternalClient;
		ibamaExternalClientService.createIbamaExternalClient = _createIbamaExternalClient;
		ibamaExternalClientService.deleteIbamaExternalClient = _deleteIbamaExternalClient;

		return ibamaExternalClientService;
	}
]);
