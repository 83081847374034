angular
	.module("vgresiduos")
	.directive("autoFocus", [
		"$timeout",
		function ($timeout) {
			return {
				link: function (scope, element, attrs) {
					"use strict";

					scope.$watch(
						attrs.autoFocus,
						function (val) {
							if (angular.isDefined(val) && val) {
								$timeout(function () {
									element[0].focus();
								});
							}
						},
						true
					);
				}
			};
		}
	])
	.directive("customRegexMask", [
		function () {
			return {
				link: function (_scope, element, attr) {
					const re = new RegExp(attr.customRegexMask, "g");
					element.on("input", function () {
						this.value = this.value.replace(re, "");
					});
				}
			};
		}
	])
	.directive("clickInText", [
		"$compile",
		function ($compile) {
			return {
				link: function (scope, element, attr) {
					if (!attr.clickInText) {
						return;
					}

					var elementForReplace = angular.element("<span/>");
					elementForReplace.append("<span>" + getHtmlWithTextForClick() + "</span>");
					$compile(elementForReplace)(scope);
					element.replaceWith(elementForReplace);

					function getHtmlWithTextForClick() {
						var htmlForClick =
							'<span ng-click="$ctrl.onClick()" class="text-underline">' + attr.clickInText + "</span>";
						return scope.$ctrl.title.replace(attr.clickInText, htmlForClick);
					}
				}
			};
		}
	]);
