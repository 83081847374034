angular.module("vgresiduos").controller("CustomReportDataCtrl", [
	"$rootScope",
	"$scope",
	"reportUtilService",
	function ($rootScope, $scope, reportUtilService) {
		"use strict";

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;

			setValues($scope.$ctrl.data);
		};

		this.$onChanges = function (obj) {
			if (obj.data && !obj.data.isFirstChange() && obj.data.currentValue != obj.data.previousValue) {
				setValues(obj.data.currentValue);
			}
		};

		function setValues(reportData) {
			$scope.tableHeaders = reportUtilService.getHearders(reportData.header);
			$scope.headersConcatenatedArray = reportUtilService.getHeadersConcatenatedArrayFromDto(reportData.header);

			loadDataQualifiers(reportData);

			$scope.hasData = hasData();
			$scope.hideChart = hideChart();
			$scope.addTotalRowByQualifier = hasGrouping();

			$scope.csvFileName = $scope.$ctrl.fileName ? $scope.$ctrl.fileName : "report.csv";
			$scope.title = $scope.$ctrl.information.description;

			let informationId = $scope.$ctrl.information.id.toUpperCase();

			if (informationId === Vgr.enumerations.customReport.information.ResidueDisposalPrice) {
				$scope.subtitle = [$scope.labels.FINALCIAL_CHART_SUBTILE];
				$scope.decimalPlaces = 2;
				$scope.isMoney = true;
			}
		}

		function loadDataQualifiers(reportData) {
			let tableData = [];

			if (reportData.data) {
				for (const data of reportData.data) {
					tableData.push(getTableRow(data));
				}
			}

			$scope.reportDataQualifiers = reportUtilService.getDataQualifiersFromDto(tableData);
		}

		function getTableRow(data) {
			let tableDataRow = {
				Groups: [],
				DataQualifiers: [],
				Intervals: [],
				Total: null
			};

			angular.copy(data.groups, tableDataRow.Groups);
			angular.copy(data.dataQualifiers, tableDataRow.DataQualifiers);
			angular.copy(data.intervals, tableDataRow.Intervals);
			tableDataRow.Total = data.total;

			return tableDataRow;
		}

		$scope.isLineChartCase = function () {
			return !isBarChartCase();
		};

		function hasData() {
			return (
				$scope.reportDataQualifiers &&
				$scope.reportDataQualifiers != null &&
				$scope.reportDataQualifiers != "" &&
				!angular.equals($scope.reportDataQualifiers, {})
			);
		}

		function hideChart() {
			return !hasGrouping() && !isDataGroupedByIntervals();
		}

		function isDataGroupedByIntervals() {
			return !!$scope.tableHeaders.Intervals && $scope.tableHeaders.Intervals.length > 0;
		}

		function hasGrouping() {
			return !!$scope.tableHeaders.Groups && $scope.tableHeaders.Groups.length > 0;
		}

		function isBarChartCase() {
			return !isDataGroupedByIntervals() || !hasGrouping();
		}

		$scope.getTitleComplement = function (dataQualifierName) {
			if (dataQualifierName) {
				return " (" + dataQualifierName + ")";
			} else {
				return "";
			}
		};
	}
]);
