angular.module("vgresiduos").controller("LinkUserGridCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		this.$onInit = function () {
			$scope.gridOptions = {
				columnDefinitions: [
					{
						field: "Id",
						displayName: function () {
							return $rootScope.labels.commonFields.ID;
						},
						width: 50
					},
					{
						field: "Name",
						displayName: function () {
							return $rootScope.labels.userForm.USER_NAME;
						}
					}
				],
				filterFields: ["Id", "Name"],
				title: $rootScope.labels.USERS,
				query: {
					order: "Name",
					limit: 10,
					page: 1
				},
				removePrint: true,
				data: [],
				promise: {},
				selected: [],
				hasData: false,
				errorLoading: false,
				rowClick: "",
				create: ""
			};

			if ($scope.ctrl.gridOptions) {
				Vgr.util.merge($scope.gridOptions, $scope.ctrl.gridOptions);
			}

			$scope.gridOptions.promise = $scope.gridOptions.list().then(
				function (list) {
					$scope.gridOptions.data = list;

					$scope.gridOptions.hasData = true;
				},
				function (err) {
					$scope.gridOptions.errorLoading = true;
					$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.SERVER_CONNECTION_ERROR);
				}
			);

			$rootScope.contentNavBarGrid = $scope.gridOptions;
		};

		$scope.$watch("gridOptions.selected", function (newValue) {
			if (newValue) {
				$scope.ctrl.linkModel.selectedLinks = $scope.gridOptions.selected;
			}
		});
	}
]);
