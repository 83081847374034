angular.module("vgresiduos").controller("DocumentEditCtrl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `documento/${$stateParams.documentId}`);
		};
	}
]);
