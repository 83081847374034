angular.module("vgresiduos").controller("OrganizationFormCtrl", [
	"$scope",
	function ($scope) {
		"use strict";

		$scope.onSelectOwners = function (owners) {
			$scope.ctrl.model.owners = owners;
		};
	}
]);
