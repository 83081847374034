angular.module("vgresiduos").controller("ClientPropertiesPopupCtrl", [
	"$rootScope",
	"$scope",
	"dialogService",
	"propertyData",
	"additionalPropertyProcess",
	"popupTitle",
	function ($rootScope, $scope, dialogService, propertyData, additionalPropertyProcess, popupTitle) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.popupTitle = popupTitle;

		function fillFields() {
			$scope.clientPropertySetter = {
				Id: propertyData.PropertyId,
				Name: propertyData.PropertyName,
				Value: propertyData.InfoValueId ? propertyData.InfoValueId : propertyData.InfoValue,
				cantDelete: propertyData.cantDelete
			};
		}

		function validate() {
			if (!$scope.clientPropertiesForm.$valid) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.VALIDATION_ERROR_MESSAGE);
				return false;
			} else if (!$scope.canCreate && !$scope.formModel.ClientProperty.Property) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.SELECT_PROPERTY_FROM_LIST);
				return false;
			}

			return true;
		}

		$scope.onCancel = function () {
			dialogService.cancel();
		};

		$scope.onConfirm = function () {
			if (!validate()) {
				return;
			}

			const propertyObjectToReturn = {};
			propertyObjectToReturn.Id = propertyData.Id;
			propertyObjectToReturn.PropertyValue = $scope.formModel.ClientProperty.PropertyValue;

			if (!$scope.formModel.ClientProperty.Property) {
				propertyObjectToReturn.Property = {
					Id: null,
					name: $scope.formModel.ClientProperty.PropertyName,
					Type: Vgr.enumerations.additionalProperty.type.FreeText
				};
			} else {
				propertyObjectToReturn.Property = $scope.formModel.ClientProperty.Property;
			}

			propertyObjectToReturn.Property.cantDelete = propertyData.cantDelete;

			dialogService.confirm(propertyObjectToReturn);
		};

		function initializePopup() {
			$scope.formModel = {};

			$scope.additionalPropertyProcess = additionalPropertyProcess;

			$scope.canCreate = additionalPropertyProcess !== Vgr.enumerations.additionalProperty.process.Area;

			$scope.clientPropertySetter = {};
			if (propertyData.Id) {
				fillFields();
			}
		}

		initializePopup();
	}
]);
