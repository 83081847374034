angular.module("vgresiduos").controller("storageListInternalTransferCtrl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `movimentacao-interna/${$stateParams.id}/listagem-geracoes`);
		};
	}
]);
