angular.module("vgresiduos").factory("productV2Service", [
	"$http",
	"accountService",
	function ($http, accountService) {
		"use strict";

		const _listOrganizationProducts = function () {
			const organizationId = accountService.getCurrentClient().organization.id;
			const req = {
				method: "get",
				url:
					Vgr.constants.coreHostUrl +
					Vgr.resources.core.organizationProducts.replace("[ORGANIZATION_ID]", organizationId)
			};
			return $http(req);
		};

		return {
			listOrganizationProducts: _listOrganizationProducts
		};
	}
]);
