angular.module("vgresiduos").controller("AttachmentPopupCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"$window",
	"$state",
	"fileService",
	"dialogService",
	"clientService",
	"attachmentList",
	"attachmentPath",
	"observations",
	"observationsDescription",
	"isEditMode",
	"hasDocumentCreatePermission",
	"supplierId",
	"supplierCode",
	"documentIds",
	"documentsEnabled",
	function (
		$scope,
		$rootScope,
		$q,
		$window,
		$state,
		fileService,
		dialogService,
		clientService,
		attachmentList,
		attachmentPath,
		observations,
		observationsDescription,
		isEditMode,
		hasDocumentCreatePermission,
		supplierId,
		supplierCode,
		documentIds,
		documentsEnabled
	) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.isEditMode = isEditMode;
		$scope.hasDocumentCreatePermission = hasDocumentCreatePermission;
		$scope.observations = observations;
		$scope.observationsDescription = observationsDescription;

		$scope.supplierId = supplierId;
		$scope.supplierCode = supplierCode;
		$scope.documentIds = documentIds;
		$scope.gridOptions = {
			hasPermission: function () {
				return isEditMode;
			}
		};

		const isSupplier = clientService.isSupplier();
		$scope.showDocumentGrid = documentsEnabled && !isSupplier;

		$scope.documentFields = { documentType: null, toggledCreateDocument: false };

		$scope.fileOptions = {
			fileService: "mixed",
			get: function () {
				const deferred = $q.defer();
				const list = attachmentList;
				deferred.resolve(list);
				return deferred.promise;
			},
			update: function (newFiles, deletedIds) {
				const deferred = $q.defer();
				const path = attachmentPath;
				fileService.uploadAll(newFiles, path).then(
					function (response) {
						deferred.resolve(response);
					},
					function (response) {
						deferred.reject(response);
					}
				);
				return deferred.promise;
			},
			fileList: []
		};

		$scope.close = function () {
			if ($scope.fileOptions.blockActions) {
				return;
			}

			dialogService.confirm({
				observations: $scope.observations,
				attachmentList: $scope.fileOptions.fileList,
				documentIds:
					$scope.gridOptions && $scope.gridOptions.selected
						? $scope.gridOptions.selected.map(function (item) {
								return item.id;
						  })
						: []
			});
		};

		$scope.showOrHideNewDoc = function () {
			$scope.documentFields.toggledCreateDocument = !$scope.documentFields.toggledCreateDocument;
		};

		$scope.createDocument = function () {
			if (!$scope.documentFields.documentType) {
				return;
			}
			const url = $state.href("newOrganizationAuthDocument", {
				supplierCode: $scope.supplierCode,
				docType: $scope.documentFields.documentType.id
			});
			$window.open(url, "_blank");
		};
	}
]);
