angular.module("vgresiduos").controller("ResidueTagMultiSelectCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"clientService",
	function ($scope, $rootScope, httpService, clientService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			listResidueTag();
		};

		function listResidueTag() {
			httpService.getListFromServiceCore(clientService.listTags).then(function (resp) {
				resp.list.forEach((element) => {
					element.id = element.id.toString();
					element.displayText = element.name;
				});
				$scope.residueTag = resp.list;
			});
		}

		$scope.onChanged = function (selectedValues) {
			callback(selectedValues);
		};

		function callback(selectedValues) {
			if ($scope.$ctrl.callback) {
				$scope.$ctrl.callback(selectedValues);
			}
		}
	}
]);
