angular.module("componentes").component("informationMessage", {
	controller: function ($scope) {
		const classes = {
			[Vgr.constants.generalStatus.success]: "information-message-success",
			[Vgr.constants.generalStatus.warning]: "information-message-warning",
			[Vgr.constants.generalStatus.error]: "information-message-error",
			[Vgr.constants.generalStatus.info]: "information-message-info",
			[Vgr.constants.generalStatus.accent]: "information-message-accent"
		};

		const textAlignClasses = {
			["left"]: "align-left"
		};

		this.$onInit = function () {
			$scope.colorClass = classes[this.status];
			$scope.textAlignClass = textAlignClasses[this.alignText];
		};
	},
	templateUrl: "views/components/informationMessage/informationMessage_template.html",
	transclude: true,
	bindings: {
		icon: "@",
		cardTitle: "@",
		status: "<",
		tooltipText: "@?",
		textButton: "@?",
		textForClickInTitle: "@?",
		onClick: "&?",
		hideTitleIcon: "@?",
		alignText: "@?"
	}
});
