angular.module("vgresiduos").controller("UserPermissionListCtrl", [
	"$rootScope",
	"$scope",
	"$state",
	"httpService",
	"userService",
	function ($rootScope, $scope, $state, httpService, userService) {
		"use strict";

		this.$onInit = function () {
			$scope.user = null;
			$scope.gridOptions = {
				title: $rootScope.labels.USERS
			};

			$rootScope.contentNavBar = {
				back: function () {
					$state.go("userList");
				},
				breadcrumb: "",
				title: $rootScope.labels.USERS,
				valid: true
			};

			$rootScope.contentNavBarGrid = $scope.gridOptions;

			loadUser();
		};

		function loadUser() {
			httpService.getDTOFromServiceV2(userService.getUser, $state.params.id).then(
				function (user) {
					$scope.user = user;

					$rootScope.contentNavBar.breadcrumb = $rootScope.labels.PROFILES + " - " + user.name;
				},
				function () {
					$rootScope.contentNavBar.breadcrumb = $rootScope.labels.PROFILES + " - " + $state.params.id;
				}
			);
		}
	}
]);
