angular.module("componentes").controller("LoginOptionsCtrl", [
	"$scope",
	"$rootScope",
	"$state",
	"$stateParams",
	"accountService",
	"organizationService",
	"httpService",
	function ($scope, $rootScope, $state, $stateParams, accountService, organizationService, httpService) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.step = 1;
		$scope.data = {
			idp: null
		};

		$scope.toLoginWithEmail = function () {
			$state.go("login", { showBack: true });
		};

		$scope.toLoginWithSso = function () {
			const idp = getIdentityProvider();
			if (!idp) {
				$scope.step = 2;
				return;
			}

			$scope.loading = true;
			accountService.validateIdentityProvider(idp).then(
				function (result) {
					$scope.loading = false;
					if (result.data) {
						accountService.loginWithFederatedIdentity(idp);
					} else {
						$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.INVALID_SSO_IDENTITY);
					}
				},
				function () {
					$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.UNEXPECTED_LOGIN_ERROR);
					$scope.loading = false;
				}
			);
		};

		$scope.backStep = function () {
			$scope.step = 1;
		};

		function getIdentityProvider() {
			if ($scope.step == 1) {
				return $stateParams.idp;
			}
			return $scope.data.idp;
		}

		this.$onInit = function () {
			if ($stateParams.error) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.UNEXPECTED_LOGIN_ERROR);
			}

			if ($stateParams.appLogin) {
				accountService.setAppLogin(true);
			}

			$scope.loading = true;
			httpService.getDTOFromServiceV2(organizationService.isOrganizationOnlySSO, getIdentityProvider(), true).then(
				function (data) {
					$scope.emailInviteEnabled = !data;
					$scope.loading = false;
				},
				function () {
					$scope.EmailInviteEnabled = true;
					$scope.loading = false;
				}
			);
		};
	}
]);
