angular.module("vgresiduos").controller("residuesManagementCtrl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `gestao-residuos`, [{ key: "tab", value: $stateParams.tab }]);
		};
	}
]);
