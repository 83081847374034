angular.module("vgresiduos").controller("AdditionalPropertiesFormCtrl", [
	"$scope",
	"additionalPropertyUtilService",
	function ($scope, additionalPropertyUtilService) {
		"use strict";

		$scope.canHaveMandatoryResidue = false;

		function calculateCanHaveMandatoryResidue(process) {
			$scope.canHaveMandatoryResidue = additionalPropertyUtilService.setMandatoryResidueToAdditionalProperty(process);
		}

		this.$onInit = function () {
			calculateCanHaveMandatoryResidue($scope.ctrl.model.process);
		};
	}
]);
