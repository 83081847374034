angular.module("vgresiduos").directive("disposalCostsForm", function () {
	return {
		scope: {},
		controller: "DisposalCostsFormCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/destination/steps/disposalCosts/disposalCostsForm_template.html",
		bindToController: {
			disposalModel: "=",
			formEvents: "<?",
			hasPermission: "="
		}
	};
});
