angular.module("vgresiduos").directive("areaForm", function () {
	return {
		scope: true,
		controller: "AreaFormCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/onboarding/areas/form/areaForm_template.html",
		bindToController: {
			model: "=",
			saveOptions: "="
		}
	};
});
