angular.module("vgresiduos").controller("CustomizedFilterCtrl", [
	"$rootScope",
	"$scope",
	"reportUtilService",
	"$timeout",
	function ($rootScope, $scope, reportUtilService, $timeout) {
		"use strict";

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;
			$scope.showFilters = true;
			if ($scope.ctrl.preFilterOptions) {
				$scope.showFilters = false;
			} else {
				$scope.showFilters = true;
			}

			if (
				$scope.ctrl.preFilterOptions &&
				$scope.ctrl.preFilterOptions.options &&
				$scope.ctrl.preFilterOptions.options.length == 1
			) {
				$scope.showFilters = true;
				$scope.preFilterOptionsToggleEnabled = true;
				$scope.ctrl.preFilterOptions.selected = $scope.ctrl.preFilterOptions.options[0].value;
			}
		};

		$scope.applyFilters = function () {
			if (validFilters()) {
				$scope.ctrl.hasActiveFilters = hasActiveFilters();

				const promise = $scope.ctrl.applyFilters($scope.ctrl.filterOptions, getFormattedFilters());
				if (promise) {
					promise.then(function () {
						if ($scope.ctrl.showMinimizeButton) {
							$scope.showFilters = false;
						}
						$scope.hasAppliedFilter = true;
					});
				}
			}
		};

		function hasActiveFilters() {
			return (
				$scope.ctrl.filterOptions &&
				Array.isArray($scope.ctrl.filterOptions) &&
				$scope.ctrl.filterOptions.filter(function (option) {
					return option.field;
				}).length > 0
			);
		}

		function getFormattedFilters() {
			const formattedFilters = reportUtilService.formatFilterToDto($scope.ctrl.filterOptions);
			return formattedFilters;
		}

		function validFilters() {
			const filters = $scope.ctrl.filterOptions;
			for (let i = 0; i < filters.length; ++i) {
				if (!validOption(filters[i])) {
					$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.MANDATORY_FILL_ALL_FILTERS);
					return false;
				}
			}
			return true;
		}

		function validOption(filter) {
			if (filter.field) {
				if (!filter.value) {
					return false;
				} else if (Array.isArray(filter.value) && filter.value.length == 0) {
					return false;
				} else if (typeof filter.value === "object") {
					if (filter.field.valueField) {
						return filter.value[filter.field.valueField] != null;
					} else {
						for (const property in filter.value) {
							if (property != "dateId" && !filter.value[property]) {
								return false;
							}
						}
					}
				}
			}

			return true;
		}

		$scope.cleanFilters = function () {
			resetFilters();
			if ($scope.ctrl.onCleanFilters) {
				$scope.ctrl.onCleanFilters();
			}
		};

		function addNewFilterOption() {
			$scope.addNewOption = true;
			$timeout(function () {
				$scope.addNewOption = false;
			});
		}

		$scope.toggleFilters = function () {
			if ($scope.ctrl.showMinimizeButton && $scope.hasAppliedFilter && !$scope.ctrl.preFilterOptions) {
				$scope.showFilters = !$scope.showFilters;
			}
		};

		$scope.togglePreFilterOptions = function () {
			if ($scope.ctrl.preFilterOptions && $scope.hasAppliedFilter && $scope.preFilterOptionsToggleEnabled) {
				$scope.showFilters = !$scope.showFilters;
			}
		};

		$scope.preFilterOptionsClick = function (option) {
			const anySelected = $scope.ctrl.preFilterOptions.options.some((o) => o.selected);
			if (!anySelected) {
				$scope.hasAppliedFilter = false;
				$scope.preFilterOptionsToggleEnabled = false;
				$scope.showFilters = false;
			} else {
				$scope.preFilterOptionsToggleEnabled = true;
				$scope.showFilters = true;
			}

			if (option.onChange) {
				option.onChange();
			}
		};

		const availableFieldsWatch = $scope.$watch("ctrl.availableFields", function (newValue, oldValue) {
			resetFilterIfAvailableFieldsChanged(newValue, oldValue);
		});

		const availableFieldsLengthWatch = $scope.$watch("ctrl.availableFields.length", function (newValue, oldValue) {
			resetFilterIfAvailableFieldsChanged(newValue, oldValue);
		});

		function resetFilterIfAvailableFieldsChanged(newValue, oldValue) {
			if (newValue != null && oldValue != null && newValue != oldValue) {
				resetFilters();
			}
		}

		function resetFilters() {
			$scope.ctrl.filterOptions.length = 0;
			addNewFilterOption();
			$scope.ctrl.hasActiveFilters = false;
		}

		$scope.onPreFilterSelected = function (option) {
			if (option) {
				$scope.showFilters = true;
				$scope.preFilterOptionsToggleEnabled = true;
			} else {
				$scope.showFilters = false;
				$scope.preFilterOptionsToggleEnabled = false;
				$scope.hasAppliedFilter = false;
			}

			if ($scope.ctrl.preFilterOptions.onChange) {
				$scope.ctrl.preFilterOptions.onChange(option);
			}
		};

		this.$onDestroy = function () {
			// disable the listener
			availableFieldsLengthWatch();
			availableFieldsWatch();

			// nullify the DOM-bound model
			$scope.domElement = null;
		};
	}
]);
