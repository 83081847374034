angular.module("vgresiduos").directive("disposalReceivingResponsible", function () {
	return {
		scope: true,
		controller: "DisposalReceivingResponsibleCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/destination/steps/disposalReceivingResponsible/disposalReceivingResponsible_template.html",
		bindToController: {
			receivingDate: "=",
			responsible: "=",
			showResponsible: "=?"
		}
	};
});
