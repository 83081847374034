angular.module("vgresiduos").controller("CnpjCpfTextFieldCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		this.$onInit = function () {
			$scope.label = $scope.ctrl.label || $rootScope.labels.CNPJ_CPF_ONLY_NUMBERS;
			if ($scope.ctrl.isRequired) {
				$scope.label += "*";
			}
		};
	}
]);
