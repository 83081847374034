angular.module("vgresiduos").factory("systemReportService", [
	"$http",
	function ($http) {
		"use strict";

		var _listInformations = function (reportType) {
			var parameters = "";

			if (reportType) {
				parameters = "?reportType=" + reportType;
			}

			var req = {
				method: "get",
				url: Vgr.constants.biHostUrl + Vgr.resources.bi.systemReports + parameters
			};

			return $http(req);
		};

		var _getInformation = function (informationId) {
			var req = {
				method: "get",
				url: Vgr.constants.biHostUrl + Vgr.resources.bi.systemReports + "/" + informationId
			};

			return $http(req);
		};

		return {
			listInformations: _listInformations,
			getInformation: _getInformation
		};
	}
]);
