angular.module("vgresiduos").directive("opportunities", function () {
	"use strict";

	return {
		scope: {},
		controller: "OpportunitiesCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/dashboard/components/opportunities/opportunities_template.html",
		bindToController: {
			options: "="
		}
	};
});
