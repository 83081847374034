angular.module("vgresiduos").factory("localStorageWithTimeoutService", [
	"localStorageService",
	"$rootScope",
	function (localStorageService, $rootScope) {
		"use strict";

		const _setInCacheWithExpiry = function (key, value, timeoutInMinutes, eventToRefresh) {
			const now = new Date();
			const item = {
				value: value,
				expiry: now.getTime() + timeoutInMinutes * 1000 * 60,
				event: eventToRefresh
			};
			localStorageService.set(key, JSON.stringify(item));
		};

		const _getFromCacheWithExpiry = function (key, eventToRefresh) {
			const itemStr = localStorageService.get(key);
			if (!itemStr) {
				if (eventToRefresh) {
					$rootScope.$broadcast(eventToRefresh);
				}
				return null;
			}
			const item = JSON.parse(itemStr);
			const now = new Date();
			if (now.getTime() > item.expiry) {
				if (item.event) {
					$rootScope.$broadcast(item.event);
				}
			}
			return item.value;
		};

		const _getFromCacheWithAutoExpiry = function (key) {
			const itemStr = localStorageService.get(key);
			if (!itemStr) {
				return null;
			}
			const item = JSON.parse(itemStr);
			const now = new Date();
			if (now.getTime() > item.expiry) {
				localStorageService.remove(key);
				return null;
			}
			return item.value;
		};

		return {
			setInCacheWithExpiry: _setInCacheWithExpiry,
			getFromCacheWithExpiry: _getFromCacheWithExpiry,
			getFromCacheWithAutoExpiry: _getFromCacheWithAutoExpiry
		};
	}
]);
