angular.module("vgresiduos").controller("CountrySelectFieldCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"locationService",
	function ($scope, $rootScope, httpService, locationService) {
		"use strict";
		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			loadCountries();
		};

		function loadCountries() {
			if ($scope.Countries) {
				return;
			}
			httpService
				.getListFromServiceCore(locationService.listCountries, { onlyAddressEnabled: $scope.$ctrl.addressEnabled })
				.then((response) => {
					$scope.Countries = response.list;
				});
		}

		$scope.onChange = function (value) {
			if ($scope.$ctrl.onChange) {
				$scope.$ctrl.onChange(value);
			}
		};
	}
]);
