angular.module("vgresiduos").controller("DisposalPendenciesControlCtrl", [
	"$rootScope",
	"$scope",
	"disposalPreferenceService",
	function ($rootScope, $scope, disposalPreferenceService) {
		"use strict";

		let alreadySetManually = false;

		const onDisposalResiduesUpdated = $rootScope.$on(
			Vgr.constants.evtDisposalResiduesUpdated,
			function (event, disposalId) {
				if (!disposalId || $scope.destinationModel.Id == disposalId) {
					loadClientPreferencesPendencies();
				}
			}
		);

		$scope.$on("$destroy", function () {
			// disable the listener
			onDisposalResiduesUpdated();

			// nullify the DOM-bound model
			$scope.domElement = null;
		});

		this.$onInit = function () {
			configurePendencies();
		};

		$scope.onClick = function () {
			alreadySetManually = true;
		};

		function configurePendencies() {
			for (const i in $scope.PendencyTypes) {
				$scope.destinationModel.Pendencies.push({
					Type: $scope.PendencyTypes[i].Type,
					Description: $scope.labels.PENDENCIES_CONTROL_PENDENCY_DESCRIPTION[$scope.PendencyTypes[i].Type],
					IsChecked: false
				});
			}
		}

		function loadClientPreferencesPendencies() {
			if (alreadySetManually) {
				return;
			}

			if ($scope.disposalPreferences) {
				buildClientPreferencesModel();
				return;
			}

			disposalPreferenceService.getDisposalPreferences().then(function (disposalPreferences) {
				$scope.disposalPreferences = disposalPreferences;
				buildClientPreferencesModel();
			});
		}

		function buildClientPreferencesModel() {
			for (const pendency of $scope.destinationModel.Pendencies) {
				pendency.IsChecked = hasToGeneratePendency(pendency.Type);
			}
		}

		function hasToGeneratePendency(pendencyType) {
			for (const residue of $scope.destinationModel.Residues) {
				const preference = $scope.disposalPreferences.pendencies.find(
					(preferencePendency) =>
						preferencePendency.type == pendencyType && preferencePendency.isDangerousResidue == residue.IsDangerous
				);
				if (preference) {
					return true;
				}
			}

			return false;
		}
	}
]);
