angular.module("vgresiduos").component("clientRecipient", {
	controller: "ClientRecipientCtrl",
	templateUrl: "views/components/fields/clientRecipient/clientRecipient_template.html",
	bindings: {
		emptyField: "<?",
		setterId: "<?",
		setterCode: "<?",
		identifier: "<?",
		model: "=",
		isDisabled: "<?",
		onClientRecipientChanged: "<?",
		recipientPercentualEfficiency: "<?",
		hideRecipientPercentualEfficiency: "=?"
	}
});
