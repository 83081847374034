angular.module("vgresiduos").controller("ActionPlanDashboardStatusCtrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";

		this.$onInit = function () {
			var statusActionPlan = {
				[Vgr.enumerations.actionPlan.Status.Finalized]: $rootScope.labels.FINALIZED,
				[Vgr.enumerations.actionPlan.Status.Created]: $rootScope.labels.ACTIVE,
				[Vgr.enumerations.actionPlan.Status.Pending]: $rootScope.labels.ACTIVE
			};

			if ($scope.actionPlan) $scope.actionPlan.statusDescription = statusActionPlan[$scope.ctrl.actionPlan.status];
		};
	}
]);
