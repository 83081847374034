angular.module("vgresiduos").controller("SupplierListCtrl", [
	"$scope",
	"$rootScope",
	"$state",
	"$stateParams",
	"localStorageService",
	"dialogService",
	"engagementScoreService",
	"userAccessService",
	function (
		$scope,
		$rootScope,
		$state,
		$stateParams,
		localStorageService,
		dialogService,
		engagementScoreService,
		userAccessService
	) {
		"use strict";

		const hasComplianceModuleAccess = userAccessService.hasAccess(
			null,
			Vgr.constants.featureToggles.permission.complianceModule
		);

		this.$onInit = function () {
			buildNewFeaturesPopups();
			Vgr.util.redirectSingleton($rootScope, `organizacao/fornecedores`, getQueries());
		};

		function getQueries() {
			const queries = [];
			if ($stateParams.pendencia && $stateParams.unidade) {
				queries.push({ key: "filterPendencyId", value: $stateParams.pendencia });
				queries.push({ key: "filterOrganizationUnitId", value: $stateParams.unidade });
			}
			if ($stateParams.vincular) {
				queries.push({ key: "linkUnitsToSupplier", value: true });
			}
			return queries;
		}

		function buildNewFeaturesPopups() {
			const reportData = buildCustomReportNewFeaturesPopup();
			if (reportData) {
				openNewFeaturesPopups(reportData);
			}
		}

		function buildCustomReportNewFeaturesPopup() {
			if (!localStorageService.get(Vgr.enumerations.newFeaturesKey.categorization) && !hasComplianceModuleAccess) {
				return buildCategorizationPopup();
			}
		}

		function buildCategorizationPopup() {
			return {
				key: Vgr.enumerations.newFeaturesKey.categorization,
				imageLink: "/img/generalTopAlerts/categorization.svg",
				isManualAccess: true,
				isManualAccessKnowMoreBelow: false,
				knowMore: function () {
					trackEventAction(Vgr.trackings.supplier.categorization.accessPopupManual);
					const url = Vgr.constants.zendesk.baseUrl + Vgr.constants.zendesk.guides.categorization;
					window.open(url, "_blank");
				},
				buttons: [
					{
						label: $scope.labels.CLOSE,
						class: "md-primary button-large",
						click: function () {
							trackEventAction(Vgr.trackings.supplier.categorization.closePopupNewFeature);
							dialogService.confirm();
						}
					},
					{
						label: $scope.labels.GENERAL_TOP_ALERTS.CREATE,
						class: "md-primary md-raised button-large",
						click: function () {
							dialogService.confirm();
							$state.go("listSupplierProfile");
						}
					}
				]
			};
		}

		function openNewFeaturesPopups(reportData) {
			const disablePopup = localStorageService.get(reportData.key);
			if (disablePopup || reportData == null) {
				return;
			}
			dialogService
				.showDialogFromTemplateV2(
					"views/components/generalTopAlerts/newFeatures/newFeaturesPopup_template.html",
					"NewFeaturesPopupCtrl",
					".vgr-page",
					{ popupModel: reportData },
					true
				)
				.then(
					function () {
						localStorageService.set(reportData.key, true);
					},
					function () {
						localStorageService.set(reportData.key, true);
					}
				);
		}

		function trackEventAction(event) {
			engagementScoreService.track(event);
		}
	}
]);
