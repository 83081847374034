angular.module("vgresiduos").controller("LoginStatusCheckerCtrl", [
	"$scope",
	"$rootScope",
	"accountService",
	"$timeout",
	"$state",
	function ($scope, $rootScope, accountService, $timeout, $state) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.tick = function () {
			if (!accountService.isLoggedInIdp()) {
				$timeout($scope.tick, 1000);
			} else {
				$state.go("afterLoginIdentityProvider");
			}
		};

		$scope.tick();
	}
]);
