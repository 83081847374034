angular.module("vgresiduos").directive("customizedFilterValue", function () {
	return {
		scope: true,
		controller: "CustomizedFilterValueCtrl",
		controllerAs: "ctrl",
		templateUrl:
			"views/components/customizedFilter/baseComponents/customizedFilterValue/customizedFilterValue_template.html",
		bindToController: {
			type: "@",
			key: "=?",
			displayProperty: "=?",
			list: "=?",
			loadList: "=?",
			listOptions: "=?",
			selectedValue: "=",
			setterValue: "=?",
			option: "=?",
			searchLabel: "=?",
			isDisabled: "=",
			customLabel: "@?",
			autofocus: "@?"
		}
	};
});
