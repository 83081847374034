angular.module("vgresiduos").controller("ResidueReportLineChartCtrl", [
	"$scope",
	"clientService",
	function ($scope, clientService) {
		"use strict";

		$scope.originalSeriesLabels = [];
		$scope.chart = "";

		var yAxisDecimals;

		this.$onInit = function () {
			$scope.chartOptions = {
				legend: {
					display: $scope.$ctrl.forceHideLegend ? false : true,
					position: "top",
					fullWidth: true
				},
				tooltips: {
					callbacks: {
						label: formatNumberTooltip
					}
				}
			};
			yAxisDecimals = $scope.$ctrl.yAxisDecimals ? $scope.$ctrl.yAxisDecimals : clientService.getDecimalPlaces();
			loadChart();
		};

		function loadChart() {
			$scope.labels = $scope.$ctrl.headers.Intervals;
			$scope.firstDataQualifier = $scope.$ctrl.concatenateFirstDataQualifier ? getFirstDataQualifierFromData() : null;
			$scope.data = getIntervalsFromData();
			$scope.series = getChartSeriesFromDataGroupings();
		}

		function getFirstDataQualifierFromData() {
			return $scope.$ctrl.data[0].DataQualifiers[0];
		}

		function getIntervalsFromData() {
			let intervals = [];
			for (let row of $scope.$ctrl.data) {
				for (let index = 0; index < row.Intervals.length; index++) {
					if (!row.Intervals[index]) {
						row.Intervals[index] = 0;
					}
				}

				intervals.push(row.Intervals);
			}
			return intervals;
		}

		function getChartSeriesFromDataGroupings() {
			let series = [];
			for (let row of $scope.$ctrl.data) {
				series.push(Vgr.util.cut(row.Groups.join(", "), true, 40));
				$scope.originalSeriesLabels.push(row.Groups.join(", "));
			}
			return series;
		}

		function formatNumberTooltip(tooltipItem, data) {
			let dataset = data.datasets[tooltipItem.datasetIndex];
			let value = dataset.data ? dataset.data[tooltipItem.index] : "";

			let label = yAxisFormat(value);
			if ($scope.firstDataQualifier) {
				label += $scope.firstDataQualifier;
			}

			label += " - " + $scope.originalSeriesLabels[tooltipItem.datasetIndex];

			return label;
		}

		function yAxisFormat(value) {
			return Vgr.util.formatUnitDecimalPlaces(value, yAxisDecimals);
		}
	}
]);
