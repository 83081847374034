angular.module("vgresiduos").controller("ProcessStepListCtrl", [
	"$stateParams",
	"$rootScope",
	function ($stateParams, $rootScope) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `unidades/etapas-processo`);
		};
	}
]);
