angular.module("vgresiduos").controller("RootCtrl", [
	"$scope",
	"$rootScope",
	"$state",
	"translationService",
	"engagementScoreService",
	"hotjarService",
	"accountService",
	"featureToggleService",
	"permissionService",
	function (
		$scope,
		$rootScope,
		$state,
		translationService,
		engagementScoreService,
		hotjarService,
		accountService,
		featureToggleService,
		permissionService
	) {
		"use strict";

		$rootScope.labels = null;
		$rootScope.dbMap = null;
		$rootScope.webApp = {
			id: 2
		};

		$scope.observableUserChange$ = undefined;

		$scope.init = function () {
			translationService.initialize();
			$rootScope.dbMap = {};

			$scope.state = $state;

			if (accountService.isLoggedIn()) {
				engagementScoreService.identify();
				hotjarService.identify();

				accountService.reloadLoggedAccount();
				permissionService.loadUserPermissions(accountService.getLoggedAccount().id);
				monitorUserChange(true);
				monitorOrganization(true);
			}
		};

		$scope.isLoggedIn = function () {
			return accountService.isLoggedIn();
		};

		const watchChangeLoginStatus = $rootScope.$on(Vgr.constants.evtChangeLoginStatus, function (event, isLogged) {
			monitorUserChange(isLogged);
		});

		const watchChangeLoggedClient = $rootScope.$on(Vgr.constants.evtChangeLoggedClient, function () {
			engagementScoreService.identify();
			hotjarService.identify();
			monitorOrganization(true);
		});

		const watchFeatureToggleCache = $rootScope.$on(Vgr.constants.events.cache.featureToggle, function () {
			const currentClient = accountService.getCurrentClient();
			const currentUser = accountService.getLoggedAccount();
			if (currentClient && currentUser) {
				featureToggleService.loadFeatureToggles(currentClient.organization.id, currentClient.id, currentUser.code);
			}
		});

		const watchUserPermissionCache = $rootScope.$on(Vgr.constants.events.cache.userPermission, function () {
			const loggedAccount = accountService.getLoggedAccount();
			if (loggedAccount) {
				permissionService.loadUserPermissions(loggedAccount.id);
			}
		});

		const watchUserCache = $rootScope.$on(Vgr.constants.events.cache.user, function () {
			accountService.reloadLoggedAccount();
		});

		//------------------appsync user------------------//
		function monitorUserChange(isLogged) {
			if (isLogged) {
				subscribeChannelToListenLoggedUser();
			} else {
				unsubscribeChannelToListenLoggedUser();
			}
		}

		function subscribeChannelToListenLoggedUser() {
			$scope.observableUserChange$ = Vgr.appSyncUtil.subscribeToChannelToListen(
				getChannelNameToListenLoggedUser,
				callBackListenLoggedUserChannelSuccess
			);
		}

		function getChannelNameToListenLoggedUser() {
			return Vgr.appSyncUtil.channels.user.replace("[USER_CODE]", accountService.getLoggedAccount().code);
		}

		function callBackListenLoggedUserChannelSuccess(payload) {
			const data = JSON.parse(payload.value.data.subscribe2channel.data);

			if (data.action == Vgr.appSyncUtil.userChannel.action.userChanged) {
				$rootScope.$broadcast(Vgr.constants.events.cache.user);
			} else if (data.action == Vgr.appSyncUtil.userChannel.action.permissionChanged) {
				$rootScope.$broadcast(Vgr.constants.events.cache.userPermission);
			}
		}

		function unsubscribeChannelToListenLoggedUser() {
			if ($scope.observableUserChange$) {
				$scope.observableUserChange$.unsubscribe();
			}
		}
		//------------------------------------------------//

		//--------------appsync organization--------------//

		function monitorOrganization(isLogged) {
			unsubscribeChannelToListenOrganization();
			const currentOrganizationUnit = accountService.getCurrentClient();
			if (isLogged && currentOrganizationUnit && currentOrganizationUnit.organization) {
				subscribeChannelToListenOrganization();
			}
		}

		function subscribeChannelToListenOrganization() {
			$scope.observableOrganization$ = Vgr.appSyncUtil.subscribeToChannelToListen(
				getChannelNameToListenOrganization,
				callBackListenOrganizationChannelSuccess
			);
		}

		function getChannelNameToListenOrganization() {
			return Vgr.appSyncUtil.channels.organization.replace(
				"[ORGANIZATION_CODE]",
				accountService.getCurrentClient().organization.id
			);
		}

		function callBackListenOrganizationChannelSuccess(payload) {
			const data = JSON.parse(payload.value.data.subscribe2channel.data);

			if (data.action == Vgr.appSyncUtil.organizationChannel.action.featureToggleChanged) {
				$rootScope.$broadcast(Vgr.constants.events.cache.featureToggle);
			}
		}

		function unsubscribeChannelToListenOrganization() {
			if ($scope.observableOrganization$) {
				$scope.observableOrganization$.unsubscribe();
			}
		}

		//------------------------------------------------//

		$scope.$on("$destroy", function () {
			// disable the listener
			watchChangeLoginStatus();
			watchChangeLoggedClient();
			watchFeatureToggleCache();
			watchUserPermissionCache();
			watchUserCache();
			unsubscribeChannelToListenLoggedUser();
			unsubscribeChannelToListenOrganization();

			// nullify the DOM-bound model
			$scope.domElement = null;
		});

		$scope.init();
	}
]);
