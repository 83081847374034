angular.module("vgresiduos").controller("DisposalAdditionalInformationCtrl", [
	"$rootScope",
	"$scope",
	"$q",
	"httpService",
	"additionalPropertyUtilService",
	"additionalPropertyService",
	function ($rootScope, $scope, $q, httpService, additionalPropertyUtilService, additionalPropertyService) {
		"use strict";

		var isToBlockEdition = !$scope.editPermission || $scope.isToBlockFieldsBasedOnDisposalDate;

		$scope.processId = Vgr.enumerations.additionalProperty.process.Disposal;

		var disposalProperties = [];
		var isListLoaded = false;

		function loadDisposalProperties() {
			if (isListLoaded) {
				return $q.resolve();
			}

			var deferred = $q.defer();

			var dto = {
				process: $scope.processId
			};

			httpService.getListFromServiceCore(additionalPropertyService.listClientProperties, dto).then(
				function (clientProperties) {
					disposalProperties = clientProperties.list;
					isListLoaded = true;
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		$scope.getEditPermission = function () {
			return !isToBlockEdition;
		};

		$scope.$watch("destinationModel.Informations.length", function (newVal, oldVal) {
			const step = $scope.destinationConfig.currentStage.Steps.find(
				(item) => item.Index == $scope.disposalSolicitationSteps.AdditionalInformations.Index
			).Id;
			if (newVal > 0 && areAllInformationsWithValue()) {
				$scope.updateSlip(step, "Ok");
			} else {
				$scope.updateSlip(step, null);
			}
		});

		function areAllInformationsWithValue() {
			return $scope.destinationModel.Informations.find((info) => !info.InfoValue && !info.InfoValueId) == null;
		}

		var onDisposalResiduesUpdated = $rootScope.$on(
			Vgr.constants.evtDisposalResiduesUpdated,
			function (_event, disposalId) {
				if (!disposalId || $scope.destinationModel.Id == disposalId) {
					loadDisposalProperties().then(setMandatoryProperties);
				}
			}
		);

		$scope.$on("$destroy", function () {
			onDisposalResiduesUpdated();
		});

		function setMandatoryProperties() {
			var mandatoryProperties = getMandatoryProperties();

			if (mandatoryProperties && mandatoryProperties.length) {
				for (var info of $scope.destinationModel.Informations) {
					var cantDelete = mandatoryProperties.find((p) => p.id == info.PropertyId) != undefined;
					info.cantDelete = cantDelete;

					if (cantDelete) {
						mandatoryProperties = mandatoryProperties.filter((p) => p.id != info.PropertyId);
					}
				}

				if (!hasChangedResiduesFromReadonlyModel()) {
					return;
				}

				const propertiesFormated = mandatoryProperties.map(function (property) {
					if (!$scope.destinationModel.Informations.find((p) => p.PropertyId == property.id)) {
						return additionalPropertyUtilService.buildInformationToGridFromApiCoreAdditionalProperty(property, true);
					}
				});

				if (propertiesFormated) {
					$scope.destinationModel.Informations = [...$scope.destinationModel.Informations, ...propertiesFormated];
				}
			} else {
				$scope.destinationModel.Informations.forEach((i) => {
					i.cantDelete = false;
				});
			}
		}

		function getMandatoryProperties() {
			return disposalProperties.filter((dp) =>
				$scope.destinationModel.Residues.find((r) => dp.residueIds.find((id) => id == r.ResidueOrganizationId))
			);
		}

		function hasChangedResiduesFromReadonlyModel() {
			if (!$scope.readOnlyModel || !$scope.readOnlyModel.Residues) {
				return true;
			}

			if ($scope.readOnlyModel.Residues.length != $scope.destinationModel.Residues.length) {
				return true;
			}

			var readonlyModelResidueIds = $scope.readOnlyModel.Residues.map((r) => r.Id).sort();
			var disposalModelResidueIds = $scope.destinationModel.Residues.map((r) => r.Id).sort();

			return JSON.stringify(readonlyModelResidueIds) != JSON.stringify(disposalModelResidueIds);
		}
	}
]);
