angular.module("vgresiduos").component("organizationUnitsMultiSelect", {
	controller: "OrganizationUnitsMultiSelectCtrl",
	templateUrl: "views/components/multiSelects/organizationUnitsMultiSelect/organizationUnitsMultiSelect_template.html",
	bindings: {
		setter: "<?",
		callback: "<",

		clear: "<?"
	}
});
