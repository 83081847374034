angular.module("vgresiduos").component("clientPropertySelectField", {
	controller: "ClientPropertySelectFieldCtrl",
	templateUrl: "views/components/fields/clientPropertySelectField/clientPropertySelectField_template.html",
	bindings: {
		processType: "<",

		required: "<",

		setter: "<?",
		callback: "<"
	}
});
