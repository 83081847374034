angular.module("vgresiduos").controller("AddPermissionToFeatureTogglePopupCtrl", [
	"$scope",
	"$rootScope",
	"dialogService",
	"httpService",
	"organizationService",
	"clientService",
	"$q",
	"featureToggleService",
	"featureToggleId",
	function (
		$scope,
		$rootScope,
		dialogService,
		httpService,
		organizationService,
		clientService,
		$q,
		featureToggleService,
		featureToggleId
	) {
		"use strict";

		$scope.labels = $rootScope.labels;

		function initializePopup() {
			$scope.popupTitle = $scope.labels.ADD_FEATURE_TOGGLE_PERMISSION;
			$scope.model = {};

			$scope.popupActionButtons = [
				{
					label: $scope.labels.CANCEL,
					class: "md-primary",
					click: function () {
						dialogService.cancel();
					}
				},
				{
					label: $scope.labels.ADD,
					class: "md-primary md-raised",
					click: function () {
						if (!validate()) {
							$rootScope.$broadcast(
								Vgr.constants.evtShowErrorMessage,
								$rootScope.labels.FEATURE_TOGGLE_PERMISSION_ERROR
							);
							return;
						}

						createFeatureTogglePermission().then(function (id) {
							$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $rootScope.labels.SAVE_SUCCESS_MESSAGE);
							dialogService.confirm({
								id: id,
								organization: $scope.model.organization
									? $scope.model.organization
									: $scope.model.organizationUnit.organization,
								organizationUnit: $scope.model.organizationUnit
							});
						});
					}
				}
			];

			$scope.organizationSubfieldList = [
				{
					item: "contractTypeDescription",
					label: $scope.labels.CONTRACT
				}
			];

			$scope.organizationUnitSubfieldList = [
				{
					item: "organizationDescription",
					label: $scope.labels.ORGANIZATION
				},
				{
					item: "contractTypeDescription",
					label: $scope.labels.CONTRACT
				}
			];
		}

		$scope.organizationsList = [];
		$scope.organizationUnitsPaginationOptions = {
			pageSize: 20,
			pageNumber: 1,
			query: ""
		};
		$scope.organizationUnitsList = [];
		$scope.organizationsPaginationOptions = {
			pageSize: 50,
			pageNumber: 1,
			query: ""
		};

		$scope.listOrganizations = function () {
			const deferred = $q.defer();

			if (!$scope.organizationsPaginationOptions.query) {
				$scope.organizationsPaginationOptions.query = "";
			}

			const params = {
				skip: $scope.organizationsPaginationOptions.pageNumber - 1,
				take: $scope.organizationsPaginationOptions.pageSize,
				query: $scope.organizationsPaginationOptions.query,
				contractTypes: [
					Vgr.enumerations.organization.contractType.Payer,
					Vgr.enumerations.organization.contractType.Trial
				]
			};

			httpService.getDTOFromServiceV2(organizationService.listMasterOrganizations, params).then(
				function (list) {
					for (const organization of list) {
						organization.organizationDescription = `${organization.id} - ${organization.name}`;
						organization.contractTypeDescription =
							$rootScope.labels.ORGANIZATION_PAYMENT_PLAN_ENUM[organization.contractType];
					}

					deferred.resolve(list);
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		};

		$scope.listOrganizationsUnit = function () {
			const deferred = $q.defer();

			const dto = {
				query: $scope.organizationUnitsPaginationOptions.query,
				skip: $scope.organizationUnitsPaginationOptions.pageNumber - 1,
				take: $scope.organizationUnitsPaginationOptions.pageSize,
				organizationId: $scope.model.organization ? $scope.model.organization.id : null,
				contractTypes: [
					Vgr.enumerations.organization.contractType.Payer,
					Vgr.enumerations.organization.contractType.Trial
				]
			};

			httpService.getListFromServiceCore(clientService.listMasterClients, dto).then(
				function (response) {
					for (const organizationUnit of response.list) {
						organizationUnit.organizationUnitDescription = `${organizationUnit.code} - ${organizationUnit.name}`;
						organizationUnit.organizationDescription = `${organizationUnit.organization.id} - ${organizationUnit.organization.name}`;
						organizationUnit.contractTypeDescription =
							$rootScope.labels.ORGANIZATION_PAYMENT_PLAN_ENUM[organizationUnit.organization.contractType];
					}
					deferred.resolve(response.list);
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		};

		function validate() {
			return !!$scope.model.organization || !!$scope.model.organizationUnit;
		}

		function createFeatureTogglePermission() {
			const deferred = $q.defer();
			const dto = {
				featureToggleId: featureToggleId,
				organizationId: $scope.model.organization ? $scope.model.organization.id : null,
				organizationUnitId: $scope.model.organizationUnit ? $scope.model.organizationUnit.id : null
			};

			httpService.postDTOToServiceV2(featureToggleService.createFeatureTogglePermission, dto).then(
				function (data) {
					$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $rootScope.labels.SAVE_SUCCESS_MESSAGE);
					deferred.resolve(data.id);
				},
				function () {
					deferred.reject();
				}
			);
			return deferred.promise;
		}

		initializePopup();
	}
]);
