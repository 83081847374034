angular.module("vgresiduos").controller("InventoryReportCtrl", [
	"$rootScope",
	"$scope",
	"conama313Service",
	function ($rootScope, $scope, conama313Service) {
		"use strict";

		this.$onInit = function () {
			$scope.filter = {
				format: "PDF"
			};

			$scope.reportOptions = {
				title: $rootScope.labels.CONAMA_313_INVENTORY_REPORT,
				reload: function () {
					$scope.filter.format = "PDF";
					$scope.reportOptions.promise = conama313Service.generateInventoryReport($scope.filter);
				},
				generateExcel: function () {
					$scope.filter.format = "EXCEL";
					$scope.reportOptions.promise = conama313Service.generateInventoryReport($scope.filter);
				},
				generateWord: function () {
					$scope.filter.format = "WORD";
					$scope.reportOptions.promise = conama313Service.generateInventoryReport($scope.filter);
				}
			};

			loadYearList();
		};

		function loadYearList() {
			$scope.years = conama313Service.getYearReportFilterList();
			$scope.filter.year = $scope.years[0].year;
			$scope.reportOptions.promise = null;
		}
	}
]);
