Vgr.filters = {
	localeOrderBy: function (array, sortPredicate, reverseOrder) {
		if (!Array.isArray(array)) return array;
		if (!sortPredicate) return array;

		var isString = function (value) {
			return typeof value === "string";
		};

		var isNumber = function (value) {
			return typeof value === "number";
		};

		var isBoolean = function (value) {
			return typeof value === "boolean";
		};

		var arrayCopy = [];
		angular.forEach(array, function (item) {
			arrayCopy.push(item);
		});

		arrayCopy.sort(function (a, b) {
			var valueA = a[sortPredicate];
			var valueB = b[sortPredicate];

			if (isString(valueA)) return !reverseOrder ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);

			if (isNumber(valueA) || isBoolean(valueA)) return !reverseOrder ? valueA - valueB : valueB - valueA;

			return 0;
		});

		return arrayCopy;
	},

	filterBy: function (array, query, params, equal, subFields) {
		"use strict";

		if (!Array.isArray(array)) return;

		var parts = null;
		if (Array.isArray(query)) {
			parts = query;
		} else {
			parts = query && query.trim().split(/\s+/);
		}

		var keys = [];
		if (params) keys = params;
		else keys = Object.keys(array[0]);

		if (!parts || !parts.length) return array;

		return array.filter(function (obj) {
			return parts.every(function (part) {
				return keys.some(function (key) {
					return Vgr.filters.criteriaMet(obj, key, query, part, equal, params, subFields);
				});
			});
		});
	},

	filterByDate: function (array, dateFilter, params) {
		"use strict";

		if (!Array.isArray(array)) return;

		var keys = [];
		if (params) keys = params;
		else keys = Object.keys(array[0]);

		return array.filter(function (obj) {
			return keys.some(function (key) {
				return Vgr.filters.dateCriteriaMet(obj, key, dateFilter, params);
			});
		});
	},

	criteriaMet: function (obj, key, query, part, equal, params, subFields) {
		"use strict";

		var array = Vgr.util.getDescendantProp(obj, key, true);
		if (Array.isArray(array)) {
			var path = subFields || [array.path];
			var subArray = Vgr.filters.filterBy(array, query, path);
			return subArray && subArray.length > 0;
		} else if (equal) {
			return Vgr.util.isStringEqual(String(Vgr.util.getDescendantProp(obj, key)), part);
		} else {
			return Vgr.util.isStringIncluded(String(Vgr.util.getDescendantProp(obj, key)), part);
		}
	},

	dateCriteriaMet: function (obj, key, dateFilter, params) {
		"use strict";

		var dateToFilterFrom = moment(Vgr.util.getDescendantProp(obj, key)).toDate();

		if (dateFilter.dateId == 0) {
			return true;
		} else if (dateFilter.dateId == 1) {
			var begMonth = moment().startOf("Month").toDate();
			var endMonth = moment().add(1, "Month").startOf("Month").toDate();

			return dateToFilterFrom >= begMonth && dateToFilterFrom < endMonth;
		} else if (dateFilter.dateId == 2) {
			var begYear = moment().startOf("Year").toDate();
			var endYear = moment().add(1, "Year").startOf("Year").toDate();

			return dateToFilterFrom >= begYear && dateToFilterFrom < endYear;
		} else if (dateFilter.dateId == 3) {
			if (dateFilter.beginDate == null && dateFilter.endDate == null) {
				return true;
			} else if (dateFilter.beginDate != null && dateFilter.endDate == null) {
				var beg = moment(dateFilter.beginDate).toDate();

				return dateToFilterFrom >= beg;
			} else if (dateFilter.beginDate == null && dateFilter.endDate != null) {
				var end = moment(dateFilter.endDate).add(1, "Day").toDate();

				return dateToFilterFrom <= end;
			} else if (dateFilter.beginDate != null && dateFilter.endDate != null) {
				var beg = moment(dateFilter.beginDate).toDate();
				var end = moment(dateFilter.endDate).add(1, "Day").toDate();

				return dateToFilterFrom >= beg && dateToFilterFrom < end;
			}
		}

		return true;
	},

	filterBySome: function (array, query, params, equal) {
		"use strict";

		if (!Array.isArray(array)) return;

		var parts = null;
		if (Array.isArray(query)) {
			parts = query;
		} else {
			parts = query && query.trim().split(/\s+/);
		}
		var keys = [];
		if (params) keys = params;
		else keys = Object.keys(array[0]);

		if (!parts || !parts.length) return array;

		return array.filter(function (obj) {
			return parts.some(function (part) {
				return keys.some(function (key) {
					if (equal) {
						return String(Vgr.util.getDescendantProp(obj, key)).toLowerCase() === part.toLowerCase();
					}
					return String(Vgr.util.getDescendantProp(obj, key)).toLowerCase().indexOf(part.toLowerCase()) > -1;
				});
			});
		});
	},

	pagination: function (input, page, size, serverPagination) {
		"use strict";

		if (serverPagination) {
			return input;
		}
		if (!Array.isArray(input)) return;

		page = parseInt(page, 10);
		size = parseInt(size, 10);

		var start = (page - 1) * size;
		var end = start + size;

		return input.slice(start, end);
	}
};
