angular.module("vgresiduos").controller("DisposalEditionBlockMessageCtrl", [
	"$scope",
	"$rootScope",
	function ($scope) {
		"use strict";

		this.$onInit = function () {
			const key = $scope.ctrl.status ? $scope.ctrl.status : "error";
			$scope.status = Vgr.constants.generalStatus[key];
		};
	}
]);
