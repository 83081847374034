angular.module("vgresiduos").controller("clientResidueTextCompleteCtrl", [
	"$rootScope",
	"$scope",
	"httpService",
	"residueV2Service",
	function ($rootScope, $scope, httpService, residueV2Service) {
		"use strict";

		const labels = $rootScope.labels;
		$scope.residueList = [];

		this.$onInit = function () {
			listResidues();
			$scope.label = labels.RESIDUE;

			$scope.subfieldList = [
				{
					item: "code",
					label: labels.ID
				}
			];
		};

		function listResidues() {
			httpService.getListFromServiceCore(residueV2Service.listOrganizationUnitResidues).then(function (response) {
				$scope.residueList = response.list;
			});
		}
	}
]);
