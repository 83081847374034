angular.module("vgresiduos").controller("ExternalSystemSelectFieldCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"externalSystemService",
	function ($scope, $rootScope, $q, httpService, externalSystemService) {
		"use strict";

		this.$onInit = function () {
			$scope.label = $rootScope.labels.EXTERNAL_SYSTEM_SOURCE + "*";
			loadOptions().then(setExternalSystem);
		};

		function loadOptions() {
			var deferred = $q.defer();

			httpService.getDTOFromServiceV2(externalSystemService.listExternalSystems).then(
				function (content) {
					$scope.list = content.ExternalSystems;
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function setExternalSystem() {
			if (!$scope.$ctrl.setterId) {
				return;
			}

			var foundExternalSystems = $scope.list.find((es) => es.Id == $scope.$ctrl.setterId);
			if (foundExternalSystems != null) {
				$scope.$ctrl.model = foundExternalSystems;
			}
		}

		$scope.onChange = function () {
			if ($scope.$ctrl.onChange) {
				$scope.$ctrl.onChange($scope.$ctrl.model);
			}
		};
	}
]);
