angular.module("vgresiduos").controller("IbamaRappCreateSummaryCtrl", [
	"$rootScope",
	"$scope",
	"$q",
	"httpService",
	"ibamaIntegrationService",
	function ($rootScope, $scope, $q, httpService, ibamaIntegrationService) {
		"use strict";

		var labels = $rootScope.labels;
		var residueGenerationList = [];
		var residueDisposalList = [];
		var transporterList = [];

		this.$onInit = function () {
			var deferred = $q.defer();

			$scope.loadingFinished = false;
			setInformationMessage();
			httpService.getDTOFromServiceV2(ibamaIntegrationService.getSummaryIbamaRapp, getDto()).then(
				function (list) {
					$scope.ibamaRappList = list;

					formatResidueGenerationList(list);
					formatResidueDisposalList(list);
					formatTransporterList(list);

					$scope.loadingFinished = true;
					deferred.resolve($scope.ibamaRappList);
				},
				function () {
					$scope.loadingFinished = true;
					deferred.reject();
				}
			);
		};

		function getDto() {
			return {
				Year: $scope.ctrl.model.year,
				OrganizationUnitCode: $scope.ctrl.model.unit.code
			};
		}

		function formatResidueGenerationList(list) {
			list.GeneratedResidues.map((el) => {
				var residue = {};

				residue.Activity = `${el?.Activity?.Code}-${el?.Activity?.DetailCode}`;

				residue.IbamaCode = el?.Activity?.Code;
				residue.ResidueDescription = el.Residue.Description;
				residue.GeneratedQuantity = `${el.FinalQuantity.Quantity} ${el.FinalQuantity.MeasureUnit}`;

				residueGenerationList.push(residue);
			});

			$scope.residueGenerationList = residueGenerationList;
		}

		function formatResidueDisposalList(list) {
			list.DisposedResidues.map((el) => {
				var residueDisposal = {};

				residueDisposal.IbamaCode = `${el?.Activity?.Code}`;
				residueDisposal.ResidueDescription = el.Residue.Description;
				residueDisposal.Quantity = `${el.FinalQuantity.Quantity} ${el.FinalQuantity.MeasureUnit}`;
				residueDisposal.Destinator = el.Suppliers[0].Name;
				residueDisposal.CNPJ = Vgr.util.formatCNPJ(el.SupplierDocumentId);
				residueDisposal.DestinationType = el.DisposalType.Code + " - " + el.DisposalType.Description;

				residueDisposalList.push(residueDisposal);
			});

			$scope.residueDisposalList = residueDisposalList;
		}

		function formatTransporterList(list) {
			list.Transporters.map((el) => {
				var transporter = {};

				transporter.CNPJ = Vgr.util.formatCNPJ(el.SupplierDocumentId);
				transporter.Transporter = el.Suppliers[0].Name;
				transporterList.push(transporter);
			});

			$scope.transporterList = transporterList;
		}

		function setInformationMessage() {
			$scope.messageIcon = "warning";
			$scope.messageTitle = labels.WARNING_VERIFY_TO_CONFIRM;
			$scope.messageStatus = Vgr.constants.generalStatus.warning;
		}
	}
]);
