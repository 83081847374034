angular.module("vgresiduos").directive("cardSmall", function () {
	return {
		scope: {},
		replace: false,
		controller: function () {},
		controllerAs: "ctrl",
		templateUrl: "views/components/card/cardSmall_template.html",
		transclude: true,
		bindToController: {
			text: "<?",
			textButton: "<?",
			icon: "<?",
			classIcon: "@?",
			classText: "@?",
			classBorder: "@?",
			classBackground: "@?",
			onClickButton: "&?"
		}
	};
});
