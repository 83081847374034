angular.module("vgresiduos").factory("measureUnitService", [
	"$http",
	"$q",
	"localStorageWithTimeoutService",
	function ($http, $q, localStorageWithTimeoutService) {
		"use strict";

		const measureUnitsCacheKey = Vgr.constants.cacheKeysPrefix.measureUnits;
		const cacheTimeoutInMinutes = 43200;

		const _listMeasureUnits = function (dto) {
			return Vgr.util.getWithCache(
				Vgr.constants.newHostUrl + Vgr.resources.apiV1.measureUnits,
				dto,
				measureUnitsCacheKey,
				cacheTimeoutInMinutes,
				localStorageWithTimeoutService,
				$http,
				$q
			);
		};

		return {
			listMeasureUnits: _listMeasureUnits
		};
	}
]);
