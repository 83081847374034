angular.module("vgresiduos").component("clientProperty", {
	controller: "ClientPropertyCtrl",
	controllerAs: "ctrl",
	templateUrl: "views/components/fields/clientProperty/clientProperty_template.html",
	bindings: {
		processType: "<",
		displayPropertyText: "@?",
		required: "<",
		disableAll: "<",
		disableProperty: "<",
		setterPropertyId: "<",
		setterPropertyName: "<",
		setterPropertyValue: "<",
		model: "=?",
		canCreate: "<",
		showOnlyProperty: "=?",
		onPropertyChange: "<?"
	}
});
