angular.module("vgresiduos").directive("clientDisposalPreferences", function () {
	return {
		scope: true,
		controller: "clientDisposalPreferencesCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/client/components/disposalPreferences/clientDisposalPreferences_template.html",
		bindToController: {
			inputClient: "=",
			saveOptions: "="
		}
	};
});
