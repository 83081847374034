angular.module("vgresiduos").config([
	"$mdThemingProvider",
	function ($mdThemingProvider) {
		"use strict";

		$mdThemingProvider.definePalette("vertownPallete", {
			50: "1E4960",
			100: "1E4960",
			200: "1E4960",
			300: "1E4960",
			400: "1E4960",
			500: "1E4960",
			600: "1E4960",
			700: "1E4960",
			800: "1E4960",
			900: "1E4960",
			A100: "1E4960",
			A200: "1E4960",
			A400: "1E4960",
			A700: "1E4960",
			// By default, text (contrast) on this palette should be white with 87% opacity.
			contrastDefaultColor: "light"
			// By default, for these lighter hues, text (contrast) should be 'dark'.
			// contrastDarkColors: "50 100 200 300 400 500 A100 A200 A400",
			// By default, for these darker hues, text (contrast) should be white with 100% opacity.
			// contrastStrongLightColors: "600 700 800 900 A700"
		});

		$mdThemingProvider.definePalette("vertownSecondaryPallete", {
			50: "901663",
			100: "901663",
			200: "901663",
			300: "901663",
			400: "901663",
			500: "901663",
			600: "901663",
			700: "901663",
			800: "901663",
			900: "901663",
			A100: "901663",
			A200: "901663",
			A400: "901663",
			A700: "901663",
			// By default, text (contrast) on this palette should be white with 87% opacity.
			contrastDefaultColor: "light"
			// By default, for these lighter hues, text (contrast) should be 'dark'.
			// contrastDarkColors: "50 100 200 300 400 500 A100 A200 A400",
			// By default, for these darker hues, text (contrast) should be white with 100% opacity.
			// contrastStrongLightColors: "600 700 800 900 A700"
		});

		$mdThemingProvider
			.theme("default")
			.primaryPalette("vertownPallete", {
				default: "600",
				"hue-1": "A700",
				"hue-2": "A400",
				"hue-3": "300"
			})
			.accentPalette("vertownSecondaryPallete", {
				default: "500",
				"hue-1": "900",
				"hue-2": "400",
				"hue-3": "700"
			})
			.warnPalette("red", {
				default: "500",
				"hue-1": "400",
				"hue-2": "600",
				"hue-3": "800"
			});
	}
]);
