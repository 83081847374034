angular.module("vgresiduos").controller("DisposalCostInformationCtrl", [
	"$scope",
	"$rootScope",
	"dialogService",
	"clientService",
	function ($scope, $rootScope, dialogService, clientService) {
		"use strict";

		$scope.canOpenPopup = true;

		const onOpenDisposalCostPopup = $rootScope.$on(Vgr.constants.evtOpenDisposalCostPopup, function () {
			$scope.openDisposalCostPopup();
		});

		const onDisposalCostChanged = $rootScope.$on(Vgr.constants.evtDisposalCostChanged, function (event, cost) {
			$scope.canOpenPopup = true;
			updateTotalValueLabels(cost);
		});

		const onDisposalResiduesUpdated = $rootScope.$on(Vgr.constants.evtDisposalResiduesUpdated, function () {
			$scope.canOpenPopup = false;
		});

		const onRecalculateDisposalCost = $rootScope.$on(Vgr.constants.evtRecalculateDisposalCost, function () {
			$scope.canOpenPopup = true;
		});

		this.$onInit = function () {
			updateTotalValueLabels();
		};

		$scope.$on("$destroy", function () {
			// disable the listener
			onOpenDisposalCostPopup();
			onDisposalCostChanged();
			onDisposalResiduesUpdated();
			onRecalculateDisposalCost();

			// nullify the DOM-bound model
			$scope.domElement = null;
		});

		$scope.openDisposalCostPopup = function () {
			if (!$scope.canOpenPopup) {
				$rootScope.$broadcast(Vgr.constants.evtShowInfoMessage, $scope.labels.NEED_TO_SAVE_TO_UPDATE_COSTS);
				return;
			}

			const dialog = dialogService
				.showDialogFromTemplateWithScope(
					null,
					"views/destination/popups/disposalCostPopup/disposalCostPopup_template.html",
					"DisposalCostPopupCtrl",
					$scope,
					"#disposal-popup",
					true,
					function () {}
				)
				.then(
					function () {},
					function () {}
				);
			$scope.dialog = dialog;
		};

		function isFinalized() {
			return (
				$scope.destinationModel.Status == Vgr.enumerations.destination.status.Finalized ||
				$scope.destinationModel.Status == Vgr.enumerations.destination.status.Pending
			);
		}

		function updateTotalValueLabels(cost) {
			$scope.destinationModel.DisposalCost = cost ?? $scope.destinationModel.DisposalCost;
			$scope.DisposalTotalIsIncome = $scope.destinationModel.DisposalCost.totalCost > 0;

			const totalValue = Math.abs($scope.destinationModel.DisposalCost.totalCost);

			if (!$scope.DisposalTotalIsIncome) {
				$scope.TotalCostPredictionLabel = isFinalized()
					? $rootScope.labels.DISPOSAL_EXPENSE
					: $rootScope.labels.EXPENSE_PREDICTION;
			} else {
				$scope.TotalCostPredictionLabel = isFinalized()
					? $rootScope.labels.DISPOSAL_INCOME
					: $rootScope.labels.INCOME_PREDICTION;
			}
			$scope.TotalValueString = Vgr.util.formatUnitDecimalPlaces(totalValue, clientService.getPriceDecimalPlaces());
		}
	}
]);
