angular.module("vgresiduos").factory("disposalPreferenceService", [
	"$http",
	"$q",
	"accountService",
	"rootScopeService",
	function ($http, $q, accountService, rootScopeService) {
		"use strict";

		const disposalPreferenceService = {};

		const _purgeDisposalPreferences = function () {
			rootScopeService.setDisposalPreferences(null);
		};

		function getBaseRequestUrl() {
			const client = accountService.getCurrentClient();
			const organizationId = client.organization.id;
			const organizationUnitCode = client.code;
			return (
				Vgr.constants.residuesManagementHostUrl +
				Vgr.resources.residuesManagement.disposalPreferences
					.replace("[ORGANIZATION_ID]", organizationId)
					.replace("[ORGANIZATION_UNIT]", organizationUnitCode)
			);
		}

		const getDisposalPreferencesFromService = function () {
			const req = {
				method: "get",
				url: getBaseRequestUrl()
			};

			return $http(req);
		};

		const _getDisposalPreferences = function () {
			const deferred = $q.defer();

			const disposalPreferences = rootScopeService.getDisposalPreferences();
			if (disposalPreferences != null) {
				deferred.resolve(disposalPreferences);
			} else {
				getDisposalPreferencesFromService().then(
					function (response) {
						rootScopeService.setDisposalPreferences(response.data);
						deferred.resolve(response.data);
					},
					function () {
						deferred.reject();
					}
				);
			}

			return deferred.promise;
		};

		disposalPreferenceService.getDisposalPreferences = _getDisposalPreferences;
		disposalPreferenceService.purgeDisposalPreferences = _purgeDisposalPreferences;

		return disposalPreferenceService;
	}
]);
