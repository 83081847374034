angular.module("vgresiduos").controller("yearSelectFieldV2Ctrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			getYearsList();
		};

		function getYearsList() {
			var minYear = $scope.$ctrl.minYear;
			var maxYear = $scope.$ctrl.maxYear;
			var interval = maxYear - minYear;

			$scope.years = [];
			while (interval >= 0) {
				$scope.years.push({
					year: maxYear - interval
				});
				interval--;
			}
		}

		this.$onChanges = function (obj) {
			if (obj.setterYear && obj.setterYear.currentValue != obj.setterYear.previousValue) {
				$scope.$ctrl.model = obj.setterYear.currentValue;
			}
		};

		$scope.changed = function (year) {
			if ($scope.$ctrl.changed) {
				$scope.$ctrl.changed(year);
			}
		};
	}
]);
