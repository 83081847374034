angular.module("vgresiduos").controller("DisposalCostsPreferenceCtrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";

		$scope.labels = $rootScope.labels;

		const costs = [
			{
				value: Vgr.enumerations.reportFilters.costsEnum.Residue,
				label: $scope.labels.COSTS_ENUM[Vgr.enumerations.reportFilters.costsEnum.Residue],
				selected: true
			},
			{
				value: Vgr.enumerations.reportFilters.costsEnum.Shipping,
				label: $scope.labels.COSTS_ENUM[Vgr.enumerations.reportFilters.costsEnum.Shipping],
				selected: true
			},
			{
				value: Vgr.enumerations.reportFilters.costsEnum.Other,
				label: $scope.labels.COSTS_ENUM[Vgr.enumerations.reportFilters.costsEnum.Other],
				selected: true
			}
		];

		this.$onInit = function () {
			$scope.$ctrl.model = costs;
		};

		$scope.getCostsLabel = function () {
			const labelArray = [];
			costs.forEach((c) => {
				if (c.selected) {
					labelArray.push(c.label);
				}
			});

			if (labelArray.length == costs.length) {
				return $scope.labels.ALL_COSTS_SELECTED;
			} else {
				return $scope.labels.SOME_COSTS_SELECTED.replace("[VALORES]", labelArray.join(", "));
			}
		};

		$scope.toogleCostsRadio = function () {
			$scope.showRadio = !$scope.showRadio;
		};

		this.$onChanges = function (obj) {
			if (obj.closeRadio && obj.closeRadio.currentValue != obj.closeRadio.previousValue) {
				if (obj.closeRadio.currentValue) {
					$scope.showRadio = false;
				}
			}
		};
	}
]);
