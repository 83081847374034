angular.module("vgresiduos").controller("DestinationActionsCtrl", [
	"$rootScope",
	"$scope",
	"$q",
	"$state",
	"$window",
	"$stateParams",
	"httpService",
	"disposalService",
	"disposalTrackingService",
	"additionalPropertyUtilService",
	"engagementScoreService",
	function (
		$rootScope,
		$scope,
		$q,
		$state,
		$window,
		$stateParams,
		httpService,
		disposalService,
		disposalTrackingService,
		additionalPropertyUtilService,
		engagementScoreService
	) {
		"use strict";

		this.$onInit = function () {
			if ($scope.isCreating) {
				$scope.trackStartTime = new Date();
			}
		};

		function isGatheringStage() {
			return $scope.destinationConfig.currentStage.Id == $scope.stagesEnum.Gathering;
		}

		$scope.isTrackingStage = function () {
			return $scope.destinationConfig.currentStage.Id == $scope.stagesEnum.Tracking;
		};

		function isConfirmationStage() {
			return $scope.destinationConfig.currentStage.Id == $scope.stagesEnum.Confirmation;
		}

		$scope.showActions = function () {
			return $scope.initLoadingFinished && !$scope.isStageCompletedScreen();
		};

		$scope.isStageCompletedScreen = function () {
			return $scope.isCurrentStageCompleted() && !$scope.isCurrentStageReview();
		};

		$scope.enableGoBack = function () {
			return !isCurrentStepTheFirstOne();
		};

		$scope.goBack = function () {
			if (!validateUploadFiles()) {
				return;
			}

			goToPreviousStep();
		};

		function goToPreviousStep() {
			$scope.destinationConfig.currentStepPointer--;
			if ($scope.destinationConfig.currentStage.Steps[$scope.destinationConfig.currentStepPointer].IsDisabled) {
				goToPreviousStep();
			}
		}

		$scope.enableContinue = function () {
			return !isCurrentStepTheLastOne();
		};

		$scope.continue = function () {
			if (!validateUploadFiles()) {
				return;
			}

			if (isNextStepAvailable()) {
				goToNextStep();
			} else {
				sendErrorMessage();
			}
		};

		function goToNextStep() {
			$scope.destinationConfig.currentStepPointer++;
			if ($scope.destinationConfig.currentStage.Steps[$scope.destinationConfig.currentStepPointer].IsDisabled) {
				goToNextStep();
			}
		}

		function sendErrorMessage() {
			if (
				$scope.destinationConfig.currentStage.Steps[$scope.destinationConfig.currentStepPointer].CustomValidationMessage
			) {
				$rootScope.$broadcast(
					Vgr.constants.evtShowErrorMessage,
					$scope.destinationConfig.currentStage.Steps[$scope.destinationConfig.currentStepPointer]
						.CustomValidationMessage
				);
			} else {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.DEFAULT_VALIDATION_MESSAGE);
			}
		}

		$scope.enableCancel = function () {
			return true;
		};

		$scope.cancel = function () {
			return;
		};

		$scope.enableCompleteStage = function () {
			return isCurrentStepTheLastOne();
		};

		$scope.enableUpdate = function () {
			return (
				($scope.isCurrentStageReview() || $scope.showCurrentStageCompletedStep()) &&
				!$scope.isTrackingStage() &&
				!$scope.destinationModel.IsDestinationFlowFinished
			);
		};

		$scope.disableSave = function () {
			return $scope.loadings.update || $scope.loadings.integration;
		};

		$scope.save = function () {
			if (!$scope.isTrackingStage()) {
				if (!validateUploadFiles()) {
					return;
				}

				if (!validate()) {
					return;
				}
			}

			if ($scope.destinationConfig.currentStage.beforeSaveAction) {
				const canSave = $scope.destinationConfig.currentStage.beforeSaveAction();
				if (!canSave) {
					return;
				}
			}

			if ($scope.isCreating) {
				createDisposal();
				trackDestinationCreationTime();
				return;
			}

			if ($scope.destinationConfig.currentStage.beforeUpdateAction) {
				$scope.destinationConfig.currentStage.beforeUpdateAction().then(function () {
					update();
				});
			} else {
				update();
			}
		};

		function update() {
			if ($scope.destinationConfig.currentStage.update) {
				$scope.destinationConfig.currentStage.update();
			} else {
				updateDisposal();
			}
		}

		$scope.hasMiddleStepAction = function () {
			return (
				!$scope.isCurrentStageReview() &&
				$scope.destinationConfig.currentStepPointer &&
				$scope.destinationConfig.currentStage.Steps[$scope.destinationConfig.currentStepPointer].HasMiddleAction
			);
		};

		function getIndexOfCurrentStep() {
			return $scope.destinationConfig.currentStepPointer;
		}

		function isCurrentStepTheFirstOne() {
			const indexOfCurrentStep = getIndexOfCurrentStep();
			return indexOfCurrentStep == 0;
		}

		function isCurrentStepTheLastOne() {
			const indexOfCurrentStep = getIndexOfCurrentStep();
			return indexOfCurrentStep == $scope.destinationConfig.currentStage.Steps.length - 1;
		}

		function isNextStepAvailable() {
			return (
				$scope.destinationConfig.currentStepPointer < $scope.destinationConfig.enabledStepPointer &&
				previewsStepsValid()
			);
		}

		function previewsStepsValid() {
			const stepsEntries = Object.entries($scope.destinationConfig.currentStage.Steps);

			for (const [, step] of stepsEntries) {
				if (step.Id > $scope.destinationConfig.currentStepPointer) {
					break;
				}

				if (step.enableRequirements) {
					if (!step.enableRequirements()) {
						return false;
					}
				}
			}
			return true;
		}

		function isUploadingFiles() {
			return (
				($scope.fileOptions.generalDocuments.isUploading && $scope.fileOptions.generalDocuments.isUploading()) ||
				($scope.fileOptions.invoice.isUploading && $scope.fileOptions.invoice.isUploading()) ||
				($scope.fileOptions.manifest.isUploading && $scope.fileOptions.manifest.isUploading()) ||
				($scope.fileOptions.CDF.isUploading && $scope.fileOptions.CDF.isUploading())
			);
		}

		function validateUploadFiles() {
			if (isUploadingFiles()) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.UPLOAD_ERROR_FILE);
				return false;
			}

			return true;
		}

		function validate() {
			const validation = $scope.validations.find((item) => item.IsToBlock);
			if (validation) {
				const message = $scope.getCustomValidationMessage(validation);

				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, message + ".");
			}

			return validation ? false : true;
		}

		function getInformationsDto() {
			return additionalPropertyUtilService.buildAdditionalPropertiesFromGridToRequest(
				$scope.destinationModel.Informations
			);
		}

		function getPendenciesDto() {
			const pendenciesDto = [];

			for (const i in $scope.destinationModel.Pendencies) {
				if ($scope.destinationModel.Pendencies[i].IsChecked) {
					pendenciesDto.push({
						Type: $scope.destinationModel.Pendencies[i].Type
					});
				}
			}
			return pendenciesDto;
		}

		function getIntegrationsDto() {
			const integrationsDto = [];

			for (const i in $scope.destinationModel.ExternalSystems) {
				if ($scope.destinationModel.ExternalSystems[i].Selected) {
					integrationsDto.push({
						SourceId: $scope.destinationModel.ExternalSystems[i].SourceId
					});
				}
			}

			return integrationsDto;
		}

		function getCreateDisposalDto() {
			return {
				DontFillDocumentsAutomatically: true,
				Model: $scope.destinationModel.Model,
				CreatedFromDisposal: $scope.destinationModel.CreatedFromDisposal,
				RequestDate: Vgr.date.fromDateToUTCString($scope.destinationModel.RequestDate),
				EstimatedGatheringDate: Vgr.date.fromDateToUTCString($scope.destinationModel.EstimatedGatheringDate),
				DestinatorId: $scope.destinationModel.Destinator ? $scope.destinationModel.Destinator.Id : null,
				TransporterId: $scope.destinationModel.Transporter ? $scope.destinationModel.Transporter.Id : null,
				TemporaryStoragerId: $scope.destinationModel.TemporaryStorager
					? $scope.destinationModel.TemporaryStorager.Id
					: null,
				ResponsibleId: $scope.destinationModel.Responsible.Id,
				Observations: $scope.destinationModel.Observations,
				DriverId: $scope.destinationModel.Driver ? $scope.destinationModel.Driver.id : null,
				VehicleId: $scope.destinationModel.Vehicle ? $scope.destinationModel.Vehicle.id : null,
				Residues: $scope.getResiduesDto(),
				AdditionalInformations: getInformationsDto(),
				Pendencies: getPendenciesDto(),
				Documents: $scope.getDocumentsDto(),
				OriginChannelId: Vgr.enumerations.channel.disposal.disposalScreenWithoutModel
			};
		}

		function getUpdateDisposalDto() {
			return {
				disposalId: $scope.destinationModel.Id,
				model: {
					DontFillDocumentsAutomatically: true,
					RequestDate: Vgr.date.fromDateToUTCString($scope.destinationModel.RequestDate),
					GatheringDate: Vgr.date.fromDateToUTCString($scope.destinationModel.GatheringDate),
					EstimatedGatheringDate: Vgr.date.fromDateToUTCString($scope.destinationModel.EstimatedGatheringDate),
					ReceivingDate: Vgr.date.fromDateToUTCString($scope.destinationModel.ReceivingDate),
					DisposalDate: Vgr.date.fromDateToUTCString($scope.destinationModel.DisposalDate),
					TemporaryStoragerReceivedDate: Vgr.date.fromDateToUTCString(
						$scope.destinationModel.TemporaryStoragerReceivingDate
					),
					TemporaryStoragerId: $scope.destinationModel.TemporaryStorager?.Id,
					DestinatorId: $scope.destinationModel.Destinator?.Id,
					TransporterId: $scope.destinationModel.Transporter?.Id,
					ResponsibleId: $scope.destinationModel.Responsible.Id,
					DestinatorResponsibleId: $scope.destinationModel.DestinatorResponsible?.id,
					InvoiceCode: $scope.destinationModel.InvoiceCode,
					Observations: $scope.destinationModel.Observations,
					DriverId: getDriverId(),
					VehicleId: getVehicleId(),
					Residues: $scope.getResiduesDto(),
					AdditionalInformations: getInformationsDto(),
					Pendencies: getPendenciesDto(),
					Files: getAllFiles(),
					Integrations: getIntegrationsDto(),
					PhysicalFiles: getPhysicalFilesDto(),
					Documents: $scope.getDocumentsDto()
				}
			};
		}

		function getDriverId() {
			return $scope.destinationModel.Driver
				? $scope.destinationModel.Driver.id ?? $scope.destinationModel.Driver.Id
				: null;
		}

		function getVehicleId() {
			return $scope.destinationModel.Vehicle
				? $scope.destinationModel.Vehicle.id ?? $scope.destinationModel.Vehicle.Id
				: null;
		}

		function getPhysicalFilesDto() {
			const physicalFiles = [];

			if ($scope.destinationModel.PhysicalFilesInvoice) {
				physicalFiles.push(Vgr.enumerations.destination.file.Invoice);
			}
			if ($scope.destinationModel.PhysicalFilesManifest) {
				physicalFiles.push(Vgr.enumerations.destination.file.Manifest);
			}
			if ($scope.destinationModel.PhysicalFilesCDF) {
				physicalFiles.push(Vgr.enumerations.destination.file.CDF);
			}

			return physicalFiles;
		}

		function getAllFiles() {
			return $scope.destinationModel.InvoiceFiles.concat(
				$scope.destinationModel.GeneralFiles,
				$scope.destinationModel.CDFFiles,
				$scope.destinationModel.ManifestFiles
			);
		}

		function createDisposal() {
			$scope.loadings.update = true;
			const dto = getCreateDisposalDto();
			httpService.postDTOToServiceV2(disposalService.createDisposal, dto).then(
				function (data) {
					$scope.updateReadOnlyModel(data.Disposal);
					$scope.buildDisposalModelAfterCreate(data.Disposal);
					$scope.loadNotifications().then(setStageCompletedOnCreate);
					$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $rootScope.labels.SAVE_SUCCESS_MESSAGE);
				},
				function (error) {
					if (error && error.data && Array.isArray(error.data)) {
						$scope.destinationConfig.currentStage.onError(error.data);
					}
					$scope.loadings.update = false;
				}
			);
		}

		function setStageCompletedOnCreate() {
			$scope.destinationConfig.currentStepPointer = null;
			$scope.destinationConfig.currentStage.IsCompleted = true;
			$scope.loadings.update = false;
		}

		function trackDestinationCreationTime() {
			const durationInSeconds = (new Date().getTime() - $scope.trackStartTime.getTime()) / 1000;

			disposalTrackingService.trackDuration(durationInSeconds);
		}

		function updateManifests() {
			const promise = httpService.getDTOFromServiceV2(disposalService.getDisposal, $scope.destinationModel.Id);
			promise.then(function (data) {
				$scope.updateModel(data.Disposal);
				$scope.destinationModel.Manifests = data.Disposal.Manifests;
			});
			return promise;
		}

		function onUpdateSuccess() {
			$scope.destinationConfig.currentStepPointer = null;
			$scope.destinationConfig.currentStage.IsCompleted = true;
			$scope.loadings.update = false;
			$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $rootScope.labels.SAVE_SUCCESS_MESSAGE);
		}

		function updateDisposal() {
			$scope.loadings.update = true;
			const dto = getUpdateDisposalDto();

			httpService.postDTOToServiceV2(disposalService.updateDisposal, dto).then(
				function () {
					$scope.loadNotifications();

					if (isGatheringStage() && !$scope.isCurrentStageReview() && hasSelectedIntegrations()) {
						openAddIntegrationsPopup();
					} else if (isConfirmationStage()) {
						updateDisposalStatus();
					} else {
						updateManifests().then(
							function () {
								onUpdateSuccess();
							},
							function () {
								onUpdateSuccess();
							}
						);
					}
				},
				function (data) {
					$scope.loadings.update = false;
				}
			);
		}

		function hasSelectedIntegrations() {
			return $scope.destinationModel.ExternalSystems &&
				$scope.destinationModel.ExternalSystems.find((system) => system.Selected)
				? true
				: false;
		}

		function openAddIntegrationsPopup() {
			$scope.destinationConfig.currentStepPointer = null;
			$scope.destinationConfig.currentStage.IsCompleted = true;
			$scope.loadings.update = false;

			$rootScope.$broadcast(Vgr.constants.evtOpenDisposalAddIntegrationsPopup, $scope.destinationModel.Id);
		}

		function updateDisposalStatus() {
			updateDisposalReadonlyModel().then(
				function (data) {
					const previousStatus = $scope.destinationModel.Status;
					$scope.destinationModel.Status = data.Disposal.Status;
					$scope.destinationModel.IsDestinationFlowFinished =
						previousStatus != Vgr.enumerations.destination.status.Finalized &&
						previousStatus != Vgr.enumerations.destination.status.Pending;
					$scope.destinationConfig.currentStepPointer = null;
					$scope.destinationConfig.currentStage.IsCompleted = true;
					$scope.loadings.update = false;

					if (isOpenPopupDisposalCost()) {
						$rootScope.$broadcast(Vgr.constants.evtOpenDisposalCostPopup);
					}
					$rootScope.$broadcast(Vgr.constants.evtDisposalCostChanged);
					$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $rootScope.labels.SAVE_SUCCESS_MESSAGE);
				},
				function () {
					$scope.loadings.update = false;
				}
			);
		}

		function isOpenPopupDisposalCost() {
			return $scope.destinationModel.IsDestinationFlowFinished && !$scope.destinationModel.DisposalCost.totalCost;
		}

		function updateDisposalReadonlyModel() {
			const deferrend = $q.defer();
			httpService.getDTOFromServiceV2(disposalService.getDisposal, $stateParams.id).then(
				function (data) {
					$scope.updateReadOnlyModel(data.Disposal);
					deferrend.resolve(data);
				},
				function (data) {
					deferrend.reject(data);
				}
			);

			return deferrend.promise;
		}

		$scope.getFinishButtonLabel = function () {
			switch ($scope.destinationConfig.currentStage.Id) {
				case $scope.stagesEnum.Solicitation:
					return $scope.labels.ASK_EXTERNAL_GATHERING;
				case $scope.stagesEnum.Gathering:
					return $scope.labels.COLLECT_RESIDUES;
				case $scope.stagesEnum.Tracking:
					return $scope.labels.CONFIRM_RECEIVAL;
				case $scope.stagesEnum.InTemporaryStorage:
					return $scope.labels.CONFIRM_RECEIVAL;
				case $scope.stagesEnum.Arrival:
					return $scope.labels.CONFIRM_RECEIVAL;
				default:
					return $scope.labels.FINISH_REQUEST;
			}
		};

		$scope.showMtrFollowUpPageButton = function () {
			return (
				!$scope.disableSave() &&
				$scope.editPermission &&
				$scope.isTrackingStage() &&
				$scope.enableCompleteStage() &&
				!$scope.isCurrentStageCompleted()
			);
		};

		$scope.goToMtrFollowUpPage = function () {
			engagementScoreService.track(Vgr.trackings.mtrFollowup.accessPageByDestination);
			const url = $state.href("mtrFollowUp");
			$window.open(url, "_blank");
		};
	}
]);
