angular.module("vgresiduos").factory("hotjarService", [
	"accountService",
	"$window",
	function (accountService, $window) {
		"use strict";

		const _identify = function identify() {
			if (!$window.hj) {
				return;
			}

			const user = accountService.getLoggedAccount();

			let userId = null;
			let userEmail = null;
			if (user) {
				userId = user.code;
				userEmail = user.email;
			}

			$window.hj("identify", userId, {
				email: userEmail
			});
		};

		const _trackEvent = function (eventCode) {
			if (!$window.hj) {
				return;
			}

			$window.hj("eventCode", eventCode);
		};

		return {
			identify: _identify,
			trackEvent: _trackEvent
		};
	}
]);
