angular.module("vgresiduos").controller("GeneratingManagementInventoryReportCtrl", [
	"$rootScope",
	"$scope",
	"$state",
	"residueService",
	"reportUtilService",
	function ($rootScope, $scope, $state, residueService, reportUtilService) {
		"use strict";

		this.$onInit = function () {
			$scope.intervalList = reportUtilService.listCustomReportsDateRangeIntervals();

			$scope.pricePreferences = {
				allCosts: "allCosts",
				onlyResidues: "onlyResidues"
			};

			$scope.intervalTypeList = [
				{
					ID: 1,
					Description: $rootScope.labels.CURRENT_MONTH
				},
				{
					ID: 2,
					Description: $rootScope.labels.CURRENT_YEAR
				},
				{
					ID: 3,
					Description: $rootScope.labels.CUSTOM
				}
			];

			$scope.model = {
				pricePreference: $scope.pricePreferences.allCosts,
				measureUnitPreference: {}
			};

			$scope.filter = {
				format: "PDF",
				dateOption: Vgr.enumerations.customReport.dateRangeOption.LastMonth,
				isCorporate: $state.is("generatingCorporateManagementInventoryReport"),
				preferences: {
					considerAllCosts: true
				}
			};

			$scope.reportOptions = new Cmp.constructors.ReportOptions(
				$rootScope.labels.MANAGEMENT_INVENTORY_REPORT,
				residueService.generateManagementInventoryReport,
				$scope.filter
			);

			$scope.reportOptions.toolbarButtons = [$rootScope.labels.FILTER, $rootScope.labels.CSV];
		};

		$scope.onPricePreferencesChanged = function () {
			$scope.filter.preferences.considerAllCosts = $scope.model.pricePreference == $scope.pricePreferences.allCosts;
		};

		$scope.showDates = function () {
			return $scope.filter.dateOption == Vgr.enumerations.customReport.dateRangeOption.Custom;
		};

		const watchMeasureUnit = $scope.$watch(
			"model.measureUnitPreference",
			function (newValue) {
				$scope.filter.preferences.measureUnitPreference = newValue.measureUnitPreference;
				$scope.filter.preferences.measureUnitId = newValue.measureUnit ? newValue.measureUnit.Id : null;
			},
			true
		);

		$scope.$on("$destroy", function () {
			// disable the listener
			watchMeasureUnit();

			// nullify the DOM-bound model
			$scope.domElement = null;
		});
	}
]);
