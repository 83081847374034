angular.module("vgresiduos").controller("PermissionGroupEditCtrl", [
	"$rootScope",
	"$scope",
	"$state",
	"$stateParams",
	"$q",
	"httpService",
	"permissionService",
	"permissionGroupService",
	"accountService",
	function (
		$rootScope,
		$scope,
		$state,
		$stateParams,
		$q,
		httpService,
		permissionService,
		permissionGroupService,
		accountService
	) {
		"use strict";

		const unitPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.EditUnitPermissions);
		const organizationPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.EditOrganizationPermissions);
		const systemPermissions = null;
		const isCreation = !$stateParams.id;

		$rootScope.contentNavBar = $scope.saveOptions;

		$scope.isCreation = isCreation;
		$scope.getPermissionGroup = getPermissionGroup;
		$scope.getEditPermission = getEditPermission;
		$scope.changeContext = function (context) {
			$scope.saveOptions.currentContext = context;
			setContentNavBar(context);
		};
		$scope.saveOptions = {
			title: $rootScope.labels.PROFILES,
			currentContext: "general",
			returnState: getListState(),
			general: {
				valid: true,
				returnState: getListState(),
				onSaveError: function () {},
				hasPermission: function () {
					return getEditPermission();
				},
				validate: function () {
					return checkPermissionGroup();
				},
				save: function () {
					return checkIsCreationPermissionGroup();
				}
			},
			userGrid: {}
		};

		this.$onInit = function () {
			checkIsCreation();
		};

		function isUnitPermissionState() {
			return $state.is("unitPermissionEdit");
		}

		function getListState() {
			return isUnitPermissionState() ? "unitPermissionList" : "organizationPermissionList";
		}

		function getSystemPermissionType() {
			return isUnitPermissionState()
				? Vgr.enumerations.systemPermissions.typesText.OrganizationUnit
				: Vgr.enumerations.systemPermissions.typesText.Organization;
		}

		function getEditPermission() {
			return isUnitPermissionState() ? unitPermission : organizationPermission;
		}

		function checkPermissionGroup() {
			return $scope.permissionGroup.name;
		}

		function checkIsCreationPermissionGroup() {
			if (isCreation) {
				return createPermissionGroup();
			} else {
				return updatePermissionGroup();
			}
		}

		function getPermissionsModel() {
			if (!$scope.permissionGroup.systemPermissions) {
				return [];
			}

			const permissions = [];
			$scope.permissionGroup.systemPermissions.forEach((spg) => {
				processSystemPermissions(spg, permissions);
			});

			return permissions;
		}

		function processSystemPermissions(spg, permissions) {
			spg[1].forEach((sp) => {
				processSystemPermission(sp, permissions);
			});
		}

		function processSystemPermission(sp, permissions) {
			if (sp.allow == Vgr.enumerations.systemPermissions.level.Allow) {
				permissions.push({
					SystemPermissionId: sp.id,
					Allow: true
				});
			} else if (sp.allow == Vgr.enumerations.systemPermissions.level.NotAllow) {
				permissions.push({
					SystemPermissionId: sp.id,
					Allow: false
				});
			}
		}

		function createPermissionGroup() {
			const model = getCreateModel();

			if (isUnitPermissionState()) {
				return processCreateUnitPermission(model);
			} else {
				return processCreateOrganizationPermission(model);
			}
		}

		function processCreateUnitPermission(model) {
			const promise = httpService.postDTOToServiceV2(permissionGroupService.createUnitPermission, model);
			promise.then(function (id) {
				$state.go($state.current.name, { id: id });
			});
			return promise;
		}

		function processCreateOrganizationPermission(model) {
			const promise = httpService.postDTOToServiceV2(permissionGroupService.createOrganizationPermission, model);
			promise.then(function (id) {
				$state.go($state.current.name, { id: id });
			});
			return promise;
		}

		function getCreateModel() {
			return {
				Name: $scope.permissionGroup.name,
				Description: $scope.permissionGroup.description,

				Permissions: getPermissionsModel(),
				UserIds: []
			};
		}

		function updatePermissionGroup() {
			const dto = {
				id: $stateParams.id,
				model: getUpdateModel()
			};

			if (isUnitPermissionState()) {
				return updateUnitPermission(dto);
			} else {
				return processUpdateOrganizationPermission(dto);
			}
		}

		function updateUnitPermission(dto) {
			const promise = httpService.postDTOToServiceV2(permissionGroupService.updateUnitPermission, dto);
			promise.then(function () {
				getPermissionGroup();
			});
			return promise;
		}

		function processUpdateOrganizationPermission(dto) {
			const promise = httpService.postDTOToServiceV2(permissionGroupService.updateOrganizatioPermission, dto);
			promise.then(function () {
				getPermissionGroup();
			});
			return promise;
		}

		function getUpdateModel() {
			return {
				Name: $scope.permissionGroup.name,
				Description: $scope.permissionGroup.description,

				Permissions: getPermissionsModel()
			};
		}

		function getPermissionGroup() {
			const deferred = $q.defer();

			if (isUnitPermissionState()) {
				processUnitPermission(deferred);
			} else {
				processOrganizationPermission(deferred);
			}

			return deferred.promise;
		}

		function processUnitPermission(deferred) {
			httpService.postDTOToServiceV2(permissionGroupService.getUnitPermission, $stateParams.id).then(
				function (data) {
					$scope.permissionGroup = data;
					$scope.saveOptions.breadcrumb = data.name;
					configureSystemPermissions();
					deferred.resolve(data);
				},
				function () {
					$state.go(getListState());
					deferred.reject();
				}
			);
		}

		function processOrganizationPermission(deferred) {
			httpService.getDTOFromServiceV2(permissionGroupService.getOrganizationPermission, $stateParams.id).then(
				function (data) {
					$scope.permissionGroup = data;
					$scope.saveOptions.breadcrumb = data.name;
					configureSystemPermissions();
					deferred.resolve(data);
				},
				function () {
					$state.go(getListState());
					deferred.reject();
				}
			);
		}

		function configureSystemPermissions() {
			listSystemPermissions().then(
				function (data) {
					configureSystemPermission(data);
				},
				function () {}
			);
		}

		function configureSystemPermission(data) {
			if ($scope.permissionGroup.permissions) {
				data.forEach((sp) => {
					retrievePermission(sp);
				});
			}
			const systemPermissionsGrouped = Vgr.util.groupBy(data, (sp) => sp.group.id);
			$scope.permissionGroup.systemPermissions = Array.from(systemPermissionsGrouped);
		}

		function retrievePermission(sp) {
			const foundPermission = $scope.permissionGroup.permissions.find((p) => p.id == sp.id);
			checkPermission(foundPermission, sp);
		}

		function checkPermission(foundPermission, sp) {
			if (foundPermission) {
				allowFoundPermission(foundPermission, sp);
			} else {
				sp.allow = Vgr.enumerations.systemPermissions.level.ToBeDefined;
			}
		}

		function allowFoundPermission(foundPermission, sp) {
			if (foundPermission.allow) {
				sp.allow = Vgr.enumerations.systemPermissions.level.Allow;
			} else {
				sp.allow = Vgr.enumerations.systemPermissions.level.NotAllow;
			}
		}

		function listSystemPermissions() {
			const deferred = $q.defer();

			if (systemPermissions) {
				deferred.resolve(systemPermissions);
			} else {
				listSystemPermission(deferred);
			}

			return deferred.promise;
		}

		function listSystemPermission(deferred) {
			const dto = {
				type: getSystemPermissionType(),
				organizationId: accountService.getCurrentClient().organization.id
			};

			httpService.getDTOFromServiceV2(permissionService.listSystemPermissions, dto).then(
				function (data) {
					data = data.filter(function (permission) {
						return $rootScope.storagePermissionsToHide.indexOf(permission.id) === -1;
					});

					deferred.resolve(data);
				},
				function () {
					deferred.reject();
				}
			);
		}

		function checkIsCreation() {
			if (isCreation) {
				$scope.permissionGroup = {};
				configureSystemPermissions();
			} else {
				getPermissionGroup();
			}
		}

		function setContentNavBar(context) {
			if (context == "userGrid") {
				$rootScope.contentNavBar = null;
				$rootScope.contentNavBarGrid = $scope.saveOptions.userGrid;
			} else if (context == "general") {
				$rootScope.contentNavBar = $scope.saveOptions;
				$rootScope.contentNavBarGrid = null;
			}
		}
	}
]);
