angular.module("vgresiduos").factory("marketService", [
	"$http",
	"accountService",
	"engagementScoreService",
	function ($http, accountService, engagementScoreService) {
		"use strict";

		const _createIndicationRequest = function (dto) {
			engagementScoreService.track(Vgr.trackings.dashboard.opportunities.createIndicationRequest);
			const client = accountService.getCurrentClient();
			let route = "v1/organizations/[ORGANIZATION_ID]/organization-units/[ORGANIZATION_UNIT_CODE]/indication-requests";
			route = route
				.replace("[ORGANIZATION_ID]", client.organization.id)
				.replace("[ORGANIZATION_UNIT_CODE]", client.code);
			const req = {
				method: "post",
				data: dto,
				url: Vgr.constants.residueMarketHostUrl + route
			};
			return $http(req);
		};

		return {
			createIndicationRequest: _createIndicationRequest
		};
	}
]);
