angular.module("vgresiduos").controller("GoalReportCtrl", [
	"$scope",
	"$rootScope",
	"$state",
	"$q",
	"$timeout",
	"reportService",
	"httpService",
	"reportUtilService",
	"engagementScoreService",
	function (
		$scope,
		$rootScope,
		$state,
		$q,
		$timeout,
		reportService,
		httpService,
		reportUtilService,
		engagementScoreService
	) {
		const labels = $rootScope.labels;
		let reportTitle;

		this.$onInit = function () {
			$rootScope.contentNavBar = {
				title: $scope.labels.REPORTS,
				breadcrumb: $scope.labels.GOAL_VERSUS_ACCOMPLISHED,
				back: function () {
					$state.go("customReport");
				},
				valid: true,
				customButtons: [
					{
						label: $scope.labels.EXPORT_CSV,
						buttonClasses: "md-primary",
						supressToast: true,
						onClick: function () {
							const deferred = $q.defer();
							$timeout(function () {
								const reportButton = angular.element("#hiddenCsvExportBtn");
								if (reportButton.length) {
									reportButton.click();
								}
							});
							deferred.resolve();
							return deferred.promise;
						}
					}
				],
				buttons: []
			};

			$scope.selectedFilters = [];
			$scope.onFilter = function (originalFilters, formattedFilters, preFilterOptions) {
				if (preFilterOptions.selected == Vgr.enumerations.goal.type.DisposalFinancial) {
					reportTitle = labels.RESIDUE_DISPOSAL_COSTS_CHART_TITLE;
					$scope.subtitle = [$scope.labels.FINALCIAL_CHART_SUBTILE];
					$scope.filenamePreffix = labels.FINANCIAL_DISPOSAL_GOAL_REPORT;
					$scope.isMoney = true;
					$scope.decimalPlaces = 2;
				} else if (preFilterOptions.selected == Vgr.enumerations.goal.type.ResidueGeneration) {
					reportTitle = labels.RESIDUE_GENERATION_CHART_TITLE;
					$scope.subtitle = [];
					$scope.filenamePreffix = labels.RESIDUE_GENERATION_CHART_TITLE;
					$scope.isMoney = false;
					$scope.decimalPlaces = null;
				}

				$scope.alreadyFiltered = true;
				$scope.selectedFilters = formattedFilters;
				return loadData(preFilterOptions);
			};
			$scope.alreadyFiltered = false;

			$scope.hasData = function (data) {
				return data != null && data != "" && !angular.equals(data, {});
			};
		};

		function loadData(preFilterOptions) {
			$rootScope.contentNavBar.buttons = [];
			$scope.filterParamsError = false;

			if ($scope.filterParamsError) {
				const deferred = $q.defer();
				deferred.reject();
				return deferred.promise;
			}

			const dto = getDtoFromFilters(preFilterOptions);
			$scope.isLoading = true;

			let service;
			if (preFilterOptions.selected == Vgr.enumerations.goal.type.DisposalFinancial) {
				service = reportService.generateGoalResidueDisposalFinancialReport;
			} else if (preFilterOptions.selected == Vgr.enumerations.goal.type.ResidueGeneration) {
				service = reportService.generateGoalResidueGenerationReport;
			}

			const reportPromise = httpService.postDTOToServiceV2(service, dto);
			reportPromise.then(
				function (report) {
					formatResponseData(report);
					trackReportGeneration(preFilterOptions.selected);

					$rootScope.contentNavBar.buttons = [$scope.labels.EXPORT_CSV];

					$scope.isLoading = false;
				},
				function () {
					$scope.isLoading = false;
				}
			);

			return reportPromise;
		}

		function formatResponseData(report) {
			loadTableHeaders(report);
			loadTableData(report);

			loadChartTitle(report);
			$scope.chartHeaders = report.header;
			$scope.data = report.data;
			$scope.goalData = report.goalData;
			$scope.trendingData = report.trendingData;
		}

		function loadTableHeaders(report) {
			const tableHeader = {};

			angular.copy(report.header, tableHeader);
			if (!tableHeader.groups) {
				tableHeader.groups = [];
			}
			tableHeader.groups.unshift(" ");

			$scope.tableHeadersConcatenatedArray = reportUtilService.getHeadersConcatenatedArrayFromDto(tableHeader);
		}

		function loadTableData(report) {
			const tableData = [];

			if (report.goalData) {
				for (const data of report.goalData) {
					tableData.push(getTableRow(data, labels.GOAL));
				}
			}

			if (report.data) {
				for (const data of report.data) {
					tableData.push(getTableRow(data, labels.REALIZED));
				}
			}

			if (report.trendingData) {
				for (const data of report.trendingData) {
					tableData.push(getTableRow(data, labels.TRENDING));
				}
			}

			$scope.tableReportData = reportUtilService.getDataQualifiersFromDto(tableData);
		}

		function getTableRow(data, label) {
			const tableDataRow = {
				Groups: [],
				DataQualifiers: [],
				Intervals: [],
				Total: null
			};

			if (data.groups) {
				angular.copy(data.groups, tableDataRow.Groups);
			}
			tableDataRow.Groups.unshift(label);

			angular.copy(data.dataQualifiers, tableDataRow.DataQualifiers);
			angular.copy(data.intervals, tableDataRow.Intervals);
			tableDataRow.Total = data.total;

			return tableDataRow;
		}

		function getDtoFromFilters(preFilterOptions) {
			const filters = $scope.selectedFilters;
			const dto = {
				DateRange: {
					StartDate: filters.DateRange.beginDate,
					EndDate: filters.DateRange.endDate,
					RangeGroup: filters.PeriodGrouping
				},
				Filters: filters.filters
			};

			if (preFilterOptions.selected == Vgr.enumerations.goal.type.ResidueGeneration) {
				dto.Preferences = {
					MeasureUnitId: filters.MeasureUnit.Id
				};
			}

			return dto;
		}

		function loadChartTitle(report) {
			let dataQualifier;
			if (report.data && report.data.length > 0) {
				dataQualifier = report.data[0].dataQualifiers[0];
			} else if (report.goalData && report.goalData.length > 0) {
				dataQualifier = report.goalData[0].dataQualifiers[0];
			} else if (report.trendingData && report.trendingData.length > 0) {
				dataQualifier = report.trendingData[0].dataQualifiers[0];
			}

			$scope.chartTitle = reportTitle + (dataQualifier ? " (" + dataQualifier + ")" : "");
		}

		function trackReportGeneration(metric) {
			engagementScoreService.track(Vgr.trackings.bi.reports.generateGoal);

			if (metric == Vgr.enumerations.goal.type.DisposalFinancial) {
				engagementScoreService.track(Vgr.trackings.bi.metrics.goalResidueGenerationQuantity);
			} else if (metric == Vgr.enumerations.goal.type.ResidueGeneration) {
				engagementScoreService.track(Vgr.trackings.bi.metrics.goalResidueDisposalCosts);
			}
		}
	}
]);
