angular.module("vgresiduos").directive("actionPlanDashboardCard", function () {
	return {
		scope: true,
		controller: "ActionPlanDashboardCardCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/suppliers/actionPlans/dashboard/baseComponents/card/actionPlanDashboardCard_template.html",
		bindToController: {
			title: "=",
			text: "=?",
			icon: "<?",
			classIcon: "@?",
			classTitle: "@?",
			classText: "@?"
		}
	};
});
