angular.module("vgresiduos").controller("PeriodFieldCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.model = {};

		$scope.periods = [
			{
				Id: 6,
				Name: "1º " + $scope.labels.HALF,
				GetBegin: function (year) {
					return getDate(year, 0, 1);
				},
				GetEnd: function (year) {
					return getDate(year, 6, 0);
				}
			},
			{
				Id: 7,
				Name: "2º " + $scope.labels.HALF,
				GetBegin: function (year) {
					return getDate(year, 6, 1);
				},
				GetEnd: function (year) {
					return getDate(year, 12, 0);
				}
			},
			{
				Id: 2,
				Name: "1º " + $scope.labels.QUARTER,
				GetBegin: function (year) {
					return getDate(year, 0, 1);
				},
				GetEnd: function (year) {
					return getDate(year, 3, 0);
				}
			},
			{
				Id: 3,
				Name: "2º " + $scope.labels.QUARTER,
				GetBegin: function (year) {
					return getDate(year, 3, 1);
				},
				GetEnd: function (year) {
					return getDate(year, 6, 0);
				}
			},
			{
				Id: 4,
				Name: "3º " + $scope.labels.QUARTER,
				GetBegin: function (year) {
					return getDate(year, 6, 1);
				},
				GetEnd: function (year) {
					return getDate(year, 9, 0);
				}
			},
			{
				Id: 5,
				Name: "4º " + $scope.labels.QUARTER,
				GetBegin: function (year) {
					return getDate(year, 9, 1);
				},
				GetEnd: function (year) {
					return getDate(year, 12, 0);
				}
			},
			{
				Id: 8,
				Name: $scope.labels.CUSTOMV2
			}
		];

		this.$onInit = function () {
			$scope.startingYear = new Date().getFullYear() - 3;
		};

		function getDate(year, month, day) {
			return new Date(year, month, day);
		}

		function updateDates(year, period) {
			if (!year || !period || !period.Id) {
				$scope.showDateFields = false;
				$scope.dateFiledsEnabled = false;
				$scope.$ctrl.beginDate = null;
				$scope.$ctrl.endDate = null;
				return;
			}

			$scope.showDateFields = true;
			if (period.GetBegin && period.GetEnd) {
				$scope.dateFiledsEnabled = false;
				$scope.$ctrl.beginDate = period.GetBegin(year);
				$scope.$ctrl.endDate = period.GetEnd(year);
			} else {
				$scope.$ctrl.beginDate = null;
				$scope.$ctrl.endDate = null;
				$scope.minDate = year + "-01-01";
				$scope.maxDate = year + "-12-31";
				$scope.dateFiledsEnabled = true;
			}
		}

		$scope.yearChanged = function (year) {
			updateDates(year, $scope.model.period);
			$scope.$ctrl.year = year;
		};

		$scope.periodChanged = function (period) {
			updateDates($scope.model.year, period);
		};
	}
]);
