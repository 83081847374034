angular.module("vgresiduos").controller("CreateReportPopupCtrl", [
	"$scope",
	"$rootScope",
	"dialogService",
	"httpService",
	"customReportService",
	"reportUtilService",
	"engagementScoreService",
	"filters",
	function (
		$scope,
		$rootScope,
		dialogService,
		httpService,
		customReportService,
		reportUtilService,
		engagementScoreService,
		filters
	) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.popupTitle = $scope.labels.SAVE_REPORT;
		$scope.model = { reportDescription: "" };

		const cancelButton = {
			label: $scope.labels.CANCEL,
			class: "md-primary",
			click: function () {
				dialogService.cancel();
			},
			disabled: false
		};
		const saveButton = {
			label: $scope.labels.SAVE,
			class: "md-primary md-raised",
			click: function () {
				createReport();
			},
			disabled: false,
			isLoading: false
		};

		$scope.popupActionButtons = [cancelButton, saveButton];

		function toggleButtonsEnabledState() {
			$scope.popupActionButtons.forEach((button) => (button.disabled = !button.disabled));
		}

		function createReport() {
			toggleButtonsEnabledState();
			saveButton.isLoading = true;

			const createDto = getCreateDto();

			httpService.postDTOToServiceV2(customReportService.createCustomReport, createDto).then(
				function (ret) {
					engagementScoreService.track(Vgr.trackings.bi.reports.createSavedReport);
					dialogService.confirm(ret);
					saveButton.isLoading = false;
				},
				function () {
					toggleButtonsEnabledState();
					saveButton.isLoading = false;
				}
			);
		}

		function getCreateDto() {
			return reportUtilService.buildCustomReportFilterDto(
				filters,
				customReportService.getReportType("Kpi"),
				$scope.model.reportDescription
			);
		}
	}
]);
