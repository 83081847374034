angular.module("vgresiduos").factory("clientStoragePreferenceService", [
	"$http",
	"$q",
	"rootScopeService",
	"accountService",
	function ($http, $q, rootScopeService, accountService) {
		"use strict";

		const baseRoute = Vgr.constants.residuesManagementHostUrl + Vgr.resources.residuesManagement.storagePreferences;
		const clientStoragePreferenceService = {};

		const _purgeStoragePreferences = function () {
			rootScopeService.setStoragePreferences(null);
		};

		const _getStoragePreferences = function () {
			const deferred = $q.defer();

			const storagePreferences = rootScopeService.getStoragePreferences();
			if (storagePreferences === null || storagePreferences === undefined) {
				getStoragePreferencesFromService().then(
					function (response) {
						rootScopeService.setStoragePreferences(response.data);
						deferred.resolve(response.data);
					},
					function () {
						deferred.reject({});
					}
				);
			} else {
				deferred.resolve(storagePreferences);
			}

			return deferred.promise;
		};

		function getStoragePreferencesFromService() {
			const organizationId = accountService.getCurrentClient().organization.id;
			const organizationUnitCode = accountService.getCurrentClient().code;
			const req = {
				method: "get",
				url: baseRoute.replace("[ORGANIZATION_ID]", organizationId).replace("[ORGANIZATION_UNIT]", organizationUnitCode)
			};
			return $http(req);
		}

		clientStoragePreferenceService.getStoragePreferences = _getStoragePreferences;
		clientStoragePreferenceService.purgeStoragePreferences = _purgeStoragePreferences;

		return clientStoragePreferenceService;
	}
]);
