angular.module("vgresiduos").controller("GoalReportChartCtrl", [
	"$rootScope",
	"$scope",
	"clientService",
	function ($rootScope, $scope, clientService) {
		"use strict";

		var yAxisDecimals;

		this.$onInit = function () {
			yAxisDecimals = $scope.$ctrl.yAxisDecimals ? $scope.$ctrl.yAxisDecimals : clientService.getDecimalPlaces();
			loadChartData();
		};

		function loadChartData() {
			$scope.labels = getXAxisLabels();
			$scope.data = getData();

			$scope.chartSeries = getChartSeriesFromHeader();
			$scope.datasetOverride = getDataSetOverride();

			$scope.chartOptions = getChartOptions();
		}

		function getXAxisLabels() {
			if ($scope.$ctrl.headers.intervals && $scope.$ctrl.headers.intervals.length > 0) {
				return $scope.$ctrl.headers.intervals;
			} else {
				return [$scope.$ctrl.headers.total];
			}
		}

		function getData() {
			if ($scope.$ctrl.headers.intervals && $scope.$ctrl.headers.intervals.length > 0) {
				return getIntervalsFromData();
			} else {
				return getTotalFromData();
			}
		}

		function getIntervalsFromData() {
			let values = [];
			for (let row of $scope.$ctrl.data) {
				values.push(row.intervals);
			}

			if ($scope.$ctrl.goalData) {
				for (let row of $scope.$ctrl.goalData) {
					values.push(row.intervals);
				}
			}

			if ($scope.$ctrl.trendingData) {
				for (let row of $scope.$ctrl.trendingData) {
					values.push(row.intervals);
				}
			}

			return values;
		}

		function getTotalFromData() {
			let values = [];
			for (let row of $scope.$ctrl.data) {
				values.push(row.total);
			}

			if ($scope.$ctrl.goalData) {
				for (let row of $scope.$ctrl.goalData) {
					values.push(row.total);
				}
			}

			if ($scope.$ctrl.trendingData) {
				for (let row of $scope.$ctrl.trendingData) {
					values.push(row.total);
				}
			}

			return values;
		}

		function getChartSeriesFromHeader() {
			let series = [];
			series.push($rootScope.labels.REALIZED);

			if ($scope.$ctrl.goalData) {
				series.push($rootScope.labels.GOAL);
			}

			if ($scope.$ctrl.trendingData) {
				series.push($rootScope.labels.TRENDING);
			}

			return series;
		}

		function getDataSetOverride() {
			return [
				{
					borderColor: "rgba(64,161,211, 1)",
					backgroundColor: "rgba(131,200,235, 1)",
					type: "bar",
					order: 2
				},
				{
					borderColor: "rgba(64,165,154, 1)",
					backgroundColor: "rgba(158,206,202, 1)",
					type: "bar",
					order: 3
				},
				{
					borderColor: "rgba(74,74,74, 1)",
					backgroundColor: "rgba(74,74,74, 1)",
					pointBackgroundColor: "rgba(74,74,74, 1)",
					pointColor: "rgba(74,74,74, 1)",
					pointHoverBorderColor: "rgba(74,74,74, 1)",
					pointHoverBackgroundColor: "rgba(74,74,74, 1)",
					type: "line",
					fill: false,
					order: 1
				}
			];
		}

		function getChartOptions() {
			return {
				legend: {
					display: true
				},
				tooltips: {
					callbacks: {
						title: function (tooltipItems, data) {
							return "";
						},
						label: formatNumberTooltip
					}
				}
			};
		}

		function formatNumberTooltip(tooltipItem, data) {
			let dataset = data.datasets[tooltipItem.datasetIndex];

			let value = dataset.data ? dataset.data[tooltipItem.index] : "";

			let label = dataset.label + ": " + (value == null ? "-" : yAxisFormat(value));

			return label;
		}

		function yAxisFormat(value) {
			return Vgr.util.formatUnitDecimalPlaces(value, yAxisDecimals);
		}
	}
]);
