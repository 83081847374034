angular.module("vgresiduos").component("customReportFilterPreferenceDisposalPriceSection", {
	controller: "CustomReportFilterPreferenceDisposalPriceSectionCtrl",
	templateUrl:
		"views/customReports/components/customReportFilter/sections/customReportFilterPreferenceDisposalPriceSection_template.html",
	bindings: {
		clear: "<?",
		callback: "<?",
		setter: "<?"
	}
});
