angular.module("vgresiduos").directive("auditCategoryInfo", function () {
	return {
		scope: true,
		controller: "AuditCategoryInfoCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/suppliers/audits/categoryInfo/auditCategoryInfo_template.html",
		bindToController: {
			audit: "=?"
		}
	};
});
