angular.module("vgresiduos").controller("MeasureUnitV2Ctrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"measureUnitService",
	function ($scope, $rootScope, $q, httpService, measureUnitService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		var isListLoaded;

		this.$onInit = function () {
			setInitialVariables();

			loadMeasureUnits().then(function () {
				setValue($scope.$ctrl.setter);
			});
		};

		function setInitialVariables() {
			$scope.fieldLabel = $scope.labels.MEASURE_UNIT;
			if ($scope.$ctrl.isRequired) {
				$scope.fieldLabel += "*";
			}
		}

		function loadMeasureUnits() {
			var deferred = $q.defer();

			if (isListLoaded) {
				deferred.resolve();
				return deferred.promise;
			}

			var dto = {
				onlyResidueUnits: $scope.$ctrl.onlyResidueUnits ? $scope.$ctrl.onlyResidueUnits : false,
				onlyPricingUnits: $scope.$ctrl.onlyPricingUnits ? $scope.$ctrl.onlyPricingUnits : false
			};

			httpService.getListFromServiceV2(measureUnitService.listMeasureUnits, dto, "MeasureUnits").then(
				function (list) {
					if ($scope.$ctrl.measureUnitGroup) {
						list = filterByMeasureUnitGroup(list);
					}

					$scope.measureUnits = formatList(list);

					isListLoaded = true;
					deferred.resolve();
				},
				function (data) {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function filterByMeasureUnitGroup(list) {
			return list.filter(function (item) {
				return item.Group == $scope.$ctrl.measureUnitGroup;
			});
		}

		function formatList(list) {
			return list.map((item) => {
				return Vgr.dtoMappings.measureUnit.fromV2ToCore(item);
			});
		}

		function setValue(value) {
			if (!value) {
				return;
			}

			var foundMeasureUnit = $scope.measureUnits.find((measureUnit) => measureUnit.id == value);

			$scope.model = foundMeasureUnit;
			$scope.selectedItem = foundMeasureUnit;
			callback();
		}

		function callback() {
			if ($scope.$ctrl.callback) {
				$scope.$ctrl.callback($scope.selectedItem, $scope.$ctrl.identifier);
			}
		}

		$scope.onChange = function (selectedValue) {
			$scope.selectedItem = selectedValue;
			callback();
		};

		this.$onChanges = function (obj) {
			if (obj.setter && !obj.setter.isFirstChange() && obj.setter.currentValue != obj.setter.previousValue) {
				setValue(obj.setter.currentValue);
			}
		};
	}
]);
