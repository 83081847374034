angular.module("vgresiduos").component("residueReportCsvExport", {
	controller: "ResidueReportCsvExportCtrl",
	templateUrl: "views/report/residue/base/residueReportCsvExport/residueReportCsvExport_template.html",
	bindings: {
		reportId: "<",
		header: "<",
		data: "<",
		filenamePreffix: "@"
	}
});
