angular.module("vgresiduos").controller("MeasureUnitConverterCtrl", [
	"$scope",
	"$q",
	"httpService",
	"measureUnitService",
	"calculusService",
	"residueService",
	"dialogService",
	function ($scope, $q, httpService, measureUnitService, calculusService, residueService, dialogService) {
		"use strict";

		const defaultMassMeasureUnitId = Vgr.constants.measureUnit.Kilo.Id;
		const defaultVolumeMeasureUnitId = Vgr.constants.measureUnit.M3.Id;
		const defaultUnitMeasureUnitId = Vgr.constants.measureUnit.Unit.Id;

		$scope.volumeEnum = Vgr.enumerations.measureUnit.Group.Volume;
		$scope.unitEnum = Vgr.enumerations.measureUnit.Group.Unit;

		$scope.decimals = 4;

		$scope.volumeQuantityChanged = function (quantity) {
			calculateVolumeConversionFactor(
				quantity,
				$scope.converterVolumeModel.Unit,
				$scope.converterVolumeModel.massEquivalentQuantity,
				$scope.converterVolumeModel.massEquivalentUnit
			);
		};

		$scope.volumeUnitChanged = function (unit) {
			calculateVolumeConversionFactor(
				$scope.converterVolumeModel.Quantity,
				unit,
				$scope.converterVolumeModel.massEquivalentQuantity,
				$scope.converterVolumeModel.massEquivalentUnit
			);
		};

		$scope.volumeEquivalentChanged = function (massEquivalentQuantity) {
			calculateVolumeConversionFactor(
				$scope.converterVolumeModel.Quantity,
				$scope.converterVolumeModel.Unit,
				massEquivalentQuantity,
				$scope.converterVolumeModel.massEquivalentUnit
			);
		};

		$scope.volumeMassUnitChanged = function (massEquivalentUnit) {
			calculateVolumeConversionFactor(
				$scope.converterVolumeModel.Quantity,
				$scope.converterVolumeModel.Unit,
				$scope.converterVolumeModel.massEquivalentQuantity,
				massEquivalentUnit
			);
		};

		$scope.unitQuantityChanged = function (quantity) {
			calculateUnitConversionFactor(
				quantity,
				$scope.converterUnitModel.massEquivalentQuantity,
				$scope.converterUnitModel.massEquivalentUnit
			);
		};

		$scope.unitEquivalentChanged = function (massEquivalentQuantity) {
			calculateUnitConversionFactor(
				$scope.converterUnitModel.Quantity,
				massEquivalentQuantity,
				$scope.converterUnitModel.massEquivalentUnit
			);
		};

		$scope.unitMassUnitChanged = function (massEquivalentUnit) {
			calculateUnitConversionFactor(
				$scope.converterUnitModel.Quantity,
				$scope.converterUnitModel.massEquivalentQuantity,
				massEquivalentUnit
			);
		};

		this.$onInit = function () {
			listResidueConversionFactorsHistory();
			$scope.converterVolumeModel = {};
			$scope.converterUnitModel = {};
			loadMeasureUnits().then(setDefaultParams);
		};

		function loadMeasureUnits() {
			var deferrend = $q.defer();

			var dto = {
				onlyResidueUnits: true
			};
			httpService.getListFromServiceV2(measureUnitService.listMeasureUnits, dto, "MeasureUnits").then(
				function (list) {
					setMeasureUnitsGroups(list);
					deferrend.resolve();
				},
				function (data) {
					deferrend.reject();
				}
			);

			return deferrend.promise;
		}

		function setMeasureUnitsGroups(list) {
			$scope.volumeMeasureUnits = [];
			$scope.massMeasureUnitsToVolume = [];
			$scope.massMeasureUnitsToUnit = [];
			for (var i = 0; i < list.length; i++) {
				switch (list[i].Group) {
					case $scope.volumeEnum:
						$scope.volumeMeasureUnits.push(list[i]);
						break;
					case $scope.unitEnum:
						if (list[i].Id == defaultUnitMeasureUnitId) {
							$scope.converterUnitModel.Unit = list[i];
						}
						break;
					case Vgr.enumerations.measureUnit.Group.Mass:
						var massUnitToVolume = {};
						var massUnitToUnit = {};

						angular.copy(list[i], massUnitToVolume);
						angular.copy(list[i], massUnitToUnit);

						$scope.massMeasureUnitsToVolume.push(massUnitToVolume);
						$scope.massMeasureUnitsToUnit.push(massUnitToUnit);
						break;
				}
			}
		}

		function setDefaultParams() {
			$scope.converterVolumeModel.Quantity = 1;
			$scope.converterUnitModel.Quantity = 1;
			if ($scope.ctrl.setAllParams) {
				if ($scope.ctrl.volumeConversionFactor) {
					$scope.converterVolumeModel.Unit = $scope.volumeMeasureUnits.find(
						(mu) => (mu.Id = defaultVolumeMeasureUnitId)
					);
					$scope.converterVolumeModel.massEquivalentQuantity = $scope.ctrl.volumeConversionFactor;
					$scope.converterVolumeModel.massEquivalentUnit = $scope.massMeasureUnitsToVolume.find(
						(mu) => (mu.Id = defaultMassMeasureUnitId)
					);
				}
				if ($scope.ctrl.unitConversionFactor) {
					$scope.converterUnitModel.massEquivalentQuantity = $scope.ctrl.unitConversionFactor;
					$scope.converterUnitModel.massEquivalentUnit = $scope.massMeasureUnitsToUnit.find(
						(mu) => (mu.Id = defaultMassMeasureUnitId)
					);
				}
			}
		}

		function calculateVolumeConversionFactor(quantity, unit, massEquivalentQuantity, massEquivalentUnit) {
			if (!quantity || !unit || !massEquivalentQuantity || !massEquivalentUnit) {
				$scope.ctrl.volumeConversionFactor = null;
				return;
			}

			var dto = {
				Quantity: quantity,
				UnitId: unit.Id,
				FinalQuantity: massEquivalentQuantity,
				FinalUnitId: massEquivalentUnit.Id
			};

			httpService.postDTOToServiceV2(calculusService.calculateConversionFactor, dto).then(
				function (response) {
					$scope.ctrl.volumeConversionFactor = response.ConversionFactor;
				},
				function (response) {}
			);
		}

		function calculateUnitConversionFactor(quantity, massEquivalentQuantity, massEquivalentUnit) {
			if (!quantity || !massEquivalentQuantity || !massEquivalentUnit) {
				$scope.ctrl.unitConversionFactor = null;
				return;
			}

			var dto = {
				Quantity: quantity,
				UnitId: $scope.converterUnitModel.Unit.Id,
				FinalQuantity: massEquivalentQuantity,
				FinalUnitId: massEquivalentUnit.Id
			};

			httpService.postDTOToServiceV2(calculusService.calculateConversionFactor, dto).then(
				function (response) {
					$scope.ctrl.unitConversionFactor = response.ConversionFactor;
				},
				function (response) {}
			);
		}

		function listResidueConversionFactorsHistory() {
			if (!$scope.ctrl.setAllParams || !$scope.ctrl.residueId) {
				return;
			}

			listVolumeHistory();
			listUnitHistory();
		}

		function listVolumeHistory() {
			listConversionFactorHistory($scope.volumeEnum).then(function (list) {
				$scope.volumeConversionHisotry = list;
			});
		}

		function listUnitHistory() {
			listConversionFactorHistory($scope.unitEnum).then(function (list) {
				$scope.unitConversionHisotry = list;
			});
		}

		function listConversionFactorHistory(measureUnitGroup) {
			var deferred = $q.defer();

			var dto = {
				id: $scope.ctrl.residueId,
				measureUnitGroup: measureUnitGroup
			};

			httpService
				.getListFromServiceV2(residueService.listResidueConversionFactorsHistory, dto, "ResidueConversionFactors")
				.then(
					function (list) {
						deferred.resolve(list);
					},
					function () {
						deferred.reject();
					}
				);

			return deferred.promise;
		}

		$scope.openHistoryPopup = function (group, list) {
			list = list.sort(function (a, b) {
				return Vgr.util.getDateFromString(b.EndDate) - Vgr.util.getDateFromString(a.EndDate);
			});

			var historyDataList = [];

			for (var item of list) {
				var endDate = Vgr.util.getDateFromString(item.EndDate);
				endDate.setDate(endDate.getDate() - 1);

				historyDataList.push({
					data: Vgr.util.formatUnitDecimalPlaces(item.Value, $scope.decimals),
					date: Vgr.util.dateToString(endDate)
				});
			}

			const params = {
				popupTitle: $scope.labels.CONVERSION_HISOTRY_POPUP_TITLE[group],
				header: [
					{
						description: $scope.labels.CONVERSION_FACTOR + " " + $scope.labels.CONVERSION_FACTOR_ABBREVIATION[group],
						property: "data"
					},
					{
						description: $scope.labels.VALID_UNTIL,
						property: "date"
					}
				],
				list: historyDataList
			};

			dialogService.showDialogFromTemplateV2(
				"views/components/popups/historyDataPopup_template.html",
				"HistoryDataPopupCtrl",
				null,
				params
			);
		};
	}
]);
