angular.module("vgresiduos").component("yearSelectFieldV2", {
	controller: "yearSelectFieldV2Ctrl",
	templateUrl: "views/components/fields/yearSelectFieldV2/yearSelectFieldV2_template.html",
	bindings: {
		minYear: "=",
		maxYear: "=",
		model: "=",
		setterYear: "<?",
		hasEmptyField: "=?",
		changed: "=?",
		isDisabled: "<?"
	}
});
