angular.module("vgresiduos").controller("OrganizationEditCtrl", [
	"$rootScope",
	"$scope",
	"$state",
	"httpService",
	"$q",
	"organizationService",
	"permissionService",
	"accountService",
	"userAccessService",
	function (
		$rootScope,
		$scope,
		$state,
		httpService,
		$q,
		organizationService,
		permissionService,
		accountService,
		userAccessService
	) {
		"use strict";

		const editOrganizationDataPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.EditOrganization);

		$scope.editOrganizationSupplierPreferencesPermission = permissionService.hasPermissionV2(
			Vgr.systemPermissions.SupplierPreferences
		);

		$scope.editOrganizationChartPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.OrganizationChart);

		const currentClient = accountService.getCurrentClient();
		$scope.labels = $rootScope.labels;
		$scope.model = {};

		$scope.editDataPermission = editOrganizationDataPermission;

		const isLowerGenerationPlan = userAccessService.hasAccess(
			null,
			Vgr.constants.featureToggles.permission.lowerGenerationPlan,
			null
		);

		$rootScope.contentNavBar = {
			title: $scope.labels.ORGANIZATION,
			save: function () {
				return updateOrganization();
			},
			valid: true,
			validate: function () {
				return validate();
			}
		};

		function goHome() {
			accountService.goHome();
		}

		this.$onInit = function () {
			getOrganization();
		};

		function getOrganization() {
			$scope.loading = true;
			httpService.getDTOFromServiceV2(organizationService.getOrganizationCore, currentClient.organization.id).then(
				function (data) {
					buildOrganizationModel(data);
					$scope.loading = false;
				},
				function () {
					goHome();
				}
			);
		}

		function updateOrganization() {
			const deferred = $q.defer();

			const dto = getOrganizationDto();

			httpService.postDTOToServiceV2(organizationService.updateOrganizationCore, dto).then(
				function () {
					$state.go("organizationEdit", { reload: true });
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function getOrganizationDto() {
			return {
				id: $scope.model.organizationId,
				model: {
					Name: $scope.model.orgName,
					OwnerIds: $scope.model.owners ? $scope.model.owners : [],
					IbamaActivities: $scope.model.orgIbamaActivities,
					IbamaCnpjs: $scope.model.ibamaCnpjs
				}
			};
		}

		function buildOrganizationModel(data) {
			const orgIbamaActivities = [];

			data.ibamaActivities.map((el) => orgIbamaActivities.push(el.code));

			$scope.model.organizationId = data.id;
			$scope.model.orgName = data.name;
			$scope.model.ownerUserIds = data.ownerUserIds;
			$scope.model.orgIbamaActivities = orgIbamaActivities;
			$scope.model.ibamaCnpjs = data.ibamaCnpjs;
		}

		function validate() {
			if (!$scope.model.orgName) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.VALIDATION_ERROR_MESSAGE);
				return false;
			}
			if (!$scope.model.organizationId) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.NO_CLIENT);
				return false;
			}
			return true;
		}

		$scope.organizationSupplierPreferencesTabSelectedClick = function () {
			$scope.organizationSupplierPreferencesTabSelected = true;
			$scope.organizationChartTabSelected = false;
			$rootScope.contentNavBar.save = null;
			if (!$scope.editOrganizationSupplierPreferencesPermission) {
				$rootScope.contentNavBar.buttons = [];
			}
		};

		$scope.organizationChartTabSelectedClick = function () {
			$scope.organizationChartTabSelected = true;
			$scope.organizationSupplierPreferencesTabSelected = false;
			$rootScope.contentNavBar.save = null;
			if (!$scope.editOrganizationChartPermission) {
				$rootScope.contentNavBar.buttons = [];
			}
		};

		$scope.generalAndIbamaTabSelectedClick = function () {
			$scope.organizationSupplierPreferencesTabSelected = false;
			$scope.organizationChartTabSelected = false;
			$rootScope.contentNavBar.save = updateOrganization;
			$rootScope.contentNavBar.buttons = [$rootScope.labels.SAVE];
		};

		$scope.showIbamaTab = function () {
			return !isLowerGenerationPlan;
		};

		$scope.showSuppliersTab = function () {
			return !isLowerGenerationPlan;
		};
	}
]);
