angular.module("vgresiduos").controller("DestinationArrivalCtrl", [
	"$scope",
	function ($scope) {
		"use strict";

		$scope.arrivalCompletedActions = [
			{
				Title: $scope.labels.CONFIRM_DISPOSAL,
				Icon: "fact_check",
				IsRaised: true,
				Action: function () {
					$scope.destinationConfig.currentStage.IsCompleted = true;
					$scope.destinationConfig.currentStage = $scope.stagesConfig[$scope.stagesEnum.Confirmation];
					$scope.destinationConfig.enabledStagePointer++;
					$scope.destinationConfig.currentStepPointer =
						$scope.destinationConfig.currentStage.Steps[$scope.stepsEnum.A].Id;
					$scope.destinationConfig.enabledStepPointer =
						$scope.destinationConfig.currentStage.Steps[$scope.stepsEnum.A].Id;
				},
				Enabled: function () {
					return true;
				}
			}
		];

		$scope.isResidueStep = function () {
			return $scope.destinationConfig.currentStepPointer == $scope.stepsEnum.A;
		};

		$scope.isManifesStep = function () {
			return $scope.destinationConfig.currentStepPointer == $scope.stepsEnum.B;
		};

		$scope.enableStep = function (stepId) {
			if ($scope.isCurrentStageReview()) {
				return;
			}

			if (!isStepEnabled(stepId)) {
				$scope.destinationConfig.enabledStepPointer = stepId;
			}
		};

		$scope.enableBackToStep = function (stepId) {
			if ($scope.isCurrentStageReview()) {
				return;
			}

			$scope.destinationConfig.enabledStepPointer = stepId;
		};

		function isStepEnabled(stepId) {
			return stepId <= $scope.destinationConfig.enabledStepPointer;
		}
	}
]);
