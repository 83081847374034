angular.module("vgresiduos").factory("cultureHeaderInjector", [
	"$injector",
	function ($injector) {
		"use strict";

		return {
			request: function (config) {
				if (config) {
					var translationService = $injector.get("translationService");
					var currentLanguage = translationService.getLanguage();
					if (currentLanguage) {
						config.headers["Accept-Language"] = currentLanguage;
					}
				}
				return config;
			}
		};
	}
]);
