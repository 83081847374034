angular.module("vgresiduos").controller("AddressFieldsetFormCtrl", [
	"$rootScope",
	"$scope",
	"httpService",
	"locationService",
	function ($rootScope, $scope, httpService, locationService) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.changeCountry = changeCountry;

		this.$onInit = function () {
			if ($scope.$ctrl.cityId) {
				getCity($scope.$ctrl.cityId);
			} else {
				setCountryId(Vgr.constants.country.Brazil);
				ManageAddressFields($scope.$ctrl.countryId);
			}
		};

		function getCity(cityId) {
			httpService.getDTOFromServiceV2(locationService.getCity, cityId).then(function (cityResponse) {
				formatCity(cityResponse);
				$scope.$ctrl.city = cityResponse;
				setCountryId(cityResponse.state.countryId);
				ManageAddressFields($scope.$ctrl.countryId);
			});
		}

		function setCountryId(countryId) {
			$scope.$ctrl.countryId = countryId;
		}

		function ManageAddressFields(countryId) {
			calculateIfShowsMask(countryId);
			setCityFieldLabel(countryId);
		}

		function changeCountry(value) {
			setCountryId(value);
			calculateIfShowsMask(value);
			setCityFieldLabel(value);
			clearAllFields(value);
		}

		function calculateIfShowsMask(value) {
			$scope.showCEPMask = value == Vgr.constants.country.Brazil;
		}

		function setCityFieldLabel(value) {
			if (value == Vgr.constants.country.Peru) {
				$scope.labelCity = $scope.labels.PROVINCIA_DEPARTAMENTO;
			} else {
				$scope.labelCity = $scope.labels.CITY_STATE;
			}
		}

		function clearAllFields(isCountryChanged) {
			if (isCountryChanged) {
				$scope.$ctrl.neighborhood = null;
				$scope.$ctrl.cep = null;
				$scope.$ctrl.address = null;
				$scope.$ctrl.city = null;
			}
		}

		function formatCity(city) {
			if (city.name) {
				city.DescriptionToShow = Vgr.util.capitalizeText(city.name) + "- " + city.state.abbreviation;
			}
		}
	}
]);
