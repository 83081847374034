angular.module("vgresiduos").directive("ibamaRappGrid", function () {
	return {
		scope: true,
		controller: "IbamaRappGridCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/ibama/ibamaRappList/grid/ibamaRappGrid_template.html",
		bindToController: {
			year: "="
		}
	};
});
