angular.module("vgresiduos").directive("cadriDocumentSelectField", function () {
	return {
		scope: true,
		controller: "CadriDocumentSelectFieldCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/suppliers/cadriDocumentSelectField/cadriDocumentSelectField_template.html",
		bindToController: {
			supplierCode: "@",
			documentId: "=?",
			onChange: "=?",
			onLoadList: "=?",
			label: "@"
		}
	};
});
