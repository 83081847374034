angular.module("vgresiduos").factory("buildVersionInjector", [
	"localStorageService",
	"$location",
	"$timeout",
	function (localStorageService, $location, $timeout) {
		"use strict";

		return {
			response: function (config) {
				var actualVersion = localStorageService.get("build-version");
				var newVersion = config.headers("build-version");

				if (actualVersion && newVersion && actualVersion != newVersion) {
					if ($location.$$path.indexOf("/atualizando") < 0) {
						$location.path("/atualizando");
						localStorageService.set("build-version", config.headers("build-version"));
						$timeout(function () {
							$location.path("/");
							$timeout(function () {
								window.location.reload();
							}, 500);
						}, 2500);
					}
				} else if (!actualVersion && newVersion) {
					localStorageService.set("build-version", config.headers("build-version"));
				}
				return config;
			}
		};
	}
]);
