angular.module("vgresiduos").directive("customizedFilterOption", function () {
	return {
		scope: true,
		controller: "CustomizedFilterOptionCtrl",
		controllerAs: "ctrl",
		templateUrl:
			"views/components/customizedFilter/baseComponents/customizedFilterOption/customizedFilterOption_template.html",
		bindToController: {
			availableFields: "=",
			selected: "=",
			fieldLabel: "@",
			onChangeField: "=",
			disableOnChange: "=",
			isDisabled: "@?"
		}
	};
});
