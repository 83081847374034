angular.module("vgresiduos").factory("mapService", [
	"$http",
	function ($http) {
		"use strict";

		var mapService = {};

		var _map = {};

		var _add = function Add(key, value) {
			_map[key] = value;
		};

		var _remove = function Remove(key) {
			delete _map[key];
		};

		var _contains = function Contains(key) {
			return key in _map;
		};

		var _value = function Value(key) {
			if (!_contains(key)) {
				return null;
			}
			return _map[key];
		};

		mapService.add = _add;
		mapService.remove = _remove;
		mapService.contains = _contains;
		mapService.value = _value;
		return mapService;
	}
]);
