angular.module("vgresiduos").controller("DisposalReportsPopupCtrl", [
	"$rootScope",
	"$scope",
	"httpService",
	"dialogService",
	"disposalService",
	"destinationModel",
	function ($rootScope, $scope, httpService, dialogService, disposalService, destinationModel) {
		"use strict";

		$scope.destinationModel = destinationModel;
		$scope.labels = $rootScope.labels;

		$scope.generalReports = [];
		$scope.reportsByResidue = [];
		$scope.disposalReportPopup = { radio: {} };
		$scope.radioOptions = [
			{
				Id: Vgr.enumerations.reportExtension.Pdf,
				Label: $scope.labels.PDF,
				Image: "img/reportPopup/pdf.png",
				Extension: "PDF"
			},
			{
				Id: Vgr.enumerations.reportExtension.Word,
				Label: $scope.labels.WORD,
				Image: "img/reportPopup/word.png",
				Extension: "WORD"
			}
		];
		$scope.disposalReportPopup.radio = $scope.radioOptions[0];

		$scope.isReportDisabled = function (report) {
			for (const index in report.AvailableExtensions) {
				if (report.AvailableExtensions[index] == $scope.disposalReportPopup.radio.Id) {
					return false;
				}
			}

			return true;
		};

		$scope.extensionChanged = function () {
			for (const report of $scope.generalReports) {
				if ($scope.isReportDisabled(report)) {
					report.IsChecked = false;
				}
			}

			for (const reportByResidue of $scope.reportsByResidue) {
				for (const report of reportByResidue.reports) {
					if ($scope.isReportDisabled(report)) {
						report.IsChecked = false;
					}
				}
			}
		};

		$scope.cancel = function () {
			dialogService.hide($scope.dialog);
		};

		$scope.confirm = function () {
			generateReports();
		};

		function loadReports(allReports) {
			const residueMap = new Map();
			let generalReports = [];

			for (const report of allReports) {
				report.Description = getReportDescription(report);

				if (
					report.Type == Vgr.enumerations.destination.report.ResidueTransportManifest ||
					report.Type == Vgr.enumerations.destination.report.ProvisionalMTR
				) {
					generalReports.push(manageCheckboxReport(report));
				} else if (!report.ResidueId) {
					generalReports.push(report);
				} else {
					let reportsByResidue = residueMap.get(report.ResidueId);
					if (reportsByResidue === undefined) {
						reportsByResidue = {};
						reportsByResidue.residueName = $scope.destinationModel.Residues.find(
							(r) => r.ResidueId === report.ResidueId
						).ResidueName;
						reportsByResidue.reports = [];
					}

					reportsByResidue.reports.push(report);
					residueMap.set(report.ResidueId, reportsByResidue);
				}
			}

			const foundChecked = generalReports.find((r) => r.IsChecked);
			if (!foundChecked) {
				generalReports = getReportsWithCheckMtrDefaultMarked(generalReports);
			}
			$scope.generalReports = generalReports;
			$scope.reportsByResidue = Array.from(residueMap.values());
		}

		function getReportDescription(report) {
			const localReportType = Vgr.enumerations.destination.report.ResidueTransportManifest;
			const localSourceId = Vgr.enumerations.destination.systemSource.Local;

			const description = $scope.labels.DISPOSAL_REPORT_TYPES_ENUM[report.Type];
			if (report.Source.Id == localSourceId) {
				if (report.Type == localReportType) {
					return $scope.labels.LOCAL_MTR_REPORT;
				}

				return description;
			}

			return `${report.Source.Description} - ${description}`;
		}

		function generateReports() {
			$scope.loadingReports = true;

			const dto = {
				DisposalId: $scope.destinationModel.Id,
				model: createDtoToGenerateReport()
			};

			if (dto.model.Reports.length == 0) {
				$scope.loadingReports = false;
				dialogService.hide($scope.dialog);
				return;
			}

			httpService.postDTOToServiceV2(disposalService.generateDisposalReports, dto, false, true).then(
				function (data) {
					if (data && data.length > 0) {
						const fileDTO = Vgr.util.fileDtoToFile(Vgr.util.capitalizeObjectKeys(data[0]));
						// eslint-disable-next-line no-undef
						saveAs(fileDTO, fileDTO.FileName);
					}
					$scope.loadingReports = false;
				},
				function () {
					$scope.loadingReports = false;
				}
			);
		}

		function createDtoToGenerateReport() {
			return {
				Format: $scope.disposalReportPopup.radio.Extension,
				Reports: listReportsToPrint()
			};
		}

		function listReportsToPrint() {
			let reportsToPrint = [];

			reportsToPrint = reportsToPrint.concat(listGeneralReportsToPrint());
			reportsToPrint = reportsToPrint.concat(listResidueReportsToPrint());

			return reportsToPrint;
		}

		function listGeneralReportsToPrint() {
			const reportsToPrint = [];
			for (const report of $scope.generalReports) {
				if (report.IsChecked) {
					reportsToPrint.push(createReportDto(report));
				}
			}

			return reportsToPrint;
		}

		function listResidueReportsToPrint() {
			const reportsToPrint = [];
			for (const reportByResidue of $scope.reportsByResidue) {
				for (const report of reportByResidue.reports) {
					if (report.IsChecked) {
						reportsToPrint.push(createReportDto(report));
					}
				}
			}

			return reportsToPrint;
		}

		function createReportDto(report) {
			return {
				Type: report.Type,
				ResidueId: report.ResidueId,
				SourceId: report.Source.Id
			};
		}

		function listDisposalReportstoPrint() {
			$scope.loadingReports = true;
			const dto = {
				DisposalId: $scope.destinationModel.Id
			};
			httpService.getListFromServiceV2(disposalService.listDisposalReportstoPrint, dto, "Reports").then(
				function (list) {
					loadReports(list);
					$scope.loadingReports = false;
				},
				function (data) {
					$scope.loadingReports = false;
				}
			);
		}

		function manageCheckboxReport(report) {
			const reportWithChecked = { ...report };
			reportWithChecked.IsChecked = false;
			const foundManifest = $scope.destinationModel.Manifests.find(
				(m) =>
					m.Source.Id != Vgr.enumerations.destination.systemSource.Local && m.Source.Id == reportWithChecked.Source.Id
			);

			if (foundManifest) {
				reportWithChecked.IsChecked = true;
			}

			return reportWithChecked;
		}

		function getReportsWithCheckMtrDefaultMarked(reports) {
			const reportsWithMtrDefault = [];
			reports.forEach((r) => {
				if (
					r.Type === Vgr.enumerations.destination.report.ResidueTransportManifest ||
					r.Type === Vgr.enumerations.destination.report.ProvisionalMTR
				) {
					r.IsChecked = true;
				}
				reportsWithMtrDefault.push(r);
			});
			return reportsWithMtrDefault;
		}

		function initializePopup() {
			listDisposalReportstoPrint();
		}

		initializePopup();
	}
]);
