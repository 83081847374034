angular.module("vgresiduos").controller("CustomReportFilterDataSectionViewCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			$scope.label = "";
			$scope.informationDescription = $scope.$ctrl.setter[0].information.description;
		};
	}
]);
