angular.module("vgresiduos").factory("goalService", [
	"$http",
	"accountService",
	function ($http, accountService) {
		"use strict";

		var goalService = {};

		var _listGoals = function ListGoals(dto) {
			var organizationId = accountService.getCurrentClient().organization.id;
			var organizationUnitCode = accountService.getCurrentClient().code;
			var parameters = null;

			if (dto) {
				parameters = $.param(dto);
			}

			var req = {
				method: "get",
				url:
					Vgr.constants.biHostUrl +
					Vgr.resources.bi.goals
						.replace("[ORGANIZATION_ID]", organizationId)
						.replace("[ORGANIZATION_UNIT]", organizationUnitCode) +
					"?" +
					parameters
			};

			return $http(req);
		};

		var _createGoal = function CreateGoal(dto) {
			var organizationId = accountService.getCurrentClient().organization.id;
			var organizationUnitCode = accountService.getCurrentClient().code;

			var req = {
				method: "post",
				data: dto,
				url:
					Vgr.constants.biHostUrl +
					Vgr.resources.bi.goals
						.replace("[ORGANIZATION_ID]", organizationId)
						.replace("[ORGANIZATION_UNIT]", organizationUnitCode)
			};

			return $http(req);
		};

		var _updateGoal = function UpdateGoal(dto) {
			var organizationId = accountService.getCurrentClient().organization.id;
			var organizationUnitCode = accountService.getCurrentClient().code;

			var req = {
				method: "put",
				data: dto,
				url:
					Vgr.constants.biHostUrl +
					Vgr.resources.bi.goals
						.replace("[ORGANIZATION_ID]", organizationId)
						.replace("[ORGANIZATION_UNIT]", organizationUnitCode) +
					"/" +
					dto.id
			};

			return $http(req);
		};

		var _deleteGoal = function DeleteGoal(dto) {
			var organizationId = accountService.getCurrentClient().organization.id;
			var organizationUnitCode = accountService.getCurrentClient().code;

			var req = {
				method: "delete",
				url:
					Vgr.constants.biHostUrl +
					Vgr.resources.bi.goals
						.replace("[ORGANIZATION_ID]", organizationId)
						.replace("[ORGANIZATION_UNIT]", organizationUnitCode) +
					"/" +
					dto.id
			};

			return $http(req);
		};

		goalService.listGoals = _listGoals;
		goalService.createGoal = _createGoal;
		goalService.updateGoal = _updateGoal;
		goalService.deleteGoal = _deleteGoal;

		return goalService;
	}
]);
