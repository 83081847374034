angular.module("vgresiduos").directive("clientFormContact", function () {
	return {
		scope: true,
		controller: "ClientFormContactCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/client/components/clientForm/clientFormContact/clientFormContact_template.html",
		bindToController: {
			inputClient: "=",
			saveOptions: "="
		}
	};
});
