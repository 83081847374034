angular.module("vgresiduos").controller("ClientExternalSystemPopupInputCnpjCpfCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.sgaPrId = Vgr.enumerations.destination.systemSource.SGA_PR;
	}
]);
