angular.module("vgresiduos").controller("ClientPropertyCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"additionalPropertyService",
	function ($scope, $rootScope, $q, httpService, additionalPropertyService) {
		"use strict";

		const labels = $rootScope.labels;

		let canCreateProperty;
		let clientProperties;
		let isListLoaded;

		$scope.additionalPropertyTypes = Vgr.enumerations.additionalProperty.type;

		$scope.propertiesPaginationOptions = {
			pageSize: 30,
			pageNumber: 1,
			query: "",
			id: 0
		};

		this.$onInit = function () {
			if (!$scope.ctrl.model) {
				$scope.ctrl.model = {};
			}

			canCreateProperty = $scope.ctrl.canCreate ? true : false;
			$scope.requireMatch = !canCreateProperty;

			if ($scope.ctrl.displayPropertyText) {
				$scope.displayPropertyText = $scope.ctrl.displayPropertyText;
			} else {
				$scope.displayPropertyText = canCreateProperty ? labels.SELECT_OR_CREATE_PROPERTY : labels.SELECT_PROPERTY;
			}

			$scope.displayValueText = labels.VALUE;
			if ($scope.ctrl.required) {
				$scope.displayPropertyText += "*";
				$scope.displayValueText += "*";
			}

			if ($scope.ctrl.setterPropertyId) {
				getProperty($scope.ctrl.setterPropertyId).then(onSuccessGetProperty, onErrorGetProperty);
			} else if ($scope.ctrl.setterPropertyName && canCreateProperty) {
				const property = createNewProperty($scope.ctrl.setterPropertyName);
				setProperty(property, true);
				setPropertyValue();
			}
		};

		function getProperty(propertyId) {
			const deferred = $q.defer();

			httpService.getDTOFromServiceV2(additionalPropertyService.getAdditionalProperty, propertyId).then(
				function (data) {
					deferred.resolve(data);
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function onSuccessGetProperty(property) {
			setProperty(property, true);
			setPropertyValue();
		}

		function onErrorGetProperty(data) {
			if (data.Status == Vgr.constants.httpStatus.Notfound && $scope.ctrl.setterPropertyName && canCreateProperty) {
				const property = createNewProperty($scope.ctrl.setterPropertyName);
				setProperty(property, true);
				setPropertyValue();
			} else {
				$scope.ctrl.model.Property = null;
			}
		}

		function setPropertyValue() {
			if ($scope.ctrl.model.Property.type == Vgr.enumerations.additionalProperty.type.SuspendList) {
				$scope.ctrl.model.PropertyValue = $scope.ctrl.model.Property.values.find(
					(a) => a.id == $scope.ctrl.setterPropertyValue
				);
			} else {
				$scope.ctrl.model.PropertyValue = $scope.ctrl.setterPropertyValue;
			}
		}

		function setProperty(property, isFromSetter) {
			property.isFromSetter = isFromSetter;
			$scope.ctrl.model.Property = property;
		}

		function createNewProperty(name) {
			return {
				Name: name,
				Type: Vgr.enumerations.additionalProperty.type.FreeText,
				Process: $scope.ctrl.processType,
				IsNew: true
			};
		}

		$scope.listClientProperties = function () {
			const deferred = $q.defer();
			let filteredList = "";

			if (!isListLoaded) {
				loadClientProperties().then(function () {
					filteredList = filterPropertyList();
					deferred.resolve(filteredList);
				});
			} else {
				filteredList = filterPropertyList();
				deferred.resolve(filteredList);
			}

			return deferred.promise;
		};

		function loadClientProperties() {
			const deferred = $q.defer();

			if (!$scope.ctrl.processType) {
				clientProperties = [];
				deferred.resolve();

				return deferred.promise;
			}

			const dto = {
				process: $scope.ctrl.processType
			};

			httpService.getListFromServiceCore(additionalPropertyService.listClientProperties, dto).then(
				function (additionalProperties) {
					clientProperties = additionalProperties.list;
					if ($scope.ctrl.model.Property && $scope.ctrl.model.Property.IsNew) {
						clientProperties.push($scope.ctrl.model.Property);
					}
					isListLoaded = true;

					deferred.resolve();
				},
				function () {
					clientProperties = [];
					deferred.resolve();
				}
			);

			return deferred.promise;
		}

		function filterPropertyList() {
			if (!$scope.propertiesPaginationOptions.query) {
				return clientProperties;
			}

			const filteredList = [];

			for (const property of clientProperties) {
				if (Vgr.util.isStringIncluded(property.Name, $scope.propertiesPaginationOptions.query)) {
					filteredList.push(property);
				}
			}

			return filteredList;
		}

		$scope.onPropertySelect = function (selectedProperty) {
			if (selectedProperty && selectedProperty.isFromSetter) {
				return;
			}

			if ($scope.ctrl.model) {
				$scope.ctrl.model.PropertyValue = null;
			}

			if ($scope.ctrl.onPropertyChange) {
				$scope.ctrl.onPropertyChange(selectedProperty);
			}
		};
	}
]);
