angular.module("vgresiduos").controller("ContentNavBarCtrl", [
	"$scope",
	"$state",
	"$stateParams",
	"$rootScope",
	function ($scope, $state, $stateParams, $rootScope) {
		"use strict";

		var permissionActionTypes = Vgr.enumerations.systemPermissions.permissionActionTypes;
		var hideDelete = false;
		var enableDelete = true;

		var onApplyAllFilters;
		var onCleanTargetFilter;

		this.$onInit = function () {
			onApplyAllFilters = $rootScope.$on(Vgr.constants.evtApplyAllFilters, function () {
				$scope.toShowAllFilters();
			});

			onCleanTargetFilter = $rootScope.$on(Vgr.constants.evtCleanTagFilter, function () {
				$scope.textTagsFilter = [];
				$rootScope.textTagsFilter = [];
			});

			getTitle();
			getStoredTagFilter();
			configureGridDeleteButton();

			$scope.labels = $rootScope.labels;
			$scope.showTextChipsField = $state.includes("storageList") || containsChipsFilter();
		};

		this.$onDestroy = function () {
			// disable the listener
			onApplyAllFilters();
			onCleanTargetFilter();

			// nullify the DOM-bound model
			$scope.domElement = null;
		};

		function hasPermissionGrid(actionType) {
			if (!$scope.ctrl.contentNavBarGrid) {
				return false;
			}

			var hasPermissionMethod = $scope.ctrl.contentNavBarGrid.hasPermission;
			if (!hasPermissionMethod) {
				return true;
			}

			return hasPermissionMethod(actionType);
		}

		function containsChipsFilter() {
			if ($scope.contentNavBarGrid && $scope.contentNavBarGrid.isChipsFilter) {
				return true;
			}
			return false;
		}

		$scope.addTextChip = function () {
			$rootScope.textTagsFilter = $scope.textTagsFilter;
			$rootScope.$broadcast(Vgr.constants.evtGridFilterTag);
		};

		function configureGridDeleteButton() {
			if (!$scope.ctrl.contentNavBarGrid) {
				return;
			}

			if ($scope.ctrl.contentNavBarGrid.showDeleteOnSelectedItens) {
				hideDelete = true;
			}

			if ($scope.ctrl.contentNavBarGrid.enableDeleteOnSelectedItens) {
				enableDelete = false;
			}

			$scope.ctrl.contentNavBarGrid.onSelectedItens = function (selected) {
				if (selected) {
					if ($scope.ctrl.contentNavBarGrid.showDeleteOnSelectedItens) {
						hideDelete = false;
					}

					if ($scope.ctrl.contentNavBarGrid.enableDeleteOnSelectedItens) {
						enableDelete = true;
					}

					$scope.hasAnyRowSelected = true;
				} else {
					if ($scope.ctrl.contentNavBarGrid.showDeleteOnSelectedItens) {
						hideDelete = true;
					}

					if ($scope.ctrl.contentNavBarGrid.enableDeleteOnSelectedItens) {
						enableDelete = false;
					}

					$scope.hasAnyRowSelected = false;
				}
			};
		}

		function getTitle() {
			if ($scope.ctrl.contentNavBarOptions && $scope.ctrl.contentNavBarOptions.title) {
				$scope.title = $scope.ctrl.contentNavBarOptions.title;
			} else {
				$scope.title = $scope.ctrl.contentNavBarGrid.title;
			}
		}

		$scope.getCurrentId = function () {
			if ($scope.ctrl.contentNavBarOptions.breadcrumb) {
				return $scope.ctrl.contentNavBarOptions.breadcrumb;
			} else if ($stateParams.id) {
				return $stateParams.id;
			} else {
				return $rootScope.labels.NEW;
			}
		};

		$scope.goBackToPreviousPage = function () {
			if ($scope.ctrl.contentNavBarOptions.returnState) {
				$state.go($scope.ctrl.contentNavBarOptions.returnState);
				$scope.ctrl.contentNavBarOptions.returnState = false;
			} else if ($scope.ctrl.contentNavBarOptions.back) {
				$scope.useBack($scope.ctrl.contentNavBarOptions.back);
			}
		};

		$scope.useBack = function (back) {
			if (back.constructor.name === "AsyncFunction") {
				back().then(function () {
					$scope.ctrl.contentNavBarOptions.back = false;
				});
			} else if (typeof back === "function") {
				back();
				$scope.ctrl.contentNavBarOptions.back = false;
			} else if (typeof back === "string") {
				$state.go(back);
				$scope.ctrl.contentNavBarOptions.back = false;
			}
		};

		$scope.showAllFilters = false;
		$scope.toShowAllFilters = function () {
			$scope.showAllFilters = !$scope.showAllFilters;
			if ($scope.ctrl.contentNavBarGrid.onShowAllFilters) {
				$scope.ctrl.contentNavBarGrid.onShowAllFilters($scope.showAllFilters);
			}
		};

		$scope.hasCustomizedFilter = function () {
			return $scope.isCustomizedFilter() && $scope.ctrl.contentNavBarGrid.hasActiveFilters();
		};

		$scope.isCustomizedFilterOpen = function () {
			return $scope.isCustomizedFilter() && $scope.showAllFilters;
		};

		$scope.isCustomizedFilter = function () {
			return $scope.ctrl.contentNavBarGrid.onShowAllFilters;
		};

		$scope.hideDeleteButton = function () {
			return hideDeleteButton() || !hasPermissionGrid(permissionActionTypes.Delete);
		};

		function hideDeleteButton() {
			if (hideDelete) {
				return true;
			}
			if (!$scope.ctrl.contentNavBarGrid) {
				return true;
			}
			if ($scope.ctrl.contentNavBarGrid.disableAll) {
				return true;
			}
			return $scope.ctrl.contentNavBarGrid.removeDelete;
		}

		$scope.enableDeleteButton = function () {
			return enableDelete;
		};

		$scope.hideNewButton = function () {
			return hideNewButton() || !hasPermissionGrid(permissionActionTypes.Create);
		};

		function hideNewButton() {
			if (!$scope.ctrl.contentNavBarGrid) {
				return true;
			}
			if ($scope.ctrl.contentNavBarGrid.enableNew) {
				return false;
			}
			if ($scope.ctrl.contentNavBarGrid.disableAll) {
				return true;
			}
			return $scope.ctrl.contentNavBarGrid.removeNew;
		}

		$scope.showCdfButton = function () {
			return $scope.ctrl.contentNavBarGrid && $scope.ctrl.contentNavBarGrid.cdf;
		};

		$scope.delete = function (event) {
			$rootScope.deleteEvt = event;
			$rootScope.$broadcast(Vgr.constants.evtGridDelete);
		};

		$scope.create = function (event) {
			$rootScope.createEvt = event;
			$rootScope.$broadcast(Vgr.constants.evtGridCreate);
		};

		$scope.showCdf = function (event) {
			$rootScope.CDFEvt = event;
			$rootScope.$broadcast(Vgr.constants.evtGridCDF);
		};

		$scope.onCustomPeriodDateFilterButtonClick = function () {
			$rootScope.$broadcast(Vgr.constants.evtGridFilter);
		};

		$scope.onDateFilterSelectChange = function (type) {
			$rootScope.dateType = type;
			$rootScope.$broadcast(Vgr.constants.evtGridFilterOnDate);
		};

		function getStoredTagFilter() {
			var storedTagsFilter =
				$scope.ctrl.contentNavBarGrid && $scope.ctrl.contentNavBarGrid.getSelectedTagsFilter
					? $scope.ctrl.contentNavBarGrid.getSelectedTagsFilter()
					: "";
			var storedTextFilter =
				$scope.ctrl.contentNavBarGrid && $scope.ctrl.contentNavBarGrid.getSelectedTextFilter
					? $scope.ctrl.contentNavBarGrid.getSelectedTextFilter()
					: "";
			if (storedTagsFilter && Array.isArray(storedTagsFilter)) {
				$scope.selectedTagsFilter = storedTagsFilter;
			} else {
				$scope.selectedTagsFilter = [];
			}
			if (storedTextFilter && Array.isArray(storedTextFilter)) {
				$scope.textTagsFilter = storedTextFilter;
			} else {
				$scope.textTagsFilter = [];
			}
		}

		$scope.$watch("selectedTagsFilter.length", function (newValue, oldValue) {
			if (newValue !== oldValue) {
				$rootScope.selectedTagsFilter = $scope.selectedTagsFilter;
			}
		});

		$scope.$watch("ctrl.contentNavBarGrid.showDeleteOnSelectedItens", function (newValue, oldValue) {
			if (newValue != oldValue) {
				configureGridDeleteButton();
			}
		});

		$scope.$watch("ctrl.contentNavBarGrid.customButtons", function (newValue, oldValue) {
			if (!newValue) {
				$scope.hasAnyRowSelected = false;
			}
			if (newValue != oldValue) {
				configureGridDeleteButton();
			}
		});

		$scope.getInsertButtonDisplayName = function () {
			if ($scope.ctrl.contentNavBarGrid && $scope.ctrl.contentNavBarGrid.insertButton) {
				return $scope.ctrl.contentNavBarGrid.insertButton.displayName;
			} else {
				return $rootScope.labels.INSERT;
			}
		};
	}
]);
