angular.module("vgresiduos").controller("DisposalValidationPopupCtrl", [
	"$scope",
	"dialogService",
	function ($scope, dialogService) {
		"use strict";

		$scope.goBack = function () {
			dialogService.hide($scope.dialog);
		};

		function setValidationDescriptions() {
			for (const validation of $scope.validations) {
				$scope.validationDescriptions.push($scope.getCustomValidationMessage(validation));
			}
		}

		function initializePopup() {
			$scope.validationDescriptions = [];
			setValidationDescriptions();
		}

		initializePopup();
	}
]);
