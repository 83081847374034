angular.module("vgresiduos").controller("QuantityFieldCtrl", [
	"$scope",
	"$rootScope",
	"scaleService",
	"httpService",
	"$timeout",
	"clientService",
	"permissionService",
	function ($scope, $rootScope, scaleService, httpService, $timeout, clientService, permissionService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.selectMeasureUnitPermission = permissionService.hasPermissionV2(
			Vgr.systemPermissions.UseNotDefaultMeasureUnit
		);

		$scope.buildScalingButton = false;
		$scope.isMeasureUnitEnabled = true;
		$scope.enableTwoStepsScaling = false;
		$scope.scalingProcess = Vgr.enumerations.scaling.process.Storage;
		$scope.decimals = clientService.getDecimalPlaces();

		$scope.scalingWeight = {
			totalWeight: "",
			weight: "",
			tare: "",
			scaleAuxiliar1: "",
			scaleAuxiliar2: "",
			scalingType: ""
		};

		$scope.measureUnit = {
			Id: "",
			Name: ""
		};

		$scope.measureUnitId = "";

		this.$onChanges = function (obj) {
			if (obj.setterQuantity && obj.setterQuantity.currentValue != obj.setterQuantity.previousValue) {
				$scope.scalingWeight.totalWeight = obj.setterQuantity.currentValue;
			} else if (obj.setterQuantity == null && $scope.scalingWeight.totalWeight != null) {
				$scope.scalingWeight.totalWeight = null;
			}

			if (obj.setterTareQuantity && obj.setterTareQuantity.currentValue != obj.setterTareQuantity.previousValue) {
				$scope.scalingWeight.tare = obj.setterTareQuantity.currentValue;
			}

			if (obj.setterGrossQuantity && obj.setterGrossQuantity.currentValue != obj.setterGrossQuantity.previousValue) {
				$scope.scalingWeight.weight = obj.setterGrossQuantity.currentValue;
			}

			if (
				obj.setterScaleAuxiliar1Weight &&
				obj.setterScaleAuxiliar1Weight.currentValue != obj.setterScaleAuxiliar1Weight.previousValue
			) {
				$scope.scalingWeight.scaleAuxiliar1 = obj.setterScaleAuxiliar1Weight.currentValue
					? obj.setterScaleAuxiliar1Weight.currentValue
					: "";
			}

			if (
				obj.setterScaleAuxiliar2Weight &&
				obj.setterScaleAuxiliar2Weight.currentValue != obj.setterScaleAuxiliar2Weight.previousValue
			) {
				$scope.scalingWeight.scaleAuxiliar2 = obj.setterScaleAuxiliar2Weight.currentValue
					? obj.setterScaleAuxiliar2Weight.currentValue
					: "";
			}

			if (obj.setterScalingType && obj.setterScalingType.currentValue != obj.setterScalingType.previousValue) {
				$scope.scalingWeight.scalingType = obj.setterScalingType.currentValue ? obj.setterScalingType.currentValue : "";
			}

			if (obj.setterMeasureUnit && obj.setterMeasureUnit.currentValue != obj.setterMeasureUnit.previousValue) {
				setMeasureUnit(obj.setterMeasureUnit.currentValue ? obj.setterMeasureUnit.currentValue : "");
			}

			if (obj.setterMin && obj.setterMin.currentValue != obj.setterMin.previousValue) {
				$scope.min = obj.setterMin.currentValue;
			}

			if (obj.setterMax && obj.setterMax.currentValue != obj.setterMax.previousValue) {
				$scope.max = obj.setterMax.currentValue;
			}

			if (obj.setterResidueCode && obj.setterResidueCode.currentValue != obj.setterResidueCode.previousValue) {
				$scope.residueCode = obj.setterResidueCode.currentValue;
			}

			if (
				obj.setterOriginalMeasureUnit &&
				obj.setterOriginalMeasureUnit.currentValue != obj.setterOriginalMeasureUnit.previousValue
			) {
				$scope.originalMeasureUnit = obj.setterOriginalMeasureUnit.currentValue;
			}

			if (obj.setterReferenceDate && obj.setterReferenceDate.currentValue != obj.setterReferenceDate.previousValue) {
				$scope.referenceDate = obj.setterReferenceDate.currentValue;
			}
		};

		function loadScalingFieldsIfPresent() {
			$scope.scalingWeight.totalWeight = $scope.$ctrl.setterQuantity;

			if (!$scope.$ctrl.setterScaleQuantity) {
				$scope.enableQuantityLimitValidation = true;
				return;
			}

			$scope.measureUnit = $scope.$ctrl.setterMeasureUnit;
			$scope.scalingWeight.tare = $scope.$ctrl.setterTareQuantity;
			$scope.scalingWeight.weight = $scope.$ctrl.setterGrossQuantity;
			$scope.scalingWeight.scaleAuxiliar1 = $scope.$ctrl.setterScaleAuxiliar1Weight;
			$scope.scalingWeight.scaleAuxiliar2 = $scope.$ctrl.setterScaleAuxiliar2Weight;
			$scope.scalingWeight.scalingType = $scope.$ctrl.setterScalingType;
			$scope.isMeasureUnitEnabled = false;
			$scope.enableQuantityLimitValidation = false;
			if ($scope.scalingWeight.scalingType) {
				$scope.enableTwoStepsScaling =
					$scope.scalingWeight.scalingType == Vgr.enumerations.scaling.type.TwoSteps ||
					$scope.scalingWeight.ScalingType == Vgr.enumerations.scaling.type.TwoStepsWithStorage
						? true
						: false;
				$scope.selectedScalingType = $scope.scalingWeight.scalingType;
			}
		}

		$scope.onScalingButtonClick = function () {
			$scope.enableQuantityLimitValidation = false;
			forceKgMeasureUnit();
		};

		$scope.onTareButtonClick = function () {
			$scope.enableQuantityLimitValidation = false;
			forceKgMeasureUnit();
		};

		$scope.onGrossWeightButtonClick = function () {
			$scope.enableQuantityLimitValidation = false;
			forceKgMeasureUnit();
		};

		$scope.measureUnitChanged = function (selectedMeasureUnit) {
			setMeasureUnit(selectedMeasureUnit);
			$scope.$ctrl.onMeasureUnitChanged(selectedMeasureUnit, $scope.$ctrl.identifier);
		};

		function forceKgMeasureUnit() {
			setMeasureUnitId(Vgr.enumerations.measureUnit.Unit.Kg);
			$scope.isMeasureUnitEnabled = false;
		}

		function setMeasureUnitId(measureUnitToSetId) {
			$scope.measureUnitId = measureUnitToSetId;
		}

		function setMeasureUnit(measureUnitToSet) {
			$scope.measureUnit = measureUnitToSet;
			setMeasureUnitId(measureUnitToSet && measureUnitToSet.Id);
			updateErrorMessages();
		}

		function updateErrorMessages() {
			$scope.maxValueErrorMessage = $scope.labels.MAXIMUM_QUANTITY_RESIDUE.replace(
				"{1}",
				$scope.measureUnit.Abbreviation
			);
			$scope.minValueErrorMessage = $scope.labels.MINIMUM_QUANTITY_RESIDUE.replace(
				"{1}",
				$scope.measureUnit.Abbreviation
			);
		}

		function loadUnitScalingConfigurations() {
			if ($scope.$ctrl.hideScalingButton) {
				return;
			}

			httpService.getListFromServiceV2(scaleService.listScales, $scope.$ctrl.process, "Scales").then(function (scales) {
				$scope.unitScalingConfigurations = scales;
				$scope.buildScalingButton = $scope.unitScalingConfigurations.length > 0;
			});
		}

		$scope.isToshowTwoStepsScaling = function (selectedScalingType) {
			$scope.onScalingTypeChanged(selectedScalingType);
			$scope.enableTwoStepsScaling =
				selectedScalingType == Vgr.enumerations.scaling.type.TwoSteps ||
				selectedScalingType == Vgr.enumerations.scaling.type.TwoStepsWithStorage;
		};

		$scope.onToggleTwoStepsScaling = function () {
			$scope.enableTwoStepsScaling = !$scope.enableTwoStepsScaling;
		};

		$scope.onTotalWeightChanged = function (weight) {
			updateTotalWeight(weight);
		};

		$scope.onTotalWeightChangedManually = function (weight) {
			$scope.$ctrl.onQuantityChangedManually(weight, $scope.$ctrl.identifier);
			if (!weight && weight !== 0) {
				$scope.isMeasureUnitEnabled = true;
			}
			updateTareWeight("");
			updateGrossWeight("");
			hideTwoStepsScalingFields();
			$scope.enableQuantityLimitValidation = true;
		};

		$scope.onUpdateTotalWeightTwoStepsScaling = function (totalWeight) {
			updateTotalWeight(totalWeight);
		};

		$scope.onUpdateWeightTwoStepsScaling = function (weight) {
			updateGrossWeight(weight);
		};

		$scope.onUpdateTareWeightTwoStepsScaling = function (weight) {
			updateTareWeight(weight);
		};

		$scope.onUpdateScaleAuxiliar1WeightTwoStepsScaling = function (weight) {
			updateScaleAuxiliar1Weight(weight);
		};

		$scope.onUpdateScaleAuxiliar2WeightTwoStepsScaling = function (weight) {
			updateScaleAuxiliar2Weight(weight);
		};

		$scope.onScalingTypeChanged = function (scalingType) {
			$scope.$ctrl.onScalingTypeChanged(scalingType, $scope.$ctrl.identifier);
			$scope.scalingWeight.scalingType = scalingType;
		};

		$scope.onScalingEraseClicked = function () {
			clearScalingWeights();
			hideTwoStepsScalingFields();
			$scope.isMeasureUnitEnabled = true;
		};

		function hideTwoStepsScalingFields() {
			$scope.enableTwoStepsScaling = false;
		}

		function clearScalingWeights() {
			updateScaleAuxiliar1Weight("");
			updateScaleAuxiliar2Weight("");
			updateTareWeight("");
			updateGrossWeight("");
			updateTotalWeight("");
			$scope.$ctrl.onClearScalingWeights($scope.$ctrl.identifier);
		}

		function updateTotalWeight(totalWeight) {
			$scope.$ctrl.onQuantityChanged(totalWeight, $scope.$ctrl.identifier);
			$scope.scalingWeight.totalWeight = totalWeight;
		}

		function updateScaleAuxiliar1Weight(weight) {
			$scope.scalingWeight.scaleAuxiliar1 = weight;
			$scope.$ctrl.onScaleAuxiliar1WeightChanged(weight, $scope.$ctrl.identifier);
		}

		function updateScaleAuxiliar2Weight(weight) {
			$scope.scalingWeight.scaleAuxiliar2 = weight;
			$scope.$ctrl.onScaleAuxiliar2WeightChanged(weight, $scope.$ctrl.identifier);
		}

		function updateGrossWeight(grossWeight) {
			$scope.scalingWeight.weight = grossWeight;
			$scope.$ctrl.onGrossQuantityChanged(grossWeight, $scope.$ctrl.identifier);
		}

		function updateTareWeight(tareWeight) {
			$scope.scalingWeight.tare = tareWeight;
			$scope.$ctrl.onTareQuantityChanged(tareWeight, $scope.$ctrl.identifier);
		}

		$scope.onCallTareWeight = function () {
			$scope.callTareWeight = true;
			$timeout(function () {
				$scope.callTareWeight = false;
			});
		};

		this.$onInit = function () {
			loadUnitScalingConfigurations();
			loadScalingFieldsIfPresent();
		};

		$scope.hasSomeError = function () {
			const errors = $scope.getErrorObj();
			return errors && typeof errors === "object" && Object.keys(errors).length > 0;
		};

		$scope.getErrorObj = function () {
			if ($scope.$ctrl.form && $scope.$ctrl.form[$scope.getName()]) {
				return $scope.$ctrl.form[$scope.getName()].$error;
			}
			return "";
		};

		$scope.getName = function () {
			return "quantity_" + JSON.stringify($scope.$ctrl.identifier);
		};

		$scope.$on("$destroy", function () {
			// disable the listener

			// nullify the DOM-bound model
			$scope.domElement = null;
		});
	}
]);
