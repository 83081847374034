angular.module("vgresiduos").controller("CustomReportFilterFilterSectionViewCtrl", [
	"$rootScope",
	"$scope",
	"dialogService",
	function ($rootScope, $scope, dialogService) {
		"use strict";

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;

			$scope.showPopupOption = false;

			showFilters();
		};

		function showFilters() {
			if ($scope.$ctrl.setter && $scope.$ctrl.setter.length > 0) {
				$scope.showFilters = true;
				setFiltersLabels();
			} else {
				$scope.showFilters = false;
			}
		}

		function setFiltersLabels() {
			if ($scope.$ctrl.setter.length <= 3) {
				setLabels($scope.$ctrl.setter.length);
			} else {
				setLabels(3);
				$scope.showFiltersCount = true;
				$scope.filtersQtd = $scope.$ctrl.setter.length + " " + $scope.labels.SELECTED_FILTERS;
			}
		}

		function setLabels(qtd) {
			$scope.filters = [];

			for (let i = 0; i < qtd; i++) {
				const filter = {};

				if ($scope.$ctrl.setter[i].additionalProperty) {
					filter.label = $scope.$ctrl.setter[i].additionalProperty.name;
				} else {
					filter.label = $scope.$ctrl.setter[i].systemReportProperty.description;
				}

				filter.value = $scope.$ctrl.setter[i].items
					.map(function (item) {
						return item.description;
					})
					.join(", ");
				$scope.filters.push(filter);
			}
		}

		$scope.openPopupFiltersView = function () {
			const params = {
				filters: $scope.$ctrl.setter
			};

			dialogService.showDialogFromTemplateV2(
				"views/customReports/components/customReportFilter/sectionsView/customReportFilterFiltersPopup/customReportFilterFiltersPopup_template.html",
				"customReportFilterFiltersPopupCtrl",
				null,
				params
			);
		};
	}
]);
