angular.module("vgresiduos").directive("dateFilter", function () {
	return {
		scope: {},
		controller: "DateFilterCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/dateFilter/dateFilter_template.html",
		bindToController: {
			selectedDates: "=",
			clear: "=?",
			type: "@?",
			dontLimitToday: "=?"
		},
		link: function (scope, element, attrs) {
			scope.type = attrs.type || "date";
		}
	};
});
