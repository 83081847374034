angular.module("vgresiduos").factory("actionPlanService", [
	"$http",
	"accountService",
	function ($http, accountService) {
		"use strict";

		function getBaseUrl(supplierId) {
			const organizationId = accountService.getCurrentClient().organization.id;

			return (
				Vgr.constants.coreHostUrl +
				Vgr.resources.core.actionPlans.replace("[ORGANIZATION_ID]", organizationId).replace("[SUPPLIER_ID]", supplierId)
			);
		}
		function getBaseUrlsForSupplier() {
			const supplierOrganizationId = accountService.getCurrentClient().organization.id;
			const supplierUnitId = accountService.getCurrentClient().id;

			return (
				Vgr.constants.coreHostUrl +
				Vgr.resources.core.supplierActionPlans
					.replace("[SUPPLIER_ORGANIZATION_ID]", supplierOrganizationId)
					.replace("[UNIT_ID]", supplierUnitId)
			);
		}

		// CRUD
		const _listActionPlans = function (dto) {
			let parameters = "";

			if (dto.queryParams) {
				parameters = $.param(dto.queryParams, true);
			}

			const req = {
				method: "get",
				url: getBaseUrl(dto.supplierId) + "?" + parameters
			};

			return $http(req);
		};

		const _createActionPlan = function (dto) {
			const data = {
				clientAuditId: dto.clientAuditId
			};

			const req = {
				method: "post",
				data: data,
				url: getBaseUrl(dto.supplierId)
			};

			return $http(req);
		};

		const _deleteActionPlan = function (dto) {
			const req = {
				method: "delete",
				url: getBaseUrl(dto.supplierId) + dto.actionPlanId
			};

			return $http(req);
		};

		const _getActionPlan = function (dto) {
			const req = {
				method: "get",
				url: getBaseUrl(dto.supplierId) + dto.actionPlanId
			};

			return $http(req);
		};

		const _updateActionPlan = function (dto) {
			const req = {
				method: "put",
				data: dto.data,
				url: getBaseUrl(dto.supplierId) + dto.actionPlanId
			};

			return $http(req);
		};

		// Supplier
		const _listSupplierActionPlans = function (dto) {
			let parameters = "";

			if (dto.queryParams) {
				parameters = $.param(dto.queryParams, true);
			}

			const req = {
				method: "get",
				url: getBaseUrlsForSupplier() + "?" + parameters
			};

			return $http(req);
		};

		const _getSupplierActionPlan = function (dto) {
			const req = {
				method: "get",
				url: getBaseUrlsForSupplier() + dto.actionPlanId
			};

			return $http(req);
		};

		// Others
		const _finalizeActionPlan = function (dto) {
			const req = {
				method: "post",
				url: getBaseUrl(dto.supplierId) + dto.actionPlanId + "/finalization"
			};

			return $http(req);
		};

		const _activateActionPlan = function (dto) {
			const req = {
				method: "post",
				url: getBaseUrl(dto.supplierId) + dto.actionPlanId + "/activation"
			};

			return $http(req);
		};

		const _executeActionPlanItem = function (dto) {
			const req = {
				method: "post",
				data: dto.data,
				url: getBaseUrl(dto.supplierId) + dto.actionPlanId + "/action-plan-items/" + dto.actionPlanItemId + "/execution"
			};

			return $http(req);
		};

		return {
			listActionPlans: _listActionPlans,
			createActionPlan: _createActionPlan,
			deleteActionPlan: _deleteActionPlan,
			getActionPlan: _getActionPlan,
			updateActionPlan: _updateActionPlan,
			finalizeActionPlan: _finalizeActionPlan,
			activateActionPlan: _activateActionPlan,
			executeActionPlanItem: _executeActionPlanItem,
			listSupplierActionPlans: _listSupplierActionPlans,
			getSupplierActionPlan: _getSupplierActionPlan
		};
	}
]);
