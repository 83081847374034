angular.module("vgresiduos").controller("ResidueOrganizationPopUpCtrl", [
	"$scope",
	"$rootScope",
	"dialogService",
	"linkedResiduesOrganization",
	"allResiduesOrganization",
	function ($scope, $rootScope, dialogService, linkedResiduesOrganization, allResiduesOrganization) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.checkList = [];
		$scope.selectAll = false;
		$scope.residueSearch = "";

		$scope.changeResidueOption = function (residueSearch) {
			$scope.checkList = [];
			filterCheckList(residueSearch.toLowerCase());
		};

		function filterCheckList(residueSearch) {
			var filterField = ["name", "code"];
			if (residueSearch == "") {
				$scope.checkList = $scope.checkList.concat(allResiduesOrganization);
			} else {
				var newList = Vgr.filters.filterBy(allResiduesOrganization, residueSearch, filterField);
				$scope.checkList = $scope.checkList.concat(newList);
			}
		}

		var cancelButton = {
			label: $scope.labels.CLOSE,
			class: "md-primary",
			click: function () {
				dialogService.cancel();
			},
			disabled: false
		};

		var confirmButton = {
			label: $scope.labels.SAVE,
			class: "md-primary md-raised",
			click: function () {
				if (validate()) {
					dialogService.confirm(getSelectedResidues());
				}
			},
			disabled: false
		};

		function getSelectedResidues() {
			return $scope.checkList.filter((cl) => cl.toEnabled).map((c) => c.id);
		}

		function listOrganizationResidues() {
			allResiduesOrganization.forEach((ro) => {
				if (linkedResiduesOrganization.find((lro) => lro == ro.id)) {
					ro.toEnabled = true;
				}
			});
			$scope.checkList = $scope.checkList.concat(allResiduesOrganization);
			$scope.popupActionButtons.push(confirmButton);
		}

		$scope.selectDeselectAll = function (selectAll) {
			for (let item of $scope.checkList) {
				if (selectAll) {
					item.toEnabled = true;
				} else {
					item.toEnabled = false;
				}
			}
		};

		function validate() {
			if ($scope.checkList.filter((cl) => !cl.toEnabled).length == $scope.checkList.length) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.NO_RESIDUE_SELECTED);
				return false;
			}
			return true;
		}

		initializePopUp();

		function initializePopUp() {
			$scope.popupActionButtons = [cancelButton];
			listOrganizationResidues();
		}
	}
]);
