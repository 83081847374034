angular.module("vgresiduos").factory("awsCognitoService", [
	"$q",
	function ($q) {
		"use strict";

		let cognitoUserWithSession = null;

		const _initiateVgrLogin = function (username, password) {
			const deferred = $q.defer();

			window.Amplify.Auth.signIn(username, password)
				.then(function (user) {
					cognitoUserWithSession = user;
					deferred.resolve(user);
				})
				.catch((err) => {
					deferred.reject(err);
				});

			return deferred.promise;
		};

		const _initiateCustomIdpLogin = function (customIdp) {
			const deferred = $q.defer();

			window.Amplify.Auth.federatedSignIn({ provider: customIdp }).then(function () {
				deferred.resolve();
			});

			return deferred.promise;
		};

		const _refreshSession = function () {
			const deferred = $q.defer();

			const currentUser = _getCurrentUser();
			if (!currentUser) {
				deferred.reject();
			} else {
				if (!cognitoUserWithSession) {
					cognitoUserWithSession = currentUser;
				}
				cognitoUserWithSession.getSession((err, session) => {
					if (err) {
						deferred.reject(err);
					} else {
						const tokens = _getTokens(session);
						deferred.resolve(tokens);
					}
				});
			}

			return deferred.promise;
		};

		const _completeNewPassword = function (password, requiredAttributes) {
			const deferred = $q.defer();

			if (!cognitoUserWithSession) {
				deferred.reject();
				return deferred.promise;
			}

			window.Amplify.Auth.completeNewPassword(cognitoUserWithSession, password, requiredAttributes)
				.then(function () {
					deferred.resolve();
				})
				.catch((err) => {
					deferred.reject(err);
				});

			return deferred.promise;
		};

		const _getTokens = function (session) {
			if (!session) {
				return null;
			}

			return {
				accessToken: session.getAccessToken().getJwtToken(),
				idToken: session.getIdToken().getJwtToken(),
				refreshToken: session.getRefreshToken().getToken()
			};
		};

		const _getCachedTokens = function () {
			const currentUser = _getCurrentUser();
			if (currentUser && currentUser.signInUserSession && currentUser.signInUserSession.isValid()) {
				return _getTokens(currentUser.signInUserSession);
			}
			return null;
		};

		const _isLoggedIn = function () {
			return _getCurrentUser() ? true : false;
		};

		const _logout = function () {
			const curUser = _getCurrentUser();
			if (curUser) {
				curUser.signOut();
			}
		};

		const _getCurrentUser = function () {
			return cognitoUserWithSession || window.Amplify.Auth.userPool.getCurrentUser();
		};

		const _getCurrentChallengeParams = function () {
			const loggedUser = _getCurrentUser();

			if (loggedUser && loggedUser.challengeParam) {
				return loggedUser.challengeParam.userAttributes;
			}

			return null;
		};

		return {
			initiateVgrLogin: _initiateVgrLogin,
			initiateCustomIdpLogin: _initiateCustomIdpLogin,
			logout: _logout,
			isLoggedIn: _isLoggedIn,
			refreshSession: _refreshSession,
			getCachedTokens: _getCachedTokens,
			completeNewPassword: _completeNewPassword,
			getCurrentChallengeParams: _getCurrentChallengeParams
		};
	}
]);
