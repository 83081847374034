angular.module("vgresiduos").controller("AutomaticCreateProvisionalMtrCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"provisionalMtrService",
	"disposalService",
	"residuesManagementService",
	"asyncTimeoutService",
	function (
		$scope,
		$rootScope,
		httpService,
		provisionalMtrService,
		disposalService,
		residuesManagementService,
		asyncTimeoutService
	) {
		"use strict";

		const maxProvisionalsToTake = 2;
		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			$scope.model = {
				provisionalMtrToUse: null
			};
			listAvailableProvisionalMtrs();
		};

		function listAvailableProvisionalMtrs() {
			const numberOfProvisionalsToTake = $scope.ctrl.calculateAutomaticCode ? maxProvisionalsToTake : 1;
			const dto = {
				ExternalSystemId: $scope.ctrl.externalSystemId,
				UnitId: $scope.ctrl.unitId,
				params: {
					skip: 0,
					take: numberOfProvisionalsToTake
				}
			};

			httpService.getListFromServiceCore(provisionalMtrService.listProvisionalMtrs, dto).then(
				function (response) {
					if (response.list.length != numberOfProvisionalsToTake) {
						$scope.noProvisionalMtrToUse = true;
					} else if ($scope.ctrl.calculateAutomaticCode) {
						$scope.model.multipleProvisionalMtrsToUse = true;
					} else {
						$scope.model.provisionalMtrToUse = response.list[0];
					}
				},
				function () {
					$scope.noProvisionalMtrToUse = true;
				}
			);
		}

		$scope.hasProvisionalMtrToUse = function () {
			return (
				($scope.model.provisionalMtrToUse && $scope.model.provisionalMtrToUse.id) ||
				$scope.model.multipleProvisionalMtrsToUse
			);
		};

		$scope.cancel = function (closeAfterAction) {
			$scope.provisionalMTRCanceled = true;
			if ($scope.ctrl.onCancel) {
				$scope.ctrl.onCancel($scope.ctrl.externalSystemId, closeAfterAction);
			}
		};

		$scope.confirm = function (closeAfterAction) {
			$scope.loadings.integration = true;

			if ($scope.ctrl.isToUpdate) {
				UpdateIntegrationAsync();
			} else {
				CreateIntegration().then(
					function () {
						onProvisionalMtrSave(closeAfterAction);
					},
					function () {
						$scope.loadings.integration = false;
					}
				);
			}
		};

		function onProvisionalMtrSave(closeAfterAction) {
			$scope.provisionalMTRCreated = true;
			printProvisionalMtr(closeAfterAction);
		}

		$scope.close = function () {
			if ($scope.ctrl.onConfirm) {
				$scope.ctrl.onConfirm($scope.ctrl.externalSystemId, true);
			}
		};

		function UpdateIntegrationAsync() {
			const dto = {
				disposalId: $scope.ctrl.disposalId,
				sourceId: $scope.ctrl.externalSystemId,
				ProvisionalMtrId: $scope.model.provisionalMtrToUse ? $scope.model.provisionalMtrToUse.id : null,
				UseProvisionalMtr: $scope.ctrl.calculateAutomaticCode
			};

			listenForEvents();
			httpService.postDTOToServiceV2(residuesManagementService.updateDisposalManifestsBySource, dto, true).then(
				function () {
					//async response, handled by socket
				},
				function () {
					$scope.isListeningForEvents = false;
					asyncTimeoutService.cancelAsyncTimer($scope.ctrl);
					unsubscribeTopic();
				}
			);
		}

		function CreateIntegration() {
			const dto = {
				disposalId: $scope.ctrl.disposalId,
				organizationUnitCode: $scope.ctrl.unitId,
				model: {
					SourceId: $scope.ctrl.externalSystemId,
					ProvisionalMtrId: $scope.model.provisionalMtrToUse ? $scope.model.provisionalMtrToUse.id : null,
					UseProvisionalMtr: $scope.ctrl.calculateAutomaticCode
				}
			};

			return httpService.postDTOToServiceV2(disposalService.createDisposalIntegration, dto, true);
		}

		function printProvisionalMtr(closeAfterAction) {
			const dto = {
				DisposalId: $scope.ctrl.disposalId,
				model: {
					Format: Vgr.enumerations.reportExtension.Pdf,
					Reports: [
						{
							Type: Vgr.enumerations.destination.report.ProvisionalMTR,
							SourceId: $scope.ctrl.externalSystemId
						}
					]
				}
			};

			httpService.postDTOToServiceV2(disposalService.generateDisposalReports, dto, false, true).then(
				function (data) {
					if (data && data.length > 0) {
						const fileDTO = Vgr.util.fileDtoToFile(Vgr.util.capitalizeObjectKeys(data[0]));
						// eslint-disable-next-line no-undef
						saveAs(fileDTO, fileDTO.FileName);
					}
					$scope.loadings.integration = false;
					if ($scope.ctrl.onConfirm) {
						$scope.ctrl.onConfirm($scope.ctrl.externalSystemId, closeAfterAction);
					}
				},
				function () {
					$scope.loadings.integration = false;
				}
			);
		}

		function checkIfProvisionalMtrIsAssociatedToDisposalmanifest() {
			const dto = {
				disposalId: $scope.ctrl.disposalId,
				sourceIds: [$scope.ctrl.externalSystemId]
			};

			httpService.postDTOToServiceV2(residuesManagementService.listDisposalManifests, dto, true).then(
				function (updatedManifests) {
					if (updatedManifestsContainsProvisionalMtr(updatedManifests)) {
						onProvisionalMtrSave();
					} else {
						$scope.loadings.integration = false;
					}
				},
				function () {
					$scope.loadings.integration = false;
				}
			);
		}

		function updatedManifestsContainsProvisionalMtr(updatedManifests) {
			if ($scope.ctrl.calculateAutomaticCode) {
				return updatedManifests.every((m) => m.provisionalMtrId);
			} else if ($scope.model.provisionalMtrToUse) {
				return updatedManifests.some((m) => m.provisionalMtrId == $scope.model.provisionalMtrToUse.id);
			}
		}

		//init socket methods -------------------------------------------------------------------------------
		function subscribeTopic() {
			$scope.observable$ = window.Amplify.API.graphql(
				window.Amplify.graphqlOperation(Vgr.constants.graphql.subscriptions, {
					name: getRoomName()
				})
			).subscribe({
				next: ({ value }) => callBackTopicSucces(JSON.parse(value.data.subscribe2channel.data)),
				error: () => {
					unsubscribeTopic();
					$scope.loadings.integration = false;
				}
			});
		}

		function callBackTopicSucces(data) {
			if (!$scope.isListeningForEvents) {
				return;
			}

			$scope.isListeningForEvents = false;
			asyncTimeoutService.cancelAsyncTimer($scope.ctrl);
			if (data.success) {
				onProvisionalMtrSave();
			} else {
				$scope.loadings.integration = false;
			}
			unsubscribeTopic();
		}

		function unsubscribeTopic() {
			if ($scope.observable$) {
				$scope.observable$.unsubscribe();
			}
		}
		function getRoomName() {
			return "disposal_" + $scope.ctrl.disposalId + "_source_" + $scope.ctrl.externalSystemId;
		}

		function listenForEvents() {
			$scope.isListeningForEvents = true;
			subscribeTopic();
			asyncTimeoutService.startAsyncCallTimer($scope.ctrl, onTimeoutNoResponseFromServer, 30000, 1);
		}

		function cancelAllTimers() {
			asyncTimeoutService.cancelAsyncTimer($scope.ctrl);
		}

		function onTimeoutNoResponseFromServer() {
			$scope.isListeningForEvents = false;
			checkIfProvisionalMtrIsAssociatedToDisposalmanifest();
			unsubscribeTopic();
		}

		//end socket methods -------------------------------------------------------------------------------

		$scope.$on("$destroy", function () {
			cancelAllTimers();
			unsubscribeTopic();
			// nullify the DOM-bound model
			$scope.domElement = null;
		});
	}
]);
