angular.module("vgresiduos").directive("disposalEditionBlockMessage", function () {
	return {
		scope: true,
		controller: "DisposalEditionBlockMessageCtrl",
		controllerAs: "ctrl",
		templateUrl:
			"views/destination/baseComponents/destinationData/disposalEditionBlockMessage/disposalEditionBlockMessage_template.html",
		bindToController: {
			title: "=?",
			hideTitleIcon: "=?",
			status: "=?"
		}
	};
});
