angular.module("vgresiduos").controller("RelativeKpisCustomizedFilterCtrl", [
	"$rootScope",
	"$scope",
	"$timeout",
	"accountService",
	"reportUtilService",
	function ($rootScope, $scope, $timeout, accountService, reportUtilService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.defaultMeasureUnit = Vgr.enumerations.reportFilters.measureUnitEnum.ConvertToDefaultMeasureUnitInGroup;

		const currentClient = accountService.getCurrentClient();

		const filterOptions = {
			selectedFilters: [],
			initialValues: [
				{
					id: reportUtilService.organizationUnitFilter.id,
					value: [currentClient.id]
				}
			],
			availableFields: []
		};

		function resetFilterOptions() {
			$scope.ctrl.indicator.filterOptions = null;
			$scope.ctrl.indicator.groupingOptions = null;
			$timeout(function () {
				$scope.ctrl.indicator.filterOptions = angular.copy(filterOptions);
				$scope.ctrl.indicator.filterOptions.availableFields = angular.copy(addAvailableFileds());
				$scope.ctrl.indicator.groupingOptions = angular.copy(getGroupingOptions());
			});
		}

		function addAvailableFileds() {
			if ($scope.ctrl.indicator.id == Vgr.enumerations.kpiDataType.GenerationQuantity) {
				return reportUtilService.residueGenerationReport.filters;
			} else if (
				$scope.ctrl.indicator.id == Vgr.enumerations.kpiDataType.DisposalQuantity ||
				$scope.ctrl.indicator.id == Vgr.enumerations.kpiDataType.DisposalCost
			) {
				return reportUtilService.residueDisposalReport.filters;
			} else if ($scope.ctrl.indicator.id == Vgr.enumerations.kpiDataType.ProductionQuantity) {
				return reportUtilService.productionReport.filters;
			} else if ($scope.ctrl.indicator.id == Vgr.enumerations.kpiDataType.BuiltArea) {
				return reportUtilService.unitBuiltAreaReport.filters;
			}
		}

		function getGroupingOptions() {
			if ($scope.ctrl.indicator.id == Vgr.enumerations.kpiDataType.GenerationQuantity) {
				return reportUtilService.residueGenerationReport.groupings;
			} else if (
				$scope.ctrl.indicator.id == Vgr.enumerations.kpiDataType.DisposalQuantity ||
				$scope.ctrl.indicator.id == Vgr.enumerations.kpiDataType.DisposalCost
			) {
				return reportUtilService.residueDisposalReport.groupings;
			} else if ($scope.ctrl.indicator.id == Vgr.enumerations.kpiDataType.ProductionQuantity) {
				return reportUtilService.productionReport.groupings;
			} else if ($scope.ctrl.indicator.id == Vgr.enumerations.kpiDataType.BuiltArea) {
				return reportUtilService.unitBuiltAreaReport.groupings;
			}
		}

		$scope.showCostsPreferences = function () {
			return $scope.ctrl.indicator && $scope.ctrl.indicator.id == Vgr.enumerations.kpiDataType.DisposalCost;
		};

		$scope.isProductionQuantityKpi = function () {
			return $scope.ctrl.indicator && $scope.ctrl.indicator.id == Vgr.enumerations.kpiDataType.ProductionQuantity;
		};

		$scope.showMeasureUnitPreferences = function () {
			return (
				$scope.ctrl.indicator &&
				($scope.ctrl.indicator.id == Vgr.enumerations.kpiDataType.GenerationQuantity ||
					$scope.ctrl.indicator.id == Vgr.enumerations.kpiDataType.DisposalQuantity ||
					$scope.ctrl.indicator.id == Vgr.enumerations.kpiDataType.ProductionQuantity)
			);
		};

		$scope.$watch("ctrl.indicator.id", function (newValue, oldValue) {
			if (newValue && newValue != oldValue) {
				if ($scope.showMeasureUnitPreferences()) {
					resetMeasureUnitPreferences();
				}
				resetFilterOptions();
			}
		});

		function resetMeasureUnitPreferences() {
			if ($scope.ctrl.isRelative && !$scope.isProductionQuantityKpi()) {
				$scope.defaultMeasureUnit = Vgr.enumerations.reportFilters.measureUnitEnum.ConvertToSpecificUnit;
			} else {
				$scope.defaultMeasureUnit = Vgr.enumerations.reportFilters.measureUnitEnum.ConvertToDefaultMeasureUnitInGroup;
			}
			$scope.ctrl.indicator.measureUnit = {
				measureUnitPreference: $scope.defaultMeasureUnit
			};
		}
	}
]);
