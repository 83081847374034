angular.module("vgresiduos").controller("EmergencySheetPhonesCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"clientService",
	"permissionService",
	function ($scope, $rootScope, $q, httpService, clientService, permissionService) {
		"use strict";

		var editPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.EditUnit);
		$scope.editPermission = editPermission;

		var states = {
			Amazonas: 19,
			DistritoFederal: 17,
			Sergipe: 18,
			Alagoas: 34,
			RioDeJaneiro: 10,
			EspiritoSanto: 11,
			RioGrandeDoNorte: 22,
			Paraiba: 21,
			SantaCatarina: 6,
			Pernambuco: 20,
			Amapa: 24,
			Ceara: 16,
			Acre: 23,
			Parana: 9,
			Roraima: 25,
			Rondonia: 26,
			SaoPaulo: 4,
			Piaui: 28,
			Tocantins: 27,
			Maranhao: 29,
			Goias: 12,
			RioGrandeDoSul: 15,
			MatoGrossoDoSul: 14,
			Bahia: 5,
			MinasGerais: 3,
			MatoGrosso: 13,
			Para: 8
		};

		this.$onInit = function () {
			loadEmergencySheetPhones();
		};

		function loadEmergencySheetPhones() {
			httpService.getListFromServiceV2(clientService.listEmergencySheetPhones, null, "EmergencySheetPhones").then(
				function (list) {
					buildSeetModel(list);
				},
				function (err) {
					$scope.sheet = {};
					$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.SERVER_CONNECTION_ERROR);
				}
			);
		}

		function buildSeetModel(list) {
			$scope.sheet = {};

			for (var item of list) {
				switch (item.StateId) {
					case states.Acre:
						$scope.sheet.Acre = item;
						break;
					case states.Alagoas:
						$scope.sheet.Alagoas = item;
						break;
					case states.Amapa:
						$scope.sheet.Amapa = item;
						break;
					case states.Amazonas:
						$scope.sheet.Amazonas = item;
						break;
					case states.Bahia:
						$scope.sheet.Bahia = item;
						break;

					case states.Ceara:
						$scope.sheet.Ceara = item;
						break;
					case states.DistritoFederal:
						$scope.sheet.DistritoFederal = item;
						break;
					case states.EspiritoSanto:
						$scope.sheet.EspiritoSanto = item;
						break;
					case states.Goias:
						$scope.sheet.Goias = item;
						break;
					case states.Maranhao:
						$scope.sheet.Maranhao = item;
						break;

					case states.MatoGrosso:
						$scope.sheet.MatoGrosso = item;
						break;
					case states.MatoGrossoDoSul:
						$scope.sheet.MatoGrossoDoSul = item;
						break;
					case states.MinasGerais:
						$scope.sheet.MinasGerais = item;
						break;
					case states.Para:
						$scope.sheet.Para = item;
						break;
					case states.Paraiba:
						$scope.sheet.Paraiba = item;
						break;

					case states.Parana:
						$scope.sheet.Parana = item;
						break;
					case states.Pernambuco:
						$scope.sheet.Pernambuco = item;
						break;
					case states.Piaui:
						$scope.sheet.Piaui = item;
						break;
					case states.RioDeJaneiro:
						$scope.sheet.RioDeJaneiro = item;
						break;
					case states.RioGrandeDoNorte:
						$scope.sheet.RioGrandeDoNorte = item;
						break;

					case states.RioGrandeDoSul:
						$scope.sheet.RioGrandeDoSul = item;
						break;
					case states.Rondonia:
						$scope.sheet.Rondonia = item;
						break;
					case states.Roraima:
						$scope.sheet.Roraima = item;
						break;
					case states.SantaCatarina:
						$scope.sheet.SantaCatarina = item;
						break;
					case states.SaoPaulo:
						$scope.sheet.SaoPaulo = item;
						break;

					case states.Sergipe:
						$scope.sheet.Sergipe = item;
						break;
					case states.Tocantins:
						$scope.sheet.Tocantins = item;
						break;
				}
			}
		}

		$scope.saveOptions = {
			valid: true,
			// returnState: 'emergencySheetPhones',
			buttons: [$rootScope.labels.SAVE],
			hasPermission: function () {
				return editPermission;
			},
			save: function () {
				var deferred = $q.defer();

				var dto = getDto();

				httpService.postDTOToServiceV2(clientService.updateEmergencySheetPhones, dto).then(
					function () {
						deferred.resolve();
					},
					function () {
						deferred.reject();
					}
				);

				return deferred.promise;
			}
		};

		function getDto() {
			var dto = {
				EmergencySheetPhones: []
			};

			for (var index in $scope.sheet) {
				if ($scope.sheet[index].StateId) {
					dto.EmergencySheetPhones.push($scope.sheet[index]);
				}
			}

			return dto;
		}

		$rootScope.contentNavBar = $scope.saveOptions;
		$rootScope.contentNavBar.title = $scope.labels.EMERGENCY_SHEET_PHONES_TITLE;
		$rootScope.contentNavBarGrid = false;
	}
]);
