angular.module("vgresiduos").controller("ClientRecipientMultiSelectCtrl", [
	"$rootScope",
	"$scope",
	"httpService",
	"recipientService",
	function ($rootScope, $scope, httpService, recipientService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.searchLabel = "";

		this.$onInit = function () {
			listRecipients();
		};

		function listRecipients() {
			httpService.getListFromServiceCore(recipientService.listOrganizationRecipients).then(function (response) {
				var list = response.list;
				list.forEach((element) => {
					element.descriptionFormated = element.code + " - " + element.name;
				});
				$scope.list = list;
			});
		}
	}
]);
