angular.module("vgresiduos").component("customReportFilterFilterSection", {
	controller: "CustomReportFilterFilterSectionCtrl",
	templateUrl:
		"views/customReports/components/customReportFilter/sections/customReportFilterFilterSection_template.html",
	bindings: {
		setter: "<?",
		reportInformation: "<",
		clear: "<",
		callback: "<?",

		isDisabled: "<?"
	}
});
