angular.module("vgresiduos").component("residueReportTotals", {
	controller: "ResidueReportTotalsCtrl",
	templateUrl: "views/report/residue/base/residueReportTotals/residueReportTotals_template.html",
	bindings: {
		data: "<",
		chartTitle: "@",
		decimalPlaces: "<?",
		subtitles: "<?"
	}
});
