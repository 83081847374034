angular.module("vgresiduos").controller("HistoryDataPopupCtrl", [
	"$scope",
	"dialogService",
	"popupTitle",
	"header",
	"list",
	function ($scope, dialogService, popupTitle, header, list) {
		"use strict";

		$scope.popupTitle = popupTitle;

		function buildGrid() {
			$scope.columns = [];

			for (var item of header) {
				$scope.columns.push({
					description: item.description,
					property: item.property,
					width: 100 / header.length + "%"
				});
			}

			$scope.list = list;
		}

		$scope.confirm = function () {
			dialogService.confirm();
		};

		function initializePopup() {
			buildGrid();
		}

		initializePopup();
	}
]);
