angular.module("vgresiduos").directive("yearSelectField", function () {
	return {
		scope: true,
		controller: "yearSelectFieldCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/fields/yearSelectField/yearSelectField_template.html",
		bindToController: {
			model: "=",
			referenceYear: "=",
			stopYear: "=?",
			hasEmptyField: "=",
			changed: "=",
			isDisabled: "<?",
			disableAutoSelect: "<?"
		}
	};
});
