angular.module("vgresiduos").controller("GoalsEditCtrl", [
	"$rootScope",
	"$scope",
	"$stateParams",
	"httpService",
	"residueV2Service",
	"goalService",
	"$q",
	"permissionService",
	function ($rootScope, $scope, $stateParams, httpService, residueV2Service, goalService, $q, permissionService) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.goalType = Vgr.enumerations.goal.type;

		$scope.canEdit = true;
		if (!permissionService.hasPermissionV2(Vgr.systemPermissions.GoalEdit)) {
			$scope.canEdit = false;
		}

		$scope.model = {};

		$rootScope.contentNavBar = {
			title: $scope.labels.GOAL_REGISTRY,
			returnState: "goalsList",
			removeButtonBar: !$scope.canEdit,
			save: saveGoals,
			valid: function () {
				if (!$scope.model.measureUnit) {
					return false;
				}
				return true;
			}
		};

		function saveDisposalGoal(deferred) {
			const dto = buildDisposalGoalDto();
			const service = getDisposalSaveService();
			if (!service) {
				$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $rootScope.labels.SAVE_SUCCESS_MESSAGE);
				deferred.resolve();
				return;
			}

			httpService.postDTOToServiceV2(service, dto).then(
				function (goalId) {
					updateGoalId($scope.model.goals.disposal, goalId);
					$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $rootScope.labels.SAVE_SUCCESS_MESSAGE);
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);
		}

		function saveGoals() {
			const deferred = $q.defer();
			const dto = buildResidueGenerationGoalDto();
			const service = getResidueGenerationSaveService();
			if (!service) {
				saveDisposalGoal(deferred);
			} else {
				httpService.postDTOToServiceV2(service, dto).then(
					function (goalId) {
						updateGoalId($scope.model.goals.residueGeneration, goalId);
						saveDisposalGoal(deferred);
					},
					function () {
						deferred.reject();
					}
				);
			}
			return deferred.promise;
		}

		function updateGoalId(goal, goalId) {
			if ($scope.removeGoalIdResidueGenerationAfterDelete || $scope.removeGoalIdDisposalAfterDelete) {
				goal.id = "";
			} else if (goalId) {
				goal.id = goalId;
			}
		}

		function getDisposalSaveService() {
			$scope.removeGoalIdDisposalAfterDelete = false;
			if ($scope.model.goals.disposal.id) {
				if (hasAnyAmount($scope.model.goals.disposal)) {
					return goalService.updateGoal;
				}
				$scope.removeGoalIdDisposalAfterDelete = true;
				return goalService.deleteGoal;
			} else {
				if (hasAnyAmount($scope.model.goals.disposal)) {
					return goalService.createGoal;
				}
			}
			return null;
		}

		function getResidueGenerationSaveService() {
			$scope.removeGoalIdResidueGenerationAfterDelete = false;
			if ($scope.model.goals.residueGeneration.id) {
				if (hasAnyAmount($scope.model.goals.residueGeneration)) {
					return goalService.updateGoal;
				}
				$scope.removeGoalIdResidueGenerationAfterDelete = true;
				return goalService.deleteGoal;
			} else {
				if (hasAnyAmount($scope.model.goals.residueGeneration)) {
					return goalService.createGoal;
				}
			}
			return null;
		}

		function hasAnyAmount(goal) {
			return goal.months.some((goalMonth) => goalMonthHasAnyAmount(goalMonth));
		}

		function buildResidueGenerationGoalDto() {
			return {
				id: $scope.model.goals.residueGeneration.id,
				residueCode: $scope.model.residue.code,
				year: $scope.model.year,
				type: $scope.goalType.ResidueGeneration,
				months: getMonthsWithAmountFormatted($scope.model.goals.residueGeneration),
				measureUnitId: $scope.model.measureUnit.Id
			};
		}

		function buildDisposalGoalDto() {
			return {
				id: $scope.model.goals.disposal.id,
				residueCode: $scope.model.residue.code,
				year: $scope.model.year,
				type: $scope.goalType.DisposalFinancial,
				months: getMonthsWithAmountFormatted($scope.model.goals.disposal),
				measureUnitId: $scope.model.measureUnit.id
			};
		}

		function goalMonthHasAnyAmount(month) {
			return month.amount === 0 || (month.amount && typeof month.amount == "number");
		}

		function getMonthsWithAmountFormatted(goal) {
			const months = [];
			goal.months.forEach((month) => {
				if (goalMonthHasAnyAmount(month)) {
					if (goal.type == $scope.goalType.DisposalFinancial) {
						month.amount = month.isRevenue ? Math.abs(month.amount) : -Math.abs(month.amount);
					}
					months.push(month);
				}
			});
			return months;
		}

		this.$onInit = function () {
			$scope.model.year = $stateParams.year;
			$scope.model.goals = {};
			loadResidue();
		};

		function loadResidue() {
			$scope.loading = true;
			httpService.getDTOFromServiceV2(residueV2Service.getOrganizationUnitResidue, $stateParams.residueCode).then(
				function (residue) {
					$scope.model.residue = residue;
					$rootScope.contentNavBar.breadcrumb = residue.code + " - " + residue.name;
					listGoals();
				},
				function () {
					$scope.loading = false;
				}
			);
		}

		function listGoals() {
			const dto = {
				year: $scope.model.year,
				residueCode: $stateParams.residueCode
			};

			httpService.getDTOFromServiceV2(goalService.listGoals, dto).then(
				function (goalList) {
					buildModel(goalList);
					$scope.loading = false;
				},
				function () {
					$scope.loading = false;
				}
			);
		}

		function buildModel(goalList) {
			if (goalList.length == 0) {
				initGoals();
			} else {
				loadGoalsFromList(goalList);
			}

			loadMonths();
		}

		function initGoals() {
			$scope.measureUnitId = $scope.model.residue.measureUnit.id;
			$scope.model.goals.residueGeneration = {
				type: $scope.goalType.ResidueGeneration,
				months: []
			};
			$scope.model.goals.disposal = {
				type: $scope.goalType.DisposalFinancial,
				months: []
			};
		}

		function loadGoalsFromList(goals) {
			$scope.measureUnitId = getMeasureUnitId(goals);
			$scope.model.goals.residueGeneration = getGoalFromList(goals, $scope.goalType.ResidueGeneration);
			$scope.model.goals.disposal = getGoalFromList(goals, $scope.goalType.DisposalFinancial);
		}

		function getMeasureUnitId(goals) {
			const goalsWithMeasureUnit = goals.filter((goal) => goal.measureUnitId);
			if (goalsWithMeasureUnit.length > 0) {
				return goalsWithMeasureUnit[0].measureUnitId;
			} else {
				return $scope.model.residue.measureUnit.id;
			}
		}

		function getGoalFromList(goals, type) {
			const goal = goals.find((g) => g.type == type);
			if (!goal) {
				return {
					type: type,
					months: []
				};
			} else {
				return goal;
			}
		}

		function loadMonths() {
			formatMonths($scope.model.goals.residueGeneration);
			formatMonths($scope.model.goals.disposal);
		}

		function formatMonths(goal) {
			goal.months = getMonths(goal);
		}

		function getMonths(goal) {
			const months = [];
			for (let index = 1; index <= 12; index++) {
				let currentMonth = goal.months.find((goalMonth) => goalMonth.month == index);
				if (!currentMonth) {
					currentMonth = {
						month: index
					};
				} else {
					if (
						goal.type == $scope.goalType.DisposalFinancial &&
						currentMonth.amount &&
						typeof currentMonth.amount == "number"
					) {
						currentMonth.isRevenue = currentMonth.amount >= 0;
						currentMonth.amount = Math.abs(currentMonth.amount);
					}
				}
				months.push(currentMonth);
			}
			return months;
		}

		$scope.onIsRevenueChange = function (isRevenue) {
			$scope.model.goals.disposal.months.forEach((goalMonth) => {
				goalMonth.isRevenue = isRevenue;
			});
		};

		$scope.onAmountResidueGenerationChange = function (month, amount) {
			const goalMonth = $scope.model.goals.residueGeneration.months.find((gm) => gm.month == month);
			goalMonth.amount = amount;
		};

		$scope.onAmountDisposalChange = function (month, amount) {
			const goalMonth = $scope.model.goals.disposal.months.find((gm) => gm.month == month);
			goalMonth.amount = amount;
		};

		$scope.onIsRevenueChangeInput = function (month, isRevenue) {
			const goalMonth = $scope.model.goals.disposal.months.find((gm) => gm.month == month);
			goalMonth.isRevenue = isRevenue;
		};

		$scope.onMeasureUnitChange = function (newValue) {
			$scope.model.measureUnit = newValue;
		};
	}
]);
