angular.module("vgresiduos").directive("ibamaRappCreateValidate", function () {
	return {
		scope: true,
		controller: "IbamaRappCreateValidateCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/ibama/ibamaRappCreate/stages/ibamaRappCreateValidate_template.html",
		bindToController: {
			model: "="
		}
	};
});
