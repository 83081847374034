angular.module("vgresiduos").controller("IbamaRappFollowupCtrl", [
	"$rootScope",
	"$scope",
	"$state",
	"$stateParams",
	"httpService",
	"ibamaIntegrationService",
	function ($rootScope, $scope, $state, $stateParams, httpService, ibamaIntegrationService) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.ibamaRappStatusEnum = Vgr.enumerations.ibamaRapp.status;

		var ibamaRappId = $stateParams.id;

		$scope.model = {};

		$rootScope.contentNavBar = {
			title: $scope.labels.RAPP_IBAMA_INTEGRATION,
			back: function () {
				$state.go("ibamaRappList", { ano: $scope.model.Year });
			},
			valid: true,
			customButtons: [
				{
					label: "",
					buttonClasses: "button-refresh",
					icon: "refresh",
					supressToast: true,
					tooltip: $scope.labels.UPDATE,
					onClick: function () {
						$scope.reloadCards = false;
						$scope.loadingFinished = false;
						return loadPage();
					}
				}
			],
			buttons: []
		};

		this.$onInit = function () {
			loadPage();
		};

		function loadPage() {
			const promise = loadIbamaRapp();
			promise.then(
				function (data) {
					buildModel(data);
					formatBreadcrumb();
					formatResidueGridFields();
					if ($scope.model.Status != Vgr.enumerations.ibamaRapp.status.Success) {
						$rootScope.contentNavBar.buttons = [""];
					}
					$scope.loadingFinished = true;
				},
				function () {
					$state.go("ibamaRappList");
				}
			);
			return promise;
		}

		function formatBreadcrumb() {
			var referenceUnit = $scope.model.OrganizationUnits[0];
			$rootScope.contentNavBar.breadcrumb = referenceUnit.Code + " - " + referenceUnit.Name + " - " + $scope.model.Year;
		}

		function loadIbamaRapp() {
			return httpService.getDTOFromServiceV2(ibamaIntegrationService.getIbamaRapp, ibamaRappId);
		}

		function buildModel(data) {
			$scope.model = data;
		}

		function formatResidueGridFields() {
			$scope.model.Residues.forEach((ibamaRappResidue) => {
				formatResidueStatus(ibamaRappResidue);
				formatActivityDetails(ibamaRappResidue);
				formatAmountsWithMeasureUnit(ibamaRappResidue);
			});
		}

		function formatAmountsWithMeasureUnit(ibamaRappResidue) {
			ibamaRappResidue.GenerationAmountWithMeasureUnit =
				ibamaRappResidue.GenerationAmount + " " + ibamaRappResidue.Residue.MeasureUnitDescription;
			ibamaRappResidue.DisposalAmountWithMeasureUnit =
				ibamaRappResidue.DisposalAmount + " " + ibamaRappResidue.Residue.MeasureUnitDescription;
		}

		function formatActivityDetails(ibamaRappResidue) {
			ibamaRappResidue.ActivityDetailFormated =
				ibamaRappResidue.ActivityDetail.IbamaActivityCode + "-" + ibamaRappResidue.ActivityDetail.Code;
		}

		function formatResidueStatus(ibamaRappResidue) {
			ibamaRappResidue.StatusFormated = $scope.labels.IBAMA_RAPP_RESIDUE_STATUS_USER[ibamaRappResidue.Status];
			if (ibamaRappResidue.Status == Vgr.enumerations.ibamaRapp.residue.status.DataError) {
				ibamaRappResidue.StatusFormated = ibamaRappResidue.StatusFormated.replace(
					"[ERROR]",
					ibamaRappResidue.ErrorMessage
				);
			} else if (ibamaRappResidue.Status == Vgr.enumerations.ibamaRapp.residue.status.InternalError) {
				ibamaRappResidue.StatusFormated = ibamaRappResidue.StatusFormated.replace(
					"[ERROR]",
					$scope.labels.INTEGRATION_INTERNAL_ERROR
				);
			}

			switch (ibamaRappResidue.Status) {
				case Vgr.enumerations.ibamaRapp.residue.status.Pending:
					ibamaRappResidue.StatusClass = "c-orange";
					break;
				case Vgr.enumerations.ibamaRapp.residue.status.Success:
					ibamaRappResidue.StatusClass = "strong-green";
					break;
				case Vgr.enumerations.ibamaRapp.residue.status.InternalError:
					ibamaRappResidue.StatusClass = "strong-red";
					break;
				case Vgr.enumerations.ibamaRapp.residue.status.DataError:
					ibamaRappResidue.StatusClass = "strong-red";
					break;
			}
		}

		function getErrorResidues() {
			return $scope.model.Residues.filter(
				(r) =>
					r.Status == Vgr.enumerations.ibamaRapp.residue.status.DataError ||
					r.Status == Vgr.enumerations.ibamaRapp.residue.status.InternalError
			);
		}

		$scope.hasAnyResidueWithError = function () {
			return getErrorResidues().length > 0;
		};

		$scope.retryResidues = function () {
			$scope.isRetrying = true;
			var list = angular.copy(getErrorResidues());

			updateResidues(list);
		};

		function updateResidues(list) {
			var toUpdateResidue = list.splice(0, 1);
			updateResidue(toUpdateResidue[0]).then(
				function () {
					onUpdateFinish(list);
				},
				function () {
					onUpdateFinish(list);
				}
			);
		}

		function onUpdateFinish(list) {
			if (list.length > 0) {
				updateResidues(list);
			} else {
				$scope.isRetrying = false;
				loadIbamaRapp().then(function (data) {
					$scope.model.Status = data.Status;
					if ($scope.model.Status == Vgr.enumerations.ibamaRapp.status.Success) {
						$rootScope.contentNavBar.buttons = [];
					}
					reloadCards();
				});
			}
		}

		function updateResidue(toUpdateResidue) {
			var dto = {
				ibamaRappId: $scope.model.Id,
				residueId: toUpdateResidue.Id
			};

			return httpService.postDTOToServiceV2(ibamaIntegrationService.retryIbamaRappResidue, dto).then(
				function () {
					for (var index in $scope.model.Residues) {
						if ($scope.model.Residues[index].Residue.Id == toUpdateResidue.Residue.Id) {
							$scope.model.Residues[index].Status = Vgr.enumerations.ibamaRapp.status.Pending;
							formatResidueStatus($scope.model.Residues[index]);
						}
					}
				},
				function (error) {}
			);
		}

		function reloadCards() {
			$scope.reloadCards = true;
		}

		$scope.confirm = function () {
			$scope.reloadCards = false;
			httpService.postDTOToServiceV2(ibamaIntegrationService.integrateIbamaRapp, ibamaRappId).then(
				function () {
					$scope.model.Status = Vgr.enumerations.ibamaRapp.status.Success;
					$rootScope.contentNavBar.buttons = [];
					reloadCards();
				},
				function (error) {}
			);
		};

		$scope.toIntegrationsList = function () {
			$state.go("ibamaRappList", { ano: $scope.model.Year });
		};
	}
]);
