angular.module("vgresiduos").controller("TransporterMultiSelectCtrl", [
	"$rootScope",
	"$scope",
	"httpService",
	"supplierService",
	function ($rootScope, $scope, httpService, supplierService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.searchLabel = "";

		$scope.listClientTransporters = function () {
			httpService
				.getListFromServiceCore(supplierService.listOrganizationSupplier, {
					isTransporter: true
				})
				.then(function (response) {
					$scope.list = response.list.map((supplier) => {
						return {
							id: supplier.supplierId,
							name: supplier.name,
							descriptionFormated: supplier.supplierId + " - " + supplier.name
						};
					});
				});
		};

		this.$onInit = function () {
			$scope.listClientTransporters();
		};
	}
]);
