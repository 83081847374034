angular.module("vgresiduos").controller("GenericKpiReportCtrl", [
	"$scope",
	"$rootScope",
	"$state",
	"$timeout",
	"$q",
	"customReportService",
	"httpService",
	"reportUtilService",
	"engagementScoreService",
	"dialogService",
	function (
		$scope,
		$rootScope,
		$state,
		$timeout,
		$q,
		customReportService,
		httpService,
		reportUtilService,
		engagementScoreService,
		dialogService
	) {
		$scope.labels = $rootScope.labels;
		let reportGenerationAlreadyTracked = false;

		this.$onInit = function () {
			$scope.filters = {};

			$rootScope.contentNavBar = {
				title: $scope.labels.REPORTS,
				breadcrumb: $scope.labels.KEY_PERFORMANCE_INDICATORS,
				back: function () {
					$state.go("customReport");
				},
				valid: true,
				customButtons: [
					{
						label: $scope.labels.EXPORT_CSV,
						buttonClasses: "md-primary",
						supressToast: true,
						onClick: function () {
							const deferred = $q.defer();
							$timeout(function () {
								angular.element("#hiddenCsvExportBtn").click();
							});
							deferred.resolve();
							return deferred.promise;
						}
					},
					{
						label: $scope.labels.SAVE_REPORT,
						buttonClasses: "md-primary",
						icon: "check",
						supressToast: true,
						onClick: function () {
							return openPopupCreateReport();
						},
						disableCustomButton: shouldDisableSaveReportButton
					}
				],
				buttons: [$scope.labels.SAVE_REPORT]
			};

			showFilters(true);

			$scope.isLoading = false;
		};

		$scope.onChangeFilters = function (filters) {
			$scope.filters = filters;
		};

		$scope.onClickViewData = function () {
			const dto = reportUtilService.buildCustomReportFilterDto($scope.filters);

			$scope.reportGenerated = false;
			$scope.isLoading = true;

			httpService.getDTOFromServiceV2(customReportService.generateGenericKpiReport, dto).then(
				function (response) {
					$scope.reportData = response;
					$scope.reportGenerated = true;

					trackReportGeneration();
					showFilters(false);

					$rootScope.contentNavBar.buttons.push($scope.labels.EXPORT_CSV);

					$scope.isLoading = false;
				},
				function () {
					$scope.reportData = null;
					$scope.isLoading = false;
				}
			);
		};

		$scope.toggleShowFilters = function () {
			showFilters(!$scope.showFilters);
		};

		function showFilters(isToShow) {
			$scope.showFilters = isToShow;
		}

		function openPopupCreateReport() {
			const deferred = $q.defer();

			const params = {
				filters: $scope.filters
			};

			dialogService
				.showDialogFromTemplateV2(
					"views/customReports/genericKpiReport/popup/createReportPopup_template.html",
					"CreateReportPopupCtrl",
					null,
					params
				)
				.then(
					function (id) {
						$state.go("customReportEdit", { reportId: id });
						deferred.resolve();
					},
					function () {
						deferred.resolve();
					}
				);

			return deferred.promise;
		}

		function shouldDisableSaveReportButton() {
			return !$scope.filters.information;
		}

		function trackReportGeneration() {
			if (reportGenerationAlreadyTracked) {
				return;
			}

			reportGenerationAlreadyTracked = true;
			engagementScoreService.track(Vgr.trackings.bi.reports.generateKpi);
			if ($scope.filters.information) {
				customReportService.trackReportMetric($scope.filters.information.id);
			}
		}
	}
]);
