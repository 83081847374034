angular.module("vgresiduos").directive("disposalVehicle", function () {
	return {
		scope: true,
		controller: "DisposalVehicleCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/destination/steps/disposalVehicle/disposalVehicle_template.html",
		bindToController: {
			showTitle: "=?",
			mustUpdateSlip: "=?"
		}
	};
});
