angular.module("vgresiduos").controller("CustomReportFilterGroupingSectionCtrl", [
	"$rootScope",
	"$scope",
	"reportUtilService",
	function ($rootScope, $scope, reportUtilService) {
		"use strict";

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;

			if (!$scope.model) {
				$scope.model = [];
			}
		};

		function setAvailableFields(information) {
			if (!information || !information.id) {
				$scope.groupingOptions = [];
				return;
			}

			let informationId = information.id.toUpperCase();
			if (informationId === Vgr.enumerations.customReport.information.ResidueGenerationQuantity) {
				$scope.groupingOptions = reportUtilService.residueGenerationReport.groupings;
			} else if (informationId === Vgr.enumerations.customReport.information.ResidueDisposalQuantity) {
				$scope.groupingOptions = reportUtilService.residueDisposalReport.groupings;
			} else if (informationId === Vgr.enumerations.customReport.information.ResidueDisposalPrice) {
				$scope.groupingOptions = reportUtilService.residueDisposalReport.groupings;
			} else if (informationId === Vgr.enumerations.customReport.information.ResidueDisposalRecipientEfficiency) {
				$scope.groupingOptions = reportUtilService.recipientEfficiencyReport.groupings;
			} else if (informationId === Vgr.enumerations.customReport.information.ProductionQuantity) {
				$scope.groupingOptions = reportUtilService.productionReport.groupings;
			}
		}

		$scope.onGroupingChanged = function (groupings) {
			$scope.model = groupings;
			callback();
		};

		function callback() {
			if ($scope.$ctrl.callback) {
				$scope.$ctrl.callback($scope.model);
			}
		}

		this.$onChanges = function (obj) {
			if (obj.reportInformation) {
				setAvailableFields(obj.reportInformation.currentValue);
			}
		};
	}
]);
