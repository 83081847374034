angular.module("componentes").controller("ResetPasswordFormCtrl", [
	"$scope",
	"$rootScope",
	"$state",
	"httpService",
	"accountService",
	function ($scope, $rootScope, $state, httpService, accountService) {
		"use strict";

		$scope.passwordUpdated = false;

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			var username = $rootScope.usernameConfirming;
			if (username) {
				$rootScope.usernameConfirming = "";
				$scope.usernameCameFromForgotPassword = true;
			}

			$scope.account = {
				username: username,
				code: null,
				password: null,
				confirmPassword: null
			};
		};

		$scope.toLogin = function () {
			accountService.goToLogin();
		};

		$scope.resetPassword = function () {
			$scope.loading = true;
			if (!validate()) {
				$scope.loading = false;
				return;
			}

			var dto = {
				username: $scope.account.username,
				code: $scope.account.code,
				password: $scope.account.password
			};
			httpService.postDTOToServiceV2(accountService.confirmForgotPassword, dto).then(
				function (response) {
					$scope.passwordUpdated = true;
					$scope.loading = false;
				},
				function (response) {
					$scope.loading = false;
				}
			);
		};

		$scope.backToForgottenPassword = function () {
			$state.go("forgottenPassword");
		};

		var validate = function () {
			if (!$scope.account.username) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.USERNAME_MISSING);
				return false;
			}
			if (!$scope.account.code) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.CODE_MISSING);
				return false;
			}
			if (!$scope.account.password) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.PASSWORD_MISSING);
				return false;
			}
			if ($scope.account.password != $scope.account.confirmPassword) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.PASSWORDS_DO_NOT_MATCH);
				return false;
			}

			return true;
		};
	}
]);
