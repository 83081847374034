angular.module("vgresiduos").controller("EditSupplierDocumentCtrl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton(
				$rootScope,
				`organizacao/fornecedores/${$stateParams.supplierCode}/documento/${$stateParams.docId}`,
				[
					{ key: "supDocId", value: $stateParams.supDocId },
					{ key: "requestId", value: $stateParams.requestId }
				]
			);
		};
	}
]);
