angular.module("vgresiduos").directive("popupButton", function () {
	"use strict";

	function calculatePopupPosition(scope, element, attr) {
		var paddingTop = 20;
		var paddingBottom = 50;
		var offset = element.offset();
		var positionTop = offset.top + element.height() + paddingTop;
		var maxHeight = window.innerHeight - positionTop - paddingBottom;

		scope.popupButtonPopupStyle = {
			"max-height": maxHeight + "px",
			top: positionTop
		};

		if (attr.position && attr.position == "right") {
			var positionRight = window.innerWidth - offset.left - element.width();
			scope.popupButtonPopupStyle.right = positionRight;
		}

		if (attr.minWidth) {
			scope.popupButtonPopupStyle["min-width"] = attr.minWidth + "px";
		}
	}

	return {
		scope: true,
		replace: false,
		transclude: true,
		controller: "popupButtonCtrl",
		controllerAs: "ctrl",
		bindToController: {
			icon: "@",
			label: "@",
			buttonClasses: "@",
			fill: "@?",
			position: "@?",
			minWidth: "@?"
		},
		templateUrl: "views/components/popupButton/popupButton_template.html",
		link: function (scope, element, attr) {
			var selfElement = element;
			var selfAttributes = attr;
			var selfScope = scope;

			var guid = Vgr.util.uuid();
			angular.element(element).attr("id", "popup-button-" + guid);
			scope.guid = guid;
			$("#popup-button-" + guid + " .popup-button-button").on("click", function () {
				calculatePopupPosition(selfScope, selfElement, selfAttributes);
			});
		}
	};
});
