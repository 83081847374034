angular.module("vgresiduos").controller("DisposalComplementaryMtrCtrl", [
	"$scope",
	"$q",
	"fileService",
	function ($scope, $q, fileService) {
		"use strict";

		var filesDeferredManifest = $q.defer();

		$scope.fileOptions.complementaryMtr = {
			fileService: "mixed",
			get: function () {
				return filesDeferredManifest.promise;
			},
			update: function (newFiles, deletedIds) {
				var deferrend = $q.defer();
				var path = $scope.getDisposalFilePath();
				fileService.uploadAll(newFiles, path).then(
					function (response) {
						deferrend.resolve(response);
					},
					function () {
						deferrend.reject();
					}
				);
				return deferrend.promise;
			},
			fileList: []
		};

		function getFilesModel() {
			var files = [];

			for (var index in $scope.fileOptions.complementaryMtr.fileList) {
				files.push({
					Path: $scope.fileOptions.complementaryMtr.fileList[index].Path,
					Type: Vgr.enumerations.destination.file.ComplementaryMtr,
					Name: $scope.fileOptions.complementaryMtr.fileList[index].Name
				});
			}

			return files;
		}

		$scope.$watch("fileOptions.complementaryMtr.fileList.length", function (newValue, oldValue) {
			if (newValue != oldValue) {
				$scope.destinationModel.ComplementaryMtrFiles = getFilesModel();
			}
		});

		this.$onInit = function () {
			loadManifestFiles($scope.destinationModel.ComplementaryMtrFiles);
		};

		function loadManifestFiles(files) {
			var manifestFiles = [];
			if (files) {
				for (var index in files) {
					if (files[index].Type == Vgr.enumerations.destination.file.ComplementaryMtr) {
						manifestFiles.push({
							Id: files[index].Id,
							Name: files[index].Name,
							Path: files[index].Path,
							S3Key: files[index].Path
						});
					}
				}
			}

			filesDeferredManifest.resolve(manifestFiles);
		}
	}
]);
