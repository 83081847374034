angular.module("vgresiduos").component("customizedFilterOptionField", {
	controller: "CustomizedFilterOptionFieldCtrl",
	templateUrl:
		"views/components/customizedFilterV2/customizedFilterOptionField/customizedFilterOptionField_template.html",
	bindings: {
		label: "@?",
		displayProperty: "@?",

		items: "<",

		setter: "<?",
		callback: "<",

		unavailableItems: "<"
	}
});
