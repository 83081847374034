angular.module("vgresiduos").directive("auditCategoryList", function () {
	return {
		scope: true,
		controller: "AuditCategoryListCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/suppliers/audits/categoryList/auditCategoryList_template.html",
		bindToController: {
			audit: "=?",
			corporateOrigin: "=?",
			supplierCode: "=?",
			isEdition: "=?",
			hasDocumentCreatePermission: "=?"
		}
	};
});
