angular.module("vgresiduos").directive("ibamaRappCreateValidateGrid", function () {
	return {
		scope: true,
		controller: "IbamaRappCreateValidateGridCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/ibama/ibamaRappCreate/grid/ibamaRappCreateValidateGrid_template.html",
		bindToController: {
			list: "=",
			reloadList: "="
		}
	};
});
