/* eslint-disable no-undef */
angular.module("vgresiduos").controller("dmrAlertPopupCtrl", [
	"$scope",
	"$rootScope",
	"alertPopupModel",
	"dialogService",
	"userAccessService",
	"localStorageService",
	function ($scope, $rootScope, alertPopupModel, dialogService, userAccessService, localStorageService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		function initializePopup() {
			const dmrKeyPrefix = "dmrAlertPopup_";
			const quarter = moment().quarter();
			const dmrAlertPopup = `${dmrKeyPrefix}${quarter}_${moment().year()}`;
			if (alertPopupModel == null || !alertPopupModel) {
				$scope.alertPopupModel = {
					imageLink: "/img/generalTopAlerts/dmr_report.png",
					title: buildTitle(),
					cards: buildCards(),
					buttons: [
						{
							class: "",
							click: function () {
								localStorageService.set(dmrAlertPopup, true);
								dialogService.confirm();
							},
							label: $rootScope.labels.DMR_DISPENSER
						},
						{
							class: "md-primary md-raised",
							click: function () {
								dialogService.confirm();
							},
							label: $rootScope.labels.DMR_REMEMBER
						}
					]
				};
			}
		}

		initializePopup();

		function buildTitle() {
			const quarter = moment().quarter();
			if (quarter == 1 || quarter == 3) {
				return (
					$rootScope.labels.DMR_QUARTER.replace("[QUARTER_NUMBER]", quarter > 1 ? quarter - 1 : 4) +
					" " +
					$rootScope.labels.DMR_SEMESTER.replace("[SEMESTER_NUMBER]", quarter <= 2 ? 2 : 1)
				);
			} else {
				return $rootScope.labels.DMR_QUARTER.replace("[QUARTER_NUMBER]", quarter > 1 ? quarter - 1 : 4);
			}
		}

		function buildCards() {
			return [
				{
					icon: "summarize",
					label: $scope.labels.DMR_ACCESS,
					click: function () {
						dialogService.confirm();
					}
				},
				{
					icon: "calendar_today",
					label: buildCalendarTitle(),
					click: function () {}
				},
				{
					icon: "forum",
					label: $scope.labels.DMR_FAQ,
					click: function () {}
				}
			];
		}

		function buildCalendarTitle() {
			const quarter = moment().quarter();
			const quarterDeadline = moment().endOf("month").format("DD/MM");
			if (quarter == 1 || quarter == 3) {
				const feamDeadline = moment().add("month", 1).endOf("month").format("DD/MM");
				const imaDeadline = moment().add("month", 2).endOf("month").format("DD/MM");
				return $rootScope.labels.DMR_CALENDAR.replace("[PRAZO_QUARTER]", quarterDeadline)
					.replace("[PRAZO_FEAM]", feamDeadline)
					.replace("[PRAZO_IMA]", imaDeadline);
			} else {
				return $rootScope.labels.DMR_CALENDAR_QUARTER.replace("[PRAZO_QUARTER]", quarterDeadline);
			}
		}
	}
]);
