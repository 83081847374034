angular.module("vgresiduos").directive("dateFilterGroupBy", function () {
	return {
		scope: {},
		controller: "DateFilterGroupByCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/dateFilter/dateFilterGroupBy_template.html",
		bindToController: {
			model: "=",
			options: "="
		}
	};
});
