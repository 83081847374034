angular.module("vgresiduos").controller("storageStoreCtrl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `geracoes/${$stateParams.id}`, [{ key: "store", value: true }]);
		};
	}
]);
