angular.module("vgresiduos").component("supplierContactSelectField", {
	controller: "SupplierContactSelectFieldCtrl",
	templateUrl: "views/components/fields/supplierContactSelectField/supplierContactSelectField_template.html",
	bindings: {
		model: "=",
		supplierId: "<?",
		list: "=?",
		label: "@?",
		setterId: "<?",
		emptyField: "<?",
		isDisabled: "<?"
	}
});
