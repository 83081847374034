Vgr.util = {
	isNull: function isNull(element) {
		"use strict";
		return !element || typeof element === "undefined";
	},
	isNullOrEmpty: function isNullOrEmpty(str) {
		"use strict";
		return this.isNull(str) || str.length === 0;
	},
	extractList: function extractList(inputList) {
		"use strict";
		var outputList = [];
		if (inputList === null || inputList === "undefined") {
			outputList = [];
		} else if (!Array.isArray(inputList)) {
			outputList.push(inputList);
		} else {
			outputList = inputList;
		}

		return outputList;
	},
	selectedToDeletedIds: function (selected) {
		"use strict";

		var deletedIds = {
			Ids: "",
			ClientIds: ""
		};

		var Ids = [];
		var hashIds = [];
		var clientIds = [];

		selected.forEach(function (element, index, array) {
			Ids[index] = element.ID;
			clientIds[index] = element.ClientID;
			hashIds[index] = element.HashID;
		});

		deletedIds.Ids = Ids.join(", ");
		deletedIds.hashIds = hashIds.join(", ");
		deletedIds.ClientIds = clientIds.join(", ");

		return deletedIds;
	},

	//returns chrome version, false if other browser
	getChromeVersion: function () {
		var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

		return raw ? parseInt(raw[2], 10) : false;
	},

	isIE: function isIE() {
		var ua = window.navigator.userAgent;

		var msie = ua.indexOf("MSIE ");
		if (msie > 0) {
			// IE 10 or older => return version number
			return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
		}

		var trident = ua.indexOf("Trident/");
		if (trident > 0) {
			// IE 11 => return version number
			var rv = ua.indexOf("rv:");
			return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
		}

		// other browser
		return false;
	},

	isValidDate: function (dateString) {
		"use strict";

		if (typeof dateString !== "string") {
			return false;
		}

		var regEx = /^\d{4}-\d{2}-\d{2}$/;
		if (!dateString.match(regEx)) return false; // Invalid format
		var d;
		if (!((d = new Date(dateString)) | 0)) return false; // Invalid date (or this could be epoch)
		return d.toISOString().slice(0, 10) == dateString;
	},

	isInvalidEmail: function isInvalidEmail(email) {
		return email.indexOf("@") == -1 || email.indexOf(".") == -1 || email.split("@")[0].length < 1;
	},

	applyOnDescendantProp: function applyOnDescendantProp(obj, propertyPath, func) {
		"use strict";

		if (propertyPath) {
			var arr = propertyPath.split(".");
			while (arr.length > 1 && (obj = obj[arr.shift()])) {
				if (Array.isArray(obj)) {
					break;
				}
			}

			func(obj, arr.shift());
		}
	},

	createDescendantProp: function createProp(obj, propertyPath) {
		if (obj && propertyPath) {
			var arr = propertyPath.split(".");
			var prop = "";

			while ((prop = arr.shift())) {
				if (!obj[prop]) obj[prop] = arr.length > 0 ? {} : null;

				obj = obj[prop];
			}
		}
	},

	setDescendantProp: function (obj, propertyPath, value) {
		"use strict";

		this.createDescendantProp(obj, propertyPath);

		Vgr.util.applyOnDescendantProp(obj, propertyPath, function setFunction(descendantObj, descendantProp) {
			descendantObj[descendantProp] = value;
		});
	},

	deleteDescendantProp: function (obj, propertyPath) {
		"use strict";
		Vgr.util.applyOnDescendantProp(obj, propertyPath, function deleteFunction(descendantObj, descendantProp) {
			delete descendantObj[descendantProp];
		});
	},

	getDescendantProp: function (obj, propertyPath, getFirstArray) {
		"use strict";

		if (propertyPath) {
			var arr = propertyPath.split(".");
			while (arr.length && (obj = obj[arr.shift()])) {
				if (getFirstArray && Array.isArray(obj)) {
					obj.path = arr.join(".");
					break;
				}
			}
			return obj;
		} else {
			return undefined;
		}
	},
	showServerErrorMessage: function ($rootScope, response) {
		"use strict";

		var errorMessage = $rootScope.labels.SERVER_ERROR;
		if (response.errorCode) {
			errorMessage = $rootScope.labels[response.errorCode];
		} else if (response.message) {
			errorMessage = response.message;
		}
		$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, errorMessage);
	},

	getExtensionByName: function (name) {
		if (name.indexOf(".doc") != -1) return ".doc";
		if (name.indexOf(".jpg") != -1) return ".jpg";
		if (name.indexOf(".pdf") != -1) return ".pdf";
		if (name.indexOf(".docx") != -1) return ".docx";
		if (name.indexOf(".xls") != -1) return ".xls";
		if (name.indexOf(".xlsx") != -1) return ".xlsx";
		if (name.indexOf(".ppt") != -1) return ".ppt";
		if (name.indexOf(".pptx") != -1) return ".pptx";
		if (name.indexOf(".gif") != -1) return ".gif";
		if (name.indexOf(".png") != -1) return ".png";
	},
	getNameByPath: function (name) {
		var names = name.split("/");
		return names[names.length - 1];
	},

	arrayBufferToUint8Array: function (arrayBuffer) {
		return [...new Uint8Array(arrayBuffer)];
	},

	fileDtoToFile: function (fileDTO) {
		"use strict";
		if (!fileDTO) {
			return;
		}

		if (!fileDTO.Extension) {
			if (fileDTO.S3Key) {
				fileDTO.Extension = Vgr.util.getExtensionByName(fileDTO.S3Key);
			} else if (fileDTO.Path) {
				fileDTO.Extension = Vgr.util.getExtensionByName(fileDTO.Path);
			}
		}
		if (!fileDTO.Name) {
			if (fileDTO.S3Key) {
				fileDTO.Name = Vgr.util.getNameByPath(fileDTO.S3Key);
			}
		}
		var fileType = {
			type: Vgr.util.fileExtensionToMimeType(fileDTO.Extension)
		};

		var file = {};

		if (Array.isArray(fileDTO.FileBytes)) {
			var arrBuff = new Uint8Array(fileDTO.FileBytes);
			file = new Blob([arrBuff], fileType);
		} else {
			file = Vgr.util.base64FileBytestoBlob(fileDTO.FileBytes, fileType.type);
		}

		file.name = fileDTO.Name;
		file.FileName = fileDTO.Name;

		if (fileDTO.Extension) {
			var dotSplitParts = fileDTO.Extension.split(".");
			var extension = dotSplitParts[dotSplitParts.length - 1];

			if (file.name && file.name.toLowerCase().indexOf(extension.toLowerCase().trim()) < 0)
				file.name += "." + extension.trim();

			if (file.FileName && file.FileName.toLowerCase().indexOf(extension.toLowerCase().trim()) < 0)
				file.FileName += "." + extension.trim();
		}

		file.ID = fileDTO.ID;
		file.HashID = fileDTO.HashID;
		file.url = window.URL.createObjectURL(file);
		return file;
	},

	fileExtensionToMimeType: function (fileNameOrExtension) {
		"use strict";

		if (!fileNameOrExtension) {
			return "";
		}
		fileNameOrExtension = fileNameOrExtension.trim();
		var dotSplitParts = fileNameOrExtension.split(".");
		var extension = dotSplitParts[dotSplitParts.length - 1];
		var type = "";

		switch (extension.toLowerCase().trim()) {
			case "jpg":
			case "jpeg":
				type = "image/jpeg";
				break;
			case "pdf":
				type = "application/pdf";
				break;
			case "png":
				type = "image/png";
				break;
			case "gif":
				type = "image/gif";
				break;
			case "xlsx":
				type = ".xlsx";
				break;
			case "xls":
				type = ".xls";
				break;
			case "docx":
				type = ".docx";
				break;
			case "doc":
				type = ".doc";
				break;
			default:
				type = "application/octet-stream";
		}

		return type;
	},

	addFilesToForm: function (formData, files) {
		"use strict";

		for (var i = 0; i < files.length; ++i) {
			var obj = files[i];
			var file = new Blob([obj.lfFile], { type: obj.lfFile.type });
			file.ID = obj.lfFile.ID;
			file.HashID = obj.lfFile.HashID;
			file.name = obj.Name + "." + obj.Extension;
			file.FileName = obj.Name + "." + obj.Extension;

			formData.append("files[" + i + "]", file, obj.Name + "." + obj.Extension);
		}
	},

	convertKeysToCamel: function (obj) {
		const newObj = Array.isArray(obj) ? [] : {};
		for (let key in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, key)) {
				if (Array.isArray(obj[key])) {
					newObj[Vgr.util.pascalToCamel(key)] = obj[key].map((item) => {
						if (typeof item === "object" && item !== null) {
							return Vgr.util.convertKeysToCamel(item);
						} else {
							return item;
						}
					});
				} else if (typeof obj[key] === "object" && obj[key] !== null) {
					newObj[Vgr.util.pascalToCamel(key)] = Vgr.util.convertKeysToCamel(obj[key]);
				} else {
					newObj[Vgr.util.pascalToCamel(key)] = obj[key];
				}
			}
		}
		return newObj;
	},

	pascalToCamel: function (str) {
		return str.charAt(0).toLowerCase() + str.slice(1);
	},

	merge: function (toObj, fromObj, preserving) {
		"use strict";

		if (!toObj) {
			toObj = {};
		}
		for (var attrname in fromObj) {
			if (!preserving || !toObj.hasOwnProperty(attrname)) {
				toObj[attrname] = fromObj[attrname];
			}
		}
	},

	formatCNPJ: function (cleanValue) {
		"use strict";

		return cleanValue.toString().replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
	},

	capitalize: function (word) {
		"use strict";

		if (word.length > 0) return word.replace(word[0], word[0].toUpperCase());

		return word;
	},

	capitalizeText: function (string) {
		if (!string) return;
		var strings = string.split(" ");
		var ret = "";
		strings.forEach(function (word) {
			if (word.length > 2) {
				ret += word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + " ";
			} else if (Vgr.util.isRomanNumeral(word)) {
				ret += word.toUpperCase() + " ";
			} else {
				ret += word.toLowerCase() + " ";
			}
		});
		return ret;
	},

	isRomanNumeral: function (word) {
		return word == "I" || word == "II" || word == "IV" || word == "V" || word == "VI";
	},

	getDescriptionToShow: function (city) {
		if (!city) return;
		return Vgr.util.capitalizeText(city.Description) + "- " + city.State.Abbreviation;
	},

	getCityDescription: function (city) {
		if (!city) return;
		return Vgr.util.capitalizeText(city.name) + "- " + city.state.abbreviation;
	},

	getStateDescription: function (state) {
		if (!state) return;
		return Vgr.util.capitalizeText(state.name) + "- " + state.abbreviation;
	},

	removeDuplicate: function (list, key) {
		return [...new Map(list.map((x) => [key(x), x])).values()];
	},

	getKey: function (label, obj) {
		"use strict";

		var matchKey = Object.keys(obj).find(function (key) {
			return obj[key] == label;
		});
		return matchKey;
	},

	sortListByField: function (list, sortField) {
		"use strict";

		return list.sort(function (item1, item2) {
			return Vgr.util.getDescendantProp(item1, sortField) > Vgr.util.getDescendantProp(item2, sortField) ? 1 : -1;
		});
	},

	mergeProperties: function (sourceObj, destinationObj, replace) {
		"use strict";

		var sourceKeys = Object.keys(sourceObj);
		sourceKeys.forEach(function (sourceKey) {
			if (replace) {
				destinationObj[sourceKey] = sourceObj[sourceKey] || destinationObj[sourceKey];
			} else destinationObj[sourceKey] = destinationObj[sourceKey] || sourceObj[sourceKey];
		});
	},

	uuid: function () {
		"use strict";

		var d = performance.now();
		var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
			var r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
			return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
		});
		return uuid;
	},

	base64FileBytestoBlob: function b64toBlob(b64Data, contentType, sliceSize) {
		"use strict";

		contentType = contentType || "";
		sliceSize = sliceSize || 512;

		if (contentType === ".docx") {
			contentType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
		} else if (contentType === ".doc") {
			contentType = "application/msword";
		} else if (contentType === ".xlsx") {
			contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
		} else if (contentType === ".xls") {
			contentType = "application/vnd.ms-excel";
		}

		var byteCharacters = atob(b64Data);
		var byteArrays = [];

		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);

			var byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			var byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}

		var blob = new Blob(byteArrays, { type: contentType });
		return blob;
	},

	getService: function (serviceName) {
		"use strict";

		return angular.element(document.body).injector().get(serviceName);
	},
	formatMoney: function (value, c, d, t) {
		let s;
		let i;
		let j;
		(c = isNaN((c = Math.abs(c))) ? 2 : c),
			(d = d == undefined ? "." : d),
			(t = t == undefined ? "," : t),
			(s = value < 0 ? "-" : ""),
			(i = parseInt((value = Math.abs(+value || 0).toFixed(c))) + ""),
			(j = (j = i.length) > 3 ? j % 3 : 0);
		return (
			s +
			(j ? i.substr(0, j) + t : "") +
			i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
			(c
				? d +
				  Math.abs(value - i)
						.toFixed(c)
						.slice(2)
				: "")
		);
	},
	formatCurrency: function (strValue, coin) {
		return coin + " " + Vgr.util.formatMoney(parseFloat(strValue), 2, ",", ".");
	},
	formatUnit: function (value, c, d, t) {
		(c = isNaN((c = Math.abs(c))) ? 2 : c),
			(d = d == undefined ? "." : d),
			(t = t == undefined ? "," : t),
			(s = value < 0 ? "-" : ""),
			(i = parseInt((value = Math.abs(+value || 0).toFixed(c))) + ""),
			(j = (j = i.length) > 3 ? j % 3 : 0);
		return (
			s +
			(j ? i.substr(0, j) + t : "") +
			i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
			(c
				? d +
				  Math.abs(value - i)
						.toFixed(c)
						.slice(2)
				: "")
		);
	},
	formatMeasureUnit: function (strValue, unit) {
		return Vgr.util.formatUnit(parseFloat(strValue), 2, ",", ".") + unit;
	},
	formatUnitDecimalPlaces: function (numValue, decimalPlaces) {
		return Vgr.util.formatUnit(numValue, decimalPlaces, ",", ".");
	},
	roundFloor: function (value, decimalPlaces) {
		var multiplier = Math.pow(10, decimalPlaces);
		return Math.floor(value * multiplier) / multiplier;
	},
	round: function (value, decimalPlaces) {
		var multiplier = Math.pow(10, decimalPlaces);
		return Math.round(value * multiplier) / multiplier;
	},
	phoneMask: function (phone) {
		var phone_str = phone.toString();
		return "(" + phone_str.substring(0, 2) + ")" + phone_str.substring(2, 11);
	},
	

	compareItems: function compareItems(item1, item2) {
		if (item1.hasOwnProperty("ID") || item2.hasOwnProperty("ID")) return item1.ID === item2.ID;

		if (item1.hasOwnProperty("id") || item2.hasOwnProperty("id")) return item1.id === item2.id;

		if (item1.hasOwnProperty("$$hashKey") || item2.hasOwnProperty("$$hashKey"))
			return item1.$$hashKey === item2.$$hashKey;

		return item1 === item2;
	},
	cut: function (value, wordwise, max, tail) {
		if (!value) return "";

		max = parseInt(max, 10);
		if (!max) return value;
		if (value.length <= max) return value;
		if (!isNaN(value)) return value;

		value = value.substr(0, max);
		if (wordwise) {
			var lastspace = value.lastIndexOf(" ");
			if (lastspace !== -1) {
				//Also remove . and , so its gives a cleaner result.
				if (value.charAt(lastspace - 1) === "." || value.charAt(lastspace - 1) === ",") {
					lastspace = lastspace - 1;
				}
				value = value.substr(0, lastspace);
			}
		}

		return value + (tail || " …");
	},

	getDateFromString: function (format) {
		if (!format || format.length < 11) return;
		var day = parseInt(format.substring(8, 10));
		var month = parseInt(format.substring(5, 7));
		var year = parseInt(format.substring(0, 4));
		var date = new Date(year, month - 1, day);
		return date;
	},

	getSumOfDate: function (fromDate, addDay) {
		var date = new Date(fromDate);
		date.setDate(fromDate.getDate() + addDay);
		return date;
	},

	getDateStringYYYYMMDD: function (date) {
		return date.toISOString().substring(0, 10);
	},

	getDate: function () {
		return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
	},

	compareDays: function (firstDate, secondDate) {
		return moment(Vgr.date.fromISOToMoment(firstDate).toDate()).diff(moment(secondDate), "days");
	},
	dateToString: function (date) {
		var mm = date.getMonth() + 1; // getMonth() is zero-based
		var dd = date.getDate();
		return (dd > 9 ? dd : "0" + dd) + "/" + (mm > 9 ? mm : "0" + mm) + "/" + date.getFullYear();
	},

	isStringIncluded: function (fullString, stringToSearch) {
		if (!stringToSearch) {
			return true;
		}

		var fullStringNormalized = this.normalizeString(fullString);
		var stringToSearchNormalized = this.normalizeString(stringToSearch);

		return fullStringNormalized.includes(stringToSearchNormalized);
	},

	isStringEqual: function (value1, value2) {
		if (!value1 && !value2) {
			return true;
		}

		var value1Normalized = this.normalizeString(value1);
		var value2Normalized = this.normalizeString(value2);

		return value1Normalized === value2Normalized;
	},

	normalizeString: function (value) {
		return value
			? value
					.toString()
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.toLowerCase()
					.trim()
			: "";
	},

	groupBy(list, keyGetter) {
		const map = new Map();
		list.forEach((item) => {
			const key = keyGetter(item);
			const collection = map.get(key);
			if (!collection) {
				map.set(key, [item]);
			} else {
				collection.push(item);
			}
		});
		return map;
	},

	generateEventKey(event, key) {
		return event + "&" + key;
	},

	generatePassword() {
		var length = 16,
			charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
			retVal = "";

		const array = new Uint32Array(length);
		window.crypto.getRandomValues(array);
		for (var i = 0, n = charset.length; i < length; ++i) {
			retVal += charset.charAt(Math.floor(array[i] % n));
		}

		return retVal;
	},

	isPromise(promise) {
		return !!promise && typeof promise.then === "function";
	},

	intersectTwoArrayOfObjects(array1, array2, key) {
		if (array1 && array2) {
			return array1.filter(function (item1) {
				return array2.some(function (items2) {
					return item1[key] === items2;
				});
			});
		} else {
			return [];
		}
	},

	areArraysEqual(array1, array2) {
		if (array1.length != array2.length) {
			return false;
		}

		return array1.find((i1) => !array2.includes(i1)) == null;
	},

	getTermsAndConditionsUrl() {
		return Vgr.constants.s3BaseUrl + Vgr.constants.content.termsAndConditionsLink;
	},

	getPrivacyPolicyUrl() {
		return Vgr.constants.site + Vgr.constants.content.privacyPolicyLink;
	},

	capitalizeObjectKeys(object) {
		const capitalize = (obj) => {
			const isObject = (o) => Object.prototype.toString.apply(o) === "[object Object]";
			const isArray = (o) => Object.prototype.toString.apply(o) === "[object Array]";

			const transformedObj = isArray(obj) ? [] : {};

			for (const key in obj) {
				const transformedKey = key.replace(/^\w/, (c, _) => c.toUpperCase());

				if (isObject(obj[key]) || isArray(obj[key])) {
					transformedObj[transformedKey] = capitalize(obj[key]);
				} else {
					transformedObj[transformedKey] = obj[key];
				}
			}
			return transformedObj;
		};
		return capitalize(object);
	},

	sumPropArray(array, key) {
		let result = null;
		if (array.some((item) => Math.abs(item[key]) > 0 || item[key] === 0)) {
			result = array.reduce((initialValue, q) => initialValue + (q[key] || 0), 0);
		}
		return result;
	},
	serializeObjectToQueryString(obj) {
		let parameters = "";
		if (obj) {
			const params = new URLSearchParams();
			Object.entries(obj).forEach(([key, value]) => {
				if (value || value === false) {
					if (Array.isArray(value)) {
						value.forEach((valueParameter) => params.append(key, valueParameter.toString()));
					} else {
						params.append(key, value.toString());
					}
				}
			});
			parameters = "?" + params.toString();
		}
		return parameters;
	},
	getWithCache(baseUrl, params, baseCacheKey, cacheTimeoutInMinutes, localStorageWithTimeoutService, $http, $q) {
		const parameters = Vgr.util.serializeObjectToQueryString(params);
		const cacheKey = baseCacheKey + parameters;
		const cachedEntities = localStorageWithTimeoutService.getFromCacheWithAutoExpiry(cacheKey);
		const deferred = $q.defer();
		if (cachedEntities) {
			deferred.resolve(cachedEntities);
		} else {
			const req = {
				method: "get",
				url: baseUrl + parameters
			};
			$http(req).then(
				function (response) {
					localStorageWithTimeoutService.setInCacheWithExpiry(cacheKey, response, cacheTimeoutInMinutes, null);
					deferred.resolve(response);
				},
				function () {
					deferred.reject();
				}
			);
		}
		return deferred.promise;
	},
	redirectSingleton($rootScope, route, queryString) {
		this.tempLastRoute = { route: route, queryString: queryString };
		$rootScope.$broadcast("REDIRECT_FRAME_SINGLETON", this.tempLastRoute);
	},
	tempLastRoute: undefined,
	replaceAt(string, replacement, index) {
		if (index >= string.length) {
			return string;
		}

		const chars = string.split("");
		chars[index] = replacement;
		return chars.join("");
	},

	sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	},

	isNumeric(value) {
		return /^-?\d+$/.test(value);
	}
};
