angular.module("vgresiduos").directive("disposalResidueStorages", function () {
	return {
		scope: {},
		controller: "DisposalResidueStorages",
		controllerAs: "ctrl",
		templateUrl: "views/destination/popups/disposalResiduePopup/components/disposalResidueStorages_template.html",
		bindToController: {
			storages: "=",
			isEdited: "=",
			isRecipientStockControlType: "="
		}
	};
});
