angular.module("componentes").controller("LoginFormCtrl", [
	"$scope",
	"$rootScope",
	"$state",
	"$stateParams",
	"httpService",
	"accountService",
	function ($scope, $rootScope, $state, $stateParams, httpService, accountService) {
		"use strict";

		$scope.forgotPassword = false;

		$scope.labels = $rootScope.labels;
		$scope.step = 1;

		$scope.forceChangePassword = false;

		$scope.account = {
			username: null,
			password: null
		};

		$scope.showBackToOptions = false;

		this.$onInit = function () {
			if ($stateParams.username) {
				$scope.account.username = $stateParams.username;
				$scope.step++;
				$scope.forceChangePassword = true;
			}

			if ($stateParams.showBack) {
				$scope.showBackToOptions = $stateParams.showBack;
			}

			if ($stateParams.error) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.UNEXPECTED_LOGIN_ERROR);
			}
		};

		$scope.toForgotPassword = function () {
			$state.go("forgottenPassword");
		};

		$scope.toLoginOptions = function () {
			$state.go("chooseLogin");
		};

		$scope.goToHome = function () {
			$scope.step = 1;
			$scope.account.username = null;
			$scope.account.password = null;
			$scope.forceChangePassword = false;
		};

		$scope.signIn = function () {
			$scope.loading = true;

			if (!validate()) {
				$scope.loading = false;
				return;
			}

			if ($scope.step == 1) {
				$scope.loading = false;
				$scope.step++;
				return;
			}

			accountService.loginWithUsernameAndPassword($scope.account.username, $scope.account.password).then(
				function (user) {
					if (user && user.challengeName == Vgr.constants.authentication.challenge.newPasswordRequired) {
						$state.go("changeTemporaryPassword");
					} else {
						onLoginSuccessful();
					}
				},
				function (error) {
					onLoginError(error);
				}
			);
		};

		function onLoginSuccessful() {
			const isAppLogin = accountService.getAppLogin();

			if (isAppLogin) {
				const tokens = accountService.getCachedVgrToken();
				const idp = accountService.getPreviousLoggedSsoIdp();
				const message = { type: "login", payload: { tokens, idp } };

				window.ReactNativeWebView.postMessage(JSON.stringify(message));
			} else {
				$scope.loading = false;
				window.location.reload();
			}
		}

		function onLoginError(response) {
			let message = $rootScope.labels.AUTH_ERRORS[response.code];
			if (!message) {
				message = $rootScope.labels.AUTH_ERRORS.DefaultError;
			}

			if (response.code == Vgr.constants.authentication.errors.UserNotConfirmedException) {
				resendConfirmationCode();
				return;
			}

			if (response.code == Vgr.constants.authentication.errors.PasswordResetRequiredException) {
				$rootScope.passwordResetRequired = true;
				$state.go("forgottenPassword");
				return;
			}

			if (
				response.code == Vgr.constants.authentication.errors.NotAuthorizedException &&
				response.message == Vgr.constants.authentication.errorMessages.UserDisabled
			) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.USER_DISABLED);
			} else {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, message);
			}

			$scope.loading = false;
		}

		function resendConfirmationCode() {
			httpService.postDTOToServiceV2(accountService.resendCode, $scope.account.username).then(
				function () {
					$rootScope.usernameConfirming = $scope.account.username;
					$state.go("registerConfirmation");
					$scope.loading = false;
				},
				function () {
					$scope.loading = false;
				}
			);
		}

		function validate() {
			if (!$scope.account.username && $scope.step == 1) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.EMAIL_OR_USERNAME_MISSING);
				return false;
			}
			if (!$scope.account.password && $scope.step == 2) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.PASSWORD_MISSING);
				return false;
			}

			return true;
		}

		$scope.getSignInLabel = function () {
			if ($scope.step == 1) {
				return $scope.labels.NEXT;
			} else {
				return $scope.labels.SIGN_IN;
			}
		};

		$scope.getForceChangePasswordLabel = function () {
			return $scope.labels.INSERT_TEMPORARY_PASSWORD;
		};
	}
]);
