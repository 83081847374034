angular.module("vgresiduos").controller("CustomReportFilterGroupingSectionViewCtrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;

			if ($scope.$ctrl.setter && $scope.$ctrl.setter.length > 0) {
				$scope.showGroupings = true;
				loadGroupings();
			} else {
				$scope.showGroupings = false;
			}
		};

		function loadGroupings() {
			$scope.groupings = $scope.$ctrl.setter
				.map(function (grouping) {
					if (grouping.additionalProperty) {
						return grouping.additionalProperty.name;
					} else {
						return grouping.systemReportProperty.description;
					}
				})
				.join(", ");
		}
	}
]);
