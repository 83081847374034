angular.module("vgresiduos").component("customReportFilterDateRangeSection", {
	controller: "CustomReportFilterDateRangeSectionCtrl",
	templateUrl:
		"views/customReports/components/customReportFilter/sections/customReportFilterDateRangeSection_template.html",
	bindings: {
		reportType: "<",
		callback: "<?",
		setter: "<?",
		isDisabled: "<?"
	}
});
