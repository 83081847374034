angular.module("vgresiduos").component("measureUnitV2", {
	controller: "MeasureUnitV2Ctrl",
	templateUrl: "views/components/fields/measureUnit/measureUnitV2_template.html",
	bindings: {
		setter: "<?",
		callback: "<?",

		onlyPricingUnits: "<?",
		onlyResidueUnits: "<?",
		measureUnitGroup: "<?",

		isDisabled: "<?",
		isRequired: "<?",
		emptyField: "<?",

		identifier: "<?"
	}
});
