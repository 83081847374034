angular.module("vgresiduos").component("addressFieldsetForm", {
	controller: "AddressFieldsetFormCtrl",
	templateUrl: "views/components/fieldsets/addressFieldsetForm/addressFieldsetForm_template.html",
	bindings: {
		address: "=?",
		city: "=?",
		isDisabled: "=?",
		neighborhood: "=?",
		cep: "=?",
		cityId: "=?",
		countryId: "=?",
		isDisabledCountry: "=?"
	}
});
