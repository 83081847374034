angular.module("vgresiduos").controller("ClientUserExternalSystemsCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"externalSystemService",
	"dialogService",
	"permissionService",
	"$stateParams",
	"accountService",
	"clientService",
	function (
		$scope,
		$rootScope,
		$q,
		httpService,
		externalSystemService,
		dialogService,
		permissionService,
		$stateParams,
		accountService,
		clientService
	) {
		"use strict";

		const editPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.EditUnit);
		$scope.isSupplier = clientService.isSupplier();
		const organizationUnitId = $stateParams.id;
		const currentUserId = accountService.getLoggedAccount().code;
		const externalSystemLoginStatus = Vgr.enumerations.externalSystemLoginStatus;
		const labels = $rootScope.labels;

		this.$onInit = function () {
			$scope.labelClientUserExternalSystems =
				labels.INTEGRATION_BY_USER_ON_SYSTEM +
				" " +
				labels.MTR_SYSTEM_USER_INTEGRATION_ENUM[$scope.ctrl.externalSystemId];
			$scope.popupDeferred = "";
			$scope.hasPermission = hasPermission;
			$scope.clientUserExternalSystemsGrid = {
				list: [],
				defaultActions: ["refresh"],
				actionButtons: [],
				columns: [
					{
						description: labels.NAME,
						property: "user.name"
					},
					{
						description: labels.EMAIL,
						property: "user.email"
					},
					{
						description: labels.LOGIN,
						property: "login"
					}
				],
				btnAddClick: function () {
					openPreferencesPopup();
				},
				btnRefreshClick: function () {
					list();
				}
			};

			if (!$scope.isSupplier) {
				$scope.clientUserExternalSystemsGrid.columns.push({
					description: labels.LOGIN_STATUS,
					property: "loginStatusDescription",
					getTooltip: function (row) {
						return labels.MTR_SYSTEM_CLIENT_LOGIN_STATUS_TOOLTIP[row.loginStatus];
					},
					getClass: function (row) {
						if (row.loginStatus == externalSystemLoginStatus.Ok) {
							return "c-green-primary";
						} else if (row.loginStatus == externalSystemLoginStatus.Error) {
							return "c-red";
						}
						return "f-bold";
					}
				});
			}

			list().then(configureActions);
		};

		function hasPermission(row) {
			return editPermission || (row && row.user.code == currentUserId);
		}

		function deleteUser(row) {
			const params = {
				popupTitle: labels.DELETE_USER_EXTERNAL_SYSTEM_POPUP.TITLE,
				popupText: labels.DELETE_USER_EXTERNAL_SYSTEM_POPUP.DESCRIPTION,
				userCode: row.user.code,
				externalSystemId: $scope.ctrl.externalSystemId,
				organizationUnitId: organizationUnitId
			};
			dialogService
				.showDialogFromTemplateV2(
					"views/client/components/clientForm/clientFormIntegration/baseComponents/ClientUserExternalSystemsPopupDelete/clientUserExternalSystemsPopupDelete_template.html",
					"ClientUserExternalSystemsPopupDeleteCtrl",
					"#middle-popup",
					params
				)
				.then(function () {
					list();
				});
		}

		function openPreferencesPopup(mtrOnlinePreferenceSelected) {
			const params = {
				clientUserExternalSystem: mtrOnlinePreferenceSelected,
				externalSystemId: $scope.ctrl.externalSystemId,
				userId: null,
				onCancel: function () {
					dialogService.hide($scope.dialog);
				},
				onOk: function (data) {
					dialogService.hide($scope.dialog);
					list();
				}
			};

			if (!mtrOnlinePreferenceSelected && !hasPermission()) {
				params.userId = currentUserId;
			}

			const dialog = dialogService
				.showDialogFromTemplateWithScope(
					null,
					"views/client/components/clientForm/clientFormIntegration/baseComponents/clientUserExternalSystemsPopup/clientUserExternalSystemsPopup_template.html",
					"ClientUserExternalSystemsPopupCtrl",
					null,
					null,
					false,
					function () {},
					params
				)
				.then(
					function () {},
					function () {}
				);
			$scope.dialog = dialog;
			return dialog;
		}

		function list() {
			$scope.clientUserExternalSystemsGrid.loading = true;
			const dto = { externalSystemId: $scope.ctrl.externalSystemId, UnitId: organizationUnitId };
			return httpService.getDTOFromServiceV2(externalSystemService.listClientUserExternalSystems, dto).then(
				function (content) {
					$scope.clientUserExternalSystemsGrid.list = content;
					formatLoginStatusDescription();
					$scope.clientUserExternalSystemsGrid.loading = false;
				},
				function () {
					$scope.clientUserExternalSystemsGrid.loading = false;
				}
			);
		}

		function formatLoginStatusDescription() {
			for (const externalSystemClient of $scope.clientUserExternalSystemsGrid.list) {
				externalSystemClient.loginStatusDescription =
					labels.MTR_SYSTEM_CLIENT_LOGIN_STATUS[externalSystemClient.loginStatus];
			}
		}

		function configureActions() {
			if (hasPermission() || !currentUserHasExternalSystem()) {
				$scope.clientUserExternalSystemsGrid.defaultActions.unshift("add");
			}

			$scope.clientUserExternalSystemsGrid.actionButtons = [
				{
					label: labels.EDIT,
					icon: "edit",
					click: function (row) {
						openPreferencesPopup(row);
					}
				},
				{
					label: labels.DELETE,
					icon: "delete",
					click: function (row) {
						deleteUser(row);
					}
				}
			];
		}

		function currentUserHasExternalSystem() {
			const currentUserExternalSystem = $scope.clientUserExternalSystemsGrid.list.find(
				(cues) => cues.user.code == currentUserId
			);
			return currentUserExternalSystem != null;
		}
	}
]);
