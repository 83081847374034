angular.module("vgresiduos").controller("createContactCtrl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `organizacao/fornecedores/${$stateParams.supplierCode}/contato`, [
				{ key: "supplierId", value: $stateParams.supplierId }
			]);
		};
	}
]);
