angular.module("vgresiduos").factory("dashboardService", [
	"$http",
	function ($http) {
		"use strict";

		const _getLicensesToExpire = function GetLicensesToExpire() {
			const req = {
				method: "post",
				url: Vgr.constants.newHostUrl + Vgr.resources.api.dashboard + "GetLicensesToExpire"
			};
			return $http(req);
		};

		const _getUsersMoreAccesses = function GetUsersMoreAccesses() {
			const req = {
				method: "post",
				url: Vgr.constants.newHostUrl + Vgr.resources.api.dashboard + "GetUsersMoreAccesses"
			};
			return $http(req);
		};

		const _getResiduesGenerated = function GetResiduesGenerated() {
			const req = {
				method: "post",
				url: Vgr.constants.newHostUrl + Vgr.resources.api.dashboard + "GetResiduesGenerated"
			};
			return $http(req);
		};

		const _getResiduesDestinated = function GetResiduesDestinated() {
			const req = {
				method: "post",
				url: Vgr.constants.newHostUrl + Vgr.resources.api.dashboard + "GetResiduesDestinated"
			};
			return $http(req);
		};

		const _getExpenseSummary = function GetExpenseSummary() {
			const req = {
				method: "post",
				url: Vgr.constants.newHostUrl + Vgr.resources.api.dashboard + "GetExpenseSummary"
			};
			return $http(req);
		};

		const _getExpenseManagement = function GetExpenseManagement(dto) {
			const req = {
				method: "post",
				url: Vgr.constants.newHostUrl + Vgr.resources.api.dashboard + "GetExpenseManagement",
				data: dto
			};
			return $http(req);
		};

		const _getOpportunities = function GetOpportunities() {
			const req = {
				method: "post",
				url: Vgr.constants.newHostUrl + Vgr.resources.api.dashboard + "GetOpportunities"
			};
			return $http(req);
		};

		return {
			getResiduesDestinated: _getResiduesDestinated,
			getResiduesGenerated: _getResiduesGenerated,
			getUsersMoreAccesses: _getUsersMoreAccesses,
			getLicensesToExpire: _getLicensesToExpire,
			getExpenseSummary: _getExpenseSummary,
			getExpenseManagement: _getExpenseManagement,
			getOpportunities: _getOpportunities
		};
	}
]);
