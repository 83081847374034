angular.module("vgresiduos").directive("ibamaRappCreateSummary", function () {
	return {
		scope: true,
		controller: "IbamaRappCreateSummaryCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/ibama/ibamaRappCreate/stages/ibamaRappCreateSummary_template.html",
		bindToController: {
			model: "="
		}
	};
});
