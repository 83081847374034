angular.module("vgresiduos").controller("ResidueMultiSelectCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"residueV2Service",
	function ($scope, $rootScope, $q, httpService, residueV2Service) {
		"use strict";

		$scope.labels = $rootScope.labels;
		let isListLoaded;

		this.$onInit = function () {
			loadResidues();
		};

		function loadResidues() {
			const deferred = $q.defer();

			if (isListLoaded) {
				deferred.resolve();
				return deferred.promise;
			}

			httpService.getListFromServiceCore(residueV2Service.listResidues).then(
				function (response) {
					$scope.residues = response.list;
					isListLoaded = true;
					deferred.resolve();
				},
				function (data) {
					deferred.reject();
				}
			);

			return deferred.promise;
		}
	}
]);
