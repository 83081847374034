angular.module("vgresiduos").controller("DestinationDataCtrl", [
	"$scope",
	function ($scope) {
		"use strict";

		$scope.isSolicitationStage = function () {
			return $scope.destinationConfig.currentStage.Id == $scope.stagesEnum.Solicitation;
		};

		$scope.isGatheringStage = function () {
			return $scope.destinationConfig.currentStage.Id == $scope.stagesEnum.Gathering;
		};

		$scope.isTrackingStage = function () {
			return $scope.destinationConfig.currentStage.Id == $scope.stagesEnum.Tracking;
		};

		$scope.isArrivalStage = function () {
			return $scope.destinationConfig.currentStage.Id == $scope.stagesEnum.Arrival;
		};

		$scope.isTemporaryStorageStage = function () {
			return $scope.destinationConfig.currentStage.Id == $scope.stagesEnum.InTemporaryStorage;
		};

		$scope.isConfirmationStage = function () {
			return $scope.destinationConfig.currentStage.Id == $scope.stagesEnum.Confirmation;
		};

		$scope.getDisposalFilePath = function () {
			return "Clients/" + $scope.destinationModel.Client.Id + "/Disposals/" + $scope.destinationModel.Id;
		};
	}
]);
