angular.module("vgresiduos").controller("ResidueClassMultiSelectV2Ctrl", [
	"$scope",
	"$rootScope",
	"residueV2Service",
	"httpService",
	function ($scope, $rootScope, residueV2Service, httpService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			listResidueClasses();
		};

		function listResidueClasses() {
			httpService.getListFromServiceCore(residueV2Service.listResidueClasses).then(
				function (response) {
					const list = response.list;
					list.forEach((element) => {
						element.id = element.id.toString();
						element.displayText = element.name;
					});
					$scope.classes = list;
				},
				function () {
					$scope.classes = [];
				}
			);
		}

		$scope.onChanged = function (selectedValues) {
			callback(selectedValues);
		};

		function callback(selectedValues) {
			if ($scope.$ctrl.callback) {
				$scope.$ctrl.callback(selectedValues);
			}
		}
	}
]);
