angular.module("vgresiduos").component("scalingButtons", {
	controller: "ScalingButtonsCtrl",
	templateUrl: "views/components/scalingQuantity/scalingButtons_template.html",
	bindings: {
		scalingButtonLabel: "@",
		unitScalingConfigurations: "<",
		scalingProcess: "<",
		onScalingTypeChange: "<",
		onToggleTwoStepsScaling: "<",
		onWeightButtonClick: "<",
		onClickClearButton: "<",
		onCallTareWeight: "<",
		onTotalWeightChanged: "<",
		setterEnableTwoStepScaling: "<",
		setterSelectedScalingType: "<",
		isDisabled: "<?"
	}
});
