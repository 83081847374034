angular.module("vgresiduos").controller("UsersSelectCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"userService",
	function ($scope, $rootScope, $q, httpService, userService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		var isListLoaded;

		this.$onInit = function () {
			$scope.label = $scope.$ctrl.label ? $scope.$ctrl.label : $scope.labels.USER;
			listUsers().then(setUser);
		};

		function listUsers() {
			var deferred = $q.defer();

			if (isListLoaded) {
				deferred.resolve();
				return deferred.promise;
			}

			httpService.getDTOFromServiceV2(userService.listUsersV2).then(
				function (list) {
					if ($scope.$ctrl.excluded) {
						list = list.filter((u) => !$scope.$ctrl.excluded.includes(u.id));
					}
					$scope.users = list;
					isListLoaded = true;
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function setUser() {
			var foundUser;
			if ($scope.$ctrl.setterId) {
				foundUser = $scope.users.find((u) => u.id == $scope.$ctrl.setterId);
				if (foundUser != null) {
					$scope.$ctrl.model = foundUser;
				}
			} else if ($scope.$ctrl.setterCode) {
				foundUser = $scope.users.find((u) => u.code == $scope.$ctrl.setterCode);
				if (foundUser != null) {
					$scope.$ctrl.model = foundUser;
				}
			} else if ($scope.$ctrl.setterModel) {
				foundUser = $scope.users.find((u) => u.id == $scope.$ctrl.setterModel.id);
				if (foundUser != null) {
					$scope.$ctrl.model = foundUser;
				} else {
					$scope.users.push($scope.$ctrl.setterModel);
					$scope.$ctrl.model = $scope.users.find((u) => u.id == $scope.$ctrl.setterModel.id);
				}
			}
		}

		$scope.$watch("$ctrl.setterId", function (newValue, oldValue) {
			var modelId = $scope.$ctrl.model ? $scope.$ctrl.model.id : null;

			if (newValue != modelId) {
				listUsers().then(setUser);
			}
		});

		$scope.$watch("$ctrl.setterCode", function (newValue, oldValue) {
			if ($scope.$ctrl.setterId) {
				return;
			}

			var modelCode = $scope.$ctrl.model ? $scope.$ctrl.model.code : null;

			if (newValue != modelCode) {
				listUsers().then(setUser);
			}
		});
	}
]);
