angular.module("vgresiduos").component("clientTextComplete", {
	controller: "ClientTextCompleteCtrl",
	templateUrl: "views/components/fields/clientTextComplete/clientTextComplete_template.html",
	bindings: {
		model: "=?",
		permissionId: "<?",
		onChange: "=?",
		excluded: "<?",
		label: "@",
		isDisabled: "=?",
		isRequired: "<?",
		setterId: "<?"
	}
});
