angular.module("vgresiduos").controller("AuditDashboardItemClassificationCtrl", [
	"$rootScope",
	"$scope",
	"httpService",
	"auditService",
	function ($rootScope, $scope, httpService, auditService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			listAuditItemClassifications();
		};

		function listAuditItemClassifications() {
			httpService.getListFromServiceCore(auditService.listAuditItemClassifications, null).then(
				function (response) {
					$scope.itemClassificationsList = response.list;
					for (const classification of $scope.itemClassificationsList) {
						loadAuditItemClassification(classification);
					}
				},
				function () {
					//Nothing to be done
				}
			);
		}

		function loadAuditItemClassification(itemClassification) {
			if (!$scope.ctrl.audit.ItemResults || !$scope.ctrl.audit.ItemResults.length) {
				return;
			}
			for (var i = 0; i < $scope.ctrl.audit.ItemResults.length; i++) {
				if ($scope.ctrl.audit.ItemResults[i].Classification == itemClassification.id) {
					itemClassification.HasValue = true;
					itemClassification.PercentualScore = $scope.ctrl.audit.ItemResults[i].PercentualScore;
					itemClassification.NotAttendedTotal = $scope.ctrl.audit.ItemResults[i].NotAttendedTotal;
					itemClassification.PartiallyAttendedTotal = $scope.ctrl.audit.ItemResults[i].PartiallyAttendedTotal;
					loadItemClassificationDetails(itemClassification);
					return;
				}
			}
		}

		function loadItemClassificationDetails(itemClassification) {
			if (itemClassification.PercentualScore == 100) {
				itemClassification.Details = $scope.labels.ALL_ATTENDED;
			} else if (itemClassification.NotAttendedTotal > 0) {
				itemClassification.Details = itemClassification.NotAttendedTotal + " " + $scope.labels.NOT_ATTENDED_TOTAL;
			} else if (itemClassification.PartiallyAttendedTotal > 0) {
				itemClassification.Details =
					itemClassification.PartiallyAttendedTotal + " " + $scope.labels.PARTIALLY_ATTENDED_TOTAL;
			}
			//this should never happen, check server
			else {
				itemClassification.PercentualScore = 100;
				itemClassification.Details = $scope.labels.ALL_ATTENDED;
			}
		}
	}
]);
