angular.module("vgresiduos").component("clientUserField", {
	controller: "ClientUserFieldCtrl",
	templateUrl: "views/components/fields/clientUserField/clientUserField_template.html",
	bindings: {
		label: "@?",
		customClasses: "@?",
		isDisabled: "<?",
		emptyField: "<?",
		displayField: "@?",

		setterId: "<?",

		model: "="
	}
});
