angular.module("vgresiduos").component("usersMultiSelect", {
	controller: "UsersMultiSelectCtrl",
	templateUrl: "views/components/fields/usersMultiSelect/usersMultiSelect_template.html",
	bindings: {
		onChange: "<",
		searchLabel: "@",
		customClasses: "@?",
		initialValues: "<?",
		label: "@",
		organizationUsers: "<?"
	}
});
