angular.module("vgresiduos").factory("asyncTimeoutService", [
	function () {
		"use strict";

		const _startAsyncCallTimer = function (obj, callbackFunc, maxTimeoutInMilliseconds, poolingTimes) {
			obj.asyncCallTimers = [];
			for (let i = 0; i < poolingTimes; ++i) {
				const poolingTimeout = (maxTimeoutInMilliseconds / poolingTimes) * (i + 1);
				obj.asyncCallTimers.push(
					setTimeout(function () {
						callbackFunc(obj, maxTimeoutInMilliseconds / poolingTimeout == 1);
					}, poolingTimeout)
				);
			}
		};

		const _cancelAsyncTimer = function (system) {
			if (system && system.asyncCallTimers && system.asyncCallTimers.length > 0) {
				for (const asyncTimer of system.asyncCallTimers) {
					clearTimeout(asyncTimer);
				}
				system.asyncCallTimers = [];
			}
		};

		const _getTimeoutBySystem = function (sourceId, isCreation) {
			return Vgr.constants.socketDefaultTimeout;
		};

		return {
			startAsyncCallTimer: _startAsyncCallTimer,
			cancelAsyncTimer: _cancelAsyncTimer,
			getTimeoutBySystem: _getTimeoutBySystem
		};
	}
]);
