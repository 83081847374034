angular.module("vgresiduos").controller("ConversionCalculatorCtrl", [
	"$scope",
	"$q",
	"httpService",
	"measureUnitService",
	"calculusService",
	"clientService",
	function ($scope, $q, httpService, measureUnitService, calculusService, clientService) {
		"use strict";

		this.$onInit = function () {
			setDefaultParams();
			loadMeasureUnits().then(setMeasureUnitsGroups);
		};

		$scope.calculatorModel = {};
		var isListLoaded;

		$scope.decimals = clientService.getDecimalPlaces();

		$scope.calculate = function () {
			if (
				!$scope.calculatorModel.quantity ||
				!$scope.calculatorModel.gatheringGroupMeasureUnit ||
				!$scope.calculatorModel.equivalentQuantity ||
				!$scope.calculatorModel.equivalentMeasureUnit
			) {
				return;
			}

			calculateConversion();
		};

		function loadMeasureUnits() {
			var deferred = $q.defer();

			if (isListLoaded) {
				deferred.resolve();
				return deferred.promise;
			}

			var dto = {
				onlyResidueUnits: true
			};
			httpService.getListFromServiceV2(measureUnitService.listMeasureUnits, dto, "MeasureUnits").then(
				function (list) {
					$scope.measureUnits = list;
					isListLoaded = true;
					deferred.resolve();
				},
				function (data) {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function setMeasureUnitsGroups() {
			$scope.massMeasureUnits = [];
			$scope.gatheringGroupMeasureUnits = [];

			for (var measureUnit of $scope.measureUnits) {
				if (measureUnit.Group == Vgr.enumerations.measureUnit.Group.Mass) {
					var massMeasureUnit = angular.copy(measureUnit);
					$scope.massMeasureUnits.push(massMeasureUnit);
				}
				if (measureUnit.Group == $scope.ctrl.measureUnit.Group) {
					var groupMeasureUnit = angular.copy(measureUnit);
					$scope.gatheringGroupMeasureUnits.push(groupMeasureUnit);
				}
			}
		}

		function setDefaultParams() {
			$scope.calculatorModel.quantity = 1;
		}

		function calculateConversion() {
			var dto = {
				Quantity: $scope.calculatorModel.quantity,
				UnitId: $scope.calculatorModel.gatheringGroupMeasureUnit.Id,
				FinalQuantity: $scope.calculatorModel.equivalentQuantity,
				FinalUnitId: $scope.calculatorModel.equivalentMeasureUnit.Id
			};
			httpService.postDTOToServiceV2(calculusService.calculateConversionFactor, dto).then(
				function (response) {
					$scope.ctrl.conversionFactorChanged(
						response.ConversionFactor,
						$scope.calculatorModel.gatheringGroupMeasureUnit
					);
				},
				function (response) {}
			);
		}

		$scope.$watch("ctrl.measureUnit", function (newValue) {
			loadMeasureUnits().then(setMeasureUnitsGroups);
		});
	}
]);
