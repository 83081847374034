angular.module("vgresiduos").factory("directiveService", [
	"$http",
	"accountService",
	function ($http, accountService) {
		"use strict";

		var directiveService = {};

		var _getDirective = function (directiveId) {
			var organizationId = accountService.getCurrentClient().organization.id;

			var req = {
				method: "get",
				url:
					Vgr.constants.coreHostUrl +
					Vgr.resources.core.directives
						.replace("[ORGANIZATION_ID]", organizationId) + 
						"/" +
						directiveId
			};

			return $http(req);
		};

		directiveService.getDirective = _getDirective;

		return directiveService;
	}
]);
