angular.module("vgresiduos").controller("IbamaRappCreateSummaryResidueGenerationGridCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		var labels = $rootScope.labels;

		var columns = [
			{
				field: "Activity",
				displayName: function () {
					return labels.IBAMA_ACTIVITY;
				},
				width: 50
			},
			{
				field: "IbamaCode",
				displayName: function () {
					return labels.IBAMA_ID;
				},
				width: 50
			},
			{
				field: "ResidueDescription",
				displayName: function () {
					return labels.RESIDUE;
				},
				width: 400
			},
			{
				field: "GeneratedQuantity",
				displayName: function () {
					return labels.GENERATED_QUANTITY;
				},
				width: 200
			}
		];

		$scope.gridOptions = {
			columnDefinitions: columns,
			filterFields: ["Activity", "IbamaCode", "ResidueDescription", "GeneratedQuantity"],
			data: [],
			query: {
				order: "Activity",
				limit: 10,
				page: 1
			},
			promise: {},
			hasData: false,
			removeNew: true,
			removeDelete: true,
			errorLoading: false
		};

		this.$onInit = function () {
			$scope.gridOptions.data = $scope.ctrl.list;
			$scope.gridOptions.hasData = true;
		};
	}
]);
