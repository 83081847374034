angular.module("vgresiduos").controller("CustomizedFilterFieldCtrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";
		$scope.labels = $rootScope.labels;

		$scope.onSelectField = function () {
			var oldSelectedField;
			if (!$scope.currentSelectedField) {
				$scope.currentSelectedField = $scope.ctrl.selectedField;
			} else if ($scope.currentSelectedField.id != $scope.ctrl.selectedField.id) {
				oldSelectedField = angular.copy($scope.ctrl.selectedField);
				$scope.currentSelectedField = $scope.ctrl.selectedField;
			}
			if ($scope.ctrl.onChangeSelectField) {
				$scope.ctrl.onChangeSelectField($scope.currentSelectedField, oldSelectedField);
			}
		};
	}
]);
