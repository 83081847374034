angular.module("componentes").directive("changeTemporaryPasswordForm", [
	function () {
		"use strict";
		return {
			scope: {},
			controller: "ChangeTemporaryPasswordFormCtrl",
			controllerAs: "ctrl",
			templateUrl: "views/register/components/changeTemporaryPasswordForm_template.html"
		};
	}
]);
