angular.module("vgresiduos").controller("AuditDashboardClassificationCtrl", [
	"$rootScope",
	"$scope",
	"auditService",
	function ($rootScope, $scope, auditService) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.auditLevelsDescriptions = {};

		this.$onInit = function () {
			$scope.audit = auditService.loadClientClassification($scope.ctrl.audit);
			getAuditLevelDescription();
		};

		function getAuditLevelDescription() {
			if ($scope.ctrl.audit && !$scope.ctrl.audit.LevelDescription) {
				$scope.ctrl.audit.LevelDescription = auditService.getDefaultAuditLevelDescription($scope.ctrl.audit.Level);
			}
		}
	}
]);
