angular.module("vgresiduos").factory("ibamaService", [
	"$http",
	"$q",
	"localStorageWithTimeoutService",
	function ($http, $q, localStorageWithTimeoutService) {
		"use strict";

		const cacheTimeoutInMinutes = 43200;

		const _listIbamaResidues = function () {
			return Vgr.util.getWithCache(
				Vgr.constants.newHostUrl + Vgr.resources.apiV1.ibamaResidues,
				null,
				Vgr.constants.cacheKeysPrefix.ibamaResidues,
				cacheTimeoutInMinutes,
				localStorageWithTimeoutService,
				$http,
				$q
			);
		};

		const _listIbamaActivities = function () {
			return Vgr.util.getWithCache(
				Vgr.constants.coreHostUrl + Vgr.resources.core.ibamaActivities,
				null,
				Vgr.constants.cacheKeysPrefix.ibamaActivities,
				cacheTimeoutInMinutes,
				localStorageWithTimeoutService,
				$http,
				$q
			);
		};

		return {
			listIbamaResidues: _listIbamaResidues,
			listIbamaActivities: _listIbamaActivities
		};
	}
]);
