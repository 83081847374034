angular.module("vgresiduos").component("customizedFilterMultiGrouping", {
	controller: "CustomizedFilterMultiGroupingCtrl",
	templateUrl:
		"views/components/customizedFilterV2/customizedFilterMultiGrouping/customizedFilterMultiGrouping_template.html",
	bindings: {
		label: "@?",
		list: "<",
		setter: "<?",
		callback: "<",
		clear: "<"
	}
});
