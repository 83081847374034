angular.module("vgresiduos").controller("ClientUserExternalSystemsPopupCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"externalSystemService",
	"clientUserExternalSystem",
	"externalSystemId",
	"userId",
	"onCancel",
	"onOk",
	"$stateParams",
	"clientService",
	function (
		$scope,
		$rootScope,
		httpService,
		externalSystemService,
		clientUserExternalSystem,
		externalSystemId,
		userId,
		onCancel,
		onOk,
		$stateParams,
		clientService
	) {
		"use strict";

		const ImaSCId = Vgr.enumerations.destination.systemSource.IMA_SC;
		const organizationUnitId = $stateParams.id;

		$scope.clientUserExternalSystem = clientUserExternalSystem;
		$scope.labels = $rootScope.labels;
		$scope.labelPopup = $scope.labels.REGISTER_USER_IN_MTR_ENUM[externalSystemId];
		$scope.model = {};
		$scope.hasChecked = false;
		$scope.isSupplier = clientService.isSupplier();

		$scope.isPasswordMandatory = function () {
			return $scope.clientUserExternalSystem ? false : true;
		};

		$scope.cancel = function () {
			onCancel();
		};

		$scope.confirm = function () {
			const isInvalid = !isFieldsValid();
			if (isInvalid) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.VALIDATION_ERROR_MESSAGE);
				return;
			}

			if ($scope.clientUserExternalSystem) {
				update();
			} else {
				create();
			}
		};

		function update() {
			const dto = buildDto();
			httpService.postDTOToServiceV2(externalSystemService.updateClientUserExternalSystems, dto).then(function (data) {
				onOk();
			});
		}

		function create() {
			const dto = buildDto();
			httpService.postDTOToServiceV2(externalSystemService.createClientUserExternalSystems, dto).then(function (data) {
				onOk(data);
			});
		}

		function buildDto() {
			const login = $scope.model.Login ? $scope.model.Login : null;

			return {
				model: {
					Login: login,
					Password: $scope.model.Password,
					UserCode: $scope.model.User.code,
					UnitId: organizationUnitId,
					ExternalSystemId: externalSystemId
				}
			};
		}

		function isFieldsValid() {
			$scope.hasChecked = true;

			const isNewInvalid = !$scope.clientUserExternalSystem && !$scope.model.Password;
			const isInvalidFields = !$scope.model.User || !isLoginValid() || isNewInvalid;

			return !isInvalidFields;
		}

		function isLoginValid() {
			return $scope.skipLoginField || $scope.model.Login;
		}

		function loadDataForEdit() {
			$scope.isEdition = true;
			$scope.model.userId = $scope.clientUserExternalSystem.user.code;
			$scope.model.Login = $scope.clientUserExternalSystem.login;
		}

		function configureFields() {
			if (externalSystemId == ImaSCId) {
				$scope.skipLoginField = true;
			} else {
				$scope.skipLoginField = false;
				$scope.loginIsEmail = false;
			}
		}

		function initializePopup() {
			configureFields();

			if ($scope.clientUserExternalSystem) {
				loadDataForEdit();
			} else {
				$scope.isEdition = false;
				if (userId) {
					$scope.disableUserField = true;
					$scope.model.userId = userId;
				}
			}
		}

		initializePopup();
	}
]);
