angular.module("vgresiduos").directive("customizedFilter", function () {
	return {
		scope: true,
		controller: "CustomizedFilterCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/customizedFilter/customizedFilter_template.html",
		transclude: {
			customFiltersSlot: "?customFilters",
			customButtonsSlot: "?customButtons"
		},
		bindToController: {
			isFilterActive: "=",
			availableFields: "=",
			initialValues: "=",
			filterOptions: "=",
			applyFilters: "=",
			onCleanFilters: "=",
			hasActiveFilters: "=",
			showMinimizeButton: "=",
			filterBtnLabel: "@?",
			fieldLabel: "@?",
			preFilterOptions: "=?"
		}
	};
});
