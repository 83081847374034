angular.module("vgresiduos").controller("DisposalPendenciesPopupCtrl", [
	"dialogService",
	"$scope",
	function (dialogService, $scope) {
		"use strict";

		$scope.cancel = function () {
			dialogService.hide($scope.dialog);
		};

		$scope.confirm = function () {
			for (var i in $scope.disposalPendencies) {
				$scope.destinationModel.Pendencies[i].IsChecked = $scope.disposalPendencies[i].IsChecked;
			}
			dialogService.hide($scope.dialog);
		};

		function checkIfHasPendencies() {
			for (let pendency of $scope.disposalPendencies) {
				checkIfPendencyIsResolved(pendency);
			}
		}

		function checkIfPendencyIsResolved(pendency) {
			switch (pendency.Type) {
				case Vgr.enumerations.destination.pendencyType.Invoice:
					pendency.Resolved =
						$scope.destinationModel.PhysicalFilesInvoice ||
						($scope.destinationModel.InvoiceFiles.length > 0 && pendency.IsChecked)
							? true
							: false;
					break;

				case Vgr.enumerations.destination.pendencyType.CDF:
					pendency.Resolved =
						$scope.destinationModel.PhysicalFilesCDF ||
						($scope.destinationModel.CDFFiles.length > 0 && pendency.IsChecked)
							? true
							: false;
					break;

				case Vgr.enumerations.destination.pendencyType.MTR:
					pendency.Resolved =
						$scope.destinationModel.PhysicalFilesManifest ||
						($scope.destinationModel.ManifestFiles.length > 0 && pendency.IsChecked)
							? true
							: false;
					break;
			}
		}

		function initializePopup() {
			$scope.disposalPendencies = angular.copy($scope.destinationModel.Pendencies);
			checkIfHasPendencies();
		}

		initializePopup();
	}
]);
