angular.module("vgresiduos").controller("DisposalNotificationPopupCtrl", [
	"dialogService",
	"$scope",
	"$sce",
	"httpService",
	"disposalService",
	function (dialogService, $scope, $sce, httpService, disposalService) {
		"use strict";

		$scope.cancel = function () {
			dialogService.hide($scope.dialog);
		};

		$scope.confirm = function () {
			sendNotification();
		};

		function sendNotification() {
			$scope.sendingEmail = true;

			var dto = {
				model: {
					Notification: $scope.notificationToPopup.Notification
				},
				disposalId: $scope.destinationModel.Id
			};

			httpService.postDTOToServiceV2(disposalService.sendDisposalNotification, dto).then(
				function (data) {
					$scope.sendingEmail = false;
					dialogService.hide($scope.dialog);
				},
				function (data) {
					$scope.sendingEmail = false;
				}
			);
		}

		function initializePopup() {
			$scope.htmlObject = $sce.trustAsHtml($scope.notificationToPopup.Template);
		}

		initializePopup();
	}
]);
