angular.module("vgresiduos").controller("createVehiclePopupCtrl", [
	"$scope",
	"$rootScope",
	"dialogService",
	"supplierCode",
	"supplierId",
	function ($scope, $rootScope, dialogService, supplierCode, supplierId) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.supplierCode = supplierCode;
		$scope.queries = [
			{ label: "supplierId", value: supplierId },
			{ label: "popupForm", value: true }
		];

		$scope.$on(Vgr.constants.evtIframeOnSuccessCreateVehicle, function (event, data) {
			dialogService.confirm(data);
		});

		$scope.$on(Vgr.constants.evtIframeOnCancelCreateVehicle, function (event, data) {
			dialogService.hide($scope.dialog);
		});
	}
]);
