angular.module("vgresiduos").controller("CustomReportFilterDateRangeSectionViewCtrl", [
	"$rootScope",
	"$scope",
	"reportUtilService",
	function ($rootScope, $scope, reportUtilService) {
		"use strict";

		this.$onInit = function () {
			const setter = $scope.$ctrl.setter;

			$scope.labels = $rootScope.labels;
			$scope.dateRange =
				Vgr.util.dateToString(Vgr.util.getDateFromString(setter.beginDate)) +
				" - " +
				Vgr.util.dateToString(Vgr.util.getDateFromString(setter.endDate));

			setDateRange(setter.option);
			setDateRangeGroup(setter.grouping);
		};

		function setDateRange(option) {
			const intervalList = reportUtilService.listCustomReportsDateRangeIntervals();

			$scope.dateRangeOption = intervalList.find((value) => value.Id == option).Description;
		}

		function setDateRangeGroup(group) {
			const groupings = reportUtilService.listCustomReportsDateRangeGroupings();

			$scope.dateRangeGrouping = groupings.find((value) => value.id == group).description;
		}
	}
]);
