angular.module("vgresiduos").controller("CustomReportFilterPreferenceDisposalPriceSectionViewCtrl", [
	"$rootScope",
	"$scope",
	"reportUtilService",
	function ($rootScope, $scope, reportUtilService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			loadCostsLabel($scope.$ctrl.setter);
		};

		function loadCostsLabel(setter) {
			const labelArray = [];

			if (setter) {
				if (setter.useResidue) {
					labelArray.push(
						reportUtilService.getCustomReportsDisposalPricePreference(Vgr.enumerations.reportFilters.costsEnum.Residue)
							.description
					);
				}
				if (setter.useShipping) {
					labelArray.push(
						reportUtilService.getCustomReportsDisposalPricePreference(Vgr.enumerations.reportFilters.costsEnum.Shipping)
							.description
					);
				}
				if (setter.useOthers) {
					labelArray.push(
						reportUtilService.getCustomReportsDisposalPricePreference(Vgr.enumerations.reportFilters.costsEnum.Other)
							.description
					);
				}
			}

			if (labelArray.length == reportUtilService.listCustomReportsDisposalPricePreferences().length) {
				$scope.disposalLabel = $scope.labels.ALL_COSTS_SELECTED;
			} else {
				$scope.disposalLabel = $scope.labels.SOME_COSTS_SELECTED.replace("[VALORES]", labelArray.join(", "));
			}
		}
	}
]);
