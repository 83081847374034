angular.module("vgresiduos").directive("statesSelectField", function () {
	return {
		scope: true,
		controller: "StatesSelectFieldCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/fields/statesSelectField/statesSelectField_template.html",
		bindToController: {
			stateId: "=?",
			label: "@?",
			isDisabled: "@?",
			allStatesField: "@?"
		}
	};
});
