angular.module("vgresiduos").directive("contentNavBar", [
	"$rootScope",
	function ($rootScope) {
		"use strict";
		return {
			scope: true,
			controller: "ContentNavBarCtrl",
			controllerAs: "ctrl",
			templateUrl: "views/components/contentNavBar/contentNavBar_template.html",
			bindToController: {
				options: "=",
				contentNavBarOptions: "=?",
				contentNavBarGrid: "=?"
			},
			link: function (scope, element, attr) {
				element.on("$destroy", function () {
					$rootScope.$broadcast(Vgr.constants.evtDestroyGrid);
				});
			}
		};
	}
]);
