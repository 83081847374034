angular.module("vgresiduos").controller("ProcessStepEditCtrl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `unidades/etapa-processo/${$stateParams.id}`);
		};
	}
]);
