angular.module("vgresiduos").directive("userGrid", function () {
	return {
		scope: true,
		controller: "userGridCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/onboarding/areas/userGrid/userGrid_template.html",
		bindToController: {
			gridOptions: "=?"
		}
	};
});
