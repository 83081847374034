angular.module("vgresiduos").directive("ibamaRappCreateGeneralData", function () {
	return {
		scope: true,
		controller: "IbamaRappCreateGeneralDataCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/ibama/ibamaRappCreate/stages/ibamaRappCreateGeneralData_template.html",
		bindToController: {
			model: "=",
			onClientSelect: "="
		}
	};
});
