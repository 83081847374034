angular.module("vgresiduos").controller("PricingConsultCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"pricingService",
	"dialogService",
	function ($scope, $rootScope, httpService, pricingService, dialogService) {
		$rootScope.contentNavBarGrid = null;
		$scope.labels = $rootScope.labels;
		$scope.pricingConsultModel = {
			pricingGrid: {
				list: []
			}
		};
		$rootScope.contentNavBar = {
			title: $scope.labels.PRICING_CONSULT,
			removeButtonBar: true
		};

		this.$onInit = function () {
			$scope.isLoading = true;
			setInitParams();
			listPricingTypes();
		};

		function setInitParams() {
			$scope.referenceYear = new Date(2019, 1).getFullYear();
			$scope.pricingConsultModel.DisposalTypeAdditionalInfo = true;
		}

		function openAdditionalPricingInformations() {
			var dialog = dialogService
				.showDialogFromTemplateWithScope(
					null,
					"views/pricing/popup/pricingPopup_template.html",
					"PricingPopupCtrl",
					null,
					"#pricing-popup",
					false,
					function () {}
				)
				.then(
					function () {},
					function () {}
				);
			$scope.dialog = dialog;
		}

		function listPricingTypes(residuePricingType) {
			var pricingTypes = pricingService.listPricingTypes(residuePricingType);
			for (var type of pricingTypes) {
				type.IsDisabled = true;
			}

			$scope.PricingTypes = pricingTypes;
		}

		function validate() {
			if (!$scope.pricingConsultModel.ResidueId) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.NO_RESIDUE_SELECTED_ERROR);
				return false;
			}

			return true;
		}

		$scope.consult = function () {
			if (!validate()) {
				return;
			}
			$scope.isLoading = true;
			$scope.hasNoSummarizedPrices = false;
			$scope.pricingConsultModel.treatmentChart = null;
			$scope.pricingConsultModel.salesChart = null;

			buildGridColumns();

			var dto = getConsultFilterDTO();
			httpService.getDTOFromServiceV2(pricingService.getSummarizedPrices, dto).then(
				function (data) {
					$scope.measureUnit = getResidueMeasureUnit(data.SummarizedPrices);
					$scope.pricingType = getResiduePricingType(data.SummarizedPrices);
					buildGridModelFromData(data.SummarizedPrices);
					if ($scope.pricingConsultModel.pricingGrid.list.length > 0) {
						$scope.showSummarizedPrices = true;
						constructChart();
					} else {
						$scope.showSummarizedPrices = false;
						$scope.hasNoSummarizedPrices = true;
					}
					$scope.isLoading = false;
				},
				function () {
					$scope.isLoading = false;
				}
			);
		};

		function getConsultFilterDTO() {
			return {
				residueId: $scope.pricingConsultModel.ResidueId,
				year: $scope.pricingConsultModel.Year,
				pricingType: $scope.pricingConsultModel.PricingType.Id,
				stateId: $scope.pricingConsultModel.StateId,
				groupByDisposalType: $scope.pricingConsultModel.DisposalTypeAdditionalInfo
			};
		}

		function buildGridModelFromData(data) {
			$scope.pricingConsultModel.pricingGrid.list = [];
			for (var pricing of data) {
				insertIntoRow(pricing);
			}
		}

		function buildGridColumns() {
			$scope.pricingConsultModel.pricingGrid.columns = [
				{
					description: "",
					property: "NegotiationType",
					width: 130
				},
				{
					description: getMonthDescription(Vgr.enumerations.months.JANUARY),
					property: "JanuaryPriceFormated",
					width: 50
				},
				{
					description: getMonthDescription(Vgr.enumerations.months.FEBRUARY),
					property: "FebruaryPriceFormated",
					width: 50
				},
				{
					description: getMonthDescription(Vgr.enumerations.months.MARCH),
					property: "MarchPriceFormated",
					width: 50
				},
				{
					description: getMonthDescription(Vgr.enumerations.months.APRIL),
					property: "AprilPriceFormated",
					width: 50
				},
				{
					description: getMonthDescription(Vgr.enumerations.months.MAY),
					property: "MayPriceFormated",
					width: 50
				},
				{
					description: getMonthDescription(Vgr.enumerations.months.JUNE),
					property: "JunePriceFormated",
					width: 50
				},
				{
					description: getMonthDescription(Vgr.enumerations.months.JULY),
					property: "JulyPriceFormated",
					width: 50
				},
				{
					description: getMonthDescription(Vgr.enumerations.months.AUGUST),
					property: "AugustPriceFormated",
					width: 50
				},
				{
					description: getMonthDescription(Vgr.enumerations.months.SEPTEMBER),
					property: "SeptemberPriceFormated",
					width: 50
				},
				{
					description: getMonthDescription(Vgr.enumerations.months.OCTOBER),
					property: "OctoberPriceFormated",
					width: 50
				},
				{
					description: getMonthDescription(Vgr.enumerations.months.NOVEMBER),
					property: "NovemberPriceFormated",
					width: 50
				},
				{
					description: getMonthDescription(Vgr.enumerations.months.DECEMBER),
					property: "DecemberPriceFormated",
					width: 50
				}
			];
			if ($scope.pricingConsultModel.DisposalTypeAdditionalInfo) {
				$scope.pricingConsultModel.pricingGrid.columns.splice(1, 0, {
					description: $scope.labels.DISPOSAL_TECH,
					property: "DisposalType",
					width: 100
				});
			}
		}

		function getMonthDescription(month) {
			var monthString;
			if (month < 10) {
				monthString = "0" + month.toString();
			} else {
				monthString = month.toString();
			}
			return monthString + "/" + $scope.pricingConsultModel.Year.toString().slice(2);
		}

		function insertIntoRow(pricingData) {
			var foundRow = getRow(pricingData);

			if (foundRow == null) {
				foundRow = createGridRowModel(pricingData);
				$scope.pricingConsultModel.pricingGrid.list.push(foundRow);
			}

			setMonthPricing(foundRow, pricingData);
		}

		function getRow(pricingData) {
			if ($scope.pricingConsultModel.DisposalTypeAdditionalInfo) {
				return $scope.pricingConsultModel.pricingGrid.list.find(
					(row) =>
						row.NegotiationTypeId == pricingData.NegotiationType &&
						row.DisposalType == getDisposalTypeDescription(pricingData)
				);
			} else {
				return $scope.pricingConsultModel.pricingGrid.list.find(
					(row) => row.NegotiationTypeId == pricingData.NegotiationType
				);
			}
		}

		function getDisposalTypeDescription(pricingData) {
			return pricingData.DisposalType
				? Vgr.util.capitalizeText(pricingData.DisposalType.Description)
				: $scope.labels.UNKNOWN_TECH;
		}

		function setMonthPricing(row, data) {
			switch (data.Month) {
				case 1:
					row.JanuaryPrice = getPriceValue(data.Price);
					row.JanuaryPriceFormated = getPriceFormated(data.Price);
					break;
				case 2:
					row.FebruaryPrice = getPriceValue(data.Price);
					row.FebruaryPriceFormated = getPriceFormated(data.Price);
					break;
				case 3:
					row.MarchPrice = getPriceValue(data.Price);
					row.MarchPriceFormated = getPriceFormated(data.Price);
					break;
				case 4:
					row.AprilPrice = getPriceValue(data.Price);
					row.AprilPriceFormated = getPriceFormated(data.Price);
					break;
				case 5:
					row.MayPrice = getPriceValue(data.Price);
					row.MayPriceFormated = getPriceFormated(data.Price);
					break;
				case 6:
					row.JunePrice = getPriceValue(data.Price);
					row.JunePriceFormated = getPriceFormated(data.Price);
					break;
				case 7:
					row.JulyPrice = getPriceValue(data.Price);
					row.JulyPriceFormated = getPriceFormated(data.Price);
					break;
				case 8:
					row.AugustPrice = getPriceValue(data.Price);
					row.AugustPriceFormated = getPriceFormated(data.Price);
					break;
				case 9:
					row.SeptemberPrice = getPriceValue(data.Price);
					row.SeptemberPriceFormated = getPriceFormated(data.Price);
					break;
				case 10:
					row.OctoberPrice = getPriceValue(data.Price);
					row.OctoberPriceFormated = getPriceFormated(data.Price);
					break;
				case 11:
					row.NovemberPrice = getPriceValue(data.Price);
					row.NovemberPriceFormated = getPriceFormated(data.Price);
					break;
				case 12:
					row.DecemberPrice = getPriceValue(data.Price);
					row.DecemberPriceFormated = getPriceFormated(data.Price);
					break;
			}
		}

		function getPriceValue(price) {
			if (price == null) {
				$scope.pricingConsultModel.HasNullPrices = true;
				return null;
			}
			return price;
		}

		function getPriceFormated(price) {
			if (price == null) {
				$scope.pricingConsultModel.HasNullPrices = true;
				return "-";
			}
			return Vgr.util.formatUnitDecimalPlaces(price, 2);
		}

		function createGridRowModel(data) {
			var row = angular.copy(blankGridLineModel);
			row.NegotiationTypeId = data.NegotiationType;
			row.NegotiationType = getNegotiationDescription(data);
			row.DisposalType = getDisposalTypeDescription(data);
			return row;
		}

		function getNegotiationDescription(pricingData) {
			if (!pricingData || !pricingData.NegotiationType) {
				return "";
			}
			if (isSale(pricingData.NegotiationType)) {
				return getSaleDescription();
			} else if (isTreatment(pricingData.NegotiationType)) {
				return getTreatmentDescription();
			}
			return "";
		}

		function getResidueMeasureUnit(summarizedPrices) {
			if (summarizedPrices.length > 0) {
				return summarizedPrices[0].MeasureUnit ? summarizedPrices[0].MeasureUnit.Abbreviation : null;
			}

			return null;
		}

		function getResiduePricingType(summarizedPrices) {
			if (summarizedPrices.length > 0) {
				return summarizedPrices[0].PricingType ? summarizedPrices[0].PricingType : null;
			}

			return null;
		}

		function isGroupingByDisposalType() {
			return $scope.pricingConsultModel.DisposalTypeAdditionalInfo;
		}

		function constructChart() {
			var labels = getFullYearMonthsDescriptions();
			var chartType = "line";
			var showYAxisLegend = true;
			var yAxisLegend = isByDisposalPricing() ? "R$" : "R$/" + $scope.measureUnit;
			var showLegend = isGroupingByDisposalType() ? true : false;
			var startAtZero = true;

			var sale = $scope.pricingConsultModel.pricingGrid.list.filter((row) => isSale(row.NegotiationType));
			if (sale.length) {
				let title = $scope.labels.SALES_PRICE;
				if (isGroupingByDisposalType()) {
					title += " / " + $scope.labels.DISPOSAL_TECH;
				}
				let data = getChartData(sale);
				let series = getSeries(sale);
				$scope.pricingConsultModel.salesChart = new Vgr.constructors.Chart(
					title,
					chartType,
					series,
					labels,
					data,
					null,
					showLegend,
					startAtZero,
					showYAxisLegend,
					yAxisLegend,
					null,
					true,
					yAxisFormat
				);
			}

			var treatment = $scope.pricingConsultModel.pricingGrid.list.filter((row) => isTreatment(row.NegotiationType));
			if (treatment.length) {
				let title = $scope.labels.TREATMENT_PRICE;
				if (isGroupingByDisposalType()) {
					title += " / " + $scope.labels.DISPOSAL_TECH;
				}
				let data = getChartData(treatment);
				let series = getSeries(treatment);
				$scope.pricingConsultModel.treatmentChart = new Vgr.constructors.Chart(
					title,
					chartType,
					series,
					labels,
					data,
					null,
					showLegend,
					startAtZero,
					showYAxisLegend,
					yAxisLegend,
					null,
					true,
					yAxisFormat
				);
			}
		}

		function yAxisFormat(value) {
			return Vgr.util.formatUnitDecimalPlaces(value, 2);
		}

		function getFullYearMonthsDescriptions() {
			var allDescriptions = [];
			for (var i = 1; i <= 12; i++) {
				allDescriptions.push(getMonthDescription(i));
			}
			return allDescriptions;
		}

		function getChartData(inputData) {
			let data = [];
			if ($scope.pricingConsultModel.DisposalTypeAdditionalInfo) {
				let filteredByDisposalType = inputData.filter((inpData) => inpData.DisposalType);
				for (let element of filteredByDisposalType) {
					data.push(getOnlyMonthsPricesArrayModel(element));
				}
			} else {
				for (let element of inputData) {
					data.push(getOnlyMonthsPricesArrayModel(element));
				}
			}

			return data;
		}

		function getSeries(inputData) {
			let data = [];
			if ($scope.pricingConsultModel.DisposalTypeAdditionalInfo) {
				for (let element of inputData) {
					data.push(element.DisposalType);
				}
			} else {
				for (let index = 0; index < inputData.length; index++) {
					data.push($scope.labels.PRICE);
				}
			}

			return data;
		}

		function getOnlyMonthsPricesArrayModel(inputData) {
			return [
				inputData.JanuaryPrice,
				inputData.FebruaryPrice,
				inputData.MarchPrice,
				inputData.AprilPrice,
				inputData.MayPrice,
				inputData.JunePrice,
				inputData.JulyPrice,
				inputData.AugustPrice,
				inputData.SeptemberPrice,
				inputData.OctoberPrice,
				inputData.NovemberPrice,
				inputData.DecemberPrice
			];
		}

		function isSale(negotiationType) {
			return (
				negotiationType == Vgr.enumerations.pricing.negotiationType.Sale || negotiationType == getSaleDescription()
			);
		}

		function isTreatment(negotiationType) {
			return (
				negotiationType == Vgr.enumerations.pricing.negotiationType.Treatment ||
				negotiationType == getTreatmentDescription()
			);
		}

		function isByDisposalPricing() {
			return $scope.pricingType == Vgr.enumerations.pricing.pricingType.Disposal;
		}

		function getSaleDescription() {
			return $scope.labels.SALES_PRICE + " (" + $scope.labels.BRL_CURRENCY + formatMeasureUnitDescription();
		}

		function getTreatmentDescription() {
			return $scope.labels.TREATMENT_PRICE + " (" + $scope.labels.BRL_CURRENCY + formatMeasureUnitDescription();
		}

		function formatMeasureUnitDescription() {
			return isByDisposalPricing() ? ")" : "/" + $scope.measureUnit + ")";
		}

		var blankGridLineModel = {
			NegotiationType: null,
			DisposalType: null,
			JanuaryPrice: null,
			FebruaryPrice: null,
			MarchPrice: null,
			AprilPrice: null,
			MayPrice: null,
			JunePrice: null,
			JulyPrice: null,
			AugustPrice: null,
			SeptemberPrice: null,
			OctoberPrice: null,
			NovemberPrice: null,
			DecemberPrice: null
		};

		$scope.pricingResiduesLoaded = function (isEmptyList) {
			$scope.isLoading = false;
			if (isEmptyList) {
				openAdditionalPricingInformations();
			}
		};

		$scope.pricingResidueChanged = function (residue) {
			var allowedPricing = residue.PricingType;
			var residueAllowed = Vgr.enumerations.pricing.residuePricingType.ByResidue;
			var disposaAllowed = Vgr.enumerations.pricing.residuePricingType.ByDisposal;
			var bothAllowed = Vgr.enumerations.pricing.residuePricingType.ByResidueAndDisposal;

			for (var type of $scope.PricingTypes) {
				if (type.Id == Vgr.enumerations.pricing.pricingType.Residue) {
					if (allowedPricing == residueAllowed || allowedPricing == bothAllowed) {
						type.IsDisabled = false;
					} else {
						type.IsDisabled = true;
					}
				} else if (type.Id == Vgr.enumerations.pricing.pricingType.Disposal) {
					if (allowedPricing == disposaAllowed || allowedPricing == bothAllowed) {
						type.IsDisabled = false;
					} else {
						type.IsDisabled = true;
					}
				}
			}

			autoSelectFirstPricingTypeEnabled();
		};

		function autoSelectFirstPricingTypeEnabled() {
			for (var type of $scope.PricingTypes) {
				if (!type.IsDisabled) {
					$scope.pricingConsultModel.PricingType = type;
					break;
				}
			}
		}
	}
]);
