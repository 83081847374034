angular.module("vgresiduos").controller("supplierTextCompleteV2Ctrl", [
	"$rootScope",
	"$scope",
	"httpService",
	"supplierService",
	"$q",
	function ($rootScope, $scope, httpService, supplierService, $q) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			$scope.subfieldList = [
				{
					item: "supplierId",
					label: $scope.labels.ID
				},
				{
					item: "document",
					label: $scope.labels.CNPJ
				},
				{
					item: "cityStateDescription",
					label: $scope.labels.CITY
				}
			];

			$scope.paginationOptions = {
				pageSize: 40,
				pageNumber: 1,
				query: ""
			};

			$scope.listSuppliers = function () {
				const params = {
					skip: $scope.paginationOptions.pageNumber - 1,
					take: $scope.paginationOptions.pageSize,
					query: $scope.paginationOptions.query
				};

				if ($scope.ctrl.allowedSupplierType) {
					if ($scope.ctrl.allowedSupplierType == Vgr.enumerations.supplier.Destinator) {
						params.supplierTypes = [1];
					} else if ($scope.ctrl.allowedSupplierType == Vgr.enumerations.supplier.type.Transporter) {
						params.supplierTypes = [2];
					} else if ($scope.ctrl.allowedSupplierType == Vgr.enumerations.supplier.type.TemporaryStorager) {
						params.supplierTypes = [3];
					}
				}

				const deferred = $q.defer();
				const promise = httpService.getListFromServiceCore(supplierService.listOrganizationUnitSupplier, params);
				promise.then(function (response) {
					formatCityAndDocument(response.list);
					deferred.resolve(response.list);
				});
				return deferred.promise;
			};

			$scope.getCustomClasses = function () {
				return $scope.ctrl.customClasses;
			};

			$scope.getLabel = function () {
				return $scope.ctrl.customLabel ? $scope.ctrl.customLabel : $scope.labels.SUPPLIER;
			};
		};

		function formatCityAndDocument(list) {
			for (const supplier of list) {
				if (!supplier.document) {
					supplier.document = "";
				}

				if (supplier.city) {
					supplier.cityStateDescription = supplier.city.name + " - " + supplier.city.state.abbreviation;
				} else {
					supplier.cityStateDescription = $scope.labels.CITY_NOT_REGISTERED;
				}
			}
		}
	}
]);
