angular.module("vgresiduos").controller("PermissionGroupDescriptionSelectFieldCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"permissionGroupService",
	function ($scope, $rootScope, httpService, permissionGroupService) {
		"use strict";

		this.$onInit = function () {
			$scope.label = $rootScope.labels.PROFILE;
			if ($scope.$ctrl.isRequired) {
				$scope.label += "*";
			}
			listUnitPermissons();
		};

		function listUnitPermissons() {
			httpService.getListFromServiceCore(permissionGroupService.listAllUnitPermissions).then(
				function (response) {
					$scope.list = response.list;
				},
				function () {
					//do nothing
				}
			);
		}
	}
]);
