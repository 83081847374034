angular.module("vgresiduos").factory("permissionService", [
	"$http",
	"$q",
	"localStorageService",
	"localStorageWithTimeoutService",
	function ($http, $q, localStorageService, localStorageWithTimeoutService) {
		"use strict";

		const permissionsCacheExpirationTimeoutInMinutes = 4 * 60;

		const _listSystemPermissions = function (dto) {
			let parameters = "";
			if (dto) {
				parameters = $.param(dto);
			}

			const req = {
				method: "get",
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.systemPermissions + "?" + parameters
			};
			return $http(req);
		};

		function getUserPermissions(identityId) {
			const req = {
				method: "get",
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.users + identityId + "/permissions"
			};

			return $http(req);
		}

		const _setUserPermission = function (userPermission) {
			localStorageWithTimeoutService.setInCacheWithExpiry(
				Vgr.constants.authentication.permissions,
				userPermission,
				permissionsCacheExpirationTimeoutInMinutes,
				Vgr.constants.events.cache.userPermission
			);
		};

		const _loadUserPermissions = function (identityId) {
			const deferred = $q.defer();

			getUserPermissions(identityId).then(
				function (response) {
					_setUserPermission(response.data);
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		};

		function isAllowed(list, systemPermission) {
			const foundPermission = list.find((p) => p.permissionId == systemPermission.Id);

			return foundPermission && foundPermission.allow;
		}

		const _hasPermissionV2 = function (systemPermission, referenceClientId) {
			const userPermissions = getPermissionsConsideringCache();

			if (!userPermissions || !systemPermission) {
				return false;
			}

			if (systemPermission.Type == Vgr.enumerations.systemPermissions.types.Master) {
				return isAllowed(userPermissions.masterPermissions, systemPermission);
			}

			const localStorageClient = localStorageService.get(Vgr.constants.authentication.client);
			if (!localStorageClient) {
				return false;
			}

			const organizationId = localStorageClient.organization.id;
			if (organizationId != Vgr.constants.MasterOrganizationId) {
				if (
					isAllowed(userPermissions.masterPermissions, Vgr.systemPermissions.MasterCreateEditClientData) ||
					(isAllowed(userPermissions.masterPermissions, Vgr.systemPermissions.MasterViewClientData) &&
						systemPermission.IsView)
				) {
					return true;
				}
			}

			const organization = userPermissions.organizations.find((o) => o.organizationId == organizationId);

			if (!organization) {
				return false;
			}

			if (organization.isOwner) {
				return true;
			}

			if (systemPermission.Type == Vgr.enumerations.systemPermissions.types.Organization) {
				return isAllowed(organization.permissions, systemPermission);
			}

			const clientId =
				referenceClientId != null ? referenceClientId : localStorageService.get(Vgr.constants.authentication.client).id;
			const client = organization.organizationUnits.find((c) => c.organizationUnitId == clientId);

			if (!client) {
				return false;
			}

			return isAllowed(client.permissions, systemPermission);
		};

		const _hasAccessToOrganizationUnit = function (organizationUnitId) {
			const userPermissions = getPermissionsConsideringCache();

			if (!userPermissions) {
				return false;
			}

			const localStorageClient = localStorageService.get(Vgr.constants.authentication.client);
			if (!localStorageClient) {
				return false;
			}

			const organizationId = localStorageClient.organization.id;
			if (organizationId != Vgr.constants.MasterOrganizationId) {
				if (
					isAllowed(userPermissions.masterPermissions, Vgr.systemPermissions.MasterCreateEditClientData) ||
					isAllowed(userPermissions.masterPermissions, Vgr.systemPermissions.MasterViewClientData)
				) {
					return true;
				}
			}

			const organization = userPermissions.organizations.find((o) => o.organizationId == organizationId);

			if (!organization) {
				return false;
			}

			if (organization.isOwner) {
				return true;
			}

			return organization.organizationUnits.some((c) => c.organizationUnitId == organizationUnitId);
		};

		const _hasOtherClientWithPermission = function (systemPermission, referenceClientId) {
			const userPermissions = getPermissionsConsideringCache();

			if (!userPermissions || !systemPermission) {
				return false;
			}

			if (systemPermission.Type == Vgr.enumerations.systemPermissions.types.Master) {
				return isAllowed(userPermissions.masterPermissions, systemPermission);
			}

			const organizationId = localStorageService.get(Vgr.constants.authentication.client).organization.id;
			if (
				organizationId != Vgr.constants.MasterOrganizationId &&
				isAllowed(userPermissions.masterPermissions, Vgr.systemPermissions.MasterCreateEditClientData)
			) {
				return true;
			}

			const organization = userPermissions.organizations.find((o) => o.organizationId == organizationId);

			if (!organization) {
				return false;
			}

			if (organization.isOwner) {
				return true;
			}

			const clientId =
				referenceClientId != null ? referenceClientId : localStorageService.get(Vgr.constants.authentication.client).id;
			const clientsWithPermission = organization.organizationUnits.filter(
				(u) =>
					u.organizationUnitId != clientId &&
					u.permissions.some((p) => p.permissionId == systemPermission.Id && p.allow)
			);

			return clientsWithPermission.length > 0;
		};

		const _hasAnyClientWithPermission = function (systemPermission, referenceClientId) {
			return (
				_hasPermissionV2(systemPermission, referenceClientId) ||
				_hasOtherClientWithPermission(systemPermission, referenceClientId)
			);
		};

		const _isMaster = function () {
			const userPermissions = getPermissionsConsideringCache();

			if (!userPermissions) {
				return false;
			}

			return userPermissions.isMaster;
		};

		function getPermissionsConsideringCache() {
			return localStorageWithTimeoutService.getFromCacheWithExpiry(
				Vgr.constants.authentication.permissions,
				Vgr.constants.events.cache.userPermission
			);
		}

		return {
			listSystemPermissions: _listSystemPermissions,

			loadUserPermissions: _loadUserPermissions,
			setUserPermission: _setUserPermission,

			hasPermissionV2: _hasPermissionV2,
			hasAccessToOrganizationUnit: _hasAccessToOrganizationUnit,
			hasOtherClientWithPermission: _hasOtherClientWithPermission,
			hasAnyClientWithPermission: _hasAnyClientWithPermission,
			isMaster: _isMaster
		};
	}
]);
