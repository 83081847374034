angular.module("vgresiduos").component("customizedFilterMultiFilter", {
	controller: "CustomizedFilterMultiFilterCtrl",
	templateUrl:
		"views/components/customizedFilterV2/customizedFilterMultiFilter/customizedFilterMultiFilter_template.html",
	bindings: {
		label: "@?",
		list: "<",
		setter: "<?",
		callback: "<",
		clear: "<"
	}
});
