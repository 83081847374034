angular.module("vgresiduos").controller("ClientPropertySelectFieldCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"additionalPropertyService",
	function ($scope, $rootScope, $q, httpService, additionalPropertyService) {
		"use strict";

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;

			loadClientProperties($scope.$ctrl.processType).then(function () {
				setValue($scope.$ctrl.setter);
			});
		};

		function loadClientProperties(processType) {
			let deferred = $q.defer();

			let dto = {
				process: processType
			};

			httpService.getDTOFromServiceV2(additionalPropertyService.listAdditionalProperties, dto).then(
				function (list) {
					$scope.propertyList = list;
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function setValue(value) {
			if (!value) {
				return;
			}

			let foundProperty = $scope.propertyList.find((item) => item.id === value);

			$scope.model = foundProperty;
			$scope.selectedItem = foundProperty;
			callback();
		}

		this.$onChanges = function (obj) {
			if (obj.processType && !obj.processType.isFirstChange()) {
				loadClientProperties(obj.processType.currentValue).then(function () {
					setValue($scope.$ctrl.setter);
				});
			}
		};

		$scope.onChange = function (selectedItem) {
			$scope.selectedItem = selectedItem;
			callback();
		};

		function callback() {
			if ($scope.$ctrl.callback) {
				$scope.$ctrl.callback($scope.selectedItem);
			}
		}
	}
]);
