angular.module("vgresiduos").controller("TransporterGridCtrl", [
	"$scope",
	"supplierService",
	"$rootScope",
	"$q",
	"$state",
	"$stateParams",
	"httpService",
	"accountService",
	"residueV2Service",
	"locationService",
	"auditService",
	"permissionService",
	"dialogService",
	"localStorageWithTimeoutService",
	function (
		$scope,
		supplierService,
		$rootScope,
		$q,
		$state,
		$stateParams,
		httpService,
		accountService,
		residueV2Service,
		locationService,
		auditService,
		permissionService,
		dialogService,
		localStorageWithTimeoutService
	) {
		"use strict";

		const cacheTimeoutInMinutes = 43200;
		const pendency = $stateParams.pendencia;
		const supplierOrganizationPermission = permissionService.hasPermissionV2(
			Vgr.systemPermissions.SupplierOrganizationMaster
		);
		const supplierEditPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.SupplierEdit);

		$scope.viewMarketPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.ViewMarket);
		const defaultSupplierStatus = Vgr.enumerations.supplier.situation.Regular;
		$scope.currentClient = accountService.getCurrentClient();

		$scope.situationFilterOptions = {
			cityId: null,
			residueId: null,
			situation: defaultSupplierStatus,
			filters: { allSuppliersSwitch: false }
		};

		$scope.suppliersQueryHistory = [];

		$scope.model = {
			suppliers: {
				regularSuppliers: [],
				irregularSuppliers: [],
				inactiveSuppliers: []
			}
		};

		this.$onInit = function () {
			if ($scope.currentClient.city && $scope.currentClient.city.state.countryId) {
				$scope.countryId = $scope.currentClient.city.state.countryId;
			} else {
				$scope.countryId = Vgr.constants.country.Brazil;
			}

			const columns = [
				{
					status: [
						{
							name: "Regular",
							color: "#7ed321 "
						},
						{
							name: "Irregular",
							color: "#d0021b"
						}
					],
					width: 5
				},
				{
					field: "code",
					displayName: function () {
						return $rootScope.labels.commonFields.ID;
					},
					width: 50
				},
				{
					field: "name",
					displayName: function () {
						return $rootScope.labels.NAME;
					},
					width: 250
				},
				{
					field: "CityStateColumn",
					displayName: function () {
						return $rootScope.labels.CITY;
					},
					width: 250
				},
				{
					field: "Profile",
					displayName: function () {
						return $rootScope.labels.PROFILE;
					},
					width: 250
				},
				{
					auditColumn: "Audit.ColumnDescription",
					displayName: function () {
						return $rootScope.labels.CLASSIFICATION;
					},
					getAudit: function (row) {
						return row.Audit;
					},
					iconClass: "align-icon",
					classes: "m-l-30",
					orderField: "Audit.Classification",
					hasAudit: function (row) {
						return row.Audit && row.Audit.Classification;
					},
					icon: {
						iconImageClass: "icon-resize",
						property: "Audit.ClassificationImg",
						condition: function (row) {
							if (row.Audit && row.Audit.Classification) {
								return true;
							}
							return false;
						}
					},
					disableClick: function (row) {
						return !row.Audit || !row.Audit.Classification;
					},
					width: 475
				},
				{
					menuButton: "menuButton",
					width: 25
				}
			];

			const gridOptions = {
				selectAllRegisters: {
					getTextButton: getSelectAllSuppliersTextButton,
					getTitle: getSelectAllSuppliersTitle,
					isSelected: false
				},
				columnDefinitions: columns,
				isLoadingOnBorderColor: false,
				filterFields: ["code", "name", "CityStateColumn"],
				title: $rootScope.labels.SUPPLIER_UNIT_CONTROL,
				query: {
					order: "Name",
					limit: 10,
					page: 1
				},
				data: [],
				list: function () {
					const deferred = $q.defer();
					listOrganizationUnitSuppliers().then(
						function (list) {
							const response = {
								data: {
									success: true,
									content: {
										list: list
									}
								}
							};
							deferred.resolve(response);
						},
						function () {
							deferred.reject();
						}
					);

					return deferred.promise;
				},
				editState: "transporterEdit",
				removeDelete: true,
				removeNew: true,
				enableSelect: hasLinkAndUnlinkPermission(),
				rowClick: function (supplier) {
					$state.go("transporterEdit", { id: supplier.code });
				},
				promise: {},
				hasData: false,
				errorLoading: false,
				isDisabled: function (supplier) {
					return !supplier.active;
				}
			};

			$scope.residueFilterListOptions = {
				pageSize: 30,
				pageNumber: 1,
				query: "",
				id: 0
			};

			$scope.citiesOptions = {
				pageSize: 10,
				pageNumber: 1,
				query: ""
			};

			if (pendency) {
				$scope.disableFilters = true;
				$scope.situationFilterOptions.situation = null;
				$scope.situationFilterOptions.pendency = pendency;
			}

			if (!$scope.ctrl.gridOptions) {
				$scope.ctrl.gridOptions = {};
			}
			Vgr.util.merge($scope.ctrl.gridOptions, gridOptions, true);
			$scope.ctrl.gridOptions.promise = httpService.getListFromService($scope.ctrl.gridOptions.list);
			$scope.ctrl.gridOptions.promise.then(function () {
				//Nothing to be done
			});

			$rootScope.contentNavBarGrid = $scope.ctrl.gridOptions;

			setInformationMessage();
		};

		function deleteSuppliers(toDeleteSuppliers) {
			const dto = {
				supplierCodes: toDeleteSuppliers.map((s) => s.code)
			};
			return httpService.postDTOToServiceV2(supplierService.deleteOrganizationUnitSuppliers, dto);
		}

		$scope.onSelectResidueFilter = function (selected) {
			if (!selected) {
				$scope.situationFilterOptions.residueId = null;
				return;
			}
			$scope.situationFilterOptions.residueId = selected.id;
		};

		$scope.onSelectCityFilter = function (selected) {
			if (!selected) {
				$scope.situationFilterOptions.cityId = null;
				return;
			}
			$scope.situationFilterOptions.cityId = selected.id;
		};

		$scope.listOrganizationUnitResiduesOld = function () {
			if (!$scope.residueFilterListOptions.query) {
				$scope.residueFilterListOptions.query = "";
			}

			const params = {
				active: true,
				nameLike: $scope.residueFilterListOptions.query,
				skip: $scope.residueFilterListOptions.pageNumber - 1,
				take: $scope.residueFilterListOptions.pageSize
			};

			const deferred = $q.defer();
			httpService
				.getListFromServiceCore(residueV2Service.listOrganizationUnitResiduesOld, params)
				.then(function (response) {
					for (const residue of response.list) {
						residue.systemResidueName = residue.masterResidue.name;
					}
					deferred.resolve(response.list);
				});
			return deferred.promise;
		};

		$scope.subfieldList = [
			{
				item: "code",
				label: $scope.labels.ID
			},
			{
				item: "systemResidueName",
				label: $scope.labels.RESIDUE_UNIFIED_BASE
			}
		];

		$scope.changeCountry = function (value) {
			if (value) {
				resetFilters();
				$scope.countryId = value;
			}
		};

		function getParamsToListCity() {
			$scope.citiesOptions.nameLike = $scope.citiesOptions.query;
			$scope.citiesOptions.skip = $scope.citiesOptions.pageNumber - 1;
			$scope.citiesOptions.take = $scope.citiesOptions.pageSize;
			if ($scope.countryId) {
				$scope.citiesOptions.countryId = $scope.countryId;
			}
		}

		$scope.listSupplierFilterCities = function () {
			getParamsToListCity();
			if (!$scope.citiesOptions.query) {
				$scope.citiesOptions.query = "";
			}

			const deferred = $q.defer();
			httpService.getListFromServiceCore(locationService.listCities, $scope.citiesOptions).then(function (response) {
				for (const city of response.list) {
					city.Id = city.id;
					city.Description = Vgr.util.capitalizeText(city.name) + "- " + city.state.abbreviation;
				}
				deferred.resolve(response.list);
			});
			return deferred.promise;
		};

		$scope.onSwitchChange = function (active) {
			if (active) {
				$scope.situationFilterDisabled = true;
				$scope.situationFilterOptions.situation = null;
			} else {
				$scope.situationFilterDisabled = false;
			}
		};

		$scope.onClearFilterClick = function () {
			$scope.situationFilterOptions.filters.allSuppliersSwitch = false;
			resetFilters();
			listOrganizationUnitSuppliers();
		};

		function resetFilters() {
			$scope.onSwitchChange(false);
			$scope.situationFilterOptions.situation = defaultSupplierStatus;
			$scope.situationFilterOptions.cityId = null;
			$scope.situationFilterOptions.residueId = null;
			$scope.model.selectedCity = null;
			$scope.model.selectedCityDescription = null;
			$scope.model.selectedResidue = null;
			$scope.model.selectedResidueDescription = null;
		}

		$scope.onFilterClick = function () {
			$scope.showSpinnerOnFilterClick = true;
			listOrganizationUnitSuppliers().then(function () {
				$scope.showSpinnerOnFilterClick = false;
			});
		};

		$scope.openMarket = function () {
			$state.go("residueMarketNew");
		};

		function createSettingsButton(supplier) {
			supplier.menuButton = {
				icon: "more_vert",
				label: "",
				forceDropdown: true,
				menuItems: [
					{
						usedIcon: "visibility",
						title: $rootScope.labels.VIEW,
						method: function () {
							$state.go("transporterEdit", {
								id: supplier.code
							});
						}
					}
				]
			};
			if (hasSupplierEditPermission(supplier)) {
				supplier.menuButton.menuItems.push({
					usedIcon: "link_off",
					title: $rootScope.labels.UNLINK,
					method: function (suppliers) {
						deleteSuppliers(suppliers).then(
							function () {
								fetchSuppliers(getSupplierFilterDto());
							},
							function () {
								//Nothing to be done
							}
						);
					},
					parameters: [
						{
							code: supplier.code
						}
					]
				});
			}
		}

		function hasLinkAndUnlinkPermission() {
			return supplierOrganizationPermission || supplierEditPermission;
		}

		function hasSupplierEditPermission(supplier) {
			return supplierService.hasSupplierPermission(supplier, null, Vgr.systemPermissions.SupplierEdit, true);
		}

		function createGridRow(supplier) {
			const row = supplier;
			row.ID = supplier.id;
			row.Code = supplier.code;
			row.CityStateColumn = formatCityState(supplier);
			row.Profile = formatSupplierProfile(supplier);
			createSettingsButton(row);
			if (supplier.validAudit) {
				supplier.Audit = {
					Classification: supplier.validAudit.classification,
					Level: supplier.validAudit.level,
					LevelDescription: supplier.validAudit.levelDescription
				};
				supplier.Audit = auditService.loadClientClassification(supplier.Audit);
				supplier.Audit.ColumnDescription =
					supplier.Audit.classification + " - " + $scope.labels.LEVEL + " " + supplier.Audit.level;
			}
			return row;
		}

		function formatSupplierProfile(supplier) {
			const profile = [];
			if (supplier.isDestinator) {
				profile.push($scope.labels.DESTINATOR);
			}
			if (supplier.isTransporter) {
				profile.push($scope.labels.TRANSPORTER);
			}
			if (supplier.isTemporaryStorager) {
				profile.push($scope.labels.TEMPORARY_STORAGER);
			}
			return profile.length > 0 ? profile.join(" / ") : "";
		}

		//Necessary to avoid default link button
		const linkLabel = $scope.labels.LINK + " ";

		function getVisibleNavBarButtons() {
			const navBarButtons = [];
			if (hasLinkAndUnlinkPermission()) {
				navBarButtons.push(linkLabel);
				navBarButtons.push($scope.labels.UNLINK);
				navBarButtons.push($scope.labels.REPLICATE_SUPPLIERS);
			}
			return navBarButtons;
		}

		$rootScope.contentNavBar = {
			breadcrumb: $rootScope.labels.SUPPLIER_UNIT_CONTROL,
			title: $rootScope.labels.SUPPLIER_UNIT_CONTROL,
			customButtons: [
				{
					hasPermission: function () {
						return hasLinkAndUnlinkPermission();
					},
					label: linkLabel,
					icon: "link",
					buttonClasses: "",
					supressToast: true,
					notRaised: true,
					onClick: function () {
						const deferred = $q.defer();

						$state.go("supplierList", { vincular: true });

						return deferred.promise;
					}
				},
				{
					hasPermission: function () {
						return hasLinkAndUnlinkPermission();
					},
					label: $scope.labels.UNLINK,
					icon: "link_off",
					buttonClasses: "",
					supressToast: true,
					disableCustomButton: getCheckboxButtonsDisable,
					notRaised: true,
					onClick: function () {
						const deferred = $q.defer();

						const toDeleteSuppliers = getSelectedSuppliers();

						if (toDeleteSuppliers.length == 0) {
							$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.NO_SUPPLIER_SELECTED);
							deferred.resolve([]);
						} else {
							deleteSuppliers(toDeleteSuppliers).then(
								function (response) {
									fetchSuppliers(getSupplierFilterDto());
									deferred.resolve(response);
								},
								function (error) {
									deferred.reject(error);
								}
							);
						}
						return deferred.promise;
					}
				},
				{
					hasPermission: function () {
						return hasLinkAndUnlinkPermission();
					},
					label: $scope.labels.REPLICATE_SUPPLIERS,
					icon: "file_copy",
					buttonClasses: "",
					supressToast: true,
					disableCustomButton: getCheckboxButtonsDisable,
					notRaised: true,
					onClick: openReplicateSupplierDialog
				}
			],
			onSaveError: function (error) {
				if (error) {
					Cmp.util.showError(error);
				}
			},
			validate: false,
			valid: true,
			buttons: getVisibleNavBarButtons()
		};

		function getSelectedSuppliers() {
			return $scope.ctrl.gridOptions.selectAllRegisters.isSelected
				? $scope.ctrl.gridOptions.data
				: $scope.ctrl.gridOptions.selected;
		}

		function getCheckboxButtonsDisable() {
			const selected = getSelectedSuppliers();
			return !selected || selected.length == 0;
		}

		function listOrganizationUnitSuppliers() {
			return fetchSuppliersOrUseInMemory(getSupplierFilterDto());
		}

		function fetchSuppliersOrUseInMemory(dto) {
			const dtoHash = objectHash(dto);
			const inMemorySuppliers = $scope.suppliersQueryHistory.find(function (supplierQuery) {
				return supplierQuery.dto === dtoHash;
			});
			if (!inMemorySuppliers) {
				return fetchSuppliers(dto);
			}
			const deferred = $q.defer();
			useSuppliersOnQueryHistory(inMemorySuppliers);
			deferred.resolve();
			return deferred.promise;
		}

		function useSuppliersOnQueryHistory(inMemorySuppliers) {
			setSuppliersFromQueryHistory(inMemorySuppliers);
			_changeGridRows();
		}

		function clearSupplierRows() {
			$scope.model.suppliers.regularSuppliers = [];
			$scope.model.suppliers.irregularSuppliers = [];
			$scope.model.suppliers.inactiveSuppliers = [];
			$scope.model.suppliers.allSuppliers = [];
		}

		function fetchSuppliers(dto) {
			const deferred = $q.defer();
			const cacheKey = Vgr.constants.cacheKeysPrefix.suppliersList;
			httpService.getListFromServiceCore(supplierService.listOrganizationUnitSupplier, dto).then(
				function (response) {
					localStorageWithTimeoutService.setInCacheWithExpiry(cacheKey, response.list, cacheTimeoutInMinutes, null);
					const suppliersList = response.list.map(createGridRow);
					clearSupplierRows();
					groupSuppliersByStatus(suppliersList);
					const clonedSuppliers = cloneSuppliers($scope.model.suppliers);
					addSupplierRowToQueryHistory(dto, clonedSuppliers);
					updateSuppliersOnQueryHistory(dto, clonedSuppliers);
					_changeGridRows();
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);
			return deferred.promise;
		}

		function setSuppliersFromQueryHistory(inMemorySuppliers) {
			$scope.model.suppliers = cloneSuppliers(inMemorySuppliers.suppliers);
		}

		function updateSuppliersOnQueryHistory(dto, groupedSuppliers) {
			const suppliersOnMemory = findSuppliersOnQueryHistory(dto);
			if (suppliersOnMemory) {
				suppliersOnMemory.suppliers = groupedSuppliers;
			}
		}

		function addSupplierRowToQueryHistory(dto, groupedSuppliers) {
			const isQueryAlreadyOnHistory = findSuppliersOnQueryHistory(dto);
			if (!isQueryAlreadyOnHistory) {
				$scope.suppliersQueryHistory.push({
					dto: objectHash(dto),
					suppliers: groupedSuppliers
				});
			}
		}

		function findSuppliersOnQueryHistory(dto) {
			const dtoHash = objectHash(dto);
			return $scope.suppliersQueryHistory.find(function (query) {
				return query.dto === dtoHash;
			});
		}

		function cloneSuppliers(suppliers) {
			return {
				regularSuppliers: cloneSupplierRows(suppliers.regularSuppliers),
				irregularSuppliers: cloneSupplierRows(suppliers.irregularSuppliers),
				inactiveSuppliers: cloneSupplierRows(suppliers.inactiveSuppliers),
				allSuppliers: cloneSupplierRows(suppliers.allSuppliers)
			};
		}

		function cloneSupplierRows(suppliers) {
			return suppliers.map(function (supplier) {
				return supplier;
			});
		}

		function _changeGridRows() {
			$scope.ctrl.gridOptions.data = getSelectedSuppliersByStatus();
			$scope.ctrl.gridOptions.selectAllRegisters.isSelected = false;
			$rootScope.$broadcast(Vgr.constants.evtChangeGridFilters);
		}

		function getSelectedSuppliersByStatus() {
			switch ($scope.situationFilterOptions.situation) {
				case Vgr.enumerations.supplier.situation.Inactive:
					return $scope.model.suppliers.inactiveSuppliers;
				case Vgr.enumerations.supplier.situation.Irregular:
					return $scope.model.suppliers.irregularSuppliers;
				case Vgr.enumerations.supplier.situation.Regular:
					return $scope.model.suppliers.regularSuppliers;
				default:
					return $scope.model.suppliers.allSuppliers;
			}
		}

		function groupSuppliersByStatus(suppliers) {
			suppliers.forEach(function (supplier) {
				$scope.model.suppliers.allSuppliers.push(supplier);
				if (!supplier.active) {
					$scope.model.suppliers.inactiveSuppliers.push(supplier);
				} else if (supplier.status == Vgr.enumerations.supplier.status.Irregular) {
					$scope.model.suppliers.irregularSuppliers.push(supplier);
				} else {
					$scope.model.suppliers.regularSuppliers.push(supplier);
				}
			});
		}

		function openReplicateSupplierDialog() {
			const selectedSuppliers = getSelectedSuppliers();
			const codes = selectedSuppliers.map(function (selectedSupplier) {
				return selectedSupplier.code;
			});
			const ids = selectedSuppliers.map(function (selectedSupplier) {
				return selectedSupplier.supplierId;
			});

			if (codes.length == 0 && ids.length == 0) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.NO_SUPPLIER_SELECTED);
				return $q.reject();
			}
			const params = {
				replicateType: Vgr.enumerations.replicateType.Supplier,
				supplierId: null,
				requestModel: {
					codes,
					ids
				}
			};
			return dialogService.showDialogFromTemplateV2(
				"views/components/replicateForClientsTwoStepsPopup/replicateForClientsTwoStepsPopup_template.html",
				"ReplicateForClientsTwoStepsPopupCtrl",
				null,
				params,
				true
			);
		}

		function getSupplierFilterDto() {
			return {
				onlyActives: false,
				cityId: $scope.situationFilterOptions.cityId,
				residueIds: $scope.situationFilterOptions.residueId,
				pendencies: $scope.situationFilterOptions.pendency,
				includeValidAudits: true
			};
		}

		function getEntitiesFilterType() {
			switch ($scope.situationFilterOptions.situation) {
				case Vgr.enumerations.supplier.situation.Inactive:
					return $scope.labels.INATIVE_SUPPLIERS;
				case Vgr.enumerations.supplier.situation.Irregular:
					return $scope.labels.IRREGULAR_SUPPLIERS;
				case Vgr.enumerations.supplier.situation.Regular:
					return $scope.labels.REGULAR_SUPPLIERS;
				default:
					return $scope.labels.SUPPLIERS;
			}
		}

		function getSelectAllSuppliersTitle() {
			let message;
			if ($scope.ctrl.gridOptions.selectAllRegisters.isSelected) {
				message = $rootScope.labels.SELECT_ALL_REGISTER_FROM_REQUEST_TITLE;
				message = message.replace("[ENTITY_COUNT]", $scope.ctrl.gridOptions.data.length);
				message = message.replace("[ENTITY_FILTER]", getEntitiesFilterType().toLowerCase());
			} else {
				message = $rootScope.labels.SELECT_ALL_REGISTER_TITLE;
				message = message.replace("[ENTITY_COUNT]", $scope.ctrl.gridOptions.selected.length);
				message = message.replace("[ENTITY_NAME]", $scope.labels.SUPPLIERS);
			}
			return message;
		}

		function getSelectAllSuppliersTextButton() {
			let message;
			if ($scope.ctrl.gridOptions.selectAllRegisters.isSelected) {
				message = $rootScope.labels.CLEAN_SELECTION;
			} else {
				message = $rootScope.labels.SELECT_ALL_REGISTER_TEXT_BUTTON;
				message = message.replace("[ENTITY_COUNT]", $scope.ctrl.gridOptions.data.length);
				message = message.replace("[ENTITY_FILTER]", getEntitiesFilterType().toLowerCase());
			}
			return message;
		}

		function formatCityState(supplier) {
			if (supplier.city && supplier.city.state) {
				return supplier.city.name + " - " + supplier.city.state.abbreviation;
			}
			return "";
		}

		function setInformationMessage() {
			$scope.alertMessage = {
				status: Vgr.constants.generalStatus.warning,
				title: $scope.labels.WARNING_ACTION_PLANS_MOVED,
				onClick: goToOrganizationSupplierList,
				onClickText: $rootScope.labels.WARNING_ACTION_PLANS_MOVED_CLICK
			};
		}

		function goToOrganizationSupplierList() {
			$state.go("supplierList");
		}

		$scope.changeGridRows = _changeGridRows;
	}
]);
