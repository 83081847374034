angular.module("vgresiduos").directive("clientStockControlPreferences", function () {
	return {
		scope: true,
		controller: "clientStockControlPreferencesCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/client/components/stockControlPreferences/clientStockControlPreferences_template.html",
		bindToController: {
			inputClient: "=",
			saveOptions: "="
		}
	};
});
