angular.module("vgresiduos").controller("AreaFormCtrl", [
	"$scope",
	"additionalPropertyUtilService",
	"userAccessService",
	function ($scope, additionalPropertyUtilService, userAccessService) {
		"use strict";

		$scope.areaProcessId = Vgr.enumerations.additionalProperty.process.Area;
		$scope.isLowerGenerationPlan = userAccessService.hasAccess(
			null,
			Vgr.constants.featureToggles.permission.lowerGenerationPlan
		);

		this.$onInit = function () {
			if (isCreation()) {
				$scope.ctrl.model.active = true;
				$scope.ctrl.model.InformationsFormated = [];
			} else {
				buildInformationsFormated();
			}
		};

		function isCreation() {
			return $scope.ctrl.model.id == null ? true : false;
		}

		$scope.$watch("areaForm.$valid", function (newVal) {
			$scope.ctrl.saveOptions.valid = newVal;
		});

		function buildInformationsFormated() {
			$scope.ctrl.model.InformationsFormated =
				additionalPropertyUtilService.buildInformationsToGridFromApiCoreEntityInformation(
					$scope.ctrl.model.informations
				);
		}

		$scope.getCodeLabel = function () {
			const label = $scope.labels.INTERNAL_ID;
			if (isCreation()) {
				return label;
			}

			return label + "*";
		};
	}
]);
