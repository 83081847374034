angular.module("vgresiduos").controller("DestinationFlowCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"disposalService",
	"accountService",
	function ($scope, $rootScope, $q, httpService, disposalService, accountService) {
		"use strict";

		const IN_TEMPORARY_STORAGE_STAGE = 3;
		const stepsEnum = Vgr.enumerations.destination.steps;
		const stagesEnum = Vgr.enumerations.destination.stages;

		$scope.isCurrentStage = function (stage) {
			return stage.Id == $scope.destinationConfig.currentStage.Id;
		};

		$scope.isStageAvailable = function (stage) {
			return stage.Id <= $scope.destinationConfig.enabledStagePointer;
		};

		$scope.toStage = function (stage) {
			if (!$scope.isStageAvailable(stage)) {
				return;
			}

			if ($scope.isCurrentStage(stage)) {
				return;
			}

			getDisposal(stage);
		};

		const canLoadStagesWatch = $scope.$watch("destinationFlow.canLoadStages", function (newVal) {
			if (newVal) {
				$scope.configStages();
				$scope.ctrl.onStagesLoaded();
			}
		});

		$scope.getStageImage = function (stage) {
			return $scope.isStageAvailable(stage) ? stage.FlowActiveImage : stage.FlowInactiveImage;
		};

		function getDisposal(stage) {
			$scope.loadings.update = true;
			httpService.getDTOFromServiceV2(disposalService.getDisposal, $scope.destinationModel.Id).then(
				function (data) {
					$scope.updateReadOnlyModel(data.Disposal);
					$scope.updateModel(data.Disposal).then(() => {
						$scope.destinationConfig.currentStage = stage;
						$scope.loadStage();
					});
					$scope.loadings.update = false;
				},
				function () {
					$scope.loadings.update = false;
				}
			);
		}

		$scope.configStages = function () {
			configStagesEnum();
			initStages();
		};

		function configStagesEnum() {
			$scope.stagesEnum = {};
			let lastIndex = 0;
			for (const [key, stage] of Object.entries(stagesEnum)) {
				if (IN_TEMPORARY_STORAGE_STAGE == stage && !$scope.isTemporaryStorage()) {
					continue;
				}
				$scope.stagesEnum[key] = lastIndex;
				lastIndex++;
			}
		}

		function initStages() {
			$scope.stagesConfig = [];

			$scope.destinationFlow.solicitationStage = {
				Id: $scope.stagesEnum.Solicitation,
				IsCompleted: false,
				FlowTitle: $scope.labels.DISPOSAL_FLOW_TITLE[stagesEnum.Solicitation],
				FlowActiveImage: "img/destination/stage1_active.png",
				FlowInactiveImage: "img/email_not_confirmed.png",
				SlipTitle: $scope.labels.DISPOSAL_SLIP_TITLE[stagesEnum.Solicitation],
				SlipImage: "img/destination/stage1_grey.png",
				beforeSaveAction: function () {
					const destinatorStep = $scope.isTemporaryStorage() ? stepsEnum.D : stepsEnum.C;
					return $scope.stagesConfig[stagesEnum.Solicitation].Steps[destinatorStep].beforeSaveAction();
				},
				onError: function (error) {
					$scope.$emit(Vgr.constants.evtErrorCreatingDisposal, error);
				},
				Steps: []
			};

			$scope.destinationFlow.gatheringStage = {
				Id: $scope.stagesEnum.Gathering,
				IsCompleted: false,
				FlowTitle: $scope.labels.DISPOSAL_FLOW_TITLE[stagesEnum.Gathering],
				FlowActiveImage: "img/destination/stage2_active.png",
				FlowInactiveImage: "img/destination/stage2_inactive.png",
				SlipTitle: $scope.labels.DISPOSAL_SLIP_TITLE[stagesEnum.Gathering],
				SlipImage: "img/destination/stage2_grey.png",
				load: null,
				Steps: [
					{
						Id: stepsEnum.A,
						Title: $scope.labels.DISPOSAL_GATHERING_STEPS[stepsEnum.A]
					},
					{
						Id: stepsEnum.B,
						Title: $scope.labels.DISPOSAL_GATHERING_STEPS[stepsEnum.B],
						IsDisabled: false
					},
					{
						Id: stepsEnum.C,
						Title: $scope.labels.DISPOSAL_GATHERING_STEPS[stepsEnum.C],
						IsDisabled: false
					},
					{
						Id: stepsEnum.D,
						Title: $scope.labels.DISPOSAL_GATHERING_STEPS[stepsEnum.D],
						IsDisabled: true
					},
					{
						Id: stepsEnum.E,
						Title: $scope.labels.DISPOSAL_GATHERING_STEPS[stepsEnum.E],
						HasMiddleAction: true,
						MiddleActionEnabled: false
					},
					{
						Id: stepsEnum.F,
						Title: $scope.labels.DISPOSAL_GATHERING_STEPS[stepsEnum.F]
					}
				]
			};

			$scope.destinationFlow.trackingStage = {
				Id: $scope.stagesEnum.Tracking,
				IsCompleted: false,
				FlowTitle: $scope.labels.DISPOSAL_FLOW_TITLE[stagesEnum.Tracking],
				FlowActiveImage: "img/destination/stage3_active.png",
				FlowInactiveImage: "img/destination/stage3_inactive.png",
				SlipTitle: $scope.labels.DISPOSAL_SLIP_TITLE[stagesEnum.Tracking],
				SlipImage: "img/destination/stage3_grey.png",
				NoScroll: true,
				update: function () {
					const deferred = $q.defer();

					if ($scope.destinationConfig.enabledStagePointer == $scope.stagesEnum.Tracking) {
						$scope.destinationConfig.enabledStagePointer++;
					}
					$scope.destinationConfig.currentStage.IsCompleted = true;
					$scope.destinationConfig.currentStage = $scope.stagesConfig[stepsEnum.D];

					deferred.resolve();

					return deferred.promise;
				},
				Steps: [
					{
						Id: stepsEnum.A
					}
				]
			};

			$scope.destinationFlow.inTemporaryStorageStage = {
				Id: $scope.stagesEnum.InTemporaryStorage,
				IsCompleted: false,
				FlowTitle: $scope.labels.DISPOSAL_FLOW_TITLE[stagesEnum.InTemporaryStorage],
				FlowActiveImage: "img/destination/stage4_active.png",
				FlowInactiveImage: "img/destination/stage4_inactive.png",
				SlipTitle: $scope.labels.DISPOSAL_SLIP_TITLE[stagesEnum.InTemporaryStorage],
				SlipImage: "img/destination/stage4_grey.png",
				update: function () {
					const dto = getTemporaryStorageReceiveDto();
					const promise = httpService.postDTOToServiceV2(disposalService.temporaryStoragerReceiveDisposal, dto);
					$scope.loadings.update = true;
					promise.then(
						function () {
							$scope.destinationConfig.currentStepPointer = stepsEnum.A;
							$scope.destinationConfig.currentStage.IsCompleted = true;
							$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $rootScope.labels.SAVE_SUCCESS_MESSAGE);
							if (!$scope.isCurrentStageReview()) {
								$scope.toDestinatorArrivalStage();
							} else {
								$scope.loadings.update = false;
							}
						},
						function () {
							$scope.loadings.update = false;
						}
					);
					return promise;
				},
				Steps: [
					{
						Id: stepsEnum.A,
						Title: $scope.labels.DISPOSAL_ARRIVAL_STEPS[stepsEnum.A]
					},
					{
						Id: stepsEnum.B,
						Title: $scope.labels.DISPOSAL_ARRIVAL_STEPS[stepsEnum.B]
					}
				]
			};

			$scope.destinationFlow.arrivalStage = {
				Id: $scope.stagesEnum.Arrival,
				IsCompleted: false,
				FlowTitle: $scope.labels.DISPOSAL_FLOW_TITLE[stagesEnum.Arrival],
				FlowActiveImage: "img/destination/stage4_active.png",
				FlowInactiveImage: "img/destination/stage4_inactive.png",
				SlipTitle: $scope.labels.DISPOSAL_SLIP_TITLE[stagesEnum.Arrival],
				SlipImage: "img/destination/stage4_grey.png",
				Steps: [
					{
						Id: stepsEnum.A,
						Title: $scope.labels.DISPOSAL_ARRIVAL_STEPS[stepsEnum.A]
					},
					{
						Id: stepsEnum.B,
						Title: $scope.labels.DISPOSAL_ARRIVAL_STEPS[stepsEnum.B]
					}
				]
			};

			$scope.destinationFlow.confirmationStageSteps = {
				Id: $scope.stagesEnum.Confirmation,
				IsCompleted: false,
				FlowTitle: $scope.labels.DISPOSAL_FLOW_TITLE[stagesEnum.Confirmation],
				FlowActiveImage: "img/destination/stage5_active.png",
				FlowInactiveImage: "img/destination/stage5_inactive.png",
				SlipTitle: $scope.labels.DISPOSAL_SLIP_TITLE[stagesEnum.Confirmation],
				SlipImage: "img/destination/stage5_grey.png",
				Steps: [
					{
						Id: stepsEnum.A,
						Title: $scope.labels.DISPOSAL_CONFIRMATION_STEPS[stepsEnum.A]
					}
				]
			};

			$scope.stagesConfig = [
				$scope.destinationFlow.solicitationStage,
				$scope.destinationFlow.gatheringStage,
				$scope.destinationFlow.trackingStage
			];

			if ($scope.isTemporaryStorage()) {
				$scope.destinationFlow.arrivalStage.Steps.pop();
				$scope.stagesConfig.push($scope.destinationFlow.inTemporaryStorageStage);
				$scope.stagesConfig.push($scope.destinationFlow.arrivalStage);
				$scope.stagesConfig.push($scope.destinationFlow.confirmationStageSteps);
			} else {
				$scope.stagesConfig.push($scope.destinationFlow.arrivalStage);
				$scope.stagesConfig.push($scope.destinationFlow.confirmationStageSteps);
			}
		}

		$scope.setInitialStage = function () {
			if ($scope.showCurrentStageCompletedStep()) {
				setInitialStageAsCompletedOnCreateDisposalFromModel();
				return;
			}

			switch ($scope.readOnlyModel.Status) {
				case Vgr.enumerations.destination.status.Requested:
					if ($scope.editPermission) {
						$scope.destinationConfig.currentStage = $scope.stagesConfig[$scope.stagesEnum.Gathering];
					} else {
						$scope.destinationConfig.currentStage = $scope.stagesConfig[$scope.stagesEnum.Solicitation];
					}
					break;
				case Vgr.enumerations.destination.status.Gathered:
					$scope.destinationConfig.currentStage = $scope.stagesConfig[$scope.stagesEnum.Tracking];
					break;
				case Vgr.enumerations.destination.status.TemporaryStorageReceived:
					$scope.destinationConfig.currentStage = $scope.stagesConfig[$scope.stagesEnum.Arrival];
					break;
				case Vgr.enumerations.destination.status.Received:
					if ($scope.editPermission) {
						$scope.destinationConfig.currentStage = $scope.stagesConfig[$scope.stagesEnum.Confirmation];
					} else {
						$scope.destinationConfig.currentStage = $scope.stagesConfig[$scope.stagesEnum.Arrival];
					}
					break;
				case Vgr.enumerations.destination.status.Pending:
					$scope.destinationConfig.currentStage = $scope.stagesConfig[$scope.stagesEnum.Confirmation];
					break;
				case Vgr.enumerations.destination.status.Finalized:
					$scope.destinationConfig.currentStage = $scope.stagesConfig[$scope.stagesEnum.Confirmation];
					break;
			}
		};

		function setInitialStageAsCompletedOnCreateDisposalFromModel() {
			if ($scope.readOnlyModel.Status == Vgr.enumerations.destination.status.Requested) {
				$scope.destinationConfig.currentStage = $scope.stagesConfig[$scope.stagesEnum.Solicitation];
			} else if ($scope.readOnlyModel.Status == Vgr.enumerations.destination.status.Gathered) {
				$scope.stagesConfig[$scope.stagesEnum.Solicitation].IsCompleted = true;
				$scope.destinationConfig.currentStage = $scope.stagesConfig[$scope.stagesEnum.Gathering];
				$scope.destinationConfig.enabledStagePointer = $scope.stagesConfig[$scope.stagesEnum.Gathering].Id;
			}
			$scope.destinationConfig.currentStage.IsCompleted = true;
		}

		$scope.configureDisposal = function () {
			if ($scope.showCurrentStageCompletedStep()) {
				$scope.alreadyLoadedReviewAfterCreationFromModel = true;
				return;
			}

			switch ($scope.readOnlyModel.Status) {
				case Vgr.enumerations.destination.status.Requested:
					setRequestedStageConfig();
					break;
				case Vgr.enumerations.destination.status.Gathered:
					setGatheredStageConfig();
					break;
				case Vgr.enumerations.destination.status.TemporaryStorageReceived:
					setTemporaryStorageReceivedStageConfig();
					break;
				case Vgr.enumerations.destination.status.Received:
					setReceivedStageConfig();
					break;
				case Vgr.enumerations.destination.status.Pending:
					setPendingFinalizedStageConfig();
					break;
				case Vgr.enumerations.destination.status.Finalized:
					setPendingFinalizedStageConfig();
					break;
			}

			$scope.destinationConfig.currentStepPointer = stepsEnum.A;
			$scope.destinationConfig.enabledStepPointer = stepsEnum.A;
		};

		function setRequestedStageConfig() {
			$scope.stagesConfig[$scope.stagesEnum.Solicitation].IsCompleted = true;
			if ($scope.editPermission) {
				$scope.destinationConfig.enabledStagePointer = $scope.stagesConfig[$scope.stagesEnum.Gathering].Id;
			} else {
				$scope.destinationConfig.enabledStagePointer = $scope.stagesConfig[$scope.stagesEnum.Solicitation].Id;
			}
		}

		function setGatheredStageConfig() {
			$scope.stagesConfig[$scope.stagesEnum.Solicitation].IsCompleted = true;
			$scope.stagesConfig[$scope.stagesEnum.Gathering].IsCompleted = true;
			$scope.stagesConfig[$scope.stagesEnum.Tracking].IsCompleted = false;
			$scope.destinationConfig.enabledStagePointer = $scope.stagesConfig[$scope.stagesEnum.Tracking].Id;
		}

		function setTemporaryStorageReceivedStageConfig() {
			$scope.stagesConfig[$scope.stagesEnum.Solicitation].IsCompleted = true;
			$scope.stagesConfig[$scope.stagesEnum.Gathering].IsCompleted = true;
			$scope.stagesConfig[$scope.stagesEnum.Tracking].IsCompleted = true;
			$scope.stagesConfig[$scope.stagesEnum.InTemporaryStorage].IsCompleted = true;
			$scope.stagesConfig[$scope.stagesEnum.Arrival].IsCompleted = false;
			$scope.destinationConfig.enabledStagePointer = $scope.stagesConfig[$scope.stagesEnum.Arrival].Id;
		}

		function setReceivedStageConfig() {
			$scope.stagesConfig[$scope.stagesEnum.Solicitation].IsCompleted = true;
			$scope.stagesConfig[$scope.stagesEnum.Gathering].IsCompleted = true;
			$scope.stagesConfig[$scope.stagesEnum.Tracking].IsCompleted = true;
			if ($scope.stagesConfig[$scope.stagesEnum.InTemporaryStorage]) {
				$scope.stagesConfig[$scope.stagesEnum.InTemporaryStorage].IsCompleted = true;
			}
			$scope.stagesConfig[$scope.stagesEnum.Arrival].IsCompleted = true;
			if ($scope.editPermission) {
				$scope.destinationConfig.enabledStagePointer = $scope.stagesConfig[$scope.stagesEnum.Confirmation].Id;
			} else {
				$scope.destinationConfig.enabledStagePointer = $scope.stagesConfig[$scope.stagesEnum.Arrival].Id;
			}
		}

		function setPendingFinalizedStageConfig() {
			$scope.stagesConfig[$scope.stagesEnum.Solicitation].IsCompleted = true;
			$scope.stagesConfig[$scope.stagesEnum.Gathering].IsCompleted = true;
			$scope.stagesConfig[$scope.stagesEnum.Tracking].IsCompleted = true;
			if ($scope.stagesConfig[$scope.stagesEnum.InTemporaryStorage]) {
				$scope.stagesConfig[$scope.stagesEnum.InTemporaryStorage].IsCompleted = true;
			}
			$scope.stagesConfig[$scope.stagesEnum.Arrival].IsCompleted = true;
			$scope.stagesConfig[$scope.stagesEnum.Confirmation].IsCompleted = true;
			$scope.destinationConfig.enabledStagePointer = $scope.stagesConfig[$scope.stagesEnum.Confirmation].Id;
		}

		function getTemporaryStorageReceiveDto() {
			return {
				disposalId: $scope.destinationModel.Id,
				organizationUnitCode: accountService.getCurrentClient().code,
				model: {
					ReceivedDate: Vgr.date.fromDateToUTCString($scope.destinationModel.TemporaryStoragerReceivingDate),
					DispatchDate: Vgr.date.fromDateToUTCString($scope.destinationModel.TemporaryStoragerDispatchDate),
					Residues: $scope.getResiduesDto(),
					Files: $scope.destinationModel.ManifestFiles.concat($scope.destinationModel.ComplementaryMtrFiles),
					PhysicalFiles: $scope.destinationModel.PhysicalFilesManifest
						? [Vgr.enumerations.destination.file.Manifest]
						: []
				}
			};
		}

		$scope.$on("$destroy", function () {
			// disable the listener
			canLoadStagesWatch();

			// nullify the DOM-bound model
			$scope.domElement = null;
		});
	}
]);
