angular.module("vgresiduos").directive("disposalIntegrationsInfoGrid", function () {
	return {
		scope: true,
		controller: "DisposalIntegrationsInfoGridCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/destination/popups/disposalIntegrationsInfoPopup/disposalIntegrationsInfoGrid_template.html",
		bindToController: {
			list: "=",
			integrationInfo: "="
		}
	};
});
