angular.module("componentes").component("informationSmall", {
	controller: function ($scope) {
		this.$onInit = function () {
			var statusVariant = {
				[Vgr.constants.generalStatus.success]: "bg-primary-color-i",
				[Vgr.constants.generalStatus.warning]: "bg-warning-color-i"
			};
			var textColorVariant = {
				[Vgr.constants.generalStatus.white]: "c-white",
				[Vgr.constants.generalStatus.black]: "bg-warning-color-i"
			};

			var statusClass = statusVariant[this.status];
			var textColorClass = textColorVariant[this.textColor];

			if (!statusClass) throw new Error("status invalid");
			if (!textColorClass) textColorClass = textColorVariant[Vgr.constants.generalStatus.black];

			$scope.classes = [statusClass, textColorClass].join(" ");
		};
	},
	templateUrl: "views/components/informationSmall/informationSmall_template.html",
	transclude: true,
	bindings: {
		status: "<",
		textColor: "<"
	}
});
