angular.module("vgresiduos").controller("DateFilterGroupByCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.periodEnum = Vgr.enumerations.reportFilters.periodEnum;

		this.$onInit = function () {
			$scope.groupBy = $scope.ctrl.options || [$scope.periodEnum.TotalInPeriod, $scope.periodEnum.GroupedByMonth];
		};
	}
]);
