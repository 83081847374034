angular.module("vgresiduos").controller("IbamaReportCtrl", [
	"$scope",
	"$rootScope",
	"reportService",
	"httpService",
	function ($scope, $rootScope, reportService, httpService) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.filters = {};

		$scope.pdfExtension = Vgr.enumerations.reportExtension.Pdf;
		$scope.csvExtension = Vgr.enumerations.reportExtension.Csv;

		$rootScope.contentNavBar = {
			title: $scope.labels.RAPP_IBAMA,
			valid: true,
			buttons: []
		};

		$scope.generateReport = function (extension) {
			$scope.isLoading = true;

			const dto = {
				StartDate: $scope.filters.beginDate ? $scope.filters.beginDate : new Date($scope.filters.year, 0, 1),
				EndDate: $scope.filters.endDate ? $scope.filters.endDate : new Date($scope.filters.year, 12, 0),
				ClientIds: $scope.filters.selectedClients ? $scope.filters.selectedClients.map((c) => c.id) : [],
				Extension: extension
			};

			httpService.getDTOFromServiceV2(reportService.generateIbamaReport, dto).then(
				function (data) {
					var fileDTO = Vgr.util.fileDtoToFile(data.File);
					saveAs(fileDTO, fileDTO.FileName);
					$scope.isLoading = false;
				},
				function () {
					$scope.isLoading = false;
				}
			);
		};
	}
]);
