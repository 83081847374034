angular.module("vgresiduos").controller("DestinationSolicitationCtrl", [
	"$scope",
	"$rootScope",
	"$state",
	"userAccessService",
	function ($scope, $rootScope, $state, userAccessService) {
		"use strict";

		const DISPOSAL_SOLICITATION_STEPS = $scope.labels.DISPOSAL_SOLICITATION_STEPS;
		const stepsEnum = $scope.stepsEnum;
		$scope.disposalSolicitationSteps = {
			Responsible: {
				Index: stepsEnum.A,
				Title: DISPOSAL_SOLICITATION_STEPS[stepsEnum.A],
				enableRequirements: function () {
					return $scope.destinationModel.Responsible;
				}
			},
			Residues: {
				Index: stepsEnum.B,
				Title: DISPOSAL_SOLICITATION_STEPS[stepsEnum.B],
				enableRequirements: function () {
					return $scope.destinationModel.Residues && $scope.destinationModel.Residues.length > 0;
				}
			},
			TemporaryStorage: {
				Index: stepsEnum.C,
				Title: DISPOSAL_SOLICITATION_STEPS[stepsEnum.C],
				enableRequirements: function () {
					return $scope.destinationModel.TemporaryStorager;
				}
			},
			Destinator: {
				Index: stepsEnum.D,
				Title: DISPOSAL_SOLICITATION_STEPS[stepsEnum.D],
				HasMiddleAction: true,
				MiddleActionEnabled: false,
				enableRequirements: function () {
					return $scope.destinationModel.Destinator;
				},
				beforeSaveAction: function () {
					return destinatorStepBeforeSaveAction();
				}
			},
			Transporter: {
				Index: stepsEnum.E,
				Title: DISPOSAL_SOLICITATION_STEPS[stepsEnum.E],
				HasMiddleAction: true,
				MiddleActionEnabled: true
			},
			EstimateGathering: {
				Index: stepsEnum.F,
				Title: DISPOSAL_SOLICITATION_STEPS[stepsEnum.F],
				HasMiddleAction: true,
				MiddleActionEnabled: true
			},
			AdditionalInformations: {
				Index: stepsEnum.G,
				Title: DISPOSAL_SOLICITATION_STEPS[stepsEnum.G],
				HasMiddleAction: true,
				MiddleActionEnabled: true
			},
			PendenciesControl: { Index: stepsEnum.H, Title: DISPOSAL_SOLICITATION_STEPS[stepsEnum.H] }
		};

		$scope.solicitationCompletedActions = [
			{
				Title: $scope.labels.DISPOSAL_NOTIFICATIONS[Vgr.enumerations.destination.notification.Destinator],
				Icon: "send",
				Action: function () {
					const notification = $scope.destinationModel.Notifications.find(
						(item) => item.Notification == Vgr.enumerations.destination.notification.Destinator
					);
					return $scope.openDisposalNotificationsPopup(notification);
				},
				Enabled: function () {
					return $scope.destinationModel.Notifications.find(
						(item) => item.Notification == Vgr.enumerations.destination.notification.Destinator
					);
				}
			},
			{
				Title: $scope.labels.DISPOSAL_NOTIFICATIONS[Vgr.enumerations.destination.notification.Transporter],
				Icon: "send",
				Action: function () {
					const notification = $scope.destinationModel.Notifications.find(
						(item) => item.Notification == Vgr.enumerations.destination.notification.Transporter
					);
					return $scope.openDisposalNotificationsPopup(notification);
				},
				Enabled: function () {
					return $scope.destinationModel.Notifications.find(
						(item) => item.Notification == Vgr.enumerations.destination.notification.Transporter
					);
				}
			},
			{
				Title: $scope.labels.COLLECT_AND_EMIT_MTRS,
				Icon: "local_shipping",
				IsRaised: true,
				Action: function () {
					$state.go(
						"destinationEdit",
						{ id: $scope.destinationModel.Id, createdFromDisposalModel: null, forceIntegrations: null },
						{ reload: true }
					);
				},
				Enabled: function () {
					return !$scope.loadings.notifications;
				}
			}
		];

		const stepsErrors = {
			TemporaryStorage: [Vgr.errorCodes.disposal.TemporaryStoragerRequired],
			AdditionalInformations: [Vgr.errorCodes.disposal.InvalidProperty, Vgr.errorCodes.disposal.InvalidPropertyValue]
		};

		const onErrorCreatingDisposal = $rootScope.$on(Vgr.constants.evtErrorCreatingDisposal, function (_event, errors) {
			const errorsCode = errors.map((item) => item.Code);
			for (const step in stepsErrors) {
				const stepErrors = stepsErrors[step];
				const isStepErrors = stepErrors.some((r) => errorsCode.indexOf(r) >= 0);

				if (isStepErrors) {
					const newStepPointer = $scope.destinationConfig.currentStage.Steps.find(
						(item) => item.Index == $scope.disposalSolicitationSteps[step].Index
					).Id;

					if ($scope.destinationConfig.currentStepPointer != newStepPointer) {
						$scope.destinationConfig.currentStepPointer = newStepPointer;
					}
					return;
				}
			}
		});

		$scope.isResponsibleStep = function () {
			return $scope.destinationConfig.currentStepPointer == $scope.stepsEnum.A;
		};

		$scope.isResidueStep = function () {
			return $scope.destinationConfig.currentStepPointer == $scope.stepsEnum.B;
		};

		$scope.isTemporaryStoragerStep = function () {
			if ($scope.isTemporaryStorage()) {
				return $scope.destinationConfig.currentStepPointer == $scope.stepsEnum.C;
			}
			return false;
		};

		$scope.isDestinatorStep = function () {
			const currentStep = getCurrentStep($scope.isTemporaryStorage(), $scope.stepsEnum.C);
			return $scope.destinationConfig.currentStepPointer == currentStep;
		};

		$scope.isTransporterStep = function () {
			const currentStep = getCurrentStep($scope.isTemporaryStorage(), $scope.stepsEnum.D);
			return $scope.destinationConfig.currentStepPointer == currentStep;
		};

		$scope.isEstimateGatheringStep = function () {
			const currentStep = getCurrentStep($scope.isTemporaryStorage(), $scope.stepsEnum.E);
			return $scope.destinationConfig.currentStepPointer == currentStep;
		};

		$scope.isAdditionalInformationStep = function () {
			const currentStep = getCurrentStep($scope.isTemporaryStorage(), $scope.stepsEnum.F);
			return $scope.destinationConfig.currentStepPointer == currentStep;
		};

		$scope.isPendenciesControlStep = function () {
			const currentStep = getCurrentStep($scope.isTemporaryStorage(), $scope.stepsEnum.G);
			return $scope.destinationConfig.currentStepPointer == currentStep;
		};

		$scope.enableStep = function (stepId) {
			if ($scope.isCurrentStageReview()) {
				return;
			}

			if (!isStepEnabled(stepId)) {
				$scope.destinationConfig.enabledStepPointer = stepId;
			}
		};

		$scope.enableBackToStep = function (stepId) {
			if ($scope.isCurrentStageReview()) {
				return;
			}

			$scope.destinationConfig.enabledStepPointer = stepId;
		};

		function isStepEnabled(stepId) {
			return stepId <= $scope.destinationConfig.enabledStepPointer;
		}

		function getCurrentStep(hasPreviousUsingCurrentStep, current) {
			if (hasPreviousUsingCurrentStep) {
				current++;
			}
			return current;
		}

		const configStepsWatch = $scope.$watch("destinationStages.configSteps", function (newVal) {
			if (newVal) {
				setSolicitationSteps();
			}
		});

		function setSolicitationSteps() {
			const solicitationStage = $scope.stagesConfig[$scope.stagesEnum.Solicitation];
			solicitationStage.Steps = getSolicitationSteps();
		}

		function getSolicitationSteps() {
			const ITEM_TEMPORARY_STORAGE = 2;

			const disposalSolicitationStepsEntries = Object.entries($scope.disposalSolicitationSteps);
			const solicitationSteps = [];
			let lastIndex = 0;

			const setValues = (id, step) => {
				return { ...step, Id: id };
			};
			for (const [, step] of disposalSolicitationStepsEntries) {
				if (ITEM_TEMPORARY_STORAGE == step.Index && !$scope.isTemporaryStorage()) {
					continue;
				}
				solicitationSteps.push(setValues(lastIndex, step));
				lastIndex++;
			}

			return solicitationSteps;
		}

		function destinatorStepBeforeSaveAction() {
			const destinatorStep = $scope.isTemporaryStorage() ? stepsEnum.D : stepsEnum.C;
			if ($scope.destinationConfig.currentStepPointer >= destinatorStep) {
				return true;
			}

			const destinatorAlertMessageEnum = Vgr.enumerations.destination.alertMessages.destinatorPendenciesAlert;
			const destinatorAlertMessage = $scope.alertMessages[destinatorAlertMessageEnum];
			if (destinatorAlertMessage && destinatorAlertMessage.condition) {
				if (destinatorAlertMessage.condition(false)) {
					$scope.destinationConfig.currentStepPointer = destinatorStep;
					return false;
				}
			}
			return true;
		}

		this.$onInit = function () {
			if ($scope.destinationStages.configSteps) {
				setSolicitationSteps();
			}
		};

		$scope.$on("$destroy", function () {
			// disable the listener
			configStepsWatch();
			onErrorCreatingDisposal();
			// nullify the DOM-bound model
			$scope.domElement = null;
		});
	}
]);
