angular.module("vgresiduos").controller("DisposalReceivingResponsibleCtrl", [
	"$scope",
	"httpService",
	"supplierContactService",
	function ($scope, httpService, supplierContactService) {
		"use strict";

		this.$onInit = function () {
			if ($scope.ctrl.showResponsible) {
				loadDestintorContacts();
			}

			if (!$scope.isCurrentStageReview()) {
				configureCreatingDefaultParams();
			}
		};

		function loadDestintorContacts() {
			const dto = {
				supplierId: $scope.destinationModel.Destinator.Id,
				supplierCode: $scope.destinationModel.Destinator.Code
			};
			httpService.getListFromServiceCore(supplierContactService.listContacts, dto).then(function (response) {
				$scope.destinatorContacts = response.list.filter((c) => c.active);
				if ($scope.isCurrentStageReview()) {
					$scope.ctrl.responsible = getDestinatorResponsible($scope.ctrl.responsible);
				}
			});
		}

		function configureCreatingDefaultParams() {
			if (!$scope.ctrl.receivingDate) {
				$scope.ctrl.receivingDate = Vgr.util.getDate();
			}
		}

		function getDestinatorResponsible(responsible) {
			if (!responsible || !responsible.id) {
				return null;
			}

			const foundReponsible = $scope.destinatorContacts.find((r) => r.id == responsible.id);

			if (foundReponsible == null) {
				$scope.destinatorContacts.push(responsible);
			}

			return foundReponsible;
		}

		$scope.$watch("ctrl.receivingDate", function (newValue, oldValue) {
			if (newValue && newValue != oldValue) {
				$scope.validateDisposal();
			}
		});
	}
]);
