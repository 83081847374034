angular.module("vgresiduos").controller("BaseAccountPageCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		$rootScope.onlyContent = true;
		$scope.labels = $rootScope.labels;
	}
]);
