angular.module("vgresiduos").directive("destinationFollowupAccordion", function () {
	return {
		scope: true,
		controller: "DestinationFollowupAccordionCtrl",
		controllerAs: "ctrl",
		templateUrl:
			"views/destinationFollowup/baseComponents/destinationFollowupAccordion/destinationFollowupAccordion_template.html",
		bindToController: {
			newOptions: "="
		}
	};
});
