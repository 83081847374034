angular.module("vgresiduos").controller("ResidueFilterPopupCtrl", [
	"$scope",
	"httpService",
	"dialogService",
	"residueService",
	"residueV2Service",
	function ($scope, httpService, dialogService, residueService, residueV2Service) {
		"use strict";

		const minGroupsShowed = 11;
		const maxGroupsShowed = 100;
		$scope.radioModel = {};
		$scope.residueFilterList = [];
		$scope.residueFilterOptions = {
			pageSize: 20,
			pageNumber: 1,
			query: "",
			groups: []
		};

		$scope.listSize = minGroupsShowed;
		$scope.showMoreLabel = $scope.labels.SHOW_MORE;

		$scope.groupSelected = function (residueGroup) {
			residueGroup.isActive = !residueGroup.isActive;
			resetResidueList();
			builResidueGroupFilter();
			listResidues();
		};

		$scope.residueInputChanged = function () {
			if (!$scope.residueGroups || $scope.loadingResidues) {
				return;
			}
			resetResidueList();
			listResidues();
		};

		$scope.onScrollEnd = function () {
			$scope.residueFilterOptions.pageNumber = $scope.residueFilterOptions.pageNumber + 1;
			listResidues();
		};

		$scope.showMore = function () {
			if ($scope.listSize == minGroupsShowed) {
				$scope.listSize = maxGroupsShowed;
				$scope.showMoreLabel = $scope.labels.SHOW_LESS;
				$scope.toggled = true;
			} else {
				$scope.listSize = minGroupsShowed;
				$scope.showMoreLabel = $scope.labels.SHOW_MORE;
				$scope.toggled = false;
			}
		};

		$scope.cancel = function () {
			dialogService.hide($scope.dialog);
		};

		$scope.choose = function () {
			if ($scope.radioModel.residue) {
				$scope.selectedResidueName = $scope.radioModel.residue.name;
				$scope.radioModel.residue.Id = $scope.radioModel.residue.id;
				$scope.radioModel.residue.Name = $scope.radioModel.residue.name;
				$scope.residueSelected($scope.radioModel.residue);
			}
			dialogService.hide($scope.dialog);
		};

		function initParams() {
			$scope.residueInput = null;
		}

		function listResidueGroups() {
			$scope.loadingGroups = true;
			httpService.getListFromServiceCore(residueService.listResidueGroups, null).then(
				function (response) {
					$scope.residueGroups = response.list;
					$scope.loadingGroups = false;
				},
				function () {
					$scope.loadingGroups = false;
				}
			);
		}

		function listResidues() {
			if (listedAllResidues()) {
				return;
			}
			$scope.loadingResidues = true;
			listResiduesFiltered().then(
				function (response) {
					$scope.residueFilterCount = response.count;
					$scope.residueFilterList = $scope.residueFilterList.concat(response.list);
					$scope.noResidueFound = $scope.residueFilterList.length == 0;
					$scope.loadingResidues = false;
				},
				function () {
					$scope.loadingResidues = false;
				}
			);
		}

		function listResiduesFiltered() {
			return httpService.getListFromServiceCore(residueV2Service.listSystemResidues, {
				take: 20,
				skip: $scope.residueFilterOptions.pageNumber - 1,
				residueName: $scope.residueFilterOptions.query,
				groups: $scope.residueFilterOptions.groups
			});
		}

		function resetResidueList() {
			$scope.residueFilterCount = null;
			$scope.residueFilterOptions.pageNumber = 1;
			$scope.residueFilterOptions.query = $scope.residueInput ? $scope.residueInput : "";
			$scope.residueFilterList = [];
		}

		function builResidueGroupFilter() {
			$scope.residueFilterOptions.groups = [];
			for (const group of $scope.residueGroups) {
				if (group.isActive) {
					$scope.residueFilterOptions.groups.push(group.id);
				}
			}
		}

		function listedAllResidues() {
			if (!$scope.residueFilterCount) {
				return false;
			}
			return $scope.residueFilterList.length >= $scope.residueFilterCount;
		}

		function initializePopup() {
			initParams();
			listResidueGroups();
		}

		initializePopup();
	}
]);
