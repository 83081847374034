angular.module("vgresiduos").controller("CustomReportCreateIdentificationCtrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;

			$scope.onChangeReportType = function (val) {
				$scope.$ctrl.model.ReportType = val;
				$rootScope.$broadcast(Vgr.constants.evtRecalculateDisable);
			};

			$scope.onChangeReportName = function () {
				$rootScope.$broadcast(Vgr.constants.evtRecalculateDisable);
			};

			$scope.reportTypes = [
				{
					description: $scope.labels.KEY_PERFORMANCE_INDICATORS,
					id: "Kpi"
				}
			];

			if ($scope.reportTypes.length == 1) {
				$scope.$ctrl.model.ReportType = $scope.reportTypes[0];
			}
			$scope.loadingFinished = true;
		};
	}
]);
