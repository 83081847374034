angular.module("vgresiduos").controller("checkBoxCtrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.onChange = function (value) {
			if ($scope.ctrl.onChange) {
				$scope.ctrl.onChange(value);
			}
		};
	}
]);
