angular.module("vgresiduos").component("externalSystemSelectField", {
	controller: "ExternalSystemSelectFieldCtrl",
	templateUrl: "views/components/fields/externalSystemSelectField/externalSystemSelectField_template.html",
	bindings: {
		isDisabled: "<",
		setterId: "<?",
		model: "=",
		onChange: "<?"
	}
});
