angular.module("vgresiduos").controller("DestinationConfirmationCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		$scope.getDisposalConfirmationMessage = function () {
			return isPending() ? $scope.labels.DISPOSAL_CONFIRMATION_PENDING : $scope.labels.DISPOSAL_CONFIRMATION_FINISHED;
		};

		$scope.getImageLocation = function () {
			return isPending() ? "img/destination/stage5_completed_withpendencies.png" : "img/check_sign.png";
		};

		function isPending() {
			return $scope.destinationModel.Status != Vgr.enumerations.destination.status.Finalized;
		}

		this.$onInit = function () {};

		$scope.isDocumentsStep = function () {
			return $scope.destinationConfig.currentStepPointer == $scope.stepsEnum.A;
		};

		$scope.enableStep = function (stepId) {
			if ($scope.isCurrentStageReview()) {
				return;
			}

			if (!isStepEnabled(stepId)) {
				$scope.destinationConfig.enabledStepPointer = stepId;
			}
		};

		$scope.enableBackToStep = function (stepId) {
			if ($scope.isCurrentStageReview()) {
				return;
			}
			$scope.destinationConfig.enabledStepPointer = stepId;
		};

		function isStepEnabled(stepId) {
			return stepId <= $scope.destinationConfig.enabledStepPointer;
		}
	}
]);
