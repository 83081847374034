angular.module("vgresiduos").controller("LoginWithTokenRedirectCtrl", [
	"$scope",
	"$rootScope",
	"accountService",
	"$state",
	function ($scope, $rootScope, accountService, $state) {
		"use strict";

		$scope.labels = $rootScope.labels;

		loginWithToken();

		function loginWithToken() {
			accountService.loginWithToken().then(
				function (loginWithTokenResponse) {
					if (loginWithTokenResponse.account && loginWithTokenResponse.account.needsToCompleteSignup) {
						$state.go("completeSignup");
					} else if (!loginWithTokenResponse.lastClientUnitId) {
						$scope.authenticationError = true;
						$scope.errorReasonMessage = $scope.labels["ERR-AUT-0011"];
					} else {
						changeClient(loginWithTokenResponse.lastClientUnitId);
					}
				},
				function (response) {
					onAuthenticationError(response);
				}
			);
		}

		function changeClient(clientUnitId) {
			const isAppLogin = accountService.getAppLogin();

			if (isAppLogin) {
				const tokens = accountService.getCachedVgrToken();
				const idp = accountService.getPreviousLoggedSsoIdp();
				const message = { type: "login", payload: { tokens, idp } };

				window.ReactNativeWebView.postMessage(JSON.stringify(message));
			} else {
				accountService.changeClient(clientUnitId, true).then(
					function () {
						accountService.goHome();
					},
					function (response) {
						onAuthenticationError(response);
					}
				);
			}
		}

		function onAuthenticationError(response) {
			$scope.authenticationError = true;
			if (response) {
				if (response.status == 404) {
					$scope.errorReasonMessage = $scope.labels["ERR-AUT-0008"];
				} else if (response.errorCode) {
					$scope.errorReasonMessage = $scope.labels[response.errorCode];
				} else {
					$scope.errorReasonMessage = $scope.labels["SERVER_ERROR"];
				}
			}
		}

		$scope.goBack = function () {
			accountService.logout();
			accountService.goToLogin();
		};
	}
]);
