angular.module("vgresiduos").controller("ListSupplierByRegionCtrl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `mercado/back-office/fornecedores-por-regiao`);
		};
	}
]);
