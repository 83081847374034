angular.module("vgresiduos").controller("RecipientEditCtrl", [
	"$rootScope",
	"$scope",
	"$stateParams",
	"$q",
	"$state",
	"recipientService",
	"httpService",
	"fileService",
	"accountService",
	"permissionService",
	"conama313Service",
	"residueService",
	function (
		$rootScope,
		$scope,
		$stateParams,
		$q,
		$state,
		recipientService,
		httpService,
		fileService,
		accountService,
		permissionService,
		conama313Service,
		residueService
	) {
		"use strict";

		const createEditDeletePermission = permissionService.hasPermissionV2(
			Vgr.systemPermissions.CreateEditDeleteResidueRecipient
		);

		const isNewRecipient = !$stateParams.id || isNaN($stateParams.id);
		const loggedClient = accountService.getCurrentClient();
		const filesDeferred = $q.defer();

		$scope.createEditDeletePermission = createEditDeletePermission;
		$scope.recipientId = $stateParams.id;

		$scope.saveOptions = {
			hasPermission: function () {
				return createEditDeletePermission;
			},
			valid: true,
			returnState: "recipientList",
			save: function () {
				return saveOrUpdateRecipient();
			},
			onSaveError: function () {
				//Nothing to be done
			},
			fileOptions: {
				fileService: "mixed",
				get: function () {
					if (isNewRecipient) {
						filesDeferred.resolve([]);
					}
					return filesDeferred.promise;
				},
				update: function (newFiles, deletedIds) {
					const deferred = $q.defer();
					const pathId = $scope.recipient.Id ? $scope.recipient.Id : 0;
					const path = "Clients/" + loggedClient.id + "/Recipients/" + pathId;
					fileService.uploadAll(newFiles, path).then(
						function (response) {
							deferred.resolve(response);
						},
						function () {
							deferred.reject();
						}
					);
					return deferred.promise;
				},
				fileList: []
			}
		};

		this.$onInit = function () {
			$rootScope.contentNavBar = $scope.saveOptions;

			if (isNewRecipient) {
				$scope.recipient = { hasVolume: false };
			} else {
				loadRecipient();
			}

			$rootScope.contentNavBar.title = $rootScope.labels.PACKAGING;
			$rootScope.contentNavBarGrid = false;
		};

		function loadRecipient() {
			const dto = { Id: $scope.recipientId };

			httpService.getDTOFromServiceV2(recipientService.getClientRecipientById, dto).then(
				function (response) {
					loadFiles(response.files);
					$scope.recipient = mapCoreRecipientToLegacyObject(response);

					if ($scope.recipient.Conama313Id != null) {
						buildConama313(response.conama313Id);
					}
					$scope.recipient.hasVolume = $scope.recipient.Volume ? true : false;
				},
				function () {
					$state.go("recipientList");
				}
			);
		}

		function saveOrUpdateRecipient() {
			if (!$scope.recipient.hasVolume) {
				$scope.recipient.Volume = null;
				$scope.recipient.VolumeMeasureUnitId = null;
			}

			const service = isNewRecipient
				? recipientService.createClientRecipientCore
				: recipientService.updateClientRecipient;
			const dto = { model: getModel() };

			if (!isNewRecipient) {
				dto.Id = $scope.recipient.Id;
			}

			const deferred = $q.defer();

			httpService.postDTOToServiceV2(service, dto).then(
				function (response) {
					if (isNewRecipient) {
						$state.go("recipientEdit", { id: response });
					}

					deferred.resolve(response);
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function loadFiles(files) {
			const recipientFiles = [];
			if (files) {
				for (let file of files) {
					recipientFiles.push({
						Path: file.path,
						Id: file.id,
						name: file.name
					});
				}
			}

			filesDeferred.resolve(recipientFiles);
		}

		function getFilesModel() {
			const files = [];

			for (const fileOption of $scope.saveOptions.fileOptions.fileList) {
				const file = new Object();
				file.Path = fileOption.Path;
				files.push(file);
			}
			return files;
		}

		function buildConama313(idConama313) {
			httpService
				.getListFromServiceCore(residueService.getConama313List, Vgr.enumerations.conama313.type.Recipient)
				.then(function (resp) {
					const element = resp.list.find((c) => c.id == idConama313);
					if (element) {
						$scope.recipient.Conama313 = {
							...element,
							ID: element.id,
							Code1: element.code,
							Code2: element.code2,
							Description: element.description,
							Label: conama313Service.getLabelField(element, Vgr.enumerations.conama313.type.Recipient)
						};
					}
				});
		}

		function getModel() {
			return {
				MasterRecipientId: $scope.recipient.GeneralRecipientId,
				Observations: $scope.recipient.Observations,
				DescriptionPt: $scope.recipient.DescriptionPt,
				DescriptionEs: $scope.recipient.DescriptionEsp,
				Latitude: $scope.recipient.Latitude,
				Longitude: $scope.recipient.Longitude,
				IsDestinationDefined: $scope.recipient.IsDestinationDefined,
				IsIndustryArea: $scope.recipient.IsIndustryArea,
				Volume: $scope.recipient.Volume,
				VolumeMeasureUnitId: $scope.recipient.VolumeMeasureUnitId,
				Conama313Id: $scope.recipient.Conama313 ? $scope.recipient.Conama313.id : null,
				Files: getFilesModel()
			};
		}

		function mapCoreRecipientToLegacyObject(response) {
			const recipienteMap = new Object();

			recipienteMap.Id = response.id;
			recipienteMap.GeneralRecipientId = response.masterRecipient.id;
			recipienteMap.GeneralRecipientName = response.masterRecipient.description;
			recipienteMap.Observations = response.observations;
			recipienteMap.DescriptionPt = response.descriptionPt;
			recipienteMap.DescriptionEsp = response.descriptionEs;
			recipienteMap.Latitude = response.latitude;
			recipienteMap.Longitude = response.longitude;
			recipienteMap.IsDestinationDefined = response.destinationDefined;
			recipienteMap.IsIndustryArea = response.isIndustryArea;
			recipienteMap.Volume = response.volume;
			recipienteMap.VolumeMeasureUnitId = response.volumeMeasureUnitId;
			recipienteMap.Conama313Id = response.conama313Id ? response.conama313Id : null;
			recipienteMap.Files = getFilesModel();

			return recipienteMap;
		}
	}
]);
