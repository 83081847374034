angular.module("vgresiduos").controller("CustomReportFilterDataSectionCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			$scope.label = "";
		};

		$scope.onSelectInformation = function (reportInformation) {
			$scope.selectedReportInformation = reportInformation;

			callback();
		};

		function callback() {
			if ($scope.$ctrl.callback) {
				$scope.$ctrl.callback($scope.selectedReportInformation);
			}
		}

		this.$onChanges = function () {};
	}
]);
