angular.module("vgresiduos").directive("ibamaRappCreateSummaryResidueDestinationGrid", function () {
	return {
		scope: true,
		controller: "IbamaRappCreateSummaryResidueDestinationGridCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/ibama/ibamaRappCreate/grid/ibamaRappCreateSummaryResidueDestinationGrid_template.html",
		bindToController: {
			list: "="
		}
	};
});
