angular.module("vgresiduos").factory("ibamaIntegrationService", [
	"$http",
	"accountService",
	"$q",
	"$timeout",
	function ($http, accountService, $q, $timeout) {
		"use strict";

		var ibamaIntegrationService = {};

		var _listIbamaRapp = function (params) {
			var organizationId = accountService.getCurrentClient().organization.id;

			var parameters = "";
			if (params) {
				parameters = $.param(params);
			}

			var req = {
				method: "get",
				url:
					Vgr.constants.newHostUrl +
					Vgr.resources.apiV2.ibamaIntegrations.replace("[ORGANIZATION]", organizationId) +
					"?" +
					parameters
			};

			return $http(req);
		};

		var _getIbamaRapp = function (id) {
			var organizationId = accountService.getCurrentClient().organization.id;

			var req = {
				method: "get",
				url:
					Vgr.constants.newHostUrl +
					Vgr.resources.apiV2.ibamaIntegrations.replace("[ORGANIZATION]", organizationId) +
					id
			};

			return $http(req);
		};

		var _validateIbamaRapp = function (dto) {
			var organizationId = accountService.getCurrentClient().organization.id;

			var req = {
				method: "post",
				data: dto,
				url:
					Vgr.constants.newHostUrl +
					Vgr.resources.apiV2.ibamaIntegrations.replace("[ORGANIZATION]", organizationId) +
					"validation"
			};

			return $http(req);
		};

		var _getSummaryIbamaRapp = function (dto) {
			var organizationId = accountService.getCurrentClient().organization.id;

			var req = {
				method: "post",
				data: dto,
				url:
					Vgr.constants.newHostUrl +
					Vgr.resources.apiV2.ibamaIntegrations.replace("[ORGANIZATION]", organizationId) +
					"summary"
			};

			return $http(req);
		};

		var _createIbamaRapp = function (dto) {
			var organizationId = accountService.getCurrentClient().organization.id;

			var req = {
				method: "post",
				data: dto,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV2.ibamaIntegrations.replace("[ORGANIZATION]", organizationId)
			};

			return $http(req);
		};

		var _integrateIbamaRapp = function (id) {
			var organizationId = accountService.getCurrentClient().organization.id;

			var req = {
				method: "post",
				url:
					Vgr.constants.newHostUrl +
					Vgr.resources.apiV2.ibamaIntegrations.replace("[ORGANIZATION]", organizationId) +
					id +
					"/confirmation"
			};

			return $http(req);
		};

		var _retryIbamaRappResidue = function (dto) {
			var organizationId = accountService.getCurrentClient().organization.id;

			var req = {
				method: "post",
				url:
					Vgr.constants.newHostUrl +
					Vgr.resources.apiV2.ibamaIntegrations.replace("[ORGANIZATION]", organizationId) +
					dto.ibamaRappId +
					"/residues/" +
					dto.residueId +
					"/retry"
			};

			return $http(req);
		};

		ibamaIntegrationService.listIbamaRapp = _listIbamaRapp;
		ibamaIntegrationService.getIbamaRapp = _getIbamaRapp;
		ibamaIntegrationService.validateIbamaRapp = _validateIbamaRapp;
		ibamaIntegrationService.createIbamaRapp = _createIbamaRapp;
		ibamaIntegrationService.integrateIbamaRapp = _integrateIbamaRapp;
		ibamaIntegrationService.getSummaryIbamaRapp = _getSummaryIbamaRapp;

		ibamaIntegrationService.retryIbamaRappResidue = _retryIbamaRappResidue;

		return ibamaIntegrationService;
	}
]);
