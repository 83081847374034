angular.module("vgresiduos").directive("organizationChart", function () {
	return {
		scope: true,
		controller: "organizationChartCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/newAngular/onboarding/organization/organization-chart/organizationChart_template.html",
		bindToController: {
			inputClient: "=",
			saveOptions: "="
		}
	};
});
