angular.module("vgresiduos").directive("ibamaRappCreateSummaryTransporterGrid", function () {
	return {
		scope: true,
		controller: "IbamaRappCreateSummaryTransporterGridCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/ibama/ibamaRappCreate/grid/ibamaRappCreateSummaryTransporterGrid_template.html",
		bindToController: {
			list: "="
		}
	};
});
