angular.module("vgresiduos").controller("UserListCtrl", [
	"$stateParams",
	"$rootScope",
	function ($stateParams, $rootScope) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, "organizacao/usuarios");
		};
	}
]);
