angular.module("vgresiduos").factory("masterDisposalService", [
	"$http",
	"$q",
	function ($http, $q) {
		"use strict";

		var masterDisposalService = {};

		var _listDisposalIntegrations = function (params) {
			var parameters = params ? $.param(params) : "";

			var req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV2.masterDisposalIntegrations + "?" + parameters
			};
			return $http(req);
		};

		var _getDisposalIntegration = function (id) {
			var req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV2.masterDisposalIntegrations + id
			};
			return $http(req);
		};

		var _createDisposalIntegration = function (dto) {
			var req = {
				method: "post",
				data: dto,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV2.masterDisposalIntegrations + dto.Id + "/manual-integration"
			};
			return $http(req);
		};

		masterDisposalService.listDisposalIntegrations = _listDisposalIntegrations;
		masterDisposalService.getDisposalIntegration = _getDisposalIntegration;
		masterDisposalService.createDisposalIntegration = _createDisposalIntegration;

		return masterDisposalService;
	}
]);
