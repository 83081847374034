angular.module("vgresiduos").controller("CustomReportFilterDateRangeSectionCtrl", [
	"$rootScope",
	"$scope",
	"reportUtilService",
	function ($rootScope, $scope, reportUtilService) {
		"use strict";

		let limitDateToToday;
		let maxDateAllowed;

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;
			$scope.dateRangeGroupingEnum = Vgr.enumerations.customReport.dateRangeGrouping;

			if (!$scope.model) {
				$scope.model = {};
			}

			if ($scope.$ctrl.reportType === Vgr.enumerations.customReport.type.Goal) {
				$scope.type = "month";
			} else {
				$scope.type = "date";
			}

			limitDateToToday = $scope.$ctrl.reportType !== Vgr.enumerations.customReport.type.Goal;

			initSelectValues();

			setValues($scope.$ctrl.setter);
		};

		function initSelectValues() {
			const date = new Date();
			$scope.firstDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1);
			$scope.lastDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

			$scope.firstDayOfPreviousMonth =
				date.getMonth() == 0
					? new Date(date.getFullYear() - 1, 11, 1)
					: new Date(date.getFullYear(), date.getMonth() - 1, 1);
			$scope.lastDayOfPreviousMonth = new Date(date.getFullYear(), date.getMonth(), 0);

			$scope.firstDayOfYear = new Date(date.getFullYear(), 0, 1);
			$scope.lastDayOfYear = new Date(date.getFullYear() + 1, 0, 0);

			$scope.firstDayOfLastYear = new Date(date.getFullYear() - 1, 0, 1);
			$scope.lastDayOfLastYear = new Date(date.getFullYear(), 0, 0);

			$scope.today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
			$scope.firstDayOfLast12Months = new Date(date.getFullYear() - 1, date.getMonth(), 1);

			$scope.intervalList = reportUtilService.listCustomReportsDateRangeIntervals();
		}

		function setValues(value) {
			if (!value) {
				$scope.modelDateSelect = Vgr.enumerations.customReport.dateRangeOption.CurrentYear;
				$scope.model.option = $scope.modelDateSelect;

				$scope.model.grouping = Vgr.enumerations.customReport.dateRangeGrouping.ByMonth;

				setDateRange($scope.modelDateSelect);
			} else {
				$scope.modelDateSelect = value.dateRangeOption;
				$scope.model.option = $scope.modelDateSelect;

				$scope.model.grouping = value.dateRangeGrouping;

				setDateRange($scope.modelDateSelect, value.beginDate, value.endDate);
			}

			defineMinAndMaxDateRanges();

			callback();
		}

		function defineMinAndMaxDateRanges() {
			if ($scope.model.option != Vgr.enumerations.customReport.dateRangeOption.Custom) {
				return;
			}

			if ($scope.model.beginDate) {
				const endMaxDate = new Date(
					$scope.model.beginDate.getFullYear() + 1,
					$scope.model.beginDate.getMonth(),
					$scope.model.beginDate.getDate()
				);

				$scope.minBeginDate = null;
				if (limitDateToToday) {
					$scope.maxBeginDate = Vgr.util.getDateStringYYYYMMDD($scope.today);
				} else {
					$scope.maxBeginDate = null;
				}

				if (limitDateToToday && $scope.model.beginDate.getTime() > $scope.today.getTime()) {
					$scope.minEndDate = Vgr.util.getDateStringYYYYMMDD($scope.today);
				} else {
					$scope.minEndDate = Vgr.util.getDateStringYYYYMMDD($scope.model.beginDate);
				}

				if (limitDateToToday && endMaxDate.getTime() > $scope.today.getTime()) {
					$scope.maxEndDate = Vgr.util.getDateStringYYYYMMDD($scope.today);
					maxDateAllowed = $scope.today;
				} else {
					$scope.maxEndDate = Vgr.util.getDateStringYYYYMMDD(endMaxDate);
					maxDateAllowed = endMaxDate;
				}
			} else {
				if (limitDateToToday) {
					$scope.minBeginDate = null;
					$scope.maxBeginDate = Vgr.util.getDateStringYYYYMMDD($scope.today);
					$scope.minEndDate = null;
					$scope.maxEndDate = Vgr.util.getDateStringYYYYMMDD($scope.today);
				} else {
					$scope.minBeginDate = null;
					$scope.maxBeginDate = null;
					$scope.minEndDate = null;
					$scope.maxEndDate = null;
				}
			}
		}

		function setDateRange(selectedDateRange, beginDate, endDate) {
			if (typeof selectedDateRange === "undefined") {
				$scope.modelDateSelect = Vgr.enumerations.customReport.dateRangeOption.CurrentYear;
				$scope.model.option = $scope.modelDateSelect;
				$scope.model.beginDate = $scope.firstDayOfCurrentMonth;
				$scope.model.endDate = limitDateToToday ? $scope.today : $scope.lastDayOfCurrentMonth;
			} else if (selectedDateRange == Vgr.enumerations.customReport.dateRangeOption.LastMonth) {
				$scope.model.beginDate = $scope.firstDayOfPreviousMonth;
				$scope.model.endDate = $scope.lastDayOfPreviousMonth;
			} else if (selectedDateRange == Vgr.enumerations.customReport.dateRangeOption.CurrentMonth) {
				$scope.model.beginDate = $scope.firstDayOfCurrentMonth;
				$scope.model.endDate = limitDateToToday ? $scope.today : $scope.lastDayOfCurrentMonth;
			} else if (selectedDateRange == Vgr.enumerations.customReport.dateRangeOption.LastYear) {
				$scope.model.beginDate = $scope.firstDayOfLastYear;
				$scope.model.endDate = $scope.lastDayOfLastYear;
			} else if (selectedDateRange == Vgr.enumerations.customReport.dateRangeOption.CurrentYear) {
				$scope.model.beginDate = $scope.firstDayOfYear;
				$scope.model.endDate = limitDateToToday ? $scope.today : $scope.lastDayOfYear;
			} else if (selectedDateRange == Vgr.enumerations.customReport.dateRangeOption.Last12Months) {
				$scope.model.beginDate = $scope.firstDayOfLast12Months;
				$scope.model.endDate = $scope.lastDayOfPreviousMonth;
			} else if (selectedDateRange == Vgr.enumerations.customReport.dateRangeOption.Custom) {
				$scope.model.beginDate = beginDate;
				$scope.model.endDate = endDate;
			}

			$scope.beginDate = $scope.model.beginDate;
			$scope.endDate = $scope.model.endDate;

			$scope.disableDateField = $scope.$ctrl.isDisabled || !isCustomDateRange(selectedDateRange);
		}

		function isCustomDateRange(dateRange) {
			return dateRange == Vgr.enumerations.customReport.dateRangeOption.Custom;
		}

		this.$onChanges = function (obj) {
			if (obj.setter && !obj.setter.isFirstChange()) {
				if (obj.setter.currentValue != null) {
					setValues(obj.setter.currentValue);
				}
			}
		};

		function callback() {
			if ($scope.$ctrl.callback) {
				$scope.$ctrl.callback($scope.model);
			}
		}

		$scope.onDateFilterSelectChange = function (selected) {
			$scope.model.option = selected;
			setDateRange($scope.model.option, $scope.model.beginDate, $scope.model.endDate);
			defineMinAndMaxDateRanges();
			callback();
		};

		$scope.onBeginDateChanged = function (selected) {
			$scope.model.beginDate = selected;

			defineMinAndMaxDateRanges();

			if ($scope.model.endDate && maxDateAllowed && $scope.model.endDate > maxDateAllowed) {
				$scope.model.endDate = null;
				$scope.endDate = $scope.model.endDate;
			}

			if (!$scope.disableDateField) {
				callback();
			}
		};

		$scope.onEndDateChanged = function (selected) {
			$scope.model.endDate = selected;

			defineMinAndMaxDateRanges();

			if (!$scope.disableDateField) {
				callback();
			}
		};

		$scope.onGroupingChanged = function () {
			callback();
		};
	}
]);
