angular.module("vgresiduos").controller("EnabledForClientsPopUpCtrl", [
	"$scope",
	"$rootScope",
	"dialogService",
	"enabledOrganizationUnits",
	"allOrganizationUnits",
	"organizationUnitSelectedListCount",
	function (
		$scope,
		$rootScope,
		dialogService,
		enabledOrganizationUnits,
		allOrganizationUnits,
		organizationUnitSelectedListCount
	) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.checkList = [];
		$scope.selectAll = false;
		$scope.clientSearch = "";

		$scope.changeClientOption = function (clientSearch) {
			$scope.checkList = [];
			filterCheckList(clientSearch.toLowerCase());
		};

		function filterCheckList(clientSearch) {
			const filterField = ["name", "code"];
			if (clientSearch == "") {
				$scope.checkList = allOrganizationUnits;
			} else {
				$scope.checkList = Vgr.filters.filterBy(allOrganizationUnits, clientSearch, filterField);
			}
		}

		const cancelButton = {
			label: $scope.labels.CLOSE,
			class: "md-primary",
			click: function () {
				dialogService.cancel();
			},
			disabled: false
		};

		const confirmButton = {
			label: $scope.labels.SAVE,
			class: "md-primary md-raised",
			click: function () {
				if (validate()) {
					organizationUnitSelectedListCount = $scope.checkList.length;
					dialogService.confirm({
						unselectedItens: getUnselectedItens(),
						organizationUnitSelectedListCount: organizationUnitSelectedListCount
					});
				}
			},
			disabled: false
		};

		function listClients() {
			let selectedItensCount = 0;
			allOrganizationUnits.forEach((ou) => {
				if (enabledOrganizationUnits.find((eou) => eou.id == ou.id)) {
					ou.toEnabled = true;
					selectedItensCount++;
				}
			});
			$scope.checkList = allOrganizationUnits;

			if (selectedItensCount == allOrganizationUnits.length) {
				$scope.selectAll = true;
			}

			$scope.popupActionButtons.push(confirmButton);
			if (!$scope.checkList.length) {
				$scope.hasNoClientsToEnable = true;
			}
		}

		function validate() {
			if ($scope.checkList.filter((cl) => !cl.toEnabled).map((c) => c.id).length == $scope.checkList.length) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.NO_CLIENT_SELECTED);
				return false;
			}
			return true;
		}

		function getUnselectedItens() {
			const selectedItens = $scope.checkList.filter((cl) => cl.toEnabled).map((c) => c.id);
			return allOrganizationUnits.filter((aou) => !selectedItens.find((si) => aou.id == si)).map((aou) => aou.id);
		}

		$scope.selectDeselectAll = function (selectAll) {
			for (const item of $scope.checkList) {
				item.toEnabled = selectAll;
			}
		};

		initializePopUp();

		function initializePopUp() {
			$scope.popupActionButtons = [cancelButton];
			listClients();
		}
	}
]);
