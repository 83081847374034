angular.module("vgresiduos").directive("ibamaRappCreateSummaryResidueGenerationGrid", function () {
	return {
		scope: true,
		controller: "IbamaRappCreateSummaryResidueGenerationGridCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/ibama/ibamaRappCreate/grid/ibamaRappCreateSummaryResidueGenerationGrid_template.html",
		bindToController: {
			list: "="
		}
	};
});
