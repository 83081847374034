angular.module("vgresiduos").controller("CustomizedFilterValueFieldCtrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;

			$scope.optionValues = Vgr.enumerations.customizedFilterV2.optionField.value;
		};

		$scope.isFromType = function (optionValue, isProperty) {
			if (!$scope.$ctrl.option || !$scope.$ctrl.option.item) {
				return false;
			}

			if ($scope.$ctrl.option.additionalProperty && isProperty) {
				return true;
			}

			return $scope.$ctrl.option.item.id == optionValue;
		};
	}
]);
