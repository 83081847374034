angular.module("vgresiduos").controller("featureToggleStatusSelectFieldCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		$scope.labels = $rootScope.labels;

		const featureToggleStatusEnum = ["Disabled", "Enabled", "FeatureToggle"];

		this.$onInit = function () {
			listStatus();
		};

		function listStatus() {
			$scope.featureToggleStatus = featureToggleStatusEnum.map(function (status) {
				return {
					Id: status,
					Description: $scope.labels.FEATURETOGGLE_STATUS_ENUM[status]
				};
			});
			setInitialValue();
		}

		function setInitialValue() {
			if (!$scope.$ctrl.setterId) {
				return;
			}

			var foundFeatureToggleStatus = $scope.featureToggleStatus.find(
				(featureToggleStatus) => featureToggleStatus.Id == $scope.$ctrl.setterId
			);
			if (foundFeatureToggleStatus) {
				$scope.$ctrl.model = foundFeatureToggleStatus;
			}
		}
	}
]);
