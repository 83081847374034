angular.module("vgresiduos").controller("DestinationFollowupAccordionCtrl", [
	"$rootScope",
	"$scope",
	"disposalPreferenceService",
	function ($rootScope, $scope, disposalPreferenceService) {
		"use strict";
		$scope.labels = $rootScope.labels;
		$scope.numberRequested = {};
		$scope.numberGathered = {};
		$scope.numberReceived = {};
		$scope.numberPending = {};
		$scope.numberInTemporaryStorage = {};

		$scope.getStatusTitle = function (status) {
			if (status == Vgr.enumerations.destination.status.Requested) {
				return $scope.labels.REQUESTED.toUpperCase();
			} else if (status == Vgr.enumerations.destination.status.Gathered) {
				return $scope.labels.IN_TRANSIT.toUpperCase();
			} else if (status == Vgr.enumerations.destination.status.TemporaryStorageReceived) {
				return $scope.labels.IN_TEMPORARY_STORAGE.toUpperCase();
			} else if (status == Vgr.enumerations.destination.status.Received) {
				return $scope.labels.RECEIVED_BY_DESTINATOR.toUpperCase();
			} else if (status == Vgr.enumerations.destination.status.Pending) {
				return $scope.labels.PENDING_COMPROVATION.toUpperCase();
			}
			return "";
		};

		this.$onInit = function () {
			if ($scope.disposalPreferences) {
				return;
			}

			disposalPreferenceService.getDisposalPreferences().then(function (disposalPreferences) {
				$scope.disposalPreferences = disposalPreferences;
			});
		};
	}
]);
