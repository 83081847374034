angular.module("vgresiduos").directive("baseAccountPage", function () {
	return {
		scope: {},
		replace: false,
		controller: "BaseAccountPageCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/baseAccountPage/baseAccountPage_template.html",
		transclude: true
	};
});
