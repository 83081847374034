angular.module("vgresiduos").controller("CustomReportEditCtrl", [
	"$scope",
	"$rootScope",
	"$state",
	"$stateParams",
	"$timeout",
	"$q",
	"customReportService",
	"httpService",
	"reportUtilService",
	"accountService",
	"dialogService",
	"userAccessService",
	"engagementScoreService",
	function (
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$timeout,
		$q,
		customReportService,
		httpService,
		reportUtilService,
		accountService,
		dialogService,
		userAccessService,
		engagementScoreService
	) {
		$scope.labels = $rootScope.labels;

		const loggedUser = accountService.getLoggedAccount();
		let reportGenerationAlreadyTracked = false;
		const editMode = "edicao";

		this.$onInit = function () {
			$scope.isEditMode = $stateParams.modo == editMode;

			$scope.filters = {};

			$rootScope.contentNavBar = {
				title: $scope.labels.REPORTS,
				breadcrumb: $stateParams.reportId,
				back: function () {
					$state.go("customReport");
				},
				valid: true,
				save: onClickSaveReportFilters,
				onSaveError: function () {
					//Error handling
				},
				customButtons: [
					{
						icon: "delete",
						label: $scope.labels.DELETE,
						onClick: onClickDeleteReport
					},
					{
						label: $scope.labels.EXPORT_CSV,
						buttonClasses: "md-primary",
						supressToast: true,
						onClick: function () {
							const deferred = $q.defer();
							$timeout(function () {
								angular.element("#hiddenCsvExportBtn").click();
							});
							deferred.resolve();
							return deferred.promise;
						}
					},
					{
						label: $scope.labels.EDIT,
						buttonClasses: "md-primary",
						icon: "edit",
						supressToast: true,
						onClick: onClickEditReport
					}
				],
				buttons: []
			};

			loadReport();
		};

		function loadReport() {
			$scope.isLoading = true;
			$rootScope.contentNavBar.buttons = [];

			httpService.getDTOFromServiceV2(customReportService.getCustomReport, $stateParams.reportId).then(
				function (response) {
					$scope.customReport = response;

					$rootScope.contentNavBar.breadcrumb = $scope.customReport.description;
					$scope.reportName = $scope.customReport.description;

					if ($scope.hasPermissionToEdit()) {
						setMode($scope.isEditMode);
					} else {
						setMode(false);
					}

					if (hasPermissionToDelete()) {
						addButton($scope.labels.DELETE);
					}

					generateReport();
				},
				function () {
					$scope.customReport = null;
					$scope.reportData = null;
					$scope.isLoading = false;
				}
			);
		}

		$scope.hasPermissionToEdit = function () {
			return (
				($scope.customReport && $scope.customReport.creationUser.id === loggedUser.code) ||
				userAccessService.hasAccess(Vgr.systemPermissions.BiModuleReportEdit, null, null)
			);
		};

		function hasPermissionToDelete() {
			return $scope.customReport && $scope.customReport.creationUser.id === loggedUser.code;
		}

		function loadReportFilterSetter() {
			const reportData = $scope.customReport.data;
			const dataSerie = reportData.series[0];

			$scope.filterSetter = {};
			$scope.filterSetter.informationId = dataSerie.information.id;
			$scope.filterSetter.dateRange = {};
			$scope.filterSetter.dateRange.dateRangeOption = reportData.dateRangeFilter.option;
			$scope.filterSetter.dateRange.dateRangeGrouping = reportData.dateRangeFilter.grouping;
			$scope.filterSetter.dateRange.beginDate = Vgr.util.getDateFromString(reportData.dateRangeFilter.beginDate);
			$scope.filterSetter.dateRange.endDate = Vgr.util.getDateFromString(reportData.dateRangeFilter.endDate);

			$scope.filterSetter.filters = [];
			for (const filter of reportData.filters) {
				const filterSetter = {};
				filterSetter.id = filter.systemReportProperty.code;
				if (filter.additionalProperty) {
					filterSetter.additionalPropertyId = filter.additionalProperty.id;
				}

				filterSetter.values = [];
				for (const filterValue of filter.items) {
					filterSetter.values.push(filterValue.value);
				}

				$scope.filterSetter.filters.push(filterSetter);
			}

			$scope.filterSetter.groupBys = [];
			for (const grouping of reportData.groupings) {
				const groupBySetter = {};
				groupBySetter.id = grouping.systemReportProperty.code;
				if (grouping.additionalProperty) {
					groupBySetter.additionalPropertyId = grouping.additionalProperty.id;
				}

				$scope.filterSetter.groupBys.push(groupBySetter);
			}

			$scope.filterSetter.reportPreference = {};
			if (dataSerie.measureUnitPreference) {
				$scope.filterSetter.reportPreference.measureUnit = {};
				$scope.filterSetter.reportPreference.measureUnit.option = dataSerie.measureUnitPreference.option;
				if (dataSerie.measureUnitPreference.measureUnit) {
					$scope.filterSetter.reportPreference.measureUnit.measureUnitId =
						dataSerie.measureUnitPreference.measureUnit.id;
				}
			}
			if (dataSerie.disposalPricePreference) {
				$scope.filterSetter.reportPreference.disposalPrice = {};
				$scope.filterSetter.reportPreference.disposalPrice.residue = dataSerie.disposalPricePreference.useResidue;
				$scope.filterSetter.reportPreference.disposalPrice.shipping = dataSerie.disposalPricePreference.useShipping;
				$scope.filterSetter.reportPreference.disposalPrice.other = dataSerie.disposalPricePreference.useOthers;
			}
		}

		function generateReport() {
			$scope.reportGenerated = false;
			httpService.getDTOFromServiceV2(customReportService.generateReport, $stateParams.reportId).then(
				function (response) {
					$scope.reportData = response;
					trackReportGeneration();

					$rootScope.contentNavBar.buttons.push($scope.labels.EXPORT_CSV);

					$scope.reportGenerated = true;
					$scope.isLoading = false;
				},
				function () {
					$scope.reportData = null;
					$scope.isLoading = false;
				}
			);
		}

		$scope.onChangeFilters = function (filters) {
			$scope.filters = filters;
		};

		$scope.onClickViewData = function () {
			const dto = reportUtilService.buildCustomReportFilterDto($scope.filters);

			$scope.reportGenerated = false;
			$scope.isLoading = true;

			httpService.getDTOFromServiceV2(customReportService.generateGenericKpiReport, dto).then(
				function (response) {
					$scope.reportData = response;
					$scope.reportGenerated = true;
					$scope.isLoading = false;
				},
				function () {
					$scope.reportData = null;
					$scope.isLoading = false;
				}
			);
		};

		function onClickSaveReportFilters() {
			const deferred = $q.defer();

			const dto = {};
			dto.id = $scope.customReport.id;
			dto.model = reportUtilService.buildCustomReportFilterDto($scope.filters);

			$scope.isLoading = true;

			httpService.postDTOToServiceV2(customReportService.updateCustomReportFilters, dto).then(
				function () {
					$scope.isLoading = false;

					deferred.resolve();
				},
				function () {
					$scope.isLoading = false;

					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function onClickDeleteReport() {
			const deferred = $q.defer();

			$scope.isLoading = true;

			httpService.getDTOFromServiceV2(customReportService.deleteReport, $scope.customReport.id).then(
				function () {
					$scope.isLoading = false;

					$state.go("customReport");

					deferred.resolve();
				},
				function () {
					$scope.isLoading = false;

					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function onClickEditReport() {
			const deferred = $q.defer();

			setMode(true);

			deferred.resolve();
			return deferred.promise;
		}

		function setMode(isEdit) {
			if (isEdit) {
				$scope.isEditMode = true;
				showFilters(true);

				loadReportFilterSetter();
				$timeout(function () {
					$scope.filterSetter = null;
				}, 2000);

				removeButton($scope.labels.EDIT);
				addButton($scope.labels.SAVE);
			} else {
				$scope.isEditMode = false;

				removeButton($scope.labels.SAVE);

				if ($scope.hasPermissionToEdit()) {
					addButton($scope.labels.EDIT);
				}
			}
		}

		function removeButton(label) {
			const index = $rootScope.contentNavBar.buttons.indexOf(label);
			if (index != -1) {
				$rootScope.contentNavBar.buttons.splice(index, 1);
			}
		}

		function addButton(label) {
			$rootScope.contentNavBar.buttons.push(label);
		}

		$scope.toggleShowFilters = function () {
			showFilters(!$scope.showFilters);
		};

		function showFilters(isToShow) {
			$scope.showFilters = isToShow;
		}

		$scope.openPopupEditReportName = function () {
			const params = {
				id: $scope.customReport.id,
				description: $scope.reportName
			};

			dialogService
				.showDialogFromTemplateV2(
					"views/customReports/customReportEdit/popup/editReportDescriptionPopup_template.html",
					"EditReportDescriptionPopupCtrl",
					null,
					params
				)
				.then(
					function (reportName) {
						$rootScope.contentNavBar.breadcrumb = reportName;
						$scope.reportName = reportName;
					},
					function () {
						//Error handling
					}
				);
		};

		function trackReportGeneration() {
			if (reportGenerationAlreadyTracked) {
				return;
			}

			reportGenerationAlreadyTracked = true;
			engagementScoreService.track(Vgr.trackings.bi.reports.generateSavedReport);
			const series = $scope.customReport.data.series;
			if (series && series[0] && series[0].information) {
				customReportService.trackReportMetric(series[0].information.id);
			}
		}
	}
]);
