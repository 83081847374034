angular.module("vgresiduos").controller("customReportFilterFiltersPopupCtrl", [
	"$scope",
	"$rootScope",
	"dialogService",
	"filters",
	function ($scope, $rootScope, dialogService, reportFilters) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.popupActionButtons = [
			{
				label: $scope.labels.CLOSE,
				class: "md-primary",
				click: function () {
					dialogService.cancel();
				},
				disabled: false
			}
		];

		init();

		function init() {
			loadFilters();
		}

		function loadFilters() {
			$scope.filters = [];

			for (const reportFilter of reportFilters) {
				const filter = {};

				if (reportFilter.additionalProperty) {
					filter.description = reportFilter.additionalProperty.name;
				} else {
					filter.description = reportFilter.systemReportProperty.description;
				}

				filter.values = reportFilter.items.map(function (item) {
					return item.description;
				});

				$scope.filters.push(filter);
			}
		}
	}
]);
