angular.module("vgresiduos").controller("DisposalHistoryCustomizedFilterCtrl", [
	"$rootScope",
	"$scope",
	"disposalService",
	function ($rootScope, $scope, disposalService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.listResiduesOptions = {
			pageSize: 30,
			pageNumber: 1,
			query: "",
			id: 0
		};

		$scope.listDestinatorOptions = {
			pageSize: 30,
			pageNumber: 1,
			query: "",
			id: 0
		};

		$scope.customizedFilterOptions = {
			isFilterActive: false,
			selectedFilters: [],
			availableFields: [
				{
					type: Vgr.enumerations.customizedFilter.type.SupplierTextCompleteV2,
					label: $scope.labels.DESTINATOR,
					id: "destinator",
					isEnabled: true,
					valueField: "id",
					isOptionEnabled: function () {
						return this.isEnabled;
					}
				},
				{
					type: Vgr.enumerations.customizedFilter.type.AreaSelect,
					label: $scope.labels.AREA,
					id: "area",
					isEnabled: true,
					valueField: "id",
					isOptionEnabled: function () {
						return this.isEnabled;
					}
				},
				{
					type: Vgr.enumerations.customizedFilter.type.Select,
					label: $scope.labels.STATUS,
					displayProperty: "Description",
					id: "status",
					list: disposalService.listDisposalStatus(),
					isEnabled: true,
					isOptionEnabled: function () {
						return this.isEnabled;
					}
				},
				{
					type: Vgr.enumerations.customizedFilter.type.Text,
					label: $scope.labels.MTR,
					id: "mtrCode",
					isEnabled: true,
					isOptionEnabled: function () {
						return this.isEnabled;
					}
				},
				{
					type: Vgr.enumerations.customizedFilter.type.Text,
					label: $scope.labels.INVOICE,
					id: "invoice",
					isEnabled: true,
					isOptionEnabled: function () {
						return this.isEnabled;
					}
				},
				{
					type: Vgr.enumerations.customizedFilter.type.Date,
					label: $scope.labels.REQUISITION_DATE,
					id: "requestedDate",
					isEnabled: true,
					isOptionEnabled: function () {
						return this.isEnabled;
					}
				},
				{
					type: Vgr.enumerations.customizedFilter.type.Date,
					label: $scope.labels.GATHERING_DATE,
					id: "gatheredDate",
					isEnabled: true,
					isOptionEnabled: function () {
						return this.isEnabled;
					}
				},
				{
					type: Vgr.enumerations.customizedFilter.type.Date,
					label: $scope.labels.RECEIVING_DATE_OF_DESTINATOR,
					id: "receivedDate",
					isEnabled: true,
					isOptionEnabled: function () {
						return this.isEnabled;
					}
				},
				{
					type: Vgr.enumerations.customizedFilter.type.Date,
					label: $scope.labels.DISPOSAL_DATE,
					id: "disposalDate",
					isEnabled: true,
					isOptionEnabled: function () {
						return this.isEnabled;
					}
				}
			]
		};

		$scope.onFilter = function () {
			var dto = getDtoFromFilters();
			if (dto) {
				$scope.ctrl.onFilter(dto);
			}
		};

		function getDtoFromFilters() {
			var filters = $scope.customizedFilterOptions.selectedFilters;
			var dto = {};
			for (var i = 0; i < filters.length; ++i) {
				if (!addValueFromOptionToDto(dto, filters[i])) {
					$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.MANDATORY_FILL_ALL_FILTERS);
					return null;
				}
			}
			return dto;
		}

		function addValueFromOptionToDto(dto, filter) {
			if (!filter.field) {
				return true;
			} else if (!filter.value) {
				return false;
			}

			if (filter.field.type == Vgr.enumerations.customizedFilter.type.Date) {
				dto[filter.field.id + "Begin"] = filter.value.beginDate;
				dto[filter.field.id + "End"] = filter.value.endDate;
			} else if (filter.field.type == Vgr.enumerations.customizedFilter.type.Select) {
				dto[filter.field.id] = filter.value.Id;
			} else if (filter.field.type == Vgr.enumerations.customizedFilter.type.Text) {
				dto[filter.field.id] = filter.value;
			} else if (filter.field.type == Vgr.enumerations.customizedFilter.type.AreaSelect) {
				dto[filter.field.id] = filter.value.areaId;
			} else if (filter.field.type == Vgr.enumerations.customizedFilter.type.SupplierTextCompleteV2) {
				dto[filter.field.id] = filter.value.supplierId;
			}

			return true;
		}
	}
]);
