angular.module("vgresiduos").component("clientExternalSystemPopupInputCnpjCpf", {
	scope: {},
	controller: "ClientExternalSystemPopupInputCnpjCpfCtrl",
	controllerAs: "ctrl",
	templateUrl:
		"views/client/components/clientForm/clientFormIntegration/baseComponents/clientExternalSystemsPopup/components/clientExternalSystemPopupInputCnpjCpf/clientExternalSystemPopupInputCnpjCpf_template.html",
	bindings: {
		document: "=",
		hasChecked: "=",
		externalSystemId: "="
	}
});
