angular.module("vgresiduos").controller("NewFeaturesPopupCtrl", [
	"$scope",
	"$rootScope",
	"popupModel",
	function ($scope, $rootScope, popupModel) {
		"use strict";

		$scope.labels = $rootScope.labels;

		function initializePopup() {
			$scope.popupModel = popupModel;
		}

		initializePopup();
	}
]);
