angular.module("vgresiduos").controller("CustomReportFilterPreferenceSectionViewCtrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;

			showSpecificPreference();
		};

		function showSpecificPreference() {
			const informationId = $scope.$ctrl.setter[0].information.id.toUpperCase();

			$scope.showDisposalPricePreferences = canShowDisposalPricePreferences(informationId);
			$scope.showMeasureUnitPreferences = canShowMeasureUnitPreferences(informationId);
		}

		function canShowDisposalPricePreferences(informationId) {
			return informationId === Vgr.enumerations.customReport.information.ResidueDisposalPrice;
		}

		function canShowMeasureUnitPreferences(informationId) {
			return (
				informationId === Vgr.enumerations.customReport.information.ResidueGenerationQuantity ||
				informationId === Vgr.enumerations.customReport.information.ResidueDisposalQuantity ||
				informationId === Vgr.enumerations.customReport.information.ProductionQuantity
			);
		}
	}
]);
