angular.module("vgresiduos").directive("measureUnitConverter", function () {
	return {
		scope: true,
		controller: "MeasureUnitConverterCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/measureUnitConverter/measureUnitConverter_template.html",
		bindToController: {
			volumeConversionFactor: "=",
			unitConversionFactor: "=",
			setAllParams: "=",
			residueId: "="
		}
	};
});
