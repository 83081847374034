angular.module("vgresiduos").component("areaSelect", {
	controller: "AreaCtrl",
	templateUrl: "views/components/fields/areaSelect/areaSelect_template.html",
	bindings: {
		label: "@?",
		helpInfo: "@",
		emptyField: "<?",
		setterId: "<?",
		model: "=",
		isRequired: "<?",
		isDisabled: "<?",
		selectAutoWhenOneOption: "<?",
		onlyActive: "<?"
	}
});
