angular.module("vgresiduos").controller("CustomReportFilterPreferenceDisposalPriceSectionCtrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;

			if (!$scope.model) {
				$scope.model = {};
			}

			initFields();

			setValues($scope.$ctrl.setter);
		};

		function initFields() {
			$scope.options = {};
			$scope.options.residue = {
				label: $scope.labels.COSTS_ENUM[Vgr.enumerations.reportFilters.costsEnum.Residue]
			};
			$scope.options.shipping = {
				label: $scope.labels.COSTS_ENUM[Vgr.enumerations.reportFilters.costsEnum.Shipping]
			};
			$scope.options.other = {
				label: $scope.labels.COSTS_ENUM[Vgr.enumerations.reportFilters.costsEnum.Other]
			};
		}

		function setValues(setter) {
			if (!setter) {
				$scope.model.residue = true;
				$scope.model.shipping = true;
				$scope.model.other = true;
			} else {
				$scope.model.residue = setter.residue;
				$scope.model.shipping = setter.shipping;
				$scope.model.other = setter.other;
			}

			setSummaryLabel();

			callback();
		}

		function setSummaryLabel() {
			const labelArray = [];
			if ($scope.model.residue) {
				labelArray.push($scope.options.residue.label);
			}
			if ($scope.model.shipping) {
				labelArray.push($scope.options.shipping.label);
			}

			if ($scope.model.other) {
				labelArray.push($scope.options.other.label);
			}

			if (labelArray.length == 4) {
				$scope.summaryLabel = $scope.labels.ALL_COSTS_SELECTED;
			} else {
				$scope.summaryLabel = $scope.labels.SOME_COSTS_SELECTED.replace("[VALORES]", labelArray.join(", "));
			}
		}

		$scope.onPreferenceChanged = function () {
			callback();
		};

		function callback() {
			if ($scope.$ctrl.callback) {
				$scope.$ctrl.callback($scope.model);
			}
		}

		this.$onChanges = function (obj) {
			if (obj.clear) {
				if (obj.clear.currentValue) {
					setValues();
				}
			}
			if (obj.reportInformation && !obj.reportInformation.isFirstChange()) {
				setValues($scope.$ctrl.setter);
			}
		};
	}
]);
