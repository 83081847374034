angular.module("vgresiduos").directive("auditLevelsSelectField", function () {
	return {
		scope: true,
		controller: "auditLevelsSelectFieldCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/fields/auditLevelsSelectField/auditLevelsSelectField_template.html",
		bindToController: {
			level: "=",
			defaultOption: "=",
			label: "@",
			emptyFieldDescription: "@",
			isDisabled: "=?"
		}
	};
});
