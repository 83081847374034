angular.module("vgresiduos").controller("CustomReportFilterPreferenceMeasureUnitSectionCtrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;
			$scope.measureUnitOptionEnum = Vgr.enumerations.customReport.measureUnitPreferenceOption;

			if (!$scope.model) {
				$scope.model = {};
			}

			setAvailableOptions($scope.$ctrl.reportInformation);
			setValues($scope.$ctrl.setter);
		};

		function setAvailableOptions(reportInformation) {
			let informationId = reportInformation.id.toUpperCase();
			$scope.excludeConversionToSpecificMeasureUnit = isToRemoveConversionToSpecificMeasureUnit(informationId);
		}

		function setValues(setter) {
			if (setter) {
				$scope.model.option = setter.option;

				$scope.setterMeasureUnit = setter.measureUnitId ? setter.measureUnitId : Vgr.constants.measureUnit.Ton.Id;
			} else {
				$scope.model.option = $scope.measureUnitOptionEnum.ConvertToDefaultMeasureUnitInGroup;

				$scope.setterMeasureUnit = Vgr.constants.measureUnit.Ton.Id;
			}

			verifyEnableMeasureUnitSelect();

			callback();
		}

		function isToRemoveConversionToSpecificMeasureUnit(informationId) {
			return informationId === Vgr.enumerations.customReport.information.ProductionQuantity;
		}

		$scope.onPreferenceChanged = function () {
			verifyEnableMeasureUnitSelect();

			callback();
		};

		function callback() {
			let returnModel = {
				option: $scope.model.option
			};

			if (isConvertToSpecificUnitSelected()) {
				returnModel.measureUnit = $scope.model.measureUnit;
			}

			if ($scope.$ctrl.callback) {
				$scope.$ctrl.callback(returnModel);
			}
		}

		$scope.getMeasureUnitEnumLabel = function (measureUnitOption) {
			return $scope.labels.MEASURE_UNIT_ENUM[measureUnitOption];
		};

		this.$onChanges = function (obj) {
			if (obj.clear) {
				if (obj.clear.currentValue) {
					setValues();
				}
			}
			if (obj.reportInformation && !obj.reportInformation.isFirstChange()) {
				setAvailableOptions(obj.reportInformation.currentValue);
				setValues($scope.$ctrl.setter);
			}
		};

		function verifyEnableMeasureUnitSelect() {
			return ($scope.isMeasureUnitSelectEnabled = isConvertToSpecificUnitSelected());
		}

		function isConvertToSpecificUnitSelected() {
			return $scope.model.option === Vgr.enumerations.customReport.measureUnitPreferenceOption.ConvertToSpecificUnit;
		}

		$scope.onchangeMeasureUnit = function (value) {
			$scope.model.measureUnit = value;
			callback();
		};
	}
]);
