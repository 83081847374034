angular.module("vgresiduos").directive("supplierTextComplete", function () {
	return {
		scope: true,
		controller: "supplierTextCompleteCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/fields/supplierTextComplete/supplierTextComplete_template.html",
		bindToController: {
			customClasses: "@",
			supplier: "=?",
			change: "=?",
			customLabel: "@?",
			allowedSupplierType: "@?"
		}
	};
});
