angular.module("vgresiduos").controller("CustomizedFilterFiltersCtrl", [
	"$rootScope",
	"$scope",
	"$timeout",
	function ($rootScope, $scope, $timeout) {
		"use strict";
		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			if ($scope.ctrl.filterOptions.length == 0) {
				initFilters();
			}

			$scope.addButton = [
				{
					click: function () {
						addNewFilterOption();
					}
				}
			];
		};

		$scope.removeOption = function (index) {
			if ($scope.ctrl.filterOptions.length == 1) {
				$scope.ctrl.filterOptions.length = 0;
				addNewFilterOption();
			} else {
				$scope.ctrl.filterOptions.splice(index, 1);
			}
			updateAvailableOptions();
		};

		function initFilters() {
			if ($scope.ctrl.initialValues && $scope.ctrl.initialValues.length > 0) {
				addInitialFields();
			} else {
				addNewFilterOption();
			}
		}

		function addInitialFields() {
			for (const initialValue of $scope.ctrl.initialValues) {
				const filterOption = addNewFilterOption();
				const initialField = getFieldById(initialValue.id);
				if (initialField) {
					filterOption.field = initialField;
					filterOption.initialValue = initialValue.value;
				}
			}
		}

		function getFieldById(id) {
			const fieldsWithId = $scope.ctrl.availableFields.filter(function (field) {
				return field.id == id;
			});
			return fieldsWithId.length > 0 ? fieldsWithId[0] : null;
		}

		$scope.isDeleteButtonEnabled = function (index) {
			return $scope.ctrl.filterOptions[index].field || $scope.ctrl.filterOptions.length > 1;
		};

		$scope.showAddButton = function (index) {
			if (
				index == $scope.ctrl.filterOptions.length - 1 &&
				$scope.ctrl.filterOptions[$scope.ctrl.filterOptions.length - 1].field
			) {
				return true;
			}
			return false;
		};

		function addNewFilterOption() {
			updateAvailableOptions();
			const availableFields = $scope.ctrl.availableFields;
			const newFilterOption = {
				availableFields: availableFields
			};
			$scope.ctrl.filterOptions.push(newFilterOption);
			return newFilterOption;
		}

		$scope.onChangeField = function (selectedOption) {
			$timeout(function () {
				updateAvailableOptionsFromExistentSelects(selectedOption);
			});
		};

		function updateAvailableOptions() {
			for (const option of $scope.ctrl.availableFields) {
				option.isEnabled = !alreadySelected(option.id);
			}
		}

		function updateAvailableOptionsFromExistentSelects(selectedOption) {
			for (const filterOption of $scope.ctrl.filterOptions) {
				for (const option of filterOption.availableFields) {
					option.isEnabled = !alreadySelected(option.id) && option.id != selectedOption.id;
				}
			}
		}

		function alreadySelected(id) {
			const newArray = $scope.ctrl.filterOptions.filter(function (option) {
				return option.field && option.field.id == id;
			});
			return newArray.length > 0;
		}

		$scope.$watch("ctrl.addNewOption", function (newVal, oldVal) {
			if (newVal != oldVal && newVal) {
				initFilters();
			}
		});
	}
]);
