angular.module("vgresiduos").controller("yearSelectFieldCtrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			getYearsList();
		};

		function getYearsList() {
			var referenceYear = $scope.ctrl.referenceYear;
			var currentYear = $scope.ctrl.stopYear ? $scope.ctrl.stopYear : new Date().getFullYear();
			var interval = currentYear - referenceYear;

			$scope.years = [];
			while (interval >= 0) {
				$scope.years.push({
					year: currentYear - interval
				});
				interval--;
			}
			var currentYearIndex = $scope.years.length - 1;

			if (!$scope.ctrl.disableAutoSelect) {
				$scope.ctrl.model = $scope.years[currentYearIndex].year;
				$scope.changed($scope.ctrl.model);
			}
		}

		$scope.changed = function (year) {
			if ($scope.ctrl.changed) {
				$scope.ctrl.changed(year);
			}
		};
	}
]);
