angular.module("vgresiduos").factory("rootScopeService", [
	"$rootScope",
	function ($rootScope) {
		"use strict";

		const rootScopeService = {};

		const _clientDisposalPreferences = {
			get: function () {
				return $rootScope.clientDisposalPreferences;
			},
			set: function (disposalPreferences) {
				$rootScope.clientDisposalPreferences = disposalPreferences;
			}
		};

		const _storagePreferences = {
			get: function () {
				return $rootScope.storagePreferences;
			},
			set: function (storagePreferences) {
				$rootScope.storagePreferences = storagePreferences;
			}
		};

		const _stockControlPreferences = {
			get: function () {
				return $rootScope.stockControlPreferences;
			},
			set: function (stockControlPreferences) {
				$rootScope.stockControlPreferences = stockControlPreferences;
			}
		};

		const _organizationSupplierPreferences = {
			get: function () {
				return $rootScope.organizationSupplierPreferences;
			},
			set: function (organizationSupplierPreferences) {
				$rootScope.organizationSupplierPreferences = organizationSupplierPreferences;
			}
		};

		rootScopeService.getDisposalPreferences = _clientDisposalPreferences.get;
		rootScopeService.setDisposalPreferences = _clientDisposalPreferences.set;

		rootScopeService.getStoragePreferences = _storagePreferences.get;
		rootScopeService.setStoragePreferences = _storagePreferences.set;

		rootScopeService.getStockControlPreferences = _stockControlPreferences.get;
		rootScopeService.setStockControlPreferences = _stockControlPreferences.set;

		rootScopeService.getOrganizationSupplierPreferences = _organizationSupplierPreferences.get;
		rootScopeService.setOrganizationSupplierPreferences = _organizationSupplierPreferences.set;

		return rootScopeService;
	}
]);
