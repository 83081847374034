angular.module("vgresiduos").factory("accountService", [
	"$rootScope",
	"rootScopeService",
	"localStorageService",
	"$q",
	"$http",
	"$state",
	"permissionService",
	"httpService",
	"awsCognitoService",
	"featureToggleService",
	"translationService",
	function (
		$rootScope,
		rootScopeService,
		localStorageService,
		$q,
		$http,
		$state,
		permissionService,
		httpService,
		awsCognitoService,
		featureToggleService,
		translationService
	) {
		"use strict";

		let _refreshingVgrSession = false;

		function _isLoggedInIdp() {
			return awsCognitoService.isLoggedIn();
		}

		function _loginWithTokenRequest() {
			const req = {
				method: "post",
				url: Vgr.constants.coreHostUrl + "v1/login"
			};
			return $http(req);
		}

		function _validateIdentityProvider(idp) {
			const req = {
				method: "post",
				data: { identityProvider: idp },
				url: Vgr.constants.coreHostUrl + "v1/organizations/identity-provider/validate"
			};
			return $http(req);
		}

		const _getLoggedUserAccountInfo = function (loadFullData) {
			const parameters = loadFullData ? "?loadFullData=true" : "";

			const req = {
				method: "get",
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.accountInfo + parameters
			};
			return $http(req);
		};

		const _updateAccountInfo = function (dto) {
			const req = {
				method: "put",
				data: dto,
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.accountInfo
			};
			return $http(req);
		};

		const _setUserLanguage = function (language) {
			const req = {
				method: "put",
				data: { Language: language },
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.accountInfo + "language"
			};
			return $http(req);
		};

		function _loginWithFederatedIdentity(customIdp) {
			localStorageService.set(Vgr.constants.authentication.previousLoggedSsoIdp, customIdp);

			awsCognitoService.initiateCustomIdpLogin(customIdp);
		}

		function _loginWithUsernameAndPassword(username, password) {
			localStorageService.set(Vgr.constants.authentication.previousLoggedSsoIdp, null);

			const promise = awsCognitoService.initiateVgrLogin(username, password);
			promise.then(function (user) {
				if (user && user.challengeName == Vgr.constants.authentication.challenge.newPasswordRequired) {
					loginState = Vgr.enumerations.loginState.loggedNewPasswordRequired;
				}
			});

			return promise;
		}

		function _isRefreshingVgrSession() {
			return _refreshingVgrSession;
		}

		function _refreshVgrSession() {
			if (_refreshingVgrSession) {
				const deferred = $q.defer();
				deferred.reject();
				return deferred.promise;
			}

			_refreshingVgrSession = true;
			const promise = awsCognitoService.refreshSession();
			promise.then(
				function (tokens) {
					$rootScope.$broadcast(Vgr.constants.evtVgrLoginSuccess, tokens);
					_refreshingVgrSession = false;
				},
				function (err) {
					$rootScope.$broadcast(Vgr.constants.evtVgrLoginFailure, err);
					_refreshingVgrSession = false;
				}
			);
			return promise;
		}

		function _changeTemporaryPassword(password, idpRequiredAttributes) {
			return awsCognitoService.completeNewPassword(password, idpRequiredAttributes);
		}

		function _completeSignUpWithCode(data) {
			const req = {
				method: "put",
				data: data,
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.accounts + data.Username + "/verification"
			};
			return $http(req);
		}

		const _forgotPassword = function (username) {
			const req = {
				method: "post",
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.accounts + username + "/forgot-password"
			};

			return $http(req);
		};

		function _resendCode(username) {
			const req = {
				method: "post",
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.accounts + username + "/verification"
			};
			return $http(req);
		}

		const _confirmForgotPassword = function (model) {
			const req = {
				method: "put",
				data: model,
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.accounts + model.username + "/forgot-password"
			};

			return $http(req);
		};

		function _getCachedVgrToken() {
			return awsCognitoService.getCachedTokens();
		}

		function _getTokenRefreshingIfNecessary() {
			const deferred = $q.defer();
			const tokens = _getCachedVgrToken();
			if (tokens) {
				deferred.resolve(tokens);
			} else {
				_refreshVgrSession().then(() => {
					deferred.resolve(_getCachedVgrToken());
				});
			}
			return deferred.promise;
		}

		function _setAppLogin() {
			localStorageService.set(Vgr.constants.authentication.appLogin, true);
		}

		function _getAppLogin() {
			return localStorageService.get(Vgr.constants.authentication.appLogin);
		}

		const _signUp = function (model) {
			const req = {
				method: "post",
				data: model,
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.accounts
			};
			return $http(req);
		};

		function _loginWithToken() {
			const deferred = $q.defer();

			loginState = Vgr.enumerations.loginState.loggingIn;

			httpService.getDTOFromServiceV2(_loginWithTokenRequest, "", true).then(
				function (loginWithTokenResponse) {
					localStorageService.set(Vgr.constants.authentication.account, loginWithTokenResponse.account);

					permissionService.setUserPermission(loginWithTokenResponse.permissions);

					translationService.setLanguage(_getLoggedAccount().language);

					$rootScope.$broadcast(Vgr.constants.evtChangeLoginStatus, true);

					loginState = Vgr.enumerations.loginState.logged;
					deferred.resolve(loginWithTokenResponse);
				},
				function (error) {
					loginState = Vgr.enumerations.loginState.loginError;
					deferred.reject(error);
				}
			);

			return deferred.promise;
		}

		const _getLoggedAccount = function () {
			return localStorageService.get(Vgr.constants.authentication.account);
		};

		const _reloadLoggedAccount = function () {
			httpService.getDTOFromServiceV2(_getLoggedUserAccountInfo).then(
				function (account) {
					localStorageService.set(Vgr.constants.authentication.account, account);

					if (account.forceLogout) {
						_logout();
						window.location.reload(true);
					} else {
						$rootScope.$broadcast(Vgr.constants.evtChangeLoggedUserInfo);
					}
				},
				function () {
					_logout();
					window.location.reload(true);
				}
			);
		};

		const _getIdentityProviderCurrentChallengeParams = function () {
			return awsCognitoService.getCurrentChallengeParams();
		};

		const _getCurrentClient = function () {
			return localStorageService.get(Vgr.constants.authentication.client);
		};

		const _isMarketProfile = function () {
			const clientData = _getCurrentClient();
			if (clientData) {
				return clientData.isMarketProfile;
			}
			return false;
		};

		const _isMasterClient = function () {
			const clientData = _getCurrentClient();
			if (clientData) {
				return clientData.id == Vgr.constants.VGclientID;
			}
			return false;
		};

		const _isSupplier = function () {
			const clientData = _getCurrentClient();
			if (clientData) {
				return clientData.isSupplier;
			}
			return false;
		};

		const _isPayerOrTrial = function () {
			const currentUnit = _getCurrentClient();
			if (currentUnit && currentUnit.organization) {
				return (
					currentUnit.organization.contractType == Vgr.enumerations.organization.contractType.Payer ||
					currentUnit.organization.contractType == Vgr.enumerations.organization.contractType.Trial
				);
			}
			return false;
		};

		function _changeClientRequest(clientId) {
			const req = {
				method: "post",
				data: clientId,
				url: Vgr.constants.newHostUrl + Vgr.resources.api.user + "ChangeClient"
			};
			return $http(req);
		}

		const _changeClient = function ChangeClient(clientId, ignoreErrorMessageHandling) {
			const deferred = $q.defer();

			_cleanClientPrefereces();

			httpService.postDTOToService(_changeClientRequest, clientId, "", ignoreErrorMessageHandling).then(
				function (response) {
					const organizationUnit = Vgr.dtoMappings.client.fromV2ToCore(response.content);
					localStorageService.set(Vgr.constants.authentication.client, organizationUnit);
					featureToggleService
						.loadFeatureToggles(organizationUnit.organization.id, organizationUnit.id, _getLoggedAccount().code)
						.then(
							function () {
								$rootScope.$broadcast(Vgr.constants.evtChangeLoggedClient);
								deferred.resolve();
							},
							function () {
								deferred.reject();
							}
						);
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		};

		function _cleanClientPrefereces() {
			rootScopeService.setDisposalPreferences(null);
			rootScopeService.setOrganizationSupplierPreferences(null);
			rootScopeService.setStoragePreferences(null);
			rootScopeService.setStockControlPreferences(null);
		}

		const _goHome = function GoHome(reload) {
			if (_isSupplier()) {
				$state.go("homeSupplier");
				return;
			}

			if (_isMarketProfile()) {
				$state.go("residueMarketIndicationRequestRedirect");
			} else {
				if (reload) {
					$state.go("home", null, {
						reload: true
					});
				} else {
					$state.go("home");
				}
			}
		};

		function getLoginParams(username, idp, error) {
			const params = {};
			if (username) {
				params.username = username;
			}
			if (idp) {
				params.idp = idp;
			}
			if (error) {
				params.error = error;
			}
			return params;
		}

		const _getPreviousLoggedSsoIdp = function () {
			return localStorageService.get(Vgr.constants.authentication.previousLoggedSsoIdp);
		};

		const _goToLogin = function (username, error) {
			if (username) {
				$state.go("login", getLoginParams(username));
			} else {
				const idp = _getPreviousLoggedSsoIdp();
				if (idp) {
					$state.go("chooseLogin", getLoginParams(null, idp, error));
				} else {
					$state.go("login", getLoginParams(null, null, error));
				}
			}
		};

		const _isLoggedIn = function () {
			return _getLoggedAccount() ? true : false;
		};

		const _logoutConditional = function () {
			const deferred = $q.defer();

			const isMasterLoggedInAnotherClient = permissionService.isMaster() && !_isMasterClient();

			if (!isMasterLoggedInAnotherClient) {
				_logout();
				deferred.resolve(true);
			} else {
				_changeClient(Vgr.constants.VGclientID).then(
					function () {
						deferred.resolve(false);
					},
					function () {
						_logout();
						deferred.resolve(true);
					}
				);
			}

			return deferred.promise;
		};

		const _logout = function () {
			loginState = Vgr.enumerations.loginState.loggingOut;
			awsCognitoService.logout();
			_removeLoginData();
			_removeEntitiesCache();

			$rootScope.$broadcast(Vgr.constants.evtChangeLoginStatus, false);
		};

		function _removeLoginData() {
			localStorageService.remove(Vgr.constants.authentication.account);
			localStorageService.remove(Vgr.constants.authentication.client);
			localStorageService.remove(Vgr.constants.authentication.permissions);
			localStorageService.remove(Vgr.constants.authentication.featureToggle);
		}

		function _removeEntitiesCache() {
			const keys = localStorageService.keys();
			removeKeysStartingWithPrefixFromCache(keys, Vgr.constants.cacheKeysPrefix.measureUnits);
			removeKeysStartingWithPrefixFromCache(keys, Vgr.constants.cacheKeysPrefix.scales);
			removeKeysStartingWithPrefixFromCache(keys, Vgr.constants.cacheKeysPrefix.disposalPendencyTypes);
			removeKeysStartingWithPrefixFromCache(keys, Vgr.constants.cacheKeysPrefix.disposalTypes);
			removeKeysStartingWithPrefixFromCache(keys, Vgr.constants.cacheKeysPrefix.ibamaResidues);
			removeKeysStartingWithPrefixFromCache(keys, Vgr.constants.cacheKeysPrefix.ibamaActivities);
			removeKeysStartingWithPrefixFromCache(keys, Vgr.constants.cacheKeysPrefix.NBRList);
			removeKeysStartingWithPrefixFromCache(keys, Vgr.constants.cacheKeysPrefix.conama358List);
			removeKeysStartingWithPrefixFromCache(
				keys,
				Vgr.constants.cacheKeysPrefix.conama313List + Vgr.enumerations.conama313.type.DangerousAndNotDangerousResidues
			);
			removeKeysStartingWithPrefixFromCache(
				keys,
				Vgr.constants.cacheKeysPrefix.conama313List + Vgr.enumerations.conama313.type.Recipient
			);
			removeKeysStartingWithPrefixFromCache(
				keys,
				Vgr.constants.cacheKeysPrefix.conama313List + Vgr.enumerations.conama313.type.UncommonDestinatios
			);
			removeKeysStartingWithPrefixFromCache(
				keys,
				Vgr.constants.cacheKeysPrefix.conama313List + Vgr.enumerations.conama313.type.RecycleTreatmment
			);
			removeKeysStartingWithPrefixFromCache(
				keys,
				Vgr.constants.cacheKeysPrefix.conama313List + Vgr.enumerations.conama313.type.CommonDestinatios
			);
			removeKeysStartingWithPrefixFromCache(keys, Vgr.constants.cacheKeysPrefix.onuPictograms);
		}

		function removeKeysStartingWithPrefixFromCache(keys, prefix) {
			const cacheKeys = keys.filter(function (k) {
				return k.indexOf(prefix) >= 0;
			});
			for (const key of cacheKeys) {
				localStorageService.remove(key);
			}
		}

		let loginState = Vgr.enumerations.loginState.notLogged;
		const _isLogoutInProgress = function () {
			return loginState == Vgr.enumerations.loginState.loggingOut;
		};

		const _isLoginOnIdpInProgress = function () {
			return loginState == Vgr.enumerations.loginState.loggingInOnIdp;
		};

		function _setLoggingIn() {
			loginState = Vgr.enumerations.loginState.loggingIn;
		}

		function _updateLoggedClientCachedInfo(name, city) {
			const clientData = _getCurrentClient();
			if (clientData) {
				clientData.name = name;
				clientData.city = city;
				localStorageService.set(Vgr.constants.authentication.client, clientData);
			}
		}

		function _organizationHasSso() {
			const clientData = _getCurrentClient();
			if (clientData) {
				return clientData.organization.identityProvider != null;
			}
			return false;
		}

		return {
			getLoggedAccount: _getLoggedAccount,
			getCurrentClient: _getCurrentClient,
			loginWithToken: _loginWithToken,
			isLoggedInIdp: _isLoggedInIdp,
			setLoggingIn: _setLoggingIn,
			isLoggedIn: _isLoggedIn,
			logout: _logout,
			logoutConditional: _logoutConditional,
			organizationHasSso: _organizationHasSso,
			validateIdentityProvider: _validateIdentityProvider,
			reloadLoggedAccount: _reloadLoggedAccount,

			forgotPassword: _forgotPassword,
			confirmForgotPassword: _confirmForgotPassword,
			signUp: _signUp,
			resendCode: _resendCode,

			completeSignUpWithCode: _completeSignUpWithCode,
			changeTemporaryPassword: _changeTemporaryPassword,

			loginWithUsernameAndPassword: _loginWithUsernameAndPassword,
			loginWithFederatedIdentity: _loginWithFederatedIdentity,
			refreshVgrSession: _refreshVgrSession,
			isRefreshingVgrSession: _isRefreshingVgrSession,
			getCachedVgrToken: _getCachedVgrToken,
			getTokenRefreshingIfNecessary: _getTokenRefreshingIfNecessary,
			setAppLogin: _setAppLogin,
			getAppLogin: _getAppLogin,

			isMarketProfile: _isMarketProfile,
			isMasterClient: _isMasterClient,
			isSupplier: _isSupplier,
			isPayerOrTrial: _isPayerOrTrial,
			changeClient: _changeClient,
			goHome: _goHome,
			goToLogin: _goToLogin,
			getPreviousLoggedSsoIdp: _getPreviousLoggedSsoIdp,
			isLogoutInProgress: _isLogoutInProgress,
			isLoginOnIdpInProgress: _isLoginOnIdpInProgress,

			getLoggedUserAccountInfo: _getLoggedUserAccountInfo,
			updateAccountInfo: _updateAccountInfo,
			setUserLanguage: _setUserLanguage,

			getIdentityProviderCurrentChallengeParams: _getIdentityProviderCurrentChallengeParams,

			updateLoggedClientCachedInfo: _updateLoggedClientCachedInfo,
			removeEntitiesCache: _removeEntitiesCache
		};
	}
]);
