angular.module("componentes").controller("RegisterFormCtrl", [
	"$scope",
	"$rootScope",
	"$window",
	"$state",
	"$stateParams",
	"accountService",
	"httpService",
	"locationService",
	"$q",
	function ($scope, $rootScope, $window, $state, $stateParams, accountService, httpService, locationService, $q) {
		"use strict";

		$scope.checkEmail = false;
		$scope.labels = $rootScope.labels;
		$scope.TOSUrl = Vgr.util.getTermsAndConditionsUrl();
		$scope.privacyPolicyUrl = Vgr.util.getPrivacyPolicyUrl();

		$scope.account = {
			name: null,
			email: null,
			phone: null,
			password: null,
			passwordConfirm: null,
			selectedCity: null
		};

		$scope.toLogin = function () {
			accountService.goToLogin();
		};

		$scope.getStarted = function () {
			$scope.hasChecked = true;
			$scope.loading = true;
			if (!validate()) {
				$scope.loading = false;
				return;
			}
			const dto = getAccountModel();
			httpService.postDTOToServiceV2(accountService.signUp, dto).then(
				function () {
					$rootScope.usernameConfirming = $scope.account.email;
					$state.go("registerConfirmation");
					$scope.loading = false;
				},
				function () {
					$scope.loading = false;
				}
			);
		};

		const validate = function () {
			if (
				!$scope.account.name ||
				!$scope.account.email ||
				!$scope.account.password ||
				!$scope.account.selectedCity ||
				!$scope.account.phone
			) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.VALIDATION_ERROR_MESSAGE);
				return false;
			}
			if (Vgr.util.isInvalidEmail($scope.account.email)) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.WRONG_EMAIL_FORMAT);
				return false;
			}
			if (!$scope.account.Tos) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.TOS_ERROR_MESSAGE);
				return false;
			}
			return true;
		};

		$scope.cityComponentPaginationOptions = {
			pageSize: 20,
			pageNumber: 1,
			query: ""
		};

		function getParamsToListCity() {
			return {
				nameLike: $scope.cityComponentPaginationOptions.query,
				skip: $scope.cityComponentPaginationOptions.pageNumber - 1,
				take: $scope.cityComponentPaginationOptions.pageSize
			};
		}

		function loadCityDescriptionToShow(city) {
			city.DescriptionToShow = Vgr.util.capitalizeText(city.name) + "- " + city.state.abbreviation;
		}

		$scope.listCities = function () {
			const deferred = $q.defer();
			httpService.getListFromServiceCore(locationService.listCities, getParamsToListCity()).then(function (response) {
				for (const city of response.list) {
					loadCityDescriptionToShow(city);
				}
				deferred.resolve(response.list);
			});
			return deferred.promise;
		};

		$scope.openTerms = function () {
			$window.open($scope.TOSUrl, "_blank");
		};

		this.$onInit = function () {
			$scope.account.Tos = false;
			setParams();
		};

		function setParams() {
			if ($stateParams.nome) {
				$scope.account.name = $stateParams.nome;
			}
			if ($stateParams.email) {
				$scope.account.email = $stateParams.email;
			}
			if ($stateParams.telefone) {
				$scope.account.phone = $stateParams.telefone;
			}
		}

		function getAccountModel() {
			return {
				Name: $scope.account.name,
				Email: $scope.account.email,
				Password: $scope.account.password,
				Phone: $scope.account.phone,
				CityId: $scope.account.selectedCity.id,
				ToSDate: new Date()
			};
		}
	}
]);
