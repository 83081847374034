angular.module("vgresiduos").controller("AttachCDFToDisposalsPopupCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"engagementScoreService",
	"accountService",
	"fileService",
	"disposalService",
	"dialogService",
	"list",
	function (
		$scope,
		$rootScope,
		$q,
		httpService,
		engagementScoreService,
		accountService,
		fileService,
		disposalService,
		dialogService,
		list
	) {
		"use strict";

		var CDFPath = "Clients/" + accountService.getCurrentClient().id + "/DisposalCDFs/" + Vgr.util.uuid();
		var operationStatus = Vgr.enumerations.operationStatus;

		var fileUploaded = false;
		var updatingDisposals = false;

		$scope.labels = $rootScope.labels;

		$scope.popupTitle = $scope.labels.ADD_CDF;
		$scope.popupActionButtons = [
			{
				label: $scope.labels.CLOSE,
				class: "md-primary",
				click: cancel
			},
			{
				label: $scope.labels.ATTACH,
				class: "md-primary md-raised",
				click: attach,
				disabled: true,
				hide: false
			},
			{
				label: $scope.labels.CONFIRM,
				class: "md-primary md-raised",
				click: confirm,
				hide: true
			}
		];

		$scope.fileOptions = {
			update: function (newFiles) {
				fileUploaded = false;
				updateAttachButton();
				var deferred = $q.defer();

				fileService.uploadAll(newFiles, CDFPath).then(
					function (response) {
						fileUploaded = true;
						updateAttachButton();
						deferred.resolve(response);
					},
					function () {
						deferred.reject();
					}
				);

				return deferred.promise;
			},
			get: function () {
				var deferred = $q.defer();
				deferred.resolve([]);
				return deferred.promise;
			},
			fileList: [],
			hidePreview: true
		};

		$scope.columns = [
			{
				description: $scope.labels.ID,
				property: "Code"
			},
			{
				description: $scope.labels.STATUS,
				property: "StatusLabel"
			},
			{
				description: $scope.labels.GATHERING_DATE,
				property: "FormatedGatheringDate"
			},
			{
				description: $scope.labels.DESTINATOR,
				property: "DestinatorName"
			},
			{
				description: $scope.labels.HAS_CDF_STATUS,
				property: "CDFStatusName"
			},
			{
				description: $scope.labels.OPERATION_STATUS,
				property: "OperationStatusName",
				getClass: function (row) {
					if (row.OperationStatus == operationStatus.Success) {
						return "c-green-primary";
					} else if (
						row.OperationStatus == operationStatus.UnexpectedError ||
						row.OperationStatus == operationStatus.Forbidden
					) {
						return "c-red";
					}
					return "";
				},
				action: {
					enabled: function (row) {
						return row.OperationStatus == operationStatus.UnexpectedError && !updatingDisposals;
					},
					buttonClass: "retry-integration retry-integration-white",
					class: "text-underline",
					icon: "sync",
					onClick: function (row) {
						updateDisposals([row]);
					}
				}
			}
		];

		function showConfirmButton() {
			$scope.popupActionButtons[1].hide = true;
			$scope.popupActionButtons[2].hide = false;
		}

		function updateAttachButton() {
			$scope.popupActionButtons[1].disabled = !fileUploaded || updatingDisposals;
		}

		function hasAnyCDFAttached(item) {
			return (
				item &&
				item.Files &&
				item.Files.find(
					(file) => file.Type == Vgr.enumerations.destination.calculatedDisposalFileTypes.DisposalCertificate
				)
			);
		}

		function setCDFStatus(item, CDFAttached) {
			item.CDFStatus = CDFAttached;
			if (CDFAttached) {
				item.CDFStatusName = $scope.labels.YES;
			} else {
				item.CDFStatusName = $scope.labels.NO;
			}
		}

		function setOperationStatus(item, operationStatus) {
			item.OperationStatus = operationStatus;
			item.OperationStatusName = $scope.labels.OPERATION_STATUS_ENUM[operationStatus];
		}

		function updateDisposals(list) {
			updatingDisposals = true;
			updateAttachButton();

			for (var file of $scope.fileOptions.fileList) {
				file.Type = Vgr.enumerations.destination.file.CDF;
			}

			updateSync(list);
		}

		function updateSync(list) {
			var index = 0;
			attacDisposalFiles(index, list).then(
				function () {
					updatingDisposals = false;
					updateAttachButton();
					var disposalWithError = $scope.list.find(
						(d) => d.OperationStatus != operationStatus.Success && d.OperationStatus != operationStatus.Forbidden
					);
					if (disposalWithError == null) {
						showConfirmButton();
					}
				},
				function () {
					updatingDisposals = false;
					updateAttachButton();
					var disposalWithError = $scope.list.find(
						(d) => d.OperationStatus != operationStatus.Success && d.OperationStatus != operationStatus.Forbidden
					);
					if (disposalWithError == null) {
						showConfirmButton();
					}
				}
			);
		}

		function attacDisposalFiles(index, list) {
			var deferred = $q.defer();

			if (index == list.length) {
				deferred.resolve();
				return deferred.promise;
			}

			setOperationStatus(list[index], operationStatus.Processing);

			var dto = {
				disposalId: list[index].Id,
				model: {
					Files: $scope.fileOptions.fileList
				}
			};

			httpService.postDTOToServiceV2(disposalService.attachDisposalFiles, dto, true).then(
				function (data) {
					setOperationStatus(list[index], operationStatus.Success);
					setCDFStatus(list[index], true);
					index++;
					attacDisposalFiles(index, list).then(
						function () {
							deferred.resolve();
						},
						function () {
							deferred.reject();
						}
					);
				},
				function (response) {
					if (response && response.status == Vgr.constants.httpStatus.Forbidden) {
						setOperationStatus(list[index], operationStatus.Forbidden);
					} else {
						setOperationStatus(list[index], operationStatus.UnexpectedError);
					}

					index++;
					attacDisposalFiles(index, list).then(
						function () {
							deferred.reject();
						},
						function () {
							deferred.reject();
						}
					);
				}
			);

			return deferred.promise;
		}

		function attach() {
			engagementScoreService.track(Vgr.trackings.disposal.history.attachCdf);

			const pendingList = $scope.list.filter(
				(item) =>
					item.OperationStatus == operationStatus.Pending || item.OperationStatus == operationStatus.UnexpectedError
			);

			updateDisposals(pendingList);
		}

		function cancel() {
			dialogService.cancel();
		}

		function confirm() {
			dialogService.confirm();
		}

		function initializePopup() {
			for (var item of list) {
				setOperationStatus(item, operationStatus.Pending);
				if (hasAnyCDFAttached(item)) {
					setCDFStatus(item, true);
				} else {
					setCDFStatus(item, false);
				}
			}

			$scope.list = list;
		}

		initializePopup();
	}
]);
