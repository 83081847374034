angular.module("vgresiduos").controller("OrganizationIbamaFormCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.onSelectActivity = function (activities) {
			$scope.ctrl.model.orgIbamaActivities = activities;
		};
	}
]);
