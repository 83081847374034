angular.module("vgresiduos").controller("ClientsMultiSelectCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"clientService",
	function ($scope, $rootScope, httpService, clientService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			listClients();
		};

		function listClients() {
			httpService.getListFromServiceCore(clientService.listClientsCore, null).then(function (response) {
				response.list.forEach((element) => {
					element.DescriptionFormated = element.id + " - " + element.name;
					element.Id = element.id;
				});
				$scope.clients = response.list;
			});
		}

		$scope.onMultiSelectValuesChanged = function (values) {
			if (!$scope.clients) {
				$scope.$ctrl.selected = [];
			} else {
				$scope.$ctrl.selected = $scope.clients.filter(function (client) {
					return values.includes(client.id);
				});
			}
		};
	}
]);
