angular.module("vgresiduos").controller("CitiesTextCompleteCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"locationService",
	"$q",
	function ($scope, $rootScope, httpService, locationService, $q) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.$watch("ctrl.cityState", function (newValue, old) {
			if (old && !newValue) {
				$scope.searchText = null;
			} else if (newValue && newValue.id && !newValue.Id) {
				formatCity(newValue);
			}
		});

		$scope.listCities = function () {
			const deferred = $q.defer();
			httpService.getListFromServiceCore(locationService.listCities, getParamsToListCity()).then(function (response) {
				for (const city of response.list) {
					formatCity(city);
				}
				deferred.resolve(response.list);
			});
			return deferred.promise;
		};

		$scope.cityComponentPaginationOptions = {
			pageSize: 20,
			pageNumber: 1,
			query: ""
		};

		function formatCity(city) {
			city.Id = city.id;
			city.ID = city.id;
			city.DescriptionToShow = Vgr.util.capitalizeText(city.name) + "- " + city.state.abbreviation;
		}

		function getParamsToListCity() {
			const send = {
				nameLike: $scope.cityComponentPaginationOptions.query,
				skip: $scope.cityComponentPaginationOptions.pageNumber - 1,
				take: $scope.cityComponentPaginationOptions.pageSize
			};
			if ($scope.ctrl.countryId) {
				send.countryId = $scope.ctrl.countryId;
			}
			return send;
		}

		this.$onInit = function () {
			if (!$scope.ctrl.labelCity) {
				$scope.ctrl.labelCity = $scope.labels.CITY_STATE;
			}
		};
	}
]);
