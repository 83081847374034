angular.module("vgresiduos").controller("DocumentTypeSelectFieldCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"supplierDocumentService",
	function ($scope, $rootScope, httpService, supplierDocumentService) {
		"use strict";
		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			loadDocumentTypes();
		};

		function loadDocumentTypes() {
			if ($scope.list) {
				return;
			}
			httpService.getListFromServiceCore(supplierDocumentService.listDocumentTypes).then((response) => {
				$scope.list = response.list;
			});
		}
	}
]);
