angular.module("vgresiduos").controller("ClientsInternalTextCompleteCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"clientService",
	"$q",
	function ($scope, $rootScope, httpService, clientService, $q) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.subfieldList = [
			{ item: "Id", label: $scope.labels.ID },
			{ item: "CNPJ", label: $scope.labels.CNPJ },
			{ item: "CityDescriptionToShow", label: $scope.labels.CITY }
		];
		$scope.emptyClientMessage = $rootScope.labels.NO_REGISTRY_FOUND;

		$scope.listClientsOptions = {
			pageSize: 30,
			pageNumber: 1,
			query: "",
			id: 0
		};

		$scope.loadClients = function () {
			if (!$scope.listClientsOptions.query) {
				$scope.listClientsOptions.query = "";
			}

			const deferred = $q.defer();

			const dto = {
				onlyGenerators: $scope.ctrl.onlyGenerators,
				onlyDestinators: $scope.ctrl.onlyDestinators,
				query: $scope.listClientsOptions.query,
				skip: $scope.listClientsOptions.pageNumber - 1,
				take: $scope.listClientsOptions.pageSize
			};

			httpService.getDTOFromServiceV2(clientService.listClientsInternal, dto).then(
				function (data) {
					formatClientCityDescription(data.Clients);

					deferred.resolve(data.Clients);
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		};

		function formatClientCityDescription(clients) {
			for (const client of clients) {
				if (!client.City) {
					continue;
				}
				client.City.DescriptionToShow = Vgr.util.getDescriptionToShow(client.City);
				client.CityDescriptionToShow = client.City.DescriptionToShow;
			}
		}
	}
]);
