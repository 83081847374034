angular.module("componentes").controller("RegisterConfirmationFormCtrl", [
	"$scope",
	"$rootScope",
	"$state",
	"$stateParams",
	"httpService",
	"accountService",
	"$timeout",
	function ($scope, $rootScope, $state, $stateParams, httpService, accountService, $timeout) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.confirmationSuccess = false;

		$scope.username = $rootScope.usernameConfirming;
		if ($scope.username) {
			$rootScope.usernameConfirming = "";
			$scope.usernameCameFromRegister = true;
		}

		$scope.toLogin = function () {
			accountService.goToLogin();
		};

		$scope.toTryAgain = function () {
			$scope.tryConfirm();
		};

		$scope.tryConfirm = function () {
			$scope.hasChecked = true;
			if (!$scope.username) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.USERNAME_MISSING);
				return;
			}
			if (!$scope.confirmationCode) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.CODE_MISSING);
				return;
			}

			$scope.loading = true;

			const model = getConfirmationModel();
			httpService.postDTOToServiceV2(accountService.completeSignUpWithCode, model).then(
				function () {
					$scope.confirmationSuccess = true;
					$scope.loading = false;
				},
				function () {
					$scope.loading = false;
				}
			);
		};

		function getConfirmationModel() {
			return {
				Username: $scope.username,
				Code: $scope.confirmationCode
			};
		}

		$scope.resendCode = function () {
			if (!$scope.username) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.USERNAME_MISSING);
				return;
			}

			$scope.loading = true;
			$scope.isResendDisabled = true;
			httpService.postDTOToServiceV2(accountService.resendCode, $scope.username).then(
				function () {
					$scope.isResendDisabled = true;
					$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $scope.labels.VERIFICATION_EMAIL_SENT);
					$timeout(function () {
						$scope.isResendDisabled = false;
					}, 30000);
					$scope.loading = false;
				},
				function (response) {
					$scope.isResendDisabled = false;
					$scope.loading = false;
				}
			);
		};
	}
]);
