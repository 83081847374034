angular.module("vgresiduos").controller("DisposalIntegrationCtrl", [
	"$rootScope",
	"$scope",
	"$q",
	"httpService",
	"disposalIntegrationService",
	function ($rootScope, $scope, $q, httpService, disposalIntegrationService) {
		"use strict";

		$scope.operationStatus = Vgr.enumerations.operationStatus;
		$scope.generalStatus = Vgr.constants.generalStatus;
		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			if ($scope.ctrl.destinationModel.Model == Vgr.enumerations.destination.model.Simplified) {
				return;
			}

			if ($scope.ctrl.allowNewIntegrations) {
				loadClientExternalSystems().then(function (externalSystems) {
					$scope.ctrl.destinationModel.ExternalSystems = externalSystems;
					$rootScope.$broadcast(Vgr.constants.evtClientExternalSystemChanged, $scope.ctrl.destinationModel.Id);
					executeLoadIfNecessary();
				});
				return;
			}

			if (!$scope.isCurrentStageReview() && !$scope.hasIntegrations()) {
				loadClientExternalSystems().then(function (externalSystems) {
					$scope.ctrl.destinationModel.ExternalSystems = externalSystems;
					$rootScope.$broadcast(Vgr.constants.evtClientExternalSystemChanged, $scope.ctrl.destinationModel.Id);
					if (hasAnyExternalSystemChecked()) {
						if ($scope.disposalForceIntegrations()) {
							$rootScope.$broadcast(Vgr.constants.evtOpenDisposalAddIntegrationsPopup, $scope.destinationModel.Id);
						}
						$scope.updateSlip($scope.stepsEnum.D, getSlipDescription());
					}
					executeLoadIfNecessary();
				});
			} else if ($scope.hasIntegrations()) {
				const integration = $scope.ctrl.destinationModel.Manifests.find(
					(manifest) => manifest.Source.Id != Vgr.enumerations.destination.systemSource.Local
				);
				if (integration) {
					$scope.updateSlip($scope.stepsEnum.D, integration.Source.Description);
				}
			}
		};

		function executeLoadIfNecessary() {
			if ($scope.ctrl.onLoad) {
				$scope.ctrl.onLoad();
			}
		}

		function hasAnyExternalSystemChecked() {
			const externalSystems = $scope.ctrl.destinationModel.ExternalSystems;

			for (let i in externalSystems) {
				if (externalSystems[i].Selected) {
					return true;
				}
			}
			return false;
		}

		function getSlipDescription() {
			let slipDescription = "";
			const externalSystems = $scope.ctrl.destinationModel.ExternalSystems;

			for (let i in externalSystems) {
				if (externalSystems[i].Selected) {
					slipDescription += externalSystems[i].SourceDescription + "\n";
				}
			}

			return slipDescription;
		}

		function loadClientExternalSystems() {
			const deferred = $q.defer();

			if ($scope.clientExternalSystems) {
				suggestClientExternalSystemsIntegrations(deferred);
			} else {
				$scope.ctrl.listClientExternalSystems().then(function (list) {
					$scope.clientExternalSystems = list;
					suggestClientExternalSystemsIntegrations(deferred);
				});
			}

			return deferred.promise;
		}

		function suggestClientExternalSystemsIntegrations(deferred) {
			const externalSystems = initClientExternalSystems();

			if ($scope.ctrl.forcedAutoSelectSystems) {
				$scope.ctrl.forcedAutoSelectSystems.forEach((fs) => {
					const system = externalSystems.find((es) => es.SourceId == fs);
					if (system) {
						system.Selected = true;
					}
				});

				deferred.resolve(externalSystems);
			} else {
				calculateSystemsToIntegrate(deferred, externalSystems);
			}
		}

		function initClientExternalSystems() {
			const externalSystems = [];

			for (const index in $scope.clientExternalSystems) {
				if (
					$scope.ctrl.systemsToIgnore &&
					$scope.ctrl.systemsToIgnore.includes($scope.clientExternalSystems[index].source.id)
				) {
					continue;
				}

				externalSystems.push({
					SourceId: $scope.clientExternalSystems[index].source.id,
					SourceDescription: $scope.clientExternalSystems[index].source.description,
					StateId: $scope.clientExternalSystems[index].source.state
						? $scope.clientExternalSystems[index].source.state.id
						: null,
					Selected: false,
					isBeta: $scope.clientExternalSystems[index].source.isBeta,
					canUseProvisionalMtr: $scope.clientExternalSystems[index].canUseProvisionalMtr
				});
			}

			return externalSystems;
		}

		function calculateSystemsToIntegrate(deferred, clientExternalSystems) {
			httpService
				.getDTOFromServiceV2(
					disposalIntegrationService.listMtrSystemsToIntegrate,
					$scope.ctrl.readOnlyModel.Destinator.Id
				)
				.then(
					function (mtrSystemsToIntegrate) {
						clientExternalSystems.forEach((es) => {
							es.Selected = mtrSystemsToIntegrate.some((mti) => mti.id == es.SourceId);
						});

						deferred.resolve(clientExternalSystems);
					},
					function () {
						deferred.resolve(clientExternalSystems);
					}
				);
		}

		$scope.systemChange = function () {
			$rootScope.$broadcast(Vgr.constants.evtClientExternalSystemChanged, $scope.ctrl.destinationModel.Id);

			if ($scope.ctrl.allowNewIntegrations) {
				return;
			}

			if (hasAnyExternalSystemChecked()) {
				$scope.updateSlip($scope.stepsEnum.D, getSlipDescription());
			} else {
				$scope.updateSlip($scope.stepsEnum.D, null);
			}
		};

		$scope.disableCheckbox = function (system) {
			return (
				$scope.loadings.integration ||
				$scope.ctrl.disableOptions ||
				(system && system.status == $scope.operationStatus.BadRequestError)
			);
		};

		$scope.retry = function (system) {
			$rootScope.$broadcast("retry_cancel", system);
		};
	}
]);
