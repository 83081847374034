angular.module("vgresiduos").factory("scalingIntegrationService", [
	"$http",
	function ($http) {
		"use strict";

		const _getWeight = function GetWeight(scale) {
			const req = {
				method: "get",
				url: scale.LocalServerUrl + Vgr.resources.local.apiV1.scales
			};
			return $http(req);
		};

		return {
			getWeight: _getWeight
		};
	}
]);
