angular.module("vgresiduos").controller("AdditionalPropertyValueMultiSelectCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"$timeout",
	"httpService",
	"additionalPropertyService",
	function ($scope, $rootScope, $q, $timeout, httpService, additionalPropertyService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			$scope.input = {
				freeTextPropertyValue: null
			};
			$scope.model = {};
			listAdditionalPropertyValues($scope.$ctrl.additionalPropertyId).then(function () {
				if ($scope.$ctrl.setter) {
					setValues($scope.$ctrl.setter);
				}
			});
		};

		function listAdditionalPropertyValues(additionalPropertyId) {
			let deferred = $q.defer();

			httpService.getDTOFromServiceV2(additionalPropertyService.getAdditionalProperty, additionalPropertyId).then(
				function (data) {
					$scope.label = data.name;
					$scope.property = data;

					if ($scope.isList()) {
						data.values.forEach((element) => {
							element.id = element.id.toString();
							element.displayText = element.name;
						});
						$scope.propertyValueList = data.values;
					}

					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function setValues(values) {
			if ($scope.isFreeText()) {
				$scope.input.freeTextPropertyValue = values ? values[0] : null;
				$scope.onFreeTextChanged($scope.input.freeTextPropertyValue);
			}
		}

		$scope.isFreeText = function () {
			return $scope.property && $scope.property.type == Vgr.enumerations.additionalProperty.type.FreeText;
		};

		$scope.isList = function () {
			return $scope.property && $scope.property.type == Vgr.enumerations.additionalProperty.type.SuspendList;
		};

		$scope.onListChanged = function (selectedValues) {
			$scope.model = selectedValues;
			callback();
		};

		$scope.onFreeTextChanged = function (selectedValue) {
			let values = [];

			if (selectedValue) {
				values.push({ id: selectedValue });
			}

			$scope.model = values;

			callback();
		};

		this.$onChanges = function (obj) {
			if (
				obj.additionalPropertyId &&
				!obj.additionalPropertyId.isFirstChange() &&
				obj.additionalPropertyId.currentValue != obj.additionalPropertyId.previousValue
			) {
				onChangeAdditionalProperty(obj.additionalPropertyId.currentValue);
			}
			if (obj.clear && obj.clear.currentValue != obj.clear.previousValue) {
				if (obj.clear.currentValue) {
					clearValues();
				}
			}
		};

		function onChangeAdditionalProperty(additionalPropertyId) {
			listAdditionalPropertyValues(additionalPropertyId).then(function () {
				clearValues();
			});
		}

		function clearValues() {
			if (!$scope.property) {
				return;
			}

			if ($scope.propertyValueList) {
				$scope.$ctrl.clear = true;
				$timeout(function () {
					$scope.$ctrl.clear = false;
				});
			} else {
				$scope.input.freeTextPropertyValue = null;
			}
		}

		function callback() {
			if ($scope.$ctrl.callback) {
				$scope.$ctrl.callback($scope.model);
			}
		}
	}
]);
