angular.module("vgresiduos").component("goalEditInput", {
	controller: "GoalEditInputCtrl",
	templateUrl: "views/goals/edit/components/goalEditInput/goalEditInput_template.html",
	bindings: {
		month: "<",
		measureUnit: "<",
		amountResidueGeneration: "<",
		amountDisposal: "<",
		onAmountDisposalChange: "<",
		onAmountResidueGenerationChange: "<",
		onIsRevenueChange: "<",
		isRevenue: "<"
	}
});
