angular.module("vgresiduos").controller("PermissionGroupPermissionsFormCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.permissionOptions = [
			{
				name: $scope.labels.ALLOW,
				value: Vgr.enumerations.systemPermissions.level.Allow
			},
			{
				name: $scope.labels.NOT_ALLOW,
				value: Vgr.enumerations.systemPermissions.level.NotAllow
			},
			{
				name: "",
				value: Vgr.enumerations.systemPermissions.level.ToBeDefined
			}
		];

		$scope.toUpperCase = function (text) {
			return text.toUpperCase();
		};
	}
]);
