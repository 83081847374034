angular.module("vgresiduos").component("quantityField", {
	controller: "QuantityFieldCtrl",
	templateUrl: "views/components/fields/quantityField/quantityField_template.html",
	bindings: {
		onQuantityChanged: "<",
		onQuantityChangedManually: "<",
		onClearScalingWeights: "<",
		onTareQuantityChanged: "<",
		onGrossQuantityChanged: "<",
		onScaleAuxiliar1WeightChanged: "<",
		onScaleAuxiliar2WeightChanged: "<",
		onMeasureUnitChanged: "<",
		onMeasureUnitGroupChanged: "<",
		onScalingTypeChanged: "<",
		setterScaleQuantity: "<",
		setterQuantity: "<",
		setterTareQuantity: "<",
		setterGrossQuantity: "<",
		setterScaleAuxiliar1Weight: "<",
		setterScaleAuxiliar2Weight: "<",
		setterScalingType: "<",
		setterMeasureUnit: "<",
		setterMeasureUnitGroup: "<",
		setterResidueCode: "<?",
		setterOriginalMeasureUnit: "<?",
		setterReferenceDate: "<?",
		process: "<",
		identifier: "<?",
		hideScalingButton: "<",
		setterMin: "<?",
		setterMax: "<?",
		form: "=?",
		isDisabled: "<?"
	}
});
