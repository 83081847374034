angular.module("vgresiduos").controller("HomeSuppliersCtrl", [
	"$rootScope",
	"$scope",
	"$state",
	"accountService",
	"engagementScoreService",
	function ($rootScope, $scope, $state, accountService, engagementScoreService) {
		"use strict";

		const loggedClient = accountService.getCurrentClient();

		// Scope functions
		$scope.goToGeneralData = function () {
			engagementScoreService.track(Vgr.trackings.supplierProfile.goToGeneralData);
			$state.go("clientEdit", {
				id: loggedClient ? loggedClient.id : null
			});
		};

		$scope.goToDocuments = function () {
			engagementScoreService.track(Vgr.trackings.supplierProfile.goToDocuments);
			$state.go("documentList");
		};

		$scope.goToAudits = function () {
			engagementScoreService.track(Vgr.trackings.supplierProfile.goToAudits);
			$state.go("transporterEdit", {
				id: loggedClient ? loggedClient.id : null
			});
		};

		// RootScope variables
		$rootScope.contentNavBar = {};
		$rootScope.contentNavBar.title = $scope.labels.HOME;
		$rootScope.contentNavBar.removeButtonBar = true;

		this.$onInit = function () {
			$scope.loading = true;

			$rootScope.$broadcast(Vgr.constants.evtShowMenu);

			$scope.loggedUser = accountService.getLoggedAccount();

			$scope.loading = false;
		};
	}
]);
