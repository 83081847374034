angular.module("vgresiduos").directive("citiesTextComplete", function () {
	return {
		scope: true,
		controller: "CitiesTextCompleteCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/fields/citiesTextComplete/citiesTextComplete_template.html",
		bindToController: {
			cityState: "=?",
			countryId: "=?",
			labelCity: "@?"
		}
	};
});
