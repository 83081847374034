angular.module("vgresiduos").directive("customizedFilterField", function () {
	return {
		scope: true,
		controller: "CustomizedFilterFieldCtrl",
		controllerAs: "ctrl",
		templateUrl:
			"views/components/customizedFilter/baseComponents/customizedFilterField/customizedFilterField_template.html",
		bindToController: {
			availableFields: "=",
			selectedField: "=",
			onChangeSelectField: "=",
			label: "@?",
			isDisabled: "@?"
		}
	};
});
