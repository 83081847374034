angular.module("vgresiduos").controller("DeleteDisposalPopupCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"disposalService",
	"residuesManagementService",
	"asyncTimeoutService",
	"dialogService",
	"disposal",
	function (
		$scope,
		$rootScope,
		$q,
		httpService,
		disposalService,
		residuesManagementService,
		asyncTimeoutService,
		dialogService,
		disposal
	) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.operationStatus = Vgr.enumerations.operationStatus;

		$scope.justificationHelper = $scope.labels.CANCEL_MTR_HELPER;
		let hasDeleteError = false;
		$scope.systemsSocket = [];

		$scope.model = {
			cancelReason: ""
		};

		const cancelButton = {
			label: $scope.labels.CANCEL,
			class: "md-primary",
			click: function () {
				dialogService.cancel();
			},
			disabled: false
		};

		const deleteButton = {
			label: $scope.labels.DELETE,
			class: "md-primary md-raised",
			click: function () {
				if (!validate()) {
					return;
				}
				deleteIntegrationsAndDisposal();
			},
			disabled: false
		};

		$scope.popupActionButtons = [cancelButton, deleteButton];

		function validate() {
			if (!$scope.model.cancelReason && checkJustificationIsRequired()) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.JUSTIFICATION_REQUIRED);
				return false;
			}

			return true;
		}

		$scope.justificationIsRequired = function () {
			return checkJustificationIsRequired();
		};

		function checkJustificationIsRequired() {
			return (
				$scope.manifests.filter((i) => i.Selected && i.status != Vgr.enumerations.operationStatus.Success).length > 0
			);
		}

		function updateDeleteButton() {
			const anyError = $scope.manifests.find((m) => m.Selected && m.errorMessage != null);

			if (anyError) {
				$scope.popupActionButtons[1].label = $scope.labels.DO_DELETE;
				$scope.popupActionButtons[1].click = deleteDisposal;
			} else {
				$scope.popupActionButtons[1].label = $scope.labels.DELETE;
				$scope.popupActionButtons[1].click = deleteIntegrationsAndDisposal;
			}
		}

		function deleteIntegrationsAndDisposal() {
			const toDeleteManifests = $scope.manifests.filter(
				(i) => i.Selected && i.status != Vgr.enumerations.operationStatus.Success
			);
			clearItems();
			confirm(toDeleteManifests).then(
				function () {
					if ($scope.systemsSocket.length == 0) {
						deleteDisposal();
					}
				},
				function () {
					onErrorDeleteDisposal();
				}
			);
		}

		function onErrorDeleteDisposal() {
			updateDeleteButton();
			toggleButtonsEnabledState(true);
		}

		function deleteDisposal() {
			toggleButtonsEnabledState(false);
			const dto = {
				disposalId: disposal.Id,
				organizationUnitCode: disposal.GeneratorOrganizationUnitCode,
				params: {
					ignoreIntegrations: true
				}
			};

			httpService.deleteDTOToServiceV2(disposalService.deleteDisposal, dto).then(
				function () {
					dialogService.confirm();
				},
				function () {
					onErrorDeleteDisposal();
				}
			);
		}

		function toggleButtonsEnabledState(enabled) {
			$scope.popupActionButtons.forEach((button) => (button.disabled = !enabled));
		}

		function confirm(toDeleteManifests) {
			const deferred = $q.defer();

			addSystemsToBeDeleted(toDeleteManifests);
			toggleButtonsEnabledState(false);
			batchDeleteItems(deferred, toDeleteManifests, 1);
			return deferred.promise;
		}

		function batchDeleteItems(deferred, list, itemsPerBatch) {
			const batchItemsToDelete = list.splice(0, itemsPerBatch);
			batchDelete(batchItemsToDelete).then(
				function () {
					onBatchFinish(deferred, list, itemsPerBatch);
				},
				function () {
					hasDeleteError = true;
					onBatchFinish(deferred, list, itemsPerBatch);
					$scope.popupActionButtons[0].disabled = false;
				}
			);
		}

		function onBatchFinish(deferred, list, itemsPerBatch) {
			if (list.length > 0) {
				batchDeleteItems(deferred, list, itemsPerBatch);
			} else {
				$scope.popupActionButtons[0].disabled = false;
				hasDeleteError ? deferred.reject() : deferred.resolve();
			}
		}

		function batchDelete(list) {
			const promises = getDeleteItemsPromises(list);
			return $q.all(promises);
		}

		function getDeleteItemsPromises(list) {
			const promises = [];
			list.forEach((item) => {
				promises.push(deleteItem(item));
			});

			return promises;
		}

		function deleteItem(item) {
			item.status = Vgr.enumerations.operationStatus.Processing;

			const dto = {
				organizationUnitCode: disposal.GeneratorOrganizationUnitCode,
				disposalId: disposal.Id,
				sourceId: item.Source.Id,
				params: {
					cancelReason: $scope.model.cancelReason
				}
			};

			const promise = httpService.postDTOToServiceV2(disposalService.cancelDisposalIntegration, dto, true);
			promise.then(
				function (data) {
					$scope.showErrorIntegrated = true;
					if (data.IsAsyncCancellation) {
						listenForEvents(item.Source);
						asyncTimeoutService.startAsyncCallTimer(
							item.Source,
							onTimeoutNoResponseFromServer,
							asyncTimeoutService.getTimeoutBySystem(item.Source.Id),
							1
						);
					} else {
						removeSystemToBeDeleted(item.Source.Id);
						item.status = Vgr.enumerations.operationStatus.Success;
					}
				},
				function (response) {
					unsubscribeTopic(item.Source);
					item.errorMessage = disposalService.getIntegrationErrorMessageFromResponse(
						response.status,
						response.data,
						item.Source.Description
					);
					item.status =
						response.status == 400
							? Vgr.enumerations.operationStatus.BadRequestError
							: Vgr.enumerations.operationStatus.UnexpectedError;
				}
			);

			return promise;
		}

		$scope.retry = function (item) {
			if (!validate()) {
				return;
			}

			clearItem(item);
			confirm([item]).then(
				function () {
					updateDeleteButton();
				},
				function () {
					updateDeleteButton();
					toggleButtonsEnabledState(true);
				}
			);
		};

		function clearItems() {
			hasDeleteError = false;
			$scope.manifests.forEach((item) => {
				clearItem(item);
			});
		}

		function clearItem(item) {
			item.status = null;
			item.errorMessage = null;
		}

		function initializePopup() {
			$scope.allManifests = disposal.Manifests.filter(
				(m) => m.Source.Id != Vgr.enumerations.destination.systemSource.Local
			);
			$scope.manifests = removeMultipleBySource($scope.allManifests);
			if ($scope.manifests.length) {
				$scope.manifests.forEach((m) => (m.Selected = true));
				$scope.popupTitle = $scope.labels.DELETE_DISPOSAL_TITLE_WITH_INTEGRATIONS;
				$scope.subtitle = $scope.labels.DELETE_DISPOSAL_WITH_INTEGRATIONS;
			} else {
				$scope.popupTitle = $scope.labels.DELETE_DISPOSAL;
				$scope.subtitle = $scope.labels.DELETE_DISPOSAL_WITHOUT_INTEGRATION;
			}

			clearItems();
		}

		function removeMultipleBySource(manifests) {
			const groupedManifestsBySource = manifests.reduce((grouped, manifest) => {
				const sourceId = manifest.Source.Id;
				grouped[sourceId] = grouped[sourceId] || [];
				grouped[sourceId].push(manifest);
				return grouped;
			}, []);

			const distinctManifests = [];
			const sources = Object.keys(groupedManifestsBySource);
			sources.forEach((s) => {
				if (groupedManifestsBySource[s].length > 0) {
					distinctManifests.push(groupedManifestsBySource[s][0]);
				}
			});
			return distinctManifests;
		}

		initializePopup();

		function setCancelManifestSuccess(manifest, sourceId) {
			const allManifestsFromSource = $scope.allManifests.filter((m) => m.Source.Id == sourceId);
			const allManifestsFromSourcePending = allManifestsFromSource.filter((m) => m.Source.Id == sourceId && !m.deleted);
			if (allManifestsFromSourcePending.length > 0) {
				allManifestsFromSourcePending[0].deleted = true;
			}
			if (allManifestsFromSource && allManifestsFromSource.every((m) => m.deleted)) {
				manifest.status = Vgr.enumerations.operationStatus.Success;
				removeSystemToBeDeleted(sourceId);
			}

			if ($scope.systemsSocket.length == 0) {
				deleteDisposal();
			}
		}

		function setCancelManifestError(manifest, errors) {
			if (errors && errors.length > 0) {
				manifest.errorMessage = disposalService.getIntegrationErrorMessageFromResponse(
					400,
					Vgr.util.capitalizeObjectKeys(errors),
					manifest.Source.Description
				);
				manifest.status = Vgr.enumerations.operationStatus.BadRequestError;
			}
		}

		function checkIfDisposalManifestsWhereCancelled() {
			const dto = {
				disposalId: disposal.Id
			};

			httpService.postDTOToServiceV2(residuesManagementService.listDisposalManifests, dto, true).then(
				function (updatedManifests) {
					const updatedManifestsCreated = updatedManifests.filter((m) => m.mtrCode);
					if (updatedManifestsCreated.length == 0) {
						deleteDisposal();
					} else {
						clearItems();
						handleCancelIntegrationsErrors(updatedManifestsCreated);
						onErrorDeleteDisposal();
					}
				},
				function () {
					onErrorDeleteDisposal();
				}
			);
		}

		function handleCancelIntegrationsErrors(updatedManifests) {
			const manifestsWithErrors = updatedManifests.filter((m) => m.errors && m.errors.length > 0);
			if (manifestsWithErrors.length > 0) {
				manifestsWithErrors.forEach((manifestWithError) => {
					const manifest = $scope.manifests.find((m) => m.Source.Id == manifestWithError.sourceId);
					setCancelManifestError(manifest, manifestWithError.errors);
				});
			}
		}

		function addSystemsToBeDeleted(toDeleteManifests) {
			toDeleteManifests.forEach((manifest) => {
				addSystemToBeDeleted(manifest.Source);
			});
		}

		function addSystemToBeDeleted(system) {
			const alreadyAddedSystem = $scope.systemsSocket.find((s) => s.Id == system.Id);
			if (!alreadyAddedSystem) {
				$scope.systemsSocket.push(system);
			}
		}
		function removeSystemToBeDeleted(sourceId) {
			const index = $scope.systemsSocket.findIndex((s) => s.Id == sourceId);
			if (index >= 0) {
				$scope.systemsSocket.splice(index, 1);
			}
		}

		//init socket methods -------------------------------------------------------------------------------------

		function subscribeTopic(system) {
			system.observable$ = window.Amplify.API.graphql(
				window.Amplify.graphqlOperation(Vgr.constants.graphql.subscriptions, {
					name: getRoomName(disposal.Id, system.Id)
				})
			).subscribe({
				next: ({ value }) => callBackTopicSucces(JSON.parse(value.data.subscribe2channel.data)),
				error: (error) => {
					console.log(error);
				}
			});

			return system;
		}

		function callBackTopicSucces(data) {
			const manifest = $scope.manifests.find((m) => m.Source.Id == data.sourceId);
			asyncTimeoutService.cancelAsyncTimer(manifest.Source);
			if (data.success) {
				setCancelManifestSuccess(manifest, data.sourceId);
			} else {
				setCancelManifestError(manifest, data.errors);
			}
			unsubscribeTopic(manifest.Source);
		}

		function unsubscribeTopic(system) {
			if (system.observable$) {
				system.observable$.unsubscribe();
			}
		}

		function getRoomName(disposalId, sourceId) {
			return "disposal_" + disposalId + "_source_" + sourceId;
		}

		function listenForEvents(system) {
			subscribeTopic(system);
		}

		function cancelAllTimers(system) {
			if (system) {
				asyncTimeoutService.cancelAsyncTimer(system);
				unsubscribeTopic(system);
			} else {
				$scope.systemsSocket.forEach((m) => {
					unsubscribeTopic(m);
					asyncTimeoutService.cancelAsyncTimer(m);
				});
				$scope.systemsSocket = [];
			}
		}

		function onTimeoutNoResponseFromServer(system) {
			cancelAllTimers(system);
			checkIfDisposalManifestsWhereCancelled();
		}

		//end socket methods -------------------------------------------------------------------------------

		$scope.$on("$destroy", function () {
			cancelAllTimers();
			// nullify the DOM-bound model
			$scope.domElement = null;
		});
	}
]);
