angular.module("vgresiduos").controller("IbamaActivityDetailSelectCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"ibamaService",
	function ($scope, $rootScope, $q, httpService, ibamaService) {
		"use strict";

		$scope.labels = $rootScope.labels;
		var isListLoaded;

		this.$onInit = function () {
			loadIbamaActivities().then(setIbamaActivity);
		};

		function loadIbamaActivities() {
			var deferred = $q.defer();

			if (isListLoaded) {
				deferred.resolve();
				return deferred.promise;
			}

			var listFunc = $scope.$ctrl.getList ? $scope.$ctrl.getList : getList;

			listFunc().then(
				function (data) {
					$scope.ibamaActivityList = data.list
						.map(function (a) {
							return {
								code: a.code,
								description: a.description,
								details: a.details,
								formattedDescription: a.code + " - " + a.description
							};
						})
						.sort(function (a, b) {
							return a.code - b.code;
						});
					isListLoaded = true;
					deferred.resolve();
				},
				function (data) {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function getList() {
			return httpService.getListFromServiceCore(ibamaService.listIbamaActivities, null);
		}

		function setIbamaActivity() {
			if (!$scope.$ctrl.setterId) {
				$scope.$ctrl.model = {
					IbamaActivityCode: null,
					Code: null
				};
				return;
			}
			var activity = $scope.ibamaActivityList.find(
				(ibamaActivity) => ibamaActivity.code == $scope.$ctrl.setterId.IbamaActivityCode
			);
			if (activity && activity.details) {
				$scope.ibamaActivityDetailList = activity.details
					.map(function (a) {
						return {
							code: a.code,
							description: a.description,
							formattedDescription: a.code + " - " + a.description
						};
					})
					.sort(function (a, b) {
						return a.code - b.code;
					});
			}
			$scope.$ctrl.model = $scope.$ctrl.setterId;
		}

		var watchModel = $scope.$watch("$ctrl.model", function (newValue, oldValue) {
			if (newValue != oldValue && $scope.$ctrl.onIbamaActivityChanged) {
				$scope.$ctrl.onIbamaActivityChanged(newValue);
			}
		});

		var watchSetterId = $scope.$watch("$ctrl.setterId", function (newValue, oldValue) {
			var modelId = $scope.$ctrl.model ? $scope.$ctrl.model.Id : null;

			if (newValue != modelId) {
				loadIbamaActivities().then(setIbamaActivity);
			}
		});

		$scope.$on("$destroy", function () {
			// disable the listener
			watchModel();
			watchSetterId();

			// nullify the DOM-bound model
			$scope.domElement = null;
		});

		$scope.getLabel = function () {
			if ($scope.$ctrl.noLabel) {
				return "";
			}
			return $scope.$ctrl.label ? $scope.$ctrl.label : $scope.labels.IBAMA_ACTIVITY;
		};

		$scope.getDetailLabel = function () {
			if ($scope.$ctrl.noLabel) {
				return "";
			}
			return $scope.$ctrl.label ? $scope.$ctrl.label : $scope.labels.DETAIL;
		};

		$scope.onChangeIbamaActivity = function (model) {
			$scope.ibamaActivityDetailList = [];
			$scope.$ctrl.model.Code = null;

			var activity = $scope.ibamaActivityList.find((ibamaActivity) => ibamaActivity.code == model);
			if (activity) {
				$scope.ibamaActivityDetailList = activity.details
					.map(function (a) {
						return {
							code: a.code,
							description: a.description,
							formattedDescription: a.code + " - " + a.description
						};
					})
					.sort(function (a, b) {
						return a.code - b.code;
					});
			}
		};
	}
]);
