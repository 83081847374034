angular.module("vgresiduos").component("customReportFilterPreferenceMeasureUnitSection", {
	controller: "CustomReportFilterPreferenceMeasureUnitSectionCtrl",
	templateUrl:
		"views/customReports/components/customReportFilter/sections/customReportFilterPreferenceMeasureUnitSection_template.html",
	bindings: {
		reportInformation: "<",
		clear: "<?",
		callback: "<?",
		setter: "<?"
	}
});
