angular.module("vgresiduos").controller("AuditCategoryInfoCtrl", [
	"$rootScope",
	"$scope",
	"httpService",
	"auditService",
	function ($rootScope, $scope, httpService, auditService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			listAuditItemClassifications();
			setScoreList();
		};

		function listAuditItemClassifications() {
			httpService.getListFromServiceCore(auditService.listAuditItemClassifications, null).then(
				function (response) {
					$scope.itemClassificationsList = response.list;
					loadItemClassificationsNameAndDescription();
				},
				function () {
					//Nothing to be done
				}
			);
		}

		function loadItemClassificationsNameAndDescription() {
			for (const item of $scope.itemClassificationsList) {
				switch (item.id) {
					case Vgr.enumerations.audit.Item.Classification.RLI:
						item.Description = $scope.labels.RLI_DESCRIPTION;
						break;
					case Vgr.enumerations.audit.Item.Classification.I:
						item.Description = $scope.labels.I_DESCRIPTION;
						break;
					case Vgr.enumerations.audit.Item.Classification.F:
						item.Description = $scope.labels.F_DESCRIPTION;
						break;
					case Vgr.enumerations.audit.Item.Classification.A:
						item.Description = $scope.labels.A_DESCRIPTION;
						break;
				}
			}
		}

		function setScoreList() {
			httpService.getListFromServiceCore(auditService.listAuditCategoryItemOptions, null).then(
				function (response) {
					buildScoreDescription(response.list);
				},
				function () {
					//Nothing to be done
				}
			);
		}

		function buildScoreDescription(list) {
			$scope.scoreList = [];
			let index = null;
			for (const option of list) {
				for (let j = 0; j < $scope.scoreList.length; j++) {
					if (option.score == $scope.scoreList[j].Value) {
						index = j;
					}
				}
				if (index != null) {
					$scope.scoreList[index].Description = $scope.scoreList[index].Description + " / " + option.name;
				} else {
					$scope.scoreList.push({
						Description: option.name,
						Value: option.score
					});
				}
				index = null;
			}
		}
	}
]);
