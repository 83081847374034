angular.module("vgresiduos").controller("OpportunitiesCtrl", [
	"$rootScope",
	"$scope",
	"$state",
	"dashboardService",
	"clientService",
	"marketService",
	"httpService",
	"engagementScoreService",
	"dialogService",
	"$q",
	function (
		$rootScope,
		$scope,
		$state,
		dashboardService,
		clientService,
		marketService,
		httpService,
		engagementScoreService,
		dialogService,
		$q
	) {
		"use strict";

		this.$onInit = function () {
			$scope.opportunities = [];
			$scope.isSupplier = clientService.isSupplier();
			$scope.labels = $rootScope.labels;
			this.loading = $scope.ctrl.options.loading;
			$scope.hasOpportunities = false;
			$scope.generateLoading = false;

			$scope.openCreateRequestPopup = function (event, opportunity) {
				event.stopPropagation();
				engagementScoreService.track(Vgr.trackings.opportunities.clickedOnAnOpportunity);
				const params = {
					popupTitle: null,
					popupText: $rootScope.labels.CONFIRM_REQUEST_SUPPLIER,
					popupAuxiliaryText: "",
					popupDescription: null,
					cancelButtonLabel: null,
					confirmButtonLabel: $rootScope.labels.BUTTON_CONFIRM_REQUEST_SUPPLIER,
					cancelMethod: null,
					confirmMethod: function () {
						return createIndicationRequest(opportunity);
					}
				};
				return dialogService.showDialogFromTemplateV2(
					"views/components/popups/confirmPopup_template.html",
					"ConfirmPopupCtrl",
					null,
					params
				);
			};

			setOpportunities();

			$scope.hasOpportunitiesMarketAccess = true;
		};

		function createIndicationRequest(opportunity) {
			$scope.generateLoading = true;
			const deferred = $q.defer();
			const residuesList = [];
			if (opportunity.Residues && opportunity.Residues.length > 0) {
				opportunity.Residues.forEach((el) => {
					residuesList.push({ unitResidueCode: el.Code });
				});

				httpService
					.postDTOToServiceV2(marketService.createIndicationRequest, {
						residues: residuesList
					})
					.then(() => {
						$scope.generateLoading = false;

						$rootScope.$broadcast(
							Vgr.constants.evtShowSuccessMessage,
							$rootScope.labels.IMPROVEMENT_INDICATION_REQUEST_SUCCESS
						);
						deferred.resolve();

						$state.go("residueMarketNew");
					})
					.catch(() => {
						deferred.resolve();
						$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.VALIDATION_ERROR_MESSAGE);
						$scope.generateLoading = false;
					});
				return deferred.promise;
			}
		}

		function setOpportunities() {
			httpService.getListFromService(dashboardService.getOpportunities).then(function (list) {
				$scope.opportunities = list;
				$scope.opportunities.forEach(function (opportunityModel) {
					setExtraFields(opportunityModel);
				});
				$scope.loading = false;
			});
		}

		function setExtraFields(opportunityModel) {
			opportunityModel.Num = Vgr.util.formatUnitDecimalPlaces(opportunityModel.Value, 2);
			opportunityModel.Color = opportunityModel.IsImprovement ? "green" : "red";
			if (opportunityModel.MessageCode) {
				opportunityModel.Message = getOpportunityDisplayText(opportunityModel);
				$scope.hasOpportunities = true;
			}
		}

		function getOpportunityDisplayText(opportunityModel) {
			let text = $scope.labels[opportunityModel.MessageCode].replace("[VAL]", opportunityModel.Num);
			if (opportunityModel.Residues && opportunityModel.Residues.length > 0) {
				const residuesNames = getConcatenatedResiduesNames(opportunityModel.Residues);
				text = text.replace("[RES]", residuesNames);
			}
			return text;
		}

		function getConcatenatedResiduesNames(residuesList) {
			const residues = [];
			for (const residue of residuesList) {
				residues.push(residue.Name);
			}
			return residues.join(", ");
		}
	}
]);
