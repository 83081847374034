angular.module("vgresiduos").controller("DisposalAttachmentsCtrl", [
	"$scope",
	"$q",
	"fileService",
	function ($scope, $q, fileService) {
		"use strict";

		const filesDeferredInvoice = $q.defer();
		const filesDefferedGeneralDocument = $q.defer();

		$scope.fileOptions.invoice = {
			fileService: "mixed",
			get: function () {
				if ($scope.isGatheringStage() && !$scope.isCurrentStageReview()) {
					filesDeferredInvoice.resolve([]);
				}
				return filesDeferredInvoice.promise;
			},
			update: function (newFiles) {
				const deferred = $q.defer();
				const path = $scope.getDisposalFilePath();
				fileService.uploadAll(newFiles, path).then(
					function (response) {
						deferred.resolve(response);
					},
					function () {
						deferred.reject();
					}
				);
				return deferred.promise;
			},
			fileList: []
		};

		$scope.fileOptions.generalDocuments = {
			fileService: "mixed",
			get: function () {
				if ($scope.isGatheringStage() && !$scope.isCurrentStageReview()) {
					filesDefferedGeneralDocument.resolve([]);
				}
				return filesDefferedGeneralDocument.promise;
			},
			update: function (newFiles) {
				const deferred = $q.defer();
				const path = $scope.getDisposalFilePath();
				fileService.uploadAll(newFiles, path).then(
					function (response) {
						deferred.resolve(response);
					},
					function () {
						deferred.reject();
					}
				);
				return deferred.promise;
			},
			fileList: []
		};

		function getInvoiceFilesModel() {
			const files = [];

			for (const index in $scope.fileOptions.invoice.fileList) {
				files.push({
					Path: $scope.fileOptions.invoice.fileList[index].Path,
					Type: Vgr.enumerations.destination.file.Invoice,
					Name: $scope.fileOptions.invoice.fileList[index].Name
				});
			}
			return files;
		}

		function getGeneralFilesModel() {
			const files = [];

			for (const index in $scope.fileOptions.generalDocuments.fileList) {
				files.push({
					Path: $scope.fileOptions.generalDocuments.fileList[index].Path,
					Type: Vgr.enumerations.destination.file.GeneralDocument,
					Name: $scope.fileOptions.generalDocuments.fileList[index].Name
				});
			}
			return files;
		}

		$scope.$watch("fileOptions.invoice.fileList.length", function (newValue, oldValue) {
			if (newValue != oldValue) {
				$scope.destinationModel.InvoiceFiles = getInvoiceFilesModel();
			}
		});

		$scope.$watch("fileOptions.generalDocuments.fileList.length", function (newValue, oldValue) {
			if (newValue != oldValue) {
				$scope.destinationModel.GeneralFiles = getGeneralFilesModel();
			}
		});

		this.$onInit = function () {
			loadInvoiceFiles($scope.destinationModel.InvoiceFiles);
			loadGeneralDocumentFiles($scope.destinationModel.GeneralFiles);

			if (!$scope.isGatheringStage() || $scope.isCurrentStageReview()) {
				setInvoiceStatusChip();
			}
		};

		function setInvoiceStatusChip() {
			$scope.invoiceStatusChip = {};
			if ($scope.destinationModel.InvoiceStatus == Vgr.enumerations.destination.invoiceStatus.Valid) {
				$scope.invoiceStatusChip.label = $scope.labels.DISPOSAL_INVOICE_STATUS.VALID;
				$scope.invoiceStatusChip.className = "valid";
			} else {
				$scope.invoiceStatusChip.label = $scope.labels.DISPOSAL_INVOICE_STATUS.CANCELED;
				$scope.invoiceStatusChip.className = "canceled";
			}
		}

		$scope.showInvoiceAlert = function () {
			return (
				hasInvoicePendency() &&
				!$scope.fileOptions.invoice.fileList.length &&
				!$scope.destinationModel.PhysicalFilesInvoice &&
				!$scope.destinationModel.InvoiceCode
			);
		};

		function hasInvoicePendency() {
			const foundPendency = $scope.destinationModel.Pendencies.find(
				(p) => p.Type == Vgr.enumerations.destination.pendencyType.Invoice
			);
			return foundPendency ? foundPendency.IsChecked : false;
		}

		$scope.showAlertMtrNotCanceled = function () {
			return (
				$scope.isCurrentStageReview() &&
				$scope.destinationModel.DeleteStatus == Vgr.enumerations.destination.deleteStatus.NotPossible &&
				$scope.hasIntegrations()
			);
		};

		function loadInvoiceFiles(files) {
			const invoiceFiles = [];
			if (files) {
				for (const index in files) {
					if (files[index].Type == Vgr.enumerations.destination.file.Invoice) {
						invoiceFiles.push({
							Id: files[index].Id,
							Name: files[index].Name,
							Path: files[index].Path,
							S3Key: files[index].Path
						});
					}
				}
			}

			filesDeferredInvoice.resolve(invoiceFiles);
		}

		function loadGeneralDocumentFiles(files) {
			const generalDocuments = [];
			if (files) {
				for (const index in files) {
					if (files[index].Type == Vgr.enumerations.destination.file.GeneralDocument) {
						generalDocuments.push({
							Id: files[index].Id,
							Name: files[index].Name,
							Path: files[index].Path,
							S3Key: files[index].Path
						});
					}
				}
			}

			filesDefferedGeneralDocument.resolve(generalDocuments);
		}
	}
]);
