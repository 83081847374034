angular.module("vgresiduos").controller("ClientRecipientCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"recipientService",
	function ($scope, $rootScope, $q, httpService, recipientService) {
		"use strict";

		$scope.labels = $rootScope.labels;
		let isListLoaded;

		this.$onInit = function () {
			if ($scope.$ctrl.setterCode) {
				loadClientRecipients().then(setClientRecipientByCode);
			} else {
				loadClientRecipients().then(setClientRecipientById);
			}
		};

		function loadClientRecipients() {
			const deferred = $q.defer();

			if (isListLoaded) {
				deferred.resolve();
				return deferred.promise;
			}

			httpService.getListFromServiceCore(recipientService.listClientRecipientsCore, null).then(
				function (response) {
					const list = response.list;
					$scope.clientRecipients = list;
					isListLoaded = true;
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function setClientRecipientById() {
			if (!$scope.$ctrl.setterId) {
				$scope.$ctrl.model = null;
				return;
			}

			const foundClientRecipient = $scope.clientRecipients.find(
				(clientRecipient) => clientRecipient.id == $scope.$ctrl.setterId
			);
			if (foundClientRecipient != null) {
				$scope.$ctrl.model = foundClientRecipient;
				if ($scope.recipientEfficiency) {
					updateEfficiencyLabel($scope.recipientEfficiency);
				}
			}
		}

		function setClientRecipientByCode() {
			if (!$scope.$ctrl.setterCode) {
				$scope.$ctrl.model = null;
				return;
			}

			const foundClientRecipient = $scope.clientRecipients.find(
				(clientRecipient) => clientRecipient.code == $scope.$ctrl.setterCode
			);
			if (foundClientRecipient != null) {
				$scope.$ctrl.model = foundClientRecipient;
				if ($scope.recipientEfficiency) {
					updateEfficiencyLabel($scope.recipientEfficiency);
				}
			}
		}

		const watchSetterId = $scope.$watch("$ctrl.setterId", function (newValue, oldValue) {
			const modelId = $scope.$ctrl.model ? $scope.$ctrl.model.id : null;

			if (newValue != modelId) {
				loadClientRecipients().then(setClientRecipientById);
			}
		});

		const watchSetterCode = $scope.$watch("$ctrl.setterCode", function (newValue, oldValue) {
			const modelCode = $scope.$ctrl.model ? $scope.$ctrl.model.code : null;

			if (newValue != modelCode) {
				loadClientRecipients().then(setClientRecipientByCode);
			}
		});

		const watchModel = $scope.$watch("$ctrl.model", function (newValue, oldValue) {
			if (newValue != oldValue && $scope.$ctrl.onClientRecipientChanged) {
				$scope.$ctrl.onClientRecipientChanged(newValue, $scope.$ctrl.identifier);
			}
		});

		$scope.recipientEfficiency = 0;
		const watchRecipientPercentualEfficiency = $scope.$watch(
			"$ctrl.recipientPercentualEfficiency",
			function (newValue) {
				$scope.recipientEfficiency = newValue;
				if ($scope.$ctrl.model && $scope.$ctrl.model.volume) {
					updateEfficiencyLabel(newValue);
				} else {
					updateEfficiencyLabel(0);
				}
			}
		);

		function updateEfficiencyLabel(newValue) {
			if (newValue === null) {
				$scope.recipientPercentualEfficiencyText = "";
				$scope.recipientPercentualEfficiencyWarning = $rootScope.labels.CANT_CALCULATE_EFFICIENCY;
				$scope.recipientPercentualEfficiencyTextTooltip = $rootScope.labels.RESIDUE_WITHOUT_CONVERSION_FACTOR;
			} else if (newValue && newValue > 100) {
				$scope.recipientPercentualEfficiencyText = $rootScope.labels.RECIPIENT_VOLUME_EXCEEDED;
				$scope.recipientPercentualEfficiencyWarning = "";
			} else if (newValue && newValue <= 100) {
				$scope.recipientPercentualEfficiencyText = $rootScope.labels.VOLUME_EFFICIENCY_IN.replace(
					"{0}",
					Vgr.util.formatUnitDecimalPlaces(newValue, 2)
				);
				$scope.recipientPercentualEfficiencyWarning = "";
			} else {
				$scope.recipientPercentualEfficiencyText = "";
				$scope.recipientPercentualEfficiencyWarning = "";
			}
		}

		$scope.$on("$destroy", function () {
			// disable the listener
			watchModel();
			watchSetterId();
			watchSetterCode();
			watchRecipientPercentualEfficiency();

			// nullify the DOM-bound model
			$scope.domElement = null;
		});
	}
]);
