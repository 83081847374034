angular.module("vgresiduos").controller("SystemReportInformationSelectCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"systemReportService",
	function ($scope, $rootScope, $q, httpService, systemReportService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			$scope.label = "";
			loadOptions().then(function () {
				setValue($scope.$ctrl.setter);
			});
		};

		function loadOptions() {
			let deferred = $q.defer();

			httpService.getDTOFromServiceV2(systemReportService.listInformations, $scope.$ctrl.reportType).then(
				function (list) {
					$scope.list = list;
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function setValue(value) {
			if (!value) {
				return;
			}

			let foundInformation = $scope.list.find((item) => item.id == value);

			$scope.model = foundInformation;
			$scope.selectedItem = foundInformation;
			callback();
		}

		function callback() {
			if ($scope.$ctrl.callback) {
				$scope.$ctrl.callback($scope.selectedItem);
			}
		}

		$scope.onChange = function (selectedValue) {
			$scope.selectedItem = selectedValue;
			callback();
		};

		this.$onChanges = function () {};
	}
]);
