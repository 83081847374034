angular.module("vgresiduos").directive("propertyOriginSelectField", function () {
	return {
		scope: true,
		controller: "propertyOriginSelectFieldCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/onboarding/additionalProperties/fields/propertyOriginSelectField_template.html",
		bindToController: {
			additionalPropertyProcess: "=",
			isDisabled: "<?"
		}
	};
});
