angular.module("vgresiduos").directive("passwordTextField", function () {
	return {
		scope: true,
		controller: "PasswordTextFieldCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/fields/passwordTextField/passwordTextField_template.html",
		bindToController: {
			model: "=",
			isMandatory: "@"
		}
	};
});
