angular.module("vgresiduos").component("residueReportLineChart", {
	controller: "ResidueReportLineChartCtrl",
	templateUrl: "views/report/residue/base/residueReportLineChart/residueReportLineChart_template.html",
	bindings: {
		data: "<",
		headers: "<",
		chartTitle: "@",
		forceHideLegend: "<?",
		yAxisDecimals: "<?",
		subtitles: "<?",
		concatenateFirstDataQualifier: "<?"
	}
});
