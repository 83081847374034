angular.module("vgresiduos").controller("PricingFormCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"$state",
	"$stateParams",
	"httpService",
	"pricingService",
	"residueV2Service",
	"dialogService",
	"clientService",
	"permissionService",
	function (
		$scope,
		$rootScope,
		$q,
		$state,
		$stateParams,
		httpService,
		pricingService,
		residueV2Service,
		dialogService,
		clientService,
		permissionService
	) {
		const createPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.MasterCreateEditDeletePrice);
		$scope.createPermission = createPermission;

		$scope.labels = $rootScope.labels;

		$scope.priceDecimals = clientService.getPriceDecimalPlaces();

		$rootScope.contentNavBar = {
			hasPermission: function () {
				return createPermission;
			},
			back: function () {
				return goBack();
			},
			breadcrumb: isPricingEdit() ? $stateParams.id : $scope.labels.NEW,
			title: $scope.labels.PRICES,
			save: function () {
				if (isPricingEdit()) {
					return updatePricing();
				} else {
					return createPricing();
				}
			},
			validate: function () {
				return validate();
			},
			valid: true
		};

		$scope.listOptions = {
			residues: {
				pageSize: 20,
				pageNumber: 1,
				query: ""
			}
		};

		$scope.subfieldListForResidues = [{ item: "id", label: $scope.labels.ID }];

		$scope.listResidues = function () {
			if (!$scope.listOptions.residues.query) {
				$scope.listOptions.residues.query = "";
			}
			const deferred = $q.defer();
			httpService
				.getListFromServiceCore(residueV2Service.listSystemResidues, {
					take: 20,
					skip: $scope.listOptions.residues.pageNumber - 1,
					residueName: $scope.listOptions.residues.query
				})
				.then(function (response) {
					deferred.resolve(response.list);
				});
			return deferred.promise;
		};

		$scope.residueSelected = function (residue) {
			if (!residue) {
				return;
			}

			$scope.pricingModel.Residue = residue;
		};

		$scope.openFilterPopup = function () {
			$scope.popupTitle = $scope.labels.RESIDUE_GROUPS;
			const dialog = dialogService
				.showDialogFromTemplateWithScope(
					null,
					"views/onboarding/residues/components/residueFilterPopup_template.html",
					"ResidueFilterPopupCtrl",
					$scope,
					"#residue-filter-popup",
					false,
					function () {}
				)
				.then(
					function () {},
					function () {}
				);
			$scope.dialog = dialog;
		};

		$scope.isByResiduePricingSelected = function () {
			return (
				$scope.pricingModel.PricingType &&
				$scope.pricingModel.PricingType.Id == Vgr.enumerations.pricing.pricingType.Residue
			);
		};

		$scope.isByDisposalPricingSelected = function () {
			return (
				$scope.pricingModel.PricingType &&
				$scope.pricingModel.PricingType.Id == Vgr.enumerations.pricing.pricingType.Disposal
			);
		};

		$rootScope.contentNavBarGrid = null;

		function isPricingEdit() {
			return $state.is("pricingEdit");
		}

		function isDuplicating() {
			return $stateParams.duplicar != null;
		}

		function goBack() {
			$state.go("pricingList");
		}

		function getUpdatePricingDTO() {
			return {
				id: $stateParams.id,
				model: getPricingModel()
			};
		}

		function getCreatePricingDTO() {
			return {
				model: getPricingModel()
			};
		}

		function getPricingModel() {
			return {
				Date: $scope.pricingModel.Date,
				ResidueId: $scope.pricingModel.Residue.id,
				NegotiationType: $scope.pricingModel.NegotiationType.Id,
				PricingType: $scope.pricingModel.PricingType.Id,
				UnitaryPrice: $scope.isByResiduePricingSelected() ? $scope.pricingModel.UnitaryPrice : null,
				Quantity: $scope.pricingModel.Quantity,
				ShippingPrice: $scope.pricingModel.ShippingPrice,
				TotalPrice: $scope.isByDisposalPricingSelected() ? $scope.pricingModel.TotalPrice : null,
				DestinatorId: $scope.pricingModel.Destinator ? $scope.pricingModel.Destinator.Id : null,
				DestinatorName: $scope.pricingModel.Destinator ? null : $scope.pricingModel.FreeDestinatorName,
				DestinatorCityId: $scope.pricingModel.Destinator ? null : $scope.pricingModel.DestinatorCity.Id,
				GeneratorId: $scope.pricingModel.Generator ? $scope.pricingModel.Generator.Id : null,
				GeneratorName: $scope.pricingModel.Generator ? null : $scope.pricingModel.FreeGeneratorName,
				GeneratorCityId: $scope.pricingModel.Generator ? null : $scope.pricingModel.GeneratorCity.Id,
				DisposalTypeId: $scope.pricingModel.DisposalType ? $scope.pricingModel.DisposalType.Id : null
			};
		}

		function setPricingModel(pricingModel) {
			$scope.pricingModel.Date = Vgr.util.getDateFromString(pricingModel.Date);
			$scope.pricingModel.Residue = { id: pricingModel.Residue.Id, name: pricingModel.Residue.Name };
			$scope.pricingModel.Residue.PricingMeasureUnit = pricingModel.MeasureUnit;
			$scope.pricingModel.NegotiationType = $scope.NegotiationTypes.find(
				(type) => type.Id == pricingModel.NegotiationType
			);
			$scope.pricingModel.PricingType = $scope.PricingTypes.find((type) => type.Id == pricingModel.PricingType);
			$scope.pricingModel.UnitaryPrice = pricingModel.UnitaryPrice;
			$scope.pricingModel.Quantity = pricingModel.Quantity;
			$scope.pricingModel.MeasureUnitId = pricingModel.MeasureUnit ? pricingModel.MeasureUnit.Id : null;
			$scope.pricingModel.ShippingPrice = pricingModel.ShippingPrice;
			$scope.pricingModel.TotalPrice = pricingModel.TotalPrice;
			$scope.pricingModel.DisposalTypeId = pricingModel.DisposalTypeId;

			if (pricingModel.Destinator) {
				$scope.pricingModel.Destinator = pricingModel.Destinator;
				if (pricingModel.Destinator.City) {
					$scope.pricingModel.Destinator.City.DescriptionToShow = Vgr.util.getDescriptionToShow(
						pricingModel.Destinator.City
					);
				}
			} else {
				$scope.pricingModel.FreeDestinatorName = pricingModel.DestinatorName;
				$scope.pricingModel.DestinatorCity = pricingModel.DestinatorCity;
				$scope.pricingModel.DestinatorCity.DescriptionToShow = Vgr.util.getDescriptionToShow(
					pricingModel.DestinatorCity
				);
			}

			if (pricingModel.Generator) {
				$scope.pricingModel.Generator = pricingModel.Generator;
				if (pricingModel.Generator.City) {
					$scope.pricingModel.Generator.City.DescriptionToShow = Vgr.util.getDescriptionToShow(
						pricingModel.Generator.City
					);
				}
			} else {
				$scope.pricingModel.FreeGeneratorName = pricingModel.GeneratorName;
				$scope.pricingModel.GeneratorCity = pricingModel.GeneratorCity;
				$scope.pricingModel.GeneratorCity.DescriptionToShow = Vgr.util.getDescriptionToShow(pricingModel.GeneratorCity);
			}
		}

		function getPricingData(id) {
			var deferred = $q.defer();

			$scope.loading = true;
			httpService.getDTOFromServiceV2(pricingService.getPricing, id).then(
				function (data) {
					setPricingModel(data.Pricing);
					$scope.loading = false;
					deferred.resolve();
				},
				function () {
					goBack();
				}
			);

			return deferred.promise;
		}

		this.$onInit = function () {
			$scope.pricingModel = {};
			listNegotiationTypes();
			listPricingTypes();
			if (isPricingEdit()) {
				$scope.pricingModel.Id = $stateParams.id;
				getPricingData($stateParams.id);
			} else if (isDuplicating()) {
				getPricingData($stateParams.duplicar).then(function () {
					setTodayDate();
				});
			} else {
				setInitParams();
			}
		};

		function setInitParams() {
			setTodayDate();
			$scope.pricingModel.PricingType = $scope.PricingTypes[0];
			$scope.pricingModel.NegotiationType = $scope.NegotiationTypes[0];
		}

		function setTodayDate() {
			$scope.pricingModel.Date = Vgr.util.getDate();
		}

		function listNegotiationTypes() {
			$scope.NegotiationTypes = pricingService.listNegotiationTypes();
		}

		function listPricingTypes() {
			$scope.PricingTypes = pricingService.listPricingTypes();
		}

		function validate() {
			if (
				!$scope.pricingModel.Residue ||
				!$scope.pricingModel.Date ||
				!$scope.pricingModel.NegotiationType ||
				!$scope.pricingModel.PricingType ||
				(!$scope.pricingModel.GeneratorCity && !$scope.pricingModel.Generator) ||
				(!$scope.pricingModel.DestinatorCity && !$scope.pricingModel.Destinator)
			) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.VALIDATION_ERROR_MESSAGE);
				return false;
			}

			return true;
		}

		function createPricing() {
			var deferred = $q.defer();
			httpService.postDTOToServiceV2(pricingService.createPricing, getCreatePricingDTO()).then(
				function (data) {
					$state.go("pricingList");
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);
			return deferred.promise;
		}

		function updatePricing() {
			var deferred = $q.defer();
			httpService.postDTOToServiceV2(pricingService.updatePricing, getUpdatePricingDTO()).then(
				function (data) {
					$state.go("pricingList");
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);
			return deferred.promise;
		}
	}
]);
