angular.module("vgresiduos").controller("MeasureUnitCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"measureUnitService",
	function ($scope, $rootScope, $q, httpService, measureUnitService) {
		"use strict";

		$scope.labels = $rootScope.labels;
		var isListLoaded;

		this.$onInit = function () {
			setInitialVariables();
			if ($scope.$ctrl.model) {
				formatDisplayText($scope.$ctrl.model);
			}
			loadMeasureUnits().then(setMeasureUnit);
		};

		function formatDisplayText(model) {
			if ($scope.$ctrl.isCore) {
				model.descriptionFormated = model.description + " (" + model.abbreviation + ")";
			} else {
				model.descriptionFormated = model.Name + " (" + model.Abbreviation + ")";
			}
		}

		function setInitialVariables() {
			if ($scope.$ctrl.isCore) {
				$scope.idField = "id";
			} else {
				$scope.idField = "Id";
			}

			$scope.fieldLabel = $scope.labels.MEASURE_UNIT;
			if ($scope.$ctrl.isRequired) {
				$scope.fieldLabel += "*";
			}
		}

		function loadMeasureUnits() {
			var deferred = $q.defer();

			if (isListLoaded) {
				deferred.resolve();
				return deferred.promise;
			}

			var dto = {
				onlyResidueUnits: $scope.$ctrl.onlyResidueUnits ? $scope.$ctrl.onlyResidueUnits : false,
				onlyPricingUnits: $scope.$ctrl.onlyPricingUnits ? $scope.$ctrl.onlyPricingUnits : false
			};

			httpService.getListFromServiceV2(measureUnitService.listMeasureUnits, dto, "MeasureUnits").then(
				function (list) {
					if ($scope.$ctrl.measureUnitGroup) {
						list = filterByMeasureUnitGroup(list);
					}

					list = $scope.$ctrl.isCore ? mapToCore(list) : list;
					list.forEach((element) => {
						formatDisplayText(element);
					});

					$scope.measureUnits = list;

					isListLoaded = true;
					deferred.resolve();
				},
				function (data) {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function mapToCore(list) {
			return list.map((item) => {
				return Vgr.dtoMappings.measureUnit.fromV2ToCore(item);
			});
		}

		function filterByMeasureUnitGroup(list) {
			return list.filter(function (item) {
				return item.Group == $scope.$ctrl.measureUnitGroup;
			});
		}

		function setMeasureUnit() {
			if (!$scope.$ctrl.setterId) {
				$scope.$ctrl.model = "";
				return;
			}

			var foundMeasureUnit = $scope.measureUnits.find(
				(measureUnit) => measureUnit[$scope.idField] == $scope.$ctrl.setterId
			);
			if (foundMeasureUnit != null) {
				if ($scope.$ctrl.onlySameGroup) {
					$scope.measureUnits = $scope.measureUnits.filter(function (item) {
						return item.Group == foundMeasureUnit.Group;
					});
				}
				$scope.$ctrl.model = foundMeasureUnit;
				$scope.onChange(foundMeasureUnit);
			}
		}

		$scope.onChange = function (model) {
			if ($scope.$ctrl.onChange) {
				$scope.$ctrl.onChange(model, $scope.$ctrl.identifier);
			}
		};

		$scope.$watch("$ctrl.setterId", function (newValue, oldValue) {
			var modelId = $scope.$ctrl.model ? $scope.$ctrl.model[$scope.idField] : null;

			if (newValue != modelId) {
				loadMeasureUnits().then(setMeasureUnit);
			}
		});
	}
]);
