angular.module("vgresiduos").component("goalEditHeader", {
	controller: "GoalEditHeaderCtrl",
	templateUrl: "views/goals/edit/components/goalEditHeader/goalEditHeader_template.html",
	bindings: {
		year: "<",
		residue: "<",
		measureUnitId: "<?",
		onMeasureUnitChange: "<",
		onIsRevenueChange: "<"
	}
});
