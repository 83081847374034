angular.module("vgresiduos").controller("ActionPlanItemCtrl", [
	"$rootScope",
	"$scope",
	"dialogService",
	"supplierService",
	"accountService",
	function ($rootScope, $scope, dialogService, supplierService, accountService) {
		"use strict";

		const suppliersCanEditActionPlan = false;
		let hasDocumentCreatePermission = false;
		$scope.executeEnabled = false;

		this.$onInit = function () {
			configureActions();
		};

		$scope.labels = $rootScope.labels;

		$scope.onDelete = function () {
			if ($scope.ctrl.onDelete) {
				$scope.ctrl.onDelete($scope.ctrl.identifier);
			}
		};

		$scope.hasContent = function () {
			return $scope.ctrl.item.comments || $scope.ctrl.item.actionPlanItemAttachments.length;
		};

		$scope.openAttachmentPopup = function () {
			const params = {
				attachmentList: $scope.ctrl.item.actionPlanItemAttachments,
				attachmentPath: "ActionPlans/" + $scope.ctrl.actionPlan.id + "/Items/" + $scope.ctrl.item.id,
				observations: $scope.ctrl.item.comments,
				observationsDescription: null,
				isEditMode: $scope.executeEnabled,
				hasDocumentCreatePermission: hasDocumentCreatePermission,
				supplierId: null,
				supplierCode: null,
				documentIds: null,
				documentsEnabled: false
			};

			dialogService
				.showDialogFromTemplateV2(
					"views/components/popups/attachmentPopup_template.html",
					"AttachmentPopupCtrl",
					null,
					params,
					true
				)
				.then(
					function (data) {
						$scope.ctrl.item.actionPlanItemAttachments = data.attachmentList;
						$scope.ctrl.item.comments = data.observations;
					},
					function () {}
				);
		};

		function configureActions() {
			const isStatusCreated = $scope.ctrl.actionPlan.status == Vgr.enumerations.actionPlan.Status.Created;
			if (isStatusCreated) {
				$scope.editFieldsEnabled = true;
				if (!$scope.ctrl.item.modelId && !$scope.ctrl.item.isMandatory) {
					$scope.deleteEnabled = true;
				}
			}

			if (hasExecutePermission()) {
				$scope.executeEnabled = true;
			}
			hasDocumentCreatePermission = supplierService.hasSupplierPermission(
				$scope.ctrl.supplier,
				Vgr.systemPermissions.SupplierOrganizationDocument,
				Vgr.systemPermissions.SupplierDocument
			);
		}

		$scope.getEditObservationsLabel = function () {
			let label = !$scope.executeEnabled ? $scope.labels.SEE_ATTACHMENT : $scope.labels.EDIT_ATTACHMENT;
			if ($scope.ctrl.item.executed && !$scope.hasContent()) {
				label += "*";
			}
			return label;
		};

		function hasExecutePermission() {
			const isStatusPending = $scope.ctrl.actionPlan.status == Vgr.enumerations.actionPlan.Status.Pending;
			if (isStatusPending) {
				if (suppliersCanEditActionPlan && isAuditedSupplier()) {
					return true;
				}
				if (isOwner()) {
					return supplierService.hasSupplierPermission(
						$scope.ctrl.supplier,
						Vgr.systemPermissions.SupplierOrganizationActionPlan,
						Vgr.systemPermissions.SupplierActionPlan
					);
				}
			}
			return false;
		}

		function isOwner() {
			return $scope.ctrl.actionPlan.organization.id == accountService.getCurrentClient().organization.id;
		}

		function isAuditedSupplier() {
			return $scope.ctrl.supplierId == accountService.getCurrentClient().id;
		}

		$scope.isDisabledCheckBoxExecuted = function () {
			return !$scope.executeEnabled || (!$scope.changeCheckBoxExecuted && $scope.ctrl.item.executed);
		};
		$scope.onChangeExecuted = function () {
			$scope.changeCheckBoxExecuted = true;
		};
	}
]);
