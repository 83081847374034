angular.module("vgresiduos").controller("CustomizedFilterMultiFilterCtrl", [
	"$rootScope",
	"$scope",
	"reportUtilService",
	function ($rootScope, $scope, reportUtilService) {
		"use strict";
		$scope.labels = $rootScope.labels;

		$scope.filterOptions = [];

		this.$onInit = function () {
			initAvailableFields($scope.$ctrl.list);
			setValues($scope.$ctrl.setter);
		};

		function initAvailableFields(list) {
			$scope.availableFields = [];

			for (const item of list) {
				const optionField = buildOptionField(item);
				$scope.availableFields.push(optionField);
			}
		}

		function buildOptionField(filterOption) {
			const filter = filterOption.id;
			if (filter == reportUtilService.filterIds.GenerationProperty) {
				return buildAdditionalPropertyOptionField(
					filterOption,
					Vgr.enumerations.additionalProperty.process.InternalGathering
				);
			} else if (filter == reportUtilService.filterIds.AreaProperty) {
				return buildAdditionalPropertyOptionField(filterOption, Vgr.enumerations.additionalProperty.process.Area);
			} else if (filter == reportUtilService.filterIds.OrganizationUnitProperty) {
				return buildAdditionalPropertyOptionField(
					filterOption,
					Vgr.enumerations.additionalProperty.process.OrganizationUnit
				);
			} else if (filter == reportUtilService.filterIds.DisposalProperty) {
				return buildAdditionalPropertyOptionField(filterOption, Vgr.enumerations.additionalProperty.process.Disposal);
			} else {
				return {
					id: filterOption.id,
					description: filterOption.label,
					category: filterOption.category,

					type: Vgr.enumerations.customizedFilterV2.optionField.type.common,

					isEnabled: true,
					isOptionEnabled: function () {
						return this.isEnabled;
					}
				};
			}
		}

		function buildAdditionalPropertyOptionField(filterOption, process) {
			return {
				id: filterOption.id,
				description: filterOption.label,
				category: filterOption.category,

				type: Vgr.enumerations.customizedFilterV2.optionField.type.additionalProperty,

				displayProperty: $scope.labels.PROPERTY,
				propertyProcess: process,

				isOptionEnabled: function () {
					return true;
				}
			};
		}

		function setValues(values) {
			if (values && values.length > 0) {
				setValueFields(values);
			} else {
				addNewEmptyOption();
			}
		}

		function setValueFields(values) {
			for (const value of values) {
				const filterOption = addNewEmptyOption();
				filterOption.optionSetter = {
					optionId: value.id,
					additionalPropertyId: value.additionalPropertyId
				};
				filterOption.valueSetter = value.values;
			}
		}

		function addNewEmptyOption() {
			const filterOption = {
				id: Vgr.util.uuid(),
				optionSetter: null,
				valueSetter: null,
				selectedOption: null
			};

			filterOption.callbackOption = function (selectedOption) {
				onChangeOptionField(selectedOption, filterOption);
			};

			filterOption.callbackValue = function (selectedValue) {
				onChangeValueField(selectedValue, filterOption);
			};

			$scope.filterOptions.push(filterOption);
			return filterOption;
		}

		function onChangeOptionField(selectedOption, filterOption) {
			if (filterOption.selectedOption) {
				updateAvailableOption(filterOption.selectedOption.item.id, true);
			}
			if (selectedOption) {
				updateAvailableOption(selectedOption.item.id, false);
			}

			filterOption.selectedOption = selectedOption;

			callback();
		}

		function onChangeValueField(selectedValue, filterOption) {
			filterOption.selectedValue = selectedValue;

			callback();
		}

		function updateAvailableOption(optionId, enable) {
			const option = $scope.availableFields.filter(function (item) {
				return item.id === optionId;
			})[0];

			option.isEnabled = enable;
		}

		$scope.onClickAddFilter = function () {
			addNewEmptyOption();
		};

		$scope.onRemoveOption = function (index) {
			if ($scope.filterOptions[index].selectedOption) {
				updateAvailableOption($scope.filterOptions[index].selectedOption.item.id, true);
			}

			if ($scope.filterOptions.length == 1) {
				$scope.filterOptions = [];
				addNewEmptyOption();
			} else {
				$scope.filterOptions.splice(index, 1);
			}

			callback();
		};

		$scope.disableAddButton = function () {
			const validOptions = $scope.filterOptions.filter(isValidOption);

			return validOptions.length != $scope.filterOptions.length;
		};

		this.$onChanges = function (obj) {
			if (obj.list && !obj.list.isFirstChange()) {
				onChangeList(obj.list.currentValue);
			}
			if (obj.clear) {
				if (obj.clear.currentValue) {
					onClearFilters();
				}
			}
		};

		function onChangeList(newList) {
			initAvailableFields(newList);
			removeFilters();
			setValues($scope.$ctrl.setter);
			callback();
		}

		function onClearFilters() {
			removeFilters();
			setValues();
			callback();
		}

		function removeFilters() {
			$scope.filterOptions = [];
		}

		function callback() {
			const selectedOptions = $scope.filterOptions.filter(isValidOption).map(function (item) {
				return {
					item: item.selectedOption.item,
					additionalProperty: item.selectedOption.additionalProperty,
					values: item.selectedValue
				};
			});

			$scope.$ctrl.callback(selectedOptions);
		}

		function isValidOption(item) {
			return (
				!!item.selectedOption &&
				!!item.selectedValue &&
				(!Array.isArray(item.selectedValue) || item.selectedValue.length > 0)
			);
		}
	}
]);
