angular.module("vgresiduos").controller("ScalingButtonsCtrl", [
	"$scope",
	"$rootScope",
	"scalingIntegrationService",
	"$q",
	"httpService",
	function ($scope, $rootScope, scalingIntegrationService, $q, httpService) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.calculatingWeight = false;
		var selectedScalingType = "";

		this.$onInit = function () {
			$scope.scalingOptions = [
				{
					title: $scope.labels.ONE_STEP_SCALING,
					isFontAwesome: true,
					usedIcon: "fa fa-balance-scale",
					method: function () {
						selectedScalingType = Vgr.enumerations.scaling.type.OneStep;
						$scope.$ctrl.onScalingTypeChange(selectedScalingType);
						$scope.enableTwoStepScaling = false;
						return $scope.onClickWeight(true);
					}
				}
			];

			if (hasTwoStepsScale()) {
				$scope.scalingOptions.push({
					title: $scope.labels.TWO_STEPS_SCALING,
					isFontAwesome: true,
					usedIcon: "fa fa-balance-scale",
					method: function () {
						var deferred = $q.defer();
						deferred.resolve();

						selectedScalingType = Vgr.enumerations.scaling.type.TwoSteps;
						$scope.$ctrl.onScalingTypeChange(selectedScalingType);
						$scope.$ctrl.onCallTareWeight();
						$scope.enableTwoStepScaling = true;

						return deferred.promise;
					}
				});
			} else {
				$scope.scalingOptions.push({
					title: $scope.labels.TWO_STEPS_SCALING_WITH_STORAGE,
					isFontAwesome: true,
					usedIcon: "fa fa-balance-scale",
					method: function () {
						var deferred = $q.defer();
						deferred.resolve();

						selectedScalingType = Vgr.enumerations.scaling.type.TwoStepsWithStorage;
						$scope.$ctrl.onScalingTypeChange(selectedScalingType);
						$scope.$ctrl.onCallTareWeight();
						$scope.enableTwoStepScaling = true;
						return deferred.promise;
					}
				});
			}

			configureScalingType();
		};

		function hasTwoStepsScale() {
			return getScaleByType(Vgr.enumerations.scaling.type.TwoSteps);
		}

		function configureScalingType() {
			if ($scope.hasOnlyOneScalingConfiguration()) {
				if (!selectedScalingType) {
					selectedScalingType = $scope.$ctrl.unitScalingConfigurations[0].ScalingType;
				}
			}
		}

		function getScaleByType(type) {
			if (!$scope.$ctrl.unitScalingConfigurations || $scope.$ctrl.unitScalingConfigurations.length == 0) {
				return null;
			}

			var scalesByType = $scope.$ctrl.unitScalingConfigurations.filter(function (scale) {
				return scale.ScalingType == type;
			});
			return scalesByType.length > 0 ? scalesByType[0] : null;
		}

		function readQuantityFromScale() {
			var scale = getScaleByType(selectedScalingType);
			$scope.calculatingWeight = true;
			var promise = httpService.getFromLocalServer(scalingIntegrationService.getWeight, scale);
			promise.then(
				function (weight) {
					$scope.calculatingWeight = false;
					$scope.$ctrl.onTotalWeightChanged(weight);
				},
				function () {
					$scope.calculatingWeight = false;
				}
			);
			return promise;
		}

		$scope.onClickToggleTwoSteps = function () {
			$scope.enableTwoStepScaling = !$scope.enableTwoStepScaling;
			if ($scope.enableTwoStepScaling) {
				$scope.$ctrl.onCallTareWeight();
			}
			$scope.$ctrl.onToggleTwoStepsScaling();
		};

		$scope.onClickWeight = function (readAutomaticallyFromBalance) {
			var promise;
			if (readAutomaticallyFromBalance) {
				promise = readQuantityFromScale();
			}
			configureScalingType();
			$scope.$ctrl.onWeightButtonClick();
			return promise;
		};

		$scope.hasOnlyOneScalingConfiguration = function () {
			return $scope.$ctrl.unitScalingConfigurations.length == 1;
		};

		this.$onChanges = function (obj) {
			if (
				obj.setterEnableTwoStepScaling &&
				obj.setterEnableTwoStepScaling.currentValue != obj.setterEnableTwoStepScaling.previousValue
			) {
				$scope.enableTwoStepScaling = obj.setterEnableTwoStepScaling.currentValue;
			}

			if (
				obj.setterSelectedScalingType &&
				obj.setterSelectedScalingType.currentValue != obj.setterSelectedScalingType.previousValue
			) {
				selectedScalingType = obj.setterSelectedScalingType.currentValue;
			}
		};

		$scope.isTwoStepsScalingEnabled = function () {
			return !$scope.isOneStepScaling() && $scope.enableTwoStepScaling;
		};

		$scope.isOneStepScaling = function () {
			return selectedScalingType == Vgr.enumerations.scaling.type.OneStep;
		};

		$scope.onClickClearButton = function () {
			$scope.enableTwoStepScaling = false;
			if (!$scope.hasOnlyOneScalingConfiguration()) {
				selectedScalingType = "";
			}
			$scope.$ctrl.onClickClearButton();
		};

		$scope.showTwoStepsToggleButton = function () {
			return !$scope.$ctrl.isDisabled && !$scope.showClearButton() && !$scope.isOneStepScaling() && selectedScalingType;
		};

		$scope.showClearButton = function () {
			return !$scope.$ctrl.isDisabled && $scope.isTwoStepsScalingEnabled();
		};

		$scope.showWeightButton = function () {
			return !$scope.$ctrl.isDisabled && $scope.isOneStepScaling() && $scope.hasOnlyOneScalingConfiguration();
		};

		$scope.showMultipleOptionsDropdown = function () {
			return (
				!$scope.$ctrl.isDisabled &&
				!$scope.hasOnlyOneScalingConfiguration() &&
				selectedScalingType != Vgr.enumerations.scaling.type.TwoSteps &&
				selectedScalingType != Vgr.enumerations.scaling.type.TwoStepsWithStorage
			);
		};

		$scope.getMinimizeButtonLabel = function () {
			if ($scope.enableTwoStepScaling) {
				return $scope.labels.MINIMIZE;
			} else {
				return $scope.$ctrl.scalingButtonLabel;
			}
		};

		$scope.isQuantityInputDisabled = function () {
			return $scope.isTwoStepsScalingEnabled();
		};
	}
]);
