angular.module("vgresiduos").controller("expenseChartsCtrl", [
	"$rootScope",
	"$scope",
	"dashboardService",
	"accountService",
	function ($rootScope, $scope, dashboardService, accountService) {
		"use strict";
		const seriesColors = {};
		let availableColors = angular.copy(Vgr.constants.chartColors);
		availableColors.reverse();
		$scope.currentClient = accountService.getCurrentClient();
		$scope.labels = $rootScope.labels;
		const labels = $rootScope.labels;

		$scope.totalSpending = null;
		$scope.totalSpendingValue = 0;
		$scope.lastMonthSpendingValue = 0;
		$scope.evolutionPercentage = 0;

		this.$onInit = function () {
			this.loading = $scope.ctrl.options.loading;

			$scope.formatCurrency = function (value) {
				return Vgr.util.formatCurrency(value, "R$");
			};

			$scope.groupingList = [
				{
					code: "RESIDUE",
					description: labels.RESIDUE
				},
				{
					code: "SUPPLIER",
					description: labels.SUPPLIER
				},
				{
					code: "AREA",
					description: labels.AREA
				}
			];
			$scope.grouping = {
				code: "RESIDUE",
				description: labels.RESIDUE
			};

			$scope.legendGroupingLabel = $scope.grouping.description;
			$scope.groupingChanged = function (group) {
				const dto = {
					Grouping: group,
					DataType: $scope.dataType.code,
					Month: $scope.month.code
				};
				loadExpenseManagement(dto);
			};

			$scope.dataTypeList = [
				{
					code: "REVENUE",
					description: labels.INCOME
				},
				{
					code: "EXPENSE",
					description: labels.EXPENSE
				},
				{
					code: "TOTAL",
					description: labels.BALANCE
				}
			];
			$scope.dataType = {
				code: "EXPENSE",
				description: labels.EXPENSE
			};
			$scope.dataTypeChanged = function (dataType) {
				const dto = {
					Grouping: $scope.grouping.code,
					DataType: dataType,
					Month: $scope.month.code
				};
				loadExpenseManagement(dto);
			};

			$scope.monthList = [
				{
					code: moment().month() + 1,
					description: moment.months()[moment().month()] + "/" + moment().year()
				},
				{
					code: moment().add(-1, "M").month() + 1,
					description: moment.months()[moment().add(-1, "M").month()] + "/" + moment().add(-1, "M").year()
				},
				{
					code: moment().add(-2, "M").month() + 1,
					description: moment.months()[moment().add(-2, "M").month()] + "/" + moment().add(-2, "M").year()
				},
				{
					code: moment().add(-3, "M").month() + 1,
					description: moment.months()[moment().add(-3, "M").month()] + "/" + moment().add(-3, "M").year()
				},
				{
					code: moment().add(-4, "M").month() + 1,
					description: moment.months()[moment().add(-4, "M").month()] + "/" + moment().add(-4, "M").year()
				},
				{
					code: moment().add(-5, "M").month() + 1,
					description: moment.months()[moment().add(-5, "M").month()] + "/" + moment().add(-5, "M").year()
				},
				{
					code: moment().add(-6, "M").month() + 1,
					description: moment.months()[moment().add(-6, "M").month()] + "/" + moment().add(-6, "M").year()
				},
				{
					code: moment().add(-7, "M").month() + 1,
					description: moment.months()[moment().add(-7, "M").month()] + "/" + moment().add(-7, "M").year()
				},
				{
					code: moment().add(-8, "M").month() + 1,
					description: moment.months()[moment().add(-8, "M").month()] + "/" + moment().add(-8, "M").year()
				},
				{
					code: moment().add(-9, "M").month() + 1,
					description: moment.months()[moment().add(-9, "M").month()] + "/" + moment().add(-9, "M").year()
				},
				{
					code: moment().add(-10, "M").month() + 1,
					description: moment.months()[moment().add(-10, "M").month()] + "/" + moment().add(-10, "M").year()
				},
				{
					code: moment().add(-11, "M").month() + 1,
					description: moment.months()[moment().add(-11, "M").month()] + "/" + moment().add(-11, "M").year()
				}
			];
			$scope.month = {
				code: moment().month() + 1,
				description: moment.months()[moment().month()]
			};
			$scope.monthChanged = function (month) {
				const dto = {
					Grouping: $scope.grouping.code,
					DataType: $scope.dataType.code,
					Month: month
				};
				loadExpenseManagement(dto);
			};

			$scope.getColor = function getColor(serie) {
				if (seriesColors[serie]) {
					return seriesColors[serie];
				}
				seriesColors[serie] = availableColors.pop();
				return seriesColors[serie];
			};

			if (!$scope.isSupplier) {
				$scope.summaryChart = {
					title: $rootScope.labels.EXPENSE_SUMMARY,
					loading: true
				};
				$scope.managementChart = {
					title: $rootScope.labels.EXPENSE_MANAGEMENT,
					loading: true
				};
			}

			loadExpenseSummary();
			loadExpenseManagement();
		};

		function loadExpenseSummary() {
			if ($scope.isSupplier) {
				return;
			}
			dashboardService.getExpenseSummary().then(
				function (response) {
					if (!response.data.success) {
						return;
					}
					const list = Vgr.util.extractList(response.data.content.list);
					if (list.length === 0) {
						$scope.summaryChart.noData = true;
						$scope.summaryChart.loading = false;
					} else {
						const dataValues = [];
						list.forEach(function (reportColumn) {
							dataValues.push(Number(reportColumn.Valor).toFixed(2));
						});

						calculateExpenseSummaryTotals(dataValues);

						const data = [
							[dataValues[0], dataValues[2], dataValues[4]],
							[dataValues[1], dataValues[3], dataValues[5]]
						];
						const financialChartLabels = [$scope.labels.LAST_MONTH, $scope.labels.MONTH_SUM, $scope.labels.PREVIEW];
						const series = [$scope.labels.INCOME, $scope.labels.EXPENSE];
						const financialChartColors = ["#46BFBD", "#F7464A"];
						$scope.summaryChart = new Vgr.constructors.Chart(
							$scope.summaryChart.title,
							"bar",
							series,
							financialChartLabels,
							data,
							financialChartColors
						);
						$scope.summaryChart.name = "ExpenseSummary";
					}
				},
				function () {
					$scope.summaryChart.errorLoading = true;
					$scope.summaryChart.loading = false;
				}
			);
		}

		function calculateExpenseSummaryTotals(dataValues) {
			if (dataValues && dataValues[2] && dataValues[3]) {
				$scope.totalSpendingValue = dataValues[2] - dataValues[3];
				$scope.totalSpending = $scope.formatCurrency($scope.totalSpendingValue);
			}

			if (dataValues && dataValues[0] && dataValues[1]) {
				$scope.lastMonthSpendingValue = dataValues[0] - dataValues[1];
			}

			if ($scope.lastMonthSpendingValue != 0) {
				$scope.evolutionPercentage =
					Vgr.util.formatUnitDecimalPlaces(
						(($scope.totalSpendingValue - $scope.lastMonthSpendingValue) * 100) /
							Math.abs($scope.lastMonthSpendingValue),
						2
					) + "%";
			}
		}

		function loadExpenseManagement(dto) {
			if (!$scope.isSupplier) {
				if (dto == null) {
					dto = {
						Grouping: $scope.grouping.code,
						DataType: $scope.dataType.code,
						Month: $scope.month.code
					};
				}
				$scope.managementChart.loading = true;
				dashboardService.getExpenseManagement(dto).then(
					function (response) {
						if (response.data.success) {
							const list = Vgr.util.extractList(response.data.content.list);
							$scope.managementList = list;
							$scope.managementChart.name = "ExpenseManagement";
							if (list.length === 0) {
								$scope.managementChart.noData = true;
								$scope.managementChart.loading = false;
							} else {
								$scope.legendGroupingLabel = $scope.labels[dto.Grouping];
								const expenseManagementLabels = getSeries(list);
								const data = [];
								list.forEach(function (reportColumn) {
									reportColumn.Color = availableColors.pop();
									data.push(Number(reportColumn.Valor).toFixed(2));
								});

								$scope.managementChart = new Vgr.constructors.Chart(
									$scope.managementChart.title,
									"doughnut",
									null,
									expenseManagementLabels,
									data
								);

								resetColors();
							}
						}
					},
					function () {
						$scope.managementChart.errorLoading = true;
						$scope.managementChart.loading = false;
					}
				);
			}
		}

		function resetColors() {
			availableColors = angular.copy(Vgr.constants.chartColors);
			availableColors.reverse();
		}

		function getSeries(list) {
			const series = [];
			list.forEach(function (reportColumn) {
				if (series.indexOf(reportColumn.Serie) === -1) {
					series.push(reportColumn.Serie);
				}
			});
			return series;
		}
	}
]);
