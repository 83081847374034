angular.module("vgresiduos").component("periodField", {
	controller: "PeriodFieldCtrl",
	templateUrl: "views/components/fields/periodField/periodField_template.html",
	bindings: {
		referenceYear: "<",

		year: "=",
		beginDate: "=",
		endDate: "="
	}
});
