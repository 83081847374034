angular.module("componentes").controller("ChangeTemporaryPasswordFormCtrl", [
	"$scope",
	"$rootScope",
	"accountService",
	function ($scope, $rootScope, accountService) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.TOSUrl = Vgr.util.getTermsAndConditionsUrl();
		$scope.privacyPolicyUrl = Vgr.util.getPrivacyPolicyUrl();

		$scope.account = {
			name: null,
			email: null,
			password: null,
			passwordConfirm: null
		};

		this.$onInit = function () {
			const challengeParams = accountService.getIdentityProviderCurrentChallengeParams();
			if (challengeParams) {
				$scope.account.name = challengeParams.name;
				$scope.account.email = challengeParams.email;
			}

			if (!$scope.account.email) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.DO_LOGIN_FIRST);
			}
		};

		$scope.onClickConfirm = function () {
			$scope.hasChecked = true;
			$scope.loading = true;

			if (!validate()) {
				$scope.loading = false;
				return;
			}

			accountService.changeTemporaryPassword($scope.account.password, getIdpModel()).then(
				function () {
					onChangePasswordSuccess();
				},
				function (response) {
					onChangePasswordError(response);
				}
			);
		};

		function validate() {
			if (!$scope.account.name || !$scope.account.password || !$scope.account.passwordConfirm) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.VALIDATION_ERROR_MESSAGE);
				return false;
			}
			if ($scope.account.password != $scope.account.passwordConfirm) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.PASSWORDS_DONT_MATCH);
				return false;
			}

			return true;
		}

		function getIdpModel() {
			return {
				name: $scope.account.name
			};
		}

		function onChangePasswordSuccess() {
			$scope.loading = false;
			accountService.setLoggingIn();
			window.location.href = Common.constants.vgrUrl + "/autenticacao";
		}

		function onChangePasswordError(response) {
			let message = response ? $rootScope.labels.AUTH_ERRORS[response.code] : "";
			if (!message) {
				message = $rootScope.labels.AUTH_ERRORS.DefaultError;
			}

			if (response && response.code == Vgr.constants.authentication.errors.UserNotConfirmedException) {
				$rootScope.$broadcast(
					Vgr.constants.evtShowErrorMessage,
					$rootScope.labels.AUTH_ERRORS.UserNotConfirmedException
				);
			} else if (response && response.code == Vgr.constants.authentication.errors.InvalidPasswordException) {
				$rootScope.$broadcast(
					Vgr.constants.evtShowErrorMessage,
					$rootScope.labels.AUTH_ERRORS.InvalidPasswordException
				);
			} else if (response && response.code == Vgr.constants.authentication.errors.InvalidParameterException) {
				$rootScope.$broadcast(
					Vgr.constants.evtShowErrorMessage,
					$rootScope.labels.AUTH_ERRORS.InvalidParameterException
				);
			} else if (response && response.code == Vgr.constants.authentication.errors.NotAuthorizedException) {
				if (response.message.indexOf(Vgr.constants.authentication.errorMessages.SessionExpired)) {
					$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.AUTH_ERRORS.SessionExpired);
					accountService.goToLogin();
				} else {
					$rootScope.$broadcast(
						Vgr.constants.evtShowErrorMessage,
						$rootScope.labels.AUTH_ERRORS.NotAuthorizedException
					);
				}
			} else {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, message);
			}

			$scope.loading = false;
		}
	}
]);
