angular.module("componentes").controller("ForgottenPasswordFormCtrl", [
	"$scope",
	"$rootScope",
	"$state",
	"httpService",
	"accountService",
	function ($scope, $rootScope, $state, httpService, accountService) {
		"use strict";

		$scope.checkEmail = false;

		$scope.labels = $rootScope.labels;

		$scope.needToChangePassword = $rootScope.passwordResetRequired;
		if ($scope.needToChangePassword) {
			$rootScope.passwordResetRequired = "";
		}

		$scope.toLogin = function () {
			accountService.goToLogin();
		};

		$scope.account = {
			email: null
		};

		$scope.sendResetPasswordEmail = function () {
			$scope.loading = true;
			if (validate()) {
				httpService.postDTOToServiceV2(accountService.forgotPassword, $scope.account.email).then(
					function (response) {
						if (response && response.returnType == Vgr.constants.authentication.forgotPasswordReturnTypes.newPassword) {
							accountService.goToLogin($scope.account.email);
						} else {
							$rootScope.usernameConfirming = $scope.account.email;
							$state.go("resetPassword");
						}

						$scope.loading = false;
					},
					function (err) {
						onForgotPasswordError(err);
					}
				);
			} else {
				$scope.loading = false;
			}
		};

		function onForgotPasswordError(err) {
			if (err && err.data && err.data.length > 0 && err.data[0].code == "ERR-ACC-0010") {
				$scope.toLogin();
			}

			$scope.loading = false;
		}

		$scope.getLabel = function () {
			return $scope.needToChangePassword ? $scope.labels.RESET_PASSWORD_REQUIRED : $scope.labels.FORGOT_YOUR_PASSWORD;
		};

		function validate() {
			if (!$scope.account.email) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.EMAIL_MISSING);
				return false;
			}

			return true;
		}
	}
]);
