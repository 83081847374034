angular.module("vgresiduos").controller("createDisposalFromModelCtrl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `disposal-models/${$stateParams.id}/new-disposal`);
		};
	}
]);
