angular.module("vgresiduos").controller("clientStoragePreferencesCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"clientStoragePreferenceService",
	function ($scope, $rootScope, $q, clientStoragePreferenceService) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.model = {};

		const watchTab = $scope.$watch("clientStoragePreferencesTabSelected", watchTabSelected);

		this.$onInit = function () {
			$scope.$on(Vgr.constants.evtIframeOnSuccessCallBack, function () {
				resetStoragePreferencesCache();
			});
		};

		function save() {
			const deferred = $q.defer();
			document.getElementById("portalAngular").contentWindow.postMessage(
				{
					type: Vgr.enumerations.messageTypes.SAVE_STORAGE_PREFERENCES,
					payload: "url"
				},
				Vgr.constants.newAngularUrl
			);
			deferred.resolve();
			return deferred.promise;
		}

		function watchTabSelected(newValue) {
			if (newValue) {
				$scope.ctrl.saveOptions.save = save;
				$scope.ctrl.saveOptions.skipMessageSave = true;
				$scope.ctrl.saveOptions.validate = () => true;
			}
		}

		function resetStoragePreferencesCache() {
			clientStoragePreferenceService.purgeStoragePreferences();
		}

		$scope.$on("$destroy", function () {
			// disable the listener
			watchTab();

			// nullify the DOM-bound model
			$scope.domElement = null;
		});
	}
]);
