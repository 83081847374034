angular.module("vgresiduos").controller("IbamaRappCreateValidateCtrl", [
	"$rootScope",
	"$scope",
	"$q",
	"httpService",
	"ibamaIntegrationService",
	function ($rootScope, $scope, $q, httpService, ibamaIntegrationService) {
		"use strict";

		const labels = $rootScope.labels;

		function validateIbamaRapp() {
			const deferred = $q.defer();
			$scope.loadingFinished = false;

			httpService.getDTOFromServiceV2(ibamaIntegrationService.validateIbamaRapp, getValidateDto()).then(
				function (list) {
					$scope.ibamaRappResiduesValidationList = list;
					formatExtraFields();

					const isValid = !$scope.ibamaRappResiduesValidationList.length;
					setInformationMessage(isValid);
					$scope.loadingFinished = true;
					deferred.resolve($scope.ibamaRappResiduesValidationList);
					$scope.onValidate(isValid);
				},
				function () {
					$scope.loadingFinished = true;
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function formatExtraFields() {
			$scope.ibamaRappResiduesValidationList.forEach((validation) => {
				const translation = labels.IBAMA_INTEGRATION_VALIDATION[validation.Code];
				if (translation) {
					validation.ErrorFormated = translation;
				}
				validation.OrganizationUnitCodeAndName =
					validation.OrganizationUnitCode + " - " + validation.OrganizationUnitName;
			});
		}

		function getValidateDto() {
			return {
				Year: $scope.ctrl.model.year,
				OrganizationUnitCode: $scope.ctrl.model.unit.code
			};
		}

		function setInformationMessage(isValid) {
			if (isValid) {
				$scope.messageIcon = "check_circle";
				$scope.messageTitle = labels.OK_YOU_CAN_CONTINUE_INTEGRATION;
				$scope.messageStatus = Vgr.constants.generalStatus.success;
			} else {
				$scope.messageIcon = "error";
				$scope.messageTitle = labels.YOU_MUST_FIX_RESIDUES_TO_CONTINUE_INTEGRATION;
				$scope.messageStatus = Vgr.constants.generalStatus.error;
			}
		}

		$scope.onValidateGridRefresh = function () {
			return validateIbamaRapp();
		};
	}
]);
