angular.module("vgresiduos").directive("clientFormProduction", function () {
	return {
		scope: true,
		controller: "ClientFormProductionCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/client/components/clientForm/clientFormProduction/clientFormProduction_template.html",
		bindToController: {
			inputClient: "=",
			saveOptions: "="
		}
	};
});
