angular.module("vgresiduos").directive("featureToggleForm", function () {
	return {
		scope: true,
		controller: "FeatureToggleFormCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/master/featureToggles/form/featureToggleForm_template.html",
		bindToController: {
			model: "=",
			saveOptions: "=",
			isCreation: "="
		}
	};
});
