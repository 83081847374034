angular.module("vgresiduos").controller("DisposalTechMultiSelectV2Ctrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"disposalService",
	function ($scope, $rootScope, httpService, disposalService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			httpService.getListFromServiceCore(disposalService.listDisposalTypes, null).then(function (response) {
				$scope.disposalTechnologies = response.list.map((d) => {
					return {
						Id: d.id.toString(),
						Description: d.description,
						Ibama: d.ibama ? { Id: d.ibama.id, Description: d.ibama.description } : null,
						id: d.id.toString(),
						displayText: d.description
					};
				});
			});
		};

		$scope.onChanged = function (selectedValues) {
			callback(selectedValues);
		};

		function callback(selectedValues) {
			if ($scope.$ctrl.callback) {
				$scope.$ctrl.callback(selectedValues);
			}
		}
	}
]);
