angular.module("vgresiduos").controller("FeatureToggleFormCtrl", [
	"$scope",
	function ($scope) {
		"use strict";

		$scope.$watch("featureToggleForm.$valid", function (newVal) {
			$scope.ctrl.saveOptions.valid = newVal;
		});

		$scope.getIdLabel = function () {
			let label = $scope.labels.commonFields.ID;
			if ($scope.ctrl.isCreation) {
				return label;
			}
			return label + "*";
		};
	}
]);
