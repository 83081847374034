angular.module("componentes").directive("scrolly", [
	"$timeout",
	function ($timeout) {
		"use strict";
		return {
			restrict: "A",
			link: function (scope, element, attrs) {
				var raw = element[0];
				var tolerance = 20;
				element.bind("scroll", function () {
					if (raw.scrollTop + raw.offsetHeight >= raw.scrollHeight - tolerance) {
						//at the bottom
						scope.$apply(attrs.scrolly);
					}
				});
			}
		};
	}
]);
