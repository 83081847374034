angular.module("vgresiduos").factory("supplierVehicleService", [
	"$http",
	"accountService",
	function ($http, accountService) {
		"use strict";

		function getSupplierVehicleBaseRequestUrl(supplierCode) {
			return (
				Vgr.constants.suppliersHostUrl +
				Vgr.resources.suppliers.supplierVehicles
					.replace("[ORGANIZATION]", accountService.getCurrentClient().organization.id)
					.replace("[SUPPLIER_CODE]", supplierCode)
			);
		}

		const _listSupplierVehicles = function (supplierCode) {
			const req = {
				method: "get",
				url: getSupplierVehicleBaseRequestUrl(supplierCode)
			};

			return $http(req);
		};

		const _getSupplierVehicle = function (dto) {
			const req = {
				method: "get",
				url: `${getSupplierVehicleBaseRequestUrl(dto.supplierCode)}/${dto.vehicleId}`
			};

			return $http(req);
		};

		return {
			listVehicles: _listSupplierVehicles,
			getVehicle: _getSupplierVehicle
		};
	}
]);
