angular.module("vgresiduos").controller("PhysicStateMultiSelectCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"residueService",
	function ($scope, $rootScope, httpService, residueService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			listPhysicState();
		};

		function listPhysicState() {
			httpService.getListFromService(residueService.getPhysicStateList).then(function (list) {
				list.forEach((element) => {
					element.id = element.ID.toString();
					element.displayText = element.Description;
				});
				$scope.physicState = list;
			});
		}

		$scope.onChanged = function (selectedValues) {
			callback(selectedValues);
		};

		function callback(selectedValues) {
			if ($scope.$ctrl.callback) {
				$scope.$ctrl.callback(selectedValues);
			}
		}
	}
]);
