angular.module("vgresiduos").controller("ProcessSelectFieldCtrl", [
	"$scope",
	"$q",
	"httpService",
	"processService",
	function ($scope, $q, httpService, processService) {
		"use strict";

		this.$onInit = function () {
			listProcess().then(setProcessId);
		};

		function setProcessId() {
			if (!$scope.$ctrl.processId) {
				return;
			}

			const process = $scope.processList.find((p) => p.id == $scope.$ctrl.processId);
			if (process != null) {
				$scope.$ctrl.model = process.id;
			}
		}

		function listProcess() {
			const deferred = $q.defer();

			httpService.getListFromServiceCore(processService.getProcessList).then(
				function (response) {
					$scope.processList = response.list;
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}
	}
]);
