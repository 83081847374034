angular.module("vgresiduos").factory("stockService", [
	"$http",
	function ($http) {
		"use strict";

		const _getResidueStock = function (dto) {
			let parameters = "";
			if (dto.model) {
				parameters = $.param(dto.model);
			}

			const req = {
				method: "get",
				url:
					Vgr.constants.newHostUrl + Vgr.resources.apiV1.default + "residue-stocks/" + dto.residueId + "?" + parameters
			};

			return $http(req);
		};

		return {
			getResidueStock: _getResidueStock
		};
	}
]);
