angular.module("vgresiduos").component("customReportFilter", {
	controller: "CustomReportFilterCtrl",
	templateUrl: "views/customReports/components/customReportFilter/customReportFilter_template.html",
	bindings: {
		reportType: "<?",
		clear: "<?",
		setter: "<?",
		callback: "<",

		closeFilters: "=?"
	}
});
