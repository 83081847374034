angular.module("vgresiduos").directive("auditDashboardItemClassification", function () {
	return {
		scope: true,
		controller: "AuditDashboardItemClassificationCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/suppliers/audits/dashboard/auditDashboardItemClassification_template.html",
		bindToController: {
			audit: "="
		}
	};
});
