angular.module("vgresiduos").directive("ibamaRappResiduesGrid", function () {
	return {
		scope: true,
		controller: "IbamaRappResiduesGridCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/ibama/ibamaRappFollowup/grid/ibamaRappResiduesGrid_template.html",
		bindToController: {
			list: "="
		}
	};
});
