angular.module("vgresiduos").controller("RelativeKpisCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"$state",
	"$timeout",
	"reportService",
	"httpService",
	"reportUtilService",
	"engagementScoreService",
	function (
		$scope,
		$rootScope,
		$q,
		$state,
		$timeout,
		reportService,
		httpService,
		reportUtilService,
		engagementScoreService
	) {
		"use strict";

		let reportGenerationAlreadyTracked = false;

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;
			$scope.periodEnum = Vgr.enumerations.reportFilters.periodEnum;
			$rootScope.contentNavBar = {
				title: $rootScope.labels.REPORTS,
				breadcrumb: $rootScope.labels.RELATIVE_KEY_PERFORMANCE_INDICATORS,
				back: function () {
					$state.go("customReport");
				},
				valid: true,
				customButtons: [
					{
						label: $scope.labels.EXPORT_CSV,
						buttonClasses: "md-primary",
						supressToast: true,
						onClick: function () {
							const deferred = $q.defer();
							$timeout(function () {
								angular.element("#hiddenCsvExportBtn").click();
							});
							deferred.resolve();
							return deferred.promise;
						}
					}
				],
				buttons: []
			};

			$scope.isIndicatorSelected = function () {
				return $scope.model.kpi && $scope.model.relativeKpi;
			};

			$scope.cleanFilters = function () {
				resetModel();
			};

			$scope.applyFilters = function () {
				if (!validate()) {
					return;
				}

				loadSubtitles();

				$rootScope.contentNavBar.buttons = [];

				$scope.isLoading = true;

				$scope.hasGenerated = false;
				$scope.reportHeader = null;
				$scope.reportData = null;
				$scope.reportHeaderForChart = null;
				$scope.reportDataForChart = null;
				$scope.isLineChart = false;

				const dto = {
					DateRange: {
						StartDate: $scope.model.dateRange.beginDate,
						EndDate: $scope.model.dateRange.endDate,
						RangeGroup: $scope.model.periodGrouping
					},
					Groupings: reportUtilService.formatGroupingToDto($scope.model.kpi.grouping),
					Kpi: buildKpiDto($scope.model.kpi),
					RelativeKpi: buildKpiDto($scope.model.relativeKpi)
				};

				httpService.postDTOToServiceV2(reportService.calculateKpi, dto).then(
					function (report) {
						trackReportGeneration();
						report.Header.DataQualifiers = [report.Header.Qualifier];

						$scope.yAxisDecimals = $scope.model.kpi.id == Vgr.enumerations.kpiDataType.DisposalCost ? 2 : null;

						report.Data.forEach((row) => {
							row.DataQualifiers = [row.Qualifier];
						});

						$scope.ignoreTotal =
							$scope.model.relativeKpi && $scope.model.periodGrouping == $scope.periodEnum.GroupedByMonth;

						$scope.reportHeader = reportUtilService.getHeadersConcatenatedArrayFromDto(
							report.Header,
							$scope.ignoreTotal
						);
						$scope.reportData = reportUtilService.getDataQualifiersFromDto(report.Data);

						if ($scope.model.periodGrouping == $scope.periodEnum.GroupedByMonth || hasGroups(report.Header)) {
							if ($scope.model.periodGrouping == $scope.periodEnum.GroupedByMonth && hasGroups(report.Header)) {
								$scope.isLineChart = true;
							}
							$scope.reportHeaderForChart = angular.copy(report.Header);
							$scope.reportDataForChart = reportUtilService.getDataQualifiersFromDto(angular.copy(report.Data));
						}

						$rootScope.contentNavBar.buttons = [$scope.labels.EXPORT_CSV];

						$scope.isLoading = false;
						$scope.hasGenerated = true;
						$scope.hideFilters = true;
					},
					function () {
						$scope.isLoading = false;
					}
				);
			};

			$scope.isLineChartCase = function () {
				return $scope.isLineChart;
			};

			$scope.toggleFilters = function () {
				if (!$scope.hasGenerated) {
					return;
				}

				$scope.hideFilters = !$scope.hideFilters;
			};

			$scope.onGroupingChanged = function (grouping) {
				if ($scope.model.kpi) {
					$scope.model.kpi.grouping = grouping;
				}
			};

			$scope.hasData = function (data) {
				return data != null && data != "" && !angular.equals(data, {});
			};

			$scope.hasGrouping = function () {
				return $scope.reportHeader.Groups;
			};

			resetModel();
		};

		function resetModel() {
			$scope.model = {
				periodGrouping: $scope.periodEnum.GroupedByMonth,
				dateRange: {
					dateId: 2
				}
			};
		}

		function validate() {
			let filters = $scope.model.kpi.filterOptions.selectedFilters;
			filters = filters.concat($scope.model.relativeKpi.filterOptions.selectedFilters);

			for (const filter of filters) {
				if (!validOption(filter)) {
					$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.MANDATORY_FILL_ALL_FILTERS);
					return false;
				}
			}
			return true;
		}

		function validOption(filter) {
			if (filter.field) {
				if (!filter.value) {
					return false;
				} else if (Array.isArray(filter.value) && filter.value.length == 0) {
					return false;
				} else if (typeof filter.value === "object") {
					for (const property in filter.value) {
						if (property != "dateId" && !filter.value[property]) {
							return false;
						}
					}
				}
			}

			return true;
		}

		function buildKpiDto(kpi) {
			const dto = {
				DataType: kpi.id,
				Filters: reportUtilService.formatFilterToDto(kpi.filterOptions.selectedFilters),
				Preferences: {}
			};

			if (kpi.id == Vgr.enumerations.kpiDataType.DisposalCost) {
				dto.Preferences.Prices = kpi.costs.reduce((a, o) => (o.selected && a.push(o.value), a), []);
			} else if (kpi.measureUnit) {
				dto.Preferences.MeasureUnitPreference = kpi.measureUnit.measureUnitPreference;
				dto.Preferences.MeasureUnitId = kpi.measureUnit.measureUnit ? kpi.measureUnit.measureUnit.Id : null;
			}

			return dto;
		}

		function hasGroups(header) {
			return header.Groups && header.Groups.length;
		}

		function loadSubtitles() {
			$scope.subtitles = [];

			if (
				$scope.model.kpi.id == Vgr.enumerations.kpiDataType.DisposalCost ||
				$scope.model.relativeKpi.id == Vgr.enumerations.kpiDataType.DisposalCost
			) {
				$scope.subtitles.push($scope.labels.FINALCIAL_CHART_SUBTILE);
			}
		}

		function trackReportGeneration() {
			if (reportGenerationAlreadyTracked) {
				return;
			}

			reportGenerationAlreadyTracked = true;
			engagementScoreService.track(Vgr.trackings.bi.reports.generateRelativeKpi);
		}
	}
]);
