angular.module("vgresiduos").directive("organizationSupplierPreferences", function () {
	return {
		scope: true,
		controller: "organizationSupplierPreferencesCtrl",
		controllerAs: "ctrl",
		templateUrl:
			"views/organization/form/organizationSupplierPreferences/organizationSupplierPreferences_template.html",
		bindToController: {
			inputClient: "=",
			saveOptions: "="
		}
	};
});
