angular.module("vgresiduos").controller("DisposalTransporterCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"supplierService",
	"disposalService",
	"$timeout",
	"$state",
	"$window",
	function ($scope, $rootScope, $q, httpService, supplierService, disposalService, $timeout, $state, $window) {
		"use strict";

		const gatheringDateInputName = "gatheringDateInput";
		const transporterForm = "transporterForm";
		$scope.transporterFormScope = {};
		$scope.transporterFormError = {};

		const cnpjMask = "cnpj";
		const cpfMask = "cpf";

		$scope.isSetByName = false;
		$scope.isSetByCnpj = false;
		$scope.tookInfoFromDestinator = false;

		$scope.disposalValidations.Transporter = {
			Icon: "warning",
			Status: Vgr.constants.generalStatus.error,
			Title: $rootScope.labels.SUPPLIER_PENDENCIES.TRANSPORTER,
			TextButton: $rootScope.labels.ACCESS_SUPPLIER,
			Messages: []
		};

		$scope.listTransporterOptions = {
			pageSize: 30,
			pageNumber: 1,
			query: "",
			id: 0
		};

		$scope.subfieldList = [
			{
				item: "Id",
				label: $scope.labels.ID
			},
			{
				item: "CNPJ",
				label: $scope.labels.CNPJ
			},
			{
				item: "CityStateDescription",
				label: $scope.labels.CITY
			}
		];

		this.$onInit = function () {
			if ($scope.destinationModel.Transporter) {
				validateDisposalTransporter($scope.destinationModel.Transporter, $scope.destinationModel.GatheringDate);
			} else {
				validateGatheringDate($scope.destinationModel.GatheringDate);
			}
			setDocumentOptions($scope.destinationModel.Transporter);
		};

		$scope.listClientTransporters = function () {
			if (!$scope.listTransporterOptions.query) {
				$scope.listTransporterOptions.query = "";
			}

			const deferred = $q.defer();

			const params = {
				onlyTransporters: true,
				query: $scope.listTransporterOptions.query,
				skip: $scope.listTransporterOptions.pageNumber - 1,
				take: $scope.listTransporterOptions.pageSize,
				disposalResidues: $scope.destinationModel.Residues.map((r) => r.ResidueId)
			};

			httpService.getListFromServiceV2(supplierService.listSuppliers, params, "Suppliers").then(
				function (list) {
					formatTransporterCityAndCnpj(list);
					deferred.resolve(list);
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		};

		$scope.onSelectTakeInfoFromDestinator = function () {
			$scope.tookInfoFromDestinator = !$scope.tookInfoFromDestinator;
			if ($scope.tookInfoFromDestinator) {
				$scope.destinationModel.Transporter = $scope.destinationModel.Destinator;
			} else {
				$scope.destinationModel.Transporter = null;
				$scope.transporterSearchText = "";
			}

			validateDisposalTransporter($scope.destinationModel.Transporter, $scope.destinationModel.GatheringDate);
		};

		function formatTransporterCityAndCnpj(list) {
			for (const i in list) {
				if (!list[i].CNPJ) {
					list[i].CNPJ = "";
				}

				if (list[i].City) {
					list[i].CityStateDescription = list[i].City.Description + " - " + list[i].City.State.Abbreviation;
				} else {
					list[i].CityStateDescription = $scope.labels.CITY_NOT_REGISTERED;
				}
			}
		}

		$scope.onTransporterSelect = function (transporter) {
			if (transporter?.Id == $scope.destinationModel.Transporter?.Id) {
				return;
			}

			defineTransporterRelatedFields(transporter);
			setDocumentOptions(transporter);
			validateDisposalTransporter(transporter, $scope.destinationModel.GatheringDate);
		};

		function defineTransporterRelatedFields(transporter) {
			if (!transporter) {
				$scope.destinationModel.Driver = null;
				$scope.destinationModel.Vehicle = null;
			} else {
				if ($scope.destinationModel.Driver && transporter.Id != $scope.destinationModel.Driver.supplierId) {
					$scope.destinationModel.Driver = null;
				}
				if ($scope.destinationModel.Vehicle && transporter.Id != $scope.destinationModel.Vehicle.supplierId) {
					$scope.destinationModel.Vehicle = null;
				}
			}
		}

		function setDocumentOptions(transporter) {
			if (transporter && transporter.CNPJ) {
				if (transporter.CNPJ.toString().length == 14) {
					$scope.transporterDocumentLabel = $scope.labels.CNPJ;
					$scope.transporterDocumentMask = cnpjMask;
				} else if (transporter.CNPJ.toString().length == 11) {
					$scope.transporterDocumentLabel = $scope.labels.CPF;
					$scope.transporterDocumentMask = cpfMask;
				}
			} else {
				$scope.transporterDocumentLabel = $scope.labels.CNPJ;
				$scope.transporterDocumentMask = "";
			}
		}

		function getSlipDescription() {
			return $scope.destinationModel.Transporter ? $scope.destinationModel.Transporter.Name : null;
		}

		function updateSlipToSolicitation(valid, validationMessage) {
			const solicitationStage = $scope.stagesEnum.Solicitation;
			const transporterStep = $scope.isTemporaryStorage() ? $scope.stepsEnum.E : $scope.stepsEnum.D;

			$scope.updateSlip(transporterStep, getSlipDescription());
			if (valid) {
				$scope.enableStep($scope.stepsEnum.F);
			} else {
				$scope.enableBackToStep(transporterStep);
				$scope.stagesConfig[solicitationStage].Steps[$scope.stepsEnum.D].MiddleActionEnabled = false;
			}
			setCustomValidationMessage(validationMessage, $scope.stepsEnum.D, solicitationStage);
		}

		function updateSlipToGathering(valid, validationMessage) {
			$scope.updateSlip($scope.stepsEnum.A, getSlipDescription());
			if (valid) {
				$scope.destinationConfig.blockEnableNextStep = false;
				$scope.enableStep($scope.stepsEnum.F);
			} else {
				$scope.destinationConfig.blockEnableNextStep = true;
				$scope.enableBackToStep($scope.stepsEnum.A);
			}

			if (!$scope.model.disposalGatheringIsLocked) {
				setCustomValidationMessage(validationMessage, $scope.stepsEnum.A);
			}

			setTransporterDisableRelatedStep(valid);
		}

		function setTransporterDisableRelatedStep(valid) {
			if (valid && !$scope.destinationModel.Transporter) {
				$scope.stagesConfig[$scope.stagesEnum.Gathering].Steps[$scope.stepsEnum.B].IsDisabled = true;
				$scope.stagesConfig[$scope.stagesEnum.Gathering].Steps[$scope.stepsEnum.C].IsDisabled = true;
			} else {
				$scope.stagesConfig[$scope.stagesEnum.Gathering].Steps[$scope.stepsEnum.B].IsDisabled = false;
				$scope.stagesConfig[$scope.stagesEnum.Gathering].Steps[$scope.stepsEnum.C].IsDisabled = false;
			}
		}

		function calculateAsyncValidation(validations) {
			const transporterValidations = validations.filter((v) => hasTransporterValidationErrors(v) && v.IsToBlock);

			return {
				IsValid: transporterValidations.length == 0,
				Message: transporterValidations.map((v) => $scope.getCustomValidationMessage(v)).join("\n")
			};
		}

		function hasTransporterValidationErrors(validation) {
			return (
				validation.Type == Vgr.enumerations.validation.TransporterIsRequired ||
				validation.Type == Vgr.enumerations.validation.AuditTransporterLevelInvalid ||
				validation.Type == Vgr.enumerations.validation.AuditTransporterClassificationInvalid ||
				validation.Metadata?.some(
					(d) =>
						d.Key == Vgr.enumerations.destination.validations.suppliers.key &&
						d.Value == Vgr.enumerations.destination.validations.suppliers.atuation.Transporter
				)
			);
		}

		function validateAsync(transporter) {
			const deferrend = $q.defer();

			const dto = $scope.createValidateDto(transporter);
			httpService.getDTOFromServiceV2(disposalService.validateDisposal, dto).then(
				function (response) {
					$scope.updateValidationList(response.Validations);
					setTransporterValidationMessages(response.Validations);
					const validationObject = calculateAsyncValidation(response.Validations);
					deferrend.resolve(validationObject);
				},
				function () {
					deferrend.reject();
				}
			);

			return deferrend.promise;
		}

		function setTransporterValidationMessages(validations) {
			$scope.setValidationMessages(validations, [], "Transporter", "Transporter");
		}

		$scope.showTransporterValidationMessages = function () {
			return $scope.destinationModel.Transporter && $scope.disposalValidations.Transporter.Messages.length > 0;
		};

		function setCustomValidationMessage(message, step, stage) {
			if ($scope.isCurrentStageReview()) {
				return;
			}

			const stagePointer = stage !== undefined && stage !== null ? stage : $scope.stagesEnum.Gathering;
			const stepPointer = step ? step : $scope.destinationConfig.currentStepPointer;
			const customMessage = message ? message : "";
			if ($scope.stagesConfig[stagePointer].Steps[stepPointer]) {
				$scope.stagesConfig[stagePointer].Steps[stepPointer].CustomValidationMessage = customMessage;
			}
		}

		function validateTransporter(transporter) {
			const validation = {
				isValid: true,
				message: ""
			};

			const deferrend = $q.defer();
			if ($scope.isCurrentStageReview()) {
				$timeout(function () {
					updateSlipToSolicitation(true);
				});
				deferrend.resolve(validation);
				return deferrend.promise;
			}

			if (!$scope.canValidateDisposal()) {
				deferrend.resolve(validation);
				return deferrend.promise;
			}

			validateAsync(transporter).then(
				function (validationObject) {
					validation.isValid = validationObject.IsValid;
					validation.message = validationObject.Message;
					deferrend.resolve(validation);
				},
				function () {
					deferrend.reject();
				}
			);
			return deferrend.promise;
		}

		function validateGatheringDate(gatheringDate) {
			const validation = {
				isValid: true,
				message: ""
			};

			if ($scope.model.disposalGatheringIsLocked) {
				return validation;
			}

			if ($scope.isGatheringStage() && !gatheringDate) {
				$scope.destinationConfig.blockEnableNextStep = true;
				$scope.enableBackToStep($scope.stepsEnum.A);
				validation.isValid = false;
				validation.message = $scope.labels.GATHERING_DATE_REQUIRED;
			} else {
				$scope.destinationConfig.blockEnableNextStep = false;
			}

			setCustomValidationMessage(validation.message, $scope.stepsEnum.A);
			return validation;
		}

		$scope.showCopyDestinatorSwitch = function () {
			return (
				!$scope.isCurrentStageReview() &&
				$scope.isSolicitationStage() &&
				$scope.destinationModel.Destinator &&
				$scope.destinationModel.Destinator.IsTransporter
			);
		};

		$scope.gatheringDateChanged = function (gatheringDate) {
			validateDisposalTransporter($scope.destinationModel.Transporter, gatheringDate);
		};

		function validateDisposalTransporter(tranporter, gatheringDate) {
			const gatheringDateValidationObject = validateGatheringDate(gatheringDate);
			const arrayValidation = [gatheringDateValidationObject];

			validateTransporter(tranporter).then(function (transporterValidationObject) {
				arrayValidation.push(transporterValidationObject);

				const validationObj = getObjectValidation(arrayValidation);
				if ($scope.isSolicitationStage()) {
					updateSlipToSolicitation(validationObj.isValid, validationObj.message);
				} else if ($scope.isGatheringStage()) {
					updateSlipToGathering(validationObj.isValid, validationObj.message);
				}
			});
		}

		function getObjectValidation(arrayValidation) {
			const validation = {
				isValid: true,
				message: ""
			};

			const validationObj = arrayValidation.find(function (item) {
				return !item.isValid;
			});

			return Object.assign(validation, validationObj);
		}

		$scope.setTransporterFormScope = function (scope) {
			$scope.transporterFormScope = scope;
		};

		$scope.getMaxDate = function () {
			const dateSummed = Vgr.util.getSumOfDate(Vgr.util.getDate(), $scope.getMaxDaysEstimatedGathering());
			return Vgr.util.getDateStringYYYYMMDD(dateSummed);
		};

		$scope.gatheringGatheringDateOnBlur = function () {
			$scope.transporterFormError = {};
			$scope.gatheringDateFieldCustomErrorMessage = "";

			$scope.transporterFormError = $scope.transporterFormScope[transporterForm][gatheringDateInputName].$error;

			const errorMessages = getGatheringDateFieldErrorMessages();
			const keysFormError = Object.keys($scope.transporterFormError);

			keysFormError.forEach((item) => {
				$scope.gatheringDateFieldCustomErrorMessage += `${errorMessages[item]}\n`;
			});
		};

		function getGatheringDateFieldErrorMessages() {
			const maxError = $scope.labels.GATHERING_DATE_RANGE_ERROR.replace("[P1]", $scope.getMaxDaysEstimatedGathering());
			const dateError = $scope.labels["ERR-DAT-0001"];

			return {
				max: maxError,
				date: dateError
			};
		}

		$scope.$on("$destroy", function () {
			// nullify the DOM-bound model
			$scope.domElement = null;
		});
	}
]);
