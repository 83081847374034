angular.module("vgresiduos").controller("StepWizardCtrl", [
	"$scope",
	function ($scope) {
		"use strict";

		$scope.currentStepIndex = 0;

		this.$onChanges = function (obj) {
			if (obj.setterStep && obj.setterStep.currentValue != obj.setterStep.previousValue) {
				if (typeof obj.setterStep.currentValue != "undefined") {
					$scope.currentStepIndex = obj.setterStep.currentValue;
				}
			}
		};

		$scope.goToStep = function (index) {
			if ($scope.$ctrl.steps[index].isDisabled) {
				return;
			}

			$scope.currentStepIndex = index;
			$scope.$ctrl.onStepChange(index);
		};
	}
]);
