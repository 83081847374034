angular.module("vgresiduos").component("residueReportTable", {
	controller: "ResidueReportTableCtrl",
	templateUrl: "views/report/residue/base/residueReportTable/residueReportTable_template.html",
	bindings: {
		data: "<",
		headersConcatenatedArray: "<",
		title: "<?",
		decimalPlaces: "<?",
		ignoreTotal: "<?",
		addTotalRowByQualifier: "<?",
		concatenateFirstDataQualifier: "<?"
	}
});
