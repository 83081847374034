angular.module("vgresiduos").directive("tabGroup", function () {
	"use strict";

	return {
		scope: true,
		transclude: true,
		controller: "TabGroupCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/tabgroup/tabGroup_template.html"
	};
});
