angular.module("vgresiduos").directive("userPermissionGrid", function () {
	return {
		scope: true,
		controller: "UserPermissionGridCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/onboarding/users/grids/userPermissionGrid_template.html",
		bindToController: {
			gridOptions: "=",
			userId: "@"
		}
	};
});
