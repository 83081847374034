angular.module("vgresiduos").factory("locationService", [
	"$http",
	"$q",
	"localStorageWithTimeoutService",
	function ($http, $q, localStorageWithTimeoutService) {
		"use strict";

		const countriesCacheKey = Vgr.constants.cacheKeysPrefix.countries;
		const statesCacheKey = Vgr.constants.cacheKeysPrefix.states;
		const citiesCacheKey = Vgr.constants.cacheKeysPrefix.cities;
		const cacheTimeoutInMinutes = 43200;

		const _listStates = function (dto) {
			return Vgr.util.getWithCache(
				Vgr.constants.coreHostUrl + Vgr.resources.core.states,
				dto,
				statesCacheKey,
				cacheTimeoutInMinutes,
				localStorageWithTimeoutService,
				$http,
				$q
			);
		};

		const _listCountries = function (dto) {
			return Vgr.util.getWithCache(
				Vgr.constants.coreHostUrl + Vgr.resources.core.countries,
				dto,
				countriesCacheKey,
				cacheTimeoutInMinutes,
				localStorageWithTimeoutService,
				$http,
				$q
			);
		};

		const _listCities = function (dto) {
			const citiesUrl = Vgr.constants.coreHostUrl + Vgr.resources.core.cities;
			const parameters = Vgr.util.serializeObjectToQueryString(dto);
			const req = {
				method: "get",
				url: citiesUrl + parameters
			};
			return $http(req);
		};

		const _getCity = function (id) {
			return Vgr.util.getWithCache(
				Vgr.constants.coreHostUrl + Vgr.resources.core.cities + "/" + id,
				null,
				citiesCacheKey + id,
				cacheTimeoutInMinutes,
				localStorageWithTimeoutService,
				$http,
				$q
			);
		};

		return {
			listStates: _listStates,
			listCities: _listCities,
			getCity: _getCity,
			listCountries: _listCountries
		};
	}
]);
