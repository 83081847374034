angular.module("vgresiduos").controller("IbamaRappCreateCtrl", [
	"$rootScope",
	"$scope",
	"$state",
	"httpService",
	"ibamaIntegrationService",
	"ibamaExternalClientService",
	function ($rootScope, $scope, $state, httpService, ibamaIntegrationService, ibamaExternalClientService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.model = {
			unit: null,
			year: new Date().getFullYear() - 1
		};

		$rootScope.contentNavBar = {
			title: $scope.labels.RAPP_IBAMA_INTEGRATION,
			breadcrumb: $scope.labels.NEW_INTEGRATION,
			back: function () {
				$state.go("ibamaRappList", { ano: $scope.model.year });
			},
			buttons: []
		};

		$scope.steps = [
			{
				title: $scope.labels.GENERAL_DATA
			},
			{
				title: $scope.labels.VALIDATION,
				isDisabled: true
			},
			{
				title: $scope.labels.SUMMARY,
				isDisabled: true
			}
		];

		this.$onInit = function () {
			setCurrentStep(0);
		};

		$scope.onClientSelect = function (client) {
			if (client) {
				httpService.getDTOFromServiceV2(ibamaExternalClientService.listIbamaExternalClient, {}).then(
					function (ret) {
						if (
							ret &&
							ret.some(function (i) {
								return i.cnpj == client.document;
							})
						) {
							$scope.steps[1].isDisabled = false;
						} else {
							disableStepButtonAndShowMessage(1, $scope.labels.IBAMA_INTEGRATION_NOT_REGISTERED_FOR_CNPJ);
						}
					},
					function () {
						disableStepButtonAndShowMessage(1, $scope.labels.IBAMA_INTEGRATION_NOT_REGISTERED_FOR_CNPJ);
					}
				);
			} else {
				disableStepButtonAndShowMessage(1, null);
			}
		};

		function disableStepButtonAndShowMessage(stepIndex, message) {
			$scope.steps[stepIndex].isDisabled = true;
			if (message) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, message);
			}
		}

		$scope.onValidate = function (isValid) {
			if (isValid) {
				$scope.isValid = true;
				$scope.steps[2].isDisabled = false;
			} else {
				$scope.isValid = false;
				$scope.steps[2].isDisabled = true;
			}
		};

		$scope.onChangeStep = function (stepIndex) {
			setCurrentStep(stepIndex);
			if (stepIndex == 0) {
				clear();
			}
		};

		$scope.isNextStepEnabled = function (step) {
			var nextStep = step + 1;

			return $scope.steps[nextStep] && !$scope.steps[nextStep].isDisabled;
		};

		$scope.cancel = function () {
			$state.go("ibamaRappList", { ano: $scope.model.year });
		};

		$scope.goBack = function () {
			setCurrentStep($scope.currentStepIndex - 1);
		};

		$scope.continue = function () {
			if ($scope.isNextStepEnabled($scope.currentStepIndex)) {
				setCurrentStep($scope.currentStepIndex + 1);
			}
		};

		$scope.confirm = function () {
			$scope.isCreating = true;
			var dto = getCreateDto();
			httpService.getDTOFromServiceV2(ibamaIntegrationService.createIbamaRapp, dto).then(
				function (id) {
					$state.go("ibamaRappFollowup", { id: id });
				},
				function () {
					$scope.isCreating = false;
				}
			);
		};

		function getCreateDto() {
			return {
				Year: $scope.model.year,
				OrganizationUnitCode: $scope.model.unit.code
			};
		}

		function clear() {}

		function setCurrentStep(index) {
			$scope.currentStepIndex = index;
		}
	}
]);
