angular.module("vgresiduos").controller("DisposalHistoryCtrl", [
	"$rootScope",
	"$scope",
	"deviceDetector",
	function ($rootScope, $scope, deviceDetector) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.isMobile = function () {
			return deviceDetector.isMobile();
		};
	}
]);
