angular.module("vgresiduos").directive("clientFormPreferences", function () {
	return {
		scope: true,
		controller: "ClientFormPreferencesCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/client/components/clientForm/clientFormPreferences/clientFormPreferences_template.html",
		bindToController: {
			inputClient: "=",
			saveOptions: "=",
			isLowerGenerationPlan: "<"
		}
	};
});
