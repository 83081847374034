angular.module("vgresiduos").factory("additionalPropertyService", [
	"$http",
	"accountService",
	function ($http, accountService) {
		"use strict";

		var additionalPropertyService = {};

		var _createAdditionalProperty = function (body) {
			var organizationId = accountService.getCurrentClient().organization.id;
			var req = {
				method: "post",
				data: body,
				url:
					Vgr.constants.coreHostUrl +
					Vgr.resources.core.additionalProperties.replace("[ORGANIZATION_ID]", organizationId)
			};
			return $http(req);
		};

		var _listAdditionalProperties = function (dto) {
			var organizationId = accountService.getCurrentClient().organization.id;
			var parameters = "";
			if (dto) {
				parameters = $.param(dto);
			}

			var req = {
				method: "get",
				url:
					Vgr.constants.coreHostUrl +
					Vgr.resources.core.additionalProperties.replace("[ORGANIZATION_ID]", organizationId) +
					"?" +
					parameters
			};
			return $http(req);
		};

		var _getAdditionalProperty = function (id) {
			var organizationId = accountService.getCurrentClient().organization.id;

			var req = {
				method: "get",
				url:
					Vgr.constants.coreHostUrl +
					Vgr.resources.core.additionalProperties.replace("[ORGANIZATION_ID]", organizationId) +
					"/" +
					id
			};
			return $http(req);
		};

		var _updateAdditionalProperty = function (dto) {
			var organizationId = accountService.getCurrentClient().organization.id;
			var req = {
				method: "put",
				data: dto.model,
				url:
					Vgr.constants.coreHostUrl +
					Vgr.resources.core.additionalProperties.replace("[ORGANIZATION_ID]", organizationId) +
					"/" +
					dto.additionalPropertyId
			};
			return $http(req);
		};

		var _deleteAdditionalProperty = function (id) {
			var organizationId = accountService.getCurrentClient().organization.id;

			var req = {
				method: "delete",
				url:
					Vgr.constants.coreHostUrl +
					Vgr.resources.core.additionalProperties.replace("[ORGANIZATION_ID]", organizationId) +
					"/" +
					id
			};

			return $http(req);
		};

		var _listClientProperties = function (dto) {
			if (!dto) {
				dto = dto || {};
			}

			dto.organizationUnitId = accountService.getCurrentClient().id;

			return _listAdditionalProperties(dto);
		};

		additionalPropertyService.createAdditionalProperty = _createAdditionalProperty;
		additionalPropertyService.getAdditionalProperty = _getAdditionalProperty;
		additionalPropertyService.deleteAdditionalProperty = _deleteAdditionalProperty;
		additionalPropertyService.updateAdditionalProperty = _updateAdditionalProperty;
		additionalPropertyService.listAdditionalProperties = _listAdditionalProperties;

		additionalPropertyService.listClientProperties = _listClientProperties;

		return additionalPropertyService;
	}
]);
