angular.module("vgresiduos").controller("CustomReportCreateConclusionCtrl", [
	"$rootScope",
	"$scope",
	"$state",
	function ($rootScope, $scope, $state) {
		"use strict";

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;
			$scope.loadingFinished = true;
		};

		$scope.goToListPage = function () {
			$state.go("customReport");
		};

		$scope.goToViewReportPage = function () {
			$state.go("customReportEdit", { reportId: $scope.$ctrl.reportId });
		};
	}
]);
