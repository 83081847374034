angular.module("vgresiduos").controller("ReplicateForClientsCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"clientService",
	"httpService",
	"accountService",
	"supplierService",
	function ($scope, $rootScope, $q, clientService, httpService, accountService, supplierService) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.currentClient = accountService.getCurrentClient();
		$scope.checkList = [];
		$scope.selectAll = false;
		$scope.isLoadingClients = false;

		let hasDeleteError = false;
		let selectedClientsCount;

		function replicateRegisters(list) {
			if ($scope.ctrl.replicateType == Vgr.enumerations.replicateType.Supplier) {
				return replicateSupplierRegisters(list);
			}
			return null;
		}

		const onConfirm = $rootScope.$on(Vgr.constants.evtConfimReplicateForClients, function () {
			confirm();
		});

		const onGetSelectedClientsCount = $rootScope.$on(Vgr.constants.evtGetSelectedCount, function () {
			if ($scope.ctrl.getSelectedClientsCount) {
				getSelectedClientsCount();
			}
		});

		$scope.$on("$destroy", function () {
			onConfirm();
			onGetSelectedClientsCount();
			$scope.domElement = null;
		});

		function confirm() {
			if (!validate()) {
				return;
			}

			$scope.isLoadingReplication = true;

			replicateSelected().then(
				function () {
					$scope.isLoadingReplication = false;
					$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $rootScope.labels.REPLICATE_INFO_SUCCESS_MESSAGE);
					if ($scope.ctrl.onReplicateFinish) {
						$scope.ctrl.onReplicateFinish();
					}
				},
				function () {
					$scope.isLoadingReplication = false;
					$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.REPLICATE_INFO_ERROR_MESSAGE);
					if ($scope.ctrl.onReplicateFinish) {
						$scope.ctrl.onReplicateFinish();
					}
				}
			);
		}

		function getSelectedClientsCount() {
			selectedClientsCount = getSelectedUnitsList().length;
			$scope.ctrl.getSelectedClientsCount(selectedClientsCount);
		}

		function replicateSelected() {
			const deferred = $q.defer();
			const list = getSelectedUnitsList();
			batchReplicateItems(deferred, list, 2);
			return deferred.promise;
		}

		function getSelectedUnitsList() {
			return $scope.checkList.filter((c) => c.toReplicate);
		}

		function batchReplicateItems(deferred, list, itemsPerBatch) {
			const batchItemsToDelete = list.splice(0, itemsPerBatch);
			const percentage = (100 * (selectedClientsCount - list.length)) / selectedClientsCount;
			$scope.toShowPercentage = 0.8 * percentage + 20;
			batchReplicate(batchItemsToDelete).then(
				function () {
					onBatchFinish(deferred, list, itemsPerBatch);
				},
				function () {
					hasDeleteError = true;
					onBatchFinish(deferred, list, itemsPerBatch);
				}
			);
		}

		function onBatchFinish(deferred, list, itemsPerBatch) {
			if (list.length > 0) {
				batchReplicateItems(deferred, list, itemsPerBatch);
			} else {
				hasDeleteError ? deferred.reject() : deferred.resolve();
			}
		}

		function batchReplicate(list) {
			const promises = getReplicateItemsPromises(list);
			return $q.all(promises);
		}

		function getReplicateItemsPromises(list) {
			const promises = [];

			const registers = replicateRegisters(list);

			if (Array.isArray(registers)) {
				promises.push(...registers);
			} else {
				promises.push(registers);
			}

			return promises;
		}

		function getPermissionId() {
			if (
				$scope.ctrl.replicateType == Vgr.enumerations.replicateType.DisposalPreference ||
				$scope.ctrl.replicateType == Vgr.enumerations.replicateType.StoragePreference
			) {
				return Vgr.systemPermissions.EditUnit.Id;
			}
			return null;
		}

		function validate() {
			if (!hasAnyClientChecked()) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.NO_CLIENT_SELECTED);
				return false;
			}
			return true;
		}

		function hasAnyClientChecked() {
			return $scope.checkList.some((client) => client.toReplicate);
		}

		function replicateSupplierRegisters(list) {
			const dto = getReplicateDto(list);
			const promises = [];
			dto.ids.forEach(function (id) {
				const dtoSupplier = {
					supplierId: id,
					organizationUnitIdsToAdd: dto.ClientIds
				};
				promises.push(httpService.postDTOToServiceV2(supplierService.updateSupplierOrganizationUnits, dtoSupplier));
			});

			return promises;
		}

		function getReplicateDto(list) {
			const clientIds = list.map((i) => i.client.id);

			let model;
			if ($scope.ctrl.requestModel != null) {
				model = $scope.ctrl.requestModel;
			} else {
				model = {};
			}

			model.ClientIds = clientIds;

			return model;
		}

		$scope.selectDeselectAll = function () {
			for (const item of $scope.checkList) {
				item.toReplicate = $scope.selectAll;
			}
		};

		function listClients() {
			$scope.isLoadingClients = true;
			const dto = {
				active: true
			};
			const permissionId = getPermissionId();
			if (permissionId) {
				dto.systemPermissionId = permissionId;
			}

			httpService.getListFromServiceCore(clientService.listClientsCore, dto).then(
				function (response) {
					for (const client of response.list) {
						if (client.id != $scope.currentClient.id) {
							$scope.checkList.push({
								toReplicate: false,
								client: client
							});
						}
					}

					$scope.isLoadingClients = false;
					if (!$scope.checkList.length) {
						$scope.hasNoClientsToReplicate = true;
					}
					if ($scope.ctrl.onLoadClientsFinish) {
						$scope.ctrl.onLoadClientsFinish(!$scope.hasNoClientsToReplicate);
					}
				},
				function () {
					$scope.isLoadingClients = false;
				}
			);
		}

		this.$onInit = function () {
			listClients();
		};
	}
]);
