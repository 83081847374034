angular.module("vgresiduos").controller("CityMultiSelectCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"clientService",
	function ($scope, $rootScope, httpService, clientService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			listCities();
		};

		function listCities() {
			httpService.getListFromServiceCore(clientService.listClientsCore).then(function (response) {
				let cityList = response.list.map((unit) => {
					return unit.city;
				});

				let uniqueCityList = Vgr.util.removeDuplicate(cityList, JSON.stringify);

				uniqueCityList.forEach((element) => {
					element.id = element.id.toString();
					element.displayText = Vgr.util.getCityDescription(element);
				});

				Vgr.util.sortListByField(uniqueCityList, "displayText");

				$scope.cities = uniqueCityList;
			});
		}

		$scope.onChanged = function (selectedValues) {
			callback(selectedValues);
		};

		function callback(selectedValues) {
			if ($scope.$ctrl.callback) {
				$scope.$ctrl.callback(selectedValues);
			}
		}
	}
]);
