angular.module("vgresiduos").directive("checkBox", function () {
	return {
		scope: true,
		controller: "checkBoxCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/fields/checkBox/checkBox_template.html",
		bindToController: {
			model: "=",
			label: "@",
			description: "@",
			showDescriptionBelow: "@",
			isDisabled: "=?",
			onChange: "=?"
		}
	};
});
