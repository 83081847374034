angular.module("vgresiduos").controller("CustomReportCreateConfigurationsCtrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;
			$scope.$ctrl.model.filters = {};
			$scope.loadingFinished = true;
		};

		$scope.onChangeFilters = function (filters) {
			$scope.$ctrl.model.filters = filters;
		};
	}
]);
