angular.module("vgresiduos").controller("FeatureToggleEditCtrl", [
	"$scope",
	"$rootScope",
	"$stateParams",
	"$state",
	"httpService",
	"featureToggleService",
	"$q",
	function ($scope, $rootScope, $stateParams, $state, httpService, featureToggleService, $q) {
		"use strict";

		$scope.featureToggleId = $stateParams.id;

		$scope.saveOptions = {
			title: $rootScope.labels.FEATURE_TOGGLE,
			currentContext: "general",
			back: function () {
				$state.go("featureToggleList");
			},
			general: {
				valid: true,
				returnState: "featureToggleList",
				save: function () {
					const deferred = $q.defer();

					if ($scope.featureToggleId) {
						updateFeatureToggle().then(
							function () {
								deferred.resolve();
							},
							function () {
								deferred.reject();
							}
						);
					} else {
						createFeatureToggle().then(
							function () {
								$state.go("featureToggleList");
								deferred.resolve();
							},
							function () {
								deferred.reject();
							}
						);
					}
					return deferred.promise;
				},
				validate: function () {
					return validate();
				},
				onSaveError: function () {
					//do nothing to override 2 message errors
				}
			},
			featureTogglePermissions: {
				removeButtonBar: true,
				buttons: []
			}
		};

		function validate() {
			if (!$scope.featureToggle.description) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.FEATURETOGGLE_DESCRIPTION_ERROR);
				return false;
			}
			if (!$scope.featureToggle.typeModel) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.FEATURETOGGLE_TYPE_ERROR);
				return false;
			}
			if (!$scope.featureToggle.statusModel) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.FEATURETOGGLE_STATUS_ERROR);
				return false;
			}
			return true;
		}

		this.$onInit = function () {
			loadFeatureToggle();
		};

		function loadFeatureToggle() {
			const deferred = $q.defer();
			if ($scope.featureToggleId) {
				httpService.getDTOFromServiceV2(featureToggleService.getFeatureToggle, $scope.featureToggleId).then(
					function (response) {
						$scope.featureToggle = response;
						if ($scope.featureToggle.creationDate) {
							$scope.featureToggle.creationDate = new Date($scope.featureToggle.creationDate);
						}
						if ($scope.featureToggle.dueDate) {
							$scope.featureToggle.dueDate = new Date($scope.featureToggle.dueDate);
						}
						if ($scope.featureToggle.id) {
							$rootScope.contentNavBar.breadcrumb = $scope.featureToggle.description;
						}
						deferred.resolve();
					},
					function () {
						$state.go("featureToggleList");
						deferred.reject();
					}
				);
			} else {
				$scope.featureToggle = {};
				deferred.resolve();
			}

			return deferred.promise;
		}

		function createFeatureToggle() {
			const deferred = $q.defer();
			const dto = getFeatureToggleDto();

			httpService.postDTOToServiceV2(featureToggleService.createFeatureToggle, dto).then(
				function (data) {
					$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $rootScope.labels.SAVE_SUCCESS_MESSAGE);
					$rootScope.contentNavBar.breadcrumb = data.description;
					$scope.featureToggle.id = data.id;
					$scope.featureToggleId = data.id;
					deferred.resolve(data.id);
				},
				function () {
					deferred.reject();
				}
			);
			return deferred.promise;
		}

		function updateFeatureToggle() {
			const deferred = $q.defer();
			const dto = getFeatureToggleDto();

			httpService.postDTOToServiceV2(featureToggleService.updateFeatureToggle, dto).then(
				function () {
					$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $rootScope.labels.SAVE_SUCCESS_MESSAGE);
					$rootScope.contentNavBar.breadcrumb = dto.description;
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);
			return deferred.promise;
		}

		function getFeatureToggleDto() {
			return {
				id: $scope.featureToggle.id,
				description: $scope.featureToggle.description,
				type: $scope.featureToggle.typeModel.Id,
				status: $scope.featureToggle.statusModel.Id,
				creationDate: new Date(),
				dueDate: $scope.featureToggle.dueDate
			};
		}

		$scope.changeContext = function (context) {
			$scope.saveOptions.currentContext = context;
			$rootScope.contentNavBarGrid = false;
		};

		$rootScope.contentNavBar = $scope.saveOptions;
		$rootScope.contentNavBar.breadcrumb = $scope.featureToggleId;
		$rootScope.contentNavBarGrid = false;
	}
]);
