angular.module("vgresiduos").component("indicatorSelect", {
	controller: "IndicatorSelectCtrl",
	templateUrl: "views/report/relativeKpis/baseComponents/indicatorSelect/indicatorSelect_template.html",
	bindings: {
		isDisabled: "<?",
		setterId: "<?",
		model: "=",
		onChange: "<?",
		excluded: "<?",
		isRelative: "<?",
		placeholder: "@?"
	}
});
