angular.module("vgresiduos").controller("CadriDocumentSelectFieldCtrl", [
	"$scope",
	"$rootScope",
	"supplierDocumentService",
	function ($scope, $rootScope, supplierDocumentService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			$scope.componentLabel = $scope.ctrl.label ? $scope.ctrl.label : $scope.labels.AUTHORIZATION_DOCUMENT;
			refreshDocuments();
		};

		function refreshDocuments() {
			if (!$scope.ctrl.supplierCode) {
				cleanList();
			} else {
				loadDocuments();
			}
		}

		function cleanList() {
			$scope.authDocumentList = [];

			if ($scope.ctrl.onLoadList) {
				$scope.ctrl.onLoadList($scope.authDocumentList);
			}
		}

		function loadDocuments() {
			supplierDocumentService.listOrganizationUnitCadris($scope.ctrl.supplierCode, true).then(function (list) {
				internalLoadList(list);
			});
		}

		function internalLoadList(documentList) {
			$scope.authDocumentList = getListWithDescriptionToShow(documentList);

			if ($scope.ctrl.onLoadList) {
				$scope.ctrl.onLoadList($scope.authDocumentList);
			}
		}

		function getListWithDescriptionToShow(documents) {
			return documents.map(function (document) {
				document.DescriptionToShow = document.documentType.description;
				if (document.code) {
					document.DescriptionToShow += " - " + document.code;
				}
				return document;
			});
		}

		$scope.$watch("ctrl.supplierCode", function (newValue, oldValue) {
			if (newValue != oldValue) {
				refreshDocuments();
			}
		});

		$scope.isDisabled = function () {
			return !$scope.ctrl.supplierCode;
		};
	}
]);
