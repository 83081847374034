angular.module("vgresiduos").controller("topNavBarCtrl", [
	"$scope",
	"$rootScope",
	"$sce",
	"accountService",
	function ($scope, $rootScope, $sce, accountService) {
		"use strict";

		$scope.openMenu = function () {
			$rootScope.$broadcast(Vgr.constants.evtOpenMenu);
			$scope.currentClient = accountService.getCurrentClient();
		};

		$scope.goHome = function () {
			accountService.goHome();
		};

		$scope.currentClient = accountService.getCurrentClient();
		$scope.oldVersionMessage = $sce.trustAsHtml($rootScope.labels.OLD_VERSION_MESSAGE);

		$scope.isLoggedIn = accountService.isLoggedIn();

		const onChangeLoginStatus = $rootScope.$on(Vgr.constants.evtChangeLoginStatus, function (event, isLoggedIn) {
			$scope.isLoggedIn = isLoggedIn;
		});

		$scope.$on("$destroy", function () {
			// disable the listener
			onChangeLoginStatus();

			// nullify the DOM-bound model
			$scope.domElement = null;
		});
	}
]);
