angular.module("vgresiduos").controller("DocumentCreateCtrl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `documento`, [{ key: "documentType", value: $stateParams.documentType }]);
		};
	}
]);
