angular.module("vgresiduos").factory("residuesManagementService", [
	"$http",
	"accountService",
	function ($http, accountService) {
		"use strict";

		const _updateDisposalManifestsBySource = function (dto) {
			const req = {
				method: "put",
				timeout: 240000,
				data: dto,
				url: getDisposalAndSourceUrl().replace("[DISPOSAL_ID]", dto.disposalId).replace("[SOURCE_ID]", dto.sourceId)
			};

			return $http(req);
		};

		function getDisposalAndSourceUrl() {
			const organizationId = accountService.getCurrentClient().organization.id;
			return (
				Vgr.constants.residuesManagementHostUrl +
				Vgr.resources.residuesManagement.updateDisposalManifests
					.replace("[ORGANIZATION_UNIT]", accountService.getCurrentClient().code)
					.replace("[ORGANIZATION_ID]", organizationId)
			);
		}

		const _listDisposalManifests = function (dto) {
			const req = {
				method: "post",
				url: getBaseDisposalManifestsUrl(),
				data: { ...dto, ids: [accountService.getCurrentClient().id] }
			};

			return $http(req);
		};

		function getBaseDisposalManifestsUrl() {
			const organizationId = accountService.getCurrentClient().organization.id;
			return (
				Vgr.constants.residuesManagementHostUrl +
				Vgr.resources.residuesManagement.listDisposalManifests.replace("[ORGANIZATION_ID]", organizationId)
			);
		}
		const _listDisposalsForFollowup = function (params) {
			const organizationId = accountService.getCurrentClient().organization.id;
			let parameters = "";
			if (params) {
				parameters = $.param(params);
			}

			const req = {
				method: "get",
				url:
					Vgr.constants.residuesManagementHostUrl +
					Vgr.resources.residuesManagement.disposalsForFollowup
						.replace("[ORGANIZATION_UNIT]", accountService.getCurrentClient().code)
						.replace("[ORGANIZATION_ID]", organizationId) +
					"?" +
					parameters
			};
			return $http(req);
		};

		const _listDisposalsHistory = function (params) {
			const organizationId = accountService.getCurrentClient().organization.id;
			let parameters = "";
			if (params) {
				parameters = $.param(params);
			}
			const req = {
				method: "get",
				url:
					Vgr.constants.residuesManagementHostUrl +
					Vgr.resources.residuesManagement.disposalsHistory
						.replace("[ORGANIZATION_UNIT]", accountService.getCurrentClient().code)
						.replace("[ORGANIZATION_ID]", organizationId) +
					"?" +
					parameters
			};
			return $http(req);
		};

		const _exportDisposalsHistory = function (dto) {
			const req = {
				method: "post",
				url:
					Vgr.constants.residuesManagementHostUrl +
					Vgr.resources.residuesManagement.exportDisposalsHistory
						.replace("[ORGANIZATION_UNIT]", accountService.getCurrentClient().code)
						.replace("[ORGANIZATION_ID]", accountService.getCurrentClient().organization.id),
				data: dto
			};
			return $http(req);
		};

		return {
			updateDisposalManifestsBySource: _updateDisposalManifestsBySource,
			listDisposalManifests: _listDisposalManifests,
			listDisposalsForFollowup: _listDisposalsForFollowup,
			listDisposalsHistory: _listDisposalsHistory,
			exportDisposalsHistory: _exportDisposalsHistory
		};
	}
]);
