angular.module("vgresiduos").directive("supplierFilterButton", function () {
	return {
		scope: true,
		controller: "SupplierFilterButtonCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/suppliers/grid/general/supplierFilterButton_template.html",
		bindToController: {
			label: "@",
			count: "=",
			isDisabled: "=",
			onClick: "&",
			isActive: "=",
			isLoading: "="
		}
	};
});
