angular.module("vgresiduos").component("supplierProfileTypeSelectField", {
	controller: "SupplierProfileTypeSelectFieldCtrl",
	templateUrl: "views/components/fields/supplierProfileTypeSelectField/supplierProfileTypeSelectField_template.html",
	bindings: {
		onChange: "=",
		clearSelections: "=?",
		isDisabled: "=?",
		isRequired: "<?",
		setter: "<?",
		includedIds: "<?"
	}
});
