angular.module("vgresiduos").controller("AdditionalPropertiesCreateFormCtrl", [
	"$scope",
	"additionalPropertyUtilService",
	function ($scope, additionalPropertyUtilService) {
		"use strict";

		$scope.canHaveMandatoryResidue = false;

		function calculateCanHaveMandatoryResidue(process) {
			$scope.canHaveMandatoryResidue = additionalPropertyUtilService.setMandatoryResidueToAdditionalProperty(process);
		}

		$scope.$watch("ctrl.model.process", function (newValue) {
			calculateCanHaveMandatoryResidue(newValue);
		});
	}
]);
