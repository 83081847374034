angular.module("vgresiduos").factory("supplierDocumentService", [
	"$http",
	"$q",
	"accountService",
	function ($http, $q, accountService) {
		"use strict";

		function getOrganizationDocumentBaseRequestUrl(supplierId) {
			const client = accountService.getCurrentClient();
			const organizationId = client.organization.id;
			return (
				Vgr.constants.suppliersHostUrl +
				Vgr.resources.suppliers.organizationDocument
					.replace("[ORGANIZATION]", organizationId)
					.replace("[SUPPLIER]", supplierId)
			);
		}

		function getOrganizationUnitDocumentBaseRequestUrl(supplierId) {
			const client = accountService.getCurrentClient();
			const organizationId = client.organization.id;
			const organizationUnitCode = client.code;
			return (
				Vgr.constants.suppliersHostUrl +
				Vgr.resources.suppliers.organizationUnitDocument
					.replace("[ORGANIZATION]", organizationId)
					.replace("[ORGANIZATION_UNIT]", organizationUnitCode)
					.replace("[SUPPLIER]", supplierId)
			);
		}

		function getDocumentTypeBaseRequestUrl() {
			const client = accountService.getCurrentClient();
			const organizationId = client.organization.id;
			return (
				Vgr.constants.suppliersHostUrl + Vgr.resources.suppliers.documentTypes.replace("[ORGANIZATION]", organizationId)
			);
		}

		function getDisposalDocumentsBaseRequestUrl() {
			return (
				Vgr.constants.suppliersHostUrl +
				Vgr.resources.suppliers.disposalDocuments.replace(
					"[ORGANIZATION]",
					accountService.getCurrentClient().organization.id
				)
			);
		}

		const _listOrganizationDocuments = function (supplierCode, onlyActive) {
			const req = {
				method: "get",
				url: getOrganizationDocumentBaseRequestUrl(supplierCode)
			};

			const deferred = $q.defer();
			$http(req)
				.then((response) => {
					let list = response.data;
					if (onlyActive) {
						list = list.filter((doc) => doc.isActive);
					}
					deferred.resolve(list);
				})
				.catch(() => deferred.resolve([]));

			return deferred.promise;
		};

		const _listOrganizationUnitCadris = function (supplierCode, onlyActive) {
			const parameters = Vgr.util.serializeObjectToQueryString({
				onlyActive: onlyActive
			});
			const req = {
				method: "get",
				url: getOrganizationUnitDocumentBaseRequestUrl(supplierCode) + parameters
			};

			const deferred = $q.defer();
			$http(req)
				.then((response) => {
					const list = response.data.filter((doc) => doc.documentType.isCadri);
					deferred.resolve(list);
				})
				.catch(() => deferred.resolve([]));

			return deferred.promise;
		};

		const _listDocumentTypes = function () {
			const req = {
				method: "get"
			};

			req.url = getDocumentTypeBaseRequestUrl();

			return $http(req);
		};

		const _generateDocumentsReport = function () {
			const client = accountService.getCurrentClient();
			const organizationId = client.organization.id;
			const url =
				Vgr.constants.suppliersHostUrl +
				Vgr.resources.suppliers.documentsReport.replace("[ORGANIZATION]", organizationId);
			return $http({
				method: "post",
				url: url
			});
		};

		function _calculateDocumentsForDisposalRequest(dto) {
			const req = {
				method: "post",
				url: getDisposalDocumentsBaseRequestUrl(),
				data: {
					organizationUnitCode: accountService.getCurrentClient().code,
					destinatorId: dto.destinatorId,
					residueIds: dto.residueIds
				}
			};

			return $http(req);
		}

		return {
			listOrganizationDocuments: _listOrganizationDocuments,
			listOrganizationUnitCadris: _listOrganizationUnitCadris,
			listDocumentTypes: _listDocumentTypes,

			generateDocumentsReport: _generateDocumentsReport,
			calculateDocumentsForDisposalRequest: _calculateDocumentsForDisposalRequest
		};
	}
]);
