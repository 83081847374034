angular.module("vgresiduos").controller("SupplierContactSelectFieldCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"supplierContactService",
	"supplierService",
	function ($scope, $rootScope, $q, httpService, supplierContactService, supplierService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		let isListLoaded;

		this.$onInit = function () {
			$scope.label = $scope.$ctrl.label ? $scope.$ctrl.label : $scope.labels.CONTACTS;
			if ($scope.$ctrl.isDisabled) {
				$scope.contacts = [];
				if ($scope.$ctrl.model && $scope.$ctrl.model.Name) {
					$scope.contacts.push({
						id: $scope.$ctrl.model.Id,
						name: $scope.$ctrl.model.Name
					});
					$scope.$ctrl.model = $scope.contacts[0];
				}
			} else {
				loadContacts().then(setContact);
			}
		};

		function loadContacts() {
			if ($scope.$ctrl.list) {
				$scope.contacts = $scope.$ctrl.list;
				isListLoaded = true;
			}

			if (isListLoaded) {
				return $q.resolve();
			}

			const deferred = $q.defer();

			const dto = {
				supplierId: $scope.$ctrl.supplierId
			};

			httpService.getDTOFromServiceV2(supplierService.getOrganizationSupplier, dto.supplierId).then(
				function (supplierResponse) {
					dto.supplierCode = supplierResponse.code;
					httpService.getListFromServiceCore(supplierContactService.listContacts, dto).then(
						function (response) {
							$scope.contacts = response.list;
							isListLoaded = true;
							deferred.resolve();
						},
						function () {
							deferred.reject();
						}
					);
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function setContact() {
			if ($scope.$ctrl.setterId) {
				const foundContact = $scope.contacts.find((c) => c.id == $scope.$ctrl.setterId);
				if (foundContact != null) {
					$scope.$ctrl.model = foundContact;
				}
			}
		}
	}
]);
