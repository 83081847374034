angular.module("vgresiduos").factory("calculusService", [
	"$http",
	"$q",
	function ($http, $q) {
		"use strict";

		var calculusService = {};

		var _calculateConversionFactor = function (dto) {
			var req = {
				method: "post",
				data: dto,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.calculus + "conversions-factor"
			};
			return $http(req);
		};

		var _calculateQuantityConverted = function (dto) {
			var req = {
				method: "post",
				data: dto,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.calculus + "quantities-conversions"
			};
			return $http(req);
		};

		var _calculateRequestEstimates = function (dto) {
			var req = {
				method: "post",
				data: dto,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.calculus + "request-bid-estimates"
			};
			return $http(req);
		};

		var calculateDtoHashDict = {};
		function _internalCalculateResidueQuantityConverted(dto) {
			var deferred = $q.defer();

			var dtoHash = objectHash(dto);
			if (calculateDtoHashDict[dtoHash]) {
				deferred.resolve(calculateDtoHashDict[dtoHash]);
				return deferred.promise;
			}

			var req = {
				method: "post",
				data: dto,
				url:
					Vgr.constants.newHostUrl +
					Vgr.resources.apiV1.quantitiesConversions.replace("[RESIDUE_CODE]", dto.ResidueCode)
			};

			var promise = $http(req);
			promise.then(function (response) {
				calculateDtoHashDict[dtoHash] = response;
			});

			return promise;
		}

		var _clearCalculateConversionCache = function () {
			calculateDtoHashDict = {};
		};

		function _getResponseConvertedQuantity(quantity) {
			return {
				data: {
					ImpossibleToConvert: false,
					Quantity: quantity
				}
			};
		}

		function _calculateConvertedQuantitySameGroup(originalQuantity, originalConversionFactor, desiredConversionFactor) {
			var deferred = $q.defer();

			var convertedQuantity = (originalQuantity * originalConversionFactor) / desiredConversionFactor;
			var convertedQuantityRounded = Vgr.util.round(convertedQuantity, 8);

			var response = _getResponseConvertedQuantity(convertedQuantityRounded);

			deferred.resolve(response);
			return deferred.promise;
		}

		var _calculateResidueQuantityConverted = function (
			originalQuantity,
			originalMeasureUnit,
			desiredMeasureUnit,
			residueCode,
			referenceDate
		) {
			if (originalMeasureUnit.Group === desiredMeasureUnit.Group) {
				var response = _calculateConvertedQuantitySameGroup(
					originalQuantity,
					originalMeasureUnit.ConversionFactor,
					desiredMeasureUnit.ConversionFactor
				);

				return response;
			}

			if (!originalQuantity) return $q.resolve(_getResponseConvertedQuantity(0));

			return _internalCalculateResidueQuantityConverted({
				Quantity: originalQuantity,
				UnitId: originalMeasureUnit.Id,
				FinalUnitId: desiredMeasureUnit.Id,
				ResidueCode: residueCode,
				ReferenceDate: referenceDate
			});
		};

		calculusService.calculateConversionFactor = _calculateConversionFactor;
		calculusService.calculateQuantityConverted = _calculateQuantityConverted;
		calculusService.calculateResidueQuantityConverted = _calculateResidueQuantityConverted;
		calculusService.calculateRequestEstimates = _calculateRequestEstimates;
		calculusService.clearCalculateConversionCache = _clearCalculateConversionCache;

		return calculusService;
	}
]);
