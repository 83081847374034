angular.module("vgresiduos").component("customReportFilterGroupingSection", {
	controller: "CustomReportFilterGroupingSectionCtrl",
	templateUrl:
		"views/customReports/components/customReportFilter/sections/customReportFilterGroupingSection_template.html",
	bindings: {
		setter: "<?",
		reportInformation: "<",
		clear: "<",
		callback: "<?",

		isDisabled: "<?"
	}
});
