angular.module("vgresiduos").component("systemReportInformationSelect", {
	controller: "SystemReportInformationSelectCtrl",
	templateUrl:
		"views/customReports/components/systemReportInformationSelect/systemReportInformationSelect_template.html",
	bindings: {
		reportType: "<",
		isDisabled: "<?",
		setter: "<?",
		callback: "<?",
		placeholder: "@?"
	}
});
