angular.module("vgresiduos").controller("CreateSupplierDocumentCtrl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `organizacao/fornecedores/${$stateParams.supplierCode}/documento`, [
				{ key: "docType", value: $stateParams.docType },
				{ key: "supDocId", value: $stateParams.supDocId },
				{ key: "requestId", value: $stateParams.requestId }
			]);
		};
	}
]);
