angular.module("vgresiduos").factory("areaService", [
	"$http",
	"accountService",
	function ($http, accountService) {
		"use strict";

		function getBaseRequestUrl() {
			const client = accountService.getCurrentClient();
			const organizationId = client.organization.id;
			const organizationUnitCode = client.code;
			return (
				Vgr.constants.coreHostUrl +
				Vgr.resources.core.areas
					.replace("[ORGANIZATION_ID]", organizationId)
					.replace("[ORGANIZATION_UNIT_CODE]", organizationUnitCode)
			);
		}

		const _listAreas = function GetAreaList(dto) {
			let parameters = "";
			parameters = $.param(dto);
			const req = {
				method: "get",
				data: dto,
				url: getBaseRequestUrl() + "?" + parameters
			};
			return $http(req);
		};

		const _getArea = function GetArea(id) {
			const req = {
				method: "get",
				url: getBaseRequestUrl() + id
			};
			return $http(req);
		};

		const _createArea = function UpdateArea(dto) {
			const req = {
				method: "post",
				data: dto,
				url: getBaseRequestUrl()
			};
			return $http(req);
		};

		const _updateArea = function UpdateArea(model) {
			const req = {
				method: "put",
				data: model.dto,
				url: getBaseRequestUrl() + model.id
			};
			return $http(req);
		};

		const _listAreaUsers = function UserList(id) {
			const req = {
				method: "get",
				url: getBaseRequestUrl() + id + "/users/"
			};
			return $http(req);
		};

		const _createAreaUsers = function AddUsersToArea(dto) {
			const req = {
				method: "post",
				data: dto.model,
				url: getBaseRequestUrl() + dto.id + "/users/"
			};
			return $http(req);
		};

		const _deleteAreaUser = function RemoveUsersFromArea(dto) {
			const req = {
				method: "delete",
				url: getBaseRequestUrl() + dto.areaId + "/users/" + dto.userId
			};
			return $http(req);
		};

		const _listOrganizationAreas = function () {
			const organizationId = accountService.getCurrentClient().organization.id;
			const req = {
				method: "get",
				url:
					Vgr.constants.coreHostUrl + Vgr.resources.core.organizationAreas.replace("[ORGANIZATION_ID]", organizationId)
			};
			return $http(req);
		};

		return {
			listAreas: _listAreas,
			getArea: _getArea,
			updateArea: _updateArea,
			createArea: _createArea,
			listAreaUsers: _listAreaUsers,
			createAreaUsers: _createAreaUsers,
			deleteAreaUser: _deleteAreaUser,

			listOrganizationAreas: _listOrganizationAreas
		};
	}
]);
