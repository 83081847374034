angular.module("vgresiduos").directive("pendingDestinationPopupButton", function () {
	"use strict";

	return {
		scope: true,
		replace: false,
		controller: "PendingDestinationPopupButtonCtrl",
		controllerAs: "ctrl",
		bindToController: {
			icon: "@",
			label: "@",
			buttonClasses: "@",
			fill: "@?",
			position: "@?",
			minWidth: "@?"
		},
		templateUrl:
			"views/destinationFollowup/baseComponents/pendingDestinationPopupButton/pendingDestinationPopupButton_template.html"
	};
});
