angular.module("vgresiduos").controller("IbamaRappCreateSummaryTransporterGridCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		var labels = $rootScope.labels;

		var columns = [
			{
				field: "CNPJ",
				displayName: function () {
					return labels.CNPJ;
				},
				width: 200
			},
			{
				field: "Transporter",
				displayName: function () {
					return labels.TRANSPORTER;
				}
			}
		];

		$scope.gridOptions = {
			columnDefinitions: columns,
			filterFields: ["CNPJ", "Transporter"],
			data: [],
			query: {
				order: "CNPJ",
				limit: 10,
				page: 1
			},
			promise: {},
			hasData: false,
			removeNew: true,
			removeDelete: true,
			errorLoading: false
		};

		this.$onInit = function () {
			$scope.gridOptions.data = $scope.ctrl.list;
			$scope.gridOptions.hasData = true;
		};
	}
]);
