angular.module("vgresiduos").controller("PriceTypeMultiSelectCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			listPriceTypes();
		};

		function listPriceTypes() {
			$scope.priceType = [
				{
					id: "revenue",
					displayText: $scope.labels.INCOME
				},
				{
					id: "expense",
					displayText: $scope.labels.EXPENSE
				}
			];
		}

		$scope.onChanged = function (selectedValues) {
			callback(selectedValues);
		};

		function callback(selectedValues) {
			if ($scope.$ctrl.callback) {
				$scope.$ctrl.callback(selectedValues);
			}
		}
	}
]);
