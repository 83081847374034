angular.module("vgresiduos").controller("DestinationSlipCtrl", [
	"$rootScope",
	"$scope",
	"dialogService",
	function ($rootScope, $scope, dialogService) {
		"use strict";
		$scope.labels = $rootScope.labels;

		$scope.isCurrentStep = function (step) {
			return step.Id == $scope.destinationConfig.currentStepPointer && !$scope.isCurrentStageCompleted();
		};

		$scope.isStepAvailable = function (step) {
			return step.Id <= $scope.destinationConfig.enabledStepPointer && !$scope.isCurrentStageCompleted();
		};

		$scope.toStep = function (step) {
			if (!$scope.isStepAvailable(step)) {
				return;
			}
			$scope.destinationConfig.currentStepPointer = step.Id;
		};

		$scope.showValidationPopup = function () {
			return $scope.validations.length ? true : false;
		};

		$scope.getNotificationLabel = function (notification) {
			return $scope.labels.DISPOSAL_NOTIFICATIONS[notification.Notification];
		};

		$scope.openDisposalReportsPopup = function () {
			const dialog = dialogService
				.showDialogFromTemplateV2(
					"views/destination/popups/disposalReportsPopup/disposalReportsPopup_template.html",
					"DisposalReportsPopupCtrl",
					"#disposal-popup",
					{ destinationModel: $scope.readOnlyModel },
					true
				)
				.then(
					function () {},
					function () {}
				);
			return ($scope.dialog = dialog);
		};

		$scope.openDisposalPendenciesPopup = function () {
			const dialog = dialogService
				.showDialogFromTemplateWithScope(
					null,
					"views/destination/popups/disposalPendenciesPopup/disposalPendenciesPopup_template.html",
					"DisposalPendenciesPopupCtrl",
					$scope,
					"#disposal-popup-small",
					false,
					function () {}
				)
				.then(
					function () {},
					function () {}
				);
			return ($scope.dialog = dialog);
		};

		$scope.openValidationPopup = function () {
			const dialog = dialogService
				.showDialogFromTemplateWithScope(
					null,
					"views/destination/popups/disposalValidationPopup/disposalValidationPopup_template.html",
					"DisposalValidationPopupCtrl",
					$scope,
					"#disposal-popup-small",
					false,
					function () {}
				)
				.then(
					function () {},
					function () {}
				);
			return ($scope.dialog = dialog);
		};

		$scope.showPendencyPopup = function () {
			return !isExecutingSolicitationStage();
		};

		function isExecutingSolicitationStage() {
			return (
				$scope.destinationConfig.currentStage.Id == $scope.stagesEnum.Solicitation && !$scope.isCurrentStageReview()
			);
		}
	}
]);
