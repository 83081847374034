angular.module("vgresiduos").controller("cadriBalanceReportCtrl", [
	"$rootScope",
	"$scope",
	"$state",
	"clientService",
	"conama313Service",
	function ($rootScope, $scope, $state, clientService, conama313Service) {
		"use strict";

		this.$onInit = function () {
			$scope.change = function (supplier) {
				if (!supplier) {
					$scope.CADRIBalanceReportFilter.CadriDocumentId = null;
				}
			};

			$scope.generateReport = function () {
				if ($scope.isCorporate) {
					formatCADRIBalanceCorporateReportFilter();
					addFilterToReport();
				} else if (validate()) {
					formatCADRIBalanceReportFilter();
					addFilterToReport();
				}
			};

			$scope.labels = $rootScope.labels;
			$scope.CADRIBalanceReportFilter = {};
			$scope.referenceDate = new Date().getFullYear() - 3;
			loadYearList();

			$scope.reportOptions = new Cmp.constructors.ReportOptionsV2(
				$rootScope.labels.BALANCE_REPORT_TITLE,
				clientService.generateCADRIBalance,
				$scope.CADRIBalanceReportDTO
			);

			$scope.isCorporate = $state.is("cadriBalanceCorporateReport") ? true : false;

			$scope.reportDescription = $scope.isCorporate
				? $scope.labels.CORPORATE_BALANCE_REPORT_DESCRIPTION
				: $scope.labels.BALANCE_REPORT_DESCRIPTION;
		};

		function addFilterToReport() {
			if ($scope.reportOptions) {
				$scope.reportOptions.filter = $scope.CADRIBalanceReportDTO;
				$scope.options.filter = $scope.CADRIBalanceReportDTO;
			}
			$scope.reportOptions.onFilterClick();
		}

		function loadYearList() {
			$scope.years = conama313Service.getYearReportFilterList();
			$scope.CADRIBalanceReportFilter.year = $scope.years[0].year;
		}

		const formatCADRIBalanceReportFilter = function () {
			$scope.CADRIBalanceReportDTO = {
				IsCorporate: false,
				CadriId: $scope.CADRIBalanceReportFilter.CadriDocumentId
					? $scope.CADRIBalanceReportFilter.CadriDocumentId
					: null,
				Year: $scope.CADRIBalanceReportFilter.year ? $scope.CADRIBalanceReportFilter.year : null,
				ResidueId: $scope.CADRIBalanceReportFilter.residue ? $scope.CADRIBalanceReportFilter.residue.id : null,
				SupplierId: $scope.CADRIBalanceReportFilter.supplier
					? $scope.CADRIBalanceReportFilter.supplier.supplierId
					: null,
				Format: "PDF"
			};
		};

		const formatCADRIBalanceCorporateReportFilter = function () {
			$scope.CADRIBalanceReportDTO = {
				IsCorporate: true,
				Year: $scope.CADRIBalanceReportFilter.year ? $scope.CADRIBalanceReportFilter.year : null,
				Format: "PDF"
			};
		};

		function validate() {
			if (
				$scope.CADRIBalanceReportFilter.year != "" ||
				$scope.CADRIBalanceReportFilter.residue != null ||
				$scope.CADRIBalanceReportFilter.supplier != null
			) {
				return true;
			}
			$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.CADRI_BALANCE_REPORT_ERROR);
			return false;
		}
	}
]);
