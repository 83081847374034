angular.module("vgresiduos").directive("disposalIntegration", function () {
	return {
		scope: true,
		controller: "DisposalIntegrationCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/destination/steps/disposalIntegration/disposalIntegration_template.html",
		bindToController: {
			systemsToIgnore: "=?",
			allowNewIntegrations: "=?",
			forcedAutoSelectSystems: "=?",
			destinationModel: "=?",
			readOnlyModel: "=?",
			listClientExternalSystems: "=?",
			onLoad: "=?",
			disableOptions: "=?"
		}
	};
});
