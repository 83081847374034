angular.module("vgresiduos").controller("OperationConfirmationPopupCtrl", [
	"$scope",
	"$rootScope",
	"dialogService",
	"localVariables",
	function ($scope, $rootScope, dialogService, localVariables) {
		"use strict";

		$scope.labels = $rootScope.labels;
		let hasExecuted = false;

		$scope.operationStatus = Vgr.enumerations.operationStatus;

		$scope.idField = localVariables.idField ? localVariables.idField : "id";
		$scope.descriptionField = localVariables.descriptionField ? localVariables.descriptionField : "name";
		$scope.popupTitle = localVariables.popupTitle;
		$scope.popupText = localVariables.popupText;
		$scope.list = localVariables.list;
		$scope.downloadAction = localVariables.downloadAction;
		$scope.confirmLabel = localVariables.confirmLabel ? localVariables.confirmLabel : $scope.labels.CONFIRM;
		$scope.cancelLabel = localVariables.cancelLabel ? localVariables.cancelLabel : $scope.labels.CANCEL;
		$scope.operationMethod = localVariables.operationMethod;
		$scope.onItemExecutionFinish = localVariables.onItemExecutionFinish;
		$scope.onBatchExecutionFinish = localVariables.onBatchExecutionFinish;
		$scope.isCancelButtonDisabled = localVariables.isCancelButtonDisabled;
		$scope.isLoading = false;

		$scope.runOperations = localVariables.runOperationsOnPopupOpen;

		$scope.getDto = localVariables.getDto;

		const cancelButton = {
			label: $scope.cancelLabel,
			class: "md-primary",
			click: function () {
				onCancelClick();
			},
			disabled: false
		};

		if ($scope.isCancelButtonDisabled) {
			cancelButton.isDisabled = $scope.isCancelButtonDisabled;
		}

		const confirmButton = {
			label: $scope.confirmLabel,
			class: "md-primary md-raised",
			click: function () {
				onConfirmClick();
			},
			disabled: false
		};

		$scope.popupActionButtons = [cancelButton, confirmButton];

		function toggleButtonsEnabledState(enabled) {
			$scope.popupActionButtons.forEach((button) => (button.disabled = !enabled));
		}

		$scope.onFinish = function (hasError, hasAnySucceeded) {
			if (!hasError && !localVariables.keepPopupOpenOnFinish) {
				dialogService.confirm();
			}

			if (!$scope.isCancelButtonDisabled) {
				$scope.popupActionButtons[0].disabled = false;
			}
			if ($scope.onBatchExecutionFinish) {
				$scope.onBatchExecutionFinish(hasError, hasAnySucceeded);
			}

			hasExecuted = hasAnySucceeded;
			$scope.runOperations = false;
		};

		function onConfirmClick() {
			toggleButtonsEnabledState(false);

			if (localVariables.confirmMethod) {
				localVariables.confirmMethod();
			}

			if (localVariables.runOperationsOnPopupOpen) {
				dialogService.confirm();
			} else {
				$scope.runOperations = true;
			}
		}

		function onCancelClick() {
			if (localVariables.cancelMethod) {
				$scope.popupActionButtons[0].isLoading = true;
				localVariables.cancelMethod().then(
					function () {
						$scope.popupActionButtons[0].isLoading = false;
					},
					function () {
						$scope.popupActionButtons[0].isLoading = false;
					}
				);
			} else {
				dialogService.cancel(hasExecuted);
			}
		}
	}
]);
