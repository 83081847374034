angular.module("vgresiduos").component("residueReportCharts", {
	controller: "ResidueReportChartsCtrl",
	templateUrl: "views/report/residue/base/residueReportCharts/residueReportCharts_template.html",
	bindings: {
		data: "<",
		headers: "<",
		chartTitle: "@",
		isLineChartCase: "<",
		forceHideLegend: "<?",
		yAxisDecimals: "<?",
		subtitles: "<?",
		isMoney: "<?",
		concatenateFirstDataQualifier: "<?"
	}
});
