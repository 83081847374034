angular.module("vgresiduos").controller("DestinationFollowupDetailsCtrl", [
	"$rootScope",
	"$scope",
	"$state",
	"$sce",
	"dialogService",
	"disposalService",
	function ($rootScope, $scope, $state, $sce, dialogService, disposalService) {
		"use strict";

		const disposalStatus = Vgr.enumerations.destination.status;
		const manifestStatus = Vgr.enumerations.destination.systemStatus;

		$scope.labels = $rootScope.labels;

		$scope.goToDisposalPage = function () {
			$state.go("destinationEdit", {
				id: $scope.ctrl.model.Id
			});
		};

		$scope.getQuantity = function () {
			const residue = $scope.ctrl.model.Residues[0];
			if (!residue) {
				return "";
			}

			return getResidueQuantity(residue);
		};

		$scope.getTotalAmountLabel = function () {
			if ($scope.isSingleResidueQuantity()) {
				return $sce.trustAsHtml(getResidueQuantity($scope.ctrl.model.Residues[0]));
			}
		};

		$scope.isSingleResidueQuantity = function () {
			return (
				$scope.ctrl.model.Residues.length == 1 &&
				$scope.ctrl.model.Residues[0].Quantities &&
				$scope.ctrl.model.Residues[0].Quantities.length == 1
			);
		};

		$scope.openPopupPrintOut = function () {
			const dialog = dialogService
				.showDialogFromTemplateV2(
					"views/destination/popups/disposalReportsPopup/disposalReportsPopup_template.html",
					"DisposalReportsPopupCtrl",
					"#disposal-popup",
					{ destinationModel: $scope.ctrl.model },
					true
				)
				.then(
					() => null,
					() => null
				);
			$scope.dialog = dialog;
		};

		$scope.getResidueNames = function () {
			let residueNames = "";
			for (const residue of $scope.ctrl.model.Residues) {
				residueNames += residue.ResidueName + ": " + getResidueQuantity(residue) + " <br> ";
			}

			return $sce.trustAsHtml(residueNames);
		};

		$scope.isRequested = function () {
			return getStatus() == disposalStatus.Requested;
		};

		$scope.showInfoMessage = function () {
			return $scope.getInfoMessage() != "";
		};

		$scope.getInfoMessage = function () {
			const messages = GetManifestsInfoMessages();
			return fromMessageArrayToString(messages);
		};

		function fromMessageArrayToString(messages) {
			if (messages.length > 0) {
				const message = messages.join("<br>");

				return $sce.trustAsHtml(message);
			}
			return "";
		}

		$scope.showLockedGatheringMessage = function () {
			return (
				$scope.disposalPreferences &&
				$scope.disposalPreferences.needUnlockDisposalGatheringStage &&
				$scope.ctrl.model.LockedGathering
			);
		};

		$scope.getLockedGatheringMessage = function () {
			const lockedGatheringValidationEnum = Vgr.enumerations.validation.GatheringIsLocked + "";
			let blockMessage = $scope.labels.DISPOSAL_VALIDATIONS_DESCRIPTION_ENUM[lockedGatheringValidationEnum];

			if (!$scope.unlockDisposalGatheringPermission) {
				blockMessage += " " + $scope.labels.CANT_GATHERING;
			}

			return $scope.ctrl.model.LockReason || blockMessage;
		};

		$scope.showErrorMessage = function () {
			return $scope.getErrorMessage() != "";
		};

		$scope.getErrorMessage = function () {
			const messages = GetManifestsErrorMessages();
			return fromMessageArrayToString(messages);
		};

		$scope.showWarningMessage = function () {
			return $scope.getWarningMessage() != "";
		};

		$scope.getWarningMessage = function () {
			const messages = GetManifestsWarningMessages();
			return fromMessageArrayToString(messages);
		};

		$scope.canShowManifestLine = function () {
			if (hasOnlyOneMtr()) {
				return true;
			} else if (
				$scope.ctrl.model.Manifests.length == 2 &&
				(IsLocal($scope.ctrl.model.Manifests[0]) || IsLocal($scope.ctrl.model.Manifests[1]))
			) {
				return true;
			} else {
				return false;
			}
		};

		$scope.getManifestCode = function () {
			if (hasOnlyOneMtr() && IsLocal($scope.ctrl.model.Manifests[0])) {
				return $scope.ctrl.model.Manifests[0].MtrCode;
			}

			const groupedManifests = $scope.ctrl.model.Manifests.reduce((grouped, manifest) => {
				if (IsLocal(manifest)) {
					return grouped;
				}
				grouped[manifest.Source.Id] = grouped[manifest.Source.Id] || [];
				grouped[manifest.Source.Id].push(manifest);
				return grouped;
			}, []);

			const manifestsCode = [];
			for (const index in groupedManifests) {
				const groupedManifest = groupedManifests[index];

				const codes = [];
				const label = getSourceFormated(groupedManifest[0]);
				for (const manifest of groupedManifest) {
					const code = manifest.MtrCode ? manifest.MtrCode : getNotProcessedMtrStatusText(manifest);
					if (codes.includes(code)) {
						continue;
					}
					codes.push(code);
				}

				manifestsCode.push(label + codes.join(", "));
			}

			return manifestsCode.join("<br>");
		};
		function getStatus() {
			return Vgr.enumerations.destination.status[$scope.ctrl.model.Status];
		}
		$scope.getFormattedDate = function () {
			let date = "";
			let label = "";
			if (getStatus() == Vgr.enumerations.destination.status.Requested) {
				label = $scope.labels.REQUESTED_AT;
				date = $scope.ctrl.model.RequestDate;
			} else if (getStatus() == Vgr.enumerations.destination.status.Gathered) {
				label = $scope.labels.GATHERED_AT;
				date = $scope.ctrl.model.GatheringDate;
			} else if (getStatus() == Vgr.enumerations.destination.status.Received) {
				label = $scope.labels.RECEIVED_AT;
				date = $scope.ctrl.model.ReceivingDate;
			} else if (getStatus() == Vgr.enumerations.destination.status.TemporaryStorageReceived) {
				label = $scope.labels.STORED_AT;
				date = $scope.ctrl.model.TemporaryStoragerReceivingDate;
			} else if (getStatus() == Vgr.enumerations.destination.status.Pending) {
				label = $scope.labels.DISPOSED_AT;
				date = $scope.ctrl.model.DisposalDate;
			}

			return label + " " + Vgr.util.dateToString(Vgr.util.getDateFromString(date));
		};

		function getSourceFormated(manifest) {
			return manifest.Source.Description + ": ";
		}

		function getResidueQuantity(residue) {
			let quantity = "";
			let unit = "";

			if (residue.Quantities.length === 0) {
				quantity = residue.RecipientQuantity;
				unit = residue.RecipientDescription;
			} else {
				const groupedQuantities = disposalService.groupDisposalQuantitieByMeasureUnit(residue.Quantities);
				const quantities = [];
				const units = [];
				for (const prop in groupedQuantities) {
					const groupedQuantity = groupedQuantities[prop];
					quantities.push(Vgr.util.sumPropArray(groupedQuantity, "Quantity"));
					units.push(groupedQuantity[0].MeasureUnit.Abbreviation);
					break;
				}
				quantity = quantities[0];
				unit = units[0];
			}
			return Vgr.util.formatUnitDecimalPlaces(quantity, 2) + " " + Vgr.util.cut(unit, true, 35, " ...");
		}

		function IsLocal(manifest) {
			return manifest.Source.Id == Vgr.enumerations.destination.systemSource.Local;
		}

		function GetManifestsInfoMessages() {
			const message = [];
			for (const manifest of $scope.ctrl.model.Manifests) {
				if (IsLocal(manifest)) {
					continue;
				}

				let warningMessage;
				if (getManifestStatusNormalized(manifest.Status) == manifestStatus.Received) {
					if (getStatus() == disposalStatus.Gathered || getStatus() == disposalStatus.TemporaryStorageReceived) {
						warningMessage = $scope.labels.DISPOSAL_FOLLOWUP_INFO_MESSAGE.MANIFEST_RECEIVED_ON_MTR_SYSTEM.replace(
							"[SYSTEM]",
							manifest.Source.Description
						);
						message.push(warningMessage);
					}
				} else if (getManifestStatusNormalized(manifest.Status) == manifestStatus.ReceivedTemporaryStorage) {
					if (getStatus() == disposalStatus.Gathered) {
						warningMessage =
							$scope.labels.DISPOSAL_FOLLOWUP_INFO_MESSAGE.MANIFEST_RECEIVED_TEMPORARY_STORAGER_ON_MTR_SYSTEM.replace(
								"[SYSTEM]",
								manifest.Source.Description
							);
						message.push(warningMessage);
					}
				} else if (getManifestStatusNormalized(manifest.Status) == manifestStatus.Disposed) {
					if (
						getStatus() == disposalStatus.Gathered ||
						getStatus() == disposalStatus.TemporaryStorageReceived ||
						getStatus() == disposalStatus.Received
					) {
						warningMessage = $scope.labels.DISPOSAL_FOLLOWUP_INFO_MESSAGE.MANIFEST_DISPOSED_ON_MTR_SYSTEM.replace(
							"[SYSTEM]",
							manifest.Source.Description
						);
						message.push(warningMessage);
					}
				}
			}

			return message;
		}

		function getManifestStatusNormalized(status) {
			return manifestStatus[status];
		}

		function GetManifestsErrorMessages() {
			const message = [];

			$scope.hasError = false;
			for (const manifest of $scope.ctrl.model.Manifests) {
				if (IsLocal(manifest)) {
					continue;
				}

				addMtrErrorMessages(manifest, message);
			}

			return message;
		}

		function GetManifestsWarningMessages() {
			const message = [];

			for (const manifest of $scope.ctrl.model.Manifests) {
				if (IsLocal(manifest)) {
					continue;
				}

				addProvisionalMtrMessage(manifest, message);
			}

			return message;
		}

		function addMtrErrorMessages(manifest, message) {
			if (manifest.Errors && manifest.Errors.length > 0) {
				manifest.Errors.forEach(function (error) {
					const errors = [{ Code: error.Code, Message: error.Message }];
					const alertMessage = $scope.labels.DISPOSAL_FOLLOWUP_ALERT_MESSAGE.INTEGRATION_ERROR.replace(
						"[ERROR]",
						disposalService.getIntegrationErrorMessageFromResponse(400, errors, manifest.Source.Description)
					);
					message.push(alertMessage);
				});
			}
		}

		function addProvisionalMtrMessage(manifest, message) {
			if (getManifestStatusNormalized(manifest.Status) == manifestStatus.Provisional) {
				const alertMessage = $scope.labels.DISPOSAL_FOLLOWUP_ALERT_MESSAGE.PROVISIONAL_MTR.replace(
					"[SYSTEM]",
					manifest.Source.Description
				).replace("[CODE]", manifest.ProvisionalMtrCode);
				message.push(alertMessage);
			}
		}

		$scope.hasMultipleExternalMtrs = function () {
			return $scope.ctrl.model.Manifests.filter((m) => !IsLocal(m)).length > 1;
		};

		function hasOnlyOneMtr() {
			return $scope.ctrl.model.Manifests.length == 1;
		}

		function getNotProcessedMtrStatusText(manifest) {
			if (manifest.Errors && manifest.Errors.length > 0) {
				return $scope.labels.DISPOSAL_SYSTEM_INTEGRATION_PENDING;
			} else {
				return $scope.labels.DISPOSAL_SYSTEM_INTEGRATION_PROCESSING;
			}
		}
	}
]);
