angular.module("vgresiduos").factory("internalGatheringTrackingService", [
	"engagementScoreService",
	function (engagementScoreService) {
		"use strict";

		function _trackExportCsv() {
			_track(Vgr.trackings.internal.gathering.exportCsv);
		}

		function _trackGoToNewInternalGatheringPage() {
			_track(Vgr.trackings.internal.gathering.goToNewPage);
		}

		function _trackCreateInternalGathering() {
			_track(Vgr.trackings.internal.gathering.create);
		}

		function _trackCreateSuccessInternalGathering() {
			_track(Vgr.trackings.internal.gathering.createSuccess);
		}

		function _trackPrintInternalGathering() {
			_track(Vgr.trackings.internal.gathering.print);
		}

		function _trackEditInternalGathering() {
			_track(Vgr.trackings.internal.gathering.edit);
		}

		function _track(event) {
			engagementScoreService.track(event);
		}

		return {
			trackExportCsv: _trackExportCsv,
			trackGoToNewInternalGatheringPage: _trackGoToNewInternalGatheringPage,
			trackCreateInternalGathering: _trackCreateInternalGathering,
			trackCreateSuccessInternalGathering: _trackCreateSuccessInternalGathering,
			trackPrintInternalGathering: _trackPrintInternalGathering,
			trackEditInternalGathering: _trackEditInternalGathering
		};
	}
]);
