angular.module("vgresiduos").controller("DisposalResidueFormFields", [
	"$scope",
	"$q",
	"$state",
	"$window",
	"$rootScope",
	"$sce",
	"httpService",
	"residueService",
	"stockService",
	"calculusService",
	"clientService",
	"clientStoragePreferenceService",
	function (
		$scope,
		$q,
		$state,
		$window,
		$rootScope,
		$sce,
		httpService,
		residueService,
		stockService,
		calculusService,
		clientService,
		clientStoragePreferenceService
	) {
		"use strict";

		$scope.massMeasureUnitGroup = Vgr.enumerations.measureUnit.Group.Mass;
		$scope.decimals = clientService.getDecimalPlaces();
		$scope.maxRecipients = 50;
		$scope.minRecipients = 1;

		this.$onInit = function () {
			if ($scope.ctrl.residueReferenceModel.ResidueId) {
				configureForEdition().then(function () {
					$scope.ctrl.disposalResidueModel.isLoading = false;
					setAdjustmentLabel($scope.ctrl.disposalResidueModel.HasManualUserAssociation);
				});
			}
		};

		function setAdjustmentLabel(hasManualUserAssociation) {
			if ($scope.showStockAdjustmentOptions() && !hasManualUserAssociation) {
				$scope.stockFormConfig.adjustmentTypeLabel = $scope.labels.CHOOSE_ADJUSTMENT_TYPE;
			} else if (
				$scope.shouldDecreasedStockQuantity($scope.ctrl.disposalResidueModel.Residue?.ResidueId) ||
				hasManualUserAssociation
			) {
				$scope.stockFormConfig.adjustmentTypeLabel = $scope.labels.DECREASE_RESIDUE_GENERATION_QUANTITY;
			} else {
				$scope.stockFormConfig.adjustmentTypeLabel = $scope.labels.MAINTAIN_RESIDUE_GENERATION_QUANTITY;
			}
		}

		function configureForEdition() {
			const deferred = $q.defer();
			$scope.ctrl.disposalResidueModel.isLoading = true;

			httpService
				.getDTOFromServiceV2(residueService.getClientResidue, $scope.ctrl.residueReferenceModel.ResidueId)
				.then(function (response) {
					const residue = response.Residue;

					$scope.ctrl.disposalResidueModel.Residue = {
						...residue,
						Id: residue.Id,
						ResidueId: residue.ResidueId,
						Code: residue.Code,
						Name: residue.Name,
						Dangerous: residue.Dangerous,
						UnitaryPriceConverted: residue.UnitaryPrice,
						ControlsStock: residue.ControlsStock,
						MinimumQuantity: residue.MinimumQuantity,
						MaximumQuantity: residue.MaximumQuantity
					};

					$scope.ctrl.disposalResidueModel.IsUsingPercentualEfficiencySaved =
						!!$scope.ctrl.residueReferenceModel.RecipientPercentualEfficiency;

					setResidueQuantities($scope.ctrl.disposalResidueModel.Residue);
					setRecipient(
						$scope.ctrl.residueReferenceModel.RecipientId,
						$scope.ctrl.residueReferenceModel.RecipientQuantity
					);
					setDisposalType($scope.ctrl.residueReferenceModel.DisposalTypeId);
					setArea($scope.ctrl.residueReferenceModel.AreaCode);

					deferred.resolve();
				});
			return deferred.promise;
		}

		function setRecipient(recipientId, recipientQuantity) {
			if (recipientId && !$scope.selectRecipientPermission) {
				$scope.disableRecipient = true;
			}

			$scope.ctrl.disposalResidueModel.RecipientId = recipientId;
			$scope.ctrl.disposalResidueModel.RecipientQuantity = recipientQuantity;
		}

		function setDisposalType(disposalTypeId) {
			if (disposalTypeId && !$scope.selectDisposalTypePermission) {
				$scope.disableDisposalType = true;
			}
			$scope.ctrl.disposalResidueModel.DisposalTypeId = disposalTypeId;
		}

		function setArea(areaCode) {
			$scope.ctrl.disposalResidueModel.AreaCode = areaCode;
		}

		$scope.residueSelected = function (residue) {
			if ($scope.disableResidueEdition) {
				return;
			}

			$scope.ctrl.disposalResidueModel.Quantities = [];
			if (residue) {
				setResidueQuantities(residue);
				setRecipient(residue.Recipient ? residue.Recipient.Id : null, 1);
				setDisposalType(residue.DefaultDisposalId);
			} else {
				$scope.ctrl.disposalResidueModel.ReferenceResidueId = null;
				setRecipient(null, null);
				setDisposalType(null);
			}
		};

		$scope.onDisposalTechChanged = function (disposalTech) {
			$scope.ctrl.disposalResidueModel.DisposalTypeId = disposalTech ? disposalTech.Id : null;
		};

		$scope.onClientRecipientChanged = function (clientRecipient) {
			const hasVolumeRecipient = clientRecipient && clientRecipient.volume;

			if (hasVolumeRecipient) {
				startCalculateVolumeEfficiency();
			} else {
				$scope.ctrl.disposalResidueModel.RecipientPercentualEfficiency = 0;
			}

			$scope.ctrl.disposalResidueModel.hasVolumeRecipient = hasVolumeRecipient;
			$scope.ctrl.disposalResidueModel.RecipientId = clientRecipient ? clientRecipient.id : null;
			$scope.ctrl.disposalResidueModel.RecipientGuidId = clientRecipient ? clientRecipient.recipientId : null;
		};

		$scope.measureUnitChanged = function (measureUnit, { quantityIndex }) {
			const referenceQuantity = $scope.ctrl.disposalResidueModel.Quantities[quantityIndex];

			if (referenceQuantity.QuantityLimit && referenceQuantity.MeasureUnit) {
				referenceQuantity.QuantityLimit = getConvertedQuantity(
					referenceQuantity.QuantityLimit,
					referenceQuantity.MeasureUnit,
					measureUnit
				);
				referenceQuantity.FreeStockFormatted = getFreeStockFormatted(referenceQuantity.QuantityLimit, measureUnit);
			}
		};

		function hasAnyTotalQuantities() {
			for (const quantity of $scope.ctrl.disposalResidueModel.Quantities) {
				if (quantity.Quantity) {
					return true;
				}
			}
			return false;
		}

		// scaling logic

		$scope.scalingProcess = Vgr.enumerations.scaling.process.Disposal;

		$scope.onTotalWeightChanged = function (weight, { quantityIndex }) {
			$scope.ctrl.disposalResidueModel.Quantities[quantityIndex].Quantity = weight;
			$scope.ctrl.disposalResidueModel.Quantities[quantityIndex].ScaleQuantity = true;
			$scope.ctrl.disposalResidueModel.Quantities[quantityIndex].IsEdited = true;
			updateAdjustedQuantity(quantityIndex);
			startCalculateVolumeEfficiency();
		};

		$scope.onTotalWeightChangedManually = function (weight, { quantityIndex }) {
			if ($scope.model.disableFinishButton) {
				$scope.model.disableFinishButton = false;
			}
			$scope.ctrl.disposalResidueModel.Quantities[quantityIndex].Quantity = weight;
			$scope.ctrl.disposalResidueModel.Quantities[quantityIndex].ScaleQuantity = false;
			$scope.ctrl.disposalResidueModel.Quantities[quantityIndex].IsEdited = true;
			updateTareWeight("", quantityIndex);
			updateGrossWeight("", quantityIndex);
			updateScaleAuxiliar1Weight("", quantityIndex);
			updateScaleAuxiliar2Weight("", quantityIndex);
			updateAdjustedQuantity(quantityIndex);
			startCalculateVolumeEfficiency();
		};

		function updateAdjustedQuantity(quantityIndex) {
			if (!$scope.controlStock($scope.ctrl.disposalResidueModel.Residue?.ResidueId)) {
				return;
			}

			if ($scope.isToAdjustStock() && $scope.ctrl.residueReferenceModel.Quantities) {
				const quantity = $scope.ctrl.disposalResidueModel.Quantities[quantityIndex];
				const referenceQuantity = $scope.ctrl.residueReferenceModel.Quantities.find(
					(qtd) => qtd.MeasureUnit.Group == quantity.MeasureUnitGroup
				);
				if (!referenceQuantity || referenceQuantity.Quantity <= quantity.Quantity) {
					$scope.ctrl.disposalResidueModel.Quantities[quantityIndex].AdjustedQuantity = null;
					return;
				}

				$scope.ctrl.disposalResidueModel.Quantities[quantityIndex].AdjustedQuantity =
					referenceQuantity.Quantity - quantity.Quantity;
			}
		}

		$scope.onUpdateWeightTwoStepsScaling = function (weight, { quantityIndex }) {
			updateGrossWeight(weight, quantityIndex);
		};

		$scope.onUpdateScaleAuxiliar1Weight = function (weight, { quantityIndex }) {
			updateScaleAuxiliar1Weight(weight, quantityIndex);
		};

		$scope.onUpdateScaleAuxiliar2Weight = function (weight, { quantityIndex }) {
			updateScaleAuxiliar2Weight(weight, quantityIndex);
		};

		$scope.onUpdateTareWeightTwoStepsScaling = function (weight, { quantityIndex }) {
			updateTareWeight(weight, quantityIndex);
		};

		$scope.clearScalingWeights = function ({ quantityIndex }) {
			updateTareWeight("", quantityIndex);
			updateGrossWeight("", quantityIndex);
			updateTotalWeight("", quantityIndex);
			updateScaleAuxiliar1Weight("", quantityIndex);
			updateScaleAuxiliar2Weight("", quantityIndex);
			$scope.ctrl.disposalResidueModel.Quantities[quantityIndex].ScaleQuantity = false;
			startCalculateVolumeEfficiency();
		};

		$scope.onMeasureUnitChanged = function (measureUnit, { quantityIndex }) {
			if (!$scope.isRecipientStockControlType()) {
				$scope.measureUnitChanged(measureUnit, { quantityIndex });
			}
			$scope.ctrl.disposalResidueModel.Quantities[quantityIndex].MeasureUnit = measureUnit;
			startCalculateVolumeEfficiency();
		};

		$scope.onScalingTypeChanged = function (scalingType, { quantityIndex }) {
			$scope.ctrl.disposalResidueModel.Quantities[quantityIndex].ScalingType = scalingType;
			$scope.ctrl.disposalResidueModel.Quantities[quantityIndex].ScaleQuantity = true;
		};

		function updateTotalWeight(totalWeight, quantityIndex) {
			$scope.ctrl.disposalResidueModel.Quantities[quantityIndex].Quantity = totalWeight;
			$scope.ctrl.disposalResidueModel.Quantities[quantityIndex].ScaleQuantity = true;
		}

		function updateGrossWeight(grossWeight, quantityIndex) {
			$scope.ctrl.disposalResidueModel.Quantities[quantityIndex].GrossQuantity = grossWeight;
			$scope.ctrl.disposalResidueModel.Quantities[quantityIndex].ScaleQuantity = grossWeight ? true : false;
		}

		function updateScaleAuxiliar1Weight(weight, quantityIndex) {
			$scope.ctrl.disposalResidueModel.Quantities[quantityIndex].ScaleAuxiliar1 = weight;
		}

		function updateScaleAuxiliar2Weight(weight, quantityIndex) {
			$scope.ctrl.disposalResidueModel.Quantities[quantityIndex].ScaleAuxiliar2 = weight;
		}

		function updateTareWeight(tareWeight, quantityIndex) {
			$scope.ctrl.disposalResidueModel.Quantities[quantityIndex].TareQuantity = tareWeight;
			$scope.ctrl.disposalResidueModel.Quantities[quantityIndex].ScaleQuantity = tareWeight ? true : false;
		}

		function startCalculateVolumeEfficiency() {
			if ($scope.ctrl.disposalResidueModel.TimerForCalculateVolumeEfficiency) {
				clearTimeout($scope.ctrl.disposalResidueModel.TimerForCalculateVolumeEfficiency);
			}
			$scope.ctrl.disposalResidueModel.TimerForCalculateVolumeEfficiency = setTimeout(
				calculateVolumeEfficiency(),
				1000
			);
		}

		function calculateVolumeEfficiency() {
			if ($scope.ctrl.disposalResidueModel.IsUsingPercentualEfficiencySaved) {
				$scope.ctrl.disposalResidueModel.RecipientPercentualEfficiency =
					$scope.ctrl.residueReferenceModel.RecipientPercentualEfficiency;
				$scope.ctrl.disposalResidueModel.IsUsingPercentualEfficiencySaved = false;

				return 1;
			}

			if (!getTotalRecipients() || !$scope.ctrl.disposalResidueModel.Recipient?.volume) {
				return;
			}

			if (!$scope.ctrl.disposalResidueModel.RecipientQuantity || !hasAnyTotalQuantities()) {
				$scope.ctrl.disposalResidueModel.RecipientPercentualEfficiency = "";
			} else {
				getTotalWeightInM3().then(
					(totalQuantityM3) => {
						getTotalVolumeRecipientInM3().then((totalVolumeM3) => {
							const volumeEfficiency = (totalQuantityM3 * 100) / totalVolumeM3;
							$scope.ctrl.disposalResidueModel.RecipientPercentualEfficiency = volumeEfficiency;
						});
					},
					function (errors) {
						if (!errors) {
							$scope.ctrl.disposalResidueModel.RecipientPercentualEfficiency = null;
						}
					}
				);
			}
		}

		$scope.onBlurQuantityRecipients = function (newVal) {
			$scope.ctrl.disposalResidueModel.RecipientQuantity = newVal;
			startCalculateVolumeEfficiency();
		};

		$scope.showQuantityFreeStockFormatted = function () {
			if (!$scope.ctrl.disposalResidueModel.Residue) {
				return false;
			}
			return (
				$scope.controlStock($scope.ctrl.disposalResidueModel.Residue?.ResidueId) &&
				!$scope.isRecipientStockControlType()
			);
		};

		function getTotalWeightInM3() {
			const quantities = $scope.ctrl.disposalResidueModel.Quantities;
			const residueCode = $scope.ctrl.disposalResidueModel.Residue.Code;
			const conversionsPromises = [];

			for (const quantity of quantities) {
				if (quantity.Quantity && quantity.MeasureUnit) {
					conversionsPromises.push(
						calculusService.calculateResidueQuantityConverted(
							quantity.Quantity,
							quantity.MeasureUnit,
							Vgr.constants.measureUnit.M3,
							residueCode,
							$scope.referenceDate
						)
					);
				}
			}

			if (conversionsPromises.length == 0) {
				return $q.reject();
			}

			const deferred = $q.defer();
			let impossibleToConvert = false;
			$q.all(conversionsPromises).then(
				(responses) => {
					const totalQuantityM3 = responses.reduce((prev, current) => {
						if (current.data.ImpossibleToConvert) {
							impossibleToConvert = true;
							return prev;
						}
						return prev + current.data.Quantity;
					}, 0);
					if (impossibleToConvert) {
						deferred.reject();
					} else {
						deferred.resolve(totalQuantityM3);
					}
				},
				function (errors) {
					deferred.reject(errors);
				}
			);

			return deferred.promise;
		}

		function getTotalVolumeRecipientInM3() {
			const residueCode = $scope.ctrl.disposalResidueModel.Residue.Code;
			const originalMeasureUnit =
				$scope.ctrl.disposalResidueModel.Recipient.volumeMeasureUnitId === Vgr.constants.measureUnit.M3.Id
					? Vgr.constants.measureUnit.M3
					: Vgr.constants.measureUnit.LITER;

			const deferred = $q.defer();
			calculusService
				.calculateResidueQuantityConverted(
					$scope.ctrl.disposalResidueModel.Recipient.volume,
					originalMeasureUnit,
					Vgr.constants.measureUnit.M3,
					residueCode,
					$scope.referenceDate
				)
				.then((response) => {
					const volumeRecipientM3 = getTotalRecipients() * response.data.Quantity;
					deferred.resolve(volumeRecipientM3);
				});
			return deferred.promise;
		}

		function getTotalRecipients() {
			if ($scope.isRecipientStockControlType()) {
				return $scope.ctrl.disposalResidueModel.InternalGatheringRecipientQuantities.reduce((prev, current) => {
					return prev + (current.RecipientQuantity ?? 0);
				}, 0);
			}

			return Number(`${$scope.ctrl.disposalResidueModel.RecipientQuantity}`.replaceAll(".", "")) ?? 0;
		}

		$scope.goToInternalGatheringScream = function () {
			const params = { residuo: null, nomeResiduo: null, tab: 2 };
			if ($scope.ctrl.disposalResidueModel.Residue) {
				params.residuo = $scope.ctrl.disposalResidueModel.Residue.ResidueId;
				params.nomeResiduo = $scope.ctrl.disposalResidueModel.Residue.Name;
			}

			clientStoragePreferenceService.getStoragePreferences().then(function (storagePreference) {
				if (
					storagePreference.internalTransferType ==
					Vgr.enumerations.internalGathering.internalTransferType.TwoStepTransfer
				) {
					params.tab = 1;
				} else if (
					storagePreference.internalTransferType == Vgr.enumerations.internalGathering.internalTransferType.NoTransfer
				) {
					params.tab = 0;
				}

				$window.open($state.href("storageList", params), "_blank");
			});
		};

		$scope.refreshStock = function () {
			$scope.ctrl.disposalResidueModel.loadingStock = true;
			getResidueStock($scope.ctrl.disposalResidueModel.Residue?.ResidueId).then(function (residueStock) {
				if (residueStockWasChanged(residueStock)) {
					configureResidueStock(residueStock);
					if ($scope.model.disableFinishButton) {
						$scope.model.disableFinishButton = false;
					}
					$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $scope.labels.STOCK_CHANGED);
				} else {
					$rootScope.$broadcast(Vgr.constants.evtShowWarningMessage, $scope.labels.STOCK_NOT_CHANGED);
				}

				$scope.ctrl.disposalResidueModel.loadingStock = false;
			});
		};

		function residueStockWasChanged(residueStock) {
			if (residueStock.Stocks?.length > 0) {
				return $scope.ctrl.disposalResidueModel.Quantities.some((qtd) => {
					const foundStock = residueStock.Stocks.find((stock) => stock.MeasureUnit.Group === qtd.MeasureUnit.Group);
					return !foundStock || foundStock.FreeStock !== qtd.QuantityLimit;
				});
			}

			if (residueStock.RecipientStocks?.length > 0) {
				return $scope.ctrl.disposalResidueModel.InternalGatheringRecipientQuantities.some((qtd) => {
					const foundStock = residueStock.RecipientStocks.find((stock) => stock.RecipientId === qtd.RecipientId);
					return !foundStock || foundStock.FreeStock !== qtd.QuantityLimit;
				});
			}

			return false;
		}

		$scope.showDisposalResidueStorages = function () {
			if (!$scope.disableResidueEdition || !$scope.ctrl.disposalResidueModel.Quantities) {
				return;
			}
			if (!$scope.controlStock($scope.ctrl.disposalResidueModel.Residue?.ResidueId)) {
				return false;
			}
			if (!$scope.ctrl.disposalResidueModel.Storages || $scope.ctrl.disposalResidueModel.Storages.length == 0) {
				return false;
			}
			return $scope.ctrl.residueReferenceModel.Quantities?.find((refQtd) =>
				$scope.ctrl.disposalResidueModel.Quantities?.find((qtd) => refQtd.MeasureUnit?.Group == qtd.MeasureUnit?.Group)
			);
		};

		$scope.anyQuantityWasEdited = function () {
			const someQuantityWasEdited = $scope.ctrl.disposalResidueModel.Quantities.some((qtd) => qtd.IsEdited);
			const allQuantitiesEqualsReference = $scope.ctrl.residueReferenceModel.Quantities.every((refQtt) =>
				$scope.ctrl.disposalResidueModel.Quantities.find(
					(qtt) => qtt.MeasureUnit?.Group == refQtt.MeasureUnit?.Group && qtt.Quantity == refQtt.Quantity
				)
			);
			return someQuantityWasEdited || !allQuantitiesEqualsReference;
		};

		$scope.getAdjustmentLabel = function () {
			const mappedQuantities = $scope.ctrl.disposalResidueModel.Quantities.filter(
				(qtd) => qtd.AdjustedQuantity > 0
			).map(
				(qtd) =>
					`<b>${Vgr.util.formatUnitDecimalPlaces(qtd.AdjustedQuantity, $scope.decimals)} ${
						qtd.MeasureUnit.Abbreviation
					}</b>`
			);
			const label = $scope.stockFormConfig.adjustmentTypeLabel.replace("{0}", mappedQuantities.join(", "));
			return $sce.trustAsHtml(Vgr.util.replaceAt(label, " e ", label.lastIndexOf(", ")));
		};

		const recipientQuantityWatch = $scope.$watch("disposalResidueModel.RecipientQuantity", function () {
			startCalculateVolumeEfficiency();
		});

		$scope.$on("$destroy", function () {
			// disable the listener
			recipientQuantityWatch();
			// nullify the DOM-bound model
			$scope.domElement = null;
		});

		///////////////////////////////////////////////////////////////////////////////////
		// STOCK CONFIGURATION

		function getConvertedQuantity(originalQuantity, originalMeasureUnit, desiredMeasureUnit) {
			const convertedQuantity =
				(originalQuantity * originalMeasureUnit.ConversionFactor) / desiredMeasureUnit.ConversionFactor;
			return Vgr.util.round(convertedQuantity, 8);
		}

		function calculateAreaSetter(residueStock) {
			if ($scope.ctrl.disposalResidueModel.AreaCode) {
				return;
			}

			if (residueStock.Areas && residueStock.Areas.length == 1) {
				setArea(residueStock.Areas[0].Code);
			}
		}

		function getResidueStock(residueId) {
			const deferred = $q.defer();
			const dto = {
				residueId,
				model: {
					disposalIdToExclude: $scope.disposalId,
					referenceDate: Vgr.date.toISOString($scope.referenceDate)
				}
			};
			httpService.getDTOFromServiceV2(stockService.getResidueStock, dto).then(
				function (data) {
					deferred.resolve(data.ResidueStock);
				},
				function () {
					deferred.resolve([]);
				}
			);
			return deferred.promise;
		}

		function setResidueQuantities(residue) {
			if (residue.Id == $scope.ctrl.disposalResidueModel.ReferenceResidueId) {
				return;
			}

			if ($scope.controlStock(residue.ResidueId)) {
				configureQuantitiesToControlStock(residue);
			} else {
				configureQuantitiesToNotControlStock(residue);
			}
		}

		function configureQuantitiesToControlStock(residue) {
			$scope.ctrl.disposalResidueModel.loadingStock = true;

			getResidueStock(residue.ResidueId).then(function (residueStock) {
				$scope.ctrl.disposalResidueModel.Quantities = [];
				$scope.ctrl.disposalResidueModel.ReferenceResidueId = residueStock.ResidueId;
				configureResidueStock(residueStock);
				calculateAreaSetter(residueStock);
				if ($scope.model.disableFinishButton) {
					$scope.model.disableFinishButton = false;
				}
				$scope.ctrl.disposalResidueModel.loadingStock = false;
			});
		}

		function configureResidueStock(residueStock) {
			configureResidueStockBasedOnStockType(residueStock);
			if ($scope.disableResidueEdition) {
				configureResidueStockForEdition();
			}

			createEmptyQuantitiesIfNecessary();

			refreshQuantityFields();
		}

		function configureResidueStockForEdition() {
			for (const recipientQuantity of $scope.ctrl.residueReferenceModel.InternalGatheringRecipientQuantities ?? []) {
				createDisposalResidueRecipientQuantityByReferenceModelIfNecessary(recipientQuantity);
			}

			const restrictMeasureUnitGroup = !$scope.isRecipientStockControlType();
			for (const quantity of $scope.ctrl.residueReferenceModel.Quantities ?? []) {
				createDisposalResidueQuantityByReferenceModelIfNecessary(quantity, restrictMeasureUnitGroup);
			}
		}

		function createEmptyQuantitiesIfNecessary() {
			if (
				$scope.isRecipientStockControlType() &&
				$scope.ctrl.disposalResidueModel.InternalGatheringRecipientQuantities.length == 0
			) {
				createEmptyDisposalResidueRecipientQuantity();
			}

			if ($scope.ctrl.disposalResidueModel.Quantities.length == 0) {
				createEmptyDisposalResidueQuantity($scope.ctrl.disposalResidueModel.Residue.MeasureUnit);
			}
		}

		function configureResidueStockBasedOnStockType(residueStock) {
			if ($scope.isRecipientStockControlType()) {
				setResidueQuantityBasedOnRecipientStock(residueStock.RecipientStocks ?? []);
			} else {
				setResidueQuantityBasedOnStock(residueStock.Stocks ?? []);
			}
		}

		function configureQuantitiesToNotControlStock(residue) {
			$scope.ctrl.disposalResidueModel.ReferenceResidueId = residue.Id;
			$scope.ctrl.disposalResidueModel.Quantities = [];

			const quantitiesConverted = residueService.getResidueQuantitiesByAreaNotControlStock(
				$scope.ctrl.residueReferenceModel.Quantities
			);
			if (quantitiesConverted.length > 0) {
				for (const quantity of quantitiesConverted) {
					$scope.ctrl.disposalResidueModel.Quantities.push({
						...quantity,
						MeasureUnitId: quantity.MeasureUnit ? quantity.MeasureUnit.Id : null,
						MeasureUnitGroup: null,
						QuantityLimit: 0,
						FreeStockFormatted: getFreeStockFormatted(0, quantity.MeasureUnit)
					});
				}
			}

			if ($scope.ctrl.disposalResidueModel.Quantities.length == 0) {
				createEmptyDisposalResidueQuantity(residue.MeasureUnit);
			}

			refreshQuantityFields();
		}

		function createDisposalResidueQuantityByReferenceModelIfNecessary(quantity, restrictMeasureUnitGroup) {
			if ($scope.ctrl.disposalResidueModel.Quantities.some((qt) => qt.MeasureUnitGroup == quantity.MeasureUnit.Group)) {
				return;
			}

			if (quantity.Quantity || quantity.TareQuantity || quantity.ScaleAuxiliar1 || quantity.ScaleAuxiliar2) {
				$scope.ctrl.disposalResidueModel.Quantities.push({
					Quantity: quantity.Quantity,
					TareQuantity: quantity.TareQuantity,
					GrossQuantity: quantity.GrossQuantity,
					ScaleQuantity: quantity.ScaleQuantity,
					ScalingType: quantity.ScalingType,
					ScaleAuxiliar1: quantity.ScaleAuxiliar1,
					ScaleAuxiliar2: quantity.ScaleAuxiliar2,
					MeasureUnit: quantity.MeasureUnit,
					MeasureUnitId: quantity.MeasureUnit?.Id,
					MeasureUnitGroup: restrictMeasureUnitGroup ? quantity.MeasureUnit?.Group : null,
					AdjustedQuantity: quantity.AdjustedQuantity,
					IsEdited: quantity.IsEdited,
					QuantityLimit: 0,
					FreeStockFormatted: getFreeStockFormatted(0, quantity.MeasureUnit)
				});
			}
		}

		// Stock by Quantity

		function setResidueQuantityBasedOnStock(stocks) {
			for (const stock of stocks) {
				let foundQuantity = findCorrespondingQuantity(stock, $scope.ctrl.disposalResidueModel.Quantities);
				if (foundQuantity) {
					setCorrespondingDisposalResidueQuantityBasedOnStock(stock, foundQuantity);
				} else {
					foundQuantity = findCorrespondingQuantity(stock, $scope.ctrl.residueReferenceModel.Quantities);
					if (foundQuantity) {
						setCorrespondingResidueReferenceQuantityBasedOnStock(foundQuantity, stock);
					} else {
						createEmptyDisposalResidueQuantityBasedOnStock(stock);
					}
				}
			}
		}

		function getFreeStockFormatted(freeStock, measureUnit) {
			if (!freeStock || freeStock === 0 || !measureUnit) {
				return Vgr.util.formatUnitDecimalPlaces(0, $scope.decimals);
			}

			return Vgr.util.formatUnitDecimalPlaces(freeStock, $scope.decimals) + " " + measureUnit.Abbreviation;
		}

		function findCorrespondingQuantity(stock, quantities) {
			if (!quantities) {
				return;
			}

			return quantities.find((qt) => qt.MeasureUnit.Group == stock.MeasureUnit.Group);
		}

		function createEmptyDisposalResidueQuantity(measureUnit) {
			$scope.ctrl.disposalResidueModel.Quantities.push({
				Quantity: null,
				MeasureUnit: measureUnit,
				MeasureUnitId: measureUnit?.Id,
				MeasureUnitGroup: null,
				QuantityLimit: 0,
				FreeStockFormatted: getFreeStockFormatted(0, measureUnit)
			});
		}

		function setCorrespondingDisposalResidueQuantityBasedOnStock(stock, correspondingQuantity) {
			const quantityLimit = getConvertedQuantity(stock.FreeStock, stock.MeasureUnit, correspondingQuantity.MeasureUnit);
			const freeStockFormatted = getFreeStockFormatted(quantityLimit, correspondingQuantity.MeasureUnit);

			correspondingQuantity.FreeStockFormatted = freeStockFormatted;
			correspondingQuantity.QuantityLimit = quantityLimit;
		}

		function setCorrespondingResidueReferenceQuantityBasedOnStock(quantityRef, stock) {
			const quantityLimit = getConvertedQuantity(stock.FreeStock, stock.MeasureUnit, quantityRef.MeasureUnit);
			const freeStockFormatted = getFreeStockFormatted(quantityLimit, quantityRef.MeasureUnit);

			$scope.ctrl.disposalResidueModel.Quantities.push({
				Quantity: quantityRef.Quantity,
				TareQuantity: quantityRef.TareQuantity,
				GrossQuantity: quantityRef.GrossQuantity,
				ScaleQuantity: quantityRef.ScaleQuantity,
				ScalingType: quantityRef.ScalingType,
				ScaleAuxiliar1: quantityRef.ScaleAuxiliar1,
				ScaleAuxiliar2: quantityRef.ScaleAuxiliar2,
				MeasureUnit: quantityRef.MeasureUnit,
				MeasureUnitId: quantityRef.MeasureUnit?.Id,
				MeasureUnitGroup: quantityRef.MeasureUnit?.Group,
				IsEdited: quantityRef.IsEdited,
				AdjustedQuantity: quantityRef.AdjustedQuantity,
				QuantityLimit: quantityLimit,
				FreeStockFormatted: freeStockFormatted
			});
		}

		function createEmptyDisposalResidueQuantityBasedOnStock(stock) {
			$scope.ctrl.disposalResidueModel.Quantities.push({
				MeasureUnit: stock.MeasureUnit,
				MeasureUnitId: stock.MeasureUnit?.Id,
				MeasureUnitGroup: stock.MeasureUnit?.Group,
				QuantityLimit: stock.FreeStock,
				FreeStockFormatted: getFreeStockFormatted(stock.FreeStock, stock.MeasureUnit)
			});
		}

		function refreshQuantityFields() {
			if ($scope.ctrl.disposalResidueModel.Quantities) {
				for (let i = 0; i < $scope.ctrl.disposalResidueModel.Quantities.length; ++i) {
					$scope.ctrl.disposalResidueModel.QuantityFields[i] = {
						MeasureUnitGroup: $scope.ctrl.disposalResidueModel.Quantities[i].MeasureUnitGroup,
						MeasureUnit: $scope.ctrl.disposalResidueModel.Quantities[i].MeasureUnit,
						Quantity: $scope.ctrl.disposalResidueModel.Quantities[i].Quantity,
						TareQuantity: $scope.ctrl.disposalResidueModel.Quantities[i].TareQuantity,
						GrossQuantity: $scope.ctrl.disposalResidueModel.Quantities[i].GrossQuantity,
						ScaleQuantity: $scope.ctrl.disposalResidueModel.Quantities[i].ScaleQuantity,
						ScalingType: $scope.ctrl.disposalResidueModel.Quantities[i].ScalingType,
						ScaleAuxiliar1: $scope.ctrl.disposalResidueModel.Quantities[i].ScaleAuxiliar1,
						ScaleAuxiliar2: $scope.ctrl.disposalResidueModel.Quantities[i].ScaleAuxiliar2,
						AreaCode: $scope.ctrl.disposalResidueModel.Quantities[i].AreaCode,
						AdjustedQuantity: $scope.ctrl.disposalResidueModel.Quantities[i].AdjustedQuantity,
						IsEdited: $scope.ctrl.disposalResidueModel.Quantities[i].IsEdited
					};
				}
			}
		}

		// Stock by InternalGatheringRecipientQuantity

		function setResidueQuantityBasedOnRecipientStock(recipientStocks) {
			$scope.ctrl.disposalResidueModel.InternalGatheringRecipientQuantities =
				$scope.ctrl.disposalResidueModel.InternalGatheringRecipientQuantities.filter((igr) => igr.RecipientId);

			for (const stock of recipientStocks) {
				let recipientQtd = findCorrespondingRecipientQuantity(
					stock,
					$scope.ctrl.disposalResidueModel.InternalGatheringRecipientQuantities
				);
				if (recipientQtd) {
					setCorrespondingDisposalResidueQuantityBasedOnRecipientStock(stock, recipientQtd);
				} else {
					recipientQtd = findCorrespondingRecipientQuantity(
						stock,
						$scope.ctrl.residueReferenceModel.InternalGatheringRecipientQuantities
					);
					if (recipientQtd) {
						setCorrespondingResidueReferenceQuantityBasedOnRecipientStock(recipientQtd, stock);
					} else if (!$scope.isAlreadyCreatedResidue) {
						createEmptyDisposalResidueQuantityBasedOnRecipientStock(stock);
					}
				}
			}
		}

		function getFreeRecipientStockFormatted(freeStock, recipientName) {
			if (!freeStock) {
				return "0";
			}

			return `${freeStock} ${recipientName}`.trim();
		}

		function findCorrespondingRecipientQuantity(stock, quantities) {
			if (!quantities) {
				return;
			}

			return quantities.find((qt) => qt.RecipientId == stock.RecipientId);
		}

		function setCorrespondingDisposalResidueQuantityBasedOnRecipientStock(stock, correspondingQuantity) {
			correspondingQuantity.FreeStockFormatted = getFreeRecipientStockFormatted(stock.FreeStock, stock.RecipientName);
			correspondingQuantity.QuantityLimit = stock.FreeStock;
		}

		function setCorrespondingResidueReferenceQuantityBasedOnRecipientStock(referenceQuantity, stock) {
			$scope.ctrl.disposalResidueModel.InternalGatheringRecipientQuantities.push({
				RecipientId: referenceQuantity.RecipientId,
				RecipientQuantity: referenceQuantity.RecipientQuantity,
				QuantityLimit: stock.FreeStock,
				FreeStockFormatted: getFreeRecipientStockFormatted(stock.FreeStock, stock.RecipientName)
			});
		}

		function createEmptyDisposalResidueQuantityBasedOnRecipientStock(stock) {
			$scope.ctrl.disposalResidueModel.InternalGatheringRecipientQuantities.push({
				RecipientQuantity: null,
				RecipientId: stock.RecipientId,
				QuantityLimit: stock.FreeStock,
				FreeStockFormatted: getFreeRecipientStockFormatted(stock.FreeStock, stock.RecipientName)
			});
		}

		function createDisposalResidueRecipientQuantityByReferenceModelIfNecessary(recipientQuantity) {
			if (
				$scope.ctrl.disposalResidueModel.InternalGatheringRecipientQuantities.some(
					(qt) => qt.RecipientId == recipientQuantity.RecipientId
				)
			) {
				return;
			}

			$scope.ctrl.disposalResidueModel.InternalGatheringRecipientQuantities.push({
				RecipientId: recipientQuantity.RecipientId,
				RecipientQuantity: recipientQuantity.RecipientQuantity,
				QuantityLimit: 0,
				FreeStockFormatted: 0
			});
		}

		function createEmptyDisposalResidueRecipientQuantity() {
			$scope.ctrl.disposalResidueModel.InternalGatheringRecipientQuantities.push({
				RecipientQuantity: null,
				RecipientId: null,
				QuantityLimit: 0,
				FreeStockFormatted: getFreeRecipientStockFormatted(0, "")
			});
		}

		$scope.getMaxRecipientQuantity = function (recipientQuantity) {
			const limit = parseInt(recipientQuantity.QuantityLimit);
			return isNaN(limit) ? $scope.maxRecipients : limit + $scope.maxRecipients;
		};
	}
]);
