angular.module("vgresiduos").component("measureUnit", {
	controller: "MeasureUnitCtrl",
	templateUrl: "views/components/fields/measureUnit/measureUnit_template.html",
	bindings: {
		onlyPricingUnits: "<?",
		onlyResidueUnits: "<?",
		onlySameGroup: "<?",
		isDisabled: "<?",
		isRequired: "<?",
		isCore: "<?",
		emptyField: "<?",
		measureUnitGroup: "<?",
		identifier: "<?",
		setterId: "<?",
		model: "=?",
		onChange: "<?"
	}
});
