angular.module("vgresiduos").component("usersSelect", {
	controller: "UsersSelectCtrl",
	templateUrl: "views/components/fields/usersSelect/usersSelect_template.html",
	bindings: {
		model: "=",
		label: "@?",
		excluded: "<?",
		setterId: "<?",
		setterCode: "<?",
		setterModel: "<?",
		emptyField: "<?",
		isDisabled: "<?"
	}
});
