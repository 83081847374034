angular.module("vgresiduos").controller("ResidueCodeTextFieldCtrl", [
	"$scope",
	"$rootScope",
	"disposalPreferenceService",
	function ($scope, $rootScope, disposalPreferenceService) {
		"use strict";

		$scope.helperText = $rootScope.labels.MAX_LENGTH_RESIDUE_CODE;

		this.$onInit = function () {
			$scope.label = $scope.ctrl.label || $rootScope.labels.CNPJ_CPF_ONLY_NUMBERS;
			if ($scope.ctrl.isRequired) {
				$scope.label += "*";
			}

			disposalPreferenceService.getDisposalPreferences().then(function (preferences) {
				$scope.isToSendResidueCodeOnMtrIntegration = preferences.sendResidueCodeMtrIntegration;
			});
		};

		$scope.canEdit = function () {
			return ($scope.ctrl.canEdit && JSON.parse($scope.ctrl.canEdit)) || !$scope.isToSendResidueCodeOnMtrIntegration;
		};
	}
]);
