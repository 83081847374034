angular.module("vgresiduos").controller("CustomReportCreateCtrl", [
	"$scope",
	"$rootScope",
	"$state",
	"customReportService",
	"httpService",
	"engagementScoreService",
	"reportUtilService",
	function ($scope, $rootScope, $state, customReportService, httpService, engagementScoreService, reportUtilService) {
		$scope.labels = $rootScope.labels;
		const identificationIndex = 0;
		const configurationsIndex = 1;
		const conclusionIndex = 2;

		$scope.model = {
			ReportName: null,
			ReportType: null
		};

		$rootScope.contentNavBar = {
			title: $scope.labels.REPORTS,
			breadcrumb: $scope.labels.NEW_REPORT,
			back: function () {
				$state.go("customReport");
			},
			valid: true,
			customButtons: [],
			buttons: []
		};

		$scope.steps = [
			{
				title: $scope.labels.IDENTIFICATION,
				buttons: []
			},
			{
				title: $scope.labels.CONFIGURATIONS,
				isDisabled: !$scope.model.ReportName || !$scope.model.ReportType,
				buttons: []
			},
			{
				title: $scope.labels.CONCLUSION,
				isDisabled: true,
				buttons: []
			}
		];

		$scope.isCreatingReport = false;

		function createCustomReport() {
			const createDto = getCreateDto();
			if (isValid(createDto)) {
				$scope.isCreatingReport = true;
				httpService.postDTOToServiceV2(customReportService.createCustomReport, createDto).then(
					function (ret) {
						$scope.model.id = ret;
						$scope.isCreatingReport = false;
						engagementScoreService.track(Vgr.trackings.bi.reports.createSavedReport);
						redirectToLastStep();
					},
					function () {
						$scope.isCreatingReport = false;
					}
				);
			} else {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.FILL_REQUIRED_FIELDS);
			}
		}

		function redirectToLastStep() {
			$scope.steps[identificationIndex].isDisabled = true;
			$scope.steps[configurationsIndex].isDisabled = true;
			$scope.steps[conclusionIndex].isDisabled = false;
			setCurrentStep(conclusionIndex);
		}

		this.$onInit = function () {
			setCurrentStep(identificationIndex);
		};

		function setCurrentStep(index) {
			$scope.currentStepIndex = index;
			$rootScope.contentNavBar.customButtons = $scope.steps[index].buttons;
			$rootScope.contentNavBar.buttons = $scope.steps[index].buttons.map(function (b) {
				return b.label;
			});
		}

		function isValid(dto) {
			return dto.description && dto.type && dto.informationId && dto.dateRange;
		}

		function getCreateDto() {
			return reportUtilService.buildCustomReportFilterDto(
				$scope.model.filters,
				$scope.model.ReportType,
				$scope.model.ReportName
			);
		}

		$scope.cancel = function () {
			$rootScope.contentNavBar.back();
		};

		$scope.goBack = function () {
			setCurrentStep($scope.currentStepIndex - 1);
		};

		$scope.create = function () {
			createCustomReport();
		};

		$scope.onClickToViewData = function () {
			const dto = reportUtilService.buildCustomReportFilterDto($scope.model.filters);

			$scope.reportGenerated = false;
			$scope.isLoading = true;

			httpService.getDTOFromServiceV2(customReportService.generateGenericKpiReport, dto).then(
				function (response) {
					$scope.reportData = response;

					$scope.reportGenerated = true;
					$scope.isLoading = false;
				},
				function () {
					$scope.reportData = null;
					$scope.isLoading = false;
				}
			);
		};

		$scope.continue = function () {
			setCurrentStep($scope.currentStepIndex + 1);
		};

		$scope.isNextStepEnabled = function () {
			const nextStep = $scope.currentStepIndex + 1;
			return $scope.steps[nextStep] && !$scope.steps[nextStep].isDisabled;
		};

		$scope.onClickStep = function (stepIndex) {
			setCurrentStep(stepIndex);
		};

		$scope.$on(Vgr.constants.evtRecalculateDisable, function () {
			const stepOneFieldsValid = $scope.model.ReportName && $scope.model.ReportType;
			$scope.steps[configurationsIndex].isDisabled = !stepOneFieldsValid;
		});
	}
]);
