angular.module("vgresiduos").controller("IbamaActivityMultiSelectCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"userService",
	"accountService",
	"ibamaService",
	function ($scope, $rootScope, $q, httpService, userService, accountService, ibamaService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.searchLabel = "";
		var isListLoaded = false;

		$scope.subfieldList = [
			{
				item: "id",
				label: $scope.labels.ID
			},
			{
				item: "name",
				label: $scope.labels.Name
			}
		];

		function listibamaActivities() {
			var deferred = $q.defer();

			if (isListLoaded) {
				deferred.resolve();
				return deferred.promise;
			}

			httpService.getDTOFromServiceV2(ibamaService.listIbamaActivities).then(
				function (list) {
					$scope.ibamaActivities = list
						.map(function (a) {
							return {
								code: a.code,
								description: a.description,
								details: a.details,
								formattedDescription: a.code + " - " + a.description
							};
						})
						.sort(function (a) {
							return parseInt(a.code);
						});
					isListLoaded = true;
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		this.$onInit = function () {
			listibamaActivities();
		};

		$scope.$watch("$ctrl.setterIds", function (newValue, oldValue) {
			var modelId = $scope.$ctrl.model ? $scope.$ctrl.model[$scope.idProperty] : null;

			if (newValue != modelId) {
				loadAreas().then(setArea);
			}
		});
	}
]);
