angular.module("vgresiduos").controller("storageCreateByModelCtrl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `geracoes/novo`, [{ key: "refModelId", value: $stateParams.modelId }]);
		};
	}
]);
