angular.module("vgresiduos").controller("ResidueReportBarPieChartCtrl", [
	"$rootScope",
	"$scope",
	"clientService",
	function ($rootScope, $scope, clientService) {
		"use strict";

		$scope.barFormattedLabels = [];
		$scope.pieFormattedLabels = [];
		$scope.showBarChart = true;
		let disablePie = false;

		let yAxisDecimals;

		this.$onInit = function () {
			yAxisDecimals = $scope.$ctrl.yAxisDecimals ? $scope.$ctrl.yAxisDecimals : clientService.getDecimalPlaces();
			loadChartData();
			configureChartOptions();
		};

		$scope.hasGroups = function () {
			return $scope.$ctrl.headers.Groups && $scope.$ctrl.headers.Groups.length > 0;
		};

		function loadChartData() {
			if ($scope.$ctrl.headers.Groups && $scope.$ctrl.headers.Groups.length > 0) {
				$scope.originalLabels = getChartLabelsFromData();
			} else if ($scope.$ctrl.headers.Intervals && $scope.$ctrl.headers.Intervals.length > 0) {
				$scope.originalLabels = $scope.barFormattedLabels = $scope.pieFormattedLabels = getChartIntervalsFromHeader();
			} else {
				$scope.originalLabels = $scope.barFormattedLabels = $scope.pieFormattedLabels = [$scope.$ctrl.headers.Total];
			}

			if ($scope.$ctrl.headers.Intervals && $scope.$ctrl.headers.Intervals.length > 0) {
				$scope.data = getIntervalsFromData();
			} else {
				$scope.data = getTotalFromData();
			}

			if ($scope.data.find((i) => i > 0) && $scope.data.find((i) => i < 0)) {
				disablePie = true;
			}

			$scope.firstDataQualifier = $scope.$ctrl.concatenateFirstDataQualifier ? getFirstDataQualifierFromData() : null;

			$scope.series = getChartSeriesFromHeader();
		}

		function getFirstDataQualifierFromData() {
			return $scope.$ctrl.data[0].DataQualifiers[0];
		}

		function getChartIntervalsFromHeader() {
			return $scope.$ctrl.headers.Intervals;
		}

		function getChartLabelsFromData() {
			const labels = [];
			for (const row of $scope.$ctrl.data) {
				labels.push(row.Groups.join(", "));
				$scope.barFormattedLabels.push(Vgr.util.cut(row.Groups.join(", "), true, 20));
				$scope.pieFormattedLabels.push(Vgr.util.cut(row.Groups.join(", "), true, 60));
			}
			return labels;
		}

		function getTotalFromData() {
			const intervals = [];
			for (const row of $scope.$ctrl.data) {
				intervals.push(row.Total);
			}
			return intervals;
		}

		function getIntervalsFromData() {
			const intervals = [];
			for (const row of $scope.$ctrl.data) {
				intervals.push(row.Intervals);
			}

			return intervals;
		}

		function getChartSeriesFromHeader() {
			const series = [];
			series.push($rootScope.labels.REALIZED);

			return series;
		}

		function formatNumberTooltipToBarChart(tooltipItem, data) {
			const dataset = data.datasets[tooltipItem.datasetIndex];
			const value = dataset.data ? dataset.data[tooltipItem.index] : "";

			let label = yAxisFormat(value);
			if ($scope.firstDataQualifier) {
				label += $scope.firstDataQualifier;
			}

			label += $scope.hasGroups() ? " - " + $scope.originalLabels[tooltipItem.index] : "";

			return label;
		}

		function formatNumberTooltipToPieChart(tooltipItem, data) {
			const dataset = data.datasets[tooltipItem.datasetIndex];

			if (dataset.data) {
				const value = dataset.data[tooltipItem.index];

				let label = yAxisFormat(value);
				if ($scope.firstDataQualifier) {
					label += $scope.firstDataQualifier;
				}

				const sum = dataset.data.reduce((a, b) => a + parseFloat(b), 0);
				const percentage = Vgr.util.formatUnitDecimalPlaces((parseFloat(value) / sum) * 100, 2) + "%";

				label += " (" + percentage + ")";

				label += $scope.hasGroups() ? " - " + $scope.originalLabels[tooltipItem.index] : "";

				return label;
			} else {
				return "";
			}
		}

		function yAxisFormat(value) {
			return Vgr.util.formatUnitDecimalPlaces(value, yAxisDecimals);
		}

		function configureChartOptions() {
			$scope.barChartOptions = {
				legend: {
					display: false
				},
				tooltips: {
					callbacks: {
						title: function (/*tooltipItems, data*/) {
							return "";
						},
						label: formatNumberTooltipToBarChart
					}
				}
			};

			$scope.pieChartOptions = {
				legend: {
					display: true,
					position: "right",
					fullWidth: false
				},
				tooltips: {
					callbacks: {
						label: formatNumberTooltipToPieChart
					}
				}
			};
		}

		$scope.isPieChartEnabled = function () {
			return !disablePie && $scope.hasGroups() && !$scope.$ctrl.isMoney;
		};

		$scope.switchToBarChart = function (showBarChart) {
			$scope.showBarChart = showBarChart;
		};
	}
]);
