angular.module("vgresiduos").directive("disposalIntegrationsInfoDetails", function () {
	return {
		scope: true,
		controller: "DisposalIntegrationsInfoDetailsCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/destination/popups/disposalIntegrationsInfoPopup/disposalIntegrationsInfoDetails_template.html",
		bindToController: {
			integrationInfo: "=",
			manifestIntegrationDetail: "=",
			statusDescriptionClass: "=",
			statusDescription: "=",
			actionInfo: "=",
			receivedDateString: "=",
			isUpToDate: "=",
			residues: "=",
			errorDescription: "=",
			residuesDescriptionSummary: "="
		}
	};
});
