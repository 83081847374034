angular.module("vgresiduos").controller("ResidueReportChartsCtrl", [
	"$rootScope",
	"$scope",
	"clientService",
	function ($rootScope, $scope, clientService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		var decimalPlaces = clientService.getDecimalPlaces();

		this.$onInit = function () {
			formatDataToShowChartProperly();
		};

		function formatDataToShowChartProperly() {
			$scope.chartData = angular.copy($scope.$ctrl.data);
			if ($scope.chartData.length > 10) {
				let aggregatedData = calculateAggregatedData();
				$scope.chartData.splice(10);
				formatDecimalsList();
				$scope.chartData.push(aggregatedData);
			} else {
				formatDecimalsList();
			}
		}

		function calculateAggregatedData() {
			let aggregatedData = {};
			for (let i = 10; i < $scope.chartData.length; ++i) {
				let data = $scope.chartData[i];
				if (Object.keys(aggregatedData).length === 0) {
					aggregatedData = data;
					if (aggregatedData.Groups) {
						let othersGroup = Vgr.util.capitalize($scope.labels.OTHERS) + " (" + ($scope.chartData.length - 10) + ")";
						aggregatedData.Groups = [othersGroup];
					}
				} else if (data.Intervals) {
					sumIntervalValuesToPrevious(aggregatedData, data);
				} else if (data.Total) {
					sumTotalValueToPrevious(aggregatedData, data);
				}
			}
			formatDecimals(aggregatedData);
			return aggregatedData;
		}

		function formatDecimalsList() {
			for (let i = 0; i < $scope.chartData.length; ++i) {
				formatDecimals($scope.chartData[i]);
			}
		}

		function formatDecimals(data) {
			if (data.Intervals) {
				roundDecimalsIntervalValues(data);
			} else if (data.Total) {
				data.Total = data.Total.toFixed(decimalPlaces);
			}
		}

		function roundDecimalsIntervalValues(aggregatedData) {
			for (let i = 0; i < aggregatedData.Intervals.length; ++i) {
				aggregatedData.Intervals[i] = aggregatedData.Intervals[i]
					? aggregatedData.Intervals[i].toFixed(decimalPlaces)
					: null;
			}
		}

		function sumIntervalValuesToPrevious(aggregatedData, data) {
			for (let i = 0; i < data.Intervals.length; ++i) {
				aggregatedData.Intervals[i] += data.Intervals[i] ? data.Intervals[i] : 0;
			}
		}

		function sumTotalValueToPrevious(aggregatedData, data) {
			aggregatedData.Total += data.Total ? data.Total : 0;
		}
	}
]);
