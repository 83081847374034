angular.module("vgresiduos").directive("residueOrganizationSimpleGrid", function () {
	return {
		scope: true,
		controller: "residueOrganizationSimpleGridCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/onboarding/additionalProperties/grid/residueOrganizationSimpleGrid_template.html",
		bindToController: {
			list: "=",
			residuesSwitch: "=?"
		}
	};
});
