angular.module("vgresiduos").controller("DisposalQuitPopupCtrl", [
	"$scope",
	"dialogService",
	function ($scope, dialogService) {
		"use strict";

		$scope.cancel = function () {
			dialogService.hide($scope.dialog);
		};

		$scope.continue = function () {
			$scope.disposalQuitPopupModel.Action();
		};

		function initializePopup() {
			$scope.disposalQuitPopupModel = {
				Text: $scope.toQuitPopup.Text,
				Action: $scope.toQuitPopup.Action
			};
		}

		initializePopup();
	}
]);
