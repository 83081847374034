angular.module("vgresiduos").directive("buttonBar", function () {
	"use strict";

	return {
		scope: true,
		controller: "ButtonBarCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/buttonBar/buttonBar_template.html",
		bindToController: {
			options: "="
		}
	};
});

var Cmp = Cmp || {};
Cmp.util = Cmp.util || {};

Cmp.util.showError = function showError(error) {
	var $rootScope = angular.element(document.body).injector().get("$rootScope");

	if (error) {
		var data = {};
		if (Array.isArray(error)) {
			data = JSON.parse(error[0].d);
		} else if (typeof error.d === "string") {
			data = JSON.parse(error.d);
		} else if (typeof error === "string") {
			data = JSON.parse(error);
		} else {
			data = error;
		}

		if (data.errorCode) {
			if (data.message) {
				var errorLabel = $rootScope.labels[data.errorCode];
				var errorMessage = errorLabel ? errorLabel.replace("[PARAM]", data.message.trim()) : data.message.trim();
				if (errorLabel) $rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, errorMessage);
			} else {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels[data.errorCode]);
			}
		} else {
			$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.SERVER_ERROR);
		}

		if (data.message) {
			console.error(data.message);
		}
	} else $rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.SERVER_CONNECTION_ERROR);
};
