angular.module("vgresiduos").directive("cnpjCpfTextField", function () {
	return {
		scope: true,
		controller: "CnpjCpfTextFieldCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/fields/cnpjCpfTextField/cnpjCpfTextField_template.html",
		bindToController: {
			model: "=",
			isRequired: "=",
			label: "@?"
		}
	};
});
