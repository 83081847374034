angular.module("vgresiduos").directive("additionalPropertiesCreateForm", function () {
	return {
		scope: true,
		controller: "AdditionalPropertiesCreateFormCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/onboarding/additionalProperties/form/additionalPropertiesCreateForm_template.html",
		bindToController: {
			model: "=",
			stepIndex: "<",
			residuesSwitch: "=?",
			organizationUnitRadioSelectedId: "=?",
			organizationUnitSelectedListCount: "=?"
		}
	};
});
