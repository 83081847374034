angular.module("vgresiduos").controller("PermissionGroupUserGridCtrl", [
	"$scope",
	"$q",
	"$rootScope",
	"httpService",
	"permissionGroupService",
	"permissionService",
	"dialogService",
	"accountService",
	"userService",
	"$state",
	function (
		$scope,
		$q,
		$rootScope,
		httpService,
		permissionGroupService,
		permissionService,
		dialogService,
		accountService,
		userService,
		$state
	) {
		"use strict";

		var unitPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.EditUnitPermissions);
		var organizationPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.EditOrganizationPermissions);

		var currentUser = accountService.getLoggedAccount();

		var labels = $rootScope.labels;

		var permissionInfo = {};

		this.$onInit = function () {
			var columns = [
				{
					field: "name",
					displayName: function () {
						return labels.NAME;
					}
				},
				{
					field: "email",
					displayName: function () {
						return labels.EMAIL;
					}
				},
				{
					editOptions: [
						{
							type: "delete",
							icon: "delete",
							label: $scope.labels.DELETE,
							disable: function (row) {
								return !getEditPermission();
							},
							click: function (row) {}
						}
					],
					displayName: function () {
						return labels.ACTIONS;
					}
				}
			];

			var gridOptions = {
				hasPermission: function () {
					return getEditPermission();
				},
				enableSelect: getEditPermission(),
				columnDefinitions: columns,
				title: labels.PROFILES,
				filterFields: ["name", "email"],
				data: [],
				query: {
					order: "name",
					limit: 10,
					page: 1
				},
				deletePopup: {
					title: labels.DELETE_USER_POPUPTITLE,
					text: labels.DELETE_USER_POPUPTEXT,
					deleteMethod: permissionGroupService.removeUserFromPermissionGroup,
					getDto: function (item) {
						return {
							type: permissionInfo.permissionType,
							id: item.code,
							permissionGroupId: $scope.ctrl.model.id
						};
					},
					onClose: function (deletedList) {
						var userIds = [];

						deletedList.forEach((user) => {
							userIds.push(user.id);
						});

						reloadLoggedUserPermissions(userIds);
						return listPermissionGroupUsers(true);
					}
				},
				promise: {},
				hasData: false,
				errorLoading: false,
				removeNew: true,
				showDeleteOnSelectedItens: true
			};

			Vgr.util.merge($scope.ctrl.gridOptions, gridOptions, true);

			$rootScope.contentNavBar = {
				customButtons: [
					{
						hasPermission: function () {
							return getEditPermission();
						},
						label: $scope.labels.ADD_USER,
						icon: "add",
						buttonClasses: "md-primary",
						supressToast: true,
						onClick: function () {
							var deferred = $q.defer();
							openAddUsersPopup();
							deferred.resolve();
							return deferred.promise;
						}
					}
				],
				validate: false,
				valid: true,
				returnState: isUnitPermissionState() ? "unitPermissionList" : "organizationPermissionList",
				breadcrumb: $scope.ctrl.model.name
			};

			permissionSetupInfo();
			listPermissionGroupUsers();
			$rootScope.contentNavBar.buttons = [];
			$rootScope.contentNavBar.breadcrumb = $scope.ctrl.model.name;
			if (getEditPermission()) {
				$rootScope.contentNavBar.buttons.push($scope.labels.ADD_USER);
			}
		};

		function isUnitPermissionState() {
			return $state.is("unitPermissionEdit");
		}

		function getEditPermission() {
			return isUnitPermissionState() ? unitPermission : organizationPermission;
		}

		function permissionSetupInfo() {
			if (isUnitPermissionState()) {
				permissionInfo.permissionType = Vgr.enumerations.systemPermissions.typesText.OrganizationUnit;
			} else {
				permissionInfo.permissionType = Vgr.enumerations.systemPermissions.typesText.Organization;
			}
		}

		function openAddUsersPopup() {
			const params = {
				list: function () {
					var usersWithPermission = $scope.ctrl.gridOptions.data;

					return $scope.allUsers.filter((u) => !usersWithPermission.some((up) => up.id === u.id));
				},
				title: $scope.labels.ADD_USERS,
				legend: $scope.labels.SELECT_USERS_TO_ACCESS_PROFILE,
				searchLabel: $scope.labels.SEARCH_USER,
				noSelectedMessage: $scope.labels.NO_USER_SELECTED,
				filters: ["name", "email", "code"]
			};

			dialogService
				.showDialogFromTemplateV2(
					"views/components/checkListPopup/checkListPopup_template.html",
					"CheckListPopupCtrl",
					null,
					params
				)
				.then(
					function (users) {
						onAddConfirmation(users);
					},
					function () {}
				);
		}

		function onAddConfirmation(users) {
			var dto = {
				type: permissionInfo.permissionType,
				permissionGroupId: $scope.ctrl.model.id,
				model: {
					UserIds: users.map((c) => c.code)
				}
			};

			httpService.postDTOToServiceV2(permissionGroupService.addUsersToPermissionGroup, dto).then(
				function (data) {
					listPermissionGroupUsers(true);
					reloadLoggedUserPermissions(users.map((c) => c.id));
				},
				function (response) {}
			);
		}

		function reloadLoggedUserPermissions(userIds) {
			var currentUserId = userIds.find((id) => id == currentUser.id);

			if (currentUserId) {
				permissionService.loadUserPermissions(currentUser.id).then(function () {
					window.location.reload();
				});
			}
		}

		function listPermissionGroupUsers(reloadObject) {
			var deferred = $q.defer();

			$scope.ctrl.gridOptions.hasData = false;

			if (reloadObject) {
				$scope.getPermissionGroup().then(
					function (data) {
						$scope.ctrl.gridOptions.data = data.users;
						$scope.ctrl.gridOptions.hasData = true;
						deferred.resolve();
					},
					function () {
						//do nothing
					}
				);
			} else {
				$scope.ctrl.gridOptions.data = $scope.ctrl.model.users;
				$scope.ctrl.gridOptions.hasData = true;
				deferred.resolve();
			}
			httpService.getDTOFromServiceV2(userService.listUsersV2).then(function (list) {
				$scope.allUsers = list;
			});

			return deferred.promise;
		}
	}
]);
