angular.module("vgresiduos").controller("MeasureUnitPreferencerCtrl", [
	"$rootScope",
	"$scope",
	"$timeout",
	function ($rootScope, $scope, $timeout) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.measureUnitEnum = Vgr.enumerations.reportFilters.measureUnitEnum;
		$scope.defaultMeasureUnit = Vgr.constants.measureUnit.Ton.Id;

		$scope.elementId = Vgr.util.uuid();

		function configureLayout() {
			const element = document.getElementById("elementId");
			if (element && element.offsetWidth) {
				if (element.offsetWidth < 750) {
					$scope.layoutColumn = true;
				} else {
					$scope.layoutColumn = false;
				}
			}
		}

		let onOpenMeasureUnitRadio;

		this.$onInit = function () {
			$scope.$ctrl.model = {
				measureUnitPreference: $scope.$ctrl.setterId
					? $scope.$ctrl.setterId
					: $scope.measureUnitEnum.ConvertToDefaultMeasureUnitInGroup
			};

			$scope.showRadio = !!$scope.$ctrl.disableHide;

			onOpenMeasureUnitRadio = $rootScope.$on("openMeasureUnitRadio", function () {
				$scope.showRadio = true;
			});

			configureLayout();
		};

		this.$onDestroy = function () {
			// disable the listener
			onOpenMeasureUnitRadio();

			// nullify the DOM-bound model
			$scope.domElement = null;
		};

		$scope.getMeasureUnitEnumLabel = function (measureUnitEnum) {
			return $scope.labels.MEASURE_UNIT_ENUM[measureUnitEnum];
		};

		$scope.toogleMeasureUnitRadio = function () {
			$scope.showRadio = !$scope.showRadio;
			if ($scope.showRadio) {
				$rootScope.$broadcast("openMeasureUnitRadio", true);
			}
		};

		this.$onChanges = function (obj) {
			if (obj.closeRadio && obj.closeRadio.currentValue != obj.closeRadio.previousValue) {
				if (obj.closeRadio.currentValue) {
					$scope.showRadio = false;
				}
			}
		};

		$scope.measureUnitSelectEnabled = function () {
			return (
				$scope.$ctrl.model && $scope.$ctrl.model.measureUnitPreference == $scope.measureUnitEnum.ConvertToSpecificUnit
			);
		};

		$scope.$watch("$ctrl.model.measureUnit", function (newValue, oldValue) {
			if (!newValue && oldValue) {
				$scope.defaultMeasureUnit = null;
				$timeout(function () {
					$scope.defaultMeasureUnit = Vgr.constants.measureUnit.Ton.Id;
				});
			}
		});
	}
]);
