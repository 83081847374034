angular.module("vgresiduos").controller("ConfirmationCtrl", [
	"$scope",
	"$rootScope",
	"dialogService",
	function ($scope, $rootScope, dialogService) {
		"use strict";

		var save = function () {
			dialogService.hide($scope.dialog);
			return $scope.confirmationSave();
		};

		var cancel = function () {
			dialogService.hide($scope.dialog);
		};

		$scope.options = {
			buttons: [$rootScope.labels.CANCEL, $rootScope.labels.CONFIRM],
			customButtons: [
				new Cmp.constructors.ActionButton($rootScope.labels.CANCEL, "", false, cancel, "", "", true, "flex", "", true),
				new Cmp.constructors.ActionButton(
					$rootScope.labels.CONFIRM,
					"",
					false,
					save,
					"",
					"",
					true,
					"md-primary flex",
					"",
					true
				)
			],
			valid: true
		};
	}
]);
