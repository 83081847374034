angular.module("vgresiduos").controller("AdditionalPropertiesCreateCtrl", [
	"$scope",
	"$q",
	"$state",
	"$rootScope",
	"httpService",
	"additionalPropertyService",
	"additionalPropertyUtilService",
	"permissionService",
	function (
		$scope,
		$q,
		$state,
		$rootScope,
		httpService,
		additionalPropertyService,
		additionalPropertyUtilService,
		permissionService
	) {
		"use strict";

		var allClientProperties;
		var organizationPermission = permissionService.hasPermissionV2(
			Vgr.systemPermissions.EditOrganizationAdditionalProperties
		);
		$scope.createEditDeletePermission = organizationPermission;

		$scope.model = {
			organizationUnitSelectedListCount: 0
		};

		$scope.steps = [
			{
				title: $scope.labels.IDENTIFICATION
			},
			{
				title: $scope.labels.PROCESS,
				isDisabled: true
			},
			{
				title: $scope.labels.TYPE_OF_METADATA,
				isDisabled: true
			},
			{
				title: $scope.labels.UNIT,
				isDisabled: true
			}
		];

		function validatePermission() {
			if (!organizationPermission) {
				$state.go("organizationAdditionalPropertiesList");
			}
		}

		$scope.onChangeStep = function (stepIndex) {
			setCurrentStep(stepIndex);
			setCreateFormButtons();
		};

		function listAllClientProperties() {
			httpService
				.getDTOFromServiceV2(additionalPropertyService.listAdditionalProperties)
				.then(function (clientProperties) {
					allClientProperties = clientProperties;
				});
		}

		function setCurrentStep(index) {
			$scope.steps[index].isDisabled = false;
			$scope.currentStepIndex = index;
			$scope.goToStepIndex = index;
		}

		function setCreateFormButtons() {
			if ($scope.currentStepIndex == 0) {
				$scope.createFormButtons = [closeButton, continueButton];
			}

			if ($scope.currentStepIndex != 0 && $scope.currentStepIndex < 3) {
				$scope.createFormButtons = [goBackButton, continueButton];
			}

			if ($scope.currentStepIndex == 3) {
				$scope.createFormButtons = [goBackButton, saveButton];
			}
		}

		function getCreateModel(additionalProperty) {
			return {
				name: additionalProperty ? additionalProperty.name : "",
				type: additionalProperty ? additionalProperty.type : "",
				process: additionalProperty ? additionalProperty.process : "",
				values: additionalProperty ? additionalProperty.values.map((vl) => vl.name) : [],
				organizationUnitIds: additionalProperty ? additionalProperty.organizationUnitIds.map((o) => o.id) : [],
				residueIds: additionalProperty ? additionalProperty.residueIds : []
			};
		}

		function createAdditionalProperty() {
			var model = getCreateModel($scope.additionalProperty);
			return httpService.postDTOToServiceV2(additionalPropertyService.createAdditionalProperty, model);
		}

		const goBackButton = {
			label: $scope.labels.GO_BACK,
			class: "md-primary",
			click: function () {
				setCurrentStep($scope.currentStepIndex - 1);
				setCreateFormButtons();
			},
			disable: false
		};

		const saveButton = {
			label: $scope.labels.SAVE,
			class: "md-primary md-button md-raised",
			click: function () {
				if (
					additionalPropertyUtilService.validateOrganizationUnitRadio(
						$scope.model.organizationUnitRadioSelectedId,
						$scope.model.organizationUnitSelectedListCount,
						$scope.additionalProperty.organizationUnitIds.length
					)
				) {
					createAdditionalProperty().then(function () {
						$state.go("organizationAdditionalPropertiesList");
						$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $rootScope.labels.SAVE_SUCCESS_MESSAGE);
					});
				}
			},
			disable: false
		};

		const continueButton = {
			label: $scope.labels.CONTINUE,
			class: "md-primary md-button md-raised",
			click: function () {
				validateData();
				setCreateFormButtons();
			},
			disable: false
		};

		const closeButton = {
			label: $scope.labels.CLOSE,
			class: "md-primary md-button",
			click: function () {
				const defered = $q.defer();
				additionalPropertyUtilService.backAdditionalPropertyFormDialog().then(
					function () {
						$state.go("organizationAdditionalPropertiesList");
						defered.resolve();
					},
					function () {
						defered.reject();
					}
				);
				return defered.promise;
			},
			disable: false
		};

		function validateData() {
			if ($scope.currentStepIndex == 0) {
				setCurrentStep(1);
			} else if ($scope.currentStepIndex == 1) {
				validatePropertyProcess();
			} else if ($scope.currentStepIndex == 2) {
				validatePropertyType();
			}
		}

		function validateNameToNextStep() {
			return additionalPropertyUtilService.validateAdditionalPropertyName(
				$scope.additionalProperty,
				null,
				allClientProperties
			);
		}

		function validateListValues() {
			if ($scope.additionalProperty.values.length == 0) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.NO_DEFAULT_VALUE);
			} else {
				setCurrentStep(3);
			}
		}

		function validatePropertyType() {
			if ($scope.additionalProperty.type == "") {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.NECESSARY_METADATA_TYPE);
			} else if ($scope.additionalProperty.type == Vgr.enumerations.additionalProperty.type.SuspendList) {
				validateListValues();
			} else {
				setCurrentStep(3);
			}
		}

		function validatePropertyProcess() {
			if ($scope.additionalProperty.process == "") {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.NECESSARY_METADATA_PROCESS);
			} else if (!validateNameToNextStep()) {
				return;
			} else if (
				$scope.additionalProperty.process == Vgr.enumerations.additionalProperty.process.InternalGathering ||
				$scope.additionalProperty.process == Vgr.enumerations.additionalProperty.process.Disposal
			) {
				if (
					additionalPropertyUtilService.validateResiduesSwitch(
						$scope.model.residuesSwitch,
						$scope.additionalProperty.residueIds.length
					)
				) {
					setCurrentStep(2);
				}
			} else {
				setCurrentStep(2);
			}
		}

		$rootScope.contentNavBar = {
			back: async function () {
				const defered = $q.defer();
				additionalPropertyUtilService.backAdditionalPropertyFormDialog($scope.additionalProperty.id).then(
					function () {
						$state.go("organizationAdditionalPropertiesList");
						defered.resolve();
					},
					function () {
						defered.reject();
					}
				);
				return defered.promise;
			},
			title: $rootScope.labels.PROPERTIES,
			buttons: []
		};

		this.$onInit = function () {
			validatePermission();
			$scope.additionalProperty = getCreateModel();
			setCurrentStep(0);
			setCreateFormButtons();
			listAllClientProperties();
		};
	}
]);
