angular.module("vgresiduos").controller("removeReportAlertCtrl", [
	"$scope",
	"$rootScope",
	"$state",
	"localStorageService",
	function ($scope, $rootScope, $state, localStorageService) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.alertTypeText = null;
		$scope.showAlert = false;

		let showAlertStorageKey;

		this.$onInit = function () {
			showAlertStorageKey = "disableRemoveReportAlertBanner_" + $scope.$ctrl.name;

			$scope.showAlert = !localStorageService.get(showAlertStorageKey);

			setAlertMessage();
		};

		function setAlertMessage() {
			if ($scope.$ctrl.type == Vgr.enumerations.removeAlert.type.Kpis) {
				$scope.alertTypeText = $scope.labels.KEY_PERFORMANCE_INDICATORS;
			} else if ($scope.$ctrl.type == Vgr.enumerations.removeAlert.type.RelativeKpis) {
				$scope.alertTypeText = $scope.labels.RELATIVE_KEY_PERFORMANCE_INDICATORS;
			} else {
				$scope.alertTypeText = $scope.labels.KEY_PERFORMANCE_INDICATORS;
			}
		}

		$scope.redirectReport = function () {
			if ($scope.$ctrl.type == Vgr.enumerations.removeAlert.type.Kpis) {
				$state.go("genericKpiReport");
			} else if ($scope.$ctrl.type == Vgr.enumerations.removeAlert.type.RelativeKpis) {
				$state.go("relativeKpis");
			} else {
				$state.go("genericKpiReport");
			}
		};

		$scope.closeAlert = function () {
			localStorageService.set(showAlertStorageKey, true);
			$scope.showAlert = false;
		};
	}
]);
