angular.module("vgresiduos").controller("AuditDashboardCtrl", [
	"$rootScope",
	"$scope",
	"httpService",
	"auditService",
	"clientService",
	"accountService",
	"actionPlanService",
	"$state",
	function ($rootScope, $scope, httpService, auditService, clientService, accountService, actionPlanService, $state) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.actionPlanActive = null;
		$scope.generalStatus = Vgr.constants.generalStatus;
		$scope.goActionPlanEdit = goActionPlanEdit;

		var onAuditsChanged;

		var organizationId = accountService.getCurrentClient().organization.id;
		var isSupplier = clientService.isSupplier();

		this.$onInit = function () {
			auditRefresh();

			onAuditsChanged = $rootScope.$on(Vgr.constants.evtAuditsChanged, function () {
				auditRefresh();
			});
		};

		this.$onDestroy = function () {
			// disable the listener
			onAuditsChanged();

			// nullify the DOM-bound model
			$scope.domElement = null;
		};

		function auditRefresh() {
			if ($scope.ctrl.audit) {
				loadSupplierAuditById();
			} else {
				loadSupplierValidAudit();
				loadActionPlans();
			}
			if (isSupplier) {
				$scope.noAuditText = $scope.labels.NO_VALID_AUDIT_SUPPLIER;
			} else {
				$scope.noAuditText = $scope.labels.NO_VALID_AUDIT;
			}
		}

		function loadSupplierValidAudit() {
			$scope.loading = true;
			httpService
				.getListFromServiceV2(
					auditService.listAudits,
					{
						organizationId: organizationId,
						organizationUnitId: $scope.ctrl.clientId,
						onlyActive: true,
						onlyValid: true
					},
					"Audits"
				)
				.then(function (list) {
					if (list.length > 0) {
						$scope.audit = list[0];
						loadDates();
						checkIfAuditIsPending();
						$scope.hasAuditToShow = true;
					} else {
						$scope.hasAuditToShow = false;
					}
					$scope.loading = false;
				});
		}

		function loadSupplierAuditById() {
			$scope.audit = $scope.ctrl.audit;
			loadDates();
			checkIfAuditIsPending();
			$scope.hasAuditToShow = true;
		}

		function loadDates() {
			$scope.audit.AuditDateString = Vgr.util.dateToString(new Date($scope.audit.AuditDate));
			$scope.audit.ExpirationDateString = Vgr.util.dateToString(new Date($scope.audit.ExpirationDate));
		}

		function checkIfAuditIsPending() {
			if ($scope.audit.Status == Vgr.enumerations.audit.Status.Pending) {
				$scope.auditStillPending = true;
			}
		}

		function loadActionPlans() {
			var dto = {
				queryParams: {
					status: [Vgr.enumerations.actionPlan.Status.Created, Vgr.enumerations.actionPlan.Status.Pending]
				},
				organizationId: organizationId,
				supplierId: $scope.ctrl.clientId
			};

			httpService.getDTOFromServiceV2(actionPlanService.listActionPlans, dto).then(
				function (actionPlans) {
					$scope.actionPlanActive = actionPlans[0] || false;
				},
				function () {}
			);
		}

		function goActionPlanEdit() {
			$state.go("actionPlanEdit", {
				supplierId: $scope.ctrl.clientId,
				actionPlanId: $scope.actionPlanActive.id,
				corporateOrigin: $scope.ctrl.corporateOrigin,
				supplierCode: $scope.ctrl.supplierCode
			});
		}
	}
]);
