angular.module("vgresiduos").controller("organizationChartCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	function ($scope, $rootScope, $q) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.model = {};
		const watchTab = $scope.$watch("organizationChartTabSelected", watchTabSelected);

		this.$onInit = function () {
			$scope.$on(Vgr.constants.evtIframeOnSuccessCallBack, function () {});
		};

		function save() {
			const deferred = $q.defer();
			document.getElementById("portalAngular").contentWindow.postMessage(
				{
					type: Vgr.enumerations.messageTypes.SAVE_ORGANIZATION_CHART,
					payload: "url"
				},
				Vgr.constants.newAngularUrl
			);
			deferred.resolve();
			return deferred.promise;
		}

		function watchTabSelected(newValue) {
			if (newValue) {
				$rootScope.contentNavBar.save = save;
				$rootScope.contentNavBar.skipMessageSave = true;
				$rootScope.contentNavBar.validate = () => true;
			}
		}

		$scope.$on("$destroy", function () {
			watchTab();
			$scope.domElement = null;
		});
	}
]);
