angular.module("vgresiduos").controller("AdditionalPropertiesEditCtrl", [
	"$scope",
	"$q",
	"$stateParams",
	"$state",
	"$rootScope",
	"permissionService",
	"httpService",
	"dialogService",
	"additionalPropertyService",
	"additionalPropertyUtilService",
	function (
		$scope,
		$q,
		$stateParams,
		$state,
		$rootScope,
		permissionService,
		httpService,
		dialogService,
		additionalPropertyService,
		additionalPropertyUtilService
	) {
		"use strict";

		const organizationPermission = permissionService.hasPermissionV2(
			Vgr.systemPermissions.EditOrganizationAdditionalProperties
		);
		let originalAdditionalPropertyValueNames = [];
		const originalAdditionalPropertyValueNamesFormated = [];
		let allClientProperties;
		let originalProperty;
		$scope.createEditDeletePermission = organizationPermission;

		$scope.model = {
			organizationUnitSelectedListCount: 0
		};

		function getUpdateModel() {
			return {
				name: $scope.additionalProperty.name,
				organizationUnitIds: $scope.additionalProperty.organizationUnitIds.map((ou) => ou.id),
				residueIds: $scope.additionalProperty.residueIds,
				active: $scope.additionalProperty.active,
				values: getValuesToModel()
			};
		}

		function getValuesToModel() {
			return hasValue() ? $scope.additionalProperty.values.map((vl) => vl.name) : null;
		}

		function listAllClientProperties() {
			httpService
				.getDTOFromServiceV2(additionalPropertyService.listAdditionalProperties)
				.then(function (clientProperties) {
					allClientProperties = clientProperties;
				});
		}

		function getAdditionalProperty() {
			httpService.getDTOFromServiceV2(additionalPropertyService.getAdditionalProperty, $stateParams.id).then(
				function (data) {
					originalProperty = angular.copy(data);
					$scope.additionalProperty = data;
					if (hasValue()) {
						originalAdditionalPropertyValueNames = getListValueNames();
					}
				},
				function () {}
			);
		}

		function hasValue() {
			return $scope.additionalProperty.values != null;
		}

		function hasChanged() {
			const areResiduesEqual = Vgr.util.areArraysEqual(
				originalProperty.residueIds,
				$scope.additionalProperty.residueIds
			);
			const areOrganizationUnitsEqual = Vgr.util.areArraysEqual(
				originalProperty.organizationUnitIds,
				$scope.additionalProperty.organizationUnitIds.map((ou) => ou.Id)
			);
			const areValuesEqual = hasValue()
				? Vgr.util.areArraysEqual(
						originalProperty.values.map((v) => v.name),
						getValuesToModel()
				  )
				: true;
			const isNameEqual = originalProperty.name == $scope.additionalProperty.name;

			return !areResiduesEqual || !areOrganizationUnitsEqual || !areValuesEqual || !isNameEqual;
		}

		$rootScope.contentNavBar = {
			hasPermission: function () {
				return organizationPermission;
			},
			buttons: [$rootScope.labels.DELETE, $rootScope.labels.SAVE],
			customButtons: [
				{
					label: $rootScope.labels.DELETE,
					supressToast: true,
					icon: "delete",
					onClick: function () {
						const defered = $q.defer();
						openDeletePopup().then(
							function () {
								deleteAdditionalProperty().then(
									function () {
										defered.resolve();
										$state.go("organizationAdditionalPropertiesList");
									},
									function () {
										defered.reject();
									}
								);
							},
							function () {
								defered.reject();
							}
						);
						return defered.promise;
					}
				}
			],
			back: async function () {
				const defered = $q.defer();
				if (hasChanged()) {
					additionalPropertyUtilService.backAdditionalPropertyFormDialog($scope.additionalProperty.id).then(
						function () {
							$state.go("organizationAdditionalPropertiesList");
							defered.resolve();
						},
						function () {
							defered.reject();
						}
					);
				} else {
					$state.go("organizationAdditionalPropertiesList");
					defered.resolve();
				}
				return defered.promise;
			},
			title: $rootScope.labels.PROPERTIES,
			onSaveError: function () {
				//Nothing to be done
			},
			save: function () {
				if (
					additionalPropertyUtilService.validateAdditionalPropertyName(
						$scope.additionalProperty,
						originalProperty.name,
						allClientProperties
					) &&
					validateData()
				) {
					return save();
				} else {
					return $q.reject();
				}
			},
			validate: function () {
				return true;
			},
			valid: true
		};

		function getListValueNames() {
			return $scope.additionalProperty.values.map((n) => {
				return n.name;
			});
		}

		function validateData() {
			return additionalPropertyUtilService.validateOrganizationUnitRadio(
				$scope.model.organizationUnitRadioSelectedId,
				$scope.model.organizationUnitSelectedListCount,
				$scope.additionalProperty.organizationUnitIds.length
			) &&
				additionalPropertyUtilService.validateResiduesSwitch(
					$scope.model.residuesSwitch,
					$scope.additionalProperty.residueIds.length
				)
				? true
				: false;
		}

		function setChipsValuesName() {
			originalAdditionalPropertyValueNames.forEach((ovl) => {
				if (!originalAdditionalPropertyValueNamesFormated.find((o) => o.name == ovl)) {
					originalAdditionalPropertyValueNamesFormated.push({ name: ovl });
				}
			});

			return originalAdditionalPropertyValueNamesFormated;
		}

		function save() {
			const defered = $q.defer();
			additionalPropertyUtilService.saveAdditionalPropertyFormDialog($scope.additionalProperty.name).then(
				function () {
					if (hasValue()) {
						getListValueNames();
					}
					updateAdditionalProperty().then(
						function () {
							$state.go("organizationAdditionalPropertiesEdit", { id: $scope.additionalProperty.id }, { reload: true });
							$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $rootScope.labels.SAVE_SUCCESS_MESSAGE);
							defered.resolve();
						},
						function () {
							$scope.additionalProperty.values = setChipsValuesName();
							defered.reject();
						}
					);
				},
				function () {
					defered.reject();
				}
			);
			return defered.promise;
		}

		function updateAdditionalProperty() {
			const dto = {
				additionalPropertyId: $stateParams.id,
				model: getUpdateModel()
			};
			return httpService.postDTOToServiceV2(additionalPropertyService.updateAdditionalProperty, dto);
		}

		function openDeletePopup() {
			const params = {
				popupTitle: $scope.labels.DELETE_ADDITIONAL_PROPERTY_POPUPTITLE_SINGLE,
				popupText: $scope.labels.DELETE_ADDITIONAL_PROPERTY_POPUPTEXT_SINGLE,
				popupAuxiliaryText: null,
				popupDescription: null,
				cancelButtonLabel: null,
				confirmButtonLabel: null,
				cancelMethod: null,
				confirmMethod: null
			};

			return dialogService.showDialogFromTemplateV2(
				"views/components/popups/confirmPopup_template.html",
				"ConfirmPopupCtrl",
				null,
				params
			);
		}

		function deleteAdditionalProperty() {
			return additionalPropertyService.deleteAdditionalProperty($scope.additionalProperty.id);
		}

		this.$onInit = function () {
			getAdditionalProperty();
			listAllClientProperties();
		};
	}
]);
