angular.module("vgresiduos").controller("CustomizedFilterOptionFieldCtrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;

			setValues($scope.$ctrl.setter);
		};

		function setValues(value) {
			if (value) {
				$scope.optionSetter = value.optionId;
				$scope.additionalPropertySetter = value.additionalPropertyId;
			} else {
				$scope.optionSetter = null;
				$scope.additionalPropertySetter = null;
			}
		}

		$scope.onSelectOption = function (selectedItem) {
			$scope.selectedItem = selectedItem;
			$scope.selectedProperty = null;

			$scope.additionalPropertyProcess = $scope.selectedItem ? $scope.selectedItem.propertyProcess : null;

			callback();
		};

		$scope.onSelectProperty = function (selectedProperty) {
			$scope.selectedProperty = selectedProperty;

			callback();
		};

		$scope.showAdditionalPropertyField = function () {
			return (
				$scope.selectedItem &&
				$scope.selectedItem.type == Vgr.enumerations.customizedFilterV2.optionField.type.additionalProperty
			);
		};

		function callback() {
			if (!$scope.$ctrl.callback) {
				return;
			}

			if ($scope.selectedItem && (!$scope.showAdditionalPropertyField() || $scope.selectedProperty)) {
				$scope.$ctrl.callback({
					item: $scope.selectedItem,
					additionalProperty: $scope.selectedProperty
				});
			} else {
				$scope.$ctrl.callback(null);
			}
		}
	}
]);
