angular.module("vgresiduos").controller("featureTogglePermissionGridCtrl", [
	"$scope",
	"$rootScope",
	"featureToggleService",
	"$q",
	"dialogService",
	function ($scope, $rootScope, featureToggleService, $q, dialogService) {
		"use strict";

		const columns = [
			{
				field: "organizationName",
				displayName: function () {
					return $rootScope.labels.ORGANIZATION;
				}
			},
			{
				field: "organizationUnitName",
				displayName: function () {
					return $rootScope.labels.UNIT;
				}
			},
			{
				field: "organizationContractType",
				displayName: function () {
					return $rootScope.labels.CONTRACT;
				}
			},
			{
				editOptions: getRowEditOptions()
			}
		];

		this.$onInit = function () {
			$scope.ctrl.gridOptions = {
				columnDefinitions: columns,
				filterFields: ["organizationName", "organizationUnitName", "organizationContractType"],
				data: [],
				query: {
					order: "organizationName",
					limit: 10,
					page: 1
				},
				create: openAddPermissionToToggle,
				promise: {},
				hasData: false,
				removeDelete: true,
				errorLoading: false,
				showDeleteOnSelectedItens: true,
				deletePopup: {
					title: $rootScope.labels.FEATURE_TOGGLE_PERMISSION_DELETE_TITLE,
					text: $rootScope.labels.FEATURE_TOGGLE_PERMISSION_DELETE_TEXT,
					deleteMethod: deleteFeatureTogglePermission,
					idField: "id",
					descriptionField: "descriptionForDeletion",
					onClose: afterDeletePermission
				}
			};

			$rootScope.contentNavBar = $scope.saveOptions;
			$rootScope.contentNavBarGrid = $scope.ctrl.gridOptions;

			$scope.ctrl.gridOptions.hasData = false;
			listFeatureTogglePermission();
		};

		function getRowEditOptions() {
			return [
				{
					type: "delete",
					icon: "delete",
					label: $scope.labels.DELETE,
					disable: function (item) {
						return item.inheritedFromPlan;
					}
				},
				{
					type: "tooltip",
					icon: "info",
					label: $scope.labels.FEATURE_TOGGLE_PERMISSION_FROM_PLAN,
					disable: function (item) {
						return !item.inheritedFromPlan;
					}
				}
			];
		}

		function openAddPermissionToToggle() {
			const params = { featureToggleId: $scope.ctrl.model.id };

			dialogService
				.showDialogFromTemplateV2(
					"views/master/featureToggles/popup/addPermissionToFeatureTogglePopup_template.html",
					"AddPermissionToFeatureTogglePopupCtrl",
					null,
					params
				)
				.then(function (model) {
					$scope.ctrl.model.permissions.push({
						id: model.id,
						organization: model.organization,
						organizationUnit: model.organizationUnit
					});

					listFeatureTogglePermission();
				});
		}

		function deleteFeatureTogglePermission(additionalPropertyId) {
			const deferred = $q.defer();

			const dto = {
				featureToggleId: $scope.ctrl.model.id,
				id: additionalPropertyId
			};

			featureToggleService.deleteFeatureTogglePermission(dto).then(
				function (response) {
					deferred.resolve(response);
				},
				function (response) {
					deferred.reject(response);
				}
			);

			return deferred.promise;
		}

		function listFeatureTogglePermission() {
			for (const featureTogglePermission of $scope.ctrl.model.permissions) {
				featureTogglePermission.organizationName = `${featureTogglePermission.organization.id} - ${featureTogglePermission.organization.name}`;
				featureTogglePermission.organizationContractType =
					$rootScope.labels.ORGANIZATION_PAYMENT_PLAN_ENUM[featureTogglePermission.organization.contractType];

				featureTogglePermission.descriptionForDeletion = `${$rootScope.labels.ORGANIZATION}: ${featureTogglePermission.organizationName} `;

				if (featureTogglePermission.organizationUnit) {
					featureTogglePermission.organizationUnitName = `${featureTogglePermission.organizationUnit.id} - ${featureTogglePermission.organizationUnit.name}`;

					featureTogglePermission.descriptionForDeletion += ` // ${$rootScope.labels.UNIT}: ${featureTogglePermission.organizationUnitName} `;
				}
			}

			$scope.ctrl.gridOptions.data = $scope.ctrl.model.permissions;
			$scope.ctrl.gridOptions.hasData = true;
		}

		function afterDeletePermission(removedItems) {
			const deferred = $q.defer();

			for (const removedItem of removedItems) {
				$scope.ctrl.model.permissions = $scope.ctrl.model.permissions.filter(
					(permission) => permission.id !== removedItem.id
				);
			}

			listFeatureTogglePermission();

			deferred.resolve();

			return deferred.promise;
		}
	}
]);
