angular.module("vgresiduos").directive("organizationDocumentGrid", function () {
	return {
		scope: true,
		controller: "OrganizationDocumentGridCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/suppliers/organizationDocument/organizationDocumentGrid_template.html",
		bindToController: {
			supplierId: "=",
			supplierCode: "=",
			gridOptions: "=",
			selected: "="
		}
	};
});
