angular.module("vgresiduos").directive("userForm", function () {
	return {
		scope: true,
		controller: "UserFormCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/master/users/form/userForm_template.html",
		bindToController: {
			inputUser: "=",
			saveOptions: "=",
			masterUser: "@?"
		}
	};
});
