angular.module("vgresiduos").controller("PricingResiduesSelectFieldCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"pricingService",
	function ($scope, $rootScope, httpService, pricingService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			loadResidues();
		};

		function loadResidues() {
			if ($scope.Residues) {
				return;
			}

			httpService.getListFromServiceV2(pricingService.listPricingResidues, null, "Residues").then(function (list) {
				$scope.Residues = list;
				setInitialState();
				var isEmptyList;
				if (list.length > 0) {
					$scope.helpMessage = $scope.labels.PRICING_CONTACT_SUPORT;
					isEmptyList = false;
				} else {
					$scope.helpMessage = $scope.labels.PRICING_CONTACT_SUPORT_NEW;
					isEmptyList = true;
				}

				$scope.ctrl.onLoadList(isEmptyList);
			});
		}

		function setInitialState() {
			if ($scope.Residues.length == 1) {
				$scope.ctrl.residueId = $scope.Residues[0].Id;
				$scope.isDisabled = true;
			}
		}

		$scope.$watch("ctrl.residueId", function (newValue, oldValue) {
			if (newValue != null && newValue != oldValue && $scope.ctrl.onChange) {
				var pricingResidue = $scope.Residues.find((residue) => residue.Id == newValue);
				$scope.ctrl.onChange(pricingResidue);
			}
		});
	}
]);
