angular.module("vgresiduos").controller("NewOptionsButtonCtrl", [
	"$scope",
	"deviceDetector",
	"$timeout",
	"$mdMenu",
	function ($scope, deviceDetector, $timeout, $mdMenu) {
		"use strict";

		$scope.isMobile = deviceDetector.isMobile();

		$scope.isMenuOpen = false;
		$scope.onClickOpen = function ($mdOpenMenu, $event) {
			if ($scope.isMenuOpen) {
				$mdMenu.hide();
				onCloseMenu();
			} else {
				$mdOpenMenu($event);
				onOpenMenu();
			}
		};

		function onOpenMenu() {
			$timeout(function () {
				$scope.isMenuOpen = true;
			});
		}

		function onCloseMenu() {
			$scope.isMenuOpen = false;
		}

		$scope.$on("$mdMenuClose", function () {
			onCloseMenu();
		});

		$scope.getIcon = function () {
			return $scope.isMenuOpen ? "close" : "add";
		};
	}
]);
