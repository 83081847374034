angular.module("vgresiduos").directive("clientExternalSystems", function () {
	return {
		scope: true,
		controller: "ClientExternalSystemsCtrl",
		controllerAs: "ctrl",
		templateUrl:
			"views/client/components/clientForm/clientFormIntegration/baseComponents/clientExternalSystems/clientExternalSystems_template.html",
		bindToController: {}
	};
});
