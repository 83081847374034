angular.module("vgresiduos").controller("ClientExternalSystemsCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"externalSystemService",
	"dialogService",
	"permissionService",
	"$stateParams",
	"clientService",
	function (
		$scope,
		$rootScope,
		httpService,
		externalSystemService,
		dialogService,
		permissionService,
		$stateParams,
		clientService
	) {
		"use strict";

		const editPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.EditUnit);
		$scope.isSupplier = clientService.isSupplier();
		const organizationUnitId = $stateParams.id;
		const externalSystemLoginStatus = Vgr.enumerations.externalSystemLoginStatus;
		const systemSourcesEnum = Vgr.enumerations.destination.systemSource;
		const systemsWithCustomDeleteMessage = [systemSourcesEnum.SINIR_BR, systemSourcesEnum.SIGOR_SP];
		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			$scope.mtrOnlinePreferencesGrid = {
				columns: [
					{
						description: $scope.labels.EXTERNAL_SYSTEM_SOURCE,
						property: "source.description"
					},
					{
						description: $scope.labels.CNPJ,
						property: "document"
					},
					{
						description: $scope.labels.LOGIN,
						property: "login"
					},
					{
						description: $scope.labels.UNIT_CODE,
						property: "unitCode"
					}
				],
				defaultActions: ["refresh"],
				actionButtons: [],
				list: [],
				btnAddClick: function () {
					openPreferencesPopup();
				},
				btnRefreshClick: function () {
					list();
				}
			};

			if (!$scope.isSupplier) {
				$scope.mtrOnlinePreferencesGrid.columns.push({
					description: $scope.labels.LOGIN_STATUS,
					property: "loginStatusDescription",
					getTooltip: function (row) {
						return $scope.labels.MTR_SYSTEM_CLIENT_LOGIN_STATUS_TOOLTIP[row.loginStatus];
					},
					getClass: function (row) {
						if (row.loginStatus == externalSystemLoginStatus.Ok) {
							return "c-green-primary";
						} else if (row.loginStatus == externalSystemLoginStatus.Error) {
							return "c-red";
						}
						return "f-bold";
					}
				});
			}

			$scope.popupDeferred = "";

			configureActions();
			list();
		};

		function deleteClientExternalSystem(row) {
			const showCustomDeleteMessage = systemsWithCustomDeleteMessage.includes(row.source.id);
			const labelsPopup = {
				title: showCustomDeleteMessage
					? $scope.labels.DELETE_EXTERNAL_SYSTEM_POPUP_ENUM[row.source.id].TITLE
					: $scope.labels.DELETE_EXTERNAL_SYSTEM_POPUP.TITLE,
				description: showCustomDeleteMessage
					? $scope.labels.DELETE_EXTERNAL_SYSTEM_POPUP_ENUM[row.source.id].DESCRIPTION
					: $scope.labels.DELETE_EXTERNAL_SYSTEM_POPUP.DESCRIPTION + " " + row.source.description + "?"
			};
			const params = {
				popupTitle: labelsPopup.title,
				popupText: labelsPopup.description,
				organizationUnitId: organizationUnitId,
				externalSystemId: row.source.id
			};
			dialogService
				.showDialogFromTemplateV2(
					"views/client/components/clientForm/clientFormIntegration/baseComponents/ClientExternalSystemsPopupDelete/clientExternalSystemsPopupDelete_template.html",
					"ClientExternalSystemsPopupDeleteCtrl",
					"#middle-popup",
					params
				)
				.then(function () {
					list();
				});
		}

		function openPreferencesPopup(mtrOnlinePreferenceSelected) {
			const params = {
				clientExternalSystem: mtrOnlinePreferenceSelected,
				onCancel: function () {
					dialogService.hide($scope.dialog);
				},
				onOk: function () {
					dialogService.hide($scope.dialog);
					list();
				}
			};
			const dialog = dialogService
				.showDialogFromTemplateWithScope(
					null,
					"views/client/components/clientForm/clientFormIntegration/baseComponents/ClientExternalSystemsPopup/clientExternalSystemsPopup_template.html",
					"ClientExternalSystemsPopupCtrl",
					null,
					null,
					true,
					function () {},
					params
				)
				.then(
					function () {},
					function () {}
				);
			$scope.dialog = dialog;
			return dialog;
		}

		function list() {
			$scope.mtrOnlinePreferencesGrid.loading = true;
			const promise = httpService.getDTOFromServiceV2(externalSystemService.listClientExternalSystems, {
				UnitId: organizationUnitId,
				IncludeSystemsWithoutEmission: true
			});
			promise.then(
				function (result) {
					$scope.mtrOnlinePreferencesGrid.list = result;

					const sinirIntegration = result.find((es) => es.source.id === systemSourcesEnum.SINIR_BR);
					if (sinirIntegration) {
						$rootScope.$broadcast(Vgr.constants.evtHasIntegrationClientExternalSystemSinir, true);
					} else {
						$rootScope.$broadcast(Vgr.constants.evtHasIntegrationClientExternalSystemSinir, false);
					}

					const sigorSPIntegration = result.find((es) => es.source.id === systemSourcesEnum.SIGOR_SP);
					if (sigorSPIntegration) {
						$rootScope.$broadcast(Vgr.constants.evtHasIntegrationClientExternalSystemSigorSP, true);
					} else {
						$rootScope.$broadcast(Vgr.constants.evtHasIntegrationClientExternalSystemSigorSP, false);
					}

					formatLoginStatusDescription();

					$scope.mtrOnlinePreferencesGrid.loading = false;
				},
				function () {
					$scope.mtrOnlinePreferencesGrid.loading = false;
				}
			);
			return promise;
		}

		function formatLoginStatusDescription() {
			for (const externalSystemClient of $scope.mtrOnlinePreferencesGrid.list) {
				// remove this code when status verification is implemented
				if (externalSystemClient.source.id == systemSourcesEnum.COLETAS_SP) {
					continue;
				}

				externalSystemClient.loginStatusDescription =
					$scope.labels.MTR_SYSTEM_CLIENT_LOGIN_STATUS[externalSystemClient.loginStatus];
			}
		}

		function configureActions() {
			if (editPermission) {
				$scope.mtrOnlinePreferencesGrid.defaultActions.unshift("add");
				$scope.mtrOnlinePreferencesGrid.actionButtons = [
					{
						label: $scope.labels.EDIT,
						icon: "edit",
						click: function (row) {
							openPreferencesPopup(row);
						}
					},
					{
						label: $scope.labels.DELETE,
						icon: "delete",
						click: function (row) {
							deleteClientExternalSystem(row);
						}
					}
				];
			}
		}
	}
]);
