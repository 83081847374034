angular.module("vgresiduos").factory("supplierDriverService", [
	"$http",
	"accountService",
	function ($http, accountService) {
		"use strict";

		function getSupplierDriverBaseRequestUrl(supplierCode) {
			return (
				Vgr.constants.suppliersHostUrl +
				Vgr.resources.suppliers.supplierDrivers
					.replace("[ORGANIZATION]", accountService.getCurrentClient().organization.id)
					.replace("[SUPPLIER_CODE]", supplierCode)
			);
		}

		const _listSupplierDrivers = function (supplierCode) {
			const req = {
				method: "get",
				url: getSupplierDriverBaseRequestUrl(supplierCode)
			};

			return $http(req);
		};

		const _getSupplierDriver = function (dto) {
			const req = {
				method: "get",
				url: `${getSupplierDriverBaseRequestUrl(dto.supplierCode)}/${dto.driverId}`
			};

			return $http(req);
		};

		return {
			listDrivers: _listSupplierDrivers,
			getDriver: _getSupplierDriver
		};
	}
]);
