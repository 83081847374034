angular.module("vgresiduos").controller("DocumentTypeListCtrl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `organizacao/fornecedores/tipos-documento`);
		};
	}
]);
