angular.module("vgresiduos").controller("GeneralTopAlertsPopupCtrl", [
	"$scope",
	"$rootScope",
	"alertPopupModel",
	function ($scope, $rootScope, alertPopupModel) {
		"use strict";

		$scope.labels = $rootScope.labels;

		function initializePopup() {
			$scope.alertPopupModel = alertPopupModel;
		}

		initializePopup();
	}
]);
