angular.module("vgresiduos").component("countrySelectField", {
	controller: "CountrySelectFieldCtrl",
	templateUrl: "views/components/fields/countrySelectField/countrySelectField_template.html",
	bindings: {
		label: "@?",
		countryId: "=",
		onChange: "=?",
		isDisabled: "=?",
		addressEnabled: "<?",
		required: "=?"
	}
});
