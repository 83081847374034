angular.module("vgresiduos").directive("pricingResiduesSelectField", function () {
	return {
		scope: true,
		controller: "PricingResiduesSelectFieldCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/fields/pricingResiduesSelectField/pricingResiduesSelectField_template.html",
		bindToController: {
			residueId: "=?",
			label: "@?",
			onLoadList: "=?",
			onChange: "=?"
		}
	};
});
