angular.module("vgresiduos").controller("IbamaRappCreateSummaryResidueDestinationGridCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		var labels = $rootScope.labels;

		var columns = [
			{
				field: "IbamaCode",
				displayName: function () {
					return labels.IBAMA_ID;
				},
				width: 50
			},
			{
				field: "ResidueDescription",
				displayName: function () {
					return labels.RESIDUE;
				},
				width: 200
			},
			{
				field: "Quantity",
				displayName: function () {
					return labels.QTDE;
				},
				width: 50
			},
			{
				field: "Destinator",
				displayName: function () {
					return labels.DESTINATOR;
				},
				width: 50
			},
			{
				field: "CNPJ",
				displayName: function () {
					return labels.CNPJ;
				},
				width: 100
			},
			{
				field: "DestinationType",
				displayName: function () {
					return labels.DESTINATION_TYPE;
				},
				width: 200
			}
		];

		$scope.gridOptions = {
			columnDefinitions: columns,
			filterFields: ["IbamaCode", "ResidueDescription", "Quantity", "Destinator", "CNPJ", "DestinationType"],
			data: [],
			query: {
				order: "IbamaCode",
				limit: 10,
				page: 1
			},
			promise: {},
			hasData: false,
			removeNew: true,
			removeDelete: true,
			errorLoading: false
		};

		this.$onInit = function () {
			$scope.gridOptions.data = $scope.ctrl.list;
			$scope.gridOptions.hasData = true;
		};
	}
]);
