angular.module("vgresiduos").controller("BlockedPageCtrl", [
	"$scope",
	"$rootScope",
	"$window",
	"engagementScoreService",
	function ($scope, $rootScope, $window, engagementScoreService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.benefitItems = [
			{
				img: "img/benefits-improvements.jpg",
				title: $scope.labels.BENEFITS_IMPROVMENT_TITLE,
				description: $scope.labels.BENEFITS_IMPROVMENT_DESCRIPTION
			},
			{
				img: "img/benefits-reports.jpg",
				title: $scope.labels.BENEFITS_REPORTS_TITLE,
				description: $scope.labels.BENEFITS_REPORTS_DESCRIPTION
			},
			{
				img: "img/benefits-suppliers.jpg",
				title: $scope.labels.BENEFITS_SUPPLIERS_TITLE,
				description: $scope.labels.BENEFITS_SUPPLIERS_DESCRIPTION
			},
			{
				img: "img/benefits-mtr.jpg",
				title: $scope.labels.BENEFITS_MTR_TITLE,
				description: $scope.labels.BENEFITS_MTR_DESCRIPTION
			},
			{
				img: "img/benefits-licenses.jpg",
				title: $scope.labels.BENEFITS_LICENSES_TITLE,
				description: $scope.labels.BENEFITS_LICENSES_DESCRIPTION
			},
			{
				img: "img/benefits-law.jpg",
				title: $scope.labels.BENEFITS_LAW_TITLE,
				description: $scope.labels.BENEFITS_LAW_DESCRIPTION
			}
		];

		$scope.toWebSite = function () {
			engagementScoreService.track(Vgr.trackings.general.blockPageInterest);
			$window.open(Vgr.constants.vgrSiteDemoFormUrl, "_blank");
		};
	}
]);
