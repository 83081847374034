angular.module("vgresiduos").controller("createDriverCtrl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `organizacao/fornecedores/${$stateParams.supplierCode}/condutor`, [
				{ key: "supplierId", value: $stateParams.supplierId }
			]);
		};
	}
]);
