angular.module("vgresiduos").controller("GoalEditInputCtrl", [
	"$scope",
	"$rootScope",
	"clientService",
	function ($scope, $rootScope, clientService) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.decimals = clientService.getDecimalPlaces();
		$scope.priceDecimals = clientService.getPriceDecimalPlaces();
		$scope.goalType = Vgr.enumerations.goal.type;

		this.$onInit = function () {
			$scope.isRevenue = $scope.$ctrl.isRevenue;
		};

		this.$onChanges = function (obj) {
			if (obj.isRevenue && obj.isRevenue.currentValue != obj.isRevenue.previousValue) {
				$scope.isRevenue = obj.isRevenue.currentValue;
			}
			if (
				obj.amountResidueGeneration &&
				obj.amountResidueGeneration.currentValue != obj.amountResidueGeneration.previousValue
			) {
				$scope.amountResidueGeneration = obj.amountResidueGeneration.currentValue;
			}
			if (obj.amountDisposal && obj.amountDisposal.currentValue != obj.amountDisposal.previousValue) {
				$scope.amountDisposal = obj.amountDisposal.currentValue;
			}
			if (obj.measureUnit && obj.measureUnit.currentValue != obj.measureUnit.previousValue) {
				$scope.measureUnit = obj.measureUnit.currentValue;
			}
		};

		$scope.getDisposalLabel = function () {
			let label = $scope.isRevenue ? $scope.labels.RESIDUE_DISPOSAL_INCOME : $scope.labels.RESIDUE_DISPOSAL_EXPENSE;
			return label + " (R$)";
		};

		$scope.getIconSrc = function () {
			return $scope.isRevenue ? "img/icons/metas-receita.svg" : "img/icons/metas-despesa.svg";
		};

		$scope.toggleIsRevenue = function () {
			$scope.isRevenue = !$scope.isRevenue;
			$scope.$ctrl.onIsRevenueChange($scope.$ctrl.month, $scope.isRevenue);
		};

		$scope.onAmountResidueGenerationChange = function (newValue) {
			$scope.$ctrl.onAmountResidueGenerationChange($scope.$ctrl.month, newValue);
		};

		$scope.onAmountDisposalChange = function (newValue) {
			$scope.$ctrl.onAmountDisposalChange($scope.$ctrl.month, newValue);
		};
	}
]);
