angular.module("vgresiduos").controller("CustomizedFilterValueCtrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";
		$scope.labels = $rootScope.labels;
		$scope.filterTypes = Vgr.enumerations.customizedFilter.type;

		this.$onInit = function () {
			$scope.searchText = "";
			$scope.valueLabel = $scope.ctrl.customLabel ? $scope.ctrl.customLabel : $scope.labels.VALUE;

			if (
				($scope.isFromType($scope.filterTypes.Select) ||
					$scope.isFromType($scope.filterTypes.Chips) ||
					$scope.isFromType($scope.filterTypes.MultiSelect) ||
					$scope.isFromType($scope.filterTypes.TextComplete)) &&
				$scope.ctrl.loadList
			) {
				loadList();
			}
		};

		$scope.isFromType = function (type) {
			return $scope.ctrl.type == type;
		};

		function loadList() {
			$scope.ctrl.loadList().then(function (list) {
				$scope.ctrl.list = list;
			});
		}

		$scope.onMultiSelectValuesChanged = function (values) {
			$scope.ctrl.selectedValue = values;
		};

		$scope.onSelectField = function (newValue, oldValue) {};
	}
]);
