angular.module("vgresiduos").component("batchOperationList", {
	controller: "BatchOperationListCtrl",
	templateUrl: "views/components/batchOperationList/batchOperationList_template.html",
	bindings: {
		idField: "@?",
		descriptionField: "@",
		method: "<?",
		batchItems: "<?",
		run: "<",
		getDto: "<",
		onBatchExecutionFinish: "<",
		downloadAction: "<",
		onItemExecutionFinish: "<"
	}
});
