angular.module("vgresiduos").controller("IbamaRappResiduesGridCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		var labels = $rootScope.labels;

		var columns = [
			{
				field: "ActivityDetailFormated",
				displayName: function () {
					return labels.IBAMA_ACTIVITY;
				}
			},
			{
				field: "Residue.Code",
				displayName: function () {
					return labels.IBAMA_ID;
				}
			},
			{
				field: "Residue.Description",
				displayName: function () {
					return labels.IBAMA_DESCRIPTION;
				}
			},
			{
				field: "GenerationAmountWithMeasureUnit",
				displayName: function () {
					return labels.IBAMA_GENERATED_AMOUNT;
				}
			},
			{
				field: "DisposalAmountWithMeasureUnit",
				displayName: function () {
					return labels.IBAMA_DISPOSAL_AMOUNT;
				}
			},
			{
				field: "StatusFormated",
				displayName: function () {
					return labels.STATUS;
				},
				classes: "bold uppercase",
				getCustomClass: function (row) {
					return row.StatusClass;
				},
				width: 400
			}
		];

		$scope.gridOptions = {
			columnDefinitions: columns,
			filterFields: [],
			data: [],
			query: {
				order: "ActivityDetailFormated",
				limit: 10,
				page: 1
			},
			promise: {},
			hasData: false,
			removeNew: true,
			removeDelete: true,
			errorLoading: false
		};

		this.$onInit = function () {
			$scope.gridOptions.data = $scope.ctrl.list;
			$scope.gridOptions.hasData = true;
		};
	}
]);
