angular.module("vgresiduos").controller("ResidueReportTableCtrl", [
	"$rootScope",
	"$scope",
	"clientService",
	"reportUtilService",
	function ($rootScope, $scope, clientService, reportUtilService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		var decimalPlaces;
		var numericStart;

		$scope.periodEnum = Vgr.enumerations.reportFilters.periodEnum;
		$scope.tableConfig = {};
		$scope.tableConfig.defaultActions = [];
		$scope.tableConfig.actionButtons = [];

		this.$onInit = function () {
			decimalPlaces =
				$scope.$ctrl.decimalPlaces != null ? $scope.$ctrl.decimalPlaces : clientService.getDecimalPlaces();
			formatTable();
		};

		function formatTable() {
			configureColumns();
			configureRows();
			for (var index in $scope.tableConfig.columns) {
				if (parseInt(index) >= numericStart) {
					$scope.tableConfig.columns[index].isNumeric = true;
				}
			}
		}

		function configureColumns() {
			$scope.tableConfig.columns = [];
			for (var row of $scope.$ctrl.headersConcatenatedArray) {
				$scope.tableConfig.columns.push({
					description: row,
					property: row
				});
			}
		}

		function getDataConcatenatedArrayByCase(data) {
			var concatenatedArray = [];
			if (data.Groups) {
				concatenatedArray = concatenatedArray.concat(formatGroups(data.Groups, data.isTotalRow));
			}
			if (data.DataQualifiers && !$scope.$ctrl.concatenateFirstDataQualifier) {
				concatenatedArray = concatenatedArray.concat(data.DataQualifiers);
			}
			numericStart = concatenatedArray.length;
			if (data.Intervals) {
				concatenatedArray = concatenatedArray.concat(formatDecimalPlaces(data));
			}
			if (data.Total) {
				var total = Vgr.util.formatUnitDecimalPlaces(data.Total, decimalPlaces);
				if ($scope.$ctrl.concatenateFirstDataQualifier) {
					concatenatedArray = concatenatedArray.concat(total + data.DataQualifiers[0]);
				} else if (!$scope.$ctrl.ignoreTotal) {
					concatenatedArray = concatenatedArray.concat(total);
				}
			}
			return concatenatedArray;
		}

		function formatGroups(groups, isTotalRow) {
			return groups.map(function (group) {
				if (!group && !isTotalRow) {
					return "-";
				}
				return group;
			});
		}

		function formatDecimalPlaces(data) {
			return data.Intervals.map(function (interval) {
				if (!interval) {
					return "-";
				}
				var value = Vgr.util.formatUnitDecimalPlaces(interval, decimalPlaces);
				value = $scope.$ctrl.concatenateFirstDataQualifier ? value + data.DataQualifiers[0] : value;
				return value;
			});
		}

		function configureRows() {
			if ($scope.$ctrl.addTotalRowByQualifier) {
				$scope.$ctrl.data = reportUtilService.addTotalRowByQualifier(angular.copy($scope.$ctrl.data));
			}

			var list = [];

			var totalRows = [];

			for (var key in $scope.$ctrl.data) {
				var dataQualifier = $scope.$ctrl.data[key];
				for (var data of dataQualifier) {
					var dataConcatenatedArray = getDataConcatenatedArrayByCase(data);
					var rowObj = new Object();
					for (var i = 0; i < $scope.$ctrl.headersConcatenatedArray.length; ++i) {
						if (!dataConcatenatedArray[i] && i == $scope.$ctrl.headersConcatenatedArray.length - 1) {
							dataConcatenatedArray[i] = Vgr.util.formatUnitDecimalPlaces(0, decimalPlaces);
						}
						rowObj[$scope.$ctrl.headersConcatenatedArray[i]] = dataConcatenatedArray[i];
					}
					if (dataConcatenatedArray[0] == $scope.labels.TOTAL) {
						if (totalRows.length == 0) {
							rowObj.topBorder = true;
						}
						totalRows.push(rowObj);
					} else {
						list.push(rowObj);
					}
				}
			}

			$scope.list = list.concat(totalRows);
		}
	}
]);
