angular.module("vgresiduos").controller("ConfirmPopupCtrl", [
	"$scope",
	"$rootScope",
	"dialogService",
	"popupTitle",
	"popupText",
	"cancelButtonLabel",
	"confirmButtonLabel",
	"popupAuxiliaryText",
	"popupDescription",
	"cancelMethod",
	"confirmMethod",
	function (
		$scope,
		$rootScope,
		dialogService,
		popupTitle,
		popupText,
		cancelButtonLabel,
		confirmButtonLabel,
		popupAuxiliaryText,
		popupDescription,
		cancelMethod,
		confirmMethod
	) {
		"use strict";
		$scope.labels = $rootScope.labels;

		$scope.popupTitle = popupTitle;
		$scope.popupText = popupText;
		$scope.popupDescription = popupDescription;
		$scope.popupAuxiliaryText = popupAuxiliaryText;

		const cancelButton = {
			label: cancelButtonLabel || $scope.labels.CANCEL,
			class: "md-primary",
			click: function () {
				if (cancelMethod) {
					cancelMethod();
				}
				dialogService.cancel();
			},
			disabled: false
		};

		const confirmButton = {
			label: confirmButtonLabel || $scope.labels.CONFIRM,
			class: "md-primary md-raised",
			click: function () {
				if (confirmMethod) {
					$scope.loading = true;
					setDisabledButtonConfirm(true);
					confirmMethod().then(
						function (data) {
							setDisabledButtonConfirm(false);
							dialogService.confirm(data);
						},
						function () {
							setDisabledButtonConfirm(false);
							dialogService.cancel();
						}
					);
				} else {
					dialogService.confirm();
				}
			},
			disabled: false
		};

		$scope.popupActionButtons = [cancelButton, confirmButton];

		function setDisabledButtonConfirm(isDisabled) {
			const confirmButtonIndex = 1;
			$scope.popupActionButtons[confirmButtonIndex].disabled = isDisabled;
		}
	}
]);
