angular.module("vgresiduos").controller("IbamaRappFollowupCardCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		var labels = $rootScope.labels;

		$scope.statusCards = [];

		this.$onInit = function () {
			calculateCards();
		};

		function calculateCards() {
			$scope.statusCards = [];

			if ($scope.model.Status == Vgr.enumerations.ibamaRapp.status.Success) {
				addFinishedCard();
				return;
			}

			var pendingResidues = countResiduesByStatus(Vgr.enumerations.ibamaRapp.residue.status.Pending);
			var successResidues = countResiduesByStatus(Vgr.enumerations.ibamaRapp.residue.status.Success);
			var errorResidues =
				countResiduesByStatus(Vgr.enumerations.ibamaRapp.residue.status.DataError) +
				countResiduesByStatus(Vgr.enumerations.ibamaRapp.residue.status.InternalError);

			if (pendingResidues > 0) {
				addPendingCard();
			}

			if (successResidues > 0) {
				addSuccessCard(successResidues);
			}

			if (errorResidues > 0) {
				addErrorCard(errorResidues);
			}
		}

		function addFinishedCard() {
			addCard(Vgr.constants.generalStatus.success, labels.INTEGRATION_SUCCESSFULLY_COMPLETED, null);
		}

		function addPendingCard() {
			addCard(Vgr.constants.generalStatus.warning, labels.WE_ARE_PROCESSING_YOUR_INTEGRATION, labels.YOU_CAN_NAVIGATE);
		}

		function addSuccessCard(successResidues) {
			addCard(
				Vgr.constants.generalStatus.success,
				labels.X_OF_Y.replace("[X]", successResidues).replace("[Y]", $scope.model.Residues.length),
				labels.RESIDUES_WERE_INTEGRATED
			);
		}

		function addErrorCard(errorResidues) {
			addCard(
				Vgr.constants.generalStatus.error,
				errorResidues == 1
					? labels.THERE_IS_1_ERROR_TO_BE_RESOLVED
					: labels.THERE_ARE_X_ERRORS_TO_BE_RESOLVED.replace("[X]", errorResidues),
				null
			);
		}

		function addCard(generalStatus, title, description) {
			$scope.statusCards.push({
				status: generalStatus,
				title: title,
				description: description
			});
		}

		function countResiduesByStatus(status) {
			var filteredList = $scope.model.Residues.filter((r) => r.Status == status);

			return filteredList.length;
		}

		$scope.$watch("ctrl.reloadCards", function (newValue, oldValue) {
			if (newValue && newValue != oldValue) {
				calculateCards();
				$scope.ctrl.reloadCards = false;
			}
		});
	}
]);
