angular.module("vgresiduos").directive("customizedFilterFilters", function () {
	return {
		scope: true,
		controller: "CustomizedFilterFiltersCtrl",
		controllerAs: "ctrl",
		templateUrl:
			"views/components/customizedFilter/baseComponents/customizedFilterFilters/customizedFilterFilters_template.html",
		bindToController: {
			availableFields: "=",
			initialValues: "=",
			filterOptions: "=",
			fieldLabel: "@?",
			addNewOption: "=?"
		}
	};
});
