angular.module("vgresiduos").directive("actionPlanDashboard", function () {
	return {
		scope: true,
		controller: "ActionPlanDashboardCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/suppliers/actionPlans/dashboard/actionPlanDashboard_template.html",
		bindToController: {
			supplierId: "=?",
			actionPlan: "=?"
		}
	};
});
