angular.module("vgresiduos").directive("expenseCharts", function () {
	"use strict";

	return {
		scope: {},
		controller: "expenseChartsCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/dashboard/components/expenseCharts_template.html",
		bindToController: {
			options: "="
		}
	};
});
