angular.module("vgresiduos").directive("checklistGrid", function () {
	return {
		scope: true,
		controller: "ChecklistGridCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/suppliers/grid/checklist/checklistGrid_template.html",
		bindToController: {
			clientId: "=",
			isAuditor: "="
		}
	};
});
