angular.module("vgresiduos").controller("UnitAreaListCtrl", [
	"$stateParams",
	"$rootScope",
	function ($stateParams, $rootScope) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, "unidade/areas");
		};
	}
]);
