angular.module("vgresiduos").directive("tab", function () {
	"use strict";

	return {
		transclude: true,
		scope: true,
		controller: "TabCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/tab/tab_template.html",
		bindToController: {
			headingText: "@"
		}
	};
});
