angular.module("vgresiduos").controller("disposalCreateModelsCtrl", [
	"$rootScope",
	"$stateParams",
	"$scope",
	function ($rootScope, $stateParams, $scope) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `disposal-models/novo`, getQueries());
		};

		function getQueries() {
			const stateModel = $stateParams.modelo === $scope.labels.TEMPORARY_STORAGE_MODEL_URL;
			return [
				{ key: "onlyTemporaryStoragerModel", value: stateModel.toString() },
				{ key: "disposal", value: $stateParams.disposal }
			];
		}
	}
]);
