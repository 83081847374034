angular.module("vgresiduos").controller("disposalConfirmationAttachmentsCtrl", [
	"$scope",
	"fileService",
	"$q",
	function ($scope, fileService, $q) {
		"use strict";

		$scope.refreshFilesCount = 0;

		function getFilesModel() {
			const files = [];

			for (const index in $scope.fileOptions.CDF.fileList) {
				files.push({
					Path: $scope.fileOptions.CDF.fileList[index].Path,
					Type: Vgr.enumerations.destination.file.CDF,
					Name: $scope.fileOptions.CDF.fileList[index].Name
				});
			}
			return files;
		}

		$scope.$watch("fileOptions.CDF.fileList.length", function (newValue, oldValue) {
			if (newValue != oldValue) {
				$scope.destinationModel.CDFFiles = getFilesModel();
			}
		});

		this.$onInit = function () {
			buildFilesOptions();
		};

		function buildFilesOptions() {
			$scope.fileOptions.CDF = {
				fileService: "mixed",
				get: getFilesList,
				update: function (newFiles) {
					const uploadFilesPromise = $q.defer();
					const path = $scope.getDisposalFilePath();
					fileService.uploadAll(newFiles, path).then(
						function (response) {
							uploadFilesPromise.resolve(response);
						},
						function () {
							uploadFilesPromise.reject();
						}
					);
					return uploadFilesPromise.promise;
				},
				fileList: [],
				refreshFiles: $scope.refreshFilesCount
			};

			if (!$scope.destinationModel.DisposalDate) {
				$scope.destinationModel.DisposalDate = Vgr.util.getDate();
			}
		}

		function loadCDFFiles(files) {
			const CDFs = [];
			if (files) {
				for (const index in files) {
					if (files[index].Type == Vgr.enumerations.destination.file.CDF) {
						CDFs.push({
							Id: files[index].Id,
							Name: files[index].Name,
							Path: files[index].Path,
							S3Key: files[index].Path
						});
					}
				}
			}
			return CDFs;
		}

		$scope.showManifestAlert = function () {
			return (
				hasManifestPendency() && !$scope.fileOptions.CDF.fileList.length && !$scope.destinationModel.PhysicalFilesCDF
			);
		};

		function hasManifestPendency() {
			const foundPendency = $scope.destinationModel.Pendencies.find(
				(p) => p.Type == Vgr.enumerations.destination.pendencyType.CDF
			);
			return foundPendency ? foundPendency.IsChecked : false;
		}

		$scope.$watch("destinationModel.CDFFiles.length", function (newValue) {
			if (newValue > 0 && $scope.destinationModel.DisposalDate) {
				$scope.updateSlip($scope.stepsEnum.A, "CDF anexado");
			} else {
				$scope.updateSlip($scope.stepsEnum.A, null);
			}
		});

		$scope.$watch("destinationModel.DisposalDate", function (newValue, oldValue) {
			if (newValue) {
				$scope.enableStep($scope.stepsEnum.B);
			} else {
				$scope.enableBackToStep($scope.stepsEnum.A);
			}
			if (newValue && newValue != oldValue) {
				$scope.validateDisposal();
			}
		});

		function getFilesList() {
			const deferred = $q.defer();
			deferred.resolve(loadCDFFiles($scope.destinationModel.CDFFiles));
			return deferred.promise;
		}

		const onDisposalFilesUpdated = $scope.$on(Vgr.constants.evtDisposalFilesUpdated, function () {
			// there is a Watch on update-file-v2 checking for this property value
			// when updated, the fileList is reloaded
			$scope.fileOptions.CDF.refreshFiles += 1;
		});

		$scope.$on("$destroy", function () {
			onDisposalFilesUpdated();
			// nullify the DOM-bound model
			$scope.domElement = null;
		});
	}
]);
