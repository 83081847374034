angular.module("vgresiduos").controller("IbamaRappCreateGeneralDataCtrl", [
	"$scope",
	"httpService",
	"ibamaIntegrationService",
	function ($scope, httpService, ibamaIntegrationService) {
		"use strict";

		this.$onInit = function () {
			listIbamaRapp();
		};

		function listIbamaRapp() {
			const dto = {
				year: $scope.ctrl.model.year
			};

			httpService.getDTOFromServiceV2(ibamaIntegrationService.listIbamaRapp, dto).then(
				function (list) {
					calculateAlreadyIntegratedClients(list);
					$scope.loadingFinished = true;
				},
				function () {
					$scope.loadingFinished = true;
				}
			);
		}

		function calculateAlreadyIntegratedClients(list) {
			$scope.alreadyIntegratedClientsIds = [];

			list.forEach((ibamaRapp) => {
				ibamaRapp.OrganizationUnits.forEach((unit) => {
					$scope.alreadyIntegratedClientsIds.push(unit.Id);
				});
			});
		}
	}
]);
