angular.module("vgresiduos").directive("organizationUnitSimpleGrid", function () {
	return {
		scope: true,
		controller: "organizationUnitSimpleGridCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/onboarding/additionalProperties/grid/organizationUnitSimpleGrid_template.html",
		bindToController: {
			list: "=",
			organizationUnitRadioSelectedId: "=?",
			organizationUnitSelectedListCount: "=?"
		}
	};
});
