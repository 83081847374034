angular.module("vgresiduos").directive("clientUserExternalSystems", function () {
	return {
		scope: true,
		controller: "ClientUserExternalSystemsCtrl",
		controllerAs: "ctrl",
		templateUrl:
			"views/client/components/clientForm/clientFormIntegration/baseComponents/clientUserExternalSystems/clientUserExternalSystems_template.html",
		bindToController: {
			externalSystemId: "="
		}
	};
});
