angular.module("vgresiduos").controller("DisposalIntegrationsInfoGridCtrl", [
	"$scope",
	"clientService",
	function ($scope, clientService) {
		"use strict";

		const decimalPlaces = clientService.getDecimalPlaces();
		let hasChangedIbamaResidue = false;
		let hasChangedMultipleResidues = false;

		this.$onInit = function () {
			$scope.integrationInfo = {
				columns: [
					{
						description: $scope.labels.RESIDUE,
						property: "ResidueName",
						width: 600
					},
					{
						description: $scope.labels.GATHERING_QUANTITY,
						property: "QuantityIndicated",
						width: 200
					},
					{
						description: $scope.labels.RECEIVED_QUANTITY,
						property: "QuantityReceived",
						width: 200,
						getClass: function (row) {
							return row.NewResidue || row.QuantityIndicated != row.QuantityReceived ? "c-orange" : null;
						}
					}
				]
			};

			$scope.integrationInfo.list = buildGridList($scope.ctrl.list);
			hasChangedIbamaResidue = $scope.ctrl.list.length == 1 && $scope.ctrl.list[0].HasChangedIbamaResidue;
			hasChangedMultipleResidues =
				$scope.ctrl.integrationInfo.Manifests.length == 1 &&
				$scope.ctrl.integrationInfo.Manifests[0].NumberOfChangedResidues > 1;
			$scope.showWarningMessage = hasChangedIbamaResidue || hasChangedMultipleResidues;
		};

		$scope.getWarningMessage = function () {
			if (hasChangedIbamaResidue) {
				return (
					"*" +
					$scope.labels.DESTINATOR_CHANGED_IBAMA +
					$scope.ctrl.list[0].IndicatedIbamaResidue +
					"<strong>" +
					$scope.labels.RECEIVED_IBAMA +
					$scope.ctrl.list[0].ReceivedIbamaResidue +
					"</strong>"
				);
			} else if (hasChangedMultipleResidues) {
				return $scope.labels.COULDNT_BRING_QUANTITIES_AUTOMATICALLY;
			}
		};

		function buildGridList(list) {
			const gridList = [];

			const disposalResidues = angular.copy($scope.readOnlyModel.Residues);

			for (const intResidue of list) {
				const disposalResidue = disposalResidues.find((dr) => intResidue.ResidueId == dr.ResidueId);
				if (disposalResidue) {
					gridList.push(buildGridRowFromDisposalResidue(disposalResidue, intResidue));
				} else {
					gridList.push(buildGridRowFromIntResidue(intResidue));
				}
			}

			return gridList;
		}

		function buildGridRowFromIntResidue(integrationResidue) {
			return {
				NewResidue: true,
				ResidueName: integrationResidue.ResidueDescription,
				QuantityIndicated: getQuantityFormatted(0, integrationResidue.IndicatedMeasureUnit),
				QuantityReceived: getQuantityFormatted(
					integrationResidue.ReceivedQuantity,
					integrationResidue.ReceivedMeasureUnit
				)
			};
		}

		function buildGridRowFromDisposalResidue(disposalResidue, integrationResidue) {
			const referenceResidue = disposalResidue.Quantities[0];

			return {
				ResidueName: disposalResidue.ResidueName,
				QuantityIndicated: integrationResidue.IndicatedQuantity
					? getQuantityFormatted(integrationResidue.IndicatedQuantity, integrationResidue.IndicatedMeasureUnit)
					: getQuantityFormatted(referenceResidue.Quantity, referenceResidue.MeasureUnit),
				QuantityReceived: getQuantityFormatted(
					integrationResidue.ReceivedQuantity,
					integrationResidue.ReceivedMeasureUnit
				)
			};
		}

		function getQuantityFormatted(quantity, measureUnit) {
			const quantityFormatted = Vgr.util.formatUnitDecimalPlaces(quantity, decimalPlaces);
			if (measureUnit) {
				return quantityFormatted + " " + getMeasureUnitAbbreviation(measureUnit);
			}
			return quantityFormatted;
		}

		function getMeasureUnitAbbreviation(measureUnit) {
			return measureUnit ? measureUnit.Abbreviation : $scope.labels.MEASURE_UNIT_NOT_INFORMED;
		}
	}
]);
