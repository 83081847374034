angular.module("vgresiduos").controller("DisposalDriverCtrl", [
	"$scope",
	"$rootScope",
	"$state",
	"httpService",
	"supplierDriverService",
	"dialogService",
	"disposalService",
	"supplierService",
	function (
		$scope,
		$rootScope,
		$state,
		httpService,
		supplierDriverService,
		dialogService,
		disposalService,
		supplierService
	) {
		"use strict";

		$scope.supplierDriverPermission = false;

		this.$onInit = function () {
			loadDrivers();
		};

		$scope.changeDriver = function () {
			if ($scope.destinationModel.Driver) {
				validateDriver();
			}

			if ($scope.ctrl.mustUpdateSlip) {
				$scope.updateSlip(
					$scope.stepsEnum.B,
					$scope.destinationModel.Driver ? $scope.destinationModel.Driver.name : null
				);
			}
		};

		function validateDriver() {
			if (!$scope.canValidateDisposal()) {
				return;
			}

			const dto = $scope.createValidateDto($scope.destinationModel.Transporter);
			httpService.getDTOFromServiceV2(disposalService.validateDisposal, dto).then(function (response) {
				setValidationResult(response.Validations);
				$scope.updateValidationList(response.Validations);
			});
		}

		function setDriverModel(supplierCode, driverId) {
			const foundDriver = $scope.drivers.find((d) => d.id == driverId);
			if (foundDriver != null) {
				$scope.destinationModel.Driver = foundDriver;
			} else {
				return trySetDriverModelByGet(supplierCode, driverId);
			}
		}

		function loadDrivers() {
			if (!$scope.destinationModel.Transporter) {
				return;
			}

			const supplierCode = $scope.destinationModel.Transporter.Code;

			httpService
				.getDTOFromServiceV2(supplierService.getOrganizationSupplierByCode, { supplierCode: supplierCode })
				.then(function (supplier) {
					$scope.supplierDriverPermission = supplierService.hasSupplierPermission(
						supplier,
						null,
						Vgr.systemPermissions.SupplierVehicleAndDriver
					);
				});

			return httpService.getListFromServiceCore(supplierDriverService.listDrivers, supplierCode).then(
				function (response) {
					response.list.forEach((driver) => {
						formatDriverData(driver);
					});

					let disposalCurrentDriverId = "";
					if ($scope.destinationModel.Driver) {
						disposalCurrentDriverId = $scope.destinationModel.Driver.Id;
					}
					$scope.drivers = response.list.filter((v) => v.active || v.id == disposalCurrentDriverId);
					if (disposalCurrentDriverId) {
						setDriverModel(supplierCode, disposalCurrentDriverId);
					}
				},
				function () {
					// do nothing
				}
			);
		}

		function formatDriverData(driver) {
			const certificate = driver.certificates[driver.certificates.length - 1];
			if (certificate != null) {
				driver.certificateNumber = certificate.number;
			}

			driver.driverLicenseDate = driver.driverLicenseDate
				? Vgr.util.dateToString(new Date(driver.driverLicenseDate))
				: "";
		}

		function trySetDriverModelByGet(supplierCode, driverId) {
			return httpService
				.getDTOFromServiceV2(supplierDriverService.getDriver, { supplierCode, driverId })
				.then(function (supplierDriver) {
					formatDriverData(supplierDriver);

					$scope.drivers = [...$scope.drivers, supplierDriver];
					$scope.destinationModel.Driver = supplierDriver;
				});
		}

		$scope.openDisposalDriverPopup = function () {
			if (!$scope.destinationModel.Transporter) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.SELECT_TRANSPORTER);
				return;
			}

			openDriverPopup();
		};

		function openDriverPopup() {
			const params = {
				supplierId: $scope.destinationModel.Transporter.Id,
				supplierCode: $scope.destinationModel.Transporter.Code
			};

			dialogService
				.showDialogFromTemplateV2(
					"views/newAngular/suppliers/driver/createDriverPopup/createDriverPopup.html",
					"createDriverPopupCtrl",
					"#angular-popup-small",
					params,
					true
				)
				.then(onDriverPopupCreate, function () {
					// do nothing
				});
		}

		function onDriverPopupCreate(driver) {
			driver.driverLicenseDate = driver.driverLicenseDate
				? Vgr.util.dateToString(new Date(driver.driverLicenseDate))
				: "";
			$scope.drivers.push(driver);
			$scope.destinationModel.Driver = driver;
		}

		$scope.hasEditPermissionSupplierAndDestination = function () {
			return $scope.editPermission && $scope.supplierDriverPermission;
		};

		function setValidationResult(validations) {
			setDriverLicenseValidationResult(validations);
			setDriverMoppValidationResult(validations);
		}

		function setDriverLicenseValidationResult(validations) {
			$scope.disposalValidations.DriverValidate = {
				Image: null,
				FirstDescription: null,
				SecondDescription: null,
				ButtonDescription: null,
				IsOK: null
			};

			if (validations.find((validation) => validation.Type == Vgr.enumerations.validation.DriverLicenseExpired)) {
				$scope.disposalValidations.DriverValidate.Image = "img/destination/driverInvalidLicense.png";
				$scope.disposalValidations.DriverValidate.FirstDescription =
					$scope.labels.DISPOSAL_VALIDATIONS_DESCRIPTION_ENUM[
						Vgr.enumerations.validation.DriverLicenseExpired
					].toUpperCase();
				$scope.disposalValidations.DriverValidate.IsOK = false;
				$scope.disposalValidations.DriverValidate.ButtonClick = openDisposalQuitPopup;
				$scope.disposalValidations.DriverValidate.ButtonDescription = $scope.labels.DISPOSAL_DRIVER_EDIT;
			} else if (
				validations.find(
					(validation) => validation.Type == Vgr.enumerations.validation.DriverLicenseWithoutExpirationDate
				)
			) {
				$scope.disposalValidations.DriverValidate.Image = "img/destination/driverInvalidLicense.png";
				$scope.disposalValidations.DriverValidate.FirstDescription =
					$scope.labels.DISPOSAL_VALIDATIONS_DESCRIPTION_ENUM[
						Vgr.enumerations.validation.DriverLicenseWithoutExpirationDate
					].toUpperCase();
				$scope.disposalValidations.DriverValidate.IsOK = false;
				$scope.disposalValidations.DriverValidate.ButtonClick = openDisposalQuitPopup;
				$scope.disposalValidations.DriverValidate.ButtonDescription = $scope.labels.DISPOSAL_DRIVER_EDIT;
			} else {
				$scope.disposalValidations.DriverValidate.Image = "img/destination/driverValidLicense.png";
				$scope.disposalValidations.DriverValidate.FirstDescription = $scope.labels.VALID_CNH.toUpperCase();
				$scope.disposalValidations.DriverValidate.IsOK = true;
			}
		}

		function setDriverMoppValidationResult(validations) {
			$scope.disposalValidations.MoppValidate = {
				Image: null,
				FirstDescription: null,
				SecondDescription: null,
				IsOK: null
			};

			if (validations.find((validation) => validation.Type == Vgr.enumerations.validation.DriverWithoutMopp)) {
				$scope.disposalValidations.MoppValidate.Image = "img/destination/invalidMOPP.png";
				$scope.disposalValidations.MoppValidate.FirstDescription =
					$scope.labels.DISPOSAL_VALIDATIONS_DESCRIPTION_ENUM[
						Vgr.enumerations.validation.DriverWithoutMopp
					].toUpperCase();
				$scope.disposalValidations.MoppValidate.IsOK = false;
				$scope.disposalValidations.DriverValidate.ButtonDescription = $scope.labels.DISPOSAL_DRIVER_EDIT;
				$scope.disposalValidations.DriverValidate.ButtonClick = openDisposalQuitPopup;
			} else if (validations.find((validation) => validation.Type == Vgr.enumerations.validation.DriverMoppExpired)) {
				$scope.disposalValidations.MoppValidate.Image = "img/destination/invalidMOPP.png";
				$scope.disposalValidations.MoppValidate.FirstDescription =
					$scope.labels.DISPOSAL_VALIDATIONS_DESCRIPTION_ENUM[
						Vgr.enumerations.validation.DriverMoppExpired
					].toUpperCase();
				$scope.disposalValidations.MoppValidate.IsOK = false;
				$scope.disposalValidations.DriverValidate.ButtonClick = openDisposalQuitPopup;
				$scope.disposalValidations.DriverValidate.ButtonDescription = $scope.labels.DISPOSAL_DRIVER_EDIT;
			} else if ($scope.destinationModel.NeedsDriverMopp) {
				$scope.disposalValidations.MoppValidate.Image = "img/destination/validMOPP.png";
				$scope.disposalValidations.MoppValidate.FirstDescription = $scope.labels.VALID_MOPP.toUpperCase();
				$scope.disposalValidations.MoppValidate.IsOK = true;
			}
		}

		function goToSupplierEdit() {
			$state.go("supplierEdit", {
				id: $scope.destinationModel.Transporter.Id,
				tab: 4
			});
		}

		function openDisposalQuitPopup() {
			$scope.toQuitPopup = {
				Text: $scope.labels.DISPOSAL_QUIT_POPUP_TEXT,
				Action: goToSupplierEdit
			};
			const dialog = dialogService
				.showDialogFromTemplateWithScope(
					null,
					"views/destination/popups/disposalQuitPopup/disposalQuitPopup_template.html",
					"DisposalQuitPopupCtrl",
					$scope,
					"#disposal-popup-small",
					true,
					function () {}
				)
				.then(
					function () {},
					function () {}
				);
			$scope.dialog = dialog;
		}

		const transporterWatch = $scope.$watch("destinationModel.Transporter", function (newValue, oldValue) {
			if (newValue && newValue != oldValue) {
				loadDrivers();
			}
		});

		$scope.$on("$destroy", function () {
			// disable the listener
			transporterWatch();
			// nullify the DOM-bound model
			$scope.domElement = null;
		});
	}
]);
