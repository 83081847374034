angular.module("vgresiduos").controller("DisposalVehicleCtrl", [
	"$scope",
	"$rootScope",
	"$state",
	"httpService",
	"supplierVehicleService",
	"dialogService",
	"disposalService",
	"supplierService",
	function (
		$scope,
		$rootScope,
		$state,
		httpService,
		supplierVehicleService,
		dialogService,
		disposalService,
		supplierService
	) {
		"use strict";

		$scope.supplierVehiclePermission = false;

		this.$onInit = function () {
			loadVehicles();
		};

		$scope.changeVehicle = function () {
			if ($scope.destinationModel.Vehicle) {
				validateVehicle();
			}

			if ($scope.ctrl.mustUpdateSlip) {
				$scope.updateSlip(
					$scope.stepsEnum.C,
					$scope.destinationModel.Vehicle ? $scope.destinationModel.Vehicle.plate : null
				);
			}
		};

		function validateVehicle() {
			if (!$scope.canValidateDisposal()) {
				return;
			}

			const dto = $scope.createValidateDto($scope.destinationModel.Transporter);
			httpService.getDTOFromServiceV2(disposalService.validateDisposal, dto).then(function (response) {
				setValidationResult(response.Validations);
				$scope.updateValidationList(response.Validations);
			});
		}

		function setVehicleModel(supplierCode, vehicleId) {
			const foundVehicle = $scope.vehicles.find((d) => d.id == vehicleId);
			if (foundVehicle != null) {
				$scope.destinationModel.Vehicle = foundVehicle;
			} else {
				trySetVehicleModelByGet(supplierCode, vehicleId);
			}
		}

		function loadVehicles() {
			if (!$scope.destinationModel.Transporter) {
				return;
			}

			const supplierCode = $scope.destinationModel.Transporter.Code;

			httpService
				.getDTOFromServiceV2(supplierService.getOrganizationSupplierByCode, { supplierCode: supplierCode })
				.then(function (supplier) {
					$scope.supplierVehiclePermission = supplierService.hasSupplierPermission(
						supplier,
						null,
						Vgr.systemPermissions.SupplierVehicleAndDriver
					);
				});

			return httpService.getListFromServiceCore(supplierVehicleService.listVehicles, supplierCode).then(
				function (response) {
					response.list.forEach((vehicle) => {
						formatVehicleData(vehicle);
					});

					let disposalCurrentVehicleId = "";
					if ($scope.destinationModel.Vehicle) {
						disposalCurrentVehicleId = $scope.destinationModel.Vehicle.Id;
					}
					$scope.vehicles = response.list.filter((v) => v.active || v.id == disposalCurrentVehicleId);
					if (disposalCurrentVehicleId) {
						setVehicleModel(supplierCode, disposalCurrentVehicleId);
					}
				},
				function () {
					// do nothing
				}
			);
		}

		function formatVehicleData(vehicle) {
			const certificate = vehicle.certificates[vehicle.certificates.length - 1];
			if (certificate != null) {
				vehicle.certificateDueDate = Vgr.util.dateToString(new Date(certificate.dueDate));
				vehicle.certificateNumber = certificate.number;
			} else {
				vehicle.certificateDueDate = "";
			}
		}

		function trySetVehicleModelByGet(supplierCode, vehicleId) {
			return httpService
				.getDTOFromServiceV2(supplierVehicleService.getVehicle, { supplierCode, vehicleId })
				.then(function (supplierVehicle) {
					formatVehicleData(supplierVehicle);

					$scope.vehicles = [...$scope.vehicles, supplierVehicle];
					$scope.destinationModel.Vehicle = supplierVehicle;
				});
		}

		$scope.openDisposalVehiclePopup = function () {
			if (!$scope.destinationModel.Transporter) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.SELECT_TRANSPORTER);
				return;
			}

			openVehiclePopup();
		};

		$scope.hasEditPermissionSupplierAndDestination = function () {
			return $scope.editPermission && $scope.supplierVehiclePermission;
		};

		function openVehiclePopup() {
			const params = {
				supplierId: $scope.destinationModel.Transporter.Id,
				supplierCode: $scope.destinationModel.Transporter.Code
			};

			dialogService
				.showDialogFromTemplateV2(
					"views/newAngular/suppliers/vehicle/createVehiclePopup/createVehiclePopup.html",
					"createVehiclePopupCtrl",
					"#angular-popup",
					params,
					true
				)
				.then(onVehiclePopupCreate, function () {
					// do nothing
				});
		}

		function onVehiclePopupCreate(vehicle) {
			vehicle.certificateDueDate = "";
			if (vehicle.plate) {
				vehicle.plate = vehicle.plate.toUpperCase();
			}
			$scope.vehicles.push(vehicle);
			$scope.destinationModel.Vehicle = vehicle;
		}

		function setValidationResult(validations) {
			$scope.disposalValidations.InmetroVehicle = {
				Image: null,
				FirstDescription: null,
				SecondDescription: null,
				ButtonDescription: null,
				IsOK: null
			};

			if (
				validations.find(
					(validation) => validation.Type == Vgr.enumerations.validation.VehicleWithoutInmetroCertificate
				)
			) {
				$scope.disposalValidations.InmetroVehicle.Image = "img/destination/invalidInmetro.png";
				$scope.disposalValidations.InmetroVehicle.FirstDescription =
					$scope.labels.DISPOSAL_VALIDATIONS_DESCRIPTION_ENUM[
						Vgr.enumerations.validation.VehicleWithoutInmetroCertificate
					].toUpperCase();
				$scope.disposalValidations.InmetroVehicle.IsOK = false;
				$scope.disposalValidations.InmetroVehicle.ButtonDescription = $scope.labels.DISPOSAL_VEHICLE_EDIT;
				$scope.disposalValidations.InmetroVehicle.ButtonClick = openDisposalQuitPopup;
			} else if (
				validations.find(
					(validation) => validation.Type == Vgr.enumerations.validation.VehicleWithInmetroCertificateExpired
				)
			) {
				$scope.disposalValidations.InmetroVehicle.Image = "img/destination/invalidInmetro.png";
				$scope.disposalValidations.InmetroVehicle.FirstDescription =
					$scope.labels.DISPOSAL_VALIDATIONS_DESCRIPTION_ENUM[
						Vgr.enumerations.validation.VehicleWithInmetroCertificateExpired
					].toUpperCase();
				$scope.disposalValidations.InmetroVehicle.IsOK = false;
				$scope.disposalValidations.InmetroVehicle.ButtonDescription = $scope.labels.DISPOSAL_VEHICLE_EDIT;
				$scope.disposalValidations.InmetroVehicle.ButtonClick = openDisposalQuitPopup;
			} else if ($scope.destinationModel.NeedsVehicleInmetro) {
				$scope.disposalValidations.InmetroVehicle.Image = "img/destination/validInmetro.png";
				$scope.disposalValidations.InmetroVehicle.FirstDescription = $scope.labels.VALID_INMETRO.toUpperCase();
				$scope.disposalValidations.InmetroVehicle.IsOK = true;
			}
		}

		function goToSupplierEdit() {
			$state.go("supplierEdit", {
				id: $scope.destinationModel.Transporter.Id,
				tab: 3
			});
		}

		function openDisposalQuitPopup() {
			$scope.toQuitPopup = {
				Text: $scope.labels.DISPOSAL_QUIT_POPUP_TEXT,
				Action: goToSupplierEdit
			};
			const dialog = dialogService
				.showDialogFromTemplateWithScope(
					null,
					"views/destination/popups/disposalQuitPopup/disposalQuitPopup_template.html",
					"DisposalQuitPopupCtrl",
					$scope,
					"#disposal-popup-small",
					true,
					function () {}
				)
				.then(
					function () {},
					function () {}
				);
			$scope.dialog = dialog;
		}

		const transporterWatch = $scope.$watch("destinationModel.Transporter", function (newValue, oldValue) {
			if (newValue && newValue != oldValue) {
				loadVehicles();
			}
		});

		$scope.$on("$destroy", function () {
			// disable the listener
			transporterWatch();
			// nullify the DOM-bound model
			$scope.domElement = null;
		});
	}
]);
