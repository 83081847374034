angular.module("componentes").directive("registerForm", [
	"$timeout",
	function ($timeout) {
		"use strict";
		return {
			scope: {},
			controller: "RegisterFormCtrl",
			controllerAs: "ctrl",
			templateUrl: "views/register/components/registerForm_template.html"
		};
	}
]);
