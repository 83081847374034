angular.module("vgresiduos").controller("propertyOriginSelectFieldCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.additionalPropertyProcess = [
			{ value: "ResidueGeneration", name: $scope.labels.STORAGE },
			{ value: "Disposal", name: $scope.labels.DISPOSAL },
			{ value: "Area", name: $scope.labels.AREA },
			{ value: "OrganizationUnit", name: $scope.labels.UNIT }
		];

		$scope.onChangePropertyOrigin = function (process) {
			$scope.ctrl.additionalPropertyProcess = process;
		};
	}
]);
