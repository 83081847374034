angular.module("vgresiduos").directive("conversionCalculator", function () {
	return {
		scope: true,
		controller: "ConversionCalculatorCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/conversionCalculator/conversionCalculator_template.html",
		bindToController: {
			measureUnit: "=",
			conversionFactorChanged: "="
		}
	};
});
