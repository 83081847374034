angular.module("vgresiduos").controller("ProcessListCtrl", [
	"$stateParams",
	"$rootScope",
	function ($stateParams, $rootScope) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `unidades/processos`);
		};
	}
]);
