angular.module("vgresiduos").controller("ButtonBarCtrl", [
	"$rootScope",
	"$scope",
	"$state",
	"$timeout",
	function ($rootScope, $scope, $state, $timeout) {
		"use strict";

		this.$onInit = function () {
			var options = getOptions();
			if (options) {
				options.saveButtonClick = $scope.save;
				$scope.alignment = options.alignment;
			}
		};

		$scope.defaultButtons = [$rootScope.labels.SAVE];
		$scope.loading = Vgr.constants.loadingStates.IDLE;
		$scope.selecting = Vgr.constants.loadingStates.LOADING;
		$scope.buttonClasses = "md-primary";
		$scope.getDescendantProp = Vgr.util.getDescendantProp;
		$scope.getOptions = getOptions;

		$scope.save = function () {
			if ($scope.isBlocked()) {
				return;
			}

			if ($scope.validate()) {
				$scope.getOptions().save().then(notifySaveSuccess, catchSaveError);
			}
		};

		$scope.close = function () {
			if ($scope.getOptions().returnState) {
				$state.go($scope.getOptions().returnState);
			} else if ($scope.getOptions().back) {
				$scope.getOptions().back();
			} else if ($scope.getOptions().closePopup) {
				$scope.getOptions().closePopup();
			} else {
				console.log("WARN!! window history back called on close");
				window.history.back();
			}
		};

		$scope.ok = function () {
			if ($scope.isBlocked()) {
				return;
			}

			if ($scope.validate()) {
				$scope
					.getOptions()
					.save()
					.then(function (response) {
						notifySaveSuccess();
						$scope.close();
					}, catchSaveError);
			}
		};

		$scope.runCustomAction = function (customButton) {
			if (
				$scope.isBlocked() ||
				customButton.isDisabled ||
				(customButton.disableCustomButton && customButton.disableCustomButton())
			) {
				return;
			}

			if ($scope.validate()) {
				customButton.onClick().then(function (response) {
					if (!customButton.supressToast) {
						notifySaveSuccess();
					} else {
						animateLoading(Vgr.constants.loadingStates.SUCCESS);
					}
				}, catchSaveError);
			}
		};

		function getAvailableButtons(options) {
			var availableButtons = options.buttons;
			if (!availableButtons) {
				availableButtons = $scope.defaultButtons;
			}
			return availableButtons;
		}

		$scope.showButton = function (btnLabel) {
			var options = $scope.getOptions();
			var disableAll = $scope.ctrl.options.disableAll;
			if (options && disableAll !== true) {
				var availableButtons = getAvailableButtons(options);
				for (var i = 0; i < availableButtons.length; ++i) {
					if (availableButtons[i] === btnLabel) {
						return true;
					}
				}
			} else if (disableAll) {
				return btnLabel === $rootScope.labels.CLOSE;
			}
			return false;
		};

		$scope.hasPermission = function (button) {
			var hasPermissionMethod = button != null ? button.hasPermission : $scope.getOptions().hasPermission;
			if (!hasPermissionMethod) {
				return true;
			}

			return hasPermissionMethod();
		};

		$scope.next = function () {
			if ($scope.isDisabled($rootScope.labels.NEXT)) {
				return;
			}
			var validated = true;
			if ($scope.getOptions().next && $scope.getOptions().validateOnNext) {
				validated = $scope.validate();
			}
			if (!validated) {
				return;
			}

			$scope.loading = Vgr.constants.loadingStates.LOADING;
			var promise = $scope.getOptions().next();
			if (promise) {
				promise.then(function () {
					notifySaveSuccess();
				}, catchSaveError);
			} else {
				$scope.loading = Vgr.constants.loadingStates.IDLE;
			}
		};

		$scope.jump = function () {
			if ($scope.isBlocked()) {
				return;
			}
			$scope.getOptions().jump();
		};

		$scope.link = function () {
			if ($scope.isBlocked()) {
				return;
			}

			if (!$scope.isSelecting() && $scope.validLink()) {
				$scope.loading = Vgr.constants.loadingStates.LOADING;
				$scope
					.getOptions()
					.link()
					.then(function (response) {
						if (!response) {
							if (!$scope.getOptions().dontShowLinkSuccess) {
								notifySaveSuccess();
							}
							if ($scope.getOptions().links && !$scope.getOptions().links.stayOpen) $scope.close();
							else if (!$scope.getOptions().links) {
								$scope.close();
							}
							return;
						}

						var data = {};
						if (typeof response.d === "string") {
							data = JSON.parse(response.d);
						} else if (response.data && typeof response.data.d === "string") {
							var data = JSON.parse(response.data.d);
						} else if (response.data) {
							data = response.data;
						} else {
							data = response;
						}

						if (typeof data.success !== "undefined") {
							if (data.success) {
								notifySaveSuccess();
							} else {
								catchSaveError(response.data);
							}
						} else {
							animateLoading(Vgr.constants.loadingStates.SUCCESS);
						}

						if (!$scope.getOptions().links.stayOpen) $scope.close();
					}, catchSaveError);
			}
		};

		$scope.validate = function () {
			if (!$scope.validateForm()) {
				return false;
			}

			$scope.loading = Vgr.constants.loadingStates.LOADING;

			if ($scope.getOptions().validate) {
				if ($scope.getOptions().validate()) {
					return true;
				} else {
					animateLoading(Vgr.constants.loadingStates.ERROR);
					return false;
				}
			}

			if (!$scope.getOptions().valid) {
				validateFail();
				return false;
			}

			return true;
		};

		$scope.validLink = function () {
			var valid = true;
			var options = $scope.getOptions();

			if (!$scope.validateForm()) {
				valid = false;
			}

			if (options.links && options.links.validate) {
				valid = options.links.validate();
			}

			if (!valid) {
				validateFail();
			}

			return valid;
		};

		$scope.validateForm = function () {
			if ($scope.isLoading()) {
				return false;
			}

			var form = $("form");
			if (form) {
				form.addClass("ng-submitted");
			}

			if ($(".ng-invalid-date").length > 0) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.INVALID_DATE);
				return false;
			}

			return true;
		};

		var validateFail = function () {
			animateLoading(Vgr.constants.loadingStates.ERROR);
			$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.VALIDATION_ERROR_MESSAGE);
		};

		var animateLoading = function (state) {
			$scope.loading = state;
			$timeout(function () {
				$scope.loading = Vgr.constants.loadingStates.IDLE;
			}, Vgr.constants.ANIMATION_DELAY);
		};

		var catchSaveError = function (error) {
			animateLoading(Vgr.constants.loadingStates.ERROR);
			if ($scope.getOptions().onSaveError) {
				$scope.getOptions().onSaveError(error);
			} else {
				Cmp.util.showError(error);
			}
		};

		var notifySaveSuccess = function (response) {
			animateLoading(Vgr.constants.loadingStates.SUCCESS);
			if (!$scope.getOptions().skipMessageSave) {
				$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $rootScope.labels.SAVE_SUCCESS_MESSAGE);
			}
			if ($scope.getOptions().onSaveSuccess) {
				$scope.getOptions().onSaveSuccess(response);
			}
		};

		$scope.isLoading = function () {
			return $scope.loading === Vgr.constants.loadingStates.LOADING;
		};

		$scope.isBlocked = function () {
			return $scope.getOptions().blockButtons;
		};

		$scope.getDisabledTooltip = function (button) {
			if ($scope.getOptions() && $scope.getOptions().buttonStatus) {
				var buttonStatus = $scope.getOptions().buttonStatus(button);
				return buttonStatus.tooltip;
			}
			return "";
		};

		$scope.isDisabled = function (buttonLabel) {
			var isDisabled;
			var customButton;
			var isLoading = $scope.isLoading();
			var isBlocked = $scope.isBlocked();
			var statusDisabled = false;

			if ($scope.getOptions().customButtons) {
				customButton = $scope.getOptions().customButtons.find(function (b) {
					return b.label === buttonLabel;
				});
			}

			if (customButton && customButton.disableCustomButton) {
				return customButton.disableCustomButton();
			}

			if (customButton instanceof Cmp.constructors.ActionButton) {
				statusDisabled = customButton.isDisabled;
			}

			if ($scope.getOptions() && $scope.getOptions().buttonStatus) {
				var buttonStatus = $scope.getOptions().buttonStatus(buttonLabel);
				statusDisabled = !buttonStatus.enabled;
			}

			isDisabled = isLoading || isBlocked || statusDisabled;
			return isDisabled;
		};

		$scope.getLinkLabel = function () {
			var options = $scope.getOptions();
			if (options.links) {
				var label = options.links.label ? options.links.label : $rootScope.labels.LINK;
				return label;
			} else return $rootScope.labels.LINK;
		};

		$scope.getNextLabel = function () {
			var options = $scope.getOptions();
			if (options.next) {
				var next = options.next.label ? options.next.label : $rootScope.labels.NEXT;
				return next;
			} else return $rootScope.labels.NEXT;
		};

		$scope.getJumpLabel = function () {
			var options = $scope.getOptions();
			if (options.jump) {
				var jump = options.jump.label ? options.jump.label : $rootScope.labels.JUMP;
				return jump;
			} else return $rootScope.labels.JUMP;
		};

		$scope.getLinkIcon = function () {
			var options = $scope.getOptions();
			if (options.links) {
				var icon = options.links.icon ? options.links.icon : "link";
				return icon;
			}

			return "link";
		};

		$scope.getNextIcon = function () {
			var options = $scope.getOptions();
			if (options.next) {
				var icon = options.next.icon ? options.next.icon : "navigate_next";
				return icon;
			}

			return "navigate_next";
		};

		$scope.getJumpIcon = function () {
			var options = $scope.getOptions();
			if (options.jump) {
				var icon = options.jump.icon ? options.jump.icon : "skip_next";
				return icon;
			}

			return "skip_next";
		};

		$scope.getSaveLabel = function () {
			var options = $scope.getOptions();
			if (options.saveLabel) {
				var label = options.saveLabel ? options.saveLabel : $rootScope.labels.SAVE;
				return label;
			} else return $rootScope.labels.SAVE;
		};

		$scope.getSelecting = function () {
			if (!$scope.getOptions().links) {
				$scope.selecting = Vgr.constants.loadingStates.IDLE;
				return $scope.selecting;
			}

			if ($scope.getOptions().links.validateLink) {
				if ($scope.getOptions().links.validateLink()) {
					$scope.selecting = Vgr.constants.loadingStates.IDLE;
					return $scope.selecting;
				} else {
					$scope.selecting = Vgr.constants.loadingStates.LOADING;
					return $scope.selecting;
				}
			}
		};

		$scope.isSelecting = function () {
			return $scope.getSelecting() === Vgr.constants.loadingStates.LOADING;
		};

		function getOptions() {
			if ($scope.ctrl.options.currentContext) {
				return $scope.getDescendantProp($scope.ctrl.options, $scope.ctrl.options.currentContext);
			} else {
				return $scope.ctrl.options;
			}
		}

		$scope.getButtonLoading = function (customButton) {
			if (customButton.isLoading) {
				return Vgr.constants.loadingStates.LOADING;
			}

			return $scope.loading;
		};
	}
]);
