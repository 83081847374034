angular.module("componentes").controller("CompleteSignUpFormCtrl", [
	"$scope",
	"$rootScope",
	"$window",
	"accountService",
	"httpService",
	function ($scope, $rootScope, $window, accountService, httpService) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.TOSUrl = Vgr.util.getTermsAndConditionsUrl();
		$scope.privacyPolicyUrl = Vgr.util.getPrivacyPolicyUrl();

		let account;

		$scope.account = {
			name: null,
			telephone: null,
			cellphone: null,
			tos: false
		};

		this.$onInit = function () {
			account = accountService.getLoggedAccount();
			if (account) {
				if (account.name != account.email) {
					$scope.account.name = account.name;
				}

				$scope.account.telephone = account.telephone;
				$scope.account.cellphone = account.cellphone;
			}

			$scope.isInvitedUserFirstLogin = true;
			$scope.account.tos = !$scope.isInvitedUserFirstLogin;
		};

		$scope.openTerms = function () {
			$window.open($scope.TOSUrl, "_blank");
		};

		$scope.onClickConfirm = function () {
			$scope.hasChecked = true;
			$scope.loading = true;

			if (!validate()) {
				$scope.loading = false;
				return;
			}

			httpService.postDTOToServiceV2(accountService.updateAccountInfo, getAccountModel()).then(
				function () {
					onCompleteSignUpSuccessfull();
				},
				function (err) {
					onCompleteSignUpError(err);
				}
			);
		};

		function validate() {
			if (!$scope.account.name) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.VALIDATION_ERROR_MESSAGE);
				return false;
			}
			if (!$scope.account.tos) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.TOS_ERROR_MESSAGE);
				return false;
			}

			return true;
		}

		function getAccountModel() {
			return {
				name: $scope.account.name,
				telephone: $scope.account.telephone,
				cellphone: $scope.account.cellphone,
				jobPosition: account.jobPosition,
				registrationCode: account.registrationCode,
				ToSDate: new Date()
			};
		}

		function onCompleteSignUpSuccessfull() {
			$scope.loading = false;
			accountService.setLoggingIn();
			window.location.href = Common.constants.vgrUrl + "/autenticacao";
		}

		function onCompleteSignUpError(response) {
			let message = response ? $rootScope.labels.AUTH_ERRORS[response.code] : "";
			if (!message) {
				message = $rootScope.labels.AUTH_ERRORS.DefaultError;
			}

			if (response && response.code == Vgr.constants.authentication.errors.UserNotConfirmedException) {
				$rootScope.$broadcast(
					Vgr.constants.evtShowErrorMessage,
					$rootScope.labels.AUTH_ERRORS.UserNotConfirmedException
				);
			} else if (response && response.code == Vgr.constants.authentication.errors.InvalidPasswordException) {
				$rootScope.$broadcast(
					Vgr.constants.evtShowErrorMessage,
					$rootScope.labels.AUTH_ERRORS.InvalidPasswordException
				);
			} else if (response && response.code == Vgr.constants.authentication.errors.InvalidParameterException) {
				$rootScope.$broadcast(
					Vgr.constants.evtShowErrorMessage,
					$rootScope.labels.AUTH_ERRORS.InvalidParameterException
				);
			} else if (response && response.code == Vgr.constants.authentication.errors.NotAuthorizedException) {
				if (response.message.indexOf(Vgr.constants.authentication.errorMessages.SessionExpired)) {
					$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.AUTH_ERRORS.SessionExpired);
					accountService.goToLogin();
				} else {
					$rootScope.$broadcast(
						Vgr.constants.evtShowErrorMessage,
						$rootScope.labels.AUTH_ERRORS.NotAuthorizedException
					);
				}
			} else {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, message);
			}

			$scope.loading = false;
		}
	}
]);
