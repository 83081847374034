angular.module("vgresiduos").component("measureUnitPreference", {
	controller: "MeasureUnitPreferencerCtrl",
	templateUrl: "views/components/measureUnitPreference/measureUnitPreference_template.html",
	bindings: {
		model: "=",
		closeRadio: "<",
		excludeConversionToTon: "<?",
		setterId: "<?",
		disableHide: "<?"
	}
});
