angular.module("vgresiduos").factory("pricingService", [
	"$http",
	"$rootScope",
	function ($http, $rootScope) {
		"use strict";

		const _listPricings = function (params) {
			const parameters = Vgr.util.serializeObjectToQueryString(params);
			const req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.pricing + parameters
			};
			return $http(req);
		};

		const _getPricing = function (id) {
			const req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.pricing + id
			};
			return $http(req);
		};

		const _createPricing = function (dto) {
			const req = {
				method: "post",
				data: dto.model,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.pricing
			};
			return $http(req);
		};

		const _updatePricing = function (dto) {
			const req = {
				method: "put",
				data: dto.model,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.pricing + dto.id
			};
			return $http(req);
		};

		const _deletePricing = function (dto) {
			const req = {
				method: "delete",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.pricing + dto.id
			};
			return $http(req);
		};

		const _listPricingResidues = function () {
			const req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.pricingResidues
			};
			return $http(req);
		};

		const _getSummarizedPrices = function (params) {
			const parameters = Vgr.util.serializeObjectToQueryString(params);
			const req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.default + "summarized-prices" + parameters
			};
			return $http(req);
		};

		const _requestPriceConsult = function () {
			const req = {
				method: "post",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.pricingsRequest
			};
			return $http(req);
		};

		const _listNegotiationTypes = function () {
			return [
				{
					Id: Vgr.enumerations.pricing.negotiationType.Sale,
					Label: $rootScope.labels.PRICING[Vgr.enumerations.pricing.negotiationType.Sale]
				},
				{
					Id: Vgr.enumerations.pricing.negotiationType.Treatment,
					Label: $rootScope.labels.PRICING[Vgr.enumerations.pricing.negotiationType.Treatment]
				},
				{
					Id: Vgr.enumerations.pricing.negotiationType.Donation,
					Label: $rootScope.labels.PRICING[Vgr.enumerations.pricing.negotiationType.Donation]
				}
			];
		};

		const _listPricingTypes = function () {
			return [
				{
					Id: Vgr.enumerations.pricing.pricingType.Residue,
					Label: $rootScope.labels.PRICING_TYPES[Vgr.enumerations.pricing.pricingType.Residue],
					ShortLabel: $rootScope.labels.PRICING_TYPES_SHORT[Vgr.enumerations.pricing.pricingType.Residue]
				},
				{
					Id: Vgr.enumerations.pricing.pricingType.Disposal,
					Label: $rootScope.labels.PRICING_TYPES[Vgr.enumerations.pricing.pricingType.Disposal],
					ShortLabel: $rootScope.labels.PRICING_TYPES_SHORT[Vgr.enumerations.pricing.pricingType.Disposal]
				}
			];
		};

		return {
			listPricings: _listPricings,
			getPricing: _getPricing,
			createPricing: _createPricing,
			updatePricing: _updatePricing,
			deletePricing: _deletePricing,

			listPricingResidues: _listPricingResidues,
			getSummarizedPrices: _getSummarizedPrices,

			requestPriceConsult: _requestPriceConsult,

			listNegotiationTypes: _listNegotiationTypes,
			listPricingTypes: _listPricingTypes
		};
	}
]);
