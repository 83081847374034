angular.module("vgresiduos").directive("clientStoragePreferences", function () {
	return {
		scope: true,
		controller: "clientStoragePreferencesCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/client/components/storagePreferences/clientStoragePreferences_template.html",
		bindToController: {
			inputClient: "=",
			saveOptions: "="
		}
	};
});
