angular.module("vgresiduos").controller("BatchOperationListCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	function ($scope, $rootScope, $q, httpService) {
		"use strict";

		var hasAnySucceeded = false;
		var hasError = false;

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;
			$scope.operationStatus = Vgr.enumerations.operationStatus;
			$scope.idField = $scope.$ctrl.idField ? $scope.$ctrl.idField : "id";
			$scope.descriptionField = $scope.$ctrl.descriptionField ? $scope.$ctrl.descriptionField : "name";

			clearItems();
		};

		function runBatch() {
			clearItems();
			var toDeleteList = $scope.$ctrl.batchItems.filter((i) => i.status != Vgr.enumerations.operationStatus.Success);
			batchExecute(toDeleteList, 1);
		}

		function batchExecute(list, itemsPerBatch) {
			var batchItemsToDelete = list.splice(0, itemsPerBatch);
			batchDelete(batchItemsToDelete).then(
				function () {
					onBatchFinish(list, itemsPerBatch);
				},
				function () {
					hasError = true;
					onBatchFinish(list, itemsPerBatch);
				}
			);
		}

		function onBatchFinish(list, itemsPerBatch) {
			if (list.length > 0) {
				batchExecute(list, itemsPerBatch);
			} else {
				$scope.$ctrl.onBatchExecutionFinish(hasError, hasAnySucceeded);
			}
		}

		function batchDelete(list) {
			var promises = getBatchItemsPromises(list);
			return $q.all(promises);
		}

		function getBatchItemsPromises(list) {
			var promises = [];
			list.forEach((item) => {
				promises.push(execute(item));
			});

			return promises;
		}

		function execute(item) {
			item.status = Vgr.enumerations.operationStatus.Processing;

			var dto;

			if ($scope.$ctrl.getDto) {
				dto = $scope.$ctrl.getDto(item);
			} else {
				dto = item[$scope.idField];
			}

			var promise = httpService.postDTOToServiceV2($scope.$ctrl.method, dto, true);
			promise.then(
				function (returnDto) {
					if (isV2Response(returnDto) && isV2ResponseError(returnDto)) {
						hasError = true;
						item.errorMessage = getV2ErrorMessage(returnDto);
						item.status = returnDto.errorCode
							? Vgr.enumerations.operationStatus.BadRequestError
							: Vgr.enumerations.operationStatus.UnexpectedError;
					} else {
						hasAnySucceeded = true;
						item.returnDto = returnDto;
						item.status = Vgr.enumerations.operationStatus.Success;
						if ($scope.$ctrl.onItemExecutionFinish) {
							$scope.$ctrl.onItemExecutionFinish(returnDto);
						}
					}
				},
				function (error) {
					item.errorMessage = getErrorMessage(error);
					item.status =
						error.status == 400
							? Vgr.enumerations.operationStatus.BadRequestError
							: Vgr.enumerations.operationStatus.UnexpectedError;
				}
			);

			return promise;
		}

		function getV2ErrorMessage(returnDto) {
			return returnDto.errorCode ? $rootScope.labels[returnDto.errorCode] : $rootScope.labels.SERVER_ERROR;
		}

		function isV2Response(returnDto) {
			return returnDto && returnDto.hasOwnProperty("success");
		}

		function isV2ResponseError(returnDto) {
			return returnDto && !returnDto.success;
		}

		function getErrorMessage(error) {
			if (error && error.status == 400 && error.data.length > 0) {
				const errorCode = error.data[0].Code || error.data[0].code;
				return errorCode ? $rootScope.labels[errorCode] : $rootScope.labels.SERVER_ERROR;
			} else if (error && error.status == 403) {
				return $rootScope.labels.NO_PERMISSION_GENERAL_ERROR;
			}
			return $rootScope.labels.SERVER_ERROR;
		}

		$scope.retry = function (item) {
			clearItem(item);
			batchExecute([item], 1);
		};

		$scope.downloadAction = function (item) {
			item.disabledAction = true;
			$scope.$ctrl.downloadAction(item).then(
				function () {
					item.disabledAction = false;
				},
				function () {
					item.disabledAction = false;
				}
			);
		};

		function clearItems() {
			hasError = false;
			$scope.$ctrl.batchItems.forEach((item) => {
				clearItem(item);
			});
		}

		function clearItem(item) {
			item.status = null;
			item.errorMessage = null;
		}

		this.$onChanges = function (obj) {
			if (obj.run && obj.run.currentValue != obj.run.previousValue) {
				if (obj.run.currentValue) {
					runBatch();
				}
			}
		};
	}
]);
