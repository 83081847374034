angular.module("vgresiduos").controller("DisposalIntegrationsInfoDetailsCtrl", [
	"$scope",
	function ($scope) {
		"use strict";
		const statusEnum = Vgr.enumerations.destination.systemStatus;

		$scope.isToShowReceivalData = function () {
			if ($scope.ctrl.integrationInfo && !$scope.ctrl.manifestIntegrationDetail) {
				return (
					$scope.canGetDetailsMtr($scope.ctrl.integrationInfo) &&
					(isReceived($scope.ctrl.integrationInfo) || isDisposed($scope.ctrl.integrationInfo))
				);
			} else if ($scope.ctrl.manifestIntegrationDetail) {
				return (
					$scope.ctrl.manifestIntegrationDetail.IsUpToDateFunc($scope.ctrl.manifestIntegrationDetail) &&
					$scope.ctrl.manifestIntegrationDetail.actions.canViewDetails &&
					($scope.ctrl.manifestIntegrationDetail.Status == statusEnum.Disposed ||
						$scope.ctrl.manifestIntegrationDetail.Status == statusEnum.Received)
				);
			}
		};
		$scope.canGetDetailsMtr = function (integrationInfo) {
			return integrationInfo.Manifests.every(
				(manifestInfo) => manifestInfo.IsUpToDateFunc(manifestInfo) && manifestInfo.actions.canViewDetails
			);
		};
		function isReceived(integrationInfo) {
			return integrationInfo.Manifests.every((dm) => dm.Status == statusEnum.Received);
		}

		function isDisposed(integrationInfo) {
			return integrationInfo.Manifests.every((dm) => dm.Status == statusEnum.Disposed);
		}
	}
]);
