angular.module("vgresiduos").controller("DisposalDestinatorCtrl", [
	"$rootScope",
	"$scope",
	"$q",
	"httpService",
	"supplierService",
	"disposalService",
	"$timeout",
	"$state",
	"$window",
	function ($rootScope, $scope, $q, httpService, supplierService, disposalService, $timeout, $state, $window) {
		"use strict";

		// Local variables
		const cnpjMask = "cnpj";
		const cpfMask = "cpf";

		$scope.disposalValidations.Destinator = {
			Icon: "warning",
			Status: Vgr.constants.generalStatus.error,
			Title: $rootScope.labels.SUPPLIER_PENDENCIES.DESTINATOR,
			TextButton: $rootScope.labels.ACCESS_SUPPLIER,
			Messages: []
		};

		// Scope variables
		$scope.listDestinatorOptions = {
			pageSize: 30,
			pageNumber: 1,
			query: "",
			id: 0
		};
		$scope.subfieldList = [
			{
				item: "Id",
				label: $scope.labels.ID
			},
			{
				item: "CNPJ",
				label: $scope.labels.CNPJ
			},
			{
				item: "CityStateDescription",
				label: $scope.labels.CITY
			}
		];

		// Scope functions
		$scope.listClientDestinators = listClientDestinators;
		$scope.onDestinatorSelect = onDestinatorSelect;

		const destinatorStep = $scope.isTemporaryStorage() ? $scope.stepsEnum.D : $scope.stepsEnum.C;

		this.$onInit = function () {
			if ($scope.destinationModel.Destinator && !$scope.destinationModel.Destinator.CNPJ) {
				$scope.destinationModel.Destinator.CNPJ = "";
			}

			if ($scope.isCurrentStageReview()) {
				updateSlip(true, null);
			}

			setDocumentOptions($scope.destinationModel.Destinator);
		};

		function listClientDestinators() {
			if (!$scope.listDestinatorOptions.query) {
				$scope.listDestinatorOptions.query = "";
			}

			const params = {
				onlyTreaters: true,
				query: $scope.listDestinatorOptions.query,
				skip: $scope.listDestinatorOptions.pageNumber - 1,
				take: $scope.listDestinatorOptions.pageSize,
				disposalResidues: $scope.destinationModel.Residues.map((r) => r.ResidueId)
			};

			const deferred = $q.defer();

			httpService.getListFromServiceV2(supplierService.listSuppliers, params, "Suppliers").then(
				function (list) {
					formatDestinatorCityAndCnpj(list);
					deferred.resolve(list);
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function formatDestinatorCityAndCnpj(list) {
			for (const i in list) {
				if (!list[i].CNPJ) {
					list[i].CNPJ = "";
				}

				if (list[i].City) {
					list[i].CityStateDescription = list[i].City.Description + " - " + list[i].City.State.Abbreviation;
				} else {
					list[i].CityStateDescription = $scope.labels.CITY_NOT_REGISTERED;
				}
			}
		}

		function onDestinatorSelect(destinator) {
			setDocumentOptions(destinator);

			if (destinator) {
				if ($scope.isCurrentStageReview()) {
					$timeout(function () {
						updateSlip(true);
					});
				}
			} else {
				updateSlip(false, null);
			}

			$scope.destinationModel.Destinator = destinator;
			cleanResidueCadriDocuments();

			// if is cadri enabled, wait for load documents for validate
			if (!$scope.isCadriEnabled()) {
				validateDisposalDestinator();
			}
		}

		function setDocumentOptions(destinator) {
			if (!destinator || !destinator.CNPJ) {
				$scope.destinatorDocumentLabel = $scope.labels.CNPJ;
				$scope.destinatorDocumentMask = "";
				return;
			}

			if (destinator.CNPJ.toString().length == 14) {
				$scope.destinatorDocumentLabel = $scope.labels.CNPJ;
				$scope.destinatorDocumentMask = cnpjMask;
				return;
			}

			if (destinator.CNPJ.toString().length == 11) {
				$scope.destinatorDocumentLabel = $scope.labels.CPF;
				$scope.destinatorDocumentMask = cpfMask;
				return;
			}

			$scope.destinatorDocumentLabel = $scope.labels.CNPJ;
			$scope.destinatorDocumentMask = "";
		}

		function validateDisposalDestinator() {
			if (!$scope.canValidateDisposal()) {
				return;
			}

			validateAsync().then(function (validationObject) {
				const valid = validationObject.IsValid;
				const validationMessage = validationObject.Message;

				updateSlip(valid, validationMessage);
			});
		}

		function cleanResidueCadriDocuments() {
			$scope.destinationModel.Residues.forEach((r) => (r.CadriDocumentId = null));
		}

		function updateSlip(valid, validationMessage) {
			if (valid) {
				$scope.updateSlip(destinatorStep, $scope.destinationModel.Destinator.Name);
				$scope.enableStep($scope.stepsEnum.H);
				$scope.stagesConfig[$scope.stagesEnum.Solicitation].Steps[destinatorStep].MiddleActionEnabled = true;
			} else {
				$scope.updateSlip(
					destinatorStep,
					$scope.destinationModel.Destinator ? $scope.destinationModel.Destinator.Name : null
				);
				$scope.enableBackToStep(destinatorStep);
				$scope.stagesConfig[$scope.stagesEnum.Solicitation].Steps[destinatorStep].MiddleActionEnabled = false;
			}
			$scope.stagesConfig[$scope.stagesEnum.Solicitation].Steps[destinatorStep].CustomValidationMessage =
				validationMessage;
		}

		function validateAsync() {
			const deferred = $q.defer();
			const dto = $scope.createValidateDto($scope.destinationModel.Transporter);
			httpService.getDTOFromServiceV2(disposalService.validateDisposal, dto).then(
				function (response) {
					const validationObject = calculateAsyncValidation(response.Validations);
					setDestinatorValidations(response.Validations);
					$scope.updateValidationList(response.Validations);
					deferred.resolve(validationObject);
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function calculateAsyncValidation(validations) {
			const destinatorValidations = validations.filter((v) => v.IsToBlock);

			return {
				IsValid: destinatorValidations.length == 0,
				Message: destinatorValidations.map((v) => $scope.getCustomValidationMessage(v)).join("\n")
			};
		}

		function setDestinatorValidations(validations) {
			$scope.setValidationMessages(validations, getDocumentValidationErrors(), "Destinator", "Destinator");
		}

		function getDocumentValidationErrors() {
			return [
				Vgr.enumerations.validation.NoAuthDocumentSelected,
				Vgr.enumerations.validation.AuthDocumentDontContemplatesAllResidues,
				Vgr.enumerations.validation.AuthDocumentExpired,
				Vgr.enumerations.validation.ResidueQuantityExceededAuthValue,
				Vgr.enumerations.validation.AuthDocumentWithDifferentDisposalTechnology,
				Vgr.enumerations.validation.AuthDocumentWithDifferentRecipient,
				Vgr.enumerations.validation.NoCadriSelected,
				Vgr.enumerations.validation.CadriDocumentExpired,
				Vgr.enumerations.validation.CadriDocumentDontContemplatesAllResidues,
				Vgr.enumerations.validation.DestinatorAuditLevelInvalid,
				Vgr.enumerations.validation.DestinatorAuditClassificationInvalid,
				Vgr.enumerations.validation.TransporterAuditLevelInvalid,
				Vgr.enumerations.validation.TransporterAuditClassificationInvalid,
				Vgr.enumerations.validation.CadriDocumentNotFound,
				Vgr.enumerations.validation.TemporaryStoragerAuditLevelInvalid,
				Vgr.enumerations.validation.TemporaryStoragerAuditClassificationInvalid,
				Vgr.enumerations.validation.AuthDocumentInactive,
				Vgr.enumerations.validation.CadriDocumentInactive
			];
		}

		$scope.showDestinatorValidationMessages = function () {
			return $scope.destinationModel.Destinator && $scope.disposalValidations.Destinator.Messages.length > 0;
		};

		$scope.isCadriEnabled = function () {
			return (
				$scope.destinationModel.Destinator &&
				($scope.destinationModel.Destinator.CadriEnabled || $scope.disposalPreferences.cadriEnabled)
			);
		};

		const onRefreshDisposalValidation = $rootScope.$on(Vgr.constants.evtRefreshDisposalValidation, function () {
			validateDisposalDestinator();
		});

		$scope.$on("$destroy", function () {
			onRefreshDisposalValidation();
		});
	}
]);
