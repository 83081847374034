angular.module("vgresiduos").factory("pendencyService", [
	"$http",
	function ($http) {
		"use strict";

		var pendencyService = {};

		var _listPendencies = function (dto) {
			var parameters = "";
			if (dto) {
				parameters = $.param(dto);
			}

			var req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.pendencies + "?" + parameters
			};
			return $http(req);
		};

		pendencyService.listPendencies = _listPendencies;

		return pendencyService;
	}
]);
