angular.module("vgresiduos").controller("AreaEditCtrl", [
	"$rootScope",
	"$scope",
	"$stateParams",
	"$q",
	"$state",
	"areaService",
	"httpService",
	"userService",
	"additionalPropertyUtilService",
	"permissionService",
	"engagementScoreService",
	function (
		$rootScope,
		$scope,
		$stateParams,
		$q,
		$state,
		areaService,
		httpService,
		userService,
		additionalPropertyUtilService,
		permissionService,
		engagementScoreService
	) {
		"use strict";

		$scope.areaId = $stateParams.id;

		const createEditDeletePermission = permissionService.hasPermissionV2(Vgr.systemPermissions.CreateEditDeleteArea);
		$scope.createEditDeletePermission = createEditDeletePermission;

		$scope.saveOptions = {
			title: $rootScope.labels.AREAS,
			currentContext: "general",
			back: function () {
				$state.go("unitAreaList");
			},
			general: {
				hasPermission: function () {
					return createEditDeletePermission;
				},
				valid: true,
				returnState: "unitAreaList",
				save: function () {
					const deferred = $q.defer();

					if ($scope.area.id) {
						updateArea().then(
							function () {
								$state.go(
									"areaEdit",
									{
										id: $scope.area.id
									},
									{
										reload: true
									}
								);
							},
							function () {
								deferred.reject();
							}
						);
					} else {
						engagementScoreService.track(Vgr.trackings.area.create);
						createArea().then(
							function (id) {
								$state.go("areaEdit", {
									id: id
								});
							},
							function () {
								deferred.reject();
							}
						);
					}

					return deferred.promise;
				},
				onSaveError: function () {}
			},
			contributors: {
				valid: true,
				buttons: [],
				gridOptions: {
					hasPermission: function () {
						return createEditDeletePermission;
					},
					title: $rootScope.labels.CONTRIBUTORS,
					columnDefinitions: [
						{
							field: "code",
							displayName: function () {
								return $rootScope.labels.commonFields.ID;
							},
							width: 50
						},
						{
							field: "name",
							displayName: function () {
								return $rootScope.labels.userForm.USER_NAME;
							}
						}
					],
					isDisabled: function () {
						return false;
					},
					listCore: function () {
						return httpService.getListFromServiceCore(areaService.listAreaUsers, $scope.areaId);
					},
					deletePopup: {
						title: $rootScope.labels.REMOVE_USER_FROM_AREA_POPUP_TITLE,
						text: $rootScope.labels.REMOVE_USER_FROM_AREA_POPUP_TEXT,
						deleteMethod: areaService.deleteAreaUser,
						descriptionField: "name",
						onClose: function () {
							return httpService.getListFromServiceCore(areaService.listAreaUsers, $scope.areaId);
						},
						getDto: function (user) {
							return {
								areaId: $scope.areaId,
								userId: user.code
							};
						}
					},
					editState: "",
					rowClick: "",
					removeDelete: false,
					create: function () {
						$scope.changeContext("linkContributors");
					},
					promise: {},
					internalTabBack: function () {
						$state.go("unitAreaList");
					}
				}
			},
			linkContributors: {
				valid: true,
				links: {
					selectedLinks: [],
					label: $rootScope.labels.SELECT
				},
				link: function () {
					return addAreaUsers();
				},
				buttons: [$rootScope.labels.LINK],
				back: function () {
					$scope.changeContext("contributors");
				},
				gridOptions: {
					hasPermission: function () {
						return createEditDeletePermission;
					},
					title: $rootScope.labels.CONTRIBUTORS_SELECTION,
					back: function () {
						$scope.changeContext("contributors");
					},
					list: function () {
						const dto = {
							excludedAreaId: $scope.areaId
						};
						return httpService.getListFromServiceV2(userService.listUsers, dto, "Users");
					},
					removeNew: true,
					removeDelete: true,
					enableSelect: true
				}
			}
		};

		function getAreaDto() {
			return {
				code: $scope.area.code,
				userId: $scope.area.User ? $scope.area.User.code : null,
				areaId: $scope.area.id,
				description: $scope.area.description,
				active: $scope.area.active,
				isCentral: $scope.area.isCentral,
				distanceToResidueTerminal: $scope.area.distanceToResidueTerminal,
				employeesNumber: $scope.area.employeesNumber,
				costCenter: $scope.area.costCenter,
				informations: additionalPropertyUtilService.buildAdditionalPropertiesFromGridToRequest(
					$scope.area.InformationsFormated
				)
			};
		}

		function createArea() {
			const deferred = $q.defer();

			const dto = getAreaDto();

			httpService.postDTOToServiceV2(areaService.createArea, dto).then(
				function (data) {
					$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $rootScope.labels.SAVE_SUCCESS_MESSAGE);
					$rootScope.contentNavBar.breadcrumb = data;
					deferred.resolve(data);
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function updateArea() {
			const deferred = $q.defer();

			const model = {
				id: $scope.area.id,
				dto: getAreaDto()
			};

			httpService.postDTOToServiceV2(areaService.updateArea, model).then(
				function (data) {
					$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $rootScope.labels.SAVE_SUCCESS_MESSAGE);
					$rootScope.contentNavBar.breadcrumb = data.id;
					deferred.resolve(data.id);
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		this.$onInit = function () {
			loadArea();
		};

		function loadArea() {
			if ($scope.areaId) {
				httpService.getDTOFromServiceV2(areaService.getArea, $scope.areaId).then(
					function (response) {
						$scope.area = response;
						if ($scope.area.id) {
							$rootScope.contentNavBar.breadcrumb = $scope.area.id;
						}
					},
					function () {
						$state.go("unitAreaList");
					}
				);
			} else {
				$scope.area = {};
			}
		}

		$scope.changeContext = function (context) {
			$scope.saveOptions.currentContext = context;
			$rootScope.contentNavBarGrid = false;
		};

		$scope.getContext = function (context) {
			return $scope.saveOptions[context];
		};

		$scope.getCurrentContext = function () {
			return $scope.getContext($scope.saveOptions.currentContext);
		};

		function addAreaUsers() {
			const selected = $scope.getCurrentContext().links.selectedLinks;
			const ids = [];

			for (const item of selected) {
				ids.push(item.Id);
			}

			const dto = {
				id: $scope.areaId,
				model: {
					UserIds: ids
				}
			};

			return httpService.postDTOToServiceV2(areaService.createAreaUsers, dto);
		}

		$rootScope.contentNavBar = $scope.saveOptions;
		$rootScope.contentNavBarGrid = false;
	}
]);
