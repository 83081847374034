angular.module("vgresiduos").directive("disposalHistoryCustomizedFilter", function () {
	return {
		scope: true,
		controller: "DisposalHistoryCustomizedFilterCtrl",
		controllerAs: "ctrl",
		templateUrl:
			"views/disposalHistory/baseComponents/disposalHistoryCustomizedFilter/disposalHistoryCustomizedFilter_template.html",
		bindToController: {
			isFilterActive: "=",
			onFilter: "=",
			onCleanFilters: "=",
			hasActiveFilters: "="
		}
	};
});
