angular.module("vgresiduos").controller("ResidueReportTotalsCtrl", [
	"$rootScope",
	"$scope",
	"clientService",
	function ($rootScope, $scope, clientService) {
		"use strict";

		$scope.labels = $rootScope.labels;
		var decimalPlaces;

		this.$onInit = function () {
			decimalPlaces =
				$scope.$ctrl.decimalPlaces != null ? $scope.$ctrl.decimalPlaces : clientService.getDecimalPlaces();

			$scope.list = [];

			for (var key in $scope.$ctrl.data) {
				var uniqueRow = $scope.$ctrl.data[key][0];
				$scope.list.push({
					quantity: Vgr.util.formatUnitDecimalPlaces(uniqueRow.Total, decimalPlaces),
					qualifier: uniqueRow.DataQualifiers[0],
					isCurrency: uniqueRow.DataQualifiers[0].includes("$") && !uniqueRow.DataQualifiers[0].includes("/")
				});
			}

			$scope.xsCols = getCols(1);
			$scope.smCols = getCols(2);
			$scope.mdCols = getCols(3);
			$scope.lgCols = getCols(4);
			$scope.xlCols = getCols(5);
		};

		function getCols(max) {
			return $scope.list.length > max ? max : $scope.list.length;
		}
	}
]);
