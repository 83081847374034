angular.module("vgresiduos").directive("goBackButton", function () {
	return {
		scope: true,
		controller: "GoBackButtonCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/goBackButton/goBackButton_template.html",
		bindToController: {
			onClick: "=",
			param: "=?"
		}
	};
});
