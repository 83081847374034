angular.module("vgresiduos").factory("additionalPropertyUtilService", [
	"$rootScope",
	"dialogService",
	"$sce",
	function ($rootScope, dialogService, $sce) {
		"use strict";

		const additionalPropertyUtilService = {};

		const _validateAdditionalPropertyName = function (property, originalPropertyName, allProperties) {
			if (property.name == originalPropertyName && originalPropertyName) {
				return true;
			} else if (
				allProperties.filter(
					(acp) => property.name.toLowerCase() == acp.name.toLowerCase() && property.process == acp.process
				).length != 0
			) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.PROPERTY_NAME_DUPLICATED);
				return false;
			} else if (!property.name) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.NECESSARY_PROPERTY_NAME);
				return false;
			} else {
				return true;
			}
		};

		const _validateResiduesSwitch = function (residuesSwitch, additionalPropertyResidueIds) {
			if (residuesSwitch && additionalPropertyResidueIds == 0) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.NO_RESIDUE_SELECTED);
				return false;
			} else {
				return true;
			}
		};

		function validateOrganizationUnitItens(organizationUnitRadioSelectedId, organizationUnitSelectedListCount) {
			return organizationUnitRadioSelectedId == 2 && organizationUnitSelectedListCount == 0 ? true : false;
		}

		const _validateOrganizationUnitRadio = function (
			organizationUnitRadioSelectedId,
			organizationUnitSelectedListCount,
			additionalPropertyOrganizationUnitIdsCount
		) {
			if (
				validateOrganizationUnitItens(organizationUnitRadioSelectedId, organizationUnitSelectedListCount) &&
				additionalPropertyOrganizationUnitIdsCount == 0
			) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.NO_CLIENT_SELECTED);
				return false;
			} else {
				return true;
			}
		};

		function getCreateFormattedMessage(propertyName) {
			let message = $rootScope.labels.WANT_SAVE_PROPERTY_EDIT;
			if (message) {
				message = message.replace("[PROPERTY_NAME]", propertyName);
				return $sce.trustAsHtml(message);
			}
			return "";
		}

		function getBackFormattedMessage(propertyId) {
			return propertyId ? $rootScope.labels.WANT_CANCEL_PROPERTY_EDIT : $rootScope.labels.WANT_CANCEL_PROPERTY_SAVE;
		}

		const _saveAdditionalPropertyFormDialog = function (propertyName) {
			const params = {
				popupTitle: null,
				popupText: getCreateFormattedMessage(propertyName),
				popupAuxiliaryText: null,
				popupDescription: null,
				cancelButtonLabel: null,
				confirmButtonLabel: null,
				cancelMethod: null,
				confirmMethod: null
			};

			return dialogService.showDialogFromTemplateV2(
				"views/components/popups/confirmPopup_template.html",
				"ConfirmPopupCtrl",
				null,
				params
			);
		};

		const _backAdditionalPropertyFormDialog = function (propertyId) {
			const params = {
				popupTitle: null,
				popupText: getBackFormattedMessage(propertyId),
				popupAuxiliaryText: null,
				popupDescription: null,
				cancelButtonLabel: null,
				confirmButtonLabel: null,
				cancelMethod: null,
				confirmMethod: null
			};

			return dialogService.showDialogFromTemplateV2(
				"views/components/popups/confirmPopup_template.html",
				"ConfirmPopupCtrl",
				null,
				params
			);
		};

		const _setMandatoryResidueToAdditionalProperty = function (process) {
			return process == Vgr.enumerations.additionalProperty.process.InternalGathering ||
				process == Vgr.enumerations.additionalProperty.process.Disposal
				? true
				: false;
		};

		const _buildAdditionalPropertiesFromPopupToGrid = function (additionalInfo) {
			const gridInformation = {
				Id: additionalInfo.Id ? additionalInfo.Id : Vgr.util.uuid(),
				PropertyId: additionalInfo.Property.id,
				PropertyName: additionalInfo.Property.name,
				PropertyType: additionalInfo.Property.type,
				cantDelete: additionalInfo.Property.cantDelete
			};

			if (additionalInfo.Property.type == Vgr.enumerations.additionalProperty.type.SuspendList) {
				gridInformation.InfoValue = additionalInfo.PropertyValue.name;
				gridInformation.InfoValueId = additionalInfo.PropertyValue.id;
			} else {
				gridInformation.InfoValue = additionalInfo.PropertyValue;
			}

			return gridInformation;
		};

		const _buildInformationsToGridFromApiCoreEntityInformation = function (informations) {
			const gridInformations = [];

			for (const information of informations) {
				gridInformations.push({
					Id: information.id,
					PropertyId: information.propertyId,
					PropertyName: information.propertyName,
					InfoValue: information.value,
					InfoValueId: information.valueId
				});
			}

			return gridInformations;
		};

		const _buildInformationsToGridFromApiV2EntityInformation = function (informations) {
			const gridInformations = [];

			for (const information of informations) {
				gridInformations.push({
					Id: information.Id,
					PropertyId: information.Property.Id,
					PropertyName: information.Property.Name,
					PropertyType: information.Property.Type,
					InfoValue: information.Value,
					InfoValueId: information.ValueId,
					cantDelete: information.Property.cantDelete
				});
			}

			return gridInformations;
		};

		const _buildInformationToGridFromApiV2AdditionalProperty = function (property, cantDelete) {
			return {
				Id: Vgr.util.uuid(),
				PropertyId: property.Id,
				PropertyName: property.Name,
				InfoValue: null,
				InfoValueId: null,
				cantDelete: cantDelete
			};
		};

		const _buildInformationToGridFromApiCoreAdditionalProperty = function (property, cantDelete) {
			return {
				Id: Vgr.util.uuid(),
				PropertyId: property.id,
				PropertyName: property.name,
				InfoValue: null,
				InfoValueId: null,
				cantDelete: cantDelete
			};
		};

		const _buildAdditionalPropertiesFromGridToRequest = function (gridInformations) {
			const informations = [];

			if (gridInformations) {
				for (const gridInformation of gridInformations) {
					informations.push({
						Id: gridInformation.Id,
						Property: gridInformation.PropertyId ? gridInformation.PropertyId : gridInformation.PropertyName,
						Value: gridInformation.InfoValueId ? gridInformation.InfoValueId : gridInformation.InfoValue
					});
				}
			}

			return informations;
		};

		const _formatAdditionalPropertiesToGridCore = function (parent) {
			const informationsArray = [];
			if (parent.informations && parent.informations.length) {
				for (const information of parent.informations) {
					informationsArray.push(information.propertyName + ": " + information.value);
				}
			}
			parent.InformationsArray = informationsArray;
		};

		additionalPropertyUtilService.setMandatoryResidueToAdditionalProperty = _setMandatoryResidueToAdditionalProperty;
		additionalPropertyUtilService.validateAdditionalPropertyName = _validateAdditionalPropertyName;
		additionalPropertyUtilService.validateResiduesSwitch = _validateResiduesSwitch;
		additionalPropertyUtilService.validateOrganizationUnitRadio = _validateOrganizationUnitRadio;
		additionalPropertyUtilService.saveAdditionalPropertyFormDialog = _saveAdditionalPropertyFormDialog;
		additionalPropertyUtilService.backAdditionalPropertyFormDialog = _backAdditionalPropertyFormDialog;

		additionalPropertyUtilService.buildInformationsToGridFromApiCoreEntityInformation =
			_buildInformationsToGridFromApiCoreEntityInformation;
		additionalPropertyUtilService.buildInformationsToGridFromApiV2EntityInformation =
			_buildInformationsToGridFromApiV2EntityInformation;
		additionalPropertyUtilService.buildInformationToGridFromApiV2AdditionalProperty =
			_buildInformationToGridFromApiV2AdditionalProperty;
		additionalPropertyUtilService.buildInformationToGridFromApiCoreAdditionalProperty =
			_buildInformationToGridFromApiCoreAdditionalProperty;

		additionalPropertyUtilService.buildAdditionalPropertiesFromPopupToGrid = _buildAdditionalPropertiesFromPopupToGrid;
		additionalPropertyUtilService.buildAdditionalPropertiesFromGridToRequest =
			_buildAdditionalPropertiesFromGridToRequest;

		additionalPropertyUtilService.formatAdditionalPropertiesToGridCore = _formatAdditionalPropertiesToGridCore;

		return additionalPropertyUtilService;
	}
]);
