angular.module("vgresiduos").controller("NameTranslationFieldsetCtrl", [
	"$rootScope",
	"$scope",
	"$q",
	"dialogService",
	"userAccessService",
	"accountService",
	function ($rootScope, $scope, $q, dialogService, userAccessService, accountService) {
		"use strict";
		$scope.currentClient = accountService.getCurrentClient();

		var languageEs = $scope.currentClient.city.state.countryId == Vgr.constants.country.Peru;
		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			$scope.model = {};

			handleLabels();
			handlePrincipalOrOpcional();

			$scope.model.translationActive = $scope.model.nameOpcional ? true : false;

			//feature toogle
			var userAccess = userAccessService.getAccess(null, null, Vgr.constants.featureToggles.release.changeLanguage);
			$scope.hasFeatureToggle = userAccess == Vgr.enumerations.userAccess.Enabled;
		};

		function handleLabels() {
			$scope.switchLabel =
				$rootScope.labels.ADD_NAME_TRANSLATION +
				" " +
				handleLabelResidueOrRecipient(true) +
				handleLanguageTranslation() +
				"?";
			$scope.translationLabel = handleLabelResidueOrRecipient() + handleLanguageTranslation();
		}

		function handleLanguageTranslation() {
			if (!languageEs) {
				return " " + $rootScope.labels.IN_SPANISH;
			}
			return " " + $rootScope.labels.IN_PORTUGUESE;
		}

		function handleLabelResidueOrRecipient(toLowerCase) {
			var labelResidueName = $rootScope.labels.RESIDUE_NAME;
			var labelRecipientName = $rootScope.labels.RECIPIENT_NAME;

			if (labelResidueName == $scope.$ctrl.label) {
				return handletoLowerCase(labelResidueName, toLowerCase);
			}
			return handletoLowerCase(labelRecipientName, toLowerCase);
		}

		function handletoLowerCase(text, toLowerCase) {
			if (toLowerCase) {
				return text.toLowerCase();
			}
			return text;
		}

		function handlePrincipalOrOpcional() {
			if (languageEs) {
				$scope.model.namePrincipal = $scope.$ctrl.nameEs;
				$scope.model.nameOpcional = $scope.$ctrl.namePt;
				return;
			}

			$scope.model.namePrincipal = $scope.$ctrl.namePt;
			$scope.model.nameOpcional = $scope.$ctrl.nameEs;
		}

		$scope.$watch("model.nameOpcional", function (newValue, oldValue) {
			if (newValue !== oldValue) {
				if (languageEs) {
					$scope.$ctrl.namePt = newValue;
				} else $scope.$ctrl.nameEs = newValue;
			}
		});

		$scope.$watch("model.namePrincipal", function (newValue, oldValue) {
			if (newValue !== oldValue) {
				if (languageEs) {
					$scope.$ctrl.nameEs = newValue;
				} else $scope.$ctrl.namePt = newValue;
			}
		});

		$scope.$on("singleBaseChanged", function (event, newValue) {
			if (!languageEs) {
				$scope.model.namePrincipal = newValue;
			}
			$scope.model.nameOpcional = null;
		});

		$scope.onSwitchChange = function () {
			if (!$scope.model.translationActive) {
				if ((!languageEs && $scope.$ctrl.nameEs) || (languageEs && $scope.$ctrl.namePt)) {
					openCleaningConfirmation();
				}
			}
		};

		function getParams() {
			return {
				popupTitle: null,
				popupText: getPopupDescription(),
				popupAuxiliaryText: null,
				cancelButtonLabel: $rootScope.labels.NO,
				confirmButtonLabel: $rootScope.labels.YES,
				popupDescription: null,
				cancelMethod: function () {
					var deferred = $q.defer();
					$scope.model.translationActive = true;
					deferred.resolve();
					return deferred.promise;
				},
				confirmMethod: function () {
					var deferred = $q.defer();
					clearNameTranslationField();
					deferred.resolve();
					return deferred.promise;
				}
			};
		}

		function getPopupDescription() {
			return (
				$rootScope.labels.CONFIRM_CLEAN_NAME +
				" " +
				handleLabelResidueOrRecipient(true) +
				handleLanguageTranslation() +
				". " +
				$rootScope.labels.WILL_LOST
			);
		}

		function openCleaningConfirmation() {
			var deferred = $q.defer();
			var params = getParams();
			dialogService
				.showDialogFromTemplateV2(
					"views/components/popups/confirmPopup_template.html",
					"ConfirmPopupCtrl",
					null,
					params
				)
				.then(
					function () {
						deferred.resolve();
					},
					function () {
						deferred.reject();
					}
				);
			return deferred.promise;
		}

		function clearNameTranslationField() {
			$scope.model.nameOpcional = null;
			if (languageEs) {
				$scope.$ctrl.namePt = null;
				return;
			}
			$scope.$ctrl.nameEs = null;
		}
	}
]);
