angular.module("vgresiduos").factory("responseErrorInterceptor", [
	"$q",
	"$injector",
	"localStorageService",
	"$rootScope",
	"$location",
	function ($q, $injector, localStorageService, $rootScope, $location) {
		"use strict";

		var incrementalTimeout = 1000;

		function retryRequest(httpConfig) {
			var $timeout = $injector.get("$timeout");
			var ret = $timeout(function () {
				var $http = $injector.get("$http");
				return $http(httpConfig);
			}, incrementalTimeout);
			incrementalTimeout *= 2;
			return ret;
		}

		return {
			responseError: function (response) {
				if (response.status === -1) {
					if (incrementalTimeout < 5000) {
						return retryRequest(response.config);
					} else {
						console.log("The remote server seems to be busy at the moment. Please try again in 5 minutes");
						if (
							response.config.url.indexOf(Vgr.constants.newHostUrl) == -1 &&
							response.config.url.indexOf(Vgr.constants.coreHostUrl) == -1 &&
							response.config.url.indexOf(Vgr.constants.biHostUrl) == -1 &&
							response.config.url.indexOf(Vgr.constants.sharedServicesHostUrl) == -1 &&
							response.config.url.indexOf(Vgr.constants.residuesManagementHostUrl) == -1 &&
							response.config.url.indexOf(Vgr.constants.suppliersHostUrl) == -1 &&
							response.config.url.indexOf(Vgr.constants.residueMarketHostUrl) == -1 &&
							response.config.url.indexOf(Vgr.constants.residuesHostUrl) == -1 &&
							response.config.url.indexOf(Vgr.constants.integrationsHostUrl) == -1 &&
							response.config.url.indexOf("localhost") >= 0
						) {
							$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.LOCAL_SERVER_CONNECTION_ERROR);
						} else {
							$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.SERVER_CONNECTION_ERROR);
						}
						incrementalTimeout = 1000;
					}
				} else {
					incrementalTimeout = 1000;
				}
				return $q.reject(response);
			}
		};
	}
]);
