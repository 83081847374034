angular.module("vgresiduos").factory("disposalTrackingService", [
	"engagementScoreService",
	function (engagementScoreService) {
		"use strict";

		function _trackDuration(duration) {
			engagementScoreService.trackDuration(Vgr.trackings.disposal.flow.durationCreateDestination, duration);
		}

		return {
			trackDuration: _trackDuration
		};
	}
]);
