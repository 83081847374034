angular.module("vgresiduos").controller("PricingGridCtrl", [
	"$scope",
	"$q",
	"$rootScope",
	"$state",
	"httpService",
	"pricingService",
	"localStorageService",
	"permissionService",
	"accountService",
	function (
		$scope,
		$q,
		$rootScope,
		$state,
		httpService,
		pricingService,
		localStorageService,
		permissionService,
		accountService
	) {
		"use strict";

		var viewPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.MasterViewPrice);
		var createPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.MasterCreateEditDeletePrice);

		var negotiationTypes = pricingService.listNegotiationTypes();
		var labels = $rootScope.labels;

		var columns = [
			{
				field: "DateString",
				displayName: function () {
					return labels.DATE;
				},
				notSortable: true
			},
			{
				field: "ResidueNameString",
				displayName: function () {
					return labels.RESIDUE;
				},
				notSortable: true
			},
			{
				field: "PriceAndTypeString",
				displayName: function () {
					return labels.PRICE;
				},
				notSortable: true
			},
			{
				field: "NegotiationTypeString",
				displayName: function () {
					return labels.TYPE;
				},
				notSortable: true
			},
			{
				field: "DestinatorNameString",
				displayName: function () {
					return labels.DESTINATOR;
				},
				notSortable: true
			},
			{
				field: "GeneratorNameString",
				displayName: function () {
					return labels.GENERATOR;
				},
				notSortable: true
			},
			{
				editOptions: [
					{
						type: "custom",
						icon: "library_add",
						label: $scope.labels.DUPLICATE,
						disable: function (row) {
							return !createPermission;
						},
						click: function (row, column) {
							$state.go("pricingCreate", { duplicar: row.Id });
						}
					},
					{
						type: "custom",
						icon: "edit",
						label: $scope.labels.EDIT,
						disable: function (row) {
							return !createPermission;
						},
						click: function (row, column) {
							$state.go("pricingEdit", { id: row.Id });
						}
					},
					{
						type: "custom",
						icon: "visibility",
						label: $scope.labels.VIEW,
						disable: function (row) {
							return createPermission;
						},
						click: function (row, column) {
							$state.go("pricingEdit", { id: row.Id });
						}
					},
					{
						type: "delete",
						icon: "delete",
						label: $scope.labels.DELETE,
						disable: function (row) {
							return !createPermission;
						},
						click: function (row, column) {}
					}
				]
			}
		];

		$scope.gridOptions = {
			hasPermission: function () {
				return createPermission;
			},
			enableSelect: false,
			filterFields: [],
			filterSubFields: [],
			gridFilter: {
				text: ""
			},
			list: function () {
				return getPricingsList();
			},
			columnDefinitions: columns,
			title: labels.PRICES,
			query: {
				order: "-DateString",
				limit: 20,
				page: 1,
				serverPagination: true,
				onPaginate: function (page, limit, query, column, order, tagList, dateFilter) {
					$scope.gridOptions.query.page = page;
					$scope.gridOptions.query.limit = limit;
					getPricingsList(query);
				},
				onReorder: function (query, column, order, tagList, dateFilter) {
					getPricingsList(query);
				}
			},
			onFilter: function (query, column, order, tagList, dateFilter) {
				getPricingsList(query);
			},
			data: [],
			delete: function (selected, gridData, filteredData, gridFilter) {
				var deferred = $q.defer();
				var dto = {
					id: selected[0].Id
				};
				httpService.getDTOFromServiceV2(pricingService.deletePricing, dto).then(
					function (data) {
						getPricingsList(gridFilter.text);
						deferred.resolve();
					},
					function () {
						deferred.reject();
					}
				);
				return deferred.promise;
			},
			rowClick: function (row) {
				$state.go("pricingEdit", { id: row.Id });
			},
			create: function () {
				$state.go("pricingCreate");
			},
			promise: {},
			hasData: false,
			errorLoading: false,
			removeDelete: true,
			isChipsFilter: true
		};
		$rootScope.contentNavBarGrid = $scope.gridOptions;

		function getPricingsList(nameLike) {
			var deferred = $q.defer();
			$scope.gridOptions.hasData = false;
			var dto = getListingDTO(nameLike);
			httpService.getDTOFromServiceV2(pricingService.listPricings, dto).then(
				function (data) {
					formatPricings(data.Pricings);
					$scope.gridOptions.data = angular.copy(data.Pricings);
					$scope.gridOptions.query.total = data.Count;
					$scope.gridOptions.hasData = true;
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);
			return deferred.promise;
		}

		function formatPricings(pricings) {
			for (var pricing of pricings) {
				pricing.DateString = Vgr.util.dateToString(new Date(pricing.Date));
				pricing.ResidueNameString = pricing.Residue.Name;
				pricing.PriceAndTypeString = isByResiduePricingSelected(pricing)
					? "R$ " + Vgr.util.formatUnitDecimalPlaces(pricing.UnitaryPrice, 2) + "/" + pricing.MeasureUnit.Abbreviation
					: "R$ " + Vgr.util.formatUnitDecimalPlaces(pricing.TotalPrice, 2);
				pricing.NegotiationTypeString = negotiationTypes.find((type) => type.Id == pricing.NegotiationType).Label;
				pricing.DestinatorNameString = pricing.Destinator ? pricing.Destinator.Name : pricing.DestinatorName;
				pricing.GeneratorNameString = pricing.Generator ? pricing.Generator.Name : pricing.GeneratorName;
			}
		}

		function isByResiduePricingSelected(pricing) {
			return pricing.PricingType == Vgr.enumerations.pricing.pricingType.Residue;
		}

		function getListingDTO(nameLike, skip, take) {
			return {
				nameLike: nameLike,
				skip: $scope.gridOptions.query.page - 1,
				take: localStorageService.get("grid.pageLimit") || Vgr.constants.DEFAULT_ROWS_PER_PAGE
			};
		}

		this.$onInit = function () {
			if (!viewPermission) {
				accountService.goHome();
			}
		};
	}
]);
