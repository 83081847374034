angular.module("vgresiduos").factory("scaleService", [
	"$http",
	"$q",
	"accountService",
	"localStorageWithTimeoutService",
	function ($http, $q, accountService, localStorageWithTimeoutService) {
		"use strict";

		const scalesCacheKey = Vgr.constants.cacheKeysPrefix.scales;
		const cacheTimeoutInMinutes = 43200;

		const _listScales = function (scaleProcess) {
			const client = accountService.getCurrentClient();
			let cacheKey = scalesCacheKey;
			if (client) {
				cacheKey += client.id;
			}
			return Vgr.util.getWithCache(
				Vgr.constants.newHostUrl + Vgr.resources.apiV1.scale,
				{
					process: scaleProcess
				},
				cacheKey,
				cacheTimeoutInMinutes,
				localStorageWithTimeoutService,
				$http,
				$q
			);
		};

		return {
			listScales: _listScales
		};
	}
]);
