angular.module("vgresiduos").controller("CustomizedFilterOptionCtrl", [
	"$rootScope",
	"$scope",
	"$timeout",
	function ($rootScope, $scope, $timeout) {
		"use strict";
		$scope.labels = $rootScope.labels;
		$scope.elementId = Vgr.util.uuid();

		this.$onInit = function () {
			configureLayout();
		};

		function configureLayout() {
			const element = document.getElementById("elementId");
			if (element && element.offsetWidth) {
				if (element.offsetWidth < 650) {
					$scope.layoutColumn = true;
				} else {
					$scope.layoutColumn = false;
				}
			}
		}

		$scope.onSelectField = function (newField, oldField) {
			if (!newField) {
				return;
			}

			if (!oldField && newField && !$scope.ctrl.selected.value && $scope.ctrl.selected.initialValue) {
				$scope.ctrl.selected.value = $scope.ctrl.selected.initialValue;
			} else {
				$scope.ctrl.selected.value = "";
			}

			$scope.changing = true;
			$timeout(function () {
				$scope.changing = false;
			});
			if ($scope.ctrl.onChangeField) {
				$scope.ctrl.onChangeField(newField);
			}
		};

		$scope.isFromGroupingOptionType = function () {
			return $scope.ctrl.selected.field.type == Vgr.enumerations.customizedFilter.type.GroupingOption;
		};
	}
]);
