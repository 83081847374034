angular.module("vgresiduos").controller("UsersMultiSelectCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"userService",
	"accountService",
	function ($scope, $rootScope, $q, httpService, userService, accountService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		var isListLoaded = false;

		this.$onInit = function () {
			$scope.searchLabel = "";
			$scope.subfieldList = [
				{
					item: "id",
					label: $scope.labels.ID
				},
				{
					item: "name",
					label: $scope.labels.Name
				}
			];

			listUsers();
		};

		function listUsers() {
			var deferred = $q.defer();

			if (isListLoaded) {
				deferred.resolve();
				return deferred.promise;
			}

			var dto = null;
			
			if (!$scope.$ctrl.organizationUsers) {
				dto = {
					organizationUnitCode: accountService.getCurrentClient().code
				};
			}

			httpService.getDTOFromServiceV2(userService.listUsersV2, dto).then(
				function (list) {
					if ($scope.$ctrl.excluded) {
						list = list.filter((u) => !$scope.$ctrl.excluded.includes(u.id));
					}
					$scope.users = list;
					isListLoaded = true;
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}
	}
]);
