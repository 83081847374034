angular.module("vgresiduos").controller("CustomReportFilterPreferenceMeasureUnitSectionViewCtrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;
			$scope.measureUnitOptionEnum = Vgr.enumerations.customReport.measureUnitPreferenceOption;

			loadMeasureUnitLabel($scope.$ctrl.setter);
		};

		function loadMeasureUnitLabel(setter) {
			if (setter.option === Vgr.enumerations.customReport.measureUnitPreferenceOption.ConvertToSpecificUnit) {
				$scope.measureUnitOption = setter.measureUnit.description + " (" + setter.measureUnit.abbreviation + ")";
			} else {
				$scope.measureUnitOption = $scope.labels.MEASURE_UNIT_ENUM[setter.option];
			}
		}
	}
]);
