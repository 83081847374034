angular.module("vgresiduos").controller("DisposalResidueStorages", [
	"$scope",
	"$rootScope",
	"$state",
	"$window",
	"clientService",
	"userAccessService",
	function ($scope, $rootScope, $state, $window, clientService, userAccessService) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.showContent = false;
		const decimals = clientService.getDecimalPlaces();

		this.$onInit = function () {
			$scope.columns = [
				{
					description: $scope.labels.ID,
					property: "Id",
					action: {
						class: "text-underline",
						enabled: function (item) {
							return !!item.Id;
						},
						onClick: function (item) {
							const url = $state.href(getPageState(), { id: item.Id });
							$window.open(url, "_blank");
						}
					},
					width: 100
				},
				{
					description: $scope.labels.DATE,
					property: "StorageDate",
					width: 100
				},
				{
					description: $scope.labels.AREA,
					property: "AreaName"
				},
				{
					description: $scope.labels.RESPONSIBLE,
					property: "ResponsibleName"
				},
				{
					description: $scope.labels.GENERATION_QUANTITY,
					property: "GeneratedQuantity"
				},
				{
					description: $scope.labels.DISPOSED_AMOUNT,
					property: "DisposedQuantity"
				}
			];

			formatListToGrid();
		};

		function formatListToGrid() {
			$scope.list = [];
			for (const storage of $scope.ctrl.storages) {
				$scope.list.push({
					Id: storage.Id,
					StorageDate: Vgr.util.dateToString(new Date(storage.StorageDate)),
					AreaName: storage.Area ? storage.Area.Description : "",
					ResponsibleName: storage.ResponsibleName,
					GeneratedQuantity: $scope.ctrl.isRecipientStockControlType
						? storage.RecipientQuantity
						: formatQuantity(storage.GeneratedQuantity, storage.MeasureUnit),
					DisposedQuantity: $scope.ctrl.isRecipientStockControlType
						? storage.RecipientQuantity ?? "-"
						: formatQuantity(storage.DisposedQuantity, storage.MeasureUnit)
				});
			}
		}

		function formatQuantity(quantity, measureUnit) {
			return `${Vgr.util.formatUnitDecimalPlaces(quantity, decimals)} ${measureUnit?.Abbreviation}`;
		}

		function getPageState() {
			return "storageEdit";
		}
	}
]);
