angular.module("vgresiduos").component("temporaryStoragerMultiSelectV2", {
	controller: "TemporaryStoragerMultiSelectV2Ctrl",
	templateUrl:
		"views/components/multiSelects/temporaryStoragerMultiSelect/temporaryStoragerMultiSelectV2_template.html",
	bindings: {
		setter: "<?",
		callback: "<",
		clear: "<?"
	}
});
