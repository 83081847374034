angular.module("vgresiduos").factory("processService", [
	"$http",
	"accountService",
	($http, accountService) => {
		"use strict";

		function getBaseUrl() {
			const organizationUnit = accountService.getCurrentClient();
			const organizationId = organizationUnit.organization.id;
			const organizationUnitCode = organizationUnit.code;
			return (
				Vgr.constants.coreHostUrl +
				Vgr.resources.core.process
					.replace("[ORGANIZATION]", organizationId)
					.replace("[ORGANIZATION_UNIT]", organizationUnitCode)
			);
		}

		const _getProcessList = () => {
			const req = {
				method: "get",
				url: getBaseUrl()
			};
			return $http(req);
		};

		return {
			getProcessList: _getProcessList
		};
	}
]);
