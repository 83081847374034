angular.module("vgresiduos").controller("RawMaterialListCtrl", [
	"$stateParams",
	"$rootScope",
	function ($stateParams, $rootScope) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, "unidades/materias-primas");
		};
	}
]);
