angular.module("vgresiduos").controller("DestinatorMultiSelectV2Ctrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"supplierService",
	function ($scope, $rootScope, httpService, supplierService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			listDestinator();
		};

		function listDestinator() {
			httpService
				.getListFromServiceCore(supplierService.listOrganizationSupplier, {
					isDestinator: true
				})
				.then(function (response) {
					$scope.destinators = response.list.map((supplier) => {
						return {
							id: supplier.supplierId.toString(),
							name: supplier.name,
							displayText: supplier.supplierId + " - " + supplier.name
						};
					});
				});
		}

		$scope.onChanged = function (selectedValues) {
			callback(selectedValues);
		};

		function callback(selectedValues) {
			if ($scope.$ctrl.callback) {
				$scope.$ctrl.callback(selectedValues);
			}
		}
	}
]);
