angular.module("vgresiduos").factory("permissionGroupService", [
	"$http",
	"accountService",
	function ($http, accountService) {
		"use strict";

		const permissionGroupService = {};

		function getUnitBaseUrl(code) {
			const client = accountService.getCurrentClient();
			const organizationId = client.organization.id;
			const organizationUnitCode = code ? code : client.code;
			return (
				Vgr.constants.coreHostUrl +
				Vgr.resources.core.organizationUnitPermissions
					.replace("[ORGANIZATION_ID]", organizationId)
					.replace("[ORGANIZATION_UNIT_CODE]", organizationUnitCode)
			);
		}

		function getOrganizationBaseUrl() {
			const client = accountService.getCurrentClient();
			const organizationId = client.organization.id;
			return (
				Vgr.constants.coreHostUrl +
				Vgr.resources.core.organizationPermissions.replace("[ORGANIZATION_ID]", organizationId)
			);
		}

		const _createUnitPermission = function (model) {
			const req = {
				method: "post",
				data: model,
				url: getUnitBaseUrl()
			};
			return $http(req);
		};

		const _listUnitPermissions = function (code) {
			const req = {
				method: "get",
				url: getUnitBaseUrl(code)
			};
			return $http(req);
		};

		const _listAllUnitPermissions = function () {
			const client = accountService.getCurrentClient();
			const organizationId = client.organization.id;
			const req = {
				method: "get",
				url:
					Vgr.constants.coreHostUrl + "v1/organizations/" + organizationId + "/all-organization-units-permission-groups"
			};
			return $http(req);
		};

		const _addUserToMultiplePermissionGroups = function (dto) {
			const client = accountService.getCurrentClient();
			const organizationId = client.organization.id;
			const req = {
				method: "post",
				url:
					Vgr.constants.coreHostUrl +
					"v1/organizations/" +
					organizationId +
					"/permission-groups/batch-add-user-multiple-units",
				data: dto
			};
			return $http(req);
		};

		const _getUnitPermission = function (id) {
			const req = {
				method: "get",
				url: getUnitBaseUrl() + id
			};
			return $http(req);
		};

		const _updateUnitPermission = function (dto) {
			const req = {
				method: "put",
				data: dto.model,
				url: getUnitBaseUrl() + dto.id
			};
			return $http(req);
		};

		const _deleteUnitPermission = function (id) {
			const req = {
				method: "delete",
				url: getUnitBaseUrl() + id
			};
			return $http(req);
		};

		const _createOrganizationPermission = function (model) {
			const req = {
				method: "post",
				data: model,
				url: getOrganizationBaseUrl()
			};
			return $http(req);
		};

		const _listOrganizationPermissions = function () {
			const req = {
				method: "get",
				url: getOrganizationBaseUrl()
			};
			return $http(req);
		};

		const _getOrganizationPermission = function (id) {
			const req = {
				method: "get",
				url: getOrganizationBaseUrl() + id
			};
			return $http(req);
		};

		const _updateOrganizationPermission = function (dto) {
			const req = {
				method: "put",
				data: dto.model,
				url: getOrganizationBaseUrl() + dto.id
			};
			return $http(req);
		};

		const _deleteOrganizationPermission = function (id) {
			const req = {
				method: "delete",
				url: getOrganizationBaseUrl() + id
			};
			return $http(req);
		};

		const _addUsersToUnitPermission = function (dto) {
			const req = {
				method: "post",
				data: dto.model,
				url: getUnitBaseUrl(dto.organizationUnitCode) + dto.permissionGroupId + "/users"
			};
			return $http(req);
		};

		const _addUsersToOrganizationPermission = function (dto) {
			const req = {
				method: "post",
				data: dto.model,
				url: getOrganizationBaseUrl() + dto.permissionGroupId + "/users"
			};
			return $http(req);
		};

		const _removeUserFromUnitPermission = function (dto) {
			const req = {
				method: "delete",
				url: getUnitBaseUrl(dto.organizationUnitCode) + dto.permissionGroupId + "/users/" + dto.id
			};
			return $http(req);
		};

		const _removeUserFromOrganizationPermission = function (dto) {
			const req = {
				method: "delete",
				url: getOrganizationBaseUrl() + dto.permissionGroupId + "/users/" + dto.id
			};
			return $http(req);
		};

		const _addUsersToPermissionGroup = function (dto) {
			if (dto.type == Vgr.enumerations.systemPermissions.typesText.OrganizationUnit) {
				return _addUsersToUnitPermission(dto);
			} else {
				return _addUsersToOrganizationPermission(dto);
			}
		};

		const _removeUserFromPermissionGroup = function (dto) {
			if (dto.type == Vgr.enumerations.systemPermissions.typesText.OrganizationUnit) {
				return _removeUserFromUnitPermission(dto);
			} else {
				return _removeUserFromOrganizationPermission(dto);
			}
		};

		permissionGroupService.createUnitPermission = _createUnitPermission;
		permissionGroupService.listUnitPermissions = _listUnitPermissions;
		permissionGroupService.listAllUnitPermissions = _listAllUnitPermissions;
		permissionGroupService.getUnitPermission = _getUnitPermission;
		permissionGroupService.updateUnitPermission = _updateUnitPermission;
		permissionGroupService.deleteUnitPermission = _deleteUnitPermission;

		permissionGroupService.createOrganizationPermission = _createOrganizationPermission;
		permissionGroupService.listOrganizationPermissions = _listOrganizationPermissions;
		permissionGroupService.getOrganizationPermission = _getOrganizationPermission;
		permissionGroupService.updateOrganizatioPermission = _updateOrganizationPermission;
		permissionGroupService.deleteOrganizationPermission = _deleteOrganizationPermission;

		permissionGroupService.addUsersToPermissionGroup = _addUsersToPermissionGroup;
		permissionGroupService.addUserToMultiplePermissionGroups = _addUserToMultiplePermissionGroups;
		permissionGroupService.removeUserFromPermissionGroup = _removeUserFromPermissionGroup;

		return permissionGroupService;
	}
]);
