angular.module("vgresiduos").controller("IbamaRappListCtrl", [
	"$rootScope",
	"$scope",
	"$q",
	"$state",
	"$stateParams",
	function ($rootScope, $scope, $q, $state, $stateParams) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.referenceYear = 2020;
		$scope.stopYear = new Date().getFullYear() - 1;

		$scope.model = {
			//todo: remover ano que vem
			year: $scope.stopYear
		};

		$rootScope.contentNavBar = {
			title: $scope.labels.RAPP_IBAMA_INTEGRATION,
			valid: true,
			customButtons: [
				{
					label: $scope.labels.NEW_INTEGRATION,
					buttonClasses: "md-primary",
					icon: "add",
					supressToast: true,
					onClick: function () {
						var deferred = $q.defer();
						$state.go("ibamaRappCreate");
						deferred.resolve();
						return deferred.promise;
					}
				}
			],
			buttons: []
		};

		this.$onInit = function () {
			if ($stateParams.ano) {
				$scope.model.year = $stateParams.ano;
			}
			calculateNewIntegrationButton($scope.model.year);
		};

		function calculateNewIntegrationButton(year) {
			if (year && year == $scope.stopYear) {
				$rootScope.contentNavBar.buttons = [$scope.labels.NEW_INTEGRATION];
			} else {
				$rootScope.contentNavBar.buttons = [];
			}
		}

		$scope.yearChanged = function (year) {
			calculateNewIntegrationButton(year);
		};
	}
]);
