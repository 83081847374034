angular.module("vgresiduos").factory("organizationService", [
	"$http",
	"$rootScope",
	function ($http, $rootScope) {
		"use strict";

		const _listOrganizations = function (params) {
			const parameters = Vgr.util.serializeObjectToQueryString(params);

			const req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.organization + parameters
			};
			return $http(req);
		};

		const _getOrganization = function (id) {
			const req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.organization + id
			};
			return $http(req);
		};

		const _updateOrganization = function (data) {
			const req = {
				method: "put",
				data: data.model,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.organization + data.id
			};
			return $http(req);
		};

		const _listMasterOrganizations = function (data) {
			const req = {
				method: "post",
				data: data,
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.masterOrganizations
			};
			return $http(req);
		};

		const _listContractTypes = function () {
			return [
				{
					Id: Vgr.enumerations.organization.contractType.Payer,
					Name: $rootScope.labels.ORGANIZATION_PAYMENT_PLAN_ENUM[Vgr.enumerations.organization.contractType.Payer]
				},
				{
					Id: Vgr.enumerations.organization.contractType.NotPayer,
					Name: $rootScope.labels.ORGANIZATION_PAYMENT_PLAN_ENUM[Vgr.enumerations.organization.contractType.NotPayer]
				},
				{
					Id: Vgr.enumerations.organization.contractType.Trial,
					Name: $rootScope.labels.ORGANIZATION_PAYMENT_PLAN_ENUM[Vgr.enumerations.organization.contractType.Trial]
				}
			];
		};

		// CORE
		const _updateOrganizationCore = function (data) {
			const req = {
				method: "put",
				data: data.model,
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.organization + data.id
			};
			return $http(req);
		};

		const _getOrganizationCore = function (id) {
			const req = {
				method: "get",
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.organization + id
			};
			return $http(req);
		};

		const _isOrganizationOnlySSO = function (identityProvider) {
			const req = {
				method: "get",
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.organization + "identity-provider/" + identityProvider
			};
			return $http(req);
		};

		return {
			listOrganizations: _listOrganizations,
			getOrganization: _getOrganization,
			updateOrganization: _updateOrganization,
			listContractTypes: _listContractTypes,

			updateOrganizationCore: _updateOrganizationCore,
			getOrganizationCore: _getOrganizationCore,
			isOrganizationOnlySSO: _isOrganizationOnlySSO,

			listMasterOrganizations: _listMasterOrganizations
		};
	}
]);
