angular.module("vgresiduos").component("nameTranslationFieldset", {
	controller: "NameTranslationFieldsetCtrl",
	templateUrl: "views/components/fieldsets/nameTranslationFieldset/nameTranslationFieldset_template.html",
	bindings: {
		namePt: "=?",
		nameEs: "=?",
		label: "<?",
		maxlength: "<?"
	}
});
