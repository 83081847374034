angular.module("vgresiduos").controller("EditReportDescriptionPopupCtrl", [
	"$scope",
	"$rootScope",
	"dialogService",
	"httpService",
	"$q",
	"customReportService",
	"id",
	"description",
	function ($scope, $rootScope, dialogService, httpService, $q, customReportService, id, description) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.popupTitle = $scope.labels.EDIT_NAME_REPORT;
		$scope.model = { reportDescription: description };

		$scope.popupActionButtons = [
			{
				label: $scope.labels.CANCEL,
				class: "md-primary",
				click: function () {
					dialogService.cancel();
				},
				disabled: false
			},
			{
				label: $scope.labels.SAVE,
				class: "md-primary md-raised",
				click: function () {
					editReportName();
				},
				disabled: false
			}
		];

		function toggleButtonsEnabledState() {
			$scope.popupActionButtons.forEach((button) => (button.disabled = !button.disabled));
		}

		function editReportName() {
			toggleButtonsEnabledState();

			let params = {
				description: $scope.model.reportDescription,
				id: id
			};

			httpService.getDTOFromServiceV2(customReportService.editReportName, params).then(
				function () {
					dialogService.confirm($scope.model.reportDescription);
				},
				function () {
					toggleButtonsEnabledState();
				}
			);
		}
	}
]);
