angular.module("vgresiduos").controller("PricingPopupCtrl", [
	"$rootScope",
	"$scope",
	"httpService",
	"pricingService",
	function ($rootScope, $scope, httpService, pricingService) {
		"use strict";
		$scope.ImagePath = "img/pricing-graph-blur.jpg";
		$scope.labels = $rootScope.labels;

		$scope.knowMore = function () {
			$scope.showKnowMoreMessage = true;
			$scope.notClickedYet = false;
			httpService.postDTOToServiceV2(pricingService.requestPriceConsult);
		};

		function initializePopup() {
			$scope.notClickedYet = true;
		}

		initializePopup();
	}
]);
