angular.module("vgresiduos").directive("organizationForm", function () {
	return {
		scope: true,
		controller: "OrganizationFormCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/organization/form/organizationForm/organizationForm_template.html",
		bindToController: {
			model: "="
		}
	};
});
