angular.module("vgresiduos").controller("popupButtonCtrl", [
	"$scope",
	"$timeout",
	function ($scope, $timeout) {
		"use strict";

		$scope.isPopupVisible = false;

		$scope.togglePopup = function () {
			$timeout(function () {
				$scope.isPopupVisible = !$scope.isPopupVisible;
				$("#popup-button-" + $scope.guid + " .popup-button-button").css("z-index", $scope.isPopupVisible ? 1000 : 0);
			});
		};

		$scope.getPopupButtonButtonStyle = function () {
			return $scope.popupButtonButtonStyle;
		};
	}
]);
