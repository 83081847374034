angular.module("vgresiduos").controller("SelectDisposalResidueDocumentCtrl", [
	"$scope",
	"$rootScope",
	"dialogService",
	"documentRequired",
	"selectedDocumentId",
	"availableDocuments",
	"documentType",
	function ($scope, $rootScope, dialogService, documentRequired, selectedDocumentId, availableDocuments, documentType) {
		"use strict";

		const labels = $rootScope.labels;
		$scope.popupActionButtons = [
			{
				label: labels.CANCEL,
				class: "md-primary",
				click: function () {
					dialogService.cancel();
				},
				disabled: false
			},
			{
				label: labels.FINISH_REQUEST,
				class: "md-primary md-raised",
				click: function () {
					finish();
				},
				disabled: false
			}
		];

		function initializePopup() {
			$scope.popupTitle = `${labels.DOCUMENT} ${documentType}`;

			$scope.model = {
				selected: selectedDocumentId,
				list: getFormattedList(),
				required: documentRequired
			};
		}

		function getFormattedList() {
			availableDocuments.forEach((a) => {
				if (a.code) {
					a.customDescription = a.code + (a.description ? ` - ${a.description}` : "");
				} else if (a.description) {
					a.customDescription = a.description;
				}
			});

			return availableDocuments;
		}

		$scope.getSelectLabel = function () {
			if (documentRequired) {
				return documentType + "*";
			}
			return documentType;
		};

		function finish() {
			if (documentRequired && !$scope.model.selected) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.DEFAULT_VALIDATION_MESSAGE);
				return;
			}

			const optionSelected = availableDocuments.find((ad) => ad.id == $scope.model.selected);
			dialogService.confirm(optionSelected);
		}

		initializePopup();
	}
]);
