angular.module("vgresiduos").controller("StatesSelectFieldCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"locationService",
	function ($scope, $rootScope, httpService, locationService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			loadStates();
		};

		function loadStates() {
			if ($scope.States) {
				return;
			}
			httpService.getListFromServiceCore(locationService.listStates).then((response) => {
				$scope.States = response.list;
				setStatesId();
				if ($scope.ctrl.allStatesField) {
					addAllStatesFieldToList();
				}
			});
		}

		function setStatesId() {
			for (const state of $scope.States) {
				state.Id = state.id;
			}
		}

		function addAllStatesFieldToList() {
			const allStatesItem = {
				description: $scope.labels.ALL,
				id: null,
				Id: null
			};
			$scope.States.push(allStatesItem);
			$scope.ctrl.stateId = allStatesItem.Id;
		}
	}
]);
