angular.module("vgresiduos").controller("DateFilterCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		var date = new Date();
		$scope.firstDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1);
		$scope.lastDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

		$scope.firstDayOfPreviousMonth =
			date.getMonth() == 0
				? new Date(date.getFullYear() - 1, 11, 1)
				: new Date(date.getFullYear(), date.getMonth() - 1, 1);
		$scope.lastDayOfPreviousMonth = new Date(date.getFullYear(), date.getMonth(), 0);

		$scope.firstDayOfYear = new Date(date.getFullYear(), 0, 1);
		$scope.lastDayOfYear = new Date(date.getFullYear() + 1, 0, 0);

		$scope.firstDayOfLastYear = new Date(date.getFullYear() - 1, 0, 1);
		$scope.lastDayOfLastYear = new Date(date.getFullYear(), 0, 0);

		$scope.today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
		$scope.firstDayOfLast12Months = new Date(date.getFullYear() - 1, date.getMonth(), 1);

		$scope.intervalTypeList = [
			{
				ID: 0,
				Description: $rootScope.labels.PREVIOUS_MONTH
			},
			{
				ID: 1,
				Description: $rootScope.labels.CURRENT_MONTH
			},
			{
				ID: 2,
				Description: $rootScope.labels.CURRENT_YEAR
			},
			{
				ID: 3,
				Description: $rootScope.labels.PREVIOUS_YEAR
			},
			{
				ID: 4,
				Description: $rootScope.labels.LAST_TWELVE_MONTHS
			},
			{
				ID: 5,
				Description: $rootScope.labels.CUSTOM
			}
		];

		$scope.onDateFilterSelectChange = function (selected) {
			if (!$scope.ctrl.selectedDates) {
				$scope.ctrl.selectedDates = {};
			}

			if (typeof selected === "undefined") {
				$scope.ctrl.selectedDates.dateId = 1;
				$scope.ctrl.selectedDates.beginDate = $scope.firstDayOfCurrentMonth;
				$scope.ctrl.selectedDates.endDate = $scope.ctrl.dontLimitToday ? $scope.lastDayOfCurrentMonth : $scope.today;
			} else if (selected == 0) {
				$scope.ctrl.selectedDates.beginDate = $scope.firstDayOfPreviousMonth;
				$scope.ctrl.selectedDates.endDate = $scope.lastDayOfPreviousMonth;
			} else if (selected == 1) {
				$scope.ctrl.selectedDates.beginDate = $scope.firstDayOfCurrentMonth;
				$scope.ctrl.selectedDates.endDate = $scope.ctrl.dontLimitToday ? $scope.lastDayOfCurrentMonth : $scope.today;
			} else if (selected == 2) {
				$scope.ctrl.selectedDates.beginDate = $scope.firstDayOfYear;
				$scope.ctrl.selectedDates.endDate = $scope.ctrl.dontLimitToday ? $scope.lastDayOfYear : $scope.today;
			} else if (selected == 3) {
				$scope.ctrl.selectedDates.beginDate = $scope.firstDayOfLastYear;
				$scope.ctrl.selectedDates.endDate = $scope.lastDayOfLastYear;
			} else if (selected == 4) {
				$scope.ctrl.selectedDates.beginDate = $scope.firstDayOfLast12Months;
				$scope.ctrl.selectedDates.endDate = $scope.lastDayOfPreviousMonth;
			}
		};

		function setDefaultParams() {
			if ($scope.ctrl.selectedDates.dateId == 0 || $scope.ctrl.selectedDates.dateId) {
				$scope.onDateFilterSelectChange($scope.ctrl.selectedDates.dateId);
			} else {
				$scope.ctrl.selectedDates.dateId = 1;
				$scope.onDateFilterSelectChange(1);
			}
		}

		$scope.$watch("ctrl.clear", function (newVal, oldVal) {
			if (newVal != oldVal && newVal) {
				setDefaultParams();
			}
		});

		this.$onInit = function () {
			if (!$scope.ctrl.selectedDates) {
				$scope.ctrl.selectedDates = {};
			}
			setDefaultParams();
		};
	}
]);
