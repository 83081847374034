Vgr.appSyncUtil = {
	graphql: {
		subscriptions: `subscription Subscribe2channel($name: String!) {
			subscribe2channel(name: $name) {
			  name
			  data
			}
		  }`,
		mutations: ` mutation Publish2channel($name: String!, $data: AWSJSON!) {
			publish2channel(name: $name, data: $data) {
			  name
			  data
			}
		  }`,
		queries: `query GetChannel {
			getChannel {
			  name
			  data
			}
		  }`
	},
	channels: {
		user: "user_[USER_CODE]",
		organization: "organization_[ORGANIZATION_CODE]"
	},
	userChannel: {
		action: {
			userChanged: "userChanged",
			permissionChanged: "permissionChanged"
		}
	},
	organizationChannel: {
		action: {
			featureToggleChanged: "featureToggleChanged"
		}
	},

	subscribeToChannelToListen: function subscribeToChannelToListen(getChannelName, callbackSuccess, callbackError) {
		return window.Amplify.API.graphql(
			window.Amplify.graphqlOperation(Vgr.appSyncUtil.graphql.subscriptions, {
				name: getChannelName()
			})
		).subscribe({
			next: (payload) => callbackSuccess(payload),
			error: (error) => {
				callbackError ? callbackError(error) : console.log(error);
			}
		});
	}
};
