angular.module("vgresiduos").component("goalReportChart", {
	controller: "GoalReportChartCtrl",
	templateUrl: "views/report/residue/base/goalReportChart/goalReportChart_template.html",
	bindings: {
		chartTitle: "@",
		yAxisDecimals: "<?",
		subtitles: "<?",
		headers: "<",
		data: "<",
		goalData: "<?",
		trendingData: "<?"
	}
});
