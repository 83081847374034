angular.module("vgresiduos").controller("PasswordTextFieldCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.getLabel = function () {
			if ($scope.ctrl.isMandatory && JSON.parse($scope.ctrl.isMandatory)) {
				return $scope.labels.PASSWORD + "*";
			}
			return $scope.labels.PASSWORD;
		};
	}
]);
