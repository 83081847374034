angular.module("vgresiduos").controller("organizationUnitSimpleGridCtrl", [
	"$scope",
	"httpService",
	"$rootScope",
	"dialogService",
	"clientService",
	function ($scope, httpService, $rootScope, dialogService, clientService) {
		"use strict";

		$scope.enabledUnitsRadioOptionsSelected = {
			radio: {}
		};

		$scope.isLoadingUnits = false;
		$scope.hasOrganizationUnitsDisabled = false;

		let allOrganizationUnits = [];

		$scope.organizationUnitInfoGrid = {};
		$scope.organizationUnitInfoGrid.defaultActions = [];
		$scope.organizationUnitInfoGrid.columns = [
			{
				description: $rootScope.labels.LINKED_UNITS,
				property: "DescriptionFormated"
			}
		];

		function listOrganizationUnits(disabledOrganizationUnits) {
			$scope.isLoadingUnits = true;
			const dto = {
				active: true,
				systemPermissionId: Vgr.systemPermissions.EditUnit.Id
			};

			httpService.getListFromServiceCore(clientService.listClientsCore, dto).then(function (response) {
				allOrganizationUnits = response.list;
				loadOrganizationUnitList(disabledOrganizationUnits);
			});
		}

		$scope.enabledUnitsRadioOptions = [
			{
				id: 1,
				name: $scope.labels.ALL_UNITS_ENABLED,
				enabledPopUp: false,
				onclick: function () {
					showDialogAssociate();
					$scope.ctrl.organizationUnitRadioSelectedId = this.id;
				},
				description: $scope.labels.ALL_UNITS_ENABLED_SUBTITLE
			},
			{
				id: 2,
				name: $scope.labels.SELECTED_UNITS_ENABLED,
				enabledPopUp: true,
				onclick: function () {
					$scope.ctrl.organizationUnitRadioSelectedId = this.id;
				},
				description: $scope.labels.SELECTED_UNITS_ENABLED_SUBTITLE,
				showGrid: true
			}
		];

		function setAllOrganizationUnits() {
			$scope.organizationUnitInfoGrid.list = allOrganizationUnits;
			$scope.ctrl.list = [];
		}

		function loadOrganizationUnitList(disabledOrganizationUnits) {
			$scope.organizationUnitInfoGrid.list = allOrganizationUnits.filter(
				(ou) => !disabledOrganizationUnits.find((dou) => ou.id == dou)
			);
			$scope.organizationUnitInfoGrid.list.map((ou) => {
				formatDescription(ou);
			});
			$scope.ctrl.list = allOrganizationUnits.filter((ou) => disabledOrganizationUnits.find((dou) => ou.id == dou));
			if ($scope.organizationUnitInfoGrid.list.length == 0) {
				setAllOrganizationUnits();
			}
			$scope.isLoadingUnits = false;
		}

		function formatDescription(ou) {
			ou.DescriptionFormated = ou.code + " - " + ou.name;
		}

		function showDialogAssociate() {
			const params = {
				popupTitle: null,
				popupText: $rootScope.labels.WANT_ASSOCIATE_PROPERTY_TO_ALL_UNITS,
				popupAuxiliaryText: null,
				popupDescription: null,
				cancelButtonLabel: null,
				confirmButtonLabel: null,
				cancelMethod: null,
				confirmMethod: null
			};

			dialogService
				.showDialogFromTemplateV2(
					"views/components/popups/confirmPopup_template.html",
					"ConfirmPopupCtrl",
					null,
					params
				)
				.then(
					function () {
						$scope.hasOrganizationUnitsDisabled = false;
						setAllOrganizationUnits();
					},
					function () {
						if ($scope.organizationUnitInfoGrid.list.length != 0) {
							setRadioButtonDefaulCheck();
						}
					}
				);
		}

		$scope.openEnabledForClientsPopup = function () {
			const params = {
				enabledOrganizationUnits: $scope.organizationUnitInfoGrid.list,
				allOrganizationUnits: allOrganizationUnits,
				organizationUnitSelectedListCount: $scope.ctrl.organizationUnitSelectedListCount
			};

			dialogService
				.showDialogFromTemplateV2(
					"views/onboarding/additionalProperties/popup/enabledForClientsPopUp_template.html",
					"EnabledForClientsPopUpCtrl",
					null,
					params,
					true
				)
				.then(
					function (data) {
						$scope.hasOrganizationUnitsDisabled = true;
						loadOrganizationUnitList(data.unselectedItens);
						$scope.ctrl.organizationUnitSelectedListCount = data.organizationUnitSelectedListCount;
					},
					function () {
						if ($scope.ctrl.list.length == 0 || $scope.hasOrganizationUnitsDisabled) {
							setRadioButtonDefaulCheck();
							$scope.ctrl.organizationUnitSelectedListCount = 0;
						}
					}
				);
		};

		function setRadioButtonDefaulCheck() {
			if ($scope.hasOrganizationUnitsDisabled) {
				$scope.enabledUnitsRadioOptionsSelected.radio = $scope.enabledUnitsRadioOptions[1];
			} else {
				$scope.enabledUnitsRadioOptionsSelected.radio = $scope.enabledUnitsRadioOptions[0];
			}
		}

		this.$onInit = function () {
			if ($scope.ctrl.list.length != 0) {
				$scope.hasOrganizationUnitsDisabled = true;
				setRadioButtonDefaulCheck();
				listOrganizationUnits($scope.ctrl.list);
			} else {
				setRadioButtonDefaulCheck();
				$scope.organizationUnitInfoGrid.list = [];
				listOrganizationUnits($scope.ctrl.list);
			}
		};
	}
]);
