angular.module("vgresiduos").directive("disposalDriver", function () {
	return {
		scope: true,
		controller: "DisposalDriverCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/destination/steps/disposalDriver/disposalDriver_template.html",
		bindToController: {
			showTitle: "=?",
			mustUpdateSlip: "=?"
		}
	};
});
