angular.module("vgresiduos").controller("CustomReportFilterCtrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;

			$scope.defaultMeasureUnit = Vgr.enumerations.reportFilters.measureUnitEnum.ConvertToDefaultMeasureUnitInGroup;

			$scope.model = {};
		};

		$scope.onSelectInformation = function (reportType) {
			if ($scope.model.information != reportType) {
				$scope.model.information = reportType;
			}

			callback();
		};

		$scope.onDateSelect = function (dateFilter) {
			$scope.model.dateFilter = dateFilter;

			callback();
		};

		$scope.onFilterChange = function (filters) {
			$scope.model.filters = filters;
			callback();
		};

		$scope.onGroupByChange = function (groupBys) {
			$scope.model.groupBys = groupBys;
			callback();
		};

		$scope.onPreferenceChange = function (preferences) {
			$scope.model.preferences = preferences;
			callback();
		};

		function callback() {
			if ($scope.$ctrl.callback) {
				$scope.$ctrl.callback($scope.model);
			}
		}
	}
]);
