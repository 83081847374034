angular.module("vgresiduos").controller("ActionPlanGridCtrl", [
	"$scope",
	"$state",
	"$rootScope",
	"$q",
	"dialogService",
	"actionPlanService",
	"httpService",
	"engagementScoreService",
	function ($scope, $state, $rootScope, $q, dialogService, actionPlanService, httpService, engagementScoreService) {
		"use strict";

		// Local variables
		const statusActionPlan = {
			[Vgr.enumerations.actionPlan.Status.Finalized]: $rootScope.labels.FINALIZED,
			[Vgr.enumerations.actionPlan.Status.Created]: $rootScope.labels.ACTIVE,
			[Vgr.enumerations.actionPlan.Status.Pending]: $rootScope.labels.ACTIVE,
			[Vgr.enumerations.actionPlan.Status.Canceled]: $rootScope.labels.CANCELED
		};

		// Scope variables
		$scope.gridOptions = {};
		$scope.filters = {};
		$scope.auditValid = null;
		$scope.loading = false;
		$scope.hasActiveOrFinalizedActionPlan = false;
		$scope.generalStatus = Vgr.constants.generalStatus;

		// Scope functions
		$scope.reloadActionPlans = loadActionPlans;

		this.$onInit = function () {
			loadGridOptions();
			loadActionPlans();

			$scope.gridOptions.removeNew = true;
		};

		function loadGridOptions() {
			$scope.gridOptions = {
				columnDefinitions: getGridColumns(),
				title: $rootScope.labels.CHECKLIST,
				query: { limit: 100, page: 1 },
				data: [],
				promise: {},
				hasData: false,
				errorLoading: false,
				removeDelete: true,
				removeSearch: true,
				removeNew: true,
				insertButton: { displayName: $rootScope.labels.NEW_ACTION_PLAN },
				deletePopup: {
					title: $rootScope.labels.REMOVE_ACTION_PLAN_POPUP_TITLE,
					text: $rootScope.labels.REMOVE_ACTION_PLAN_POPUP_TEXT,
					descriptionField: "creationDate",
					onClose: loadActionPlans,
					deleteMethod: deleteActionPlan,
					getDto: function (item) {
						return { actionPlanId: item.id, supplierId: $scope.ctrl.supplierId };
					}
				},
				hasPermission: function () {
					return true;
				},
				rowClick: function (item) {
					goToActionPlanEditPage(item.id);
				},
				edit: function (item) {
					goToActionPlanEditPage(item.id);
				},
				create: openPopupNewActionPlan
			};

			$rootScope.contentNavBarGrid = $scope.gridOptions;
		}

		function getGridColumns() {
			return [
				{
					field: "organization.name",
					width: 250,
					displayName: function () {
						return $rootScope.labels.ORGANIZATION;
					}
				},
				{
					field: "creationDate",
					width: 250,
					displayName: function () {
						return $rootScope.labels.CREATED_DATE;
					}
				},
				{
					field: "statusDescription",
					width: 250,
					displayName: function () {
						return $rootScope.labels.STATUS;
					}
				},
				{
					field: "userResponsible.name",
					width: 250,
					displayName: function () {
						return $rootScope.labels.RESPONSIBLE;
					}
				},
				{
					editOptions: getGridActions(),
					width: 100,
					displayName: function () {
						return $rootScope.labels.ACTIONS;
					}
				}
			];
		}

		function getGridActions() {
			return [
				{
					type: "custom",
					icon: "edit",
					label: $rootScope.labels.EDIT,
					disable: function (item) {
						return (
							item.status === Vgr.enumerations.actionPlan.Status.Canceled ||
							item.status === Vgr.enumerations.actionPlan.Status.Finalized
						);
					},
					click: function (item) {
						goToActionPlanEditPage(item.id);
					}
				},
				{
					type: "custom",
					icon: "remove_red_eye",
					disable: function (item) {
						return (
							item.status !== Vgr.enumerations.actionPlan.Status.Canceled &&
							item.status !== Vgr.enumerations.actionPlan.Status.Finalized
						);
					},
					click: function (item) {
						goToActionPlanEditPage(item.id);
					}
				}
			];
		}

		function loadActionPlans() {
			$scope.loading = true;

			const dto = {
				queryParams: {
					status: [Vgr.enumerations.actionPlan.Status.Pending]
				},
				supplierId: $scope.ctrl.supplierId
			};

			const deferred = $q.defer();
			httpService
				.getListFromServiceCore(actionPlanService.listSupplierActionPlans, dto)
				.then(
					function (response) {
						fillActionPlanList(response.list);
						$scope.gridOptions.data = response.list;
						$scope.hasActiveOrFinalizedActionPlan = hasActiveOrFinalizedActionPlan(response.list);
						deferred.resolve();
					},
					function () {
						deferred.reject();
					}
				)
				.finally(function () {
					$scope.loading = false;
				});

			$scope.gridOptions.hasData = false;
			$scope.gridOptions.promise = deferred.promise;

			return deferred.promise;
		}

		function fillActionPlanList(actionPlans) {
			for (const actionPlan of actionPlans) {
				fillDate(actionPlan);
				fillStatusInfo(actionPlan);
			}
		}

		function fillDate(actionPlan) {
			actionPlan.creationDate = Vgr.util.dateToString(new Date(actionPlan.creationDate));
		}

		function fillStatusInfo(actionPlan) {
			actionPlan.statusDescription = statusActionPlan[actionPlan.status];
		}

		function openPopupNewActionPlan() {
			const deferred = $q.defer();

			const params = {
				popupTitle: $rootScope.labels.ACTION_PLAN,
				popupText: $rootScope.labels.DO_YOU_WANT_TO_CREATE_AN_ACTION_PLAN,
				popupAuxiliaryText: null,
				popupDescription: null,
				cancelButtonLabel: null,
				confirmButtonLabel: null,
				confirmMethod: function () {
					if ($scope.auditValid) {
						engagementScoreService.track(Vgr.trackings.supplier.actionPlan.create);
						return createActionPlan($scope.auditValid.Id);
					} else {
						return $q.reject();
					}
				},
				cancelMethod: null
			};

			showDialogConfirm(params).then(
				function (actionPlanId) {
					goToActionPlanEditPage(actionPlanId);
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function showDialogConfirm(params) {
			const deferred = $q.defer();
			dialogService
				.showDialogFromTemplateV2(
					"views/components/popups/confirmPopup_template.html",
					"ConfirmPopupCtrl",
					null,
					params
				)
				.then(
					function (data) {
						deferred.resolve(data);
					},
					function () {
						deferred.reject();
					}
				);
			return deferred.promise;
		}

		function createActionPlan(clientAuditId) {
			const dto = { supplierId: $scope.ctrl.supplierId, clientAuditId };
			const deferred = $q.defer();
			httpService.postDTOToServiceV2(actionPlanService.createActionPlan, dto).then(
				function (actionPlanId) {
					deferred.resolve(actionPlanId);
				},
				function () {
					deferred.reject();
				}
			);
			return deferred.promise;
		}

		function deleteActionPlan(actionPlanId) {
			return actionPlanService.deleteActionPlan(actionPlanId).then(function () {
				$rootScope.$broadcast(Vgr.constants.evtActionPlansChanged);
				return {};
			});
		}

		function goToActionPlanEditPage(actionPlanId) {
			$state.go("actionPlanEdit", {
				supplierId: $scope.ctrl.supplierId,
				actionPlanId,
				supplierCode: $scope.transporterCode
			});
		}

		function hasActiveOrFinalizedActionPlan(actionPlans) {
			const index = actionPlans.findIndex(function (actionPlan) {
				return (
					actionPlan.status === Vgr.enumerations.actionPlan.Status.Created ||
					actionPlan.status === Vgr.enumerations.actionPlan.Status.Pending ||
					actionPlan.status === Vgr.enumerations.actionPlan.Status.Finalized
				);
			});
			return index !== -1;
		}
	}
]);
