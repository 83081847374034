angular.module("vgresiduos").controller("CustomReportFilterPreferenceSectionCtrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;

			if (!$scope.model) {
				$scope.model = {};
			}
		};

		function loadPreferences(information) {
			if (!information || !information.id) {
				$scope.showDisposalPricePreferences = false;
				$scope.showMeasureUnitPreferences = false;
				return;
			}

			let informationId = information.id.toUpperCase();
			$scope.showDisposalPricePreferences = canShowDisposalPricePreferences(informationId);
			$scope.showMeasureUnitPreferences = canShowMeasureUnitPreferences(informationId);

			if (!$scope.showDisposalPricePreferences) {
				$scope.model.disposalPricePreferences = null;
			}
			if (!$scope.showMeasureUnitPreferences) {
				$scope.model.measureUnitPreferences = null;
			}
		}

		function canShowDisposalPricePreferences(informationId) {
			return informationId === Vgr.enumerations.customReport.information.ResidueDisposalPrice;
		}

		function canShowMeasureUnitPreferences(informationId) {
			return (
				informationId === Vgr.enumerations.customReport.information.ResidueGenerationQuantity ||
				informationId === Vgr.enumerations.customReport.information.ResidueDisposalQuantity ||
				informationId === Vgr.enumerations.customReport.information.ProductionQuantity
			);
		}

		$scope.onMeasureUnitChange = function (measureUnitPreferences) {
			if ($scope.showMeasureUnitPreferences) {
				$scope.model.measureUnitPreferences = measureUnitPreferences;
			}

			callback();
		};

		$scope.onDisposalPriceChange = function (disposalPricePreferences) {
			if ($scope.showDisposalPricePreferences) {
				$scope.model.disposalPricePreferences = disposalPricePreferences;
			}

			callback();
		};

		function callback() {
			if ($scope.$ctrl.callback) {
				$scope.$ctrl.callback($scope.model);
			}
		}

		this.$onChanges = function (obj) {
			if (obj.reportInformation) {
				loadPreferences(obj.reportInformation.currentValue);
			}
		};
	}
]);
