angular.module("vgresiduos").controller("conama313TextCompleteCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"residueService",
	"conama313Service",
	function ($scope, $rootScope, $q, httpService, residueService, conama313Service) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.conama313List = [];

		this.$onInit = function () {
			listConama313();
		};

		function listConama313() {
			httpService.getListFromServiceCore(residueService.getConama313List, $scope.$ctrl.conama313Type).then(
				function (resp) {
					resp.list.forEach((element) => {
						element.ID = element.id;
						element.Label = conama313Service.getLabelField(element, $scope.$ctrl.conama313Type);
					});
					$scope.conama313List = resp.list;
				},
				function () {
					//Nothing to be done
				}
			);
		}

		$scope.listConama313 = function () {
			var deferred = $q.defer();
			if (!$scope.paginationOptions.query) {
				$scope.paginationOptions.query = "";
			}
			var filteredList = $scope.conama313List.filter(function (c) {
				return c.Label.toUpperCase().indexOf($scope.paginationOptions.query.toUpperCase()) != -1;
			});
			deferred.resolve(filteredList);
			return deferred.promise;
		};

		$scope.paginationOptions = {
			pageSize: 30,
			pageNumber: 1,
			query: "",
			id: 0
		};
	}
]);
