angular.module("vgresiduos").component("ibamaActivityMultiSelect", {
	controller: "IbamaActivityMultiSelectCtrl",
	templateUrl: "views/components/fields/ibamaActivityMultiSelect/ibamaActivityMultiSelect_template.html",
	bindings: {
		onChange: "<?",
		searchLabel: "@",
		customClasses: "@?",
		initialValues: "<?",
		label: "@"
	}
});
