angular.module("vgresiduos").directive("propertyTypeSelectField", function () {
	return {
		scope: true,
		controller: "propertyTypeSelectFieldCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/onboarding/additionalProperties/fields/propertyTypeSelectField_template.html",
		bindToController: {
			additionalPropertyType: "=",
			additionalPropertyValues: "=?",
			isDisabled: "<?"
		}
	};
});
