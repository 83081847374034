angular.module("vgresiduos").controller("auditLevelsSelectFieldCtrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			$scope.levels = [
				{
					value: Vgr.enumerations.audit.Level.None,
					description: $scope.ctrl.emptyFieldDescription
				},
				{
					value: Vgr.enumerations.audit.Level.Level1,
					description: $scope.labels.AUDIT_LEVEL1_SUBTITLE
				},
				{
					value: Vgr.enumerations.audit.Level.Level2,
					description: $scope.labels.AUDIT_LEVEL2_SUBTITLE
				},
				{
					value: Vgr.enumerations.audit.Level.Level3,
					description: $scope.labels.AUDIT_LEVEL3_SUBTITLE
				}
			];

			if (!$scope.ctrl.defaultOption) {
				$scope.levels.shift();
			}

			if (!$scope.ctrl.level) {
				$scope.ctrl.level = $scope.levels[0].value;
			}
		};

		$scope.$watch("ctrl.level", function (newVal, oldVal) {
			if (newVal === null) {
				$scope.ctrl.level = $scope.levels[0].value;
			}
		});
	}
]);
