angular.module("vgresiduos").controller("SupplierProfileListCtrl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `organizacao/fornecedores/perfis`);
		};
	}
]);
