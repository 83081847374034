angular.module("componentes").component("stepWizard", {
	controller: "StepWizardCtrl",
	templateUrl: "views/components/stepWizard/stepWizard_template.html",
	transclude: true,
	bindings: {
		// label: '@',
		// searchLabel: '@',
		// key: '@',
		// displayProperty: '@',
		steps: "<",
		color: "@",
		onStepChange: "<",
		setterStep: "<"
		// initialValues: '<?',
		// onSelectedValuesChanged: '<',
		// showSelectAll: '<',
		// clear: '<'
	}
});
