angular.module("vgresiduos").controller("DisposalManifestCtrl", [
	"$scope",
	"$q",
	"fileService",
	function ($scope, $q, fileService) {
		"use strict";

		$scope.refreshFilesCount = 0;

		function getFilesModel() {
			const files = [];

			for (const index in $scope.fileOptions.manifest.fileList) {
				files.push({
					Path: $scope.fileOptions.manifest.fileList[index].Path,
					Type: Vgr.enumerations.destination.file.Manifest,
					Name: $scope.fileOptions.manifest.fileList[index].Name
				});
			}

			return files;
		}

		$scope.$watch("fileOptions.manifest.fileList.length", function (newValue, oldValue) {
			if (newValue != oldValue) {
				$scope.destinationModel.ManifestFiles = getFilesModel();
			}
		});

		this.$onInit = function () {
			buildFilesOptions();
		};

		function loadManifestFiles(files) {
			const manifestFiles = [];
			if (files) {
				for (const index in files) {
					if (files[index].Type == Vgr.enumerations.destination.file.Manifest) {
						manifestFiles.push({
							Id: files[index].Id,
							Name: files[index].Name,
							Path: files[index].Path,
							S3Key: files[index].Path
						});
					}
				}
			}

			return manifestFiles;
		}

		function buildFilesOptions() {
			$scope.fileOptions.manifest = {
				fileService: "mixed",
				get: getFilesList,
				update: function (newFiles) {
					const deferrend = $q.defer();
					const path = $scope.getDisposalFilePath();
					fileService.uploadAll(newFiles, path).then(
						function (response) {
							deferrend.resolve(response);
						},
						function () {
							deferrend.reject();
						}
					);
					return deferrend.promise;
				},
				fileList: [],
				refreshFiles: $scope.refreshFilesCount
			};
		}

		$scope.showManifestAlert = function () {
			return (
				hasManifestPendency() &&
				!$scope.fileOptions.manifest.fileList.length &&
				!$scope.destinationModel.PhysicalFilesManifest
			);
		};

		function hasManifestPendency() {
			const foundPendency = $scope.destinationModel.Pendencies.find(
				(p) => p.Type == Vgr.enumerations.destination.pendencyType.MTR
			);
			return foundPendency ? foundPendency.IsChecked : false;
		}

		const onDisposalFilesUpdated = $scope.$on(Vgr.constants.evtDisposalFilesUpdated, function () {
			// there is a Watch on update-file-v2 checking for this property value
			// when updated, the fileList is reloaded
			$scope.fileOptions.manifest.refreshFiles += 1;
		});

		function getFilesList() {
			const deferred = $q.defer();
			deferred.resolve(loadManifestFiles($scope.destinationModel.ManifestFiles));
			return deferred.promise;
		}

		$scope.$on("$destroy", function () {
			onDisposalFilesUpdated();
			// nullify the DOM-bound model
			$scope.domElement = null;
		});
	}
]);
