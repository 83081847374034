angular.module("vgresiduos").factory("translationService", [
	"$resource",
	"localStorageService",
	"$rootScope",
	function ($resource, localStorageService, $rootScope) {
		"use strict";

		const defaultLanguage = "pt-br";
		const localStorageLanguageKey = "language";
		const availableLanguages = ["pt-br", "es"];

		function getConsideredLanguage(language) {
			if (Vgr.util.isNullOrEmpty(language) || availableLanguages.indexOf(language) < 0) {
				return defaultLanguage;
			}
			return language;
		}

		const _applyTranslation = function (language) {
			const languageFilePath = "/js/translation/translation_" + language + ".json";
			$resource(languageFilePath).get(function (data) {
				$rootScope.labels = data;
				if (language != defaultLanguage) {
					completeLabelsWithDefaultLanguage();
				}
			});
		};

		function completeLabelsWithDefaultLanguage() {
			const defaultLanguageFilePath = "/js/translation/translation_" + defaultLanguage + ".json";
			$resource(defaultLanguageFilePath).get(function (data) {
				const defaultLanguageLabels = data;
				for (const k in defaultLanguageLabels) {
					if (!$rootScope.labels[k]) {
						$rootScope.labels[k] = defaultLanguageLabels[k];
					}
				}
			});
		}

		const _setLanguageIframe = function (currentLanguage) {
			const iframe = document.getElementById(Vgr.constants.IframeId);
			if (iframe) {
				iframe.contentWindow.postMessage(
					{
						type: Vgr.enumerations.messageTypes.SET_LANGUAGE,
						payload: { currentLanguage }
					},
					Vgr.constants.newAngularUrl
				);
			}
		};

		const _setLanguage = function (language) {
			const consideredLanguage = getConsideredLanguage(language);
			if (consideredLanguage != _getLanguage()) {
				localStorageService.set(localStorageLanguageKey, consideredLanguage);
				_applyTranslation(consideredLanguage);
				_setLanguageIframe(consideredLanguage);
				return true;
			}
			return false;
		};

		const _getLanguage = function () {
			return getConsideredLanguage(localStorageService.get(localStorageLanguageKey));
		};

		const _getLabel = function (label) {
			return function () {
				return $rootScope.labels[label];
			};
		};

		const _initialize = function () {
			localStorageService.set(localStorageLanguageKey, _getLanguage());
			_applyTranslation(_getLanguage());
		};

		return {
			initialize: _initialize,
			setLanguage: _setLanguage,
			getLanguage: _getLanguage,
			getLabel: _getLabel
		};
	}
]);
