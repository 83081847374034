angular.module("vgresiduos").controller("ClientFormContactCtrl", [
	"$scope",
	"additionalPropertyUtilService",
	function ($scope, additionalPropertyUtilService) {
		"use strict";

		$scope.unitProcessId = Vgr.enumerations.additionalProperty.process.OrganizationUnit;

		this.$onInit = function () {
			if ($scope.ctrl.inputClient.ID) {
				buildInformationsFormated();
			} else {
				$scope.ctrl.inputClient.InformationsFormated = [];
			}
		};

		function buildInformationsFormated() {
			$scope.ctrl.inputClient.InformationsFormated =
				additionalPropertyUtilService.buildInformationsToGridFromApiV2EntityInformation(
					$scope.ctrl.inputClient.Informations
				);
		}
	}
]);
