angular.module("vgresiduos").controller("DisposalTemporaryStoragerStepCtrl", [
	"$rootScope",
	"$scope",
	"$q",
	"httpService",
	"disposalService",
	"$timeout",
	function ($rootScope, $scope, $q, httpService, disposalService, $timeout) {
		"use strict";

		const cnpjMask = "cnpj";
		const cpfMask = "cpf";

		$scope.isSetByName = false;
		$scope.isSetByCnpj = false;

		$scope.supplierType = Vgr.enumerations.supplier.type.TemporaryStorager;

		$scope.disposalValidations.TemporaryStorager = {
			Icon: "warning",
			Status: Vgr.constants.generalStatus.error,
			Title: $rootScope.labels.SUPPLIER_PENDENCIES.TEMPORARY_STORAGER,
			TextButton: $rootScope.labels.ACCESS_SUPPLIER,
			Messages: []
		};

		this.$onInit = function () {
			if ($scope.destinationModel.TemporaryStorager) {
				if (!$scope.destinationModel.TemporaryStorager.CNPJ) {
					$scope.destinationModel.TemporaryStorager.CNPJ = "";
				}

				validateTemporaryStorager();
			}

			setDocumentOptions($scope.destinationModel.TemporaryStorager);
		};

		$scope.onTemporaryStoragerSelect = function (temporaryStorager) {
			setDocumentOptions(temporaryStorager);
			$timeout(function () {
				validateTemporaryStorager();
			});
		};

		function setDocumentOptions(temporaryStorager) {
			if (temporaryStorager && temporaryStorager.CNPJ) {
				if (temporaryStorager.CNPJ.toString().length == 14) {
					$scope.temporaryStoragerDocumentLabel = $scope.labels.CNPJ;
					$scope.temporaryStoragerDocumentMask = cnpjMask;
					return;
				} else if (temporaryStorager.CNPJ.toString().length == 11) {
					$scope.temporaryStoragerDocumentLabel = $scope.labels.CPF;
					$scope.temporaryStoragerDocumentMask = cpfMask;
					return;
				}
			}
			$scope.temporaryStoragerDocumentLabel = $scope.labels.CNPJ;
			$scope.temporaryStoragerDocumentMask = "";
		}

		function getSlipDescription() {
			return $scope.destinationModel.TemporaryStorager ? $scope.destinationModel.TemporaryStorager.Name : null;
		}

		function updateSlipToSolicitation(valid, validationMessage) {
			if (valid) {
				$scope.updateSlip($scope.stepsEnum.C, getSlipDescription());
				$scope.enableStep($scope.stepsEnum.D);
			} else {
				$scope.updateSlip($scope.stepsEnum.C, null);
				$scope.enableBackToStep($scope.stepsEnum.C);
				$scope.stagesConfig[$scope.stagesEnum.Solicitation].Steps[$scope.stepsEnum.C].MiddleActionEnabled = false;
			}
			$scope.stagesConfig[$scope.stagesEnum.Solicitation].Steps[$scope.stepsEnum.C].CustomValidationMessage =
				validationMessage;
		}

		function updateSlipToGathering(valid, validationMessage) {
			if (valid) {
				$scope.updateSlip($scope.stepsEnum.A, getSlipDescription());
				$scope.enableStep($scope.stepsEnum.G);
			} else {
				$scope.updateSlip($scope.stepsEnum.A, null);
				$scope.enableBackToStep($scope.stepsEnum.A);
			}
			$scope.stagesConfig[$scope.stagesEnum.Gathering].Steps[$scope.stepsEnum.A].CustomValidationMessage =
				validationMessage;

			if (valid && !$scope.destinationModel.TemporaryStorager) {
				$scope.stagesConfig[$scope.stagesEnum.Gathering].Steps[$scope.stepsEnum.B].IsDisabled = true;
				$scope.stagesConfig[$scope.stagesEnum.Gathering].Steps[$scope.stepsEnum.C].IsDisabled = true;
			} else {
				$scope.stagesConfig[$scope.stagesEnum.Gathering].Steps[$scope.stepsEnum.B].IsDisabled = false;
				$scope.stagesConfig[$scope.stagesEnum.Gathering].Steps[$scope.stepsEnum.C].IsDisabled = false;
			}
		}

		function calculateAsyncValidation(validations) {
			const temporaryStoragerValidations = validations.filter(
				(v) => hasTemporaryStoragerValidationErrors(v) && v.IsToBlock
			);

			return {
				IsValid: temporaryStoragerValidations.length == 0,
				Message: temporaryStoragerValidations.map((v) => $scope.getCustomValidationMessage(v)).join("\n")
			};
		}

		function hasTemporaryStoragerValidationErrors(validation) {
			return (
				validation.Type == Vgr.enumerations.validation.TemporaryStoragerRequired ||
				validation.Type == Vgr.enumerations.validation.TemporaryStoragerAuditLevelInvalid ||
				validation.Type == Vgr.enumerations.validation.TemporaryStoragerAuditClassificationInvalid ||
				validation.Metadata?.some(
					(d) =>
						d.Key == Vgr.enumerations.destination.validations.suppliers.key &&
						d.Value == Vgr.enumerations.destination.validations.suppliers.atuation.TemporaryStorager
				)
			);
		}

		function validateAsync() {
			const deferrend = $q.defer();

			const dto = $scope.createValidateDto(null, true);
			httpService.getDTOFromServiceV2(disposalService.validateDisposal, dto).then(
				function (response) {
					$scope.updateValidationList(response.Validations);
					setTemporaryStoragerValidationMessages(response.Validations);
					const validationObject = calculateAsyncValidation(response.Validations);
					deferrend.resolve(validationObject);
				},
				function () {
					deferrend.reject();
				}
			);

			return deferrend.promise;
		}

		function setTemporaryStoragerValidationMessages(validations) {
			$scope.setValidationMessages(validations, [], "TemporaryStorager", "TemporaryStorager");
		}

		$scope.showTemporaryStoragerValidationMessages = function () {
			return (
				$scope.destinationModel.TemporaryStorager && $scope.disposalValidations.TemporaryStorager.Messages.length > 0
			);
		};

		function validateTemporaryStorager() {
			if ($scope.isCurrentStageReview()) {
				updateSlipToSolicitation(true, null);
				return;
			}

			if (!$scope.canValidateDisposal()) {
				return;
			}

			validateAsync().then(
				function (validationObject) {
					const valid = validationObject.IsValid;
					const validationMessage = validationObject.Message;
					if ($scope.isSolicitationStage()) {
						updateSlipToSolicitation(valid, validationMessage);
					} else if ($scope.isGatheringStage()) {
						updateSlipToGathering(valid, validationMessage);
					}
				},
				function () {}
			);
		}

		$scope.$watch("destinationModel.GatheringDate", function (newValue, oldValue) {
			if (newValue && newValue != oldValue) {
				$scope.validateDisposal();
			}
		});

		$scope.$on("$destroy", function () {
			// disable the listener

			// nullify the DOM-bound model
			$scope.domElement = null;
		});
	}
]);
