angular.module("vgresiduos").controller("storageCreateCtrl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `geracoes/novo`);
		};
	}
]);
