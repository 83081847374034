Vgr.dtoMappings = {
	measureUnit: {
		fromV2ToCore: function (measureUnitV2) {
			return {
				id: measureUnitV2.Id,
				description: measureUnitV2.Name,
				abbreviation: measureUnitV2.Abbreviation,
				descriptionFormated: measureUnitV2.Name + " (" + measureUnitV2.Abbreviation + ")"
			};
		}
	},
	client: {
		fromV2ToCore: function (clientDtoV2) {
			const dto = {
				id: clientDtoV2.ID,
				code: clientDtoV2.Code,
				name: clientDtoV2.Name,
				organization: {
					contractType: clientDtoV2.Organization.ContractType,
					blockDate: clientDtoV2.Organization.BlockDate,
					endDate: clientDtoV2.Organization.EndDate,
					beginDate: clientDtoV2.Organization.BeginDate,
					id: clientDtoV2.Organization.Id,
					name: clientDtoV2.Organization.Name,
					identityProvider: clientDtoV2.Organization.IdentityProvider,
					emailDifferentFromUsername: clientDtoV2.Organization.EmailDifferentFromUsername,
					emailInviteDisabled: clientDtoV2.Organization.EmailInviteDisabled
				},
				latitude: clientDtoV2.Latitude,
				longitude: clientDtoV2.Longitude,
				registerCompleted: clientDtoV2.RegisterCompleted,
				address: clientDtoV2.Address,
				city: {},
				internalTransferType: clientDtoV2.InternalTransferType,
				priceDecimalPlaces: clientDtoV2.PriceDecimalPlaces,
				decimalPlaces: clientDtoV2.DecimalPlaces,
				isSupplier: clientDtoV2.IsSupplier,
				telephone: clientDtoV2.Telephone,
				cellPhone: clientDtoV2.Cellphone,
				active: clientDtoV2.IsActive,
				isMarketProfile: clientDtoV2.IsMarketProfile
			};

			if (clientDtoV2.City) {
				dto.city = {
					id: clientDtoV2.City.Id,
					description: clientDtoV2.City.Description,
					state: {
						id: clientDtoV2.City.State.Id,
						description: clientDtoV2.City.State.Description,
						countryId: clientDtoV2.City.State.CountryId
					}
				};
			}

			return dto;
		}
	}
};
