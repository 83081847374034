angular.module("vgresiduos").directive("newOptionsButton", function () {
	return {
		scope: true,
		controller: "NewOptionsButtonCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/destinationFollowup/baseComponents/newOptionsButton/newOptionsButton_template.html",
		bindToController: {
			options: "=",
			usesBackdrop: "@?"
		},
		link: function (scope, element, attr) {
			var guid = Vgr.util.uuid();
			angular.element(element).attr("id", "menu-" + guid);
			scope.guid = guid;
		}
	};
});
