angular.module("vgresiduos").directive("recipientForm", function () {
	return {
		scope: true,
		controller: "RecipientFormCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/onboarding/recipients/form/recipientForm_template.html",
		bindToController: {
			model: "=",
			saveOptions: "="
		}
	};
});
