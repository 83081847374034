angular.module("vgresiduos").controller("editDriverCtrl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton(
				$rootScope,
				`organizacao/fornecedores/${$stateParams.supplierCode}/condutor/${$stateParams.driverId}`,
				[{ key: "supplierId", value: $stateParams.supplierId }]
			);
		};
	}
]);
