angular.module("vgresiduos").controller("ClientExternalSystemsPopupDeleteCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"dialogService",
	"externalSystemService",
	"popupTitle",
	"popupText",
	"externalSystemId",
	"organizationUnitId",
	function (
		$scope,
		$rootScope,
		httpService,
		dialogService,
		externalSystemService,
		popupTitle,
		popupText,
		externalSystemId,
		organizationUnitId
	) {
		"use strict";

		const labels = $rootScope.labels;
		const cancelButton = {
			label: labels.CANCEL,
			class: "md-primary",
			click: function () {
				dialogService.cancel();
			},
			disabled: false
		};
		const deleteButton = {
			label: labels.DELETE,
			class: "md-primary md-raised",
			click: function () {
				onDeleteClick();
			},
			disabled: false
		};

		$scope.popupTitle = popupTitle;
		$scope.popupText = popupText;
		$scope.popupActionButtons = [cancelButton, deleteButton];

		function toggleButtonsEnabledState() {
			$scope.popupActionButtons.forEach((button) => (button.disabled = !button.disabled));
		}

		function deleteMethod() {
			const dto = {
				UnitId: organizationUnitId,
				ExternalSystemId: externalSystemId
			};
			return httpService.getDTOFromServiceV2(externalSystemService.deleteClientExternalSystem, dto);
		}

		function onDeleteClick() {
			toggleButtonsEnabledState();
			deleteMethod().then(
				function () {
					dialogService.confirm();
				},
				function () {
					toggleButtonsEnabledState();
				}
			);
		}
	}
]);
