angular.module("vgresiduos").component("iframeAngular", {
	scope: true,
	controller: "iframeAngularController",
	controllerAs: "ctrl",
	templateUrl: "views/components/iframeAngular/iframeAngular_template.html",
	bindings: {
		path: "@",
		queries: "@?",
		withHeader: "="
	}
});
