angular.module("vgresiduos").controller("IbamaIntegrationPopupDeleteCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"dialogService",
	"ibamaExternalClientService",
	"popupTitle",
	"popupText",
	"cnpj",
	function ($scope, $rootScope, httpService, dialogService, ibamaExternalClientService, popupTitle, popupText, cnpj) {
		"use strict";

		const labels = $rootScope.labels;
		const cancelButton = {
			label: labels.CANCEL,
			class: "md-primary",
			click: function () {
				dialogService.cancel();
			},
			disabled: false
		};
		const deleteButton = {
			label: labels.DELETE,
			class: "md-primary md-raised",
			click: function () {
				onDeleteClick();
			},
			disabled: false
		};

		$scope.popupTitle = popupTitle;
		$scope.popupText = popupText;
		$scope.popupActionButtons = [cancelButton, deleteButton];

		function toggleButtonsEnabledState() {
			$scope.popupActionButtons.forEach((button) => (button.disabled = !button.disabled));
		}

		function deleteMethod() {
			return httpService.getDTOFromServiceV2(ibamaExternalClientService.deleteIbamaExternalClient, cnpj);
		}

		function onDeleteClick() {
			toggleButtonsEnabledState();
			deleteMethod().then(
				function () {
					dialogService.confirm();
				},
				function () {
					toggleButtonsEnabledState();
				}
			);
		}
	}
]);
