angular.module("vgresiduos").controller("ResidueClassMultiSelectCtrl", [
	"$scope",
	"$rootScope",
	"residueV2Service",
	"httpService",
	function ($scope, $rootScope, residueV2Service, httpService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			listResidueClassTypes();
		};

		function listResidueClassTypes() {
			httpService.getListFromServiceCore(residueV2Service.listResidueClasses).then(
				function (response) {
					$scope.residueClassList = response.list;
				},
				function () {
					$scope.residueClassList = [];
				}
			);
		}
	}
]);
