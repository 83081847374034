angular.module("vgresiduos").directive("destinationFollowupList", function () {
	return {
		scope: true,
		controller: "DestinationFollowupListCtrl",
		controllerAs: "ctrl",
		templateUrl:
			"views/destinationFollowup/baseComponents/destinationFollowupList/destinationFollowupList_template.html",
		bindToController: {
			status: "@",
			newOptions: "=",
			numberOfDisposals: "=?",
			forcePaginationButton: "@?",
			noHeader: "@?"
		}
	};
});
