angular.module("vgresiduos").controller("DisposalCreateProvisionalMTRPopupCtrl", [
	"$rootScope",
	"$scope",
	"$q",
	"httpService",
	"dialogService",
	"externalSystemService",
	"disposalService",
	"accountService",
	"destinationModel",
	"loadings",
	"updateIntegrationSystemsModels",
	"externalSystemToCreateProvisionalMtrIds",
	function (
		$rootScope,
		$scope,
		$q,
		httpService,
		dialogService,
		externalSystemService,
		disposalService,
		accountService,
		destinationModel,
		loadings,
		updateIntegrationSystemsModels,
		externalSystemToCreateProvisionalMtrIds
	) {
		"use strict";

		$scope.externalSystemIntegrationStatus = {
			pending: 1,
			confirmed: 2,
			canceled: 3
		};

		$scope.labels = $rootScope.labels;
		$scope.destinationModel = destinationModel;
		$scope.loadings = loadings;
		let clientExternalSystems;

		function listClientExternalSystems() {
			const deferred = $q.defer();

			httpService
				.getListFromServiceCore(externalSystemService.listClientExternalSystems, {
					UnitId: accountService.getCurrentClient().id
				})
				.then(
					function (response) {
						clientExternalSystems = response.list;
						deferred.resolve();
					},
					function () {
						deferred.reject();
					}
				);

			return deferred.promise;
		}

		function configureCreateProvisionalMtr() {
			$scope.externalSystemToCreateProvisionalMtr = [];

			externalSystemToCreateProvisionalMtrIds.forEach((id) => {
				const clientExternalSystem = clientExternalSystems.find((ces) => ces.sourceId == id);
				$scope.externalSystemToCreateProvisionalMtr.push({
					id: id,
					message: $scope.labels.UNAVAILABLE_MTR_SYSTEM_MESSAGE.replace(
						"[SYSTEM]",
						clientExternalSystem.source.description
					),
					status: $scope.externalSystemIntegrationStatus.pending
				});
			});
		}

		$scope.close = function () {
			updateManifests(true);
		};

		$scope.onConfirm = function (externalSystemId, closeAfterAction) {
			const confirmedExternalSystem = getExternalSystemById(externalSystemId);
			if (confirmedExternalSystem != null) {
				confirmedExternalSystem.status = $scope.externalSystemIntegrationStatus.confirmed;
			}

			if (!anyExternalSystemIntegrationPending()) {
				updateManifests(closeAfterAction);
			}
		};

		$scope.onCancel = function (externalSystemId, closeAfterAction) {
			const canceledExternalSystem = getExternalSystemById(externalSystemId);
			if (canceledExternalSystem != null) {
				canceledExternalSystem.status = $scope.externalSystemIntegrationStatus.canceled;
			}

			if (closeAfterAction) {
				dialogService.confirm();
			}
		};

		function getExternalSystemById(externalSystemId) {
			return $scope.externalSystemToCreateProvisionalMtr.find((es) => es.id == externalSystemId);
		}

		function anyExternalSystemIntegrationPending() {
			const pendingExternalSystemIntegration = $scope.externalSystemToCreateProvisionalMtr.find(
				(es) => es.status == $scope.externalSystemIntegrationStatus.pending
			);

			return pendingExternalSystemIntegration != null;
		}

		function updateManifests(closePopup) {
			loadings.integration = true;
			httpService.getDTOFromServiceV2(disposalService.getDisposal, destinationModel.Id).then(
				function (data) {
					if (updateIntegrationSystemsModels) {
						destinationModel.Manifests = data.Disposal.Manifests;
						updateIntegrationSystemsModels();
					}
					loadings.integration = false;

					if (closePopup) {
						dialogService.confirm();
					}
				},
				function (data) {
					loadings.integration = false;
					if (closePopup) {
						window.location.reload();
					}
				}
			);
		}

		function initializePopup() {
			listClientExternalSystems().then(configureCreateProvisionalMtr);
		}

		initializePopup();
	}
]);
