Vgr.errorCodes = {
	disposal: {
		InternalError: "ERR-DISINT-0000",
		InvalidMTRProvisoryCode: "ERR-DISINT-0015",
		ProvisionalMTRNotReceived: "ERR-DISINT-0048",
		SystemUnavailable: "ERR-DISINT-0010",
		SystemInstable: "ERR-DISINT-0011",
		SystemInstableOnCreation: "ERR-DISINT-0012",
		InvalidProperty: "ERR-DIS-0055",
		InvalidPropertyValue: "ERR-DIS-0056",
		WrongDestinatorType: "ERR-DISINT-0063",
		WrongTransporterType: "ERR-DISINT-0065",
		TemporaryStoragerRequired: "ERR-DIS-0093",
		WrongGeneratorProfile: "ERR-DISINT-0099"
	},
	residueGenerationModel: {
		ResidueNotFound: "ERR-RESGENMOD-0001",
		AreaNotFound: "ERR-RESGENMOD-0002",
		PropertyNotFound: "ERR-RESGENMOD-0003"
	}
};
