angular.module("vgresiduos").factory("conama313Service", [
	"$http",
	"engagementScoreService",
	function ($http, engagementScoreService) {
		"use strict";

		const _getYearReportFilterList = function GetYearReportFilterList() {
			const currentYear = new Date().getFullYear();
			return [{ year: currentYear }, { year: currentYear - 1 }, { year: currentYear - 2 }, { year: currentYear - 3 }];
		};

		const _generateInventoryReport = function GetInventoryReport(filter) {
			const req = {
				method: "post",
				data: {
					format: filter.format,
					year: filter.year
				},
				url: Vgr.constants.newHostUrl + Vgr.resources.api.report + "GenerateCONAMA313Report"
			};
			engagementScoreService.track(Vgr.trackings.declarations.conama313GenerateReport);
			return $http(req);
		};

		const _getLabelField = function (element, conama313Type) {
			if (element && conama313Type == Vgr.enumerations.conama313.type.DangerousAndNotDangerousResidues) {
				return element.code + " - " + element.description;
			} else if (element && conama313Type == Vgr.enumerations.conama313.type.Recipient) {
				return element.code + " - " + element.code2 + " - " + element.description;
			}
			return null;
		};

		return {
			getYearReportFilterList: _getYearReportFilterList,
			generateInventoryReport: _generateInventoryReport,
			getLabelField: _getLabelField
		};
	}
]);
