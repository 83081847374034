angular.module("vgresiduos").directive("destinationFollowupDetails", function () {
	return {
		scope: true,
		controller: "DestinationFollowupDetailsCtrl",
		controllerAs: "ctrl",
		templateUrl:
			"views/destinationFollowup/baseComponents/destinationFollowupDetails/destinationFollowupDetails_template.html",
		bindToController: {
			model: "="
		}
	};
});
