angular.module("vgresiduos").controller("ResidueReportCsvExportCtrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.getObjectToCsv = function () {
			let data = [];
			for (const key in $scope.$ctrl.data) {
				let dataQualifier = $scope.$ctrl.data[key];
				for (const row of dataQualifier) {
					let rowData = [];
					if (row.Groups && row.Groups.length > 0) {
						rowData = rowData.concat(row.Groups);
					}
					if (row.DataQualifiers && row.DataQualifiers.length > 0 && !$scope.$parent.concatenateFirstDataQualifier) {
						rowData = rowData.concat(row.DataQualifiers);
					}
					if (row.Intervals && row.Intervals.length > 0) {
						rowData = rowData.concat(row.Intervals);
					}
					if (row.Total != null) {
						rowData = rowData.concat(row.Total);
					}

					data.push(rowData);
				}
			}
			return data;
		};

		$scope.getFileName = function () {
			return (
				$scope.$ctrl.filenamePreffix +
				"_" +
				Vgr.date.fromDateToStringWithFormat(new Date(), Vgr.date.utcDateHourFileFormat) +
				".csv"
			);
		};
	}
]);
