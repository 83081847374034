Vgr.date = {
	dateFormat: "DD/MM/YYYY",
	longDateFormat: "DD/MM/YYYY HH:mm:ss",
	utcDateFormat: "YYYY-MM-DD",
	monthYearFormat: "YYYY-MM",
	utcDateHourFormat: "YYYY-MM-DD HH:mm:ss",
	utcDateHourFileFormat: "YYYYMMDDHHmmss",
	fromISOToMoment: function (isoStr) {
		"use strict";

		return moment(isoStr);
	},
	fromISOToString: function (isoStr, format) {
		"use strict";

		return Vgr.date.fromISOToMoment(isoStr).format(format ? format : this.dateFormat);
	},
	fromISOToLongString: function (isoStr) {
		"use strict";

		return Vgr.date.fromISOToMoment(isoStr).format(this.longDateFormat);
	},
	fromISOStringsToLStrings: function (obj) {
		"use strict";

		for (var field in obj) {
			if (Vgr.util.isValidDate(obj[field])) {
				obj[field + "ISO"] = obj[field];
				obj[field] = Vgr.date.fromISOToString(obj[field]);
			}
		}
	},
	fromISOStringsToMoment: function (obj) {
		"use strict";

		for (var field in obj) {
			if (Vgr.util.isValidDate(obj[field])) {
				obj[field] = Vgr.date.fromISOToMoment(obj[field]);
			}
		}
	},
	fromISOStringsToDate: function (data) {
		"use strict";

		var array = data;
		if (!Array.isArray(data)) {
			array = [];
			array.push(data);
		}
		for (var i = 0; i < array.length; ++i) {
			var obj = array[i];
			for (var field in obj) {
				if (Vgr.util.isValidDate(obj[field])) {
					obj[field] = Vgr.date.fromISOToMoment(obj[field]).toDate();
				}
			}
		}
	},
	fromISOStringsFromArrayToLStrings: function (array) {
		"use strict";

		if (array) {
			for (var i = 0; i < array.length; ++i) {
				Vgr.date.fromISOStringsToLStrings(array[i]);
			}
		}
	},

	fromDatesFromArrayToLStrings: function (array) {
		"use strict";

		if (array) {
			for (var i = 0; i < array.length; ++i) {
				for (var prop in array[i]) {
					if (array[i][prop] instanceof Date) array[i][prop] = Vgr.date.fromDateToString(array[i][prop]);
				}
			}
		}
	},

	fromStringToDate: function (dateStr, format) {
		"use strict";

		return moment(dateStr, format ? format : this.dateFormat).toDate();
	},
	fromDateToString: function (date) {
		return moment(date).format(this.dateFormat);
	},
	fromDateToStringWithFormat: function (date, format) {
		return moment(date).format(format);
	},
	fromDateToUTCString: function (date) {
		return date ? moment(date).format(this.utcDateFormat) : null;
	},
	fromDateToMonthYearString: function (date) {
		return date ? moment(date).format(this.monthYearFormat) : null;
	},
	toISOString: function (date) {
		return moment(date).toISOString();
	},
	isSameDay: function (dateA, dateB) {
		return dateA.isSame(dateB, "day");
	},
	isDateValid: function (date) {
		if (Object.prototype.toString.call(date) === "[object Date]") {
			return !isNaN(date.getTime());
		} else {
			return false;
		}
	},
	addHours: function (date, hours) {
		date.setTime(date.getTime() + hours * 60 * 60 * 1000);
		return date;
	}
};
