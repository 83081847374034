angular.module("vgresiduos").directive("clientInformations", function () {
	return {
		scope: true,
		controller: "ClientInformationsCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/additionalProperties/clientInformations_template.html",
		bindToController: {
			informations: "=",
			processId: "@",
			getEditPermission: "=?"
		}
	};
});
