angular.module("vgresiduos").controller("OrganizationDocumentGridCtrl", [
	"$scope",
	"supplierDocumentService",
	"$rootScope",
	"$window",
	"$state",
	function ($scope, supplierDocumentService, $rootScope, $window, $state) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			$scope.columns = [
				{
					field: "documentType.description",
					displayName: function () {
						return $rootScope.labels.TYPE;
					}
				},
				{
					field: "code",
					displayName: function () {
						return $rootScope.labels.CODE;
					}
				},
				{
					field: "description",
					displayName: function () {
						return $rootScope.labels.DESCRIPTION;
					}
				},
				{
					field: "expirationDateFormated",
					displayName: function () {
						return $rootScope.labels.EXPIRATION;
					}
				}
			];

			if (!$scope.ctrl.gridOptions) {
				$scope.ctrl.gridOptions = {};
			}

			$scope.ctrl.gridOptions.columnDefinitions = $scope.columns;
			$scope.ctrl.gridOptions.query = {
				order: "Id",
				limit: 10,
				page: 1
			};
			$scope.ctrl.gridOptions.filterFields = [];
			$scope.ctrl.gridOptions.gridFilter = {
				text: ""
			};
			$scope.ctrl.gridOptions.data = [];
			$scope.ctrl.gridOptions.rowClick = function (document) {
				const url = $state.href("editSupplierDocument", {
					supplierCode: $scope.ctrl.supplierCode,
					docId: document.id
				});
				$window.open(url, "_blank");
			};
			$scope.ctrl.gridOptions.promise = {};
			$scope.ctrl.gridOptions.hasData = false;
			$scope.ctrl.gridOptions.errorLoading = false;

			loadDocuments();
		};

		function loadDocuments() {
			$scope.ctrl.gridOptions.promise = supplierDocumentService
				.listOrganizationDocuments($scope.ctrl.supplierCode, true)
				.then(function (list) {
					list.forEach((doc) => {
						doc.expirationDateFormated = doc.expirationDate ? Vgr.util.dateToString(new Date(doc.expirationDate)) : "-";
					});

					$scope.ctrl.gridOptions.data = list;
					if ($scope.ctrl.selected) {
						$scope.ctrl.gridOptions.selected = $scope.ctrl.gridOptions.data.filter((d) =>
							$scope.ctrl.selected.includes(d.id)
						);
					}
					$scope.ctrl.gridOptions.hasData = true;
				});
		}

		$scope.refresh = function () {
			$scope.ctrl.gridOptions.hasData = false;
			loadDocuments();
		};
	}
]);
