angular.module("vgresiduos").controller("ActionPlanDashboardCtrl", [
	"$rootScope",
	"$scope",
	"httpService",
	"actionPlanService",
	"$q",
	function ($rootScope, $scope, httpService, actionPlanService, $q) {
		"use strict";

		this.$onInit = function () {
			loadPage();
		};

		function loadPage() {
			// Scope variables
			$scope.labels = $rootScope.labels;
			$scope.actionPlanActiveOrFinalized = null;
			$scope.loading = false;
			$scope.cards = getCards();

			if ($scope.ctrl.actionPlan) {
				loadSupplierActionPlanById();
			} else {
				loadActiveOrFinalizedActionPlans();
			}
		}

		$rootScope.$on(Vgr.constants.evtActionPlansChanged, function () {
			loadPage();
		});

		function loadSupplierActionPlanById() {
			$scope.actionPlanActiveOrFinalized = $scope.ctrl.actionPlan;
			configDashboard();
		}

		function getActionPlan(actionPlanId) {
			const dto = {
				supplierId: $scope.ctrl.supplierId,
				actionPlanId: actionPlanId
			};
			const deferred = $q.defer();
			httpService.getDTOFromServiceV2(actionPlanService.getActionPlan, dto).then(
				function (actionPlan) {
					deferred.resolve(actionPlan);
				},
				function () {
					deferred.reject();
				}
			);
			return deferred.promise;
		}

		function loadActiveOrFinalizedActionPlans() {
			$scope.loading = true;

			const dto = {
				queryParams: {
					status: [
						Vgr.enumerations.actionPlan.Status.Created,
						Vgr.enumerations.actionPlan.Status.Pending,
						Vgr.enumerations.actionPlan.Status.Finalized
					]
				},
				supplierId: $scope.ctrl.supplierId
			};

			httpService
				.getListFromServiceCore(actionPlanService.listActionPlans, dto)
				.then(function (response) {
					const actionPlanActiveOrFinalized = response.list[0] || false;
					if (actionPlanActiveOrFinalized) {
						getActionPlan(actionPlanActiveOrFinalized.id).then(function (actionPlan) {
							$scope.actionPlanActiveOrFinalized = actionPlan;
							configDashboard();
						});
					} else {
						$scope.actionPlanActiveOrFinalized = null;
					}
				})
				.finally(function () {
					$scope.loading = false;
				});
		}

		function configDashboard() {
			$scope.actionPlanActiveOrFinalized.creationDate = Vgr.util.dateToString(
				new Date($scope.actionPlanActiveOrFinalized.creationDate)
			);
			$scope.actionPlanActiveOrFinalized.isActive = isActive($scope.actionPlanActiveOrFinalized.status);
			loadCards($scope.actionPlanActiveOrFinalized);
		}

		function isActive(actionPlanStatus) {
			return (
				actionPlanStatus === Vgr.enumerations.actionPlan.Status.Created ||
				actionPlanStatus === Vgr.enumerations.actionPlan.Status.Pending
			);
		}

		function getExpiredItemsLabel(items) {
			return items == 1 ? $rootScope.labels.EXPIRED_ITEM : $rootScope.labels.EXPIRED_ITEMS;
		}

		function getCloseToExpirationItemsLabel(items) {
			return items == 1 ? $rootScope.labels.ITEM_CLOSE_TO_EXPIRE : $rootScope.labels.ITEMS_CLOSE_TO_EXPIRE;
		}

		function loadCards(actionPlan) {
			$scope.cards.responsible.text = actionPlan.userResponsible.name;
			$scope.cards.creationDate.text = actionPlan.creationDate;
			$scope.cards.expireds.title = actionPlan.expiredItems + " " + getExpiredItemsLabel(actionPlan.expiredItems);
			$scope.cards.closeExpireds.title =
				actionPlan.closeToExpireItems + " " + getCloseToExpirationItemsLabel(actionPlan.closeToExpireItems);

			if (!actionPlan.isActive) {
				$scope.cards.status.text = $rootScope.labels.FINALIZED;
			}
		}

		function getCards() {
			return {
				status: {
					icon: "check_circle",
					title: $rootScope.labels.ACTION_PLAN,
					text: "",
					classIcon: "strong-green",
					classText: "strong-green f-s-20 uppercase font-bold"
				},
				closeExpireds: {
					icon: "info",
					title: "",
					class: "c-orange "
				},
				responsible: {
					icon: "account_circle",
					title: $rootScope.labels.RESPONSIBLE,
					classIcon: "c-grey",
					text: ""
				},
				expireds: {
					icon: "cancel",
					class: "c-red",
					text: ""
				},
				creationDate: {
					icon: "event",
					title: $rootScope.labels.CREATED_DATE,
					classIcon: "c-grey",
					text: ""
				}
			};
		}
	}
]);
