angular.module("vgresiduos").factory("dnitIntegrationService", [
	"$http",
	"accountService",
	"engagementScoreService",
	function ($http, accountService, engagementScoreService) {
		"use strict";

		const _generateDnitReport = function (filters) {
			const organizationId = accountService.getCurrentClient().organization.id;

			const req = {
				method: "POST",
				data: filters,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV2.dnitIntegration.replace("[ORGANIZATION_ID]", organizationId)
			};

			engagementScoreService.track(Vgr.trackings.declarations.dnitGenerateReport);

			return $http(req);
		};

		return {
			generateDnitReport: _generateDnitReport
		};
	}
]);
