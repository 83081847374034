angular.module("vgresiduos").controller("DestinationFollowupListCtrl", [
	"$rootScope",
	"$scope",
	"residuesManagementService",
	"httpService",
	"deviceDetector",
	"accountService",
	function ($rootScope, $scope, residuesManagementService, httpService, deviceDetector, accountService) {
		"use strict";

		const paginationOptions = {
			skip: 0,
			take: 10
		};

		$scope.labels = $rootScope.labels;
		$scope.list = [];
		$scope.finishedLoading = false;
		let listId;

		const loggedClient = accountService.getCurrentClient();

		this.$onInit = function () {
			initializeLists();
		};

		function initializeLists() {
			listId = Vgr.util.uuid();
			list(true, listId);
		}

		$scope.isMobile = function () {
			return deviceDetector.isMobile();
		};

		$scope.loadNextPage = function () {
			list(false, listId);
		};

		$scope.getStatusTitle = function () {
			if ($scope.ctrl.status == Vgr.enumerations.destination.status.Requested) {
				return $scope.labels.REQUESTED.toUpperCase();
			} else if ($scope.ctrl.status == Vgr.enumerations.destination.status.Gathered) {
				return $scope.labels.IN_TRANSIT.toUpperCase();
			} else if ($scope.ctrl.status == Vgr.enumerations.destination.status.TemporaryStorageReceived) {
				return $scope.labels.IN_TEMPORARY_STORAGE.toUpperCase();
			} else if ($scope.ctrl.status == Vgr.enumerations.destination.status.Received) {
				return $scope.labels.RECEIVED_BY_DESTINATOR.toUpperCase();
			} else if ($scope.ctrl.status == Vgr.enumerations.destination.status.Pending) {
				return $scope.labels.PENDING_COMPROVATION.toUpperCase();
			}
			return "";
		};

		const onUpdateFollowupList = $rootScope.$on(Vgr.constants.evtUpdateFollowUpList, () => {
			resetComponent();
			initializeLists();
		});

		$scope.$on("$destroy", function () {
			// disable the listener
			onUpdateFollowupList();

			// nullify the DOM-bound model
			$scope.domElement = null;
		});

		function resetComponent() {
			paginationOptions.skip = 0;
			paginationOptions.take = 10;
			$scope.finishedLoading = false;
			$scope.loading = false;
			$scope.list = [];
			$scope.ctrl.numberOfDisposals = {};
		}

		function list(isFirst, id) {
			if ($scope.loading || $scope.finishedLoading) {
				return;
			}

			if (!isFirst) {
				paginationOptions.skip++;
			}

			const params = {
				skip: paginationOptions.skip,
				take: paginationOptions.take,
				status: $scope.ctrl.status,
				organizationUnitCode: loggedClient.code
			};

			$scope.loading = true;
			const listPromise = httpService.getListFromServiceCore(
				residuesManagementService.listDisposalsForFollowup,
				params,
				true
			);
			listPromise.then(
				function (content) {
					onListLoaded(content.list, content.count, id);
				},
				function () {
					$scope.loading = false;
				}
			);
			return listPromise;
		}

		function onListLoaded(disposalsList, count, id) {
			if (id === listId) {
				if (!$scope.ctrl.numberOfDisposals) {
					$scope.ctrl.numberOfDisposals = {};
				}
				$scope.ctrl.numberOfDisposals.value = count == 100 ? "99+" : count;
				$scope.finishedLoading = disposalsList.length == 0;
				$scope.list = $scope.list.concat(disposalsList);
				$scope.loading = false;
			}
		}
	}
]);
