angular.module("vgresiduos").controller("IbamaIntegrationPopupCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"ibamaExternalClientService",
	"ibamaIntegrationInfo",
	"onCancel",
	"onOk",
	"accountService",
	function (
		$scope,
		$rootScope,
		httpService,
		ibamaExternalClientService,
		ibamaIntegrationInfo,
		onCancel,
		onOk,
		accountService
	) {
		"use strict";

		var organizationId = accountService.getCurrentClient().organization.id;

		$scope.constants = Vgr.constants;
		$scope.loading = false;

		$scope.ibamaIntegrationInfo = ibamaIntegrationInfo;
		$scope.labels = $rootScope.labels;

		$scope.model = {};

		$scope.hasChecked = false;

		$scope.isPasswordMandatory = function () {
			return $scope.ibamaIntegrationInfo ? false : true;
		};

		$scope.cancel = function () {
			onCancel();
		};

		$scope.confirm = function () {
			if (!validate()) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.VALIDATION_ERROR_MESSAGE);
				return;
			}

			if ($scope.ibamaIntegrationInfo) {
				update();
			} else {
				create();
			}
		};

		function update() {
			$scope.loading = true;
			var dto = buildDto();
			httpService.postDTOToServiceV2(ibamaExternalClientService.updateIbamaExternalClient, dto).then(
				function (data) {
					$scope.loading = false;
					onOk();
				},
				function () {
					$scope.loading = false;
				}
			);
		}

		function buildDto() {
			return {
				cnpj: $scope.model.CNPJ,
				organizationId: $scope.model.organizationId,
				password: $scope.model.password
			};
		}

		function create() {
			$scope.loading = true;
			var dto = buildDto();
			httpService.postDTOToServiceV2(ibamaExternalClientService.createIbamaExternalClient, dto).then(
				function (data) {
					$scope.loading = false;
					onOk(data);
				},
				function () {
					$scope.loading = false;
				}
			);
		}

		function validate() {
			$scope.hasChecked = true;

			return true;
		}

		function initializePopup() {
			$scope.model.organizationId = organizationId;

			if ($scope.ibamaIntegrationInfo) {
				$scope.isEdition = true;
				$scope.model.CNPJ = $scope.ibamaIntegrationInfo.cnpj;
				$scope.model.password = $scope.ibamaIntegrationInfo.password;
			} else {
				$scope.isEdition = false;
			}
		}

		initializePopup();
	}
]);
