angular.module("vgresiduos").directive("auditDashboard", function () {
	return {
		scope: true,
		controller: "AuditDashboardCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/suppliers/audits/dashboard/auditDashboard_template.html",
		bindToController: {
			clientId: "=?",
			supplierCode: "=?",
			corporateOrigin: "=?",
			audit: "=?"
		}
	};
});
