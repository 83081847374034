angular.module("vgresiduos").component("twoStepsScalingQuantity", {
	controller: "TwoStepsScalingQuantityCtrl",
	templateUrl: "views/components/scalingQuantity/twoStepsScalingQuantity_template.html",
	bindings: {
		tareWeightLabel: "@",
		scaleAuxiliar1WeightLabel: "@",
		scaleAuxiliar2WeightLabel: "@",
		weightLabel: "@",
		weightButtonLabel: "@",
		tareWeightLimit: "<?",
		scaleAuxiliar1WeightLimit: "<?",
		scaleAuxiliar2WeightLimit: "<?",
		weightLimit: "<?",
		decimals: "<",
		onTotalWeightChanged: "<",
		onTareWeightChanged: "<",
		onWeightChanged: "<",
		onScaleAuxiliar1WeightChanged: "<",
		onScaleAuxiliar2WeightChanged: "<",
		setterTare: "<",
		setterWeight: "<",
		setterTotalWeight: "<",
		setterScaleAuxiliar1Weight: "<",
		setterScaleAuxiliar2Weight: "<",
		onTareWeightButtonClick: "<",
		onGrossWeightButtonClick: "<",
		callTareMethod: "<",
		onScalingTypeChange: "<",
		unitScalingConfigurations: "<"
	}
});
