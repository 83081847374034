angular.module("vgresiduos").controller("MasterUserEditCtrl", [
	"$rootScope",
	"$scope",
	"httpService",
	"masterService",
	"dialogService",
	"permissionService",
	"accountService",
	"$state",
	function ($rootScope, $scope, httpService, masterService, dialogService, permissionService, accountService, $state) {
		"use strict";

		$scope.labels = $rootScope.labels;

		const editClientDataPermission = permissionService.hasPermissionV2(
			Vgr.systemPermissions.MasterCreateEditClientData
		);
		const viewClientDataPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.MasterViewClientData);
		$scope.editClientDataPermission = editClientDataPermission;

		this.$onInit = function () {
			if (!viewClientDataPermission) {
				accountService.goHome();
				return;
			}
			getUser($state.params.id);
		};

		$scope.saveOptions = {
			hasPermission: function () {
				return editClientDataPermission;
			},
			title: $rootScope.labels.USER_QUERY,
			returnState: "masterUserList",
			valid: true,
			save: function () {
				return updateUser();
			}
		};

		$rootScope.contentNavBar = $scope.saveOptions;

		const _forceLogoutButton = {
			title: $scope.labels.userForm.FORCE_LOGOUT,
			usedIcon: "exit_to_app",
			onClick: function () {
				return openConfirmationPopup(
					$scope.labels.FORCE_LOGOUT,
					$scope.labels.FORCE_LOGOUT_USER_MESSAGE,
					[$scope.user],
					masterService.forceLogoutUser
				);
			}
		};

		const _enableDisableButton = {
			isSvg: true,
			usedIcon: "img/icons/toggle_on.svg",
			onClick: function () {
				return openConfirmationPopup(
					$scope.user.active ? $scope.labels.DISABLE_USER : $scope.labels.ENABLE_USER,
					$scope.user.active ? $scope.labels.DISABLE_USER_MESSAGE : $scope.labels.ENABLE_USER_MESSAGE,
					[$scope.user],
					$scope.user.active ? masterService.disableUser : masterService.enableUser
				);
			}
		};

		const _changePasswordButton = {
			title: $scope.labels.NEW_PASSWORD_USER,
			isSvg: true,
			usedIcon: "img/icons/vpn_key.svg",
			onClick: function () {
				const password = Vgr.util.generatePassword();
				return openConfirmationPopup(
					$scope.labels.NEW_PASSWORD_USER_MESSAGE,
					$scope.labels.PASSWORD + ": " + password,
					[$scope.user],
					masterService.newPasswordUser,
					function () {
						return {
							id: $scope.user.code,
							password: {
								password
							}
						};
					}
				);
			}
		};

		function activateButtons(hasSso, hasForceLogout) {
			if (!editClientDataPermission) {
				return;
			}

			_enableDisableButton.title = $scope.user.active ? $scope.labels.DISABLE_USER : $scope.labels.ENABLE_USER;
			const _menuItems = [_enableDisableButton];
			if (!hasSso) {
				_menuItems.push(_changePasswordButton);
			}
			if (!hasForceLogout) {
				_menuItems.unshift(_forceLogoutButton);
			}

			$rootScope.contentNavBarGrid = {
				valid: true,
				removeSearch: true,
				removeDelete: true,
				removeNew: true,
				showSettingsButtonBeforeSave: true,
				settingsButton: {
					iconName: "expand_more",
					iconClass: "material-icons",
					buttonLabel: $scope.labels.ACTIONS,
					class: "md-primary md-raised",
					menuItems: _menuItems
				}
			};
		}

		function getUser(userId) {
			httpService.getDTOFromServiceV2(masterService.getUser, userId).then(
				function (user) {
					buildModel(user);
					activateButtons($scope.user.ssoId, $scope.user.forceLogout);
				},
				function (error) {
					if (error) {
						$state.go("masterUserList");
					}
				}
			);
		}

		function buildModel(user) {
			$scope.user = user;
			$scope.user.nameEmail = user.name + " - " + user.email;
			$scope.user.hasSso = $scope.user.ssoId ? true : false;
			getActiveStatus();
		}

		function getActiveStatus() {
			if (!$scope.user.termsAccepted && $scope.user.active) {
				$scope.user.activeStatus = $scope.labels.PENDING_CONFIRMATION;
			} else if ($scope.user.termsAccepted && $scope.user.active) {
				$scope.user.activeStatus = $scope.labels.ACTIVE;
			} else {
				$scope.user.activeStatus = $scope.labels.INACTIVE;
			}
		}

		function updateUser() {
			if ($scope.user.ssoId && !$scope.user.hasSso) {
				return openConfirmationPopup(
					$scope.labels.CONFIRM_UPDATE_SSO,
					$scope.labels.CONFIRM_UPDATE_SSO_MESSAGE,
					[$scope.user],
					masterService.updateUser,
					function () {
						return getUpdateDto();
					}
				);
			} else {
				return updateUserWithoutSso();
			}
		}

		function updateUserWithoutSso() {
			const promise = httpService.postDTOToServiceV2(masterService.updateUser, getUpdateDto());
			promise.then(function () {
				getUser($state.params.id);
				$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $rootScope.labels.SAVE_SUCCESS_MESSAGE);
			});
			return promise;
		}

		function getUpdateDto() {
			return {
				id: $scope.user.code,
				ssoId: $scope.user.ssoId,
				name: $scope.user.name,
				telephone: $scope.user.telephone,
				cellphone: $scope.user.cellphone,
				jobPosition: $scope.user.jobPosition,
				registrationCode: $scope.user.registrationCode,
				email: $scope.user.email
			};
		}

		function openConfirmationPopup(title, text, list, method, getDto) {
			const params = {
				localVariables: {
					popupTitle: title,
					popupText: text,
					list: list,
					operationMethod: method,
					getDto: getDto,
					idField: "code",
					descriptionField: "nameEmail",
					confirmLabel: null,
					keepPopupOpenOnFinish: false
				}
			};

			const promise = dialogService.showDialogFromTemplateV2(
				"views/components/popups/operationConfirmationPopup_template.html",
				"OperationConfirmationPopupCtrl",
				null,
				params,
				true
			);
			promise.then(function () {
				getUser($state.params.id);
				$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $rootScope.labels.SAVE_SUCCESS_MESSAGE);
			});
			return promise;
		}
	}
]);
