angular.module("componentes").directive("validateQuantityConversion", [
	"$q",
	"$injector",
	function ($q, $injector) {
		return {
			require: "ngModel",
			scope: {
				validateQuantityConversionOriginalMeasureUnit: "=",
				validateQuantityConversionEnabled: "=",
				validateQuantityConversionMeasureUnit: "=",
				validateQuantityConversionResidueCode: "=",
				validateQuantityConversionReferenceDate: "=",
				validateQuantityConversionMin: "=",
				validateQuantityConversionMax: "="
			},
			link: function (scope, elm, attrs, ctrl) {
				var calculusService = $injector.get("calculusService");

				function getLimit(response) {
					if (response && response.data && !response.data.ImpossibleToConvert) {
						return response.data.Quantity;
					} else {
						scope.impossibleToConvert = true;
						return null;
					}
				}

				function updateMinMaxQuantity(newValue, oldValue) {
					var promises = [];
					if (scope.validateQuantityConversionMin !== null) {
						promises.push(
							calculusService.calculateResidueQuantityConverted(
								scope.validateQuantityConversionMin,
								scope.previouslyConvertedMeasureUnit || oldValue,
								newValue,
								scope.validateQuantityConversionResidueCode,
								scope.validateQuantityConversionReferenceDate
							)
						);
					}
					if (scope.validateQuantityConversionMax !== null) {
						promises.push(
							calculusService.calculateResidueQuantityConverted(
								scope.validateQuantityConversionMax,
								scope.previouslyConvertedMeasureUnit || oldValue,
								newValue,
								scope.validateQuantityConversionResidueCode,
								scope.validateQuantityConversionReferenceDate
							)
						);
					}
					if (promises.length == 0) {
						return;
					}

					$q.all(promises).then(function (responses) {
						scope.impossibleToConvert = false;

						var previousMin = scope.validateQuantityConversionMin;
						var previousMax = scope.validateQuantityConversionMax;
						if (scope.validateQuantityConversionMin !== null) {
							scope.validateQuantityConversionMin = getLimit(responses[0]);
							if (scope.validateQuantityConversionMax !== null) {
								scope.validateQuantityConversionMax = getLimit(responses[1]);
							}
						} else if (scope.validateQuantityConversionMax !== null) {
							scope.validateQuantityConversionMax = getLimit(responses[0]);
						}

						if (!scope.impossibleToConvert) {
							scope.previouslyConvertedMeasureUnit = newValue;
						}

						if (
							(previousMin != scope.validateQuantityConversionMin ||
								previousMax != scope.validateQuantityConversionMax) &&
							!ctrl.$dirty
						) {
							ctrl.$setDirty();
						}
						ctrl.$validate();
					});
				}

				scope.$watch("validateQuantityConversionResidueCode", function (newValue, oldValue) {
					if (newValue && newValue != oldValue) {
						scope.previouslyConvertedMeasureUnit = null;
					}
				});

				scope.$watch("validateQuantityConversionMeasureUnit", function (newValue, oldValue) {
					if (
						newValue &&
						(newValue.Id != oldValue.Id || !scope.previouslyConvertedMeasureUnit) &&
						scope.validateQuantityConversionEnabled
					) {
						updateMinMaxQuantity(newValue, oldValue);
					}
				});

				scope.$watch("validateQuantityConversionOriginalMeasureUnit", function (newValue) {
					if (
						newValue &&
						newValue.Id != scope.validateQuantityConversionMeasureUnit.Id &&
						!scope.previouslyConvertedMeasureUnit &&
						scope.validateQuantityConversionEnabled
					) {
						updateMinMaxQuantity(scope.validateQuantityConversionMeasureUnit, newValue);
					}
				});

				ctrl.$validators.quantityLimitMin = function (modelValue, viewValue) {
					if (
						ctrl.$isEmpty(modelValue) ||
						!ctrl.$dirty ||
						!scope.validateQuantityConversionMin ||
						!scope.validateQuantityConversionEnabled
					) {
						// consider empty model valid
						return true;
					}

					return modelValue >= scope.validateQuantityConversionMin;
				};

				ctrl.$validators.quantityLimitMax = function (modelValue, viewValue) {
					if (
						ctrl.$isEmpty(modelValue) ||
						!ctrl.$dirty ||
						!scope.validateQuantityConversionMax ||
						!scope.validateQuantityConversionEnabled
					) {
						// consider empty model valid
						return true;
					}

					return modelValue <= scope.validateQuantityConversionMax;
				};
			}
		};
	}
]);
