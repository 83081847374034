angular.module("vgresiduos").controller("StateMultiSelectCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"clientService",
	function ($scope, $rootScope, httpService, clientService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			listStates();
		};

		function listStates() {
			httpService.getListFromServiceCore(clientService.listClientsCore).then(function (response) {
				let stateList = response.list.map((unit) => {
					return unit.city.state;
				});

				let uniqueStateList = Vgr.util.removeDuplicate(stateList, JSON.stringify);

				uniqueStateList.forEach((element) => {
					element.id = element.id.toString();
					element.displayText = Vgr.util.getStateDescription(element);
				});

				Vgr.util.sortListByField(uniqueStateList, "displayText");

				$scope.states = uniqueStateList;
			});
		}

		$scope.onChanged = function (selectedValues) {
			callback(selectedValues);
		};

		function callback(selectedValues) {
			if ($scope.$ctrl.callback) {
				$scope.$ctrl.callback(selectedValues);
			}
		}
	}
]);
