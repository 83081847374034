angular.module("vgresiduos").component("iframeAngularSingleton", {
	scope: true,
	controller: "iframeAngularSingletonController",
	controllerAs: "ctrl",
	templateUrl: "views/components/iframeAngularSingleton/iframeAngularSingleton_template.html",
	bindings: {
		path: "@",
		queries: "@?",
		withHeader: "="
	}
});
