angular.module("vgresiduos").controller("supplierTextCompleteCtrl", [
	"$rootScope",
	"$scope",
	"httpService",
	"supplierService",
	function ($rootScope, $scope, httpService, supplierService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			$scope.subfieldList = [
				{
					item: "Id",
					label: $scope.labels.ID
				},
				{
					item: "CNPJ",
					label: $scope.labels.CNPJ
				},
				{
					item: "CityStateDescription",
					label: $scope.labels.CITY
				}
			];

			$scope.paginationOptions = {
				pageSize: 40,
				pageNumber: 1,
				query: ""
			};

			$scope.listSuppliers = function () {
				const params = {
					skip: $scope.paginationOptions.pageNumber - 1,
					take: $scope.paginationOptions.pageSize,
					query: $scope.paginationOptions.query
				};

				if ($scope.ctrl.allowedSupplierType) {
					if ($scope.ctrl.allowedSupplierType == Vgr.enumerations.supplier.Transporter) {
						params.onlyTransporters = true;
					} else if ($scope.ctrl.allowedSupplierType == Vgr.enumerations.supplier.type.TemporaryStorager) {
						params.onlyTemporaryStoragers = true;
					}
				}

				const promise = httpService.getListFromServiceV2(supplierService.listSuppliers, params, "Suppliers");
				promise.then(function (list) {
					formatTemporaryStoragerCityAndCnpj(list);
				});
				return promise;
			};

			$scope.getCustomClasses = function () {
				return $scope.ctrl.customClasses;
			};

			$scope.getLabel = function () {
				return $scope.ctrl.customLabel ? $scope.ctrl.customLabel : $scope.labels.SUPPLIER;
			};
		};

		function formatTemporaryStoragerCityAndCnpj(list) {
			for (var i in list) {
				if (!list[i].CNPJ) {
					list[i].CNPJ = "";
				}

				if (list[i].City) {
					list[i].CityStateDescription = list[i].City.Description + " - " + list[i].City.State.Abbreviation;
				} else {
					list[i].CityStateDescription = $scope.labels.CITY_NOT_REGISTERED;
				}
			}
		}
	}
]);
