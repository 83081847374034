angular.module("vgresiduos").controller("residueOrganizationSimpleGridCtrl", [
	"$scope",
	"httpService",
	"$rootScope",
	"dialogService",
	"residueV2Service",
	function ($scope, httpService, $rootScope, dialogService, residueV2Service) {
		"use strict";

		$scope.residueOrganizationInfoGrid = {};
		$scope.residueOrganizationInfoGrid.defaultActions = [];
		$scope.residueOrganizationInfoGrid.columns = [
			{
				description: $rootScope.labels.LINKED_RESIDUES,
				property: "DescriptionFormated"
			}
		];

		$scope.isLoadingResidues = false;

		var allResiduesOrganization = [];

		function listOrganizationResidues() {
			$scope.isLoadingResidues = true;
			httpService.getDTOFromServiceV2(residueV2Service.listOrganizationResidues).then(function (data) {
				allResiduesOrganization = data;
				loadResidueOrganizationInfoGridList();
			});
		}

		function loadResidueOrganizationInfoGridList() {
			var filteredList = allResiduesOrganization.filter((ro) => $scope.ctrl.list.find((lro) => ro.id == lro));
			$scope.formatedList = [];

			filteredList.forEach((ro) => {
				ro.DescriptionFormated = formatDescription(ro);
				$scope.formatedList.push(ro);
			});
			$scope.isLoadingResidues = false;
		}

		function formatDescription(ro) {
			return ro.code + " - " + ro.name;
		}

		$scope.openResidueOrganizationUnitPopup = function () {
			var params = {
				linkedResiduesOrganization: $scope.ctrl.list,
				allResiduesOrganization: allResiduesOrganization
			};

			dialogService
				.showDialogFromTemplateV2(
					"views/onboarding/additionalProperties/popup/residueOrganizationPopUp_template.html",
					"ResidueOrganizationPopUpCtrl",
					null,
					params,
					true
				)
				.then(
					function (data) {
						$scope.ctrl.list = data;
						loadResidueOrganizationInfoGridList();
					},
					function () {}
				);
		};

		function setSwichDefaultOption() {
			$scope.ctrl.residuesSwitch = $scope.ctrl.list.length != 0;
		}

		$scope.onSwitch = function () {
			if (!$scope.ctrl.residuesSwitch && $scope.ctrl.list.length) {
				showDialogConfirm();
			}
		};

		function showDialogConfirm() {
			var params = {
				popupTitle: null,
				popupText: $rootScope.labels.WANT_REMOVE_RESIDUE_ORGANIZATION_LINKED,
				popupAuxiliaryText: null,
				popupDescription: null,
				cancelButtonLabel: null,
				confirmButtonLabel: null,
				cancelMethod: null,
				confirmMethod: null
			};

			dialogService
				.showDialogFromTemplateV2(
					"views/components/popups/confirmPopup_template.html",
					"ConfirmPopupCtrl",
					null,
					params
				)
				.then(
					function () {
						$scope.ctrl.list = [];
					},
					function () {
						setSwichDefaultOption();
					}
				);
		}

		this.$onInit = function () {
			setSwichDefaultOption();
			listOrganizationResidues();
		};
	}
]);
