angular.module("vgresiduos").controller("featureToggleTypeSelectFieldCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"featureToggleService",
	function ($scope, $rootScope) {
		"use strict";

		$scope.labels = $rootScope.labels;

		const featureToggleTypeEnum = ["Release", "Operations", "Permission", "Experiment"];

		this.$onInit = function () {
			listType();
		};

		function listType() {
			$scope.featureToggleType = featureToggleTypeEnum.map(function (type) {
				return {
					Id: type,
					Description: $scope.labels.FEATURETOGGLE_TYPE_ENUM[type]
				};
			});
			setInitialValue();
		}

		function setInitialValue() {
			if (!$scope.$ctrl.setterId) {
				return;
			}

			var foundFeatureToggleType = $scope.featureToggleType.find(
				(featureToggleType) => featureToggleType.Id == $scope.$ctrl.setterId
			);
			if (foundFeatureToggleType) {
				$scope.$ctrl.model = foundFeatureToggleType;
			}
		}
	}
]);
