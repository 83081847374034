angular.module("vgresiduos").controller("IndicatorSelectCtrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";

		this.$onInit = function () {
			$scope.label = "";
			loadOptions();
			setIndicator();
		};

		function loadOptions() {
			let baseList = listIndicators();
			if (!$scope.$ctrl.isRelative) {
				baseList = baseList.filter((item) => !item.onlyRelative);
			}
			if (!$scope.$ctrl.excluded) {
				$scope.list = baseList;
			} else {
				$scope.$ctrl.model = null;
				$scope.list = baseList.filter((item) => item.id != $scope.$ctrl.excluded);
			}
		}

		function setIndicator() {
			if (!$scope.$ctrl.setterId) {
				return;
			}

			const foundIndicator = $scope.list.find((es) => es.Id == $scope.$ctrl.setterId);
			if (foundIndicator != null) {
				$scope.$ctrl.model = foundIndicator;
			}
		}

		$scope.onChange = function () {
			if ($scope.$ctrl.onChange) {
				$scope.$ctrl.onChange($scope.$ctrl.model);
			}
		};

		this.$onChanges = function (obj) {
			if (obj.excluded && obj.excluded.currentValue != obj.excluded.previousValue) {
				loadOptions();
			}
		};

		function listIndicators() {
			return [
				{
					id: Vgr.enumerations.kpiDataType.GenerationQuantity,
					description: $rootScope.labels.KPIS[Vgr.enumerations.kpiDataType.GenerationQuantity]
				},
				{
					id: Vgr.enumerations.kpiDataType.DisposalQuantity,
					description: $rootScope.labels.KPIS[Vgr.enumerations.kpiDataType.DisposalQuantity]
				},
				{
					id: Vgr.enumerations.kpiDataType.DisposalCost,
					description: $rootScope.labels.KPIS[Vgr.enumerations.kpiDataType.DisposalCost]
				},
				{
					id: Vgr.enumerations.kpiDataType.ProductionQuantity,
					description: $rootScope.labels.KPIS[Vgr.enumerations.kpiDataType.ProductionQuantity]
				},
				{
					id: Vgr.enumerations.kpiDataType.BuiltArea,
					description: $rootScope.labels.KPIS[Vgr.enumerations.kpiDataType.BuiltArea],
					onlyRelative: true
				}
			];
		}
	}
]);
