angular.module("vgresiduos").controller("supplierLinkUnitCtrl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `organizacao/fornecedor/${$stateParams.id}/vincular-unidade`);
		};
	}
]);
