angular.module("vgresiduos").directive("relativeKpisCustomizedFilter", function () {
	return {
		scope: true,
		controller: "RelativeKpisCustomizedFilterCtrl",
		controllerAs: "ctrl",
		templateUrl:
			"views/report/relativeKpis/baseComponents/relativeKpisCustomizedFilter/relativeKpisCustomizedFilter_template.html",
		bindToController: {
			indicator: "=",
			onFilter: "=",
			onCleanFilters: "=",
			hasActiveFilters: "=",
			label: "@",
			excluded: "=?",
			placeholder: "@?",
			closeFilters: "=?",
			isRelative: "=?"
		}
	};
});
