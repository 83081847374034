angular.module("vgresiduos").controller("DisposalResiduePopupCtrl", [
	"$rootScope",
	"$scope",
	"dialogService",
	"permissionService",
	"residueToPopup",
	"readOnlyModelResidues",
	"disposalResidues",
	"disposalId",
	"disposalModel",
	"isQuantityMandatory",
	"referenceDate",
	"editPermission",
	"stockControlPreferences",
	"disableEdition",
	"isIntegratedAndReceive",
	"disposalStatus",
	function (
		$rootScope,
		$scope,
		dialogService,
		permissionService,
		residueToPopup,
		readOnlyModelResidues,
		disposalResidues,
		disposalId,
		disposalModel,
		isQuantityMandatory,
		referenceDate,
		editPermission,
		stockControlPreferences,
		disableEdition,
		isIntegratedAndReceive,
		disposalStatus
	) {
		"use strict";

		$scope.selectRecipientPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.UseNotDefaultRecipient);
		$scope.selectDisposalTypePermission = permissionService.hasPermissionV2(
			Vgr.systemPermissions.UseNotDefaultDisposalType
		);

		$scope.labels = $rootScope.labels;

		$scope.model = {};

		function setDisposalResidueModel() {
			$scope.disposalResidueModel = {
				Id: residueToPopup.Id,
				GridKey: residueToPopup.GridKey,
				Storages: residueToPopup.Storages,
				CadriDocumentId: residueToPopup.CadriDocumentId,
				IsUsingPercentualEfficiencySaved: false,
				IsToLoadAreaFields: false,
				TimerForCalculateVolumeEfficiency: null,
				AreaCode: residueToPopup.AreaCode,
				ReferenceResidueId: null,
				QuantityFields: [],
				Quantities: [],
				InternalGatheringRecipientQuantities: [],
				HasManualUserAssociation: residueToPopup.HasManualUserAssociation,
				StockAdjustmentType:
					residueToPopup.StockAdjustmentType || getDefaultStockAdjustmentType(residueToPopup.HasManualUserAssociation)
			};
		}

		function setScopeProperties() {
			$scope.disableEdition = disableEdition;
			$scope.editPermission = editPermission;
			$scope.isQuantityMandatory = isQuantityMandatory;
			$scope.disposalId = disposalId;
			$scope.referenceDate = Vgr.date.toISOString(referenceDate);
			$scope.disableResidueEdition = !!residueToPopup.ResidueId;
			$scope.disposalModel = disposalModel;
			$scope.residueToPopup = residueToPopup;
			$scope.isAlreadyCreatedResidue = residueToPopup.ResidueId
				? readOnlyModelResidues?.some((r) => r.ResidueId == residueToPopup.ResidueId)
				: false;
		}

		function setStockFormConfig() {
			$scope.stockFormConfig = {
				adjustmentTypeOptions: []
			};
			if ($scope.isToAdjustStock()) {
				if ($scope.showStockAdjustmentOptions()) {
					$scope.stockFormConfig.adjustmentTypeOptions = getAdjustmentTypeOptions();
				}
			}
		}

		function getDefaultStockAdjustmentType(hasManualUserAssociation) {
			if ($scope.shouldDecreasedStockQuantity(residueToPopup.ResidueId) || hasManualUserAssociation) {
				return Vgr.enumerations.stockControl.adjustmentTypes.DecreaseResidueGeneration;
			} else {
				return Vgr.enumerations.stockControl.adjustmentTypes.MaintainResidueGeneration;
			}
		}

		$scope.isToAdjustStock = function () {
			return $scope.disableResidueEdition && stockControlPreferences.stockAdjustmentTypes && disposalStatus > 0;
		};

		$scope.showStockAdjustmentOptions = function () {
			return stockControlPreferences.stockAdjustmentTypes.length > 1;
		};

		$scope.shouldDecreasedStockQuantity = function (residueId) {
			if (isCurrentResiduePreferenceRecipientType(residueId) !== $scope.isRecipientStockControlType(residueId)) {
				return true;
			}

			return (
				Vgr.enumerations.stockControl.adjustmentTypes[stockControlPreferences.stockAdjustmentTypes[0]] ===
				Vgr.enumerations.stockControl.adjustmentTypes.DecreaseResidueGeneration
			);
		};

		$scope.hasAnyAdjustedQuantity = function (quantities) {
			return !!quantities.some((qtd) => qtd.AdjustedQuantity > 0);
		};

		function getAdjustmentTypeOptions() {
			return [
				{
					title: $scope.labels.MAINTAIN_STOCK,
					value: Vgr.enumerations.stockControl.adjustmentTypes.MaintainResidueGeneration
				},
				{
					title: $scope.labels.DECREASE_FROM_STOCK,
					value: Vgr.enumerations.stockControl.adjustmentTypes.DecreaseResidueGeneration
				}
			];
		}

		$scope.cancel = function () {
			dialogService.cancel();
		};

		$scope.confirm = function () {
			if (!validate($scope.disposalResidueModel)) {
				return;
			}

			groupDuplicatedQuantityAreaMeasureUnit($scope.disposalResidueModel);

			dialogService.confirm(angular.copy($scope.disposalResidueModel));
		};

		function validate(disposalResidueModel) {
			if (!disposalResidueModel.Residue) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.ERROR_SELECT_RESIDUE);
				return false;
			}
			if (!disposalResidueModel.Recipient) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.SELECT_RECIPIENT);
				return false;
			}
			if (!(disposalResidueModel.DisposalType && disposalResidueModel.DisposalType.Id)) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.SELECT_TECH);
				return false;
			}

			if (isInvalidQuantity(disposalResidueModel)) {
				return false;
			}

			if (!disposalResidueModel.RecipientQuantity && $scope.showVolumeRecipientQuantityField()) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.INSERT_RECIPIENT_QUANTITY);
				return false;
			}

			if (!disposalResidueModel.Area) {
				if ($scope.isAreaRequired(disposalResidueModel)) {
					$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.INSERT_AREA_FOR_DISPOSAL);
					return false;
				}
			}

			if (!$scope.controlStock(disposalResidueModel.Residue.ResidueId)) {
				if (disposalResidueModel.Quantities.some((qtd) => qtd.Quantity && !qtd.SelectedArea)) {
					$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.INSERT_AREA_FOR_DISPOSAL);
					return false;
				}
			}

			if (!$scope.disableResidueEdition) {
				const foundResidue = disposalResidues.find((residue) => residue.ResidueId == disposalResidueModel.Residue.Id);
				if (foundResidue) {
					$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.ERROR_SELECT_RESIDUE_ALREADY);
					return false;
				}
			}

			if (
				!$scope.disableResidueEdition &&
				disposalResidues.length > 0 &&
				$scope.disposalModel == Vgr.enumerations.destination.model.TemporaryStorage
			) {
				$rootScope.$broadcast(
					Vgr.constants.evtShowErrorMessage,
					$scope.labels.ERROR_SELECT_MORE_RESIDUES_TEMPORARY_STORAGE
				);
				return false;
			}

			if (
				$scope.isToAdjustStock() &&
				$scope.showStockAdjustmentOptions() &&
				$scope.hasAnyAdjustedQuantity(disposalResidueModel.Quantities) &&
				!disposalResidueModel.StockAdjustmentType
			) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.ERROR_SELECT_STOCK_ADJUSTMENT_TYPE);
				return false;
			}

			return true;
		}

		$scope.isAreaRequired = function (disposalResidueModel) {
			if (!disposalResidueModel.Residue || !disposalResidueModel.Quantities) {
				return false;
			}

			if (disableDisposeAboveStock(disposalResidueModel.Residue.ResidueId)) {
				return false;
			}

			if ($scope.controlStock(disposalResidueModel.Residue.ResidueId)) {
				if (disposalResidueModel.HasManualUserAssociation) {
					return false;
				}

				if (isAnyQuantityOverLimit(disposalResidueModel)) {
					disposalResidueModel.IsToLoadAreaFields = true;
					return true;
				}
			}

			return false;
		};

		$scope.controlStock = function (residueId) {
			if ($scope.disposalModel == Vgr.enumerations.destination.model.Simplified) {
				return false;
			}

			if (stockControlPreferences.isControllingAllResiduesStock) {
				return true;
			}

			return stockControlPreferences.residuePreferences?.some((res) => res.residue.id == residueId);
		};

		$scope.isRecipientStockControlType = function (residueId) {
			residueId = residueId ?? $scope.disposalResidueModel?.Residue?.ResidueId;

			if (!$scope.controlStock(residueId)) {
				return false;
			}

			if (residueToPopup?.InternalGatheringRecipientQuantities?.length > 0) {
				return true;
			}

			if ($scope.isAlreadyCreatedResidue) {
				return false;
			}

			if (!stockControlPreferences.enableControlStock || !stockControlPreferences.residuePreferences?.length > 0) {
				return false;
			}

			return isCurrentResiduePreferenceRecipientType(residueId);
		};

		function isCurrentResiduePreferenceRecipientType(residueId) {
			const residuePreference = stockControlPreferences.residuePreferences.find((res) => res.residue.id == residueId);
			return (
				Vgr.enumerations.stockControl.controlType[residuePreference?.stockControlType] ==
				Vgr.enumerations.stockControl.controlType.RecipientQuantity
			);
		}

		$scope.isDisposingAboveStock = function (disposalResidueModel) {
			if (!disposalResidueModel.Residue || !disposalResidueModel.Quantities) {
				return false;
			}

			if (disableDisposeAboveStock(disposalResidueModel.Residue.ResidueId)) {
				if (isAnyQuantityOverLimit(disposalResidueModel)) {
					return true;
				}
			}
			return false;
		};

		$scope.showVolumeRecipientQuantityField = function () {
			if ($scope.isRecipientStockControlType()) {
				return;
			}

			return $scope.disposalResidueModel.hasVolumeRecipient;
		};

		function disableDisposeAboveStock(residueId) {
			return (
				!isIntegratedAndReceive &&
				$scope.controlStock(residueId) &&
				!stockControlPreferences.allowNotIntegratedDestinationDisposeAboveStock
			);
		}

		function isAnyQuantityOverLimit(disposalResidueModel) {
			if ($scope.isRecipientStockControlType()) {
				return disposalResidueModel.InternalGatheringRecipientQuantities.some(
					(quantity) => quantity.QuantityLimit < quantity.RecipientQuantity
				);
			}

			return disposalResidueModel.Quantities.some((quantity) => quantity.QuantityLimit < quantity.Quantity);
		}

		function isInvalidQuantity(disposalResidueModel) {
			if (disposalResidueModel.Quantities.length == 0) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.INSERT_QUANTITY);
				return true;
			}

			if (
				$scope.isRecipientStockControlType() &&
				disposalResidueModel.InternalGatheringRecipientQuantities.every((quantity) => !quantity.RecipientQuantity)
			) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.INSERT_RECIPIENT_QUANTITY);
				return true;
			}

			if (disposalResidueModel.Quantities.every((quantity) => !hasAnyQuantity(quantity))) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.INSERT_QUANTITY);
				return true;
			}

			for (const quantity of disposalResidueModel.Quantities) {
				if (hasAnyQuantity(quantity) && quantity.MeasureUnit) {
					if (quantity.TareQuantity && quantity.GrossQuantity && quantity.TareQuantity > quantity.GrossQuantity) {
						$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.GROSS_QUANTITY_LESSER_TARE_QUANTITY);
						return true;
					}
				}
			}
			if ($scope.isDisposingAboveStock(disposalResidueModel)) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.CANT_DISPOSE_QUANTITY_ABOVE_STOCK);
				return true;
			}

			return false;
		}

		function groupDuplicatedQuantityAreaMeasureUnit(disposalResidueModel) {
			const groupedQuantities = disposalResidueModel.Quantities.reduce((grouped, quantity) => {
				const measureUnitId = quantity.MeasureUnit.Id;
				const areaCode = quantity.SelectedArea
					? quantity.SelectedArea.code
					: quantity.Area
					? quantity.Area.Code
					: quantity.AreaCode;
				grouped[`${measureUnitId}_${areaCode}`] = grouped[`${measureUnitId}_${areaCode}`] || [];
				grouped[`${measureUnitId}_${areaCode}`].push(quantity);
				return grouped;
			}, []);

			disposalResidueModel.Quantities = [];
			for (const groupedIdx in groupedQuantities) {
				const groupedQuantity = groupedQuantities[groupedIdx];

				const tareQuantity = Vgr.util.sumPropArray(groupedQuantity, "TareQuantity");
				const totalQuantity = Vgr.util.sumPropArray(groupedQuantity, "Quantity");
				const scaleAuxiliar1 = Vgr.util.sumPropArray(groupedQuantity, "ScaleAuxiliar1");
				const scaleAuxiliar2 = Vgr.util.sumPropArray(groupedQuantity, "ScaleAuxiliar2");

				const duplicatedResidue = {
					...groupedQuantity[0],
					Quantity: totalQuantity,
					TareQuantity: tareQuantity,
					GrossQuantity: Vgr.util.sumPropArray(groupedQuantity, "GrossQuantity"),
					ScaleQuantity: Vgr.util.sumPropArray(groupedQuantity, "ScaleQuantity"),
					ScaleAuxiliar1: scaleAuxiliar1,
					ScaleAuxiliar2: scaleAuxiliar2
				};
				disposalResidueModel.Quantities.push(duplicatedResidue);
			}
		}

		function hasAnyQuantity(quantity) {
			return quantity.Quantity || quantity.TareQuantity || quantity.ScaleAuxiliar1 || quantity.ScaleAuxiliar2;
		}

		function initializePopup() {
			setScopeProperties();
			setStockFormConfig();
			setDisposalResidueModel();
		}

		initializePopup();
	}
]);
