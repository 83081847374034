angular.module("vgresiduos").controller("PropertiesListCtrl", [
	"$stateParams",
	"$rootScope",
	function ($stateParams, $rootScope) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, "organizacao/informacoes-adicionais");
		};
	}
]);
