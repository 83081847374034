angular.module("vgresiduos").controller("DisposalTechMultiSelectCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"disposalService",
	function ($scope, $rootScope, $q, httpService, disposalService) {
		"use strict";

		$scope.labels = $rootScope.labels;
		var isListLoaded;

		this.$onInit = function () {
			loadDisposalTechnologies().then(setDisposalTech);
		};

		function loadDisposalTechnologies() {
			const deferred = $q.defer();

			if (isListLoaded) {
				deferred.resolve();
				return deferred.promise;
			}

			httpService.getListFromServiceCore(disposalService.listDisposalTypes, null).then(
				function (response) {
					$scope.disposalTechnologies = response.list.map((d) => {
						return {
							Id: d.id,
							Description: d.description,
							Ibama: d.ibama ? { Id: d.ibama.id, Description: d.ibama.description } : null
						};
					});
					isListLoaded = true;
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function setDisposalTech() {
			if (!$scope.$ctrl.setterId) {
				return;
			}

			const foundDisposalTech = $scope.disposalTechnologies.find(
				(disposalTech) => disposalTech.Id == $scope.$ctrl.setterId
			);
			if (foundDisposalTech != null) {
				$scope.$ctrl.model = foundDisposalTech;
			} else {
				getDisposalTech();
			}
		}

		function getDisposalTech() {}

		$scope.$watch("$ctrl.setterId", function (newValue, _oldValue) {
			const modelId = $scope.$ctrl.model ? $scope.$ctrl.model.Id : null;

			if (newValue != modelId) {
				loadDisposalTechnologies().then(setDisposalTech);
			}
		});
	}
]);
