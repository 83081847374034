angular.module("vgresiduos").directive("masterUserGrid", function () {
	return {
		scope: true,
		controller: "MasterUserGridCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/master/users/grid/masterUserGrid_template.html",
		bindToController: {
			gridOptions: "=?"
		}
	};
});
