angular.module("vgresiduos").component("residueReportBarPieChart", {
	controller: "ResidueReportBarPieChartCtrl",
	templateUrl: "views/report/residue/base/residueReportBarPieChart/residueReportBarPieChart_template.html",
	bindings: {
		data: "<",
		headers: "<",
		chartTitle: "@",
		yAxisDecimals: "<?",
		subtitles: "<?",
		isMoney: "<?",
		concatenateFirstDataQualifier: "<?"
	}
});
