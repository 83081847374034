angular.module("vgresiduos").controller("MasterUserOrganizationGridCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.columns = [
			{
				description: $scope.labels.ORGANIZATION_CODE,
				property: "Id",
				width: 250
			},
			{
				description: $scope.labels.ORGANIZATION,
				property: "Name",
				width: 250
			},
			{
				description: $scope.labels.OWNER_USER,
				property: "IsOwner",
				width: 50
			},
			{
				description: $scope.labels.UNIT_CODE,
				property: "Units.UnitCode",
				width: 50
			},
			{
				description: $scope.labels.INTERNAL_UNIT_CODE,
				property: "Units.InternalUnitCode",
				width: 50
			},
			{
				description: $scope.labels.UNIT,
				property: "Units.UnitName",
				width: 550
			}
		];

		$scope.list = [];
		function buildGrid() {
			for (var organization of $scope.$ctrl.user.organizations) {
				if (organization.organizationUnits && !organization.isOwner) {
					for (var unit of organization.organizationUnits) {
						organization.Units = {
							UnitCode: unit.code,
							InternalUnitCode: unit.id,
							UnitName: unit.name
						};
						$scope.list.push({
							Id: organization.id,
							Name: organization.name,
							IsOwner: $scope.labels.NO,
							Units: organization.Units
						});
					}
				} else {
					$scope.list.push({
						Id: organization.id,
						Name: organization.name,
						IsOwner: organization.isOwner ? $scope.labels.YES : $scope.labels.NO,
						Units: {
							UnitCode: organization.isOwner ? $scope.labels.ALL_UNITS : $scope.labels.NO_UNITS
						}
					});
				}
			}
		}

		this.$onInit = function () {
			buildGrid();
		};
	}
]);
