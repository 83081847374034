angular.module("vgresiduos").controller("AddPermissionGroupToUserPopupCtrl", [
	"$scope",
	"$rootScope",
	"dialogService",
	"currentPermissions",
	function ($scope, $rootScope, dialogService, currentPermissions) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.editUnitPermissionsId = Vgr.systemPermissions.EditUnitPermissions.Id;
		$scope.popupTitle = $scope.labels.ADD_PERMISSION_PROFILE;
		$scope.currentPermissions = currentPermissions;
		$scope.permissionTypes = Vgr.enumerations.systemPermissions.typesText;
		$scope.model = {
			permissionType: $scope.permissionTypes.OrganizationUnit,
			addToAllUnits: false
		};
		$scope.popupActionButtons = [
			{
				label: $scope.labels.CANCEL,
				class: "md-primary",
				click: function () {
					dialogService.cancel();
				}
			},
			{
				label: $scope.labels.ADD,
				class: "md-primary md-raised",
				click: function () {
					if (!validate()) {
						$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.VALIDATION_ERROR_MESSAGE);
						return;
					}

					dialogService.confirm({
						organizationUnitCode: $scope.model.client ? $scope.model.client.code : null,
						permissionType: $scope.model.permissionType,
						permissionGroupId: $scope.model.permissionGroup ? $scope.model.permissionGroup.id : null,
						addToMultipleUnits: $scope.model.addToAllUnits,
						permissionGroupName: $scope.model.permissionGroupName ? $scope.model.permissionGroupName.name : null
					});
				}
			}
		];

		$scope.setPermissionType = function (value) {
			$scope.model.permissionType = value;
		};

		function validate() {
			if ($scope.model.permissionType == $scope.permissionTypes.Organization) {
				return $scope.model.permissionGroup !== null;
			} else if (!$scope.model.addToAllUnits) {
				return $scope.model.client && $scope.model.permissionGroup;
			} else {
				return $scope.model.permissionGroupName;
			}
		}

		$scope.onChangeOrganizationUnit = function (newVal) {
			$scope.model.organizationUnitId = newVal ? newVal.id : undefined;
		};

		$scope.onChangeAddToAllUnits = function () {
			$scope.model.organizationUnitId = null;
		};
	}
]);
