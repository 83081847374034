angular.module("vgresiduos").controller("MeasureUnitQuantityCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"calculusService",
	"clientService",
	function ($scope, $rootScope, httpService, calculusService, clientService) {
		"use strict";

		$scope.decimals = clientService.getDecimalPlaces();
		var labels = $rootScope.labels;
		var tonConversionFactor = 1000;

		this.$onInit = function () {
			$scope.quantityLabel = $scope.$ctrl.quantityLabel ? $scope.$ctrl.quantityLabel : labels.QUANTITY;
			$scope.convertedQuantityLabel = labels.MASS_QUANTITY;
			$scope.calculatorEnabled = false;
		};

		$scope.showConversion = function () {
			return (
				$scope.$ctrl.measureUnit &&
				($scope.$ctrl.measureUnit.Group == Vgr.enumerations.measureUnit.Group.Volume ||
					$scope.$ctrl.measureUnit.Group == Vgr.enumerations.measureUnit.Group.Unit)
			);
		};

		$scope.onQuantityChange = function (quantity) {
			calculateConvertedQuantity(quantity, $scope.$ctrl.measureUnit, $scope.$ctrl.conversionFactor);
		};

		$scope.onMeasureUnitChange = function (measureUnit) {
			if (measureUnit.Group == Vgr.enumerations.measureUnit.Group.Volume) {
				$scope.$ctrl.conversionFactor = $scope.$ctrl.setterVolumeConversionFactor;
				$scope.conversionFactorLabel = labels.VOLUME_CONVERSION_FACTOR;
			} else if (measureUnit.Group == Vgr.enumerations.measureUnit.Group.Unit) {
				$scope.$ctrl.conversionFactor = $scope.$ctrl.setterUnitConversionFactor;
				$scope.conversionFactorLabel = labels.UNIT_CONVERSION_FACTOR;
			} else {
				$scope.$ctrl.conversionFactor = 1;
				$scope.conversionFactorLabel = null;
			}

			if ($scope.$ctrl.conversionFactor) {
				calculateConvertedQuantity($scope.$ctrl.quantity, measureUnit, $scope.$ctrl.conversionFactor);
			} else {
				$scope.$ctrl.convertedQuantity = null;
			}

			if ($scope.$ctrl.onMeasureUnitChange) {
				$scope.$ctrl.onMeasureUnitChange(measureUnit);
			}

			if ($scope.$ctrl.onCalculatorButtonClick && $scope.calculatorEnabled) {
				$scope.onToggleCalculator();
			}
		};

		$scope.onConversionFactorChange = function (conversionFactor) {
			calculateConvertedQuantity($scope.$ctrl.quantity, $scope.$ctrl.measureUnit, conversionFactor);
		};

		$scope.onConvertedQuantityChange = function (convertedQuantity) {
			calculateConversionFactor($scope.$ctrl.quantity, $scope.$ctrl.measureUnit, convertedQuantity);
		};

		function calculateConvertedQuantity(quantity, measureUnit, conversionFactor) {
			if (!quantity || !measureUnit || !conversionFactor) {
				$scope.$ctrl.convertedQuantity = null;
				return;
			}

			if (measureUnit.Group == Vgr.enumerations.measureUnit.Group.Mass) {
				$scope.$ctrl.convertedQuantity = (quantity * measureUnit.ConversionFactor) / tonConversionFactor;
				return;
			}

			var dto = getDto(quantity, measureUnit, conversionFactor, null);

			httpService.postDTOToServiceV2(calculusService.calculateQuantityConverted, dto).then(
				function (response) {
					$scope.$ctrl.convertedQuantity = response.Quantity;
				},
				function (response) {}
			);
		}

		function calculateConversionFactor(quantity, measureUnit, tonQuantity) {
			if (!quantity || !measureUnit || !tonQuantity) {
				$scope.$ctrl.conversionFactor = null;
				return;
			}

			var dto = getDto(quantity, measureUnit, null, tonQuantity);

			httpService.postDTOToServiceV2(calculusService.calculateConversionFactor, dto).then(
				function (response) {
					$scope.$ctrl.conversionFactor = response.ConversionFactor;
				},
				function (response) {}
			);
		}

		function getDto(quantity, measureUnit, convertionFactor, tonQuantity) {
			return {
				Quantity: quantity,
				UnitId: measureUnit.Id,
				ConversionFactor: convertionFactor,
				FinalQuantity: tonQuantity,
				FinalUnitId: Vgr.constants.measureUnit.Ton.Id
			};
		}

		$scope.onToggleCalculator = function () {
			$scope.calculatorEnabled = !$scope.calculatorEnabled;
			$scope.$ctrl.onCalculatorButtonClick($scope.calculatorEnabled, $scope.$ctrl.measureUnit);
		};

		$scope.$watch("$ctrl.setterVolumeConversionFactor", function (newValue, oldValue) {
			if (
				newValue != oldValue &&
				$scope.$ctrl.measureUnit &&
				$scope.$ctrl.measureUnit.Group == Vgr.enumerations.measureUnit.Group.Volume
			) {
				$scope.$ctrl.conversionFactor = newValue;
				$scope.onConversionFactorChange($scope.$ctrl.conversionFactor);
			}
		});

		$scope.$watch("$ctrl.setterUnitConversionFactor", function (newValue, oldValue) {
			if (
				newValue != oldValue &&
				$scope.$ctrl.measureUnit &&
				$scope.$ctrl.measureUnit.Group == Vgr.enumerations.measureUnit.Group.Unit
			) {
				$scope.$ctrl.conversionFactor = newValue;
				$scope.onConversionFactorChange($scope.$ctrl.conversionFactor);
			}
		});
	}
]);
