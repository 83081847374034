angular.module("vgresiduos").controller("userGridCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		this.$onInit = function () {
			const filterFields = ["name", "email"];

			Vgr.util.merge(
				$scope.ctrl.gridOptions,
				{
					filterFields: filterFields,
					query: {
						order: "name",
						limit: 10,
						page: 1
					},
					showDeleteOnSelectedItens: true,
					data: [],
					hasData: false,
					errorLoading: false
				},
				true
			);

			if ($scope.ctrl.gridOptions.internalTabBack) {
				$rootScope.contentNavBar.back = $scope.ctrl.gridOptions.internalTabBack;
			}

			list();

			$rootScope.contentNavBarGrid = $scope.ctrl.gridOptions;
		};

		function list() {
			$scope.ctrl.gridOptions.data = [];
			$scope.ctrl.gridOptions.hasData = false;
			if ($scope.ctrl.gridOptions.listCore) {
				$scope.ctrl.gridOptions.promise = $scope.ctrl.gridOptions.listCore().then(
					function (usersList) {
						$scope.ctrl.gridOptions.data = usersList.list;
						$scope.ctrl.gridOptions.hasData = true;
					},
					function () {
						$scope.ctrl.gridOptions.errorLoading = true;
					}
				);
			}
			return $scope.ctrl.gridOptions.promise;
		}
	}
]);
