angular.module("vgresiduos").controller("secondNavBarCtrl", [
	"$scope",
	"accountService",
	"$rootScope",
	function ($scope, accountService, $rootScope) {
		"use strict";

		this.$onInit = function () {
			$scope.ctrl.activeOption = "allUsers";
			resetAll();
		};

		$scope.openUserMenu = function () {
			$rootScope.$broadcast(Vgr.constants.evtOpenUserMenu);
		};

		window.addEventListener("click", function (e) {
			var navBar = document.getElementById("navBar");
			var toggleButton = document.getElementById("toggleButton");
			var search = document.getElementById("companyChangeMenu"); //search menu
			if (navBar && toggleButton) {
				if (navBar.contains(e.target) || toggleButton.contains(e.target) || (search && search.contains(e.target))) {
					//do nothing
				} else {
					$scope.$apply();
				}
			}
		});

		function resetAll() {
			$scope.loggedUser = accountService.getLoggedAccount();
			$scope.loggedClient = accountService.getCurrentClient();
		}

		var onChangeLoggedUserInfo = $rootScope.$on(Vgr.constants.evtChangeLoggedUserInfo, function () {
			resetAll();
		});

		var onChangeLoggedClientInfo = $rootScope.$on(Vgr.constants.evtChangeLoggedClientInfo, function () {
			resetAll();
		});

		var onChangeLoggedClient = $rootScope.$on(Vgr.constants.evtChangeLoggedClient, function () {
			resetAll();
		});

		this.$onDestroy = function () {
			// disable the listener
			onChangeLoggedClient();
			onChangeLoggedClientInfo();
			onChangeLoggedUserInfo();

			// nullify the DOM-bound model
			$scope.domElement = null;
		};
	}
]);
