angular.module("vgresiduos").controller("DisposalCostPopupCtrl", [
	"$rootScope",
	"$scope",
	"$state",
	"httpService",
	"dialogService",
	"disposalCostService",
	"engagementScoreService",
	"permissionService",
	function (
		$rootScope,
		$scope,
		$state,
		httpService,
		dialogService,
		disposalCostService,
		engagementScoreService,
		permissionService
	) {
		"use strict";

		let newCostModel = null;

		$scope.hasPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.EditDisposalCosts);
		$scope.generalStatus = Vgr.constants.generalStatus;
		$scope.showCostModuleAlerts = Vgr.constants.showCostModuleAlerts;

		function initializePopup() {
			$scope.costFormEvents = {};
			$scope.isSaving = false;
			$scope.isCalculating = false;
		}

		initializePopup();

		$scope.confirm = function () {
			$scope.isSaving = true;
			$scope.costFormEvents.forceCalculateCost().then(
				function (cost) {
					newCostModel = cost;
					updateDisposalCosts();
				},
				function () {
					$scope.isSaving = false;
				}
			);
		};

		$scope.calculateDisposalCosts = function () {
			$scope.isCalculating = true;
			$scope.costFormEvents.recalculateCosts().then(
				function (cost) {
					newCostModel = cost;
					$scope.isCalculating = false;
				},
				function () {
					$scope.isCalculating = false;
				}
			);
		};

		function updateDisposalCosts() {
			const destinationDto = disposalCostService.getUpdateCostsDto(newCostModel, $scope.destinationModel);
			httpService.postDTOToServiceV2(disposalCostService.updateDisposalCosts, destinationDto).then(
				function (response) {
					$scope.isSaving = false;
					$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $rootScope.labels.SAVE_SUCCESS_MESSAGE);
					$rootScope.$broadcast(Vgr.constants.evtDisposalCostChanged, response);
					dialogService.hide($scope.dialog);
				},
				function () {
					$scope.isSaving = false;
				}
			);
		}

		$scope.cancel = function () {
			dialogService.hide($scope.dialog);
		};

		$scope.getLabelCancel = function () {
			return $scope.hasPermission ? $rootScope.labels.CANCEL : $rootScope.labels.CLOSE;
		};

		$scope.redirectToSupplierDocuments = function () {
			const url = $state.href("supplierEdit", {
				id: $scope.destinationModel.Destinator.Code,
				tab: 2
			});
			window.open(url, "_blank");
		};
	}
]);
