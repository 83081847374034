angular.module("vgresiduos").directive("basePopup", function () {
	return {
		scope: {},
		replace: false,
		controller: function () {},
		controllerAs: "ctrl",
		templateUrl: "views/components/basePopup/basePopup_template.html",
		transclude: true,
		bindToController: {
			popupTitle: "@",
			actionButtons: "=?",
			loading: "=?"
		}
	};
});
