angular.module("vgresiduos").controller("AuditClassificationSmallCtrl", [
	"$rootScope",
	"$scope",
	"auditService",
	function ($rootScope, $scope, auditService) {
		"use strict";

		$scope.auditPermission = auditService.hasAnyLevelPermission();

		$scope.labels = $rootScope.labels;
		$scope.auditLevelsDescriptions = {};

		this.$onInit = function () {
			if ($scope.ctrl.audit && !$scope.ctrl.audit.LevelDescription) {
				$scope.ctrl.audit.LevelDescription = auditService.getDefaultAuditLevelDescription($scope.ctrl.audit.Level);
			}
		};
	}
]);
