angular.module("vgresiduos").controller("TwoStepsScalingQuantityCtrl", [
	"$scope",
	"$rootScope",
	"scalingIntegrationService",
	"httpService",
	function ($scope, $rootScope, scalingIntegrationService, httpService) {
		"use strict";

		$scope.labels = $rootScope.labels;
		var totalWeight = 0;
		$scope.tareWeight = "";
		$scope.weight = 0;
		$scope.scaleAuxiliar1Weight = 0;
		$scope.scaleAuxiliar2Weight = 0;

		function setScale() {
			$scope.scale = getScaleByType(Vgr.enumerations.scaling.type.TwoSteps);
			if (!$scope.scale) {
				$scope.scale = getScaleByType(Vgr.enumerations.scaling.type.TwoStepsWithStorage);
			}
			if ($scope.scale) {
				$scope.$ctrl.onScalingTypeChange($scope.scale.ScalingType);
			}
		}

		function getScaleByType(type) {
			if (!$scope.$ctrl.unitScalingConfigurations || $scope.$ctrl.unitScalingConfigurations.length == 0) {
				return null;
			}

			var scalesByType = $scope.$ctrl.unitScalingConfigurations.filter(function (scale) {
				return scale.ScalingType == type;
			});
			return scalesByType.length > 0 ? scalesByType[0] : null;
		}

		function getScale() {
			if ($scope.scale) {
				return $scope.scale;
			}
			setScale();
			return $scope.scale;
		}

		$scope.onClickTareWeight = function () {
			$scope.calculatingTareWeight = true;
			httpService.getFromLocalServer(scalingIntegrationService.getWeight, getScale()).then(
				function (weight) {
					$scope.calculatingTareWeight = false;
					$scope.tareWeight = weight;
					$scope.$ctrl.onTareWeightChanged(weight);
					updateTotalWeight();
				},
				function () {
					$scope.calculatingTareWeight = false;
				}
			);
			$scope.$ctrl.onTareWeightButtonClick();
		};

		$scope.isTwoStepsWithStorage = function () {
			return getScale().ScalingType == Vgr.enumerations.scaling.type.TwoStepsWithStorage;
		};

		$scope.isGrossWeightButtonEnabled = function () {
			return $scope.tareWeight;
		};

		$scope.scaleAuxiliar1WeightChangedManually = function (weight) {
			$scope.scaleAuxiliar1Weight = weight;
			$scope.$ctrl.onScaleAuxiliar1WeightChanged(weight);
			updateTotalWeight();
		};

		$scope.scaleAuxiliar2WeightChangedManually = function (weight) {
			$scope.scaleAuxiliar2Weight = weight;
			$scope.$ctrl.onScaleAuxiliar2WeightChanged(weight);
			updateTotalWeight();
		};

		$scope.tareWeightChangedManually = function (tareWeight) {
			if (!tareWeight) {
				$scope.weight = "";
			}
			$scope.tareWeight = tareWeight;
			$scope.$ctrl.onTareWeightChanged(tareWeight);
			updateTotalWeight();
		};

		$scope.weightChangedManually = function (newWeight) {
			$scope.weight = newWeight;
			$scope.$ctrl.onWeightChanged(newWeight);
			updateTotalWeight();
		};

		$scope.onClickWeight = function () {
			$scope.calculatingWeight = true;
			httpService.getFromLocalServer(scalingIntegrationService.getWeight, getScale()).then(
				function (weight) {
					$scope.calculatingWeight = false;
					$scope.weight = weight;
					$scope.$ctrl.onWeightChanged(weight);
					updateTotalWeight();
				},
				function () {
					$scope.calculatingWeight = false;
				}
			);
			$scope.$ctrl.onGrossWeightButtonClick();
		};

		function updateTotalWeight() {
			if ($scope.weight && $scope.tareWeight && $scope.weight < $scope.tareWeight) {
				$scope.showWeightLessThanTareErrorMessage = true;
				return;
			}
			$scope.showWeightLessThanTareErrorMessage = false;
			totalWeight = calculateTotalWeight();
			$scope.$ctrl.onTotalWeightChanged(totalWeight);
		}

		function calculateTotalWeight() {
			if (!$scope.weight) {
				return "";
			}

			if ($scope.isTwoStepsWithStorage()) {
				var totalWeight =
					$scope.weight - ($scope.tareWeight - $scope.scaleAuxiliar1Weight + $scope.scaleAuxiliar2Weight);
				return totalWeight < 0 ? "" : totalWeight;
			}

			return $scope.weight - $scope.tareWeight < 0 ? "" : $scope.weight - $scope.tareWeight;
		}

		this.$onChanges = function (obj) {
			if (obj.setterTare && obj.setterTare.currentValue != obj.setterTare.previousValue) {
				$scope.tareWeight = obj.setterTare.currentValue ? obj.setterTare.currentValue : "";
			}
			if (obj.setterWeight && obj.setterWeight.currentValue != obj.setterWeight.previousValue) {
				$scope.weight = obj.setterWeight.currentValue ? obj.setterWeight.currentValue : "";
			}
			if (obj.setterTotalWeight && obj.setterTotalWeight.currentValue != obj.setterTotalWeight.previousValue) {
				$scope.totalWeight = obj.setterTotalWeight.currentValue ? obj.setterTotalWeight.currentValue : "";
			}
			if (
				obj.setterScaleAuxiliar1Weight &&
				obj.setterScaleAuxiliar1Weight.currentValue != obj.setterScaleAuxiliar1Weight.previousValue
			) {
				$scope.scaleAuxiliar1Weight = obj.setterScaleAuxiliar1Weight.currentValue
					? obj.setterScaleAuxiliar1Weight.currentValue
					: "";
			}
			if (
				obj.setterScaleAuxiliar2Weight &&
				obj.setterScaleAuxiliar2Weight.currentValue != obj.setterScaleAuxiliar2Weight.previousValue
			) {
				$scope.scaleAuxiliar2Weight = obj.setterScaleAuxiliar2Weight.currentValue
					? obj.setterScaleAuxiliar2Weight.currentValue
					: "";
			}
			if (obj.callTareMethod && obj.callTareMethod.currentValue) {
				$scope.onClickTareWeight();
			}
		};
	}
]);
