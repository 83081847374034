angular.module("vgresiduos").controller("SupplierProfileTypeSelectFieldCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"supplierProfileService",
	function ($scope, $rootScope, $q, httpService, supplierProfileService) {
		"use strict";

		$scope.labels = $rootScope.labels;
		let isListLoaded;

		this.$onInit = function () {
			loadProfiles();
		};

		function loadProfiles() {
			const deferred = $q.defer();

			if (isListLoaded) {
				deferred.resolve();
				return deferred.promise;
			}

			httpService.getListFromServiceCore(supplierProfileService.listSupplierProfiles).then(
				function (response) {
					if ($scope.$ctrl.includedIds) {
						$scope.profiles = response.list.filter((p) => $scope.$ctrl.includedIds.includes(p.id));
					}

					$scope.profiles = $scope.profiles.map((p) => {
						return {
							...p,
							descriptionFormatted: `${p.systemSupplierType.description} - ${p.description}`
						};
					});
					isListLoaded = true;
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		$scope.changed = function (selected) {
			if ($scope.ctrl.change) {
				$scope.ctrl.change(selected);
			}
		};
	}
]);
