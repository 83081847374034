angular.module("vgresiduos").controller("DisposalResiduesDocumentsCtrl", [
	"$rootScope",
	"$scope",
	"$q",
	"dialogService",
	"httpService",
	"supplierDocumentService",
	function ($rootScope, $scope, $q, dialogService, httpService, supplierDocumentService) {
		"use strict";

		const labels = $rootScope.labels;

		this.$onInit = function () {
			initializeGridConfiguration();
			updateGridList();
		};

		function initializeGridConfiguration() {
			$scope.gridOptions = {
				loading: true,
				list: [],
				defaultActions: ["edit"],
				actionButtons: [],
				columns: [],
				btnEditClick: (item) => {
					openEditPopup(item);
				},
				hasPermission: (item) => {
					return item.enableEdition;
				}
			};
		}

		function updateGridList() {
			loadData().then((result) => {
				$scope.disposalResiduesDocument = result;

				updateGridColumns();
				buildResidueDocuments();

				emitValidateDisposalEvent();
			});
		}

		function loadData() {
			const deferred = $q.defer();

			const dto = {
				destinatorId: $scope.destinationModel.Destinator.SupplierId,
				residueIds: getGroupedDisposalResidues().map((r) => r.ResidueGuidId)
			};

			httpService
				.getDTOFromServiceV2(supplierDocumentService.calculateDocumentsForDisposalRequest, dto)
				.then((response) => {
					deferred.resolve(response);
				})
				.catch(() => {
					deferred.reject();
				});

			return deferred.promise;
		}

		function updateGridColumns() {
			$scope.gridOptions.columns = [
				{
					description: labels.ID,
					property: "code",
					width: "8%"
				},
				{
					description: labels.RESIDUE_NAME,
					property: "residueName"
				},
				{
					description: labels.DISPOSAL_TECH,
					property: "disposalTypeDescription"
				},
				{
					description: labels.RECIPIENT,
					property: "recipientName"
				},
				{
					description: getCadriDocumentTypeDescription(),
					property: "selectedDocument.code"
				}
			];
		}

		function getCadriDocumentTypeDescription() {
			return $scope.disposalResiduesDocument?.residueDocumentType.description ?? "";
		}

		function buildResidueDocuments() {
			$scope.gridOptions.loading = true;

			const residueDocumentsGrid = [];
			for (const disposalResidue of getGroupedDisposalResidues()) {
				const foundDocumentResidue = $scope.disposalResiduesDocument?.residues.find(
					(r) => r.residueId == disposalResidue.ResidueGuidId
				);

				residueDocumentsGrid.push({
					residueId: disposalResidue.ResidueGuidId,
					code: disposalResidue.Code,
					disposalResidueId: disposalResidue.Id,
					residueName: disposalResidue.ResidueName,
					disposalTypeDescription: disposalResidue.DisposalTypeDescription,
					recipientName: disposalResidue.RecipientFormattedToGrid,
					selectedDocument: getSelectedDocument(disposalResidue, foundDocumentResidue),
					availableDocuments: foundDocumentResidue.availableDocuments,
					documentRequired:
						foundDocumentResidue.documentRequired && $scope.disposalPreferences.isBlockingInvalidDocument,
					enableEdition: canEditRow(foundDocumentResidue)
				});
			}

			$scope.gridOptions.list = residueDocumentsGrid;
			$scope.gridOptions.loading = false;

			updateDisposalResiduesCadriDocument();
		}

		function getGroupedDisposalResidues() {
			const residuesDto = [];

			for (const disposalResidue of $scope.destinationModel.Residues) {
				if (residuesDto.find((residue) => residue.ResidueId == disposalResidue.ResidueId)) {
					continue;
				}

				residuesDto.push(disposalResidue);
			}

			return residuesDto;
		}

		function getSelectedDocument(disposalResidue, foundDocumentResidue) {
			if (disposalResidue.CadriDocumentId) {
				return findCadriDocumentInAvailableDocuments(disposalResidue.CadriDocumentId, foundDocumentResidue);
			}

			return foundDocumentResidue.bestDocument;
		}

		function findCadriDocumentInAvailableDocuments(cadriDocumentId, foundDocumentResidue) {
			return cadriDocumentId ? foundDocumentResidue.availableDocuments?.find((ad) => ad.id == cadriDocumentId) : null;
		}

		function canEditRow(foundDocumentResidue) {
			return foundDocumentResidue.documentRequired || foundDocumentResidue.availableDocuments?.length > 0;
		}

		function updateDisposalResiduesCadriDocument() {
			for (const item of $scope.gridOptions.list) {
				const disposalResidues = $scope.destinationModel.Residues.filter((r) => r.ResidueGuidId == item.residueId);
				disposalResidues.forEach((dr) => (dr.CadriDocumentId = item.selectedDocument?.id));
			}
		}

		function emitValidateDisposalEvent() {
			$scope.$emit(Vgr.constants.evtRefreshDisposalValidation);
		}

		function openEditPopup(item) {
			dialogService
				.showDialogFromTemplateV2(
					"views/destination/steps/disposalResiduesDocuments/selectDisposalResidueDocument/selectDisposalResidueDocument_template.html",
					"SelectDisposalResidueDocumentCtrl",
					null,
					{
						documentRequired: item.documentRequired,
						selectedDocumentId: item.selectedDocument?.id,
						availableDocuments: item.availableDocuments,
						documentType: getCadriDocumentTypeDescription()
					}
				)
				.then(
					function (optionSelected) {
						item.selectedDocument = optionSelected;
						updateDisposalResiduesCadriDocument();

						emitValidateDisposalEvent();
					},
					function () {}
				);
		}

		$scope.showGrid = function () {
			return $scope.gridOptions.list?.length > 0;
		};

		const onDisposalResiduesUpdated = $rootScope.$on(Vgr.constants.evtDisposalResiduesUpdated, () => {
			updateGridList();
		});

		$scope.$on("$destroy", function () {
			// disable the listener
			onDisposalResiduesUpdated();

			// nullify the DOM-bound model
			$scope.domElement = null;
		});
	}
]);
