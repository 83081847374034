angular.module("vgresiduos").directive("disposalResidueFormFields", function () {
	return {
		scope: false,
		controller: "DisposalResidueFormFields",
		controllerAs: "ctrl",
		templateUrl: "views/destination/popups/disposalResiduePopup/form/disposalResidueFormFields_template.html",
		bindToController: {
			disposalResidueModel: "=",
			residueReferenceModel: "<"
		}
	};
});
