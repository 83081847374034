angular.module("vgresiduos").directive("clientsInternalTextComplete", function () {
	return {
		scope: true,
		controller: "ClientsInternalTextCompleteCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/fields/clientsInternalTextComplete/clientsInternalTextComplete_template.html",
		bindToController: {
			client: "=?",
			onlyDestinators: "@?",
			onlyGenerators: "@?",
			label: "@?",
			searchText: "=?"
		}
	};
});
