angular.module("vgresiduos").controller("PermissionGroupSelectFieldCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"permissionGroupService",
	function ($scope, $rootScope, httpService, permissionGroupService) {
		"use strict";

		this.$onInit = function () {
			$scope.label = $rootScope.labels.PROFILE;
			if ($scope.$ctrl.isRequired) {
				$scope.label += "*";
			}
			loadOptions();
		};

		function loadOptions() {
			if ($scope.$ctrl.permissionType == Vgr.enumerations.systemPermissions.typesText.Organization) {
				listOrganizationPermissions();
			} else {
				listUnitPermissions();
			}
		}

		function listOrganizationPermissions() {
			httpService.getDTOFromServiceV2(permissionGroupService.listOrganizationPermissions).then(
				function (list) {
					if ($scope.$ctrl.excluded) {
						list = list.filter((u) => !$scope.$ctrl.excluded.includes(u.id));
					}
					$scope.list = list;
				},
				function (data) {}
			);
		}
		function listUnitPermissions() {
			if (!$scope.$ctrl.unitCode) {
				return;
			}

			httpService.getDTOFromServiceV2(permissionGroupService.listUnitPermissions, $scope.$ctrl.unitCode).then(
				function (list) {
					if ($scope.$ctrl.excluded) {
						list = list.filter((u) => !$scope.$ctrl.excluded.includes(u.id));
					}
					$scope.list = list;
				},
				function (data) {}
			);
		}

		this.$onChanges = function (obj) {
			if (obj.permissionType && obj.permissionType.currentValue != obj.permissionType.previousValue) {
				if (obj.permissionType.currentValue) {
					loadOptions();
				}
			}
			if (obj.unitCode && obj.unitCode.currentValue != obj.unitCode.previousValue) {
				if (obj.unitCode.currentValue) {
					loadOptions();
				}
			}
		};
	}
]);
