angular.module("vgresiduos").factory("disposalIntegrationService", [
	"$http",
	"accountService",
	function ($http, accountService) {
		"use strict";

		function getBaseRequestUrl() {
			const client = accountService.getCurrentClient();
			return (
				Vgr.constants.residuesManagementHostUrl +
				Vgr.resources.residuesManagement.disposalIntegration
					.replace("[ORGANIZATION_ID]", client.organization.id)
					.replace("[ORGANIZATION_UNIT]", client.code)
			);
		}

		const _listMtrSystemsToIntegrate = function (supplierId) {
			const req = {
				method: "get",
				url: `${getBaseRequestUrl()}?supplierId=${supplierId}`
			};

			return $http(req);
		};

		return {
			listMtrSystemsToIntegrate: _listMtrSystemsToIntegrate
		};
	}
]);
