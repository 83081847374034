angular.module("vgresiduos").component("disposalTech", {
	controller: "DisposalTechCtrl",
	templateUrl: "views/components/fields/disposalTech/disposalTech_template.html",
	bindings: {
		emptyField: "<?",
		setterId: "<?",
		model: "=",
		label: "@",
		noLabel: "=?",
		onDisposalTechChanged: "<?",
		isDisabled: "=?"
	}
});
