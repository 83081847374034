angular.module("vgresiduos").controller("ClientUserFieldCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"userService",
	"accountService",
	function ($scope, $rootScope, $q, httpService, userService, accountService) {
		"use strict";

		const labels = $rootScope.labels;
		let isListLoaded;

		this.$onInit = function () {
			$scope.label = $scope.$ctrl.label ? $scope.$ctrl.label : labels.USER;
			$scope.displayField = $scope.$ctrl.displayField ? $scope.$ctrl.displayField : "name";

			loadClientUsers().then(setClientUser);
		};

		function loadClientUsers() {
			const deferred = $q.defer();

			if (isListLoaded) {
				deferred.resolve();
				return deferred.promise;
			}

			const dto = {
				organizationUnitCode: accountService.getCurrentClient().code
			};

			httpService.getDTOFromServiceV2(userService.listUsersV2, dto).then(
				function (list) {
					$scope.clientUsers = list;
					isListLoaded = true;
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function setClientUser() {
			if (!$scope.$ctrl.setterId) {
				return;
			}

			const foundClientUser = $scope.clientUsers.find((user) => user.code == $scope.$ctrl.setterId);
			if (foundClientUser != null) {
				$scope.$ctrl.model = foundClientUser;
			}
		}

		$scope.$watch("$ctrl.setterId", function (newValue, oldValue) {
			const modelId = $scope.$ctrl.model ? $scope.$ctrl.model.code : null;

			if (newValue != modelId) {
				loadClientUsers().then(setClientUser);
			}
		});
	}
]);
