angular.module("vgresiduos").component("customizedFilterValueField", {
	controller: "CustomizedFilterValueFieldCtrl",
	templateUrl:
		"views/components/customizedFilterV2/customizedFilterValueField/customizedFilterValueField_template.html",
	bindings: {
		option: "<",

		setter: "<?",
		callback: "<"
	}
});
