angular.module("vgresiduos").controller("IbamaRappCreateValidateGridCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		var labels = $rootScope.labels;

		var columns = [
			{
				field: "OrganizationUnitCodeAndName",
				displayName: function () {
					return labels.UNIT;
				},
				width: 50,
				notSortable: true
			},
			{
				field: "ResidueCode",
				displayName: function () {
					return labels.CODE;
				},
				width: 50,
				notSortable: true
			},
			{
				field: "ResidueDescription",
				displayName: function () {
					return labels.RESIDUE;
				},
				width: 400,
				notSortable: true
			},
			{
				field: "ErrorFormated",
				displayName: function () {
					return labels.STATUS;
				},
				width: 400,
				classes: "bold uppercase strong-red",
				notSortable: true
			}
		];

		$scope.gridOptions = {
			columnDefinitions: columns,
			filterFields: [],
			data: [],
			query: {
				order: "ResidueCode",
				limit: 10,
				page: 1,
				//todo: achar um modo melhor de forçar o refresh aqui
				serverPagination: true
			},
			onFilter: function (query, column, order, tagList, dateFilter) {
				$scope.gridOptions.hasData = false;
				$scope.ctrl.reloadList().then(function (list) {
					$scope.gridOptions.data = list;
					$scope.gridOptions.hasData = true;
				});
			},
			promise: {},
			hasData: false,
			removeNew: true,
			removeDelete: true,
			errorLoading: false,
			hasLastColumnRefreshButton: true
		};

		this.$onInit = function () {
			$scope.gridOptions.data = $scope.ctrl.list;
			$scope.gridOptions.hasData = true;
		};
	}
]);
