angular.module("vgresiduos").controller("CheckListPopupCtrl", [
	"$scope",
	"$rootScope",
	"dialogService",
	"title",
	"legend",
	"searchLabel",
	"noSelectedMessage",
	"list",
	"filters",
	function ($scope, $rootScope, dialogService, title, legend, searchLabel, noSelectedMessage, list, filters) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.checkList = [];
		$scope.title = title;
		$scope.legend = legend;
		$scope.searchLabel = searchLabel;
		$scope.selectAll = false;
		$scope.search = "";

		$scope.changeOption = function (search) {
			$scope.checkList = [];
			filterCheckList(search.toLowerCase());
		};

		function filterCheckList(search) {
			var filterField = filters;
			if (search == "") {
				$scope.checkList = $scope.checkList.concat(list());
			} else {
				var newList = Vgr.filters.filterBy(list(), search, filterField);
				$scope.checkList = $scope.checkList.concat(newList);
			}
		}

		const cancelButton = {
			label: $scope.labels.CLOSE,
			class: "md-primary",
			click: function () {
				dialogService.cancel();
			},
			disabled: false
		};

		const confirmButton = {
			label: $scope.labels.SAVE,
			class: "md-primary md-raised",
			click: function () {
				if (validate()) {
					dialogService.confirm(getSelecteds());
				}
			},
			disabled: false
		};

		function getSelecteds() {
			return $scope.checkList.filter((cl) => cl.toEnabled);
		}

		function listItems() {
			$scope.checkList = $scope.checkList.concat(list());
			$scope.popupActionButtons.push(confirmButton);
		}

		$scope.selectDeselectAll = function (selectAll) {
			for (let item of $scope.checkList) {
				if (selectAll) {
					item.toEnabled = true;
				} else {
					item.toEnabled = false;
				}
			}
		};

		function validate() {
			if ($scope.checkList.filter((cl) => !cl.toEnabled).length == $scope.checkList.length) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, noSelectedMessage);
				return false;
			}
			return true;
		}

		initializePopUp();

		function initializePopUp() {
			$scope.popupActionButtons = [cancelButton];
			$scope.length = list().length;
			listItems();
		}
	}
]);
