angular.module("vgresiduos").factory("externalSystemService", [
	"$http",
	"userAccessService",
	function ($http, userAccessService) {
		"use strict";

		const ignoreSystemsWithoutEmission = function () {
			const spRegulaIntegration = userAccessService.getAccess(
				null,
				Vgr.constants.featureToggles.permission.spRegulaIntegration
			);
			return spRegulaIntegration == Vgr.enumerations.userAccess.Hidden;
		};

		const _listExternalSystems = function () {
			const req = {
				method: "get",
				url:
					Vgr.constants.newHostUrl +
					Vgr.resources.apiV1.externalSystem +
					`sources?ignoreSystemsWithoutEmission=${ignoreSystemsWithoutEmission()}`
			};
			return $http(req);
		};

		const _listClientExternalSystems = function (dto) {
			let params = "";
			if (dto.IncludeSystemsWithoutEmission) {
				params = `?ignoreSystemsWithoutEmission=${ignoreSystemsWithoutEmission()}`;
			}

			const req = {
				method: "get",
				url:
					Vgr.constants.coreHostUrl +
					Vgr.resources.core.externalSystems.replace("[ORGANIZATION_UNIT]", dto.UnitId) +
					params
			};
			return $http(req);
		};

		const _createClientExternalSystem = function (dto) {
			const req = {
				method: "post",
				data: dto,
				url:
					Vgr.constants.coreHostUrl +
					Vgr.resources.core.externalSystems.replace("[ORGANIZATION_UNIT]", dto.organizationUnitId)
			};
			return $http(req);
		};

		const _updateClientExternalSystem = function (dto) {
			const req = {
				method: "put",
				data: dto,
				url:
					Vgr.constants.coreHostUrl +
					Vgr.resources.core.externalSystems.replace("[ORGANIZATION_UNIT]", dto.organizationUnitId) +
					dto.externalSystemId
			};
			return $http(req);
		};

		const _deleteClientExternalSystem = function (dto) {
			const req = {
				method: "delete",
				url:
					Vgr.constants.coreHostUrl +
					Vgr.resources.core.externalSystems.replace("[ORGANIZATION_UNIT]", dto.UnitId) +
					dto.ExternalSystemId
			};
			return $http(req);
		};

		const _listClientUserExternalSystems = function (dto) {
			const req = {
				method: "get",
				url:
					Vgr.constants.coreHostUrl +
					Vgr.resources.core.externalSystems.replace("[ORGANIZATION_UNIT]", dto.UnitId) +
					dto.externalSystemId +
					"/users"
			};
			return $http(req);
		};

		const _createClientUserExternalSystems = function (dto) {
			const req = {
				method: "post",
				data: dto.model,
				url:
					Vgr.constants.coreHostUrl +
					Vgr.resources.core.externalSystems.replace("[ORGANIZATION_UNIT]", dto.model.UnitId) +
					dto.model.ExternalSystemId +
					"/users"
			};
			return $http(req);
		};

		const _updateClientUserExternalSystems = function (dto) {
			const req = {
				method: "put",
				data: dto.model,
				url:
					Vgr.constants.coreHostUrl +
					Vgr.resources.core.externalSystems.replace("[ORGANIZATION_UNIT]", dto.model.UnitId) +
					dto.model.ExternalSystemId +
					"/users/" +
					dto.model.UserCode
			};
			return $http(req);
		};

		const _deleteClientUserExternalSystems = function (dto) {
			const req = {
				method: "delete",
				url:
					Vgr.constants.coreHostUrl +
					Vgr.resources.core.externalSystems.replace("[ORGANIZATION_UNIT]", dto.UnitId) +
					dto.externalSystemId +
					"/users/" +
					dto.userCode
			};
			return $http(req);
		};

		return {
			listExternalSystems: _listExternalSystems,

			listClientExternalSystems: _listClientExternalSystems,
			createClientExternalSystem: _createClientExternalSystem,
			updateClientExternalSystem: _updateClientExternalSystem,
			deleteClientExternalSystem: _deleteClientExternalSystem,

			listClientUserExternalSystems: _listClientUserExternalSystems,
			createClientUserExternalSystems: _createClientUserExternalSystems,
			updateClientUserExternalSystems: _updateClientUserExternalSystems,
			deleteClientUserExternalSystems: _deleteClientUserExternalSystems
		};
	}
]);
