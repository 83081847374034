angular.module("vgresiduos").factory("organizationHeaderInjector", [
	"$injector",
	function ($injector) {
		"use strict";

		return {
			request: function (config) {
				if (config) {
					const accountService = $injector.get("accountService");
					const currentClient = accountService.getCurrentClient();
					if (currentClient) {
						config.headers.OrganizationUnit = currentClient.id;
						config.headers.Organization = currentClient.organization.id;
					}
				}
				return config;
			}
		};
	}
]);
