angular.module("vgresiduos").controller("EmbedPowerbiCtrl", [
	"$rootScope",
	function ($rootScope) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, "bi");
		};
	}
]);
