angular.module("vgresiduos").controller("clientOrgEditCrtl", [
	"$rootScope",
	"$scope",
	"$state",
	"httpService",
	"$q",
	"organizationService",
	"permissionService",
	"accountService",
	function ($rootScope, $scope, $state, httpService, $q, organizationService, permissionService, accountService) {
		"use strict";

		$scope.editClientDataPermission = permissionService.hasPermissionV2(
			Vgr.systemPermissions.MasterCreateEditClientData
		);
		var viewClientDataPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.MasterViewClientData);

		var currentClient = accountService.getCurrentClient();
		$scope.labels = $rootScope.labels;
		$scope.model = {};

		$rootScope.contentNavBar = {
			title: $scope.labels.ORGANIZATION,
			breadcrumb: currentClient.organization.id,
			save: function () {
				return updateOrganization();
			},
			back: function () {
				goHome();
			},
			valid: true,
			validate: function () {
				return validate();
			}
		};

		function goHome() {
			accountService.goHome();
		}

		this.$onInit = function () {
			if (!$scope.editClientDataPermission) {
				if (!viewClientDataPermission) {
					goHome();
				} else {
					$rootScope.contentNavBar.buttons = [];
				}
			}

			$scope.clientContractTypes = organizationService.listContractTypes();

			getOrganization();
		};

		function getOrganization() {
			$scope.loading = true;
			httpService.getDTOFromServiceV2(organizationService.getOrganization, currentClient.organization.id).then(
				function (data) {
					buildOrganizationModel(data.Organization);
					$scope.loading = false;
				},
				function () {
					goHome();
				}
			);
		}

		function updateOrganization() {
			var deferred = $q.defer();

			var dto = getOrganizationDto();
			organizationService.updateOrganization(dto).then(
				function () {
					$state.go("clientOrgEdit", { reload: true });
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function getOrganizationDto() {
			var dto = {
				id: $scope.model.organizationId,
				model: {
					VertownCode: $scope.model.vertownCode,
					Active: $scope.model.isActive,
					Name: $scope.model.orgName,
					IsDestinator: $scope.model.isDestinator,
					IsGenerator: $scope.model.isGenerator,
					IsManager: $scope.model.isManager,
					IsTransporter: $scope.model.isTransporter,
					ContractType: $scope.model.clientContractTypeId,
					BeginDate: $scope.model.contractBegin,
					EndDate: $scope.model.contractEnd,
					BlockDate: $scope.model.contractBlock,
					IsOnboarding: $scope.model.onboarding
				}
			};

			return dto;
		}

		function buildOrganizationModel(data) {
			$scope.model.organizationId = data.Id;
			$scope.model.vertownCode = data.VertownCode;
			$scope.model.isActive = data.Active;
			$scope.model.orgName = data.Name;
			$scope.model.isDestinator = data.IsDestinator;
			$scope.model.isGenerator = data.IsGenerator;
			$scope.model.isManager = data.IsManager;
			$scope.model.isTransporter = data.IsTransporter;
			$scope.model.clientContractTypeId = data.ContractType;
			$scope.model.onboarding = data.IsOnboarding;
			$scope.model.contractBegin = Vgr.util.getDateFromString(data.BeginDate);
			$scope.model.contractEnd = Vgr.util.getDateFromString(data.EndDate);
			$scope.model.contractBlock = Vgr.util.getDateFromString(data.BlockDate);
		}

		function validate() {
			if (!$scope.model.orgName) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.VALIDATION_ERROR_MESSAGE);
				return false;
			}
			if (!$scope.model.organizationId) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.NO_CLIENT);
				return false;
			}
			if (
				$scope.model.contractEnd < $scope.model.contractBegin &&
				$scope.model.contractEnd &&
				$scope.model.contractBegin
			) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.DATE_ERROR_MESSAGE);
				return false;
			}
			return true;
		}
	}
]);
