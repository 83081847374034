angular.module("vgresiduos").factory("provisionalMtrService", [
	"$http",
	"accountService",
	function ($http, accountService) {
		"use strict";

		const _listProvisionalMtrs = function (dto) {
			const organizationId = accountService.getCurrentClient().organization.id;

			let parameters = "";
			if (dto.params) {
				parameters = $.param(dto.params);
			}

			const req = {
				method: "get",
				url:
					Vgr.constants.integrationsHostUrl +
					Vgr.resources.integrations.provisionalMtr
						.replace("[ORGANIZATION]", organizationId)
						.replace("[ORGANIZATION_UNIT]", dto.UnitId)
						.replace("[EXTERNAL_SYSTEM]", dto.ExternalSystemId) +
					"?" +
					parameters
			};
			return $http(req);
		};

		return {
			listProvisionalMtrs: _listProvisionalMtrs
		};
	}
]);
