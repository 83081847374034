angular.module("vgresiduos").controller("GroupByCtrl", [
	"$rootScope",
	"$scope",
	"$timeout",
	"reportUtilService",
	function ($rootScope, $scope, $timeout, reportUtilService) {
		"use strict";
		$scope.labels = $rootScope.labels;
		$scope.showFilters = true;

		$scope.groupByOptions = [];
		$scope.availableFields = [];

		this.$onInit = function () {
			initAvailableFields();
			if ($scope.$ctrl.initialValues && $scope.$ctrl.initialValues.length > 0) {
				addInitialFields();
			} else {
				addNewOption();
			}
		};

		function initAvailableFields() {
			$scope.availableFields = [];

			if ($scope.$ctrl.list) {
				for (var groupBy of $scope.$ctrl.list) {
					var optionField = getOptionField(groupBy);
					$scope.availableFields.push(optionField);
				}
			}
		}

		function addInitialFields() {
			for (const initialValue of $scope.$ctrl.initialValues) {
				var groupByOption = addNewOption();
				var initialField = getFieldById(initialValue.id);
				if (initialField) {
					groupByOption.field = initialField;
				}
			}
		}

		function getFieldById(id) {
			var fieldsWithId = $scope.availableFields.filter(function (field) {
				return field.id == id;
			});
			return fieldsWithId.length > 0 ? fieldsWithId[0] : null;
		}

		function getOptionField(groupOption) {
			var groupBy = groupOption.id;
			if (groupBy == reportUtilService.filterIds.GenerationProperty) {
				return getMetadataPropertyOption(groupOption, Vgr.enumerations.additionalProperty.process.InternalGathering);
			} else if (groupBy == reportUtilService.filterIds.AreaProperty) {
				return getMetadataPropertyOption(groupOption, Vgr.enumerations.additionalProperty.process.Area);
			} else if (groupBy == reportUtilService.filterIds.OrganizationUnitProperty) {
				return getMetadataPropertyOption(groupOption, Vgr.enumerations.additionalProperty.process.OrganizationUnit);
			} else if (groupBy == reportUtilService.filterIds.DisposalProperty) {
				return getMetadataPropertyOption(groupOption, Vgr.enumerations.additionalProperty.process.Disposal);
			} else {
				return {
					type: Vgr.enumerations.customizedFilter.type.GroupingOption,
					label: groupOption.label,
					id: groupOption.id,
					category: groupOption.category,
					isEnabled: true,
					isOptionEnabled: function () {
						return this.isEnabled;
					}
				};
			}
		}

		function getMetadataPropertyOption(groupOption, process) {
			return {
				type: Vgr.enumerations.customizedFilter.type.MetadataGrouping,
				label: groupOption.label,
				displayProperty: $scope.labels.PROPERTY,
				id: groupOption.id,
				allowMultiple: true,
				option: process,
				category: groupOption.category,
				isOptionEnabled: function () {
					return true;
				}
			};
		}

		$scope.addButton = [
			{
				click: function () {
					addNewOption();
				}
			}
		];

		$scope.removeOption = function (index) {
			if ($scope.groupByOptions.length == 1) {
				$scope.groupByOptions.length = 0;
				addNewOption();
			} else {
				$scope.groupByOptions.splice(index, 1);
			}
			updateAvailableOptions();
		};

		$scope.applyFilters = function () {
			if (validFilters()) {
				$scope.ctrl.hasActiveFilters = true;

				var promise = $scope.ctrl.applyFilters($scope.groupByOptions, getFormattedFilters());
				if (promise) {
					promise.then(function () {
						if ($scope.ctrl.showMinimizeButton) {
							$scope.showFilters = false;
						}
					});
				}
			}
		};

		function getFormattedFilters() {
			var filters = $scope.groupByOptions;
			var formattedFilters = {};
			for (var i = 0; i < filters.length; ++i) {
				addValueFromOptionToDto(formattedFilters, filters[i]);
			}
			return formattedFilters;
		}

		function addValueFromOptionToDto(dto, filter) {
			if (!filter.field) {
				return;
			}
			if (dto[filter.field.id]) {
				if (!Array.isArray(dto[filter.field.id])) {
					var previousAddedValue = dto[filter.field.id];
					dto[filter.field.id] = [previousAddedValue];
				}
				dto[filter.field.id].push(filter.value);
			} else {
				dto[filter.field.id] = filter.value;
			}
		}

		function validFilters() {
			var filters = $scope.groupByOptions;
			for (var i = 0; i < filters.length; ++i) {
				if (!validOption(filters[i])) {
					$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.MANDATORY_FILL_ALL_FILTERS);
					return false;
				}
			}
			return true;
		}

		function validOption(filter) {
			if (filter.field) {
				if (!filter.value) {
					return false;
				} else if (Array.isArray(filter.value) && filter.value.length == 0) {
					return false;
				} else if (typeof filter.value === "object") {
					for (var property in filter.value) {
						if (!filter.value[property]) {
							return false;
						}
					}
				}
			}

			return true;
		}

		function alreadySelectedGroup(id) {
			var newArray = $scope.groupByOptions.filter(function (option) {
				return option.field && option.field.id == id;
			});
			return newArray.length > 0;
		}

		$scope.isDeleteButtonEnabled = function (index) {
			if ($scope.groupByOptions[index].isFixed) {
				return false;
			}

			return ($scope.groupByOptions[index] && $scope.groupByOptions[index].field) || $scope.groupByOptions.length > 1;
		};

		$scope.showAddButton = function (index) {
			if (index == $scope.groupByOptions.length - 1 && $scope.groupByOptions[$scope.groupByOptions.length - 1].field) {
				return true;
			}
			return false;
		};

		function addNewOption() {
			updateAvailableOptions();
			var availableFields = $scope.availableFields;
			var groupByOption = {
				availableFields: availableFields
			};
			$scope.groupByOptions.push(groupByOption);
			return groupByOption;
		}

		$scope.onChangeField = function (selectedOption) {
			$timeout(function () {
				updateAvailableOptionsFromExistentSelects(selectedOption);
				$scope.$ctrl.onChange($scope.groupByOptions);
			});
		};

		function updateAvailableOptions() {
			for (var option of $scope.availableFields) {
				option.isEnabled = !alreadySelectedGroup(option.id);
			}
		}

		function updateAvailableOptionsFromExistentSelects(selectedOption) {
			for (var groupByOption of $scope.groupByOptions) {
				for (var option of groupByOption.availableFields) {
					option.isEnabled = !alreadySelectedGroup(option.id) && option.id != selectedOption.id;
				}
			}
		}

		this.$onChanges = function (obj) {
			if (obj.clear && obj.clear.currentValue != obj.clear.previousValue) {
				if (obj.clear.currentValue) {
					clearGroupings();
				}
			}
		};

		$scope.$watch("$ctrl.list.length", function (newValue, oldValue) {
			if (newValue != null && newValue != oldValue) {
				initAvailableFields();
				clearGroupings();
			}
		});

		function clearGroupings() {
			$scope.groupByOptions = [];
			addNewOption();
		}
	}
]);
