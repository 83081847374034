angular.module("vgresiduos").controller("ClientFormIntegrationCtrl", [
	"$scope",
	"clientService",
	function ($scope, clientService) {
		"use strict";

		$scope.sinirId = Vgr.enumerations.destination.systemSource.SINIR_BR;
		$scope.sigorSPId = Vgr.enumerations.destination.systemSource.SIGOR_SP;
		$scope.isSupplier = clientService.isSupplier();

		$scope.$on(Vgr.constants.evtHasIntegrationClientExternalSystemSinir, function (evt, hasSinirIntegration) {
			$scope.hasSinirIntegration = hasSinirIntegration;
		});

		$scope.$on(Vgr.constants.evtHasIntegrationClientExternalSystemSigorSP, function (evt, hasSigorSPIntegration) {
			$scope.hasSigorSPIntegration = hasSigorSPIntegration;
		});
	}
]);
