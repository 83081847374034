angular.module("vgresiduos").controller("TransporterFormCtrl", [
	"$scope",
	"$rootScope",
	"$state",
	"accountService",
	"supplierService",
	"httpService",
	function ($scope, $rootScope, $state, accountService, supplierService, httpService) {
		"use strict";

		$scope.createEditDeletePermission = $scope.ctrl.hasPermission;
		$rootScope.contentNavBarGrid = false;
		const currentClient = accountService.getCurrentClient();

		this.$onInit = function () {
			if (!$scope.ctrl.model.supplierId) {
				$state.go("transporterList");
				return;
			}
			if (!$scope.ctrl.model.cityId) {
				$scope.countryId = currentClient.city.state.countryId;
			}

			$scope.ctrl.saveOptions.save = save;
			$scope.ctrl.saveOptions.validate = validate;

			$scope.setIsCNPJ();
			if ($scope.ctrl.model.city) {
				$scope.ctrl.model.cityAndState =
					$scope.ctrl.model.city.name + " - " + $scope.ctrl.model.city.state.abbreviation;
			}
		};

		$scope.subfieldList = [{ item: "CNPJ", label: $scope.labels.CNPJ + "/" + $scope.labels.CPF }];

		$scope.setIsCNPJ = function () {
			$scope.ctrl.model.isCNPJ =
				!$scope.ctrl.model.document || $scope.ctrl.model.document.toString().length == 14 ? true : false;
		};

		function save() {
			return updateSupplier().then(function () {
				$state.go("transporterEdit", { id: $scope.transporter.code });
			});
		}

		function getModel() {
			return {
				isDestinator: $scope.transporter.isDestinator,
				isTransporter: $scope.transporter.isTransporter,
				isTemporaryStorager: $scope.transporter.isTemporaryStorager,
				isRawMaterial: $scope.transporter.isRawMaterial,
				phone: $scope.transporter.phone,
				email: $scope.transporter.email
			};
		}

		function updateSupplier() {
			const dto = {
				supplierCode: $scope.ctrl.model.code,
				model: getModel()
			};
			return httpService.postDTOToServiceV2(supplierService.updateOrganizationUnitSupplier, dto);
		}

		function validate() {
			if (
				$scope.ctrl.model.isTransporter ||
				$scope.ctrl.model.isDestinator ||
				$scope.ctrl.model.isTemporaryStorager ||
				$scope.ctrl.model.isRawMaterial
			) {
				return true;
			} else {
				$rootScope.$broadcast(
					Vgr.constants.evtShowErrorMessage,
					$rootScope.labels.CHOOSE_TREATER_OR_TRANSPORTER_OR_TEMPORARY_STORAGER
				);
				return false;
			}
		}

		$scope.isCnpjRequiredForCountry = function () {
			return !$scope.cityCountryId || $scope.cityCountryId == Vgr.constants.country.Brazil;
		};

		$scope.redirectToNewPage = function () {
			const url = $state.href("supplierEdit", {
				id: $scope.ctrl.model.supplierId,
				tab: 0
			});
			window.open(url, "_blank");
		};
	}
]);
