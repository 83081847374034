angular.module("vgresiduos").directive("automaticCreateProvisionalMtr", function () {
	return {
		scope: true,
		controller: "AutomaticCreateProvisionalMtrCtrl",
		controllerAs: "ctrl",
		templateUrl:
			"views/destination/popups/disposalCreateProvisionalMtrPopup/automaticCreateProvisionalMtr_template.html",
		bindToController: {
			externalSystemId: "=",
			disposalId: "=",
			unitId: "=",
			onConfirm: "=?",
			onCancel: "=?",
			isToUpdate: "=?",
			calculateAutomaticCode: "=?",
			//bad code, created to change the position of buttons when the component is alone
			isTheOnlyExternalSystem: "=?"
		}
	};
});
