angular.module("vgresiduos").directive("masterUserCustomizedFilter", function () {
	return {
		scope: true,
		controller: "MasterUserCustomizedFilterCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/master/users/customizedFilter/masterUserCustomizedFilter_template.html",
		bindToController: {
			isFilterActive: "=",
			onFilter: "=",
			onCleanFilters: "=",
			hasActiveFilters: "="
		}
	};
});
